<ng-container *transloco="let t; read: 'layout'">
	<!-- Button -->
	<button
			[matMenuTriggerFor]="userActions"
			mat-icon-button>
    <span class="relative">
        <img
		        *ngIf="showAvatar && this.user?.avatar"
		        [src]="this.user.avatar"
		        class="w-7 h-7 rounded-full">
        <mat-icon
		        *ngIf="!showAvatar || !this.user?.avatar"
		        [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span
		        [ngClass]="{'mr-px mb-px': !showAvatar || !this.user?.avatar,
                          'bg-green-500': this.user?.status === 'online',
                          'bg-amber-500': this.user?.status === 'away',
                          'bg-red-500': this.user?.status === 'busy',
                          'bg-gray-400': this.user?.status === 'not-visible'}"
		        class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
        ></span>
    </span>
	</button>

	<mat-menu
      #userActions="matMenu"
	  [xPosition]="'before'">
		<button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>{{ t('loggedInAs') }}</span>
            <span class="mt-1.5 text-md font-medium">{{this.user?.email}}</span>
        </span>
		</button>
		<mat-divider class="my-2"></mat-divider>
		<!--	<button mat-menu-item>-->
		<!--		<mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>-->
		<!--		<span>{{ 'USER.MENU.PROFILE' | translate }}</span>-->
		<!--	</button>-->
		<a [routerLink]="'/einstellungen'" mat-menu-item>
			<mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
			<span>{{ t('settings') }}</span>
		</a>
		<!--	<button-->
		<!--			[matMenuTriggerFor]="userStatus"-->
		<!--			mat-menu-item>-->
		<!--		<mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>-->
		<!--		<span>{{ 'USER.MENU.STATUS' | translate }}</span>-->
		<!--	</button>-->
		<mat-divider class="my-2"></mat-divider>
		<button
				(click)="signOut()"
				mat-menu-item>
			<mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
			<span>{{ t('logOut') }}</span>
		</button>
	</mat-menu>

	<mat-menu
      #userStatus="matMenu"
	  class="user-status-menu">
		<button
				(click)="updateUserStatus('online')"
				mat-menu-item>
			<span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
			<span>{{ t('online') }}</span>
		</button>
		<button
				(click)="updateUserStatus('away')"
				mat-menu-item>
			<span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
			<span>{{ t('away') }}</span>
		</button>
		<button
				(click)="updateUserStatus('busy')"
				mat-menu-item>
			<span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
			<span>Busy</span>
			<span>{{ t('busy') }}</span>
		</button>
		<button
				(click)="updateUserStatus('not-visible')"
				mat-menu-item>
			<span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
			<span>{{ t('notVisible') }}</span>
		</button>
	</mat-menu>
</ng-container>
