<div cdkScrollable
     class="absolute inset-0 flex flex-col min-w-0 overflow-y-auto bg-default">

    <!-- Header -->
    <div
        class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
        <div class="flex-1 min-w-0">
            <!-- Breadcrumbs -->
            <div>
                <div class="flex sm:hidden">
                    <a
                        [routerLink]="'./..'"
                        class="inline-flex items-center -ml-1.5 text-secondary font-medium">
                        <mat-icon
                            [svgIcon]="'heroicons_solid:chevron-left'"
                            class="icon-size-5 text-secondary"></mat-icon>
                        <span class="ml-1">Zurück</span>
                    </a>
                </div>
            </div>
            <!-- Title -->
            <div class="mt-2">
                <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                    Mahnung erstellen
                </h2>
            </div>
        </div>
        <!-- Actions -->
        <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <a [routerLink]="'./..'" mat-stroked-button>
                <mat-icon
                    [svgIcon]="'heroicons_solid:x'"
                    class="icon-size-5 mr-2"></mat-icon>
                <span class="ml-2">Abbrechen</span>
            </a>
            <button *ngIf="false"
                    [color]="'primary'"
                    class="ml-3"
                    mat-flat-button>
                <mat-icon
                    [svgIcon]="'heroicons_solid:check'"
                    class="icon-size-5 mr-2"></mat-icon>
                Speichern
            </button>
        </div>
    </div>

    <!-- Main -->
    <div class="flex-auto p-6 sm:p-10">

        <!-- CONTENT GOES HERE -->
        <fuse-card *ngIf="this.unpaidBookings; else loadingTemplate"
                   @zoomIn @zoomOut class="rounded-2xl drop-shadow-xl">
            <mat-stepper #stepper [linear]="this.isLinear" [selectedIndex]="0">

                <!--  step 1 Bookings-->
                <mat-step [stepControl]="this.selectedBookingsFC">
                    <ng-template matStepLabel>
                        Offene Posten wählen
                    </ng-template>

                    <div class="w-full max-w-3xl">
                        <!-- Section -->
                        <div class="w-full">
                            <div class="text-xl">Mahnungsauswahl treffen</div>
                            <div class="text-secondary">
                                    <span>
                                        Wählen Sie die offenen Sollstellungen aus, die in die Mahnung aufgenommen werden sollen. Überprüfen Sie die Details jeder Sollstellung sorgfältig, um sicherzustellen, dass nur die relevanten Beträge gemahnt werden.
                                    </span>
                            </div>
                        </div>

                        <div *ngIf="this.unpaidBookings?.length > 0; else noUnpaidBookingTemplate" @zoomIn @zoomOut
                             class="grid sm:grid-cols-4 gap-6 w-full mt-8">

                            <div class="sm:col-span-4">
                                <mat-selection-list #bookings [formControl]="this.selectedBookingsFC" [multiple]="true">
                                    <mat-list-option *ngFor="let booking of this.unpaidBookings"
                                                     [selected]="true"
                                                     [value]="booking"
                                                     color="primary"
                                                     togglePosition="before">
                                        <div class="flex flex-row space-x-2">
                                            <span>{{booking?.text}}</span>
                                            <div class="px-2 bg-warn text-on-warn rounded"
                                                 matTooltip="Offener Betrag, der noch bezahlt werden muss.">
                                                <span>{{booking?.saldo | currency:'EUR'}}</span>
                                            </div>
                                            <div class="px-2 bg-warn text-on-warn rounded"
                                                 matTooltip="Diese Sollstellung ist noch offen und steht zur Mahnung an.">
                                                <span>Offen</span>
                                            </div>
                                            <div class="px-2 bg-warn text-on-warn rounded"
                                                 matTooltip="Fälligkeitsdatum der Zahlung.">
                                                <span>zum {{booking?.date| toDate | date:'shortDate'}}</span>
                                            </div>
                                        </div>
                                    </mat-list-option>
                                </mat-selection-list>

                                <div *ngIf="this.selectedBookingsFC.hasError('minOneBooking')" @zoomIn @zoomOut
                                     class="my-6">
                                    <!-- Error -->
                                    <fuse-alert [type]="'error'">
                                        Um fortzufahren, wählen Sie bitte mindestens eine offene Posten Sollstellung
                                        aus.
                                    </fuse-alert>
                                </div>

                                <!-- Divider -->
                                <div class="mt-11 mb-10 border-t"></div>

                                <div class="flex flex-col space-y-2">
                                    <div class="flex flex-row space-x-2">
                                        <span class="font-extrabold"
                                              matTooltip="Anzahl der Offenen Posten, die zur Mahnung ausgewählt wurden.">
                                        Ausgewählte OP:
                                        </span>
                                        <div>
                                            <div class="px-2 bg-warn text-on-warn rounded">
                                                <span>{{bookings?.selectedOptions?.selected?.length}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-row space-x-2">
                                        <span class="font-extrabold"
                                              matTooltip="Gesamtbetrag der Offenen Posten, die gemahnt werden.">
                                        Gesamt OP:
                                        </span>
                                        <div>
                                            <div class="px-2 bg-warn text-on-warn rounded">
                                                <span>{{ this.dunningFormGroup?.get('amountToPay')?.value | currency:'EUR'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ng-template #noUnpaidBookingTemplate>
                            <div @zoomIn @zoomOut
                                 class="grid sm:grid-cols-4 gap-6 w-full mt-8">

                                <div class="sm:col-span-4">
                                    <!-- Error -->
                                    <fuse-alert [type]="'error'">
                                        Keine Mahnung möglich: Es gibt derzeit keine unbezahlten Sollstellungen. Stellen
                                        Sie sicher, dass die Sollstellungen den Status <span class="font-extrabold">'Unbezahlt'</span>
                                        haben und das Ziel-Datum erreicht ist. Sie können den Status in der
                                        Sollstellungen-Liste manuell anpassen.
                                    </fuse-alert>
                                </div>
                            </div>
                        </ng-template>
                    </div>

                    <ng-container *ngIf="this.unpaidBookings?.length > 0">
                        <div class="mt-11 mb-10 border-t"></div>

                        <div>
                            <button (click)="this.goToStep2()"
                                    [color]="this.selectedBookingsFC?.hasError('minOneBooking') ? 'warn' : 'primary'"
                                    mat-raised-button matStepperNext>Weiter
                            </button>
                        </div>
                    </ng-container>
                </mat-step>

                <!--  step 2 Dunning-->
                <mat-step [stepControl]="this.dunningFormGroup">
                    <form [formGroup]="this.dunningFormGroup">
                        <ng-template matStepLabel>
                            Daten erfassen
                        </ng-template>

                        <div class="w-full max-w-3xl">
                            <!-- Section -->
                            <div class="w-full">
                                <div class="text-xl">Mahnungsgrad auswählen</div>
                                <div class="text-secondary">
                                    <span>
                                        Bitte wählen Sie den Mahnungsgrad aus: von einer freundlichen Zahlungserinnerung bis zur letzten Mahnung. Jede Option unterstützt eine klare und positive Kommunikation mit dem Mieter.
                                    </span>
                                </div>
                            </div>
                            <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">

                                <div class="sm:col-span-4">
                                    <mat-selection-list (selectionChange)="this.onDunningTypeSelectionChanged($event)"
                                                        [formControl]="this.dunningTypeFC"
                                                        [multiple]="false">
                                        <mat-list-option *ngFor="let type of dunningTypes" [matTooltip]="type?.tooltip"
                                                         [selected]="type.value === this.nextStage"
                                                         [value]="type.value"
                                                         color="primary"
                                                         togglePosition="before">
                                            {{type.viewValue }}
                                        </mat-list-option>
                                    </mat-selection-list>
                                </div>
                            </div>

                            <!-- Divider -->
                            <div class="mt-11 mb-10 border-t"></div>

                            <div class="w-full max-w-3xl">
                                <!-- Section -->
                                <div class="w-full">
                                    <div class="text-xl">Betrag und Frist festlegen</div>
                                    <div class="text-secondary">
                                    <span>
                                        Geben Sie nun den fälligen Betrag und das Fälligkeitsdatum der Zahlung an. Diese Informationen werden in der Mahnung angezeigt und helfen dem Mieter, die überfälligen Zahlungen klar zu verstehen und fristgerecht zu begleichen.
                                    </span>
                                    </div>
                                </div>
                                <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                                    <!-- Kündigungsdatum -->
                                    <div class="sm:col-span-2">
                                        <mat-form-field class="w-full" subscriptSizing="dynamic">
                                            <mat-label>Betrag</mat-label>
                                            <span matSuffix>zu zahlen</span>
                                            <!--                                            <mat-label>Zu zahlender Betrag</mat-label>-->
                                            <input
                                                [options]="{ prefix: '€', thousands: '.', decimal: ',',align: 'left'}"
                                                currencyMask
                                                formControlName='amountToPay'
                                                matInput
                                                name='amountToPay'>
                                            <mat-error
                                                *ngIf="this.dunningFormGroup?.get('amountToPay')?.hasError('required')">
                                                Der Betrag ist erforderlich.
                                            </mat-error>
                                            <mat-error
                                                *ngIf="this.dunningFormGroup?.get('amountToPay')?.hasError('min')">
                                                Der Betrag muss größer als 0 sein.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="sm:col-span-2">
                                        <mat-form-field appearance="fill" class="w-full">
                                            <mat-label>Fristdatum</mat-label>
                                            <input [matDatepicker]="picker" [min]="this.today" formControlName="dueDate"
                                                   matInput>
                                            <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                            <!--                                            <mat-hint>Format: TT.MM.JJJJ</mat-hint>-->
                                            <mat-hint
                                                *ngIf="this.dunningFormGroup.get('dueDate')?.value as dueDate">
                                                {{dueDate | amTimeAgo }}
                                            </mat-hint>
                                            <mat-error
                                                *ngIf="this.dunningFormGroup?.get('dueDate')?.hasError('required')">
                                                Ein Datum ist erforderlich.
                                            </mat-error>
                                            <mat-error
                                                *ngIf="this.dunningFormGroup?.get('dueDate')?.hasError('matDatepickerParse')">
                                                Ungültiges Datum.
                                            </mat-error>
                                            <mat-error
                                                *ngIf="this.dunningFormGroup?.get('dueDate')?.hasError('matDatepickerMin')">
                                                Datum darf nicht in der Vergangenheit liegen.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div
                                        *ngIf="this.dunningFormGroup.get('type')?.value !== this.dunningTypeOptions.PAYMENT_REMINDER"
                                        class="sm:col-span-4">
                                        <fuse-alert @zoomIn @zoomOut [type]="'primary'" class="mt-4">
                                            <mat-icon [svgIcon]="'mat_outline:auto_fix_high'"
                                                      class="icon-size-5"
                                                      fuseAlertIcon>
                                            </mat-icon>
                                            <span fuseAlertTitle>Automatisierung</span>
                                            Sie können zusätzliche Mahngebühren festlegen, die dem Schuldner in Rechnung
                                            gestellt werden. Aktivieren Sie diese Option, um einen Betrag einzugeben.
                                            <div class="flex flex-col space-y-4 mt-4">
                                                <mat-slide-toggle
                                                    class="dark:bg-gradient-to-r from-black to-primary dark:rounded-full dark:py-2"
                                                    color="primary"
                                                    formControlName="enableLateFees">
                                                <span
                                                    class="font-extrabold text-primary dark:text-white">Mahngebühren hinzufügen</span>
                                                </mat-slide-toggle>

                                                <div *ngIf="this.dunningFormGroup.get('enableLateFees')?.value" @zoomIn
                                                     @zoomOut>
                                                    <mat-form-field appearance="fill"
                                                                    class="w-full sm:w-1/3 fuse-mat-emphasized-affix">
                                                        <mat-label>Mahngebühren Betrag</mat-label>
                                                        <input
                                                            [options]="{ prefix: '€', thousands: '.', decimal: ',',align: 'left'}"
                                                            currencyMask
                                                            formControlName="feesToPay"
                                                            matInput>
                                                        <mat-hint
                                                            *ngIf="this.dunningFormGroup.get('feesToPay')?.invalid">
                                                            Bitte geben Sie einen Betrag zwischen 0€ und 10€
                                                            ein.
                                                        </mat-hint>
                                                        <mat-error
                                                            *ngIf="this.dunningFormGroup.get('feesToPay')?.hasError('required')">
                                                            Ein Betrag ist erforderlich.
                                                        </mat-error>
                                                        <mat-error
                                                            *ngIf="this.dunningFormGroup.get('feesToPay')?.hasError('min')">
                                                            Der Betrag muss größer als 0€ sein.
                                                        </mat-error>
                                                        <mat-error
                                                            *ngIf="this.dunningFormGroup.get('feesToPay')?.hasError('max')">
                                                            Der Betrag darf nicht höher als 10€ sein.
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </fuse-alert>
                                    </div>

                                    <div
                                        *ngIf="this.dunningFormGroup.get('type')?.value !== this.dunningTypeOptions.PAYMENT_REMINDER"
                                        class="sm:col-span-4">
                                        <fuse-alert @zoomIn @zoomOut [type]="'primary'" class="mt-4">
                                            <mat-icon [svgIcon]="'mat_outline:auto_fix_high'"
                                                      class="icon-size-5"
                                                      fuseAlertIcon>
                                            </mat-icon>
                                            <span fuseAlertTitle>Automatisierung des Zahlungsverzugs</span>
                                            Aktivieren Sie diese Funktion, um automatisch Zinsen für überfällige
                                            Zahlungen zu berechnen und in die Sollstellungen aufzunehmen. Dies
                                            erleichtert die Verwaltung von verspäteten Zahlungen und sorgt für eine
                                            korrekte Abrechnung.
                                            <div class="flex flex-col space-y-4 mt-4">
                                                <mat-slide-toggle
                                                    class="dark:bg-gradient-to-r from-black to-primary dark:rounded-full dark:py-2"
                                                    color="primary"
                                                    formControlName="enablePaymentDelayInterest">
                                                <span
                                                    class="font-extrabold text-primary dark:text-white">Zahlungsverzug Zinsen berechnen</span>
                                                </mat-slide-toggle>

                                                <div
                                                    *ngIf="this.dunningFormGroup.get('enablePaymentDelayInterest')?.value"
                                                    @zoomIn @zoomOut>
                                                    <mat-form-field appearance="fill"
                                                                    class="w-full sm:w-1/3 fuse-mat-emphasized-affix">
                                                        <mat-label>Zinssatz in Prozent</mat-label>
                                                        <div
                                                            class="text-secondary"
                                                            matSuffix>
                                                            %
                                                        </div>
                                                        <input formControlName="interestRate" matInput type="number">
                                                        <mat-hint
                                                            *ngIf="this.dunningFormGroup.get('interestRate')?.invalid">
                                                            Geben Sie den jährlichen Zinssatz ein, z.B. 5 für 5%
                                                        </mat-hint>
                                                        <mat-error
                                                            *ngIf="this.dunningFormGroup.get('interestRate')?.hasError('required')">
                                                            Ein Zinssatz ist erforderlich.
                                                        </mat-error>
                                                        <mat-error
                                                            *ngIf="this.dunningFormGroup.get('interestRate')?.hasError('min')">
                                                            Der Zinssatz muss größer als 0 sein.
                                                        </mat-error>
                                                        <mat-error
                                                            *ngIf="this.dunningFormGroup.get('interestRate')?.hasError('max')">
                                                            Der Zinssatz darf nicht höher als 15% sein.
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </fuse-alert>
                                    </div>

                                    <div
                                        *ngIf="this.dunningFormGroup.get('enablePaymentDelayInterest')?.value && this.dunningFormGroup.get('bookingsWithInterest')?.value?.length > 0"
                                        @zoomIn
                                        @zoomOut
                                        class="sm:col-span-4 p-6 bg-card shadow-2xl rounded-2xl overflow-hidden">
                                        <div class="text-lg font-semibold tracking-tight leading-6 truncate">
                                            Zinsberechnung
                                        </div>
                                        <div class="flex flex-col flex-auto mt-2 overflow-x-auto">
                                            <table
                                                [dataSource]="this.dunningFormGroup.get('bookingsWithInterest')?.value"
                                                class=""
                                                mat-table>

                                                <!-- Spalten Text-->
                                                <ng-container matColumnDef="text">
                                                    <th *matHeaderCellDef mat-header-cell> Sollstellung</th>
                                                    <td *matCellDef="let element"
                                                        mat-cell>
                                                        <span class="flex items-center">
                                                            <span [ngClass]="{
                                                                  'bg-red-500': element.overdueDays === 0,
                                                                  'bg-green-500': element.overdueDays > 0}"
                                                                  class="w-2 h-2 rounded-full"></span>
                                                            <span
                                                                class="ml-3 leading-4">{{element?.booking?.text }}</span>
                                                        </span>
                                                    </td>
                                                </ng-container>

                                                <!-- Spalten Definitionen -->
                                                <ng-container matColumnDef="dueDate">
                                                    <th *matHeaderCellDef mat-header-cell> Fälligkeitsdatum</th>
                                                    <td *matCellDef="let element"
                                                        mat-cell> {{element?.booking.date | toDate | date : 'shortDate'}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="amount">
                                                    <th *matHeaderCellDef mat-header-cell
                                                        matTooltip="Dies ist der offene Betrag, der noch bezahlt werden muss.">
                                                        Betrag
                                                    </th>
                                                    <td *matCellDef="let element"
                                                        mat-cell> {{element.booking?.saldo | mathAbs | currency:'EUR'}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="overdueDays">
                                                    <th *matHeaderCellDef mat-header-cell> Überfällige Tage</th>
                                                    <td *matCellDef="let element"
                                                        mat-cell> {{element.overdueDays}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="interestAmount">
                                                    <th *matHeaderCellDef mat-header-cell> Zinsbetrag</th>
                                                    <td *matCellDef="let element"
                                                        mat-cell>
                                                        <div *ngIf="element.interestAmount > 0"
                                                             class="flex flex-row space-x-2">
                                                            <div class="px-2 bg-green-500 text-on-primary rounded">
                                                                <span>+ {{element.interestAmount | currency:'EUR'}}</span>
                                                            </div>
                                                        </div>
                                                        <span
                                                            *ngIf="element.interestAmount <= 0">{{element.interestAmount | currency:'EUR'}}</span>

                                                    </td>
                                                </ng-container>

                                                <!-- Kopf- und Datenzeilen -->
                                                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                                                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

                                                <!-- Footer-Zeile für die Gesamtsumme der Zinsen -->
                                                <ng-container matColumnDef="footer">
                                                    <td *matFooterCellDef colspan="4" mat-footer-cell>
                                                        <div
                                                            *ngIf="this.dunningFormGroup.get('interestToPay')?.value > 0"
                                                            class="flex flex-row space-x-2">
                                                            <div class="font-semibold">Gesamtzinsen:</div>
                                                            <div
                                                                class="px-2 bg-green-500 text-on-primary rounded font-extrabold">
                                                                <span>+ {{this.dunningFormGroup.get('interestToPay')?.value | currency:'EUR'}}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </ng-container>
                                                <tr *matFooterRowDef="['footer']" mat-footer-row></tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Divider -->
                        <div class="mt-11 mb-10 border-t"></div>

                        <ng-container *ngIf="this.dunningFormGroup?.valid">
                            <div @zoomIn @zoomOut class="w-full max-w-3xl">
                                <!-- Section -->
                                <div class="w-full">
                                    <div class="text-xl">Überprüfung der Mahnung</div>
                                    <div class="text-secondary">
                                    <span>
                                        Bitte überprüfen Sie alle Angaben zur Mahnung sorgfältig, bevor Sie mit der Generierung des Mahnungsanschreibens fortfahren
                                    </span>
                                    </div>
                                </div>
                                <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                                    <div class="sm:col-span-4">
                                        <div class="flex flex-col space-y-4 w-full">
                                            <div class="flex flex-row space-x-2 text-md">
                                                <span class="font-medium">Hauptforderung (unbezahlter Betrag):</span>
                                                <div class="px-2 bg-warn text-on-warn rounded">
                                                    <span>{{this.dunningFormGroup?.get('amountToPay')?.value | currency:'EUR'}}</span>
                                                </div>
                                            </div>
                                            <div *ngIf="this.dunningFormGroup?.get('feesToPay')?.value as feesToPay"
                                                 @zoomIn @zoomOut class="flex flex-row space-x-2 text-md">
                                                <span class="font-medium">Mahngebühren:</span>
                                                <div class="px-2 bg-warn text-on-warn rounded">
                                                    <span>+ {{ feesToPay | currency:'EUR'}}</span>
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="this.dunningFormGroup?.get('interestToPay')?.value as interestToPay"
                                                @zoomIn @zoomOut class="flex flex-row space-x-2 text-md">
                                                <span class="font-medium">Verzugszinsen:</span>
                                                <div class="px-2 bg-warn text-on-warn rounded">
                                                    <span>+ {{ interestToPay | currency:'EUR'}}</span>
                                                </div>
                                            </div>
                                            <div class="w-full sm:w-1/2">
                                                <mat-divider></mat-divider>
                                            </div>
                                            <div
                                                *ngIf="this.dunningFormGroup?.get('amountToPayInclFeesAndInterest')?.value as amountToPayInclFeesAndInterest"
                                                @zoomIn @zoomOut class="flex flex-row space-x-2">
                                                <span class="font-extrabold">Gesamtbetrag zu zahlen:</span>
                                                <div class="px-2 bg-warn text-on-warn rounded">
                                                    <span>{{ amountToPayInclFeesAndInterest | currency:'EUR'}}</span>
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="this.dunningFormGroup?.get('dueDate')?.value as dueDate"
                                                @zoomIn @zoomOut class="flex flex-row space-x-2">
                                                <span class="font-extrabold">Fälligkeitsdatum der Zahlung:</span>
                                                <div class="px-2 bg-primary text-on-primary rounded">
                                                    <span>{{ dueDate | date:'mediumDate'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Divider -->
                            <div @zoomIn @zoomOut class="mt-11 mb-10 border-t"></div>

                            <div @zoomIn @zoomOut>
                                <button mat-button matStepperPrevious>Zurück</button>
                                <button color="primary" mat-raised-button matStepperNext>Weiter</button>
                            </div>
                        </ng-container>
                    </form>
                </mat-step>

                <!--  step 3 Letter or contract with name and email-->
                <mat-step [stepControl]="this.confirmationFormGroup">
                    <form [formGroup]="this.confirmationFG">
                        <ng-template matStepLabel>
                            Mahnschreiben generieren
                        </ng-template>

                        <div class="w-full max-w-3xl">
                            <!-- Section -->
                            <div class="w-full">
                                <div class="text-xl">Mahnschreiben-Erstellung</div>
                                <div class="text-secondary">
                                    Wie und an wen soll das Mahnschreiben verschickt werden?
                                </div>
                            </div>
                        </div>

                        <div class="grid sm:grid-cols-4 gap-6 w-full my-8 flex flex-1">
                            <div class="sm:col-span-2">
                                <fuse-card class="flex flex-col max-w-3xl w-full mt-11">
                                    <div
                                        class="flex flex-col sm:flex-row justify-items-center items-center space-x-12 ml-6 sm:ml-8 mt-4">
                                        <div class="flex flex-col w-full justify-center sm:col-span-2">
                                            <img [ngClass]="{'grayscale': !this.confirmationFG.get('byPost')?.value}"
                                                 class="h-40"
                                                 src="assets/illustrations/opened/default/undraw_opened.svg">
                                            <div class="my-2 flex flex-row space-x-2 justify-center items-center">
                                                <div class="text-2xl font-semibold leading-tight">
                                                    Per Post
                                                </div>
                                                <div class="">
                                                    <mat-slide-toggle class="px-3" color="primary"
                                                                      formControlName="byPost">
                                                    </mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="this.confirmationFG.get('byPost')?.value"
                                         class="flex flex-col flex-auto mx-2 sm:pt-0 sm:p-8 overflow-y-auto">
                                        <!-- To -->
                                        <mat-form-field>
                                            <mat-label>An</mat-label>
                                            <mat-chip-grid #chipListName aria-label="tenants name selection"
                                                           formArrayName="tenantsNameList">
                                                <mat-chip-row (removed)="this.removeName(name)"
                                                              *ngFor="let name of this.namesControls?.value; let i = index">
                                                    <span>{{name}}</span>
                                                    <button class="h-auto" matChipRemove>
                                                        <mat-icon>cancel</mat-icon>
                                                    </button>
                                                </mat-chip-row>
                                                <input (matChipInputTokenEnd)="this.addName($event)"
                                                       [matChipInputAddOnBlur]="addOnBlur"
                                                       [matChipInputFor]="chipListName"
                                                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                       placeholder="Name des Empfängers eingeben">
                                            </mat-chip-grid>
                                            <mat-error class="mb-2">Bitte überprüfen Sie Ihre Eingaben!</mat-error>
                                        </mat-form-field>

                                    </div>
                                </fuse-card>
                            </div>
                            <div class="sm:col-span-2">
                                <fuse-card class="flex flex-col max-w-3xl w-full mt-11">
                                    <div
                                        class="flex flex-col sm:flex-row justify-items-center items-center space-x-12 ml-6 sm:ml-8 mt-4">
                                        <div class="flex flex-col w-full justify-center sm:col-span-2">
                                            <img [ngClass]="{'grayscale': !this.confirmationFG.get('byEmail')?.value}"
                                                 class="h-40"
                                                 src="assets/illustrations/mail/default/undraw_mail.svg">
                                            <div class="my-2 flex flex-row space-x-2 justify-center items-center">
                                                <div class="text-2xl font-semibold leading-tight">
                                                    Per E-Mail
                                                </div>
                                                <div class="">
                                                    <mat-slide-toggle class="px-3" color="primary"
                                                                      formControlName="byEmail">
                                                    </mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="this.confirmationFG.get('byEmail')?.value"
                                         class="flex flex-col flex-auto mx-2 sm:pt-0 sm:p-8 overflow-y-auto">

                                        <!-- To -->
                                        <mat-form-field @zoomIn @zoomOut class="w-full">
                                            <mat-label>Empfänger</mat-label>
                                            <app-chip-list-email-input (onShowBccClicked)="showCopyField('bcc')"
                                                                       (onShowCcClicked)="showCopyField('cc')"
                                                                       [formControl]="this.emailFormControl"
                                                                       [showCcAndBCC]="true"
                                                                       class="w-full"
                                                                       placeholder="E-Mail-Adressen der Mieter">
                                            </app-chip-list-email-input>
                                            <mat-error>Bitte geben Sie nur gültige E-Mail-Adressen ein</mat-error>
                                        </mat-form-field>

                                        <!-- Cc -->
                                        <mat-form-field *ngIf="copyFields.cc" @zoomIn @zoomOut class="w-full">
                                            <mat-label>Cc</mat-label>
                                            <app-chip-list-email-input [formControl]="this.cCemailFormControl"
                                                                       class="w-full"
                                                                       placeholder="E-Mail-Adressen in CC setzen">
                                            </app-chip-list-email-input>
                                            <mat-error>Bitte geben Sie nur gültige E-Mail-Adressen ein</mat-error>
                                        </mat-form-field>

                                        <!-- Bcc -->
                                        <mat-form-field *ngIf="copyFields.bcc" @zoomIn @zoomOut class="w-full">
                                            <mat-label>Bcc</mat-label>
                                            <app-chip-list-email-input [formControl]="this.bCCemailFormControl"
                                                                       class="w-full"
                                                                       placeholder="E-Mail-Adressen in BCC setzen">
                                            </app-chip-list-email-input>
                                            <mat-error>Bitte geben Sie nur gültige E-Mail-Adressen ein</mat-error>
                                        </mat-form-field>

                                        <!-- Actions -->
                                        <div *ngIf="false"
                                             class="flex flex-col sm:flex-row sm:items-center justify-between mt-4 sm:mt-6">
                                            <div class="-ml-2">
                                                <!-- Attach file -->
                                                <button mat-icon-button>
                                                    <mat-icon
                                                        [svgIcon]="'heroicons_solid:paper-clip'"
                                                        class="icon-size-5"></mat-icon>
                                                </button>
                                                <!-- Insert link -->
                                                <button mat-icon-button>
                                                    <mat-icon
                                                        [svgIcon]="'heroicons_solid:link'"
                                                        class="icon-size-5"></mat-icon>
                                                </button>
                                                <!-- Insert emoji -->
                                                <button mat-icon-button>
                                                    <mat-icon
                                                        [svgIcon]="'heroicons_solid:emoji-happy'"
                                                        class="icon-size-5"></mat-icon>
                                                </button>
                                                <!-- Insert image -->
                                                <button mat-icon-button>
                                                    <mat-icon
                                                        [svgIcon]="'heroicons_solid:photograph'"
                                                        class="icon-size-5"></mat-icon>
                                                </button>
                                            </div>

                                            <div class="flex items-center mt-4 sm:mt-0">
                                                <!-- Save as draft -->
                                                <button
                                                    class="sm:mx-3"
                                                    mat-stroked-button>
                                                    <span>Save as draft</span>
                                                </button>
                                                <!-- Send -->
                                                <button
                                                    [color]="'primary'"
                                                    class="order-first sm:order-last"
                                                    mat-flat-button>
                                                    Send
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </fuse-card>
                            </div>
                        </div>

                        <div class="mt-6">
                            <!-- <mat-expansion-panel class="w-300" (opened)="panelOpenState = true"
                            (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                            <mat-panel-title>
                                Konfiguration
                            </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ng-container
                            *ngIf="!this.publicProfile?.address || !this.publicProfile?.address?.streetName else continue">
                            <fuse-alert
                                @zoomIn @zoomOut
                                [type]="'error'">
                                <span fuseAlertTitle>Keine Adresse ausgewählt</span>
                                 Bitte gehen Sie zurück und wählen Sie eine Adresse
                                aus, um fortzufahren, oder überspringen Sie die Konfiguration.
                            </fuse-alert>
                        </ng-container>
                        <ng-template #continue>
                            <fuse-alert @zoomIn @zoomOut [dismissible]="false" [type]="'primary'" class="mt-5"
                                        variant="info">
                                <mat-icon class="icon-size-7" fontIcon="info_outline" fuseAlertIcon></mat-icon>
                                <span fuseAlertTitle class="text-lg">Bitte beachten Sie</span>
                                <span class="text-m">
                                    Die relevanten Informationen sind standardmäßig im PDF-Dokument enthalten. Wenn Sie diese ausschließen möchten, deaktivieren Sie bitte die entsprechenden Optionen.                                            </span>
                                <div
                                    class="flex flex-col md:flex-row md:flex-wrap space-x-0 md:space-x-8 md:space-y-0 space-y-0 mt-4">
                                    <mat-slide-toggle (change)="updateSettings('logo', $event) " *ngIf="this.confirmationFormGroup.get('hasLogo').value"
                                                      [checked]="settings.logo"
                                                      @zoomIn
                                                      @zoomOut
                                                      [disabled]="!this.publicProfile?.logo"
                                                      color="primary" matTooltip="{{ !this.publicProfile?.logo ? 'Kein logo zugewiesen' : ''}}">
                                        <span class="font-extrabold text-primary">Logo</span>
                                    </mat-slide-toggle>
                                    <mat-slide-toggle (change)="updateSettings('footer', $event)"
                                                      [checked]="settings.footer" color="primary">
                                        <span class="font-extrabold text-primary">Fußzeile</span>
                                    </mat-slide-toggle>

                                    <mat-slide-toggle (change)="updateSettings('bankAccount', $event)"
                                                      @zoomIn
                                                      @zoomOut
                                                      *ngIf="settings.footer"
                                                      [checked]="settings.bankAccount"
                                                      [disabled]="!this.publicProfile?.bankAccount"
                                                      color="primary"
                                                      matTooltip="{{ !this.publicProfile?.bankAccount ? 'Kein Bankkonto zugewiesen' : ''}}">
                                        <span class="font-extrabold text-primary">Bankkonto</span>
                                    </mat-slide-toggle>

                                    <mat-slide-toggle (change)="updateSignature($event)"
                                        [checked]="this.signatureRequired"
                                        color="primary">
                                        <span class="font-extrabold text-primary">Automatisierte Signaturerklärung</span>
                                    </mat-slide-toggle>

                                </div>
                            </fuse-alert>
                        </ng-template>
                        </mat-expansion-panel> -->

                            <mat-accordion [multi]="true" class="w-full drop-shadow-xl">
                                <mat-expansion-panel [expanded]="true">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            Konfiguration
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <ng-container
                                        *ngIf="!this.publicProfile?.address || !this.publicProfile?.address?.streetName else continue">
                                        <fuse-alert
                                            @zoomIn @zoomOut
                                            [type]="'error'">
                                            <span fuseAlertTitle>Keine Adresse ausgewählt</span>
                                            Bitte gehen Sie zurück und wählen Sie eine Adresse
                                            aus, um fortzufahren, oder überspringen Sie die Konfiguration.
                                        </fuse-alert>
                                    </ng-container>
                                    <ng-template #continue>
                                        <fuse-alert @zoomIn @zoomOut [dismissible]="false" [type]="'primary'"
                                                    class="mt-5"
                                                    variant="info">
                                            <mat-icon class="icon-size-7" fontIcon="info_outline"
                                                      fuseAlertIcon></mat-icon>
                                            <span class="text-lg" fuseAlertTitle>Bitte beachten Sie</span>
                                            <span class="text-m">
                                        Die relevanten Informationen sind standardmäßig im PDF-Dokument enthalten. Wenn Sie diese ausschließen möchten, deaktivieren Sie bitte die entsprechenden Optionen.                                            </span>
                                            <div
                                                class="flex flex-col md:flex-row md:flex-wrap space-x-0 md:space-x-8 md:space-y-0 space-y-0 mt-4">
                                                <mat-slide-toggle (change)="updateSettings('logo', $event) "
                                                                  *ngIf="this.confirmationFormGroup.get('hasLogo').value"
                                                                  @zoomIn
                                                                  @zoomOut
                                                                  [checked]="settings.logo"
                                                                  [disabled]="!this.publicProfile?.logo"
                                                                  color="primary"
                                                                  matTooltip="{{ !this.publicProfile?.logo ? 'Kein logo zugewiesen' : ''}}">
                                                    <span class="font-extrabold text-primary">Logo</span>
                                                </mat-slide-toggle>
                                                <mat-slide-toggle (change)="updateSettings('footer', $event)"
                                                                  [checked]="settings.footer" color="primary">
                                                    <span class="font-extrabold text-primary">Fußzeile</span>
                                                </mat-slide-toggle>

                                                <mat-slide-toggle (change)="updateSettings('bankAccount', $event)"
                                                                  *ngIf="settings.footer"
                                                                  @zoomIn
                                                                  @zoomOut
                                                                  [checked]="settings.bankAccount"
                                                                  [disabled]="!this.publicProfile?.bankAccount"
                                                                  color="primary"
                                                                  matTooltip="{{ !this.publicProfile?.bankAccount ? 'Kein Bankkonto zugewiesen' : ''}}">
                                                    <span class="font-extrabold text-primary">Bankkonto</span>
                                                </mat-slide-toggle>

                                                <mat-slide-toggle (change)="updateSignature($event)"
                                                                  [checked]="this.signatureRequired"
                                                                  color="primary"
                                                                  matTooltip="{{ this.signatureRequired ? 'Schalten Sie diese Option um, um die Aussage „Dieses Schreiben wurde maschinell erstellt und ist ohne Unterschrift gültig“ am unteren Rand Ihrer PDF-Dokumente zu entfernen. Diese Aussage weist darauf hin, dass das Dokument automatisch erstellt wurde und ohne Unterschrift gültig ist.' : 'Schalten Sie diese Option ein, um den Hinweis „Dieses Schreiben wurde maschinell erstellt und ist ohne Unterschrift gültig“ am Ende Ihrer PDF-Dokumente einzufügen. Diese Erklärung zeigt an, dass das Dokument automatisch generiert wurde und ohne Unterschrift gültig ist.'}}">
                                                    <span class="font-extrabold text-primary">Maschinell erstellte Erklärung</span>
                                                </mat-slide-toggle>
                                            </div>
                                        </fuse-alert>
                                    </ng-template>

                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>

                        <div class="grid sm:grid-cols-4 gap-6 w-full my-8 flex flex-1">
                            <div class="sm:col-span-3">
                                <!-- Subject -->
                                <mat-form-field class="w-full">
                                    <mat-label>Betreff</mat-label>
                                    <input
                                        [formControlName]="'subject'"
                                        id="subject_termination"
                                        matInput
                                        name="subject_termination">
                                </mat-form-field>
                            </div>
                            <div class="sm:col-span-1">
                                <mat-form-field class="w-full">
                                    <mat-label>Datum des Anschreibens</mat-label>
                                    <input [matDatepicker]='simpleDatePicker'
                                           formControlName='date'
                                           matInput
                                           placeholder='tt.mm.jjjj'
                                           required>
                                    <mat-hint class="mb-2">
                                        Geben Sie das Datum des Anschreibens ein
                                    </mat-hint>
                                    <mat-datepicker-toggle [for]='simpleDatePicker'
                                                           matSuffix></mat-datepicker-toggle>
                                    <mat-datepicker #simpleDatePicker></mat-datepicker>
                                    <mat-error class="mb-2">Ein Datum ist erforderlich.</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="sm:col-span-2">
                                <fuse-card class="flex flex-col">
                                    <!-- Body -->
                                    <quill-editor #quill
                                                  (onContentChanged)="this.onQuillContentChanged($event)"
                                                  [formControlName]="'html'"
                                                  [modules]="this.quillModules"
                                                  [styles]="{height: '450px'}"
                                                  class="mt-2"
                                                  trackChanges="all">
                                    </quill-editor>
                                </fuse-card>
                            </div>
                            <div class="sm:col-span-2">
                                <fuse-card class="h-full">
                                    <pdf-viewer (page-rendered)="pageRendered($event)"
                                                [original-size]="false"
                                                [render-text]="true"
                                                [src]="this.pdfSrc"
                                                style="width: 100%; height: 450px"
                                    ></pdf-viewer>
                                    <mat-progress-spinner *ngIf="this.renderingPDF"
                                                          [diameter]="64"
                                                          class="absolute top-1/2 left-1/2"
                                                          mode="indeterminate">
                                    </mat-progress-spinner>
                                    <button (click)="$event.stopPropagation(); this.generatePdf()"
                                            [disabled]="this.loadingPDF"
                                            class="absolute right-0 bottom-0"
                                            color="primary"
                                            mat-icon-button
                                            matTooltip="PDF herunterladen">
                                        <mat-icon *ngIf="!this.loadingPDF"
                                                  [svgIcon]="'heroicons_outline:document-download'"></mat-icon>
                                        <mat-progress-spinner
                                            *ngIf=this.loadingPDF
                                            [diameter]="24"
                                            [mode]="'indeterminate'"
                                            matTooltip="PDF wird geladen..."></mat-progress-spinner>
                                    </button>
                                </fuse-card>
                            </div>
                        </div>


                        <div class="mt-11 mb-10 border-t"></div>

                        <div>
                            <button mat-button matStepperPrevious>Zurück</button>
                            <button (click)="this.checkFormInvalidReason()"
                                    color="primary"
                                    mat-raised-button matStepperNext>
                                Weiter
                            </button>
                        </div>
                    </form>
                </mat-step>

                <!--   step 4 done-->
                <mat-step>
                    <ng-template matStepLabel>Fertig</ng-template>
                    <!-- Card #18 -->
                    <fuse-card class="flex flex-col md:flex-row w-full filter-article my-4">
                        <div class="flex flex-col flex-auto order-2 md:order-1 m-8 mb-4">
                            <div class="text-2xl font-semibold leading-tight">Fertigstellung der Mahnung</div>

                            <div class="flex flex-col my-4 space-y-2">
                                <!-- Success -->
                                <fuse-alert *ngIf="this.pdfCreated"
                                            @fadeInLeft
                                            [type]="'success'">
                                    <span @fadeInLeft fuseAlertTitle>Mahnung als PDF erstellt</span>
                                </fuse-alert>
                                <fuse-alert *ngIf="this.pdfUploaded"
                                            @fadeInLeft
                                            [type]="'success'">
                                    <span @fadeInLeft fuseAlertTitle>Mahnung in der Cloud gespeichert</span>
                                </fuse-alert>
                                <fuse-alert *ngIf="this.dunningCreated"
                                            @fadeInLeft
                                            [type]="'success'">
                                    <span fuseAlertTitle>Mahnung für die Buchhaltung erfasst</span>
                                </fuse-alert>
                                <fuse-alert *ngIf="this.emailGenerated"
                                            @fadeInLeft
                                            [type]="'success'">
                                    <span @fadeInLeft fuseAlertTitle>E-mail erstellt</span>
                                </fuse-alert>
                            </div>

                            <div class="mt-3 -mx-3">
                                <button class="px-3"
                                        mat-button
                                        matStepperPrevious>Zurück
                                </button>
                                <button (click)="this.save()"
                                        [disabled]="this.creating"
                                        class="px-3"
                                        color="primary"
                                        mat-raised-button>
                                    <span *ngIf="!this.creating">Bestätigen und Abschließen</span>
                                    <mat-progress-spinner
                                        *ngIf=this.creating
                                        [diameter]="24"
                                        [mode]="'indeterminate'"
                                        matTooltip="Mahnung wird erstellt..."></mat-progress-spinner>
                                </button>
                            </div>
                        </div>
                        <div class="flex-0 w-80 md:w-64">
                            <img
                                alt="Card cover image"
                                class="order-1 md:order-2 w-full h-full object-contain"
                                src="assets/illustrations/processing/undraw_Processing.svg">
                        </div>
                    </fuse-card>
                    <!--                    <div class="mt-11 mb-10 border-t"></div>-->
                    <!--                    <div>-->
                    <!--                        <button mat-button matStepperPrevious>Zurück</button>-->
                    <!--                        <button color="primary"-->
                    <!--                                mat-stroked-button matStepperNext>-->
                    <!--                            Bestätigen-->
                    <!--                        </button>-->
                    <!--                    </div>-->
                </mat-step>
            </mat-stepper>
        </fuse-card>

        <ng-template #loadingTemplate>
            <div @zoomIn @zoomOut
                 class="h-full p-8 sm:p-16 flex flex-col space-y-8 justify-center items-center nodata-container">
                <mat-spinner></mat-spinner>
                <fuse-alert
                    [appearance]="'soft'"
                    [type]="'primary'">
                    <mat-icon [svgIcon]="'mat_outline:sync'"
                              class="icon-size-5"
                              fuseAlertIcon>
                    </mat-icon>
                    Bitte warten Sie, während die unbezahlten Sollstellungen geladen werden. Dies kann einen Moment
                    dauern.icon
                </fuse-alert>
            </div>
        </ng-template>

    </div>

</div>
