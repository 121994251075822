<div class="flex flex-col flex-auto md:w-160 md:min-w-160 -m-6">
    <ng-container *ngIf="(note$ | async) as note">
        <!-- Image -->
        <ng-container *ngIf="note.image">
            <div class="relative w-full">
                <div class="absolute right-0 bottom-0 p-4">
                    <button
                        (click)="removeImage(note)"
                        mat-icon-button>
                        <mat-icon
                            [svgIcon]="'heroicons_outline:trash'"
                            class="text-white"></mat-icon>
                    </button>
                </div>
                <img
                    [src]="note.image"
                    class="w-full object-cover">
            </div>
        </ng-container>
        <div class="m-4">
            <!-- Title -->
            <div>
                <input
                    (input)="updateNoteDetails(note)"
                    [(ngModel)]="note.title"
                    [placeholder]="'Titel'"
                    class="w-full p-2 text-2xl">
            </div>
            <!-- Note -->
            <div class="flex w-full py-5 px-2">
                <textarea
                    (input)="updateNoteDetails(note)"
                    [(ngModel)]="note.content"
                    [placeholder]="'Notiz oder Hinweis'"
                    cdkTextareaAutosize
                    class="w-full"></textarea>
            </div>
            <!-- Tasks -->
            <ng-container *ngIf="note.tasks">
                <div class="mx-2 mt-4 space-y-1.5">
                    <ng-container *ngFor="let task of note.tasks; trackBy: trackByFn">
                        <div class="group flex items-center">
                            <mat-checkbox
                                (change)="updateTaskOnNote(note, task)"
                                [(ngModel)]="task.completed"
                                [color]="'primary'"
                                class="flex items-center"></mat-checkbox>
                            <input
                                (input)="updateTaskOnNote(note, task)"
                                [(ngModel)]="task.content"
                                [ngClass]="{'text-secondary line-through': task.completed}"
                                [placeholder]="'Task'"
                                class="w-full px-1 py-0.5">
                            <mat-icon
                                (click)="removeTaskFromNote(note, task)"
                                [svgIcon]="'heroicons_solid:x'"
                                class="hidden group-hover:flex ml-auto icon-size-5 cursor-pointer"></mat-icon>
                        </div>
                    </ng-container>
                    <div class="flex items-center">
                        <mat-icon
                            [svgIcon]="'heroicons_solid:plus'"
                            class="-ml-0.5 icon-size-5 text-hint"></mat-icon>
                        <input
                            #newTaskInput
                            (keydown.enter)="addTaskToNote(note, newTaskInput.value); newTaskInput.value = ''"
                            [placeholder]="'Add task'"
                            class="w-full ml-1.5 px-1 py-0.5">
                    </div>
                </div>
            </ng-container>
            <!-- Labels -->
            <ng-container *ngIf="note.labels && note.labels.length">
                <div class="flex flex-wrap items-center mx-1 mt-6">
                    <ng-container *ngFor="let label of note.labels; trackBy: trackByFn">
                        <div
                            class="flex items-center m-1 py-0.5 px-3 rounded-full text-sm font-medium text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700">
                            <div>
                                {{label.title}}
                            </div>
                            <mat-icon
                                (click)="toggleLabelOnNote(note, label)"
                                [svgIcon]="'heroicons_solid:x-circle'"
                                class="ml-1 icon-size-4 cursor-pointer"></mat-icon>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <!-- Add Actions -->
            <ng-container *ngIf="!note.id">
                <div class="flex items-center justify-end mt-4">
                    <!-- Save -->
                    <button
                        (click)="createNote(note)"
                        [color]="'primary'"
                        [disabled]="!note.title && !note.content || this.saving"
                        mat-flat-button>
                        <span *ngIf="!this.saving" class="ml-2 mr-1">Speichern</span>
                        <mat-progress-spinner
                            *ngIf=this.saving
                            [diameter]="24"
                            [mode]="'indeterminate'"
                            matTooltip="PDF wird geladen..."></mat-progress-spinner>
                    </button>
                </div>
            </ng-container>
            <!-- Edit Actions -->
            <ng-container *ngIf="note.id">
                <div class="flex items-center justify-between mt-4">
                    <div class="flex items-center space-x-2">
                        <!-- Image -->
                        <div>
                            <input
                                #imageFileInput
                                (change)="uploadImage(note, imageFileInput.files)"
                                [accept]="'image/jpeg, image/png'"
                                [multiple]="false"
                                class="absolute h-0 w-0 opacity-0 invisible pointer-events-none"
                                id="image-file-input"
                                type="file">
                            <label
                                class="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer hover:bg-gray-400 hover:bg-opacity-20 dark:hover:bg-black dark:hover:bg-opacity-5"
                                for="image-file-input"
                                matRipple>
                                <mat-icon [svgIcon]="'heroicons_outline:photograph'"></mat-icon>
                            </label>
                        </div>
                        <!-- Checklist -->
                        <button
                            (click)="addTasksToNote(note)"
                            mat-icon-button>
                            <mat-icon [svgIcon]="'heroicons_outline:clipboard-list'"></mat-icon>
                        </button>
                        <!-- Labels -->
                        <button
                            [matMenuTriggerFor]="labelsMenu"
                            mat-icon-button>
                            <mat-icon [svgIcon]="'heroicons_outline:tag'"></mat-icon>
                        </button>
                        <mat-menu #labelsMenu="matMenu">
                            <ng-container *ngIf="(labels$ | async) as labels">
                                <ng-container *ngFor="let label of labels">
                                    <button
                                        (click)="toggleLabelOnNote(note, label)"
                                        mat-menu-item>
                                        <span class="flex items-center">
                                            <mat-checkbox
                                                [checked]="isNoteHasLabel(note, label)"
                                                [color]="'primary'"
                                                class="flex items-center pointer-events-none"
                                                disableRipple></mat-checkbox>
                                            <span class="ml-1 leading-5">{{label.title}}</span>
                                        </span>
                                    </button>
                                </ng-container>
                            </ng-container>
                        </mat-menu>
                        <!-- Archive -->
                        <button
                            (click)="toggleArchiveOnNote(note)"
                            mat-icon-button>
                            <mat-icon [svgIcon]="'heroicons_outline:archive'"></mat-icon>
                        </button>
                        <!-- Delete -->
                        <button
                            (click)="deleteNote(note)"
                            mat-icon-button>
                            <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                        </button>
                    </div>
                    <!-- Close -->
                    <button
                        mat-flat-button
                        matDialogClose>
                        Schließen
                    </button>
                </div>
            </ng-container>
        </div>
    </ng-container>

</div>
