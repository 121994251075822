import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractFirestoreDocResolver } from '@shared/services/abstracts/AbstractFirestoreDocResolver';
import { Transaction } from '@transactions/models/transaction.interface';
import { TransactionsService } from '@transactions/services/transactions.service';


@Injectable()
export class TransactionResolver extends AbstractFirestoreDocResolver<Transaction> {

  constructor(route: ActivatedRoute, router: Router, service: TransactionsService) {
    super(router, service);
  }
}
