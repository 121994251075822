import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  CanMatch,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { NahausModulesPermission } from '@users/models/user.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PermissionsGuard implements CanActivate, CanActivateChild, CanLoad, CanMatch {
  constructor(private authService: AuthService) {
    console.log('PermissionsGuard created');
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.runAccessLogic(route);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.runAccessLogic(childRoute);
  }

  canMatch(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.runAccessLogic(route);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.runAccessLogic(route);
  }

  runAccessLogic(route: Route): Observable<boolean> | boolean | Promise<boolean | UrlTree> {
    const permission = route?.data?.permission as NahausModulesPermission;
    console.log('runAccessLogic --> permission = ', permission);
    return this.authService.hasPermissionOrRedirect(permission, true);
  }
}
