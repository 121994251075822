import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { firstValueFrom, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Property } from '../models/property.interface';
import { PropertiesOldService } from '../services/properties.old.service';
import { PropertiesService } from '../services/properties.service';

@Injectable({ providedIn: 'root' })
export class PropertyResolver implements Resolve<Property> {

  id: string;

  constructor(private router: Router,
              private ps: PropertiesOldService,
              private pfs: PropertiesService) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<Property> | Property {
    console.log('Property resolver is running for property id: ', route.params['id']);

    this.id = route.params['id'];

    return new Promise((resolve, reject) => {
      if (this.ps?.selected?.id === this.id && this.id !== undefined) {
        console.log('property found in service', this.ps?.selected);
        return resolve(this.ps?.selected);
      } else {
        firstValueFrom(
          this.pfs
            .doc(this.id)
            .get()
            .pipe(map(doc => doc.data() as Property), catchError((error) => {

              // Log the error
              console.error(error);

              // Get the parent url
              const parentUrl = state.url.split('/').slice(0, -1).join('/');

              // Navigate to there
              this.router.navigateByUrl(parentUrl);

              // Throw an error
              return throwError(error);
            })))
          .then((doc) => {
            console.log('doc found in firestore - property', doc);
            this.ps.selected = doc;
            return resolve(doc);
          })
          .catch(err => {
            console.error('Error while fetching a property with id', this.id, err);
            this.router.navigate(['immobilien']);
            return reject();
          });
      }
    });
  }
}
