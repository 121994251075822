import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NahausQuota } from '@quota/models';
import { AuthService } from '@shared/services/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class UsersQuotaGuard implements CanActivate {

  constructor(private auth: AuthService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const quota: NahausQuota = this.auth?.quota;

    if (!!quota?.users?.available) {
      const canAccess = quota?.users?.available > quota?.users?.used;
      console.log('canAccess new user --> ', canAccess);
      if (canAccess) {
        return true;
      } else {
        return this.router.navigate(['fehler', '432']);
      }
    }
    return true;
  }

}
