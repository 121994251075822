import {GermanAddress} from '@angular-material-extensions/google-maps-autocomplete';
import {NahausImage} from '@shared/models/image.interface';
import firebase from 'firebase/compat';
import {Landlord} from '../../landlords/models';
import Timestamp = firebase.firestore.Timestamp;

/**
 * @author Anthony Nahas
 * @since 20.10.21
 * @update 08.08.22
 * @version 1.5
 */
export interface Meter {
  id?: string;
  customerID?: string;
  createdAt?: Timestamp;
  updatedAt?: Date | Timestamp;
  // property
    h_id?: string | number;
  h_id_text?: string;
  propertyID?: string;
  address?: GermanAddress;
  // filters
  m_id?: string; // Zählernummer oder Zähler ID
  description?: string; // Allgemeinstrom, Allgemeingas, Hauptwasserzähler
  type?: MeterType;
  lastKnownReading?: MeterReading; // letze bekannte Zählertand
  location?: string; // wo befindet sich der Zähler --> Keller, Küche, hinten im Garten...
  remark?: string; // Bemerkung
  // Booleans
  outdated?: boolean;
  annualReadingRequired?: boolean;
  reRegistrationRequired?: boolean; // Ummeldung erfoderlich z.B: nachdem Eigentümerwechsel
  // stats
  numberOfReadings?: number;
  // landlord
  landlord?: Partial<Landlord>;

  img?: NahausImage;
}

export interface UnitMeter extends Meter {
    w_id?: string | number;
  w_id_text?: string;
  unitID?: string;
  unitLabel?: string; // 1OG, DG ,Hinter Haus
  unitSummary?: string; // 2Zi-Kü-Bad
  // tenants
  previousTenants?: string; // Vormieter --> this should be updated via cloud firesbase function
  currentTenants?: string; // jetzige Mieter --> this should be updated via cloud firesbase function
}

export interface MeterReading {
  // ids
  id?: string;
  customerID?: string;
  propertyID?: string;
  unitID?: string;
  meterID?: string; // meter.id --> zähler id im firestore
  h_id?: string;
  w_id?: string;
  m_id?: string; // meter.m_id --> zählernummer
  // dates
  createdAt?: Timestamp;
  updatedAt?: Date | Timestamp;
  // filters
  date?: Date | Timestamp; // Wann wurde abgelesen
  value?: number; // Zählerstand
  recorder?: string; // Name der jenige der den Stand erfasst hat

  img?: NahausImage;
}

export enum MeterTypeOptions {
  ELECTRICITY = 'ELECTRICITY',
  ELECTRICITY_INTERMEDIATE_METER = 'ELECTRICITY_INTERMEDIATE_METER',
  WATER = 'WATER',
  WARM_WATER = 'WARM_WATER',
  GAS = 'GAS',
  DISTRICT_HEATING = 'DISTRICT_HEATING',
  // HEATER = 'HEATER',
}

export enum MeterSubTypeOptions {
  MAIN_METER = 'MAIN_METER',
  INTERMEDIATE_METER = 'INTERMEDIATE_METER',
  COLD_WATER = 'COLD_WATER',
  WARM_WATER = 'WARM_WATER',
  GAS = 'GAS',
  DISTRICT_HEATING = 'DISTRICT_HEATING',
  // HEATER = 'HEATER',
}

export type Meters = Meter[];
export type UnitMeters = UnitMeter[];
export type MeterReadings = MeterReading[];


export interface StatMeter {
  size?: number;
  electricityMeters?: number;
  electricityIntermediateMeters?: number;
  waterMeters?: number;
  warmWaterMeters?: number;
  gasMeters?: number;
  unknown?: number;
}

export type MeterSubType =
  MeterSubTypeOptions.MAIN_METER
  | MeterSubTypeOptions.INTERMEDIATE_METER
  | MeterSubTypeOptions.COLD_WATER
  | MeterSubTypeOptions.WARM_WATER
  | MeterSubTypeOptions.GAS;

export type MeterType =
  MeterTypeOptions.ELECTRICITY
  | MeterTypeOptions.ELECTRICITY_INTERMEDIATE_METER
  | MeterTypeOptions.WATER
  | MeterTypeOptions.WARM_WATER
  | MeterTypeOptions.GAS
  | MeterTypeOptions.DISTRICT_HEATING;

export enum MeterReRegistrationTypeOptions {
  RENTED_RENTED = 'RENTED_RENTED', // Auszug mit Mieter
  RENTED_LEERSTAND = 'RENTED_LEERSTAND', // Auszug mit Leerstand
  LEERSTAND_RENTED = 'LEERSTAND_RENTED', // Anmeldung nach Leerstand
  NEW_PROPERTY = 'NEW_PROPERTY', // Eigentümerwechsel oder neues Objekt
  NEW_METER = 'NEW_METER', // Neue Zähler
}

export type MeterReRegistrationType =
  MeterReRegistrationTypeOptions.RENTED_RENTED
  | MeterReRegistrationTypeOptions.RENTED_LEERSTAND
  | MeterReRegistrationTypeOptions.LEERSTAND_RENTED
  | MeterReRegistrationTypeOptions.NEW_PROPERTY
  | MeterReRegistrationTypeOptions.NEW_METER;

export interface MeterReRegistration {
  id?: string;
  customerID?: string;
  createdAt?: Timestamp;
  updatedAt?: Date | Timestamp;
  startDate?: Date | Timestamp; // Einzug - Anmeldung
  endDate?: Date | Timestamp; // Auszug - Abmeldung
  previousConsumer?: string; // Vormieter oder bei Leerstand Eigentümer
  currentConsumer?: string; // Nachmieter oder bei Leerstand Eigentümer
  type?: MeterReRegistrationType;
  withContract?: boolean; // wenn Mieter nehmen das Angebot von der Stadtwerke an
  completed?: boolean; // whether the MeterReRegistration has failed input or not
  // details
  meter?: UnitMeter;
  // communication
  sender?: { name?: string; email?: string };
  receiver?: { name?: string; email?: string };
  remark?: string; // Bemerkung
}

export interface MeterReRegistrationStats {
  size?: number;
  incomplete?: number;
  completed?: number;
}

export type MeterReRegistrations = MeterReRegistration[];
