import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { MsaList } from '@msa/models';
import { Observable, of } from 'rxjs';

@Injectable()
export class MsaListForContractResolver implements Resolve<MsaList | null> {

  constructor(private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MsaList | null> {
    const msaList = this.router.getCurrentNavigation()?.extras?.state?.msaList as MsaList;
    console.log('this.router.getCurrentNavigation().extras.state', msaList);
    return of(msaList ?? null);
  }
}
