import {GermanAddress} from '@angular-material-extensions/google-maps-autocomplete';
import firebase from 'firebase/compat';
import {PropertyUnit} from '../../../models/unit.interface';
import {Deposit} from '../features/deposit/models/deposit.interface';
import {Tenant} from '../features/tenants/models';
import {TerminationType} from '../features/termination/models/termination.interface';
import Timestamp = firebase.firestore.Timestamp;


/**
 * @author Anthony Nahas
 * @since 02.2020
 * @version 1.4
 * @updated 01.11.2023
 */
export interface PropertyUnitPeriod extends BasePeriod {
    id?: string;
    h_id?: number;
    w_id?: number;
    customerID?: string;
    createdAt?: Date | Timestamp;
    updatedAt?: Date | Timestamp;
    propertyID?: string;
    unitID?: string;
    address?: GermanAddress;
    isCurrent?: boolean;
    isCommercial?: boolean;
    isRenovated?: boolean;
    vat?: number; // 0.19 default
    hasVat?: boolean;
    // this should be created after the contract
    deactivateAutoSyncNumberOfResidents?: boolean; // Automatische Synchronisierung der Personennummer durch Hinzufügen, Bearbeiten oder Löschen von Mietern deaktivieren --> das eignet sich zum Beispiel für gewerbe oder wenn ein Unternehmen eine Einheit mietet für Ihre Mitarbeiter. Das kann Nahaus nicht selber der tatsächlichen Personen Anzahl erfassen. Sie müssen das dann manuell mitteilein über das Mieterverlauf.
    initialNumberOfResidents?: number; // anzahl an Bewohner die zum ersten mal hinzugefügt wurde
    numberOfResidents?: number; // anzahl an Bewohner zB: 2 Mieter --> 2 Bewohner oder eine Familie --> 1 Mieter aber 3 Bewohner
    lastTenantsNumberChangeID?: string;
    numberOfTenants?: number; // Anzahl an Personen die den Mietvertrag unterschrieben und die Einheit mieten und dafür haften
    tenants?: Partial<Tenant>[]; // displayName of the tenants - firstName + lastName
    tenantsName?: string; // list of tenant's lastname
    tenantsDisplayName?: string; // list of tenant's display name
    // previous period and tenants
    previousPeriodID?: string;
    previousTenantsName?: string; // list of tenant's lastname
    previousTenantsDisplayName?: string; // list of tenant's display name
    // termination
    currentTerminationID?: string;
    numberOfTerminations?: number;

    // EXTRA //todo: please use PropertyUnitPeriodExtended interface instead
    unit?: Partial<PropertyUnit>; // DO NOT REMOVE --> THIS WILL BE USED FOR STATISTICS

    // Handover
    handoverIn?: Handover;
    handoverOut?: Handover;

    // nk related properties - Bemerkung Feld
    nkNote?: string;

    // accounting
    ibanList?: string[]; // this will be synced from bankAccounts.iban

    // custom greeting
    greeting?: string;
}

export interface BasePeriod {
    rental?: Rental | BusinessRental;
    costs?: Costs;
    deposit?: Deposit;
}

export interface PropertyUnitPeriodExtended extends PropertyUnitPeriod {
    h_id?: number; // todo: should be added into the PropertyUnitPeriod interface ?
    w_id?: number; // todo: should be added into the PropertyUnitPeriod interface ?
    unit?: Partial<PropertyUnit>;
    // tenants?: string[];
}

export interface PropertyUnitPeriodExtendedWithAppointment extends PropertyUnitPeriodExtended {
    type?: PeriodType;
    appointment?: Date | Timestamp;
    appointmentState?: AppointmentState;
}

export type PeriodType = 'in' | 'out';
export type AppointmentState = 'confirmed' | 'pending';


export interface Rental {
    isRented?: boolean;
    isIndexMVActivated?:boolean;
    info?: string;
    type?: RentalPeriodType | RentalPeriodType[];
    lastTerminationType?: TerminationType;
    limitedExtras?: {
        // numberOfYears?: number;
        a?: string;
        b?: string;
        c?: string;
        terminationInMonths?: number;
    };
    duration?: RentalDuration; // in month e.g: startDate = x; endDate = y --> duration = y -x; in years, months and days
    startDate?: Date | Timestamp; // Miete Start Datum
    endDate?: Date | Timestamp; // Miete End Datum
    contractDate?: Date | Timestamp; // MV Datum
    terminationDate?: Date | Timestamp; // Kündigung Datum
    terminationTimeCondition?: RentalTerminationTimeCondition;
    dueDayDate?: number; // Die Miete ist fällig am X Tag des Monats
    noticePeriod?: number; // Kündigungsfrist in Monat - Anzahl vom Kündigungsfrist
    periodUnit?: RentalPeriodUnit; // das ist nur für Festmietzeit
    fixedRentalPeriod?: number; // Number of month to rent before to quit a unit - maximum rental period
    minimumRentalPeriod?: number; // Number of minimum month to rent before to quit a unit - minimum rental period
    whoCanTerminate?: TerminationSubmitter; // if the landlord and/or the tenant can terminate the rental period
    graduatedRent?: BasePeriod[];
    extensionOption?: PeriodExtensionOption; // Option auf Verlängerung
    paymentMethodAgreement?: 'SELF' | 'SEPA';
}

export interface BusinessRental extends Rental {
    label?: string; // Laden, Pizzeria, Deutsche Post
    businessType?: BusinessType;
    notes?: string;
    autoRenewal: Rental[];
}

export interface RentalDuration {
    years?: number;
    months?: number;
    days?: number;
}

export interface RentalBasedCosts {
    strategy?: PeriodPaymentStrategy;
    total?: number;
}

export interface BasedCosts {
    strategy?: PaymentStrategy;
    total?: number;
}

export interface Costs extends RentalBasedCosts {
    id?: string;
    customerID?: string;
    propertyID?: string;
    unitID?: string;
    periodID?: string;
    createdAt?: Date | Timestamp; // time when a doc has been create
    updatedAt?: Date | Timestamp; // time when a doc has been updated
    startDate?: Date | Timestamp; // when if the price change due
    // WARM = KALT + NK
    kalt?: PeriodPayment;
    graduatedRent?: GraduatedRent;
    // NK = BK + HK
    hk?: PeriodPayment;
    bk?: PeriodPayment;
    electricity?: PeriodPayment;
    nk?: number;
    nkType?: NK_Type;
    // OTHERS
    parking?: PeriodPayment;
    others?: PeriodPayment[];
    // Mahngebühren --> pro nicht bezahlte Miete
    dunningFees?: number;
    // Zinssatz bei Zahlungsverzug in % --> interestRateDueLatePayment = 0,05 --> 5%
    interestRateDueLatePayment?: number;

    // nk related properties
    nkPrePayment?: {
        invoiceID: string;
        value?: number;
    };

    bkPrePayment?: {
        invoiceID: string;
        value?: number;
    };
    hkPrePayment?: {
        invoiceID: string;
        value?: number;
    };

    reservePrePayment?: {
        invoiceID: string;
        value?: number;
    };

    wegPrePayment?: {
        invoiceID: string;
        value?: number;
    };

    // Nur für WEG
    weg?: PeriodPayment;
    reserve?: PeriodPayment;

    // Verwendungszweck
    autoCreatePaymentReference?: boolean; // ob nahaus automatisch einer Verwendungszweck generieren soll
    paymentReference?: string; // this is needed to match a transaction with a booking
}

export interface PeriodPayment {
    description?: string;
    amount?: number;
    art?: PeriodPaymentArt;

    // todo: PeriodPaymentStrategy for every period payment
}

export interface PaymentStrategy {
    type?: PeriodPaymentStrategyType;
}

export interface PeriodPaymentStrategy extends PaymentStrategy {
    month?: number; // Sollstellung im Monat
    dayOf?: number; // Tag der Sollstellung --> 01 - 31
    autoSoll?: boolean; // automatische Sollstellung
    lastAutoBookingsDate?: Date | Timestamp;
    markAllInitBookingAsPaid?: boolean; // Rückwirkende Sollstellungen als bezahlt markieren (nur einmalig bei der Erstellung der Vermietung)
}

export interface TerminationSubmitter {
    landlord: boolean;
    tenant: boolean;
}

export interface GraduatedRent {
    activated?: boolean;
    list?: GraduatedRentPeriod[];
}

export interface GraduatedRentPeriod {
    id?: string;
    customerID?: string;
    createdAt?: Date | Timestamp;
    updatedAt?: Date | Timestamp;
    propertyID?: string;
    unitID?: string;
    periodID?: string;
    before?: number; // von 200
    diff?: number; // um  12
    after?: number; // auf 212
    startDate?: Date | Timestamp;

    address?: GermanAddress;
    tenantsName?: string;
    currentKaltAmount?: number;
    unitLabel?: string;
    h_id?: number;
    w_id?: number;
    processed?: boolean; // wenn die Staffelmiete wurde verarbeitet
}

export interface Handover {
    type?: HandoverType;
    process?: HandoverProcess;
    appointment?: Date | Timestamp;
    operations?: any; // todo: please this needs a type
}

export type Periods = PropertyUnitPeriod[];

export type BusinessType = 'OFFICE' | 'GASTRONOMY' | 'HANGAR' | 'RETAIL_TRADE';

export type PeriodPaymentArt =
    PeriodPaymentArtOptions.MIETE
    | PeriodPaymentArtOptions.BETRIEBSKOSTEN
    | PeriodPaymentArtOptions.HEIZKOSTEN
    | PeriodPaymentArtOptions.WEG
    | PeriodPaymentArtOptions.RESERVE;

export enum PeriodPaymentStrategyOptions {
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
    HALF_YEARLY = 'HALF-YEARLY',
    YEARLY = 'YEARLY'
}

export type PeriodPaymentStrategyType =
    PeriodPaymentStrategyOptions.MONTHLY
    | PeriodPaymentStrategyOptions.QUARTERLY
    | PeriodPaymentStrategyOptions.HALF_YEARLY
    | PeriodPaymentStrategyOptions.YEARLY;

export type RentalPeriodType = 'LIMITED' | 'UNLIMITED' | 'UNLIMITED_WITH_MIN_TIME' | 'LIMITED_WITH_OPTION_TO_EXTEND';

export type RentalPeriodUnit = 'MONTHS' | 'YEARS';

export type RentalTerminationTimeCondition = 'END_OF_CONTRACT' | 'END_OF_MONTH' | 'END_OF_QUARTER' | 'END_OF_YEAR';

export type NK_Type = 'PREPAYMENT' | 'FLAT_RATE';

export type PeriodPayments = PeriodPayment[];

export type HandoverType = 'IN' | 'OUT';

export type HandoverProcess = 'UNPROCESSED' | 'IN_PROGRESS' | 'PROCESSED';

export enum HandoverOperationsOptions {
    CONTACTED = 'CONTACTED',
    PROTOCOL_PREPARED = 'PROTOCOL_PREPARED',
    WAITING_FOR_APPOINTMENT_CONFIRMATION = 'WAITING_FOR_APPOINTMENT_CONFIRMATION',
    APPOINTMENT_MADE = 'APPOINTMENT_MADE',
    DEPOSIT_CHECKED = 'DEPOSIT_CHECKED',
    METERS_RE_REGISTERED = 'METERS_RE_REGISTERED',
}

export type HandoverOperationsType =
    HandoverOperationsOptions.CONTACTED
    | HandoverOperationsOptions.PROTOCOL_PREPARED
    | HandoverOperationsOptions.WAITING_FOR_APPOINTMENT_CONFIRMATION
    | HandoverOperationsOptions.APPOINTMENT_MADE
    | HandoverOperationsOptions.DEPOSIT_CHECKED
    | HandoverOperationsOptions.METERS_RE_REGISTERED;


export interface PeriodExtensionOption {
    value?: number; // Der Mietvertrag wird auf die Dauer von {{value}} Monaten/Jahren verlängert
    extensionUnit?: RentalPeriodUnit; // das ist nur für Festmietzeit
    terminationInMonths?: number; // in months unit
}

export enum PeriodPaymentArtOptions {
    MIETE = 'MIETE',
    BETRIEBSKOSTEN = 'BETRIEBSKOSTEN',
    HEIZKOSTEN = 'HEIZKOSTEN',
    WEG = 'WEG',
    RESERVE = 'RESERVE',
}
