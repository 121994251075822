import { Injectable } from '@angular/core';
import { ContactDataTitle } from '@shared/models/person.interface';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';
import faker from 'faker/locale/de';
import { BehaviorSubject } from 'rxjs';
import { NahausContact, NahausContacts } from '../models';

@Injectable()
export class ContactsService extends AbstractFirestoreService<NahausContact> {

  onListChanged$: BehaviorSubject<NahausContacts> = new BehaviorSubject<NahausContacts>(null);

  COLLECTION = 'contacts';

    constructor(authService: AuthService) {
    super(authService);
  }

  private _list: NahausContacts;

  set list(value: NahausContacts) {
    this._list = value;
    this.onListChanged$.next(value);
  }

    setParentPath(propertyID: string): void {
        this.parentPath = `properties/${propertyID}`;
    }
  generate(): NahausContact {
    return {
      address: {
        country: {
          long: faker.address.country()
        },
        locality: {
          long: faker.address.city()
        }
      },
      emails: [{ value: faker.internet.exampleEmail(), label: 'Private' }],
      firstName: faker.name.firstName(),
      lastName: faker.name.lastName(),
      notes: faker.lorem.paragraph(),
      // phoneNumber: { mobile: faker.phone.phoneNumber() },
      // type: faker.random.arrayElement([LandlordTypeOptions.PRIVATE, LandlordTypeOptions.COMMERCIAL]),
      title: faker.random.arrayElement([ContactDataTitle.F, ContactDataTitle.M])
    };
  }

}
