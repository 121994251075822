import {Component, ViewEncapsulation} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FuseVerticalNavigationComponent} from '@fuse/components/navigation';
import {FuseNavigationItem} from '@fuse/components/navigation/navigation.types';
import {NgIf} from "@angular/common";

@Component({
    selector: 'demo-sidebar',
    template: `
        <div class="py-10">
            <!-- Add any extra content that might be supplied with the component -->
            <div class="extra-content">
                <ng-content></ng-content>
            </div>

            <!-- Fixed demo sidebar -->
            <div class="mx-6 text-3xl font-bold tracking-tighter">Menü</div>
            <fuse-vertical-navigation
                [appearance]="'default'"
                [navigation]="menuData"
                [inner]="true"
                [mode]="'side'"
                [name]="'demo-sidebar-navigation'"
                [opened]="true"></fuse-vertical-navigation>

            <!-- Storage -->
            <div *ngIf="false" class="mx-6 mt-2">
                <div class="flex items-center">
                    <mat-icon
                        class="mr-2 icon-size-5"
                        [svgIcon]="'heroicons_solid:circle-stack'"></mat-icon>
                    <div class="text-lg font-semibold">Storage</div>
                </div>
                <div class="flex flex-col flex-auto mt-4">
                    <span class="text-sm leading-none mb-3">19.9 GB of 100 GB used</span>
                    <mat-progress-bar
                        [mode]="'determinate'"
                        [color]="'primary'"
                        [value]="19.9"></mat-progress-bar>
                </div>
            </div>

            <!-- Users -->
            <div *ngIf="false" class="mx-6 mt-10">
                <div class="flex items-center">
                    <mat-icon
                        class="mr-2 icon-size-5"
                        [svgIcon]="'heroicons_solid:users'"></mat-icon>
                    <div class="text-lg font-semibold">Users</div>
                </div>
                <div class="flex flex-col flex-auto mt-4">
                    <span class="text-sm leading-none mb-3">8 of 20 users used</span>
                    <mat-progress-bar
                        [mode]="'determinate'"
                        [color]="'accent'"
                        [value]="40"></mat-progress-bar>
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            demo-sidebar fuse-vertical-navigation .fuse-vertical-navigation-wrapper {
                box-shadow: none !important;
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FuseVerticalNavigationComponent, MatIconModule, MatProgressBarModule, NgIf],
})
export class DigitalProtocolSidebarComponent {
    menuData: FuseNavigationItem[];

    /**
     * Constructor
     */
    constructor() {
        this.menuData = [
            {
                title: 'Alle',
                type: 'basic',
                icon: 'mat_outline:list',
            },
            // {
            //     title: 'Actions',
            //     subtitle: 'Task, project & team',
            //     type: 'group',
            //     children: [
            //         {
            //             title: 'Create task',
            //             type: 'basic',
            //             icon: 'heroicons_outline:plus-circle',
            //         },
            //     ],
            // },
            {
                title: 'Status',
                type: 'group',
                children: [
                    {
                        title: 'Entwurf',
                        type: 'basic',
                        icon: 'heroicons_outline:clipboard',
                    },
                    {
                        title: 'In Bearbeitung',
                        type: 'basic',
                        icon: 'mat_outline:pending_actions',
                    },
                    {
                        title: 'Abgeschlossen',
                        type: 'basic',
                        icon: 'heroicons_outline:clipboard-check',
                    },
                ],
            },
            {
                title: 'Filter',
                type: 'group',
                children: [
                    {
                        title: 'Übergaben',
                        type: 'basic',
                        icon: 'mat_outline:login',
                    },
                    {
                        title: 'Abnahmen',
                        type: 'basic',
                        icon: 'mat_outline:logout',
                    },
                    {
                        title: 'Archiviert',
                        type: 'basic',
                        icon: 'mat_outline:archive',
                    },
                ],
            },
            {
                type: 'divider',
            },
        ];
    }
}
