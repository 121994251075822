import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';


import {LanguagesModule} from 'app/layout/common/languages/languages.module';
import {MessagesModule} from '../../../common/messages-old/messages.module';
import {NotificationsModule} from 'app/layout/common/notifications/notifications.module';
import {QuickChatModule} from 'app/layout/common/quick-chat/quick-chat.module';
import {SearchModule} from '../../../common/search-old/search.module';

import {UserModule} from 'app/layout/common/user/user.module';
import {CompactLayoutComponent} from 'app/layout/layouts/vertical/compact/compact.component';
import {SharedModule} from 'app/shared/shared.module';

@NgModule({
    imports: [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        LanguagesModule,
        MessagesModule,
        NotificationsModule,
        QuickChatModule,
        SearchModule,
        UserModule,
        SharedModule,
        CompactLayoutComponent
    ],
    exports: [
        CompactLayoutComponent
    ]
})
export class CompactLayoutModule {
}
