import {Injectable} from '@angular/core';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';
import {DigitalProtocol} from "../models/digital-protocol.interface";

@Injectable()
export class DigitalProtocolService extends AbstractFirestoreService<DigitalProtocol> {

    COLLECTION = 'protocols';

    constructor(authService: AuthService) {
        super(authService);
    }

}
