import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnInit,
    Optional,
    Output,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';
import {BaseWidget, NgAisIndex, NgAisInstantSearch} from 'angular-instantsearch';
import {connectAutocomplete} from 'instantsearch.js/es/connectors';
import {MatChipsModule} from '@angular/material/chips';
import {MatOptionModule} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet} from '@angular/common';

@Component({
    selector: 'app-algolia-multi-autocomplete-search',
    templateUrl: './algolia--multi-autocomplete.component.html',
    styleUrls: ['./algolia--multi-autocomplete.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, MatFormFieldModule, MatInputModule, MatAutocompleteModule, NgFor, MatOptionModule, MatChipsModule, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet]
})
export class AlgoliaMultiAutocompleteSearchComponent extends BaseWidget implements OnInit {

    @ContentChild(TemplateRef) public template?: TemplateRef<any>;

    @Input() formFieldClass: string;
    @Input() placeholder: string = 'Suchen...';
    @Input() matOptionStyles: string;
    @Input() label: string;
    @Input() hint: string;
    @Input() optGroupDisplay: boolean;

    // @ts-ignore
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onQuerySuggestionClick = new EventEmitter<{ indexName: string, value: any }>();


    state: {
        query: string;
        refine: Function;
        indices: object[];
    };

    constructor(
        @Inject(forwardRef(() => NgAisIndex))
        @Optional()
        public parentIndex: NgAisIndex,
        @Inject(forwardRef(() => NgAisInstantSearch))
        public instantSearchInstance: NgAisInstantSearch
    ) {
        super('AutocompleteComponent');
    }

    ngOnInit(): void {
        this.createWidget(connectAutocomplete, {});
        super.ngOnInit();
    }

    public handleChange($event: KeyboardEvent): void {
        this.state.refine(($event.target as HTMLInputElement).value);
    }

}
