<mat-form-field class="fuse-mat-rounded fuse-mat-dense flex-auto w-full mx-4 lg:ml-0" [subscriptSizing]="'dynamic'">
    <mat-label *ngIf="this.label">{{this.label}}</mat-label>
    <mat-icon
        [matBadge]="this.hits || null"
        [matTooltip]="this.iconToolTip"
        [svgIcon]="'heroicons_solid:search'"
        class="icon-size-5"
        matBadgeSize="small"
        matPrefix></mat-icon>
    <!--    <span class="cdk-visually-hidden">-->
    <!--      Example with a home icon with overlaid badge showing the number 15-->
    <!--    </span>-->
    <button (click)="this.clear()"
            *ngIf="search?.value?.length > 0"
            mat-icon-button
            matSuffix
            matTooltip="Suchwert löschen">
        <mat-icon matSuffix>close</mat-icon>
    </button>
    <input
        #search
        [autocomplete]="'off'"
        [disableControl]="this.disabled"
        [formControl]="this.searchInputControl"
        [placeholder]="this.placeHolder"
        matInput>
    <!--    <mat-hint class="relative">test</mat-hint>-->
</mat-form-field>
