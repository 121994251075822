import {Injectable} from '@angular/core';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {IBoard, IBoards} from '../models/scrumboard.types';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {environment} from '@env/environment';
import { where } from 'firebase/firestore';

@Injectable()
export class BoardService extends AbstractFirestoreService<IBoard> {

    onListChanged$: BehaviorSubject<IBoards> = new BehaviorSubject<IBoards>(null);

    COLLECTION = 'scrumboards';

    constructor(authService: AuthService, private firestore: AngularFirestore) {
        super(authService);
    }
    // this.buildFirestorePath(this.COLLECTION)
    getCardsWithPropertyId(propertyId: string): Observable<any[]> {
        // Use a collectionGroup query to search across all 'cards' collections in the database
        return this.firestore.collectionGroup('cards', ref => 
          ref.where('customerID', '==', this.authService.customerID)
             .where('property.id', '==', propertyId),
        ).valueChanges();
        
      }
      getCardsWithUnutId(unitId: string): Observable<any[]> {
        // Use a collectionGroup query to search across all 'cards' collections in the database
        return this.firestore.collectionGroup('cards', ref => 
          ref.where('customerID', '==', this.authService.customerID)
             .where('unit.id', '==', unitId)
        ).valueChanges();
      }
    private _list: IBoards;

    set list(value: IBoards) {
        this._list = value;
        this.onListChanged$.next(value);
    }
}
