import {Component, LOCALE_ID, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher-old';
import {Subject, takeUntil} from 'rxjs';
import {MatDrawer, MatSidenavModule} from "@angular/material/sidenav";
import {ExpensesMainSidebarComponent} from './expenses-sidebar/expenses-main-sidebar.component';
import {MatButtonModule} from '@angular/material/button';
import {ActivatedRoute, RouterLink, RouterOutlet} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {CustomDateAdapter} from "../../../../../app.component";
import {Platform} from "@angular/cdk/platform";
import * as moment from "moment";
import { ExpensesAccessLayerOptions } from '../../expenses.routing';
import { NgIf } from '@angular/common';

@Component({
    selector: 'bookingsAccounts-main',
    templateUrl: './expenses-main.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatIconModule, RouterLink, MatButtonModule, MatSidenavModule, ExpensesMainSidebarComponent, RouterOutlet , NgIf],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: moment.ISO_8601
                },
                display: {
                    dateInput: 'LL',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY'
                }
            }
        },
        {provide: LOCALE_ID, useValue: 'de'},
        {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, Platform]}
    ]
})
export class ExpensesMainComponent implements OnInit, OnDestroy {

    @ViewChild('matDrawer', {static: true}) drawer: MatDrawer;

    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    accessLayer : ExpensesAccessLayerOptions;
    expensesAccessLayerOptions =  ExpensesAccessLayerOptions;

    /**
     * Constructor
     */
    constructor(private _fuseMediaWatcherService: FuseMediaWatcherService , 
        private route: ActivatedRoute) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to media changes
        this.accessLayer = this.route.snapshot?.data?.accessLayer;
        console.log('the access layer in main expenses is', this.accessLayer)
        console.log('the access layer in main expenses is', this.expensesAccessLayerOptions)
      
            if (this.accessLayer === this.expensesAccessLayerOptions.PERIOD) {
                // Subscribe to media changes
                this._fuseMediaWatcherService.onMediaChange$
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(({matchingAliases}) => {
                        // Set the drawerMode and drawerOpened if
                        if (matchingAliases.includes('lg')) {
                            this.drawerMode = 'side';
                            this.drawerOpened = true;
                        } else {
                            this.drawerMode = 'over';
                            this.drawerOpened = false;
                        }
                    });
            } else {
                this.drawerMode = 'over';
                this.drawerOpened = false;
            }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
