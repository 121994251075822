<mat-form-field [class]="this.formFieldClass">
    <mat-label *ngIf="this.label">{{this.label}}</mat-label>
    <mat-icon
            [svgIcon]="'heroicons_solid:search'"
            class="icon-size-5"
            matPrefix></mat-icon>
    <input
        #searchInput
        (keyup)="handleChange($event)"
        [autocomplete]="'off'"
        [matAutocomplete]="auto"
        [placeholder]="this.placeholder"
        autocapitalize="none"
        autocomplete="off"
        autocorrect="off"
        id="search"
        matInput
        spellcheck="false"
        type="text">

    <mat-autocomplete
        #auto="matAutocomplete"
        style="margin-top: 30px">

        <ng-container *ngFor="let index of state.indices?.slice(1) || []">
            <mat-optgroup *ngIf="index?.hits?.length > 0">
                <mat-chip-listbox [selectable]="false" aria-label="Color selection"
                                  class="mat-mdc-chip-set-stacked">
                    <mat-chip-option [ngSwitch]="index?.indexName" [selected]="index?.hits?.length > 0" color="primary">
                        <span *ngSwitchCase="'tenants'">Mieter</span>
                        <span *ngSwitchCase="'contacts'">Kontakte</span>
                        <span *ngSwitchDefault>{{index?.indexName}}</span>
                    </mat-chip-option>
                </mat-chip-listbox>
            </mat-optgroup>
            <mat-option (click)="onQuerySuggestionClick.emit({indexName: index.indexName, value: option})"
                        *ngFor="let option of index.hits"
                        [style]="this.matOptionStyles">
                <ng-container [ngTemplateOutletContext]="{ option: option, indexName: index?.indexName } "
                              [ngTemplateOutlet]="template"></ng-container>
            </mat-option>
        </ng-container>
    </mat-autocomplete>
    <mat-hint *ngIf="this.hint">{{this.hint}}</mat-hint>
</mat-form-field>


