import { Injectable } from '@angular/core';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';
import { Booking } from '../models/booking';
import { BookingPaymentDivision } from '../models/bookingPaymentDivision';

@Injectable()
export class BookingPaymentsDivisionService extends AbstractFirestoreService<BookingPaymentDivision> {

  COLLECTION = 'bookingPaymentDivisions';

  constructor(authService: AuthService) {
    super(authService);
  }

  setParentPath(propertyID: string, unitID: string, periodID: string, bookingID: string): void {
    this.parentPath = `properties/${propertyID}/units/${unitID}/periods/${periodID}/bookings/${bookingID}`;
  }
}
