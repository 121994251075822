import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, take} from 'rxjs';
import {NahausBookingsAccountsModularService} from "../services/nahaus-bookings-accounts-modular.service";
import {QueryConstraint} from "@firebase/firestore";
import {collectionData, orderBy, query} from "@angular/fire/firestore";
import {map} from "rxjs/operators";
import {NahausBookingsAccount} from "../models/NahausBookingsAccount.interface";

@Injectable({providedIn: 'root'})
export class NahausBookingsAccountsCollectionResolver implements Resolve<NahausBookingsAccount[]> {

    constructor(private nahausBookingsAccountsModularService: NahausBookingsAccountsModularService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NahausBookingsAccount[]> {
        const queryConstraintList: QueryConstraint[] = [];
        queryConstraintList.push(orderBy('refID', 'asc'));
        const collectionQuery = this.nahausBookingsAccountsModularService.collection();
        const ref = query(collectionQuery, ...queryConstraintList);
        return collectionData(ref).pipe(take(1), map(x => x as NahausBookingsAccount[]));
    }
}
