import { createAction, props } from '@ngrx/store';
import { NahausTier, User } from '@users/models/user.interface';
import firebase from 'firebase/compat';

export const userReceivedFromFirebaseAuthentication = createAction(
  '[Auth] Receive user from firebase authentication',
  props<{ user: Partial<firebase.User> }>()
);

export const userReceivedFromFirestore = createAction(
  '[Auth] Receive user from firestore',
  props<{ user: User }>()
);

export const resetUserFirestore = createAction(
  '[Auth] Reset firestore user '
);

export const userNahausRoleReceived = createAction(
  '[Auth] Receive user role',
  props<{ role: NahausTier }>()
);

export const receivedCustomerID = createAction(
  '[Auth] Received customerID',
  props<{ customerID: string }>()
);

export const signOut = createAction(
  '[Auth] sign out'
);

export const redirectToSignOutRoute = createAction(
  '[Auth] redirect to sign out route'
);

export const bannedToUpgrade = createAction(
  '[Auth] user is banned to upgrade'
);

export const redirectToErrorPage426 = createAction(
  '[Auth] redirect banned user to 426'
);
