import {NgModule} from '@angular/core';
import {FuseUtilsService} from '@fuse/services/utils-old/utils.service';

@NgModule({
  providers: [
    FuseUtilsService
  ]
})
export class FuseUtilsModule {
  /**
   * Constructor
   */
  constructor(private _fuseUtilsService: FuseUtilsService) {
  }
}
