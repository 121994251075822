import firebase from 'firebase/compat';
import {NahausImage} from './image.interface';
import Timestamp = firebase.firestore.Timestamp;

export interface Person {
  id?: string;
  customerID?: string;
  avatar?: NahausImage; // url or asset path
  title?: ContactDataTitle;
  birthday?: Date | Timestamp;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  email?: string;
}

export enum ContactDataTitle {
  'M' = 'M',
  'F' = 'F',
  'DR' = 'DR',
  'PROF' = 'PROF',
  'DRPROF' = 'DRPROF',
}


export interface Email {
  value?: string;
  label?: string;
}

export interface PhoneNumber {
  value?: string;
  label?: string;
  country?: string;
}
