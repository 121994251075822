import { Injectable } from '@angular/core';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { Landlord, Landlords } from '../models';

@Injectable({
  providedIn: 'root'
})
export class LandlordsService extends AbstractFirestoreService<Landlord> {

  onListChanged$: BehaviorSubject<Landlords> = new BehaviorSubject<Landlords>(null);

  COLLECTION = 'landlords';

  constructor(authService: AuthService) {
    super(authService);
  }

  private _list: Landlords;

  set list(value: Landlords) {
    this._list = value;
    this.onListChanged$.next(value);
  }
}
