import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Host,
    HostListener,
    Inject,
    Input,
    OnInit,
    Optional,
    Output,
    SkipSelf,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    FormsModule,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {ErrorStateMatcher, MatOptionModule} from '@angular/material/core';
import {BaseWidget, NgAisIndex, NgAisInstantSearch} from 'angular-instantsearch';
import {connectAutocomplete} from 'instantsearch.js/es/connectors';
import {BankAccount} from '../../../../modules/admin/bankAccounts/models';
import {Bank} from '../../../../modules/admin/bankAccounts/models/bank.interface';
import {Institution} from '../../../../modules/admin/institutions/models/institution.interface';
import {Landlord} from '../../../../modules/admin/landlords/models';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {NgClass, NgFor, NgIf} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
    selector: 'app-admin-autocomplete-algolia-institutions',
    templateUrl: './autocomplete-algolia.component.html',
    styleUrls: ['./autocomplete-algolia.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutocompleteAlgoliaInstitutionsComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: AutocompleteAlgoliaInstitutionsComponent
        },
        {provide: ErrorStateMatcher, useExisting: AutocompleteAlgoliaInstitutionsComponent}
    ],
    standalone: true,
    imports: [MatFormFieldModule, NgClass, NgIf, MatInputModule, FormsModule, MatAutocompleteModule, ReactiveFormsModule, NgFor, MatOptionModule, MatButtonModule, MatTooltipModule, MatIconModule]
})
export class AutocompleteAlgoliaInstitutionsComponent extends BaseWidget implements OnInit, ControlValueAccessor, Validator {

  @ViewChild('query') inputElement: ElementRef;

  @HostListener('input', ['$event.target.value']) onChange = (_: Institution) => {
  };

  @Input() emphasized: boolean;
  @Input() label: string = 'Bank Institut';
  @Input() clearIconTooltip: string = 'Zurücksetzen';
  @Input() showHint: boolean = true;
    @Input() hintText: string = 'Unser System integriert nahtlos die Mehrheit der deutschen Bankinstitute, um unseren Nutzern eine umfassende und effiziente Erfahrung zu bieten.';
  @Input() errorText: string = 'Bitte wählen Sie Ihre Bank aus';

  state: {
    query: string;
    refine: Function;
    indices: object[];
  };

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onQuerySuggestionClick = new EventEmitter<Institution | null>();
  @Output() focus = new EventEmitter<void>();

  onTouched = () => {
  };


  @Input() formControlName: string;

  control: FormControl;

  touched = false;

  disabled = false;

  private _value: Institution;

  readonly errorStateMatcher: ErrorStateMatcher = {
    isErrorState: (ctrl: FormControl) => {
      // console.log('isErrorState --> ', (ctrl && ctrl.invalid));
      return (ctrl && ctrl.invalid);
      // return true;
    }
  };

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch,
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
    private cf: ChangeDetectorRef) {
    super('AutocompleteComponent');
    this.onQuerySuggestionClick.subscribe((value) => console.log('onQuerySuggestionClick --> ', value));
  }

  ngOnInit() {
    this.createWidget(connectAutocomplete, {});
    super.ngOnInit();

    if (this.controlContainer) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        console.log('this.control = ', this.control);
        const controlValue = this.control.value;
        // this.value = controlValue;
        console.log('already controlValue --> ', controlValue);
        if (controlValue) {
          // this.control.patchValue(controlValue?.bank);
          this.inputElement.nativeElement.value = 'test me';

        }
      } else {
        console.warn('Missing FormControlName directive from host element of the component');
      }
    } else {
      console.warn('Can\'t find parent FormGroup directive');
    }
  }

  set value(value: Institution) {
    this._value = value;
    // this.onChange(this._value);
  }

  get value(): Institution {
    return this._value;
  }

  handleChange($event: KeyboardEvent) {
    this.state.refine(($event.target as HTMLInputElement).value);
  }

  trackByFn(index: number, landlord: Landlord) {
    return landlord.id; // or item.id
  }

  /**
   * The writeValue method is called by the Angular forms module whenever the parent form wants to set a value in the child control.
   * @param value
   */
  writeValue(value: Institution | BankAccount): void {
    if (!this.value) {
      const name = (value as BankAccount)?.bank;
      setTimeout(() => this.inputElement.nativeElement.value = name || '', 0);
    }

    // @ts-ignore
    if (value?.bank as BankAccount) {
      this.value = this.fromBank(value);
    } else {
      this.value = value as Institution;
    }
    if (value?.id) {
      this.setDisabledState(true);
    }
    this.cf.markForCheck();
    this.cf.detectChanges();
  }

  registerOnChange(fn: any): void {
    // this.onChange = fn;
    this.onChange = (value: Institution | string) => {
      if (!value) {
        this.clear(false);
        return fn(null);
      }
      // @ts-ignore
      if (value?.id as Institution) {
        fn(this.toBank(value as Institution));
      } else {
        fn(this.toBank(this.value ? Object.assign(this.value, { name: (value as string) } as Institution) : { name: (value as string) } as Institution));
      }
    };
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  select(institution: Institution | null, emit = true) {
    console.log('select --> ', institution);
    this.value = institution || null;
    this.markAsTouched();
    if (emit) {
      this.onChange(this.value);
    }
    if (institution?.id) {
      // this.setDisabledState(true);
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    console.log('on validate --> ', control, control.status);
    const isNotValid = !this.value?.id;
    // return this.value?.id ? null : { required: true };
    return isNotValid && { invalid: true };
  }

  clear(emit = true) {
    console.log('clear()');
    this.select(null, emit);
    this.state.refine();
    // this.setDisabledState(false);
  }

  toBank(institution: Institution): Bank {
    return {
      institutionID: institution?.id,
      bank: institution?.name || '',
      img: institution?.logo,
      BIC: institution?.bic
    } as Bank;
  }

  fromBank(bank: BankAccount): Institution {
    return {
      id: bank?.institutionID,
      name: bank?.bank || '',
      logo: bank?.img
    } as Institution;
  }
}
