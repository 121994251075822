import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { CustomPortalLinkParams } from '@customers/models/customerPortalLinksParams.interace';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';
import 'firebase/functions';
import { firstValueFrom, Observable } from 'rxjs';
import { NahausSubscription, UpgradeSubscriptionData } from '../models/nahaus-subscription.interface';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService extends AbstractFirestoreService<NahausSubscription> {

  COLLECTION = 'subscriptions';

  constructor(authService: AuthService,
              private fns: AngularFireFunctions) {
    super(authService);
  }

  async sendToCustomerPortal(returnUrl = window.location.origin + '/abonnement'): Promise<any> {
    const callable = this.fns.httpsCallable('ext-firestore-stripe-payments-createPortalLink');
    const data = await firstValueFrom(callable({ returnUrl }));
    // const { data } = await callable({ returnUrl: window.location.origin });
    console.log('httpsCallable result --> ', data);
    window.location.assign(data?.url);
    return data;
  }

  async sendToCustomCustomerPortal(params: CustomPortalLinkParams): Promise<any> {
    const callable = this.fns.httpsCallable('stripe-createCustomPortalLink');
    const data = await firstValueFrom(callable(params));
    // const { data } = await callable({ returnUrl: window.location.origin });
    console.log('httpsCallable result --> ', data);
    window.location.assign(data?.url);
    return data;
  }

  async getCustomerObject(): Promise<any> {
    const callable = this.fns.httpsCallable('stripe-getCustomerObject');
    const data = await firstValueFrom(callable({ customer: this.authService?.customerID }));
    console.log('httpsCallable getCustomerObject result --> ', data);
    return data;
  }

  upgrade(data: UpgradeSubscriptionData): Observable<any> {
    const callable = this.fns.httpsCallable('subscriptionsPool-upgradeSubscription');
    return callable(data);
  }
}
