<div class="relative flex flex-col w-full h-full">

    <!-- Dismiss button -->
    <div class="absolute top-0 right-0 pt-4 pr-4">
        <button
            (click)="this.close()"
            mat-icon-button>
            <mat-icon
                [svgIcon]="'heroicons_outline:x'"
                class="text-secondary"></mat-icon>
        </button>
    </div>

    <!-- Content -->
    <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">

        <!-- Icon -->
        <div
            class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600">
            <mat-icon
                class="text-current"
                svgIcon="mat_outline:warning"></mat-icon>
        </div>

        <div
            class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">

            <!-- Title -->
            <div class="text-xl leading-6 font-medium">
                Art der letzten Mahnung wählen
            </div>

            <!-- Message -->
            <div class="text-secondary">Wählen Sie die passende Option für das Mahnungsanschreiben:</div>

            <div class="w-full">
                <div class="w-full mt-4">
                    <mat-selection-list (selectionChange)="this.onDunningTypeSelectionChanged($event)"
                                        [multiple]="false">
                        <mat-list-option [value]="this.lastWarningTypeOptions.FINAL_WARNING"
                                         color="primary"
                                         togglePosition="before">
                            Letzte Mahnung
                        </mat-list-option>
                        <mat-list-option [value]="this.lastWarningTypeOptions.FINAL_WARNING_BEFORE_LEGAL_ACTION"
                                         color="primary"
                                         togglePosition="before">
                            Letzte Mahnung vor Mahnbescheid
                        </mat-list-option>
                        <mat-list-option [value]="this.lastWarningTypeOptions.FINAL_WARNING_BEFORE_TERMINATION"
                                         color="primary"
                                         togglePosition="before">
                            Letzte Mahnung vor Fristloskündigung
                        </mat-list-option>
                    </mat-selection-list>
                </div>
            </div>
        </div>

    </div>

    <!-- Actions -->
    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

        <!-- Cancel -->
        <button (click)="this.close()" mat-stroked-button>
            Abbrechen
        </button>

        <!-- Confirm -->
        <button (click)="this.close()"
                [disabled]="!this.lastWarningType"
                color="primary"
                mat-flat-button>
            Weiter
        </button>
    </div>

</div>
