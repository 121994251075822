import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Property } from '../../../../../../../models/property.interface';
import { PropertyUnit } from '../../../../../models/unit.interface';

@Injectable()
export class PropertyPreviousPeriodResolver implements Resolve<Property> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Property {
    console.log('PropertyPreviousPeriodResolver is now running...');
    console.log('route?.parent?.data --> ', route?.parent?.data);
    // const propertyFromRoute = this.route.snapshot.parent.data.property_and_unit.property as Property;
    // const unitFromRoute = this.route.snapshot.parent.data.property_and_unit.unit as PropertyUnit;
    const property = route?.parent?.data?.property_and_unit?.property as Property;

    return property;
  }
}
