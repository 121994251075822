import {Injectable} from '@angular/core';
import {NahausImage} from '@shared/models/image.interface';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';

@Injectable()
export class ExpensesImagesService extends AbstractFirestoreService<NahausImage> {

    COLLECTION = 'images';

    constructor(authService: AuthService) {
        super(authService);
    }

    setParentPath(expenseID: string): void {
        this.parentPath = `expenses/${expenseID}`;
    }

}
