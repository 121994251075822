import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TenantDetailsComponent } from '@tenants/components/tenant-details/tenant-details.component';
import { Observable } from 'rxjs';

@Injectable()
export class CanDeactivateTenantDetails implements CanDeactivate<TenantDetailsComponent> {
  canDeactivate(
    component: TenantDetailsComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Get the next route
    let nextRoute: ActivatedRouteSnapshot = nextState.root;
    while (nextRoute.firstChild) {
      nextRoute = nextRoute.firstChild;
    }

    // If the next state doesn't contain '/eigentuemer'
    // it means we are navigating away from the
    // contacts app
    if (!nextState.url.includes('/mietersuche')) {
      // Let it navigate
      return true;
    }

    // If we are navigating to another contact...
    if (nextRoute.paramMap.get('id')) {
      // Just navigate
      return true;
    }
    // Otherwise...
    else {
      // Close the drawer first, and then navigate
      return component.closeDrawer().then(() => {
        return true;
      });
    }
  }
}
