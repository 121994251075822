import {Component} from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MatListModule} from '@angular/material/list';

@Component({
    selector: 'app-export-bookings-type-bottom-sheet',
    templateUrl: './export-bookings-type-bottom-sheet.component.html',
    styleUrls: ['./export-bookings-type-bottom-sheet.component.scss'],
    standalone: true,
    imports: [MatListModule]
})
export class ExportBookingsTypeBottomSheetComponent {


    constructor(private _bottomSheetRef: MatBottomSheetRef<ExportBookingsTypeBottomSheetComponent>) {
    }

    dismiss(data: any): void {
        this._bottomSheetRef.dismiss(data);
    }
}
