import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatLineModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {
    AlgoliaAutocompleteMsaComponent
} from '@shared/algolia/components/algolia-autocomplete-msa/algolia-autocomplete-msa.component';
import {
    AlgoliaInstantSearchComponent
} from '@shared/algolia/components/algolia-instant-search/algolia-instant-search.component';
import {
    AutocompleteAlgoliaInstitutionsOnlySearchComponent
} from '@shared/algolia/components/autocomplete-algolia-institutions-only-search/autocomplete-algolia-institutions-only-search.component';
import {
    AutocompleteAlgoliaInstitutionsComponent
} from '@shared/algolia/components/autocomplete-algolia-institutions/autocomplete-algolia.component';
import {
    AutocompleteAlgoliaLandlordsComponent
} from '@shared/algolia/components/autocomplete-algolia-landlords/autocomplete-algolia.component';

import {NgAisModule} from 'angular-instantsearch';
import {AlgoliaAutocompleteComponent} from './components/algolia-autocomplete/algolia-autocomplete.component';
import {HitsListenerDirective} from './directives/hits-listener.directive';
import {NahausSearchBoxDirective} from './directives/nahaus-search-box.directive';
import {StatListenerDirective} from './directives/stat-listener.directive';
import {
    AlgoliaMultiAutocompleteComponent
} from "@shared/algolia/components/algolia-multi-autocomplete/algolia--multi-autocomplete.component";
import {NahausSharedPipesModule} from "@shared/pipes/nahaus-shared-pipes.module";
import {MatChipsModule} from "@angular/material/chips";
import {
    AlgoliaMultiAutocompleteSearchComponent
} from './components/algolia-multi-autocomplete-search/algolia--multi-autocomplete.component';

import { AlgoliaMultiMsaTenantSearchComponent } from './components/algolia-multi-msa-tenants-search/algolia--multi-msa-tenants.component';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgAisModule,
        MatBadgeModule,
        MatInputModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatLineModule,
        NahausSharedPipesModule,
        MatChipsModule,
        StatListenerDirective,
        HitsListenerDirective,
        NahausSearchBoxDirective,
        AlgoliaAutocompleteComponent,
        AlgoliaAutocompleteMsaComponent,
        AlgoliaInstantSearchComponent,
        AutocompleteAlgoliaLandlordsComponent,
        AutocompleteAlgoliaInstitutionsComponent,
        AutocompleteAlgoliaInstitutionsOnlySearchComponent,
        AlgoliaMultiAutocompleteComponent,
        AlgoliaMultiAutocompleteSearchComponent
    ],
    exports: [
        NgAisModule,
        StatListenerDirective,
        HitsListenerDirective,
        NahausSearchBoxDirective,
        AlgoliaAutocompleteComponent,
        AlgoliaAutocompleteMsaComponent,
        AlgoliaInstantSearchComponent,
        AutocompleteAlgoliaLandlordsComponent,
        AutocompleteAlgoliaInstitutionsComponent,
        AutocompleteAlgoliaInstitutionsOnlySearchComponent,
        AlgoliaMultiAutocompleteComponent,
        AlgoliaMultiAutocompleteSearchComponent
    ]
})
export class NahausAlgoliaSharedModule {
}
