import {Component, Input} from '@angular/core';
import {YouTubePlayerModule} from "@angular/youtube-player";

@Component({
    selector: 'nahaus-youtube-preview',
    templateUrl: './youtube-preview.component.html',
    styleUrls: ['./youtube-preview.component.scss'],
    standalone: true,
    imports: [
        YouTubePlayerModule
    ],
    providers: [YouTubePlayerModule]
})
export class YoutubePreviewComponent {

    @Input()
    videoID: string;


    constructor() {
        this._loadApi();
    }

    private _loadApi() {
        if (!window.YT) {
            // We don't need to wait for the API to load since the
            // component is set up to wait for it automatically.
            const script = document.createElement('script');
            script.src = 'https://www.youtube.com/iframe_api';
            document.body.appendChild(script);
        }
    }
}
