import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router, RouterLink, RouterOutlet} from '@angular/router';
import {environment} from '@env/environment';
import {fuseAnimations} from '@fuse/animations';
import {FuseNavigationService, FuseVerticalNavigationComponent} from '@fuse/components/navigation';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher-old';
import {selectRole} from '@nahausStore/reducers/app.reducer';
import {Store} from '@ngrx/store';
import {AuthService} from '@shared/services/auth.service';
import {NahausSubscription} from '@subscriptions/models/nahaus-subscription.interface';
import {SubscriptionsService} from '@subscriptions/services/subscriptions.service';
import {NahausTier, NahausTiersOptions, User} from '@users/models/user.interface';
import {NavigationService} from 'app/core/navigation/navigation.service';
import {Navigation} from 'app/core/navigation/navigation.types';
import {UserService} from 'app/core/user/user.service';
import firebase from 'firebase/compat';
import {BehaviorSubject, forkJoin, from, Subject, take, takeUntil} from 'rxjs';
import {MomentModule} from 'ngx-moment';
import {ToDatePipe} from '../../../../shared/pipes/to-date.pipe';
import {QuickChatComponent} from '../../../common/quick-chat/quick-chat.component';
import {MessagesComponent} from '../../../common/messages-old/messages.component';
import {ShortcutsComponent} from '../../../common/shortcuts-old/shortcuts.component';
import {SearchComponent} from '../../../common/search-old/search.component';
import {FuseFullscreenComponent} from '@fuse/components/fullscreen/fullscreen.component';
import {LanguagesComponent} from '../../../common/languages/languages.component';
import {FuseAlertComponent} from '@fuse/components/alert/alert.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {AsyncPipe, DatePipe, I18nPluralPipe, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';
import {UserComponent} from '../../../common/user/user.component';
import {
    FuseVerticalNavigationComponent as FuseVerticalNavigationComponent_1
} from '@fuse/components/navigation/vertical/vertical.component';
import {TranslocoModule} from '@ngneat/transloco';
import {FuseLoadingBarComponent} from '@fuse/components/loading-bar/loading-bar.component';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    standalone: true,
    imports: [FuseLoadingBarComponent, TranslocoModule, FuseVerticalNavigationComponent_1, UserComponent, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, MatButtonModule, MatIconModule, FuseAlertComponent, RouterLink, LanguagesComponent, FuseFullscreenComponent, SearchComponent, ShortcutsComponent, MessagesComponent, RouterOutlet, QuickChatComponent, AsyncPipe, DatePipe, I18nPluralPipe, ToDatePipe, MomentModule]
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall: boolean;
  navigation: Navigation;
  authUser: firebase.User;
  firebaseUser: User;

  isMasterUser: boolean;
  authUser$;
  role$;
  isSubscribedUser$: Subject<boolean>;
  tier$: BehaviorSubject<NahausTier>;
  env = environment;

  today = new Date();
  trialSubscription: NahausSubscription;
  customerObject: any;
  willTrialBeExtended: boolean;

  tiersOptions = NahausTiersOptions;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _navigationService: NavigationService,
    private _userService: UserService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _authService: AuthService,
    private store: Store,
    private subscriptionsService: SubscriptionsService
  ) {
    this.authUser$ = this._authService.authUser$;
    this.role$ = this.store.select((state: any) => selectRole(state?.app));
    this.store.select((state: any) => selectRole(state?.app)).subscribe((role) => console.log('on role received', role));
    this.isSubscribedUser$ = this._authService.isSubscribedUser$;
    this.tier$ = this._authService.tier$;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    this.isMasterUser = this._authService.isMasterUser();

    this.getTrialSubscription();
    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // Subscribe to the user service
    // this._userService.user$
    //   .pipe((takeUntil(this._unsubscribeAll)))
    //   .subscribe((user: User) => {
    //       this.user = user;
    //   });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {

        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });

    this._authService.firestoreUser$.pipe(takeUntil(this._unsubscribeAll)).subscribe(firebaseUser => {
      console.log('classy component -> firebaseUser ->', firebaseUser);
      this.firebaseUser = firebaseUser;
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  getTrialSubscription(): void {
    if (!environment.pool || !this._authService.isMasterUser()) {
      return;
    }
    forkJoin([
      // get trial sub
      this.subscriptionsService
        .collection(query => query.where('status', '==', 'trialing').limit(1))
        .get()
        .pipe(take(1)),
      from(this.subscriptionsService.getCustomerObject()).pipe(take(1))
    ]).subscribe(([result, customer]: [firebase.firestore.QuerySnapshot<NahausSubscription>, any]) => {
      if (!result.empty) {
        this.trialSubscription = result.docs[0]?.data() as NahausSubscription;
        this.subscriptionsService.authService.trialSubscription$.next(this.trialSubscription);
      }
      this.customerObject = customer;
      this.willTrialBeExtended = this.customerObject?.invoice_settings?.default_payment_method && !this.trialSubscription?.cancel_at_period_end;
    });
    // this.subscriptionsService
    //   .collection(query => query.where('status', '==', 'trialing').limit(1))
    //   .get()
    //   .pipe(take(1))
    //   .subscribe((result) => {
    // if (!result.empty) {
    //   this.trialSubscription = result.docs[0]?.data() as NahausSubscription;
    //   this.subscriptionsService.authService.trialSubscription$.next(this.trialSubscription);
    // }
    // });
  }
}
