import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appMouseHover]',
    exportAs: 'appMouseHover',
    standalone: true
})
export class MouseHoverDirective {

  isHover: boolean;

  constructor() {
  }

  @HostListener('mouseenter') onMouseEnter(): void {
    // console.log('mouseenter');
    this.isHover = true;
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    // console.log('mouseleave');
    this.isHover = false;
  }

}
