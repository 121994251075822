import {Injectable} from '@angular/core';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';
import {BehaviorSubject} from 'rxjs';
import {NahausTask, NahausTasks} from '../models/tasks.types';

@Injectable()
export class TasksService extends AbstractFirestoreService<NahausTask> {

    onListChanged$: BehaviorSubject<NahausTasks> = new BehaviorSubject<NahausTasks>(null);

    COLLECTION = 'tasks';

    constructor(authService: AuthService) {
        super(authService);
  }

    private _list: NahausTasks;

    set list(value: NahausTasks) {
        this._list = value;
        this.onListChanged$.next(value);
    }

    setParentPath(userID: string): void {
        this.parentPath = `users/${userID}`;
  }

    resetPath(): void {
        this.parentPath = null;
  }

}
