import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractFirestoreDocResolver } from '@shared/services/abstracts/AbstractFirestoreDocResolver';
import { ManagementUnitPeriod } from '../../properties/features/units/features/management/models/managementUnitPeriod';
import {
  ManagementPeriodsService
} from '../../properties/features/units/features/management/services/managementPeriods.service';


@Injectable()
export class ManagementByIDResolver extends AbstractFirestoreDocResolver<ManagementUnitPeriod> {

  constructor(route: ActivatedRoute, router: Router, service: ManagementPeriodsService) {
    // @ts-ignore
    super(router, service);
    this.paramKeyID = 'managementID';
    // this.parentPathBuilderArray = [{ path: 'properties' }, { paramKey: 'id' }];
    this.parentPathBuilderArray = [{ path: 'properties' }, {
      paramKey: 'id',
      deep: 1
    }, { path: 'units' }, { paramKey: 'unitID', deep: 1 }];
  }
}
