import {Injectable} from '@angular/core';
import {AbstractFirestoreModularService} from '@shared/services/abstracts/AbstractFirestoreModularService';
import {BehaviorSubject} from 'rxjs';
import {StatMsa} from '@msa/models';
import {Firestore} from '@angular/fire/firestore';
import {AuthService} from '@shared/services/auth.service';
import { IBoard } from '../models/scrumboard.types';

export interface ContactsRange {
    start: Date | null;
    end: Date | null;
}

@Injectable({
    providedIn: 'root'
})
export class BoardsModularService extends AbstractFirestoreModularService<IBoard> {

    COLLECTION = 'scrumboards';

    onRangeChanged$: BehaviorSubject<ContactsRange> = new BehaviorSubject<ContactsRange>(null);
    onStatsChanged: BehaviorSubject<StatMsa> = new BehaviorSubject<StatMsa>(null);

    constructor(authService: AuthService,
                firestore: Firestore) {
        super(authService, firestore);
    }
}
