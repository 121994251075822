import {Injectable} from '@angular/core';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';

@Injectable({providedIn: 'root'})
export class StatsService extends AbstractFirestoreService<any> {

    COLLECTION = 'stat';

    constructor(authService: AuthService) {
        super(authService);
    }
}
