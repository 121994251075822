import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractFirestoreDocResolver } from '@shared/services/abstracts/AbstractFirestoreDocResolver';
import { Property } from '../models/property.interface';
import { PropertiesService } from '../services/properties.service';


@Injectable()
export class PropertyByIdFromParentDeep1Resolver extends AbstractFirestoreDocResolver<Property> {

  constructor(route: ActivatedRoute, router: Router, service: PropertiesService) {
    super(router, service);
    this.paramKeyID = 'id';
    this.deep = 1;
  }
}
