import { Injectable } from '@angular/core';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';
import { Contract } from '../models/contract';

@Injectable()
export class ContractsService extends AbstractFirestoreService<Contract> {

  COLLECTION = 'contracts';

  constructor(authService: AuthService) {
    super(authService);
  }
}
