<ais-instantsearch [config]='config'>
    <ais-configure [searchParameters]="{ facets: ['customerID'] }"></ais-configure>

    <div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">

        <mat-drawer-container
            (backdropClick)="onBackdropClicked()"
            class="flex-auto h-full bg-card dark:bg-transparent">

            <!-- Drawer -->
            <mat-drawer
                #matDrawer
                [disableClose]="true"
                [mode]="drawerMode"
                [opened]="false"
                [position]="'end'"
                class="w-full md:w-160 dark:bg-gray-900">
                <router-outlet></router-outlet>
            </mat-drawer>

            <mat-drawer-content class="flex flex-col">

                <!-- Main -->
                <div class="flex-auto">

                    <div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden bg-card dark:bg-transparent">

                        <!-- Header -->
                        <div
                            class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
                            <!-- Loader -->
                            <div *ngIf="this.accessLayer !== this.bookingsAccessLayerOptions.PERIOD"
                                 class="flex shrink-0 items-center  sm:mx-4 mt-20">
                                 <button
                                 (click)="this.sideBadDrawer?.toggle()"
                                 mat-icon-button>
                                 <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
                                 </button>
                                 <h2
                                 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate ml-4">
                                 Ausgabenverwaltung
                             </h2>
                            </div>
                            <div
                                *ngIf="this.isLoading"
                                class="absolute inset-x-0 bottom-0">
                                <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
                            </div>
                            <!-- Title -->
                            <!--                <div class="text-4xl font-extrabold tracking-tight">Selbstauskunft</div>-->
                            <!-- Actions -->
                            <div class="flex flex-col w-full">
                                <div class="flex flex-col flex-auto mb-4">
                                    <div class="flex flex-col flex-auto">
                                        <div class="flex items-center space-x-4">
                                            <!-- Header -->
                                            <div class="flex items-center w-full">
                                                <div class="flex items-center flex-auto w-full" *ngIf="this.accessLayer !== this.bookingsAccessLayerOptions.PROPERTY">
                                                    <button
                                                        (click)="this.sideBadDrawer?.toggle()"
                                                        class="flex lg:hidden -ml-2"
                                                        mat-icon-button>
                                                        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
                                                    </button>
                                                    <!--                                                    <div class="flex w-full"-->
                                                    <!--                                                         *ngIf="this?.dataSource?.data?.length > 0 || this.hitsSource?.data?.length > 0">-->
                                                    <instant-search @slideInTop
                                                                    [disabled]="this.dataSource?.data?.length===0"
                                                                    [hits]="this.searchState?.nbHits"
                                                                    class="w-full sm:mt-0 sm:ml-4 mr-4"
                                                                    placeHolder="Suchen..."></instant-search>
                                                    <!--                                                    </div>-->
                                                </div>
                                            </div>
                                            <div class="flex shrink-0 space-x-2 items-center" *ngIf="this.accessLayer !== this.bookingsAccessLayerOptions.PROPERTY" >
                                                <button (click)="this.openYoutubeDialog(true)"
                                                        class="px-1 sm:px-4 min-w-10"
                                                        color="primary"
                                                        mat-stroked-button
                                                        matTooltip="Klicken Sie hier, um die Video-Anleitung zum Erfassen und Verwalten von Ausgaben zu sehen."
                                                        type="button">
                                                    <mat-icon
                                                        [svgIcon]="'mat_outline:ondemand_video'"
                                                        class="icon-size-5"></mat-icon>
                                                    <span class="hidden sm:inline-block ml-2">Video-Anleitung</span>
                                                </button>
                                                <a @slideInRight @slideOutLeft
                                                   [color]="'primary'"
                                                   [routerLink]="['/ausgaben','erfassen']"
                                                   class="px-1 sm:px-4 min-w-10"
                                                   mat-flat-button
                                                   matTooltip="Erstellen Sie ein individuelles Buchungskonto, um Ihre finanziellen Transaktionen gezielt zu kategorisieren und zu verwalten">
                                                    <mat-icon
                                                        [svgIcon]="'heroicons_solid:plus-circle'"
                                                        class="icon-size-5"></mat-icon>
                                                    <span class="hidden sm:inline-block ml-2">Hinzufügen</span>
                                                </a>
                                            </div>
                                            <div *ngIf="!this.selection?.isEmpty()" @slideInRight @slideOutLeft
                                                 class="flex flex-row space-x-4">
                                                <button @slideOutLeft
                                                        class="px-1 sm:px-4 min-w-10"
                                                        mat-stroked-button>
                                                    <mat-icon
                                                        [svgIcon]="'heroicons_solid:mail'"
                                                        class="icon-size-5"></mat-icon>
                                                    <span class="hidden sm:inline-block ml-2">Email</span>
                                                </button>
                                                <button
                                                    @slideInRight @slideOutLeft
                                                    [color]="'primary'"
                                                    [disabled]="this.creatingContract"
                                                    class="px-1 sm:px-4 min-w-10"
                                                    mat-flat-button>
                                                    <mat-icon *ngIf="!this.creatingContract"
                                                              [svgIcon]="'heroicons_solid:document-add'"
                                                              class="icon-size-5"></mat-icon>
                                                    <span *ngIf="!this.creatingContract"
                                                          class="hidden sm:inline-block ml-2">Create</span>
                                                    <mat-progress-spinner
                                                        *ngIf=this.creatingContract
                                                        [diameter]="24"
                                                        [mode]="'indeterminate'"
                                                        matTooltip="Bitte warten..."></mat-progress-spinner>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <ais-stats>
                                        <ng-template let-state='state'>
                                            <ng-template (onNewStat)='onNewStat($event)' [state]='state'
                                                         appStatListener></ng-template>
                                            <span *ngIf='this.state?.query?.length > 0'>{{this.state.nbHits}}
                                                Ergebnisse gefunden in {{this.state.processingTimeMS}}
                                                ms.</span>
                                        </ng-template>
                                    </ais-stats>
                                    <ais-hits>
                                        <ng-template let-hits='hits' let-results='results'>
                                            <ng-template (onNewHits)='onNewHits($event)' [hits]='hits'
                                                         appHitsListener></ng-template>
                                        </ng-template>
                                    </ais-hits>
                                </div>
                            </div>
                        </div>

                        <!-- Main -->
                        <div class="flex flex-auto overflow-hidden">

                            <!-- MSA list -->
                            <div class="flex flex-col flex-auto sm:mb-18 overflow-auto">

                                <ng-container *ngIf="this.dataSource?.data?.length > 0; else loading">

                                    <!-- Table wrapper -->
                                    <div
                                        cdkScrollable
                                        class="overflow-x-auto sm:overflow-y-auto">

                                        <ng-container
                                            *ngIf="!this.searchState?.query || (this.searchState?.query && this.hitsSource?.data?.length > 0); else checkExpenseExists">

                                            <!-- Table -->
                                            <table
                                                (matSortChange)='sortMsaData($event)'
                                                [dataSource]='this.searchState && this.searchState?.query ? this.hitsSource : this.dataSource'
                                                [matSortDisableClear]="true"
                                                [multiTemplateDataRows]="true"
                                                [ngClass]="{'pointer-events-none': this.isLoading}"
                                                [trackBy]="trackByFn"
                                                class="w-full min-w-200 table-fixed bg-transparent"
                                                mat-table
                                                matSort>

                                                <!-- Checkbox Column -->
                                                <ng-container matColumnDef="select">
                                                    <th *matHeaderCellDef
                                                        class="w-24 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                                        mat-header-cell>
                                                        <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                                                      [aria-label]="checkboxLabel()"
                                                                      [checked]="selection.hasValue() && isAllSelected()"
                                                                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                                      color="primary">
                                                        </mat-checkbox>
                                                    </th>
                                                    <td *matCellDef="let row; let i = index" mat-cell>
                                                        <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                                                                      (click)="$event.stopPropagation()"
                                                                      [aria-label]="checkboxLabel(row,i)"
                                                                      [checked]="selection.isSelected(row)"
                                                                      color="primary">
                                                        </mat-checkbox>
                                                    </td>
                                                </ng-container>

                                                <!-- refID-->
                                                <ng-container matColumnDef='nahausBookingsAccount.refID'>
                                                    <th *matHeaderCellDef
                                                        class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                                        disableClear
                                                        mat-header-cell
                                                        mat-sort-header
                                                        matTooltip="Das Buchungskonto für die Ausgabe">
                                                        Konto
                                                    </th>
                                                    <td
                                                        *matCellDef="let expense"
                                                        class="pr-8 truncate"
                                                        mat-cell>
                                                        <div class="flex flex-row space-x-4 truncate">
                                                            <div *ngIf="!!expense?.nahausBookingsAccount?.refID "
                                                                 class="px-2 bg-primary text-on-primary rounded">
                                                                <div
                                                                    *ngIf='this.searchState && this.searchState?.query; else data'>
                                                                    <p [innerHTML]="expense?._highlightResult?.nahausBookingsAccount?.refID?.value"
                                                                       class="text-truncate font-weight-600"></p>
                                                                </div>
                                                                <ng-template #data>
                                                                    <p class="text-truncate font-weight-600">{{ expense?.nahausBookingsAccount?.refID }} </p>
                                                                </ng-template>
                                                            </div>
                                                            <div [matTooltip]="expense?.nahausBookingsAccount?.label"
                                                                 class="flex truncate">

                                                                <p *ngIf='this.searchState && this.searchState?.query; else data'
                                                                   [innerHTML]="expense?._highlightResult?.nahausBookingsAccount?.label?.value"
                                                                   class="truncate"></p>
                                                                <ng-template #data>
                                                                    <p class="truncate">{{ expense?.nahausBookingsAccount?.label }}</p>
                                                                </ng-template>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </ng-container>

                                                <!-- date-->
                                                <ng-container matColumnDef='date'>
                                                    <th
                                                        *matHeaderCellDef
                                                        class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                                        disableClear
                                                        mat-header-cell
                                                        mat-sort-header
                                                        matTooltip="Das Datum, an dem die Ausgabe getätigt wurde">
                                                        Datum
                                                    </th>

                                                    <td
                                                        *matCellDef="let expense"
                                                        [matTooltip]="expense?.date | toDate |  date:'dd.MM.yy'"
                                                        class="pr-8 truncate"
                                                        mat-cell>
                                                        {{ expense?.date | toDate | amTimeAgo }}
                                                    </td>
                                                </ng-container>

                                                <!--                                            &lt;!&ndash; description&ndash;&gt;-->
                                                <!--                                            <ng-container matColumnDef='description'>-->
                                                <!--                                                <th-->
                                                <!--                                                    *matHeaderCellDef-->
                                                <!--                                                    class="bg-gray-50 dark:bg-black dark:bg-opacity-5"-->
                                                <!--                                                    mat-header-cell-->
                                                <!--                                                    matTooltip="Eine kurze Beschreibung oder der Zweck der Ausgabe">-->
                                                <!--                                                    Beschreibung-->
                                                <!--                                                </th>-->

                                                <!--                                                <td-->
                                                <!--                                                    *matCellDef="let expense"-->
                                                <!--                                                    class="pr-8 truncate"-->
                                                <!--                                                    mat-cell>-->
                                                <!--                                                    {{ expense?.description }}-->
                                                <!--                                                </td>-->
                                                <!--                                            </ng-container>-->

                                                <!-- amount-->
                                                <ng-container matColumnDef='amount'>
                                                    <th *matHeaderCellDef
                                                        class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                                        disableClear
                                                        mat-header-cell
                                                        mat-sort-header
                                                        matTooltip="Der Geldbetrag der Ausgabe">
                                                        Betrag
                                                    </th>
                                                    <td
                                                        *matCellDef="let expense"
                                                        class="pr-8 truncate"
                                                        mat-cell>
                                                        <div class="flex flex-row">
                                                            <div *ngIf="expense?.amount < 0" @zoomIn @zoomOut
                                                                 class="px-2 bg-warn text-on-warn rounded">
                                                                <p class="text-truncate font-weight-600">{{ expense?.amount | currency:'EUR' }} </p>
                                                            </div>
                                                            <div *ngIf="expense?.amount >= 0" @zoomIn @zoomOut
                                                                 class="px-2 bg-primary text-on-primary rounded">
                                                                <p class="text-truncate font-weight-600">{{ expense?.amount | currency:'EUR' }} </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </ng-container>

                                                <!-- date-->
                                                <ng-container matColumnDef='property.address.streetName'>
                                                    <th
                                                        *matHeaderCellDef
                                                        class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                                        disableClear
                                                        mat-header-cell
                                                        mat-sort-header
                                                    >
                                                        Immobilie
                                                    </th>

                                                    <td
                                                        *matCellDef="let expense"
                                                        class="pr-8 truncate"
                                                        mat-cell>
                                                        <div *ngIf="expense?.property; else noProperty"
                                                             class="flex flex-row space-x-2 items-center truncate">
                                                            <div *ngIf="expense?.property?.h_id"
                                                                 class="ml-2 px-2 bg-primary text-on-primary rounded">
                                                                <p class="text-truncate font-weight-600">
                                                                    H{{ expense?.property?.h_id }}</p>
                                                            </div>
                                                            <div *ngIf="expense?.property?.address"
                                                                 class="flex truncate">
                                                                <p class="truncate">{{ expense?.property?.address | formatAddress}}</p>
                                                            </div>
                                                        </div>
                                                        <ng-template #noProperty>
                                                            <div class="flex flex-row space-x-2 items-center">
                                                                <div class="ml-2 px-2 bg-warn text-on-warn rounded">
                                                                    <p class="text-truncate font-weight-600">Keine
                                                                        Zuordnung</p>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </td>
                                                </ng-container>


                                                <tr
                                                    *matHeaderRowDef="displayedColumns; sticky: true"
                                                    class="shadow"
                                                    mat-header-row></tr>
                                                <tr
                                                    (click)="goTo(expense)"
                                                    *matRowDef="let expense; columns: displayedColumns;"
                                                    [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !this.selected || this.selected?.id !== expense?.id,
                                                        'bg-primary-50 dark:bg-hover': this.selected && this.selected?.id === expense?.id}"
                                                    class="h-18 hover:bg-hover cursor-pointer"
                                                    mat-row></tr>

                                            </table>
                                        </ng-container>
                                    </div>

                                    <mat-paginator
                                        (page)='onPageChanged($event)'
                                        [disabled]="this.isLoading"
                                        [length]="this.page?.length"
                                        [ngClass]="{'pointer-events-none': this.isLoading}"
                                        [pageIndex]="this.page?.pageIndex"
                                        [pageSizeOptions]="[10, 25,50, 100]"
                                        [pageSize]="this.page?.pageSize"
                                        [showFirstLastButtons]="false"
                                        class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent">
                                    </mat-paginator>
                                </ng-container>

                                <ng-template #loading>
                                    <div *ngIf="!this.initialLoad; else noData"
                                         class="p-8 sm:p-16 flex justify-center items-center nodata-container h-full">
                                        <mat-spinner></mat-spinner>
                                    </div>
                                </ng-template>
                                <ng-template #checkExpenseExists>

                                    <ng-container *ngIf="this.expenseExists; else noData">

                                        <div class="flex flex-col h-full overflow-x-auto sm:overflow-y-auto">
                                            <div class="flex flex-col justify-center p-8 sm:p-16 h-full">
                                                <div class="flex justify-center">

                                                    <!-- Card #4 -->
                                                    <fuse-card
                                                        class="flex flex-col max-w-80 sm:max-w-100 w-full filter-article drop-shadow-xl">
                                                        <img
                                                            [src]="'assets/illustrations/segmentation/' + this.appTheme + '/undraw_segmentation.svg'"
                                                            alt="Card cover image"
                                                            class="object-cover">
                                                        <div class="m-8 mb-4">
                                                            <div class="text-2xl font-semibold leading-tight">
                                                                Keine Ausgaben vorhanden
                                                            </div>
                                                            <div
                                                                class="text-secondary text-md mt-1">
                                                                Es sieht so aus, als hätten Sie noch keine Ausgaben
                                                                erfasst.
                                                            </div>
                                                            <div class="mt-4">
                                                                Nutzen Sie den Button <span class="font-extrabold">Ausgaben erfassen</span>,
                                                                um neue Ausgaben hinzuzufügen.
                                                                Hier können Sie alle Ihre Immobilienausgaben detailliert
                                                                erfassen und verwalten.
                                                                Dies erleichtert die Nachverfolgung Ihrer Finanzen und
                                                                die Vorbereitung Ihrer Steuererklärung.
                                                            </div>
                                                            <div class="flex mt-4 -mx-3 justify-center">
                                                                <a [color]="'primary'" class="px-3 items-center"
                                                                   mat-raised-button
                                                                   routerLink="erfassen">
                                                                    <mat-icon [svgIcon]="'mat_outline:add_circle'"
                                                                              color="primary"></mat-icon>
                                                                    <span class="ml-2">Ausgaben erfassen</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </fuse-card>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-template>

                            </div>

                        </div>

                    </div>

                </div>

            </mat-drawer-content>

        </mat-drawer-container>

    </div>
</ais-instantsearch>
<!-- No expenses yet-->
<ng-template #noData>
    <!-- Content -->
    <div class="flex flex-col justify-center p-8 sm:p-16 h-screen">

        <fuse-card
            class="flex flex-col w-full filter-article drop-shadow-2xl">
            <div class="w-full h-48 sm:h-80 md:h-96 lg:h-128">

                <nahaus-youtube-preview videoID="tTtOVOhiSnQ"></nahaus-youtube-preview>
            </div>

        </fuse-card>
    </div>

</ng-template>

<!--for selection-->

<!--<a-->
<!--        class="z-20 flex items-center px-6 py-4 md:px-8 cursor-pointer border-b"-->
<!--        [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !selectedContact || selectedContact.id !== contact.id,-->
<!--                                                'bg-primary-50 dark:bg-hover': selectedContact && selectedContact.id === contact.id}"-->
<!--        [routerLink]="['./', contact.id]">-->
