import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, map} from 'rxjs';
import {IBoard} from '../models/scrumboard.types';
import {BoardService} from '../services/board.service';

@Injectable()
export class BoardResolver implements Resolve<IBoard> {

    constructor(private boardService: BoardService,
                private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IBoard> {
        const boardId = route.params['boardId'];
        return this.boardService.doc(boardId).get().pipe(map(
            doc => doc?.data() as IBoard
          ));
    }
}
