import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { filter, forkJoin, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(
    private _navigationService: NavigationService,
    private authService: AuthService
    // private _messagesService: MessagesService,
    // private _notificationsService: NotificationsService,
    // private _quickChatService: QuickChatService,
    // private _shortcutsService: ShortcutsService,
    // private _userService: UserService,
  ) {
    console.log('InitialDataResolver constructor');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    console.log('InitialDataResolver resolve');
    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([
      this._navigationService.get(),
      // this._messagesService.getAll(),
      // this._notificationsService.getAll(),
      // this._quickChatService.getChats(),
      // this._shortcutsService.getAll(),
      // this._userService.get(),
      this.authService.firestoreUser$.pipe(filter(user => !!user), take(1)),
      this.authService.quota$.pipe(filter(quota => !!quota), take(1)),
      this.authService.algoliaClient$.pipe(filter(algoliaClient => !!algoliaClient), take(1))
    ]);
  }
}
