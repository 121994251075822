/* eslint-disable */
export const categories = [
    {
        id: 'b899ec30-b85a-40ab-bb1f-18a596d5c6de',
        parentId: null,
        name: 'Sanitäranlagen',
        slug: 'sanitaeranlagen'
    },
    {
        id: '07986d93-d4eb-4de1-9448-2538407f7254',
        parentId: null,
        name: 'Schließanlagen',
        slug: 'schliessanlagen'
    },
    {
        id: 'ad12aa94-3863-47f8-acab-a638ef02a3e9',
        parentId: null,
        name: 'Elektrogeräte',
        slug: 'elektrogeraete'
    }
];
export const brands = [
    {
        id: 'e1789f32-9475-43e7-9256-451d2e3a2282',
        name: 'Acme',
        slug: 'acme'
    },
    {
        id: '61d52c2a-8947-4a2c-8c35-f36baef45b96',
        name: 'Bravo Brands',
        slug: 'bravo-brands'
    },
    {
        id: 'f9987124-7ada-4b93-bef7-35280b3ddbd7',
        name: 'Delta Designs',
        slug: 'delta-designs'
    },
    {
        id: '5913ee46-a497-41db-a118-ee506011529f',
        name: 'Echo Electronics',
        slug: 'echo-electronics'
    },
    {
        id: '2c4d98d8-f334-4125-9596-862515f5526b',
        name: 'Zeon',
        slug: 'zeon'
    }
];
export const tags = [
    {
        id: '167190fa-51b4-45fc-a742-8ce1b33d24ea',
        title: 'mens'
    },
    {
        id: '3baea410-a7d6-4916-b79a-bdce50c37f95',
        title: 'ladies'
    },
    {
        id: '8ec8f60d-552f-4216-9f11-462b95b1d306',
        title: 'unisex'
    },
    {
        id: '8837b93f-388b-43cc-851d-4ca8f23f3a61',
        title: '44mm'
    },
    {
        id: '8f868ddb-d4a2-461d-bc3b-d7c8668687c3',
        title: '40mm'
    },
    {
        id: '2300ac48-f268-466a-b765-8b878b6e14a7',
        title: '5 ATM'
    },
    {
        id: '0b11b742-3125-4d75-9a6f-84af7fde1969',
        title: '10 ATM'
    },
    {
        id: '0fc39efd-f640-41f8-95a5-3f1d749df200',
        title: 'automatic'
    },
    {
        id: '7d6dd47e-7472-4f8b-93d4-46c114c44533',
        title: 'chronograph'
    },
    {
        id: 'b1286f3a-e2d0-4237-882b-f0efc0819ec3',
        title: 'watch'
    }
];
export const vendors = [
    {
        id: '987dd10a-43b1-49f9-bfd9-05bb2dbc7029',
        name: 'Evel',
        slug: 'evel'
    },
    {
        id: '998b0c07-abfd-4ba3-8de1-7563ef3c4d57',
        name: 'Mivon',
        slug: 'mivon'
    },
    {
        id: '05ebb527-d733-46a9-acfb-a4e4ec960024',
        name: 'Neogen',
        slug: 'neogen'
    }
];
export const products = [
    {
        id: '7eb7c859-1347-4317-96b6-9476a7e2ba3c',
        category: 'b899ec30-b85a-40ab-bb1f-18a596d5c6de',
        name: 'Elegantes Wand-WC',
        description: 'Modernes, wandmontiertes WC mit minimalistischem Design für ein sauberes und platzsparendes Erscheinungsbild',
        tags: [
            '167190fa-51b4-45fc-a742-8ce1b33d24ea',
            '7d6dd47e-7472-4f8b-93d4-46c114c44533',
            '8837b93f-388b-43cc-851d-4ca8f23f3a61',
            '2300ac48-f268-466a-b765-8b878b6e14a7',
            'b1286f3a-e2d0-4237-882b-f0efc0819ec3'
        ],
        sku: 'ETV-2425',
        barcode: '8346201275534',
        brand: '61d52c2a-8947-4a2c-8c35-f36baef45b96',
        vendor: '998b0c07-abfd-4ba3-8de1-7563ef3c4d57',
        stock: 30,
        reserved: 5,
        cost: 450.18,
        basePrice: 220,
        taxPercent: 30,
        price: 520.8,
        weight: 0.61,
        thumbnail: 'assets/images/inventory/wc/1.png',
        images: [
            'assets/images/inventory/wc/1.png',
            'assets/images/inventory/wc/2.png',
            'assets/images/inventory/wc/3.png',
        ],
        active: true
    },
    {
        id: '00b0292f-3d50-4669-a0c4-7a9d85efc98d',
        category: '07986d93-d4eb-4de1-9448-2538407f7254',
        name: 'Komfortable Walk-In-Dusche',
        description: 'Geräumiges Duschvergnügen mit Glaswand und Regendusche für ein entspannendes Duscherlebnis',
        tags: [
            '3baea410-a7d6-4916-b79a-bdce50c37f95',
            '7d6dd47e-7472-4f8b-93d4-46c114c44533',
            '8f868ddb-d4a2-461d-bc3b-d7c8668687c3',
            '0b11b742-3125-4d75-9a6f-84af7fde1969',
            'b1286f3a-e2d0-4237-882b-f0efc0819ec3'
        ],
        sku: 'ATH-7573',
        barcode: '8278968055700',
        brand: '2c4d98d8-f334-4125-9596-862515f5526b',
        vendor: '05ebb527-d733-46a9-acfb-a4e4ec960024',
        stock: 37,
        reserved: 2,
        cost: 723.55,
        basePrice: 1686,
        taxPercent: 30,
        price: 2191.8,
        weight: 0.79,
        thumbnail: 'assets/images/inventory/wc/1.png',
        images: [
            'assets/images/inventory/wc/1.png',
            'assets/images/inventory/wc/2.png',
            'assets/images/inventory/wc/3.png',
        ],
        active: true
    },
    {
        id: '3f34e2fb-95bf-4f61-be28-956d2c7e4eb2',
        category: 'b899ec30-b85a-40ab-bb1f-18a596d5c6de',
        name: 'Freistehende Acryl-Badewanne',
        description: 'Luxuriöse Badewanne mit freistehendem Design und modernem Wasserhahn für ein stilvolles Badezimmer',
        tags: [
            '167190fa-51b4-45fc-a742-8ce1b33d24ea',
            '0fc39efd-f640-41f8-95a5-3f1d749df200',
            '8837b93f-388b-43cc-851d-4ca8f23f3a61',
            '2300ac48-f268-466a-b765-8b878b6e14a7',
            'b1286f3a-e2d0-4237-882b-f0efc0819ec3'
        ],
        sku: 'ADH-1921',
        barcode: '8808746892183',
        brand: 'e1789f32-9475-43e7-9256-451d2e3a2282',
        vendor: '987dd10a-43b1-49f9-bfd9-05bb2dbc7029',
        stock: 30,
        reserved: 3,
        cost: 390.63,
        basePrice: 950,
        taxPercent: 10,
        price: 1045,
        weight: 0.76,
        thumbnail: 'assets/images/inventory/bade_wanne/1.png',
        images: [
            'assets/images/inventory/bade_wanne/1.png',
            'assets/images/inventory/bade_wanne/2.png',
            'assets/images/inventory/bade_wanne/3.png',
        ],
        active: true
    },
    {
        id: '8fcce528-d878-4cc8-99f7-bd3451ed5405',
        category: 'b899ec30-b85a-40ab-bb1f-18a596d5c6de',
        name: 'Modernes Waschbecken mit Unterschrank',
        description: 'Elegantes Waschbecken mit praktischem Unterschrank und Spiegel für ein aufgeräumtes und modernes Bad',
        tags: [
            '167190fa-51b4-45fc-a742-8ce1b33d24ea',
            '7d6dd47e-7472-4f8b-93d4-46c114c44533',
            '8837b93f-388b-43cc-851d-4ca8f23f3a61',
            '0b11b742-3125-4d75-9a6f-84af7fde1969',
            'b1286f3a-e2d0-4237-882b-f0efc0819ec3'
        ],
        sku: 'EAP-7752',
        barcode: '8866355574164',
        brand: '61d52c2a-8947-4a2c-8c35-f36baef45b96',
        vendor: '987dd10a-43b1-49f9-bfd9-05bb2dbc7029',
        stock: 37,
        reserved: 4,
        cost: 395.37,
        basePrice: 839,
        taxPercent: 30,
        price: 1090.7,
        weight: 0.62,
        thumbnail: 'assets/images/inventory/w_s/1.png',
        images: [
            'assets/images/inventory/w_s/1.png',
            'assets/images/inventory/w_s/2.png',
            'assets/images/inventory/w_s/3.png',
            'assets/images/inventory/w_s/4.png',
        ],
        active: true
    },
    {
        id: '91d96e18-d3f5-4c32-a8bf-1fc525cb92c0',
        category: '07986d93-d4eb-4de1-9448-2538407f7254',
        name: 'Hochwertige Armaturen-Serie',
        description: 'Set aus modernem Wasserhahn und Brausekopf für ein harmonisches und funktionales Erscheinungsbild.',
        tags: [
            '3baea410-a7d6-4916-b79a-bdce50c37f95',
            '0fc39efd-f640-41f8-95a5-3f1d749df200',
            '8f868ddb-d4a2-461d-bc3b-d7c8668687c3',
            '2300ac48-f268-466a-b765-8b878b6e14a7',
            'b1286f3a-e2d0-4237-882b-f0efc0819ec3'
        ],
        sku: 'ADP-5745',
        barcode: '8390590339828',
        brand: 'e1789f32-9475-43e7-9256-451d2e3a2282',
        vendor: '05ebb527-d733-46a9-acfb-a4e4ec960024',
        stock: 12,
        reserved: 3,
        cost: 442.61,
        basePrice: 961,
        taxPercent: 20,
        price: 1153.2,
        weight: 0.67,
        thumbnail: 'assets/images/inventory/as/1.png',
        images: [
            'assets/images/inventory/as/1.png',
            'assets/images/inventory/as/2.png',
            'assets/images/inventory/as/3.png',
        ],
        active: true
    },
    {
        id: 'd7a47d7c-4cdf-4319-bbaa-37ade38c622c',
        category: 'b899ec30-b85a-40ab-bb1f-18a596d5c6de',
        name: 'Spiegelschrank mit LED-Beleuchtung',
        description: 'Praktischer Spiegelschrank mit integrierter Beleuchtung und Stauraum für ein übersichtliches und gut ausgeleuchtetes Badezimmer',
        tags: [
            '167190fa-51b4-45fc-a742-8ce1b33d24ea',
            '7d6dd47e-7472-4f8b-93d4-46c114c44533',
            '8837b93f-388b-43cc-851d-4ca8f23f3a61',
            '0b11b742-3125-4d75-9a6f-84af7fde1969',
            'b1286f3a-e2d0-4237-882b-f0efc0819ec3'
        ],
        sku: 'ATV-2569',
        barcode: '8238990048137',
        brand: 'e1789f32-9475-43e7-9256-451d2e3a2282',
        vendor: '987dd10a-43b1-49f9-bfd9-05bb2dbc7029',
        stock: 36,
        reserved: 2,
        cost: 563.43,
        basePrice: 1370,
        taxPercent: 30,
        price: 1781,
        weight: 0.62,
        thumbnail: 'assets/images/inventory/ss/1.png',
        images: [
            'assets/images/inventory/ss/1.png',
            'assets/images/inventory/ss/2.png',
        ],
        active: true
    },
    {
        id: 'ecf0b3df-38c3-45dc-972b-c509a3dc053e',
        category: 'b899ec30-b85a-40ab-bb1f-18a596d5c6de',
        name: 'Stilvoller Handtuchhalter mit mehreren Stangen',
        description: 'Eleganter Handtuchhalter aus Edelstahl für die platzsparende und ordentliche Aufbewahrung von Handtüchern',
        tags: [
            '167190fa-51b4-45fc-a742-8ce1b33d24ea',
            '7d6dd47e-7472-4f8b-93d4-46c114c44533',
            '8837b93f-388b-43cc-851d-4ca8f23f3a61',
            '0b11b742-3125-4d75-9a6f-84af7fde1969',
            'b1286f3a-e2d0-4237-882b-f0efc0819ec3'
        ],
        sku: 'EAH-2563',
        barcode: '8638426908385',
        brand: 'e1789f32-9475-43e7-9256-451d2e3a2282',
        vendor: '987dd10a-43b1-49f9-bfd9-05bb2dbc7029',
        stock: 35,
        reserved: 5,
        cost: 705.26,
        basePrice: 1721,
        taxPercent: 20,
        price: 2065.2,
        weight: 0.67,
        thumbnail: 'assets/images/inventory/sh/1.png',
        images: [
            'assets/images/inventory/sh/1.png',
        ],
        active: false
    },
    {
        id: '5765080a-aaee-40b9-86be-c18b9d79c73c',
        category: 'ad12aa94-3863-47f8-acab-a638ef02a3e9',
        name: 'Moderner Toilettenpapierhalter in mattem Schwarz',
        description: 'Schickes Design und praktische Handhabung für ein stilvolles und funktionales Detail im Badezimmer',
        tags: [
            '8ec8f60d-552f-4216-9f11-462b95b1d306',
            '0fc39efd-f640-41f8-95a5-3f1d749df200',
            '8f868ddb-d4a2-461d-bc3b-d7c8668687c3',
            '0b11b742-3125-4d75-9a6f-84af7fde1969',
            'b1286f3a-e2d0-4237-882b-f0efc0819ec3'
        ],
        sku: 'ATH-6399',
        barcode: '8881883828441',
        brand: 'e1789f32-9475-43e7-9256-451d2e3a2282',
        vendor: '05ebb527-d733-46a9-acfb-a4e4ec960024',
        stock: 17,
        reserved: 5,
        cost: 624.12,
        basePrice: 1448,
        taxPercent: 10,
        price: 1592.8,
        weight: 0.55,
        thumbnail: 'assets/images/inventory/tp/1.png',
        images: [
            'assets/images/inventory/tp/1.png',
            'assets/images/inventory/tp/2.png',
        ],
        active: false
    },
    {
        id: '6e71be88-b225-474c-91e5-111ced7d6220',
        category: '07986d93-d4eb-4de1-9448-2538407f7254',
        name: 'Hochwertiges Türschloss mit Zylinder',
        description: 'Einbruchhemmendes Türschloss mit Sicherheitszylinder für ein sicheres Zuhause',
        tags: [
            '3baea410-a7d6-4916-b79a-bdce50c37f95',
            '7d6dd47e-7472-4f8b-93d4-46c114c44533',
            '8f868ddb-d4a2-461d-bc3b-d7c8668687c3',
            '2300ac48-f268-466a-b765-8b878b6e14a7',
            'b1286f3a-e2d0-4237-882b-f0efc0819ec3'
        ],
        sku: 'ELH-2495',
        barcode: '8268777127281',
        brand: '5913ee46-a497-41db-a118-ee506011529f',
        vendor: '05ebb527-d733-46a9-acfb-a4e4ec960024',
        stock: 49,
        reserved: 5,
        cost: 738.91,
        basePrice: 1848,
        taxPercent: 30,
        price: 2402.4,
        weight: 0.54,
        thumbnail: 'assets/images/inventory/ts/1.png',
        images: [
            'assets/images/inventory/ts/1.png',
            'assets/images/inventory/ts/2.png',
        ],
        active: false
    },
    {
        id: '51242500-6983-4a78-bff3-d278eb4e3a57',
        category: 'b899ec30-b85a-40ab-bb1f-18a596d5c6de',
        name: 'Sicherungstürzylinder mit Schlüsselbund',
        description: 'Hochsicherheitszylinder mit mehreren Schlüsseln für optimalen Schutz vor unbefugtem Zugriff.',
        tags: [
            '167190fa-51b4-45fc-a742-8ce1b33d24ea',
            '0fc39efd-f640-41f8-95a5-3f1d749df200',
            '8837b93f-388b-43cc-851d-4ca8f23f3a61',
            '0b11b742-3125-4d75-9a6f-84af7fde1969',
            'b1286f3a-e2d0-4237-882b-f0efc0819ec3'
        ],
        sku: 'ATT-6019',
        barcode: '8452763551765',
        brand: 'f9987124-7ada-4b93-bef7-35280b3ddbd7',
        vendor: '998b0c07-abfd-4ba3-8de1-7563ef3c4d57',
        stock: 24,
        reserved: 4,
        cost: 688.89,
        basePrice: 1502,
        taxPercent: 8,
        price: 1622.16,
        weight: 0.76,
        thumbnail: 'assets/images/inventory/hz/1.png',
        images: [
            'assets/images/inventory/hz/1.png',
        ],
        active: true
    },
    {
        id: '844a4395-233f-4ffb-85bd-7baa0e490a88',
        category: 'b899ec30-b85a-40ab-bb1f-18a596d5c6de',
        name: 'Set moderner Türschlüssel',
        description: 'Verschiedene Schlüsselformen und -größen für ein flexibles Schließsystem.',
        tags: [
            '167190fa-51b4-45fc-a742-8ce1b33d24ea',
            '7d6dd47e-7472-4f8b-93d4-46c114c44533',
            '8837b93f-388b-43cc-851d-4ca8f23f3a61',
            '2300ac48-f268-466a-b765-8b878b6e14a7',
            'b1286f3a-e2d0-4237-882b-f0efc0819ec3'
        ],
        sku: 'ADH-2335',
        barcode: '8385907318041',
        brand: 'f9987124-7ada-4b93-bef7-35280b3ddbd7',
        vendor: '05ebb527-d733-46a9-acfb-a4e4ec960024',
        stock: 44,
        reserved: 3,
        cost: 708.41,
        basePrice: 1467,
        taxPercent: 18,
        price: 1731.06,
        weight: 0.7,
        thumbnail: 'assets/images/inventory/ms/1.png',
        images: [
            'assets/images/inventory/ms/1.png',
        ],
        active: false
    },
    {
        id: '7520f1b6-3c45-46ef-a4d5-881971212d1e',
        category: 'ad12aa94-3863-47f8-acab-a638ef02a3e9',
        name: 'Moderner Home-Safe mit elektronischem Schloss',
        description: 'Sicherer Aufbewahrungsort für Wertsachen mit komfortabler Bedienung per Zahlencode',
        tags: [
            '8ec8f60d-552f-4216-9f11-462b95b1d306',
            '0fc39efd-f640-41f8-95a5-3f1d749df200',
            '8f868ddb-d4a2-461d-bc3b-d7c8668687c3',
            '0b11b742-3125-4d75-9a6f-84af7fde1969',
            'b1286f3a-e2d0-4237-882b-f0efc0819ec3'
        ],
        sku: 'ATH-3064',
        barcode: '8608510561856',
        brand: 'e1789f32-9475-43e7-9256-451d2e3a2282',
        vendor: '998b0c07-abfd-4ba3-8de1-7563ef3c4d57',
        stock: 25,
        reserved: 2,
        cost: 731.94,
        basePrice: 1743,
        taxPercent: 10,
        price: 1917.3,
        weight: 0.47,
        thumbnail: 'assets/images/inventory/safe/1.png',
        images: [
            'assets/images/inventory/safe/1.png',
            'assets/images/inventory/safe/2.png'
        ],
        active: false
    },
    {
        id: '683e41d8-6ebc-4e6a-a7c1-9189ca52ef19',
        category: 'b899ec30-b85a-40ab-bb1f-18a596d5c6de',
        name: 'Edelstahl-Kühlschrank mit French-Door-Design',
        description: 'Elegantes und geräumiges Kühlgerät mit moderner Technologie und viel Stauraum',
        tags: [
            '167190fa-51b4-45fc-a742-8ce1b33d24ea',
            '7d6dd47e-7472-4f8b-93d4-46c114c44533',
            '8837b93f-388b-43cc-851d-4ca8f23f3a61',
            '0b11b742-3125-4d75-9a6f-84af7fde1969',
            'b1286f3a-e2d0-4237-882b-f0efc0819ec3'
        ],
        sku: 'ADV-3188',
        barcode: '8334758988643',
        brand: '2c4d98d8-f334-4125-9596-862515f5526b',
        vendor: '987dd10a-43b1-49f9-bfd9-05bb2dbc7029',
        stock: 14,
        reserved: 5,
        cost: 375.76,
        basePrice: 786,
        taxPercent: 30,
        price: 1021.8,
        weight: 0.53,
        thumbnail: 'assets/images/inventory/kh/1.png',
        images: [
            'assets/images/inventory/kh/1.png',
            'assets/images/inventory/kh/2.png'
        ],
        active: true
    },
    {
        id: 'd4e52238-292d-462b-b9bb-1751030132e2',
        category: 'ad12aa94-3863-47f8-acab-a638ef02a3e9',
        name: 'Induktionsherd mit Backofen',
        description: 'Modernes Kochfeld mit präziser Steuerung und energieeffizientem Backofen für komfortables Kochen und Backen.',
        tags: [
            '8ec8f60d-552f-4216-9f11-462b95b1d306',
            '7d6dd47e-7472-4f8b-93d4-46c114c44533',
            '8f868ddb-d4a2-461d-bc3b-d7c8668687c3',
            '2300ac48-f268-466a-b765-8b878b6e14a7',
            'b1286f3a-e2d0-4237-882b-f0efc0819ec3'
        ],
        sku: 'ATT-7423',
        barcode: '8417153336369',
        brand: 'f9987124-7ada-4b93-bef7-35280b3ddbd7',
        vendor: '998b0c07-abfd-4ba3-8de1-7563ef3c4d57',
        stock: 33,
        reserved: 2,
        cost: 743.93,
        basePrice: 1793,
        taxPercent: 8,
        price: 1936.44,
        weight: 0.86,
        thumbnail: 'assets/images/inventory/bo/1.png',
        images: [
            'assets/images/inventory/bo/1.png',
            'assets/images/inventory/bo/2.png'
        ],
        active: false
    },
    {
        id: '98861dfc-0d21-4fd5-81aa-49785d003d95',
        category: 'b899ec30-b85a-40ab-bb1f-18a596d5c6de',
        name: 'Einbaubackofen mit Pyrolysefunktion',
        description: 'Innovativer Backofen mit Selbstreinigungsfunktion für mühelose Reinigung und perfekte Ergebnisse.',
        tags: [
            '167190fa-51b4-45fc-a742-8ce1b33d24ea',
            '0fc39efd-f640-41f8-95a5-3f1d749df200',
            '8837b93f-388b-43cc-851d-4ca8f23f3a61',
            '0b11b742-3125-4d75-9a6f-84af7fde1969',
            'b1286f3a-e2d0-4237-882b-f0efc0819ec3'
        ],
        sku: 'AAT-6453',
        barcode: '8501386761670',
        brand: '5913ee46-a497-41db-a118-ee506011529f',
        vendor: '987dd10a-43b1-49f9-bfd9-05bb2dbc7029',
        stock: 38,
        reserved: 3,
        cost: 364.64,
        basePrice: 806,
        taxPercent: 18,
        price: 951.08,
        weight: 0.59,
        thumbnail: 'assets/images/inventory/i/1.png',
        images: [
            'assets/images/inventory/i/1.png',
            'assets/images/inventory/i/2.png',
            'assets/images/inventory/i/3.png'
        ],
        active: false
    },
    {
        id: 'a71f9b10-e884-4aad-9810-29fe10ce6d42',
        category: '07986d93-d4eb-4de1-9448-2538407f7254',
        name: 'Einbauspüler in Edelstahl-Optik',
        description: 'Leises und effizientes Geschirrspülgerät mit modernem Design und vielfältigen Programmen',
        tags: [
            '3baea410-a7d6-4916-b79a-bdce50c37f95',
            '7d6dd47e-7472-4f8b-93d4-46c114c44533',
            '8f868ddb-d4a2-461d-bc3b-d7c8668687c3',
            '2300ac48-f268-466a-b765-8b878b6e14a7',
            'b1286f3a-e2d0-4237-882b-f0efc0819ec3'
        ],
        sku: 'AAP-4902',
        barcode: '8847387136582',
        brand: 'f9987124-7ada-4b93-bef7-35280b3ddbd7',
        vendor: '987dd10a-43b1-49f9-bfd9-05bb2dbc7029',
        stock: 40,
        reserved: 3,
        cost: 525.3,
        basePrice: 1303,
        taxPercent: 10,
        price: 1433.3,
        weight: 0.69,
        thumbnail: 'assets/images/inventory/es/1.png',
        images: [
            'assets/images/inventory/es/1.png',
        ],
        active: false
    },
];
