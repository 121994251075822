<div class="relative flex flex-col w-full h-full">

    <div class="absolute top-0 right-0 pt-4 pr-4">
        <button
            [matDialogClose]="undefined"
            mat-icon-button>
            <mat-icon
                [svgIcon]="'heroicons_outline:x'"
                class="text-secondary"></mat-icon>
        </button>
    </div>

    <!-- Content -->
    <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">

        <!-- Icon -->
        <div
            [ngClass]="{'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600': !this.showError,
                            'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600': this.showError
                            }"
            class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full">
            <mat-icon
                [svgIcon]="'mat_outline:payments'"
                class="text-current"></mat-icon>
        </div>

        <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 text-center sm:text-left">

            <!--            <ng-container *ngIf="!this.loading; else showLoading">-->

            <div class="flex flex-col space-y-1 w-full">

                <!-- Title -->
                <div class="text-xl leading-6 font-medium">
                    <span>{{ this.booking?.type === this.bookingTypeOptions.PAYOUT ? 'Auszahlungserfassung' : 'Zahlungserfassung'}}</span>
                </div>

                <!-- Message -->
                <div class="text-secondary mb-4">Daten der Sollstellung</div>

                <div class="flex flex-row justify-between my-2">
                    <div class="text-secondary font-semibold">Buchung:</div>
                    <div class="text-secondary text-right">{{this.booking?.text}}</div>
                </div>

                <div class="flex flex-row justify-between my-2">
                    <div class="text-secondary font-semibold">Datum</div>
                    <div class="text-secondary text-right">{{this.booking?.date | toDate | date:'shortDate'}}</div>
                </div>

                <div class="flex flex-row justify-between my-2">
                    <div class="text-secondary font-semibold">Betrag</div>
                    <div class="text-secondary text-right">{{this.booking?.amount | currency:'EUR'}}</div>
                </div>

                <div class="flex flex-row justify-between my-2">
                    <div class="text-secondary font-semibold">Saldo</div>
                    <span
                        [ngClass]="{'text-warn': booking?.saldo < 0, 'text-primary': booking?.saldo > 0, 'font-extrabold': booking?.saldo !== 0}"
                        class="text-secondary text-righ"><span *ngIf="booking?.saldo > 0"
                                                               class="font-extrabold text-primary">+</span>{{booking?.saldo | currency:'EUR'}}</span>
                </div>

                <div class="w-full my-8">
                    <mat-divider class="w-full my-4"></mat-divider>
                </div>

                <div class="text-secondary mb-4">Daten
                    der {{ this.booking?.type === this.bookingTypeOptions.PAYOUT ? 'Auszahlungen' : 'Zahlungen'}}</div>

            </div>

            <div class="flex flex-col mt-6 space-y-6 w-full">
                <ng-container *ngIf="!this.loading; else displayLoading">
                    <form *ngIf="!this.loadingBookingsPayments;else displayLoading"
                          [formGroup]="bookingPaymentFormGroup">
                        <ng-container
                            *ngFor="let payment of this.bookingPaymentFormGroup.get('payments')['controls']; let i = index; let last = last; let first = first"
                            formArrayName='payments'>
                            <fuse-card [formGroupName]='i' class="w-full p-4 my-4 drop-shadow-xl">
                                <div class="flex flex-col space-y-4">
                                    <div class="flex flex-row space-x-2 items-center">
                                        <div class="flex flex-col">
                                            <div @zoomIn
                                                 class="px-2 bg-black text-white rounded font-semibold">
                                                {{ this.booking?.type === this.bookingTypeOptions.PAYOUT ? 'Auszahlung' : 'Zahlung'}} {{i + 1}}
                                            </div>
                                        </div>
                                        <a *ngIf="payment.get('transactionID')?.value as transactionID"
                                           [routerLink]="'/transaktionen/alle/' + transactionID" color="accent"
                                           matTooltip="Zahlung erfasst über eine Bank Transaktion"
                                           mat-icon-button
                                           target="_blank">
                                            <mat-icon
                                                svgIcon="mat_outline:account_balance"></mat-icon>
                                        </a>
                                    </div>
                                    <div class="grid sm:grid-cols-4 gap-4 w-full">

                                        <div class="sm:col-span-2">
                                            <mat-form-field class="w-full">
                                                <mat-label>Betrag</mat-label>
                                                <input
                                                    [options]="{ prefix: '€', thousands: '.', decimal: ',',align: 'left'}"
                                                    [disableControl]="!!payment.get('transactionID')?.value"
                                                    currencyMask
                                                    formControlName="amount"
                                                    matInput
                                                    name="soll.amount">
                                                <!--						<mat-hint>Geben Sie den Betrag ein, der in der Sollstelle gebucht werden soll.</mat-hint>-->
                                                <mat-error>Bitte geben Sie einen gültigen Betrag ein</mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <mat-form-field class="w-full">
                                                <mat-label>Datum der Zahlung</mat-label>
                                                <input [matDatepicker]="datePicker"
                                                       [placeholder]="'Datum hier eingeben'"
                                                       [disableControl]="!!payment.get('transactionID')?.value"
                                                       formControlName="date"
                                                       matInput
                                                       name="date">
                                                <mat-datepicker-toggle
                                                    [for]="datePicker"
                                                    matSuffix>
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #datePicker>
                                                </mat-datepicker>
                                                <!--						<mat-hint>Wählen Sie das Datum der Zahlung aus</mat-hint>-->
                                                <mat-error>Bitte wählen Sie ein gültiges Datum</mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div
                                            *ngIf="this.booking?.type === this.bookingTypeOptions.PAYOUT && payment.get('amount')?.value > 0"
                                            @zoomIn
                                            @zoomOut class="sm:col-span-4">
                                            <!-- Warning -->
                                            <fuse-alert [type]="'warning'">
                                                <span fuseAlertTitle>Achtung</span>
                                                Für Auszahlungen muss der Betrag negativ sein. Bitte korrigieren Sie den
                                                eingegebenen Betrag.
                                            </fuse-alert>
                                        </div>

                                        <div
                                            *ngIf="this.booking?.type === this.bookingTypeOptions.PAYMENT && payment.get('amount')?.value < 0"
                                            @zoomIn
                                            @zoomOut class="sm:col-span-4">
                                            <!-- Warning -->
                                            <fuse-alert [type]="'warning'">
                                                <span fuseAlertTitle>Achtung</span>
                                                Für Einzahlungen muss der Betrag positiv sein. Bitte korrigieren Sie den
                                                eingegebenen Betrag.
                                            </fuse-alert>
                                        </div>

                                        <div class="sm:col-span-4">
                                            <mat-form-field class="w-full">
                                                <mat-label>Buchungstext</mat-label>
                                                <input #textInput
                                                       [placeholder]="'Miete ' + this.today | date:'MM/yyyy'"
                                                       formControlName="text" matInput
                                                       maxlength="50">
                                                <mat-hint *ngIf="!(textInput?.value?.length > 0)">Geben Sie eine kurze
                                                    Beschreibung für die Zahlung ein (maximal 50 Zeichen)
                                                </mat-hint>
                                                <mat-hint *ngIf="textInput?.value?.length > 0"
                                                          align="end">{{textInput?.value?.length}}
                                                    / 50
                                                </mat-hint>
                                                <mat-error>Bitte geben Sie eine kurze Beschreibung für die Zahlung ein
                                                    (maximal
                                                    50
                                                    Zeichen)
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="flex flex-row space-x-2">
                                        <button (click)="this.removePayment(i)"
                                                @zoomIn
                                                [disabled]="this.deletingPayment"
                                                class="mt-4"
                                                color="warn"
                                                mat-mini-fab
                                                matTooltip="Zahlung löschen">
                                            <mat-icon *ngIf="!this.isPaymentBeingRemoved(i)"
                                                      svgIcon="mat_outline:delete"></mat-icon>
                                            <mat-progress-spinner
                                                *ngIf="this.isPaymentBeingRemoved(i)"
                                                [diameter]="24"
                                                [mode]="'indeterminate'"
                                                matTooltip="Bitte warten..."></mat-progress-spinner>
                                        </button>
                                        <button (click)="this.addPayment()"
                                                *ngIf="last"
                                                @zoomIn
                                                class="mt-4"
                                                color="accent"
                                                mat-mini-fab
                                                matTooltip="Weitere Zahlungen erfassen">
                                            <mat-icon svgIcon="mat_outline:add"></mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </fuse-card>
                        </ng-container>
                    </form>

                    <fuse-alert *ngIf="this.showError"
                                [appearance]="'outline'"
                                [type]="'error'"
                                class="w-full">
                        <span fuseAlertTitle>Fehler</span>
                        <div class="flex flex-col space-y-2">
                            <p>Leider ist einen Fehler aufgetreten! Bitte versuchen Sie später noch einmal!</p>
                            <p>{{this.errorMsg}}</p>
                        </div>
                    </fuse-alert>

                </ng-container>
                <ng-template #displayLoading>
                    <div class="flex flex-row w-full justify-center">
                        <mat-progress-spinner [diameter]="48" mode="indeterminate"></mat-progress-spinner>
                    </div>
                </ng-template>
            </div>
            <!--            </ng-container>-->
        </div>


    </div>

    <!-- Actions -->
    <div *ngIf="!this.loading" @zoomIn @zoomOut
         class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

        <!-- Cancel -->
        <button
            [matDialogClose]="'cancelled'"
            mat-stroked-button>
            Abbrechen
        </button>

        <!-- Confirm -->
        <button (click)="this.createBookingPayments()"
                [color]="this.showError | toColorPalette"
                [disabled]="this.saving"
                mat-flat-button>
            <span *ngIf="!this.saving">Speichern</span>
            <mat-progress-spinner
                *ngIf=this.saving
                [diameter]="24"
                [mode]="'indeterminate'"
                matTooltip="Bitte warten..."></mat-progress-spinner>
        </button>

    </div>

</div>
