import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Property } from '../../../../../models/property.interface';
import { PropertyUnit } from '../../../models/unit.interface';

@Injectable()
export class UnitFromParentRouteResolver implements Resolve<PropertyUnit> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): PropertyUnit {
    return route?.parent?.data?.property_and_unit?.unit as PropertyUnit;
  }
}
