import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import {CropperjsComponent} from '../cropperjs/cropperjs.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

export interface ImageEditorDialog {
  file: File;
  instructions: any;
}

@Component({
    selector: 'fuse-confirmation-dialog',
    templateUrl: './image-editor-dialog.component.html',
    styles: [
        `
      .fuse-confirmation-dialog-panel {
        @screen md {
          @apply w-128;
        }

        .mat-mdc-dialog-container {

          .mat-mdc-dialog-surface {
            padding: 0 !important;
          }
        }
      }
    `
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatButtonModule, MatDialogModule, MatIconModule, CropperjsComponent]
})
export class ImageEditorDialogComponent implements OnInit {
  /**
   * Constructor
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: ImageEditorDialog,
              private matDialogRef: MatDialogRef<ImageEditorDialogComponent>,
              private cf: ChangeDetectorRef) {
    console.log('data received --> ', data);
  }

  ngOnInit(): void {
    this.cf.markForCheck();
  }

  onAvatarCropped(file: File) {
    // this.matDialogRef.close(file);
    // this.files[this.files.length - 1].file = file;
    // this.onStartUpload();
    // this.avatarEditMode = false;
  }

  onCancelEdit() {
    // this.matDialogRef.close(undefined);
    // this.files = this.files.filter(f => f.status === this.fileStatus.Completed);
    // this.avatarEditMode = false;
    // this.concurrentUploadLimit++;
  }

}
