<div class="flex flex-col w-full">
    <div *ngIf="this.updating || this.loading">
        <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
    <!-- View mode -->
    <ng-container *ngIf="!this.editMode">

        <!-- Header -->
        <div class="relative w-full h-40 sm:h-48 px-8 sm:px-12 bg-accent-100 dark:bg-accent-700">
            <!-- Background -->
            <ng-container>
                <img @zoomIn
                     @zoomOut [src]="'assets/illustrations/pay_online/'+ this.appTheme+'/undraw_pay_online.svg'"
                     class="absolute inset-0 object-center w-full h-full">
            </ng-container>
            <!-- Close button -->
            <div class="flex items-center justify-end w-full max-w-3xl mx-auto pt-6">
                <button
                    (click)="this.cancel()"
                    mat-mini-fab
                    matTooltip="Schließen">
                    <mat-icon
                        [svgIcon]="'heroicons_outline:x'"
                        class="text-white"></mat-icon>
                </button>
            </div>
        </div>

        <!-- Contact -->
        <div class="relative flex flex-col flex-auto items-center p-6 pt-0 sm:p-12 sm:pt-0">
            <div class="w-full max-w-3xl">

                <!-- Avatar and actions -->
                <div class="flex flex-auto items-end -mt-16">
                    <!-- Avatar -->
                    <div
                        class="flex items-center justify-center w-32 h-32 rounded-full overflow-hidden ring-4 ring-bg-card">
                        <!--                        <img *ngIf="this.transaction?.bankAccount?.img"-->
                        <!--                             [src]="this.transaction?.bankAccount?.img"-->
                        <!--                             class="object-cover w-full h-full bg-white">-->
                        <!--                        <div *ngIf="!this.transaction?.bankAccount?.img"-->
                        <div
                            class="flex items-center justify-center w-full h-full rounded overflow-hidden uppercase text-8xl font-bold leading-none bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                            <span
                                *ngIf="this.expense?.nahausBookingsAccount?.label">{{this.expense?.nahausBookingsAccount?.label?.charAt(0)}}</span>
                        </div>
                    </div>
                    <!-- Actions  -->
                    <div class="flex items-center ml-auto mb-1">
                        <button
                            (click)="toggleEditMode(true)"
                            mat-stroked-button>
                            <mat-icon
                                [svgIcon]="'heroicons_solid:pencil-alt'"
                                class="icon-size-5"></mat-icon>
                            <span class="ml-2">Bearbeiten</span>
                        </button>
                    </div>
                </div>

                <!-- Name -->
                <div class="mt-3 text-4xl font-bold truncate">
                    {{ this.expense?.nahausBookingsAccount?.label }}
                </div>

                <!-- Target Tags -->
                <ng-container>
                    <div class="flex flex-wrap items-center mt-2">

                        <div
                            [ngClass]="{'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600': !!this.expense?.nahausBookingsAccount?.isAllocatable, 'text-warn-800 bg-warn-100 dark:text-warn-50 dark:bg-warn-500': !this.expense?.nahausBookingsAccount?.isAllocatable}"
                            class="flex space-x-2 items-center justify-center py-1 px-3 mr-3 mb-3 rounded-full leading-normal"
                            matTooltip="Ob die Ausgaben auf die Mieter umgelegt werden können.">
                            <div *ngIf="!this.expense?.nahausBookingsAccount?.isAllocatable" @slideInRight @slideOutLeft
                                 class="flex items-center">
                                <mat-icon class="icon-size-4.5" color="warn" svgIcon="mat_outline:close"></mat-icon>
                            </div>
                            <div *ngIf="!!this.expense?.nahausBookingsAccount?.isAllocatable" @slideInRight
                                 @slideOutLeft
                                 class="flex items-center">
                                <mat-icon class="icon-size-4.5" color="primary" svgIcon="mat_outline:check"></mat-icon>
                            </div>
                            <div class="flex items-center">
                                <span class="text-sm font-medium whitespace-nowrap mt-0.5">Umlagefähig</span>
                            </div>
                        </div>

                        <div
                            [ngClass]="{'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600': !!this.expense?.nahausBookingsAccount?.taxRelevant, 'text-warn-800 bg-warn-100 dark:text-warn-50 dark:bg-warn-500': !this.expense?.nahausBookingsAccount?.taxRelevant}"
                            class="flex space-x-2 items-center justify-center py-1 px-3 mr-3 mb-3 rounded-full leading-normal"
                            matTooltip="Ob die Ausgaben in dieser Kategorie steuerlich absetzbar sind">
                            <div *ngIf="!this.expense?.nahausBookingsAccount?.taxRelevant" @slideInRight @slideOutLeft
                                 class="flex items-center">
                                <mat-icon class="icon-size-4.5" color="warn" svgIcon="mat_outline:close"></mat-icon>
                            </div>
                            <div *ngIf="!!this.expense?.nahausBookingsAccount?.taxRelevant" @slideInRight @slideOutLeft
                                 class="flex items-center">
                                <mat-icon class="icon-size-4.5" color="primary" svgIcon="mat_outline:check"></mat-icon>
                            </div>
                            <div class="flex items-center">
                                <span class="text-sm font-medium whitespace-nowrap mt-0.5">Steuerrelevant</span>
                            </div>
                        </div>

                        <div
                            [ngClass]="{'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600': !!this.expense?.nahausBookingsAccount?.depreciationRelevant, 'text-warn-800 bg-warn-100 dark:text-warn-50 dark:bg-warn-500': !this.expense?.nahausBookingsAccount?.depreciationRelevant}"
                            class="flex space-x-2 items-center justify-center py-1 px-3 mr-3 mb-3 rounded-full leading-normal"
                            matTooltip="Ob die Ausgaben in dieser Kategorie für Abschreibungen relevant sind">
                            <div *ngIf="!this.expense?.nahausBookingsAccount?.depreciationRelevant" @slideInRight
                                 @slideOutLeft
                                 class="flex items-center">
                                <mat-icon class="icon-size-4.5" color="warn" svgIcon="mat_outline:close"></mat-icon>
                            </div>
                            <div *ngIf="!!this.expense?.nahausBookingsAccount?.depreciationRelevant" @slideInRight
                                 @slideOutLeft
                                 class="flex items-center">
                                <mat-icon class="icon-size-4.5" color="primary" svgIcon="mat_outline:check"></mat-icon>
                            </div>
                            <div class="flex items-center">
                                <span class="text-sm font-medium whitespace-nowrap mt-0.5">Abschreibungsrelevant</span>
                            </div>
                        </div>

                    </div>
                </ng-container>

                <div class="flex flex-col mt-4 pt-6 border-t space-y-8">

                    <!-- date -->
                    <ng-container *ngIf="this.expense?.date">
                        <div @slideInRight @slideOutLeft class="flex">
                            <mat-icon [svgIcon]="'mat_outline:event'"></mat-icon>
                            <div class="max-w-none ml-6 prose prose-sm">Datum: <span
                                class="font-extrabold">{{this.expense?.date | toDate | date:'shortDate' }}</span></div>
                        </div>
                    </ng-container>

                    <!-- amount -->
                    <ng-container *ngIf="this.expense?.amount">
                        <div @slideInRight @slideOutLeft class="flex">
                            <mat-icon [svgIcon]="'mat_outline:money'"></mat-icon>
                            <div class="max-w-none ml-6 prose prose-sm">Betrag: <span
                                class="font-extrabold">{{this.expense?.amount | currency:'EUR' }}</span> <span
                                *ngIf="this.expense?.includesVAT && this.expense?.vatRate"> (inkl. {{this.expense?.vatRate}}
                                % MwSt)</span></div>
                        </div>
                    </ng-container>

                    <!-- refID -->
                    <ng-container *ngIf="this.expense?.nahausBookingsAccount?.refID">
                        <div @slideInRight @slideOutLeft class="flex">
                            <mat-icon [svgIcon]="'mat_outline:confirmation_number'"></mat-icon>
                            <div class="max-w-none ml-6 prose prose-sm">Konto: <span
                                class="font-extrabold">{{this.expense?.nahausBookingsAccount?.refID}}</span></div>
                        </div>
                    </ng-container>

                    <!-- accountType -->
                    <ng-container *ngIf="this.expense?.nahausBookingsAccount?.accountType">
                        <div @slideInRight @slideOutLeft class="flex">
                            <mat-icon [svgIcon]="'mat_outline:account_tree'"></mat-icon>
                            <div
                                [innerHTML]="this.expense?.nahausBookingsAccount?.accountType"
                                class="max-w-none ml-6 prose prose-sm"></div>
                        </div>
                    </ng-container>

                    <!-- accountingYears -->
                    <ng-container *ngIf="this.expense?.accountingYears?.length > 0">
                        <div @slideInRight @slideOutLeft class="flex">
                            <mat-icon [svgIcon]="'mat_outline:event_note'"></mat-icon>
                            <div class="max-w-none ml-6 prose prose-sm">Abrechnungsjahre: <span
                                class="font-extrabold">{{this.expense?.accountingYears?.join(',') }}</span></div>
                        </div>
                    </ng-container>

                    <!-- service date -->
                    <ng-container *ngIf="this.expense?.serviceStartDate || this.expense?.serviceEndDate">
                        <div @slideInRight @slideOutLeft class="flex">
                            <mat-icon [svgIcon]="'mat_outline:date_range'"></mat-icon>
                            <div class="max-w-none ml-6 prose prose-sm">Leistungszeitraum: <span
                                *ngIf="this.expense?.serviceStartDate as serviceStartDate"
                                class="font-extrabold">{{serviceStartDate | toDate | date:'shortDate' }}</span> - <span
                                *ngIf="this.expense?.serviceEndDate as serviceEndDate"
                                class="font-extrabold">{{serviceEndDate | toDate | date:'shortDate' }}</span></div>
                        </div>
                    </ng-container>

                    <!-- supplierOrService -->
                    <ng-container *ngIf="this.expense?.supplierOrService">
                        <div @slideInRight @slideOutLeft class="flex">
                            <mat-icon [svgIcon]="'mat_outline:local_shipping'"></mat-icon>
                            <div class="max-w-none ml-6 prose prose-sm">Lieferant/Dienstleister: <span
                                class="font-extrabold">{{this.expense?.supplierOrService }}</span></div>
                        </div>
                    </ng-container>


                    <!-- Address -->
                    <ng-container *ngIf="this.expense?.property?.address">
                        <div @slideInRight @slideOutLeft class="flex sm:items-center">
                            <mat-icon [svgIcon]="'heroicons_outline:location-marker'"></mat-icon>
                            <div class="ml-6 leading-6">{{this.expense?.property?.address | formatAddress}}</div>
                            <div *ngIf="this.expense?.unitsID?.length > 0"
                                 class="ml-2 px-2 bg-primary text-on-primary rounded">
                                <p class="text-truncate font-weight-600">{{ this.expense?.unitsID?.length }}
                                    Einheiten</p>
                            </div>
                        </div>
                    </ng-container>


                    <!-- Notes -->
                    <ng-container *ngIf="this.expense?.notes">
                        <div @slideInRight @slideOutLeft class="flex">
                            <mat-icon [svgIcon]="'heroicons_outline:menu-alt-2'"></mat-icon>
                            <div
                                [innerHTML]="this.expense?.notes"
                                class="max-w-none ml-6 prose prose-sm"></div>
                        </div>
                    </ng-container>

                    <div
                        *ngIf="this.expense?.files?.length > 0"
                        @zoomIn
                        @zoomOut
                        class="flex flex-col mt-4 pt-6 space-y-8">
                        <fuse-card
                            class="flex flex-col md:flex-row max-w-80 md:max-w-160 w-full filter-listing drop-shadow-2xl">
                            <div class="flex-0 w-80 md:w-64 h-64">
                                <img
                                    [src]="'assets/illustrations/sync_files/'+ this.appTheme+'/undraw_sync_files.svg'"
                                    alt="Card cover image"
                                    class="w-full h-full object-contain">
                            </div>
                            <div class="flex flex-col flex-auto m-8 mb-4">
                                <div class="text-2xl font-semibold leading-tight text-primary">
                                    Belegvorschau
                                </div>
                                <div class="mt-2 font-medium tracking-tight leading-6 text-md">
                                    Anzahl der Dateien: <span
                                    class="font-extrabold">{{this.expense?.files?.length}}</span>
                                </div>
                                <div class="flex items-center mt-12 -mx-3">
                                    <button (click)="openPreview()"
                                            [color]="'primary'"
                                            class="px-3 mr-1"
                                            mat-raised-button>
                                        <mat-icon [svgIcon]="'mat_outline:preview'"></mat-icon>
                                        <span class="ml-2">Vorschau anzeigen</span>

                                    </button>
                                </div>
                            </div>
                        </fuse-card>
                    </div>

                </div>

            </div>
        </div>


    </ng-container>

    <!-- Edit mode -->
    <ng-container *ngIf="editMode">

        <!-- Header -->
        <div class="relative w-full h-40 sm:h-48 px-8 sm:px-12 bg-accent-100 dark:bg-accent-700">

            <img @zoomIn @zoomOut
                 [src]="'assets/illustrations/online_payments/'+ this.appTheme+'/undraw_online_payments.svg'"
                 class="absolute inset-0 object-contain w-full h-full">

            <!-- Close button -->
            <div class="flex items-center justify-end w-full max-w-3xl mx-auto pt-6">
                <button [routerLink]="['../']"
                        color="accent"
                        mat-mini-fab
                        matTooltip="Schließen">
                    <mat-icon
                        [svgIcon]="'heroicons_outline:x'"
                        class="text-white"></mat-icon>
                </button>
            </div>
        </div>

        <!-- Expense form -->
        <div class="relative flex flex-col flex-auto items-center px-6 sm:px-12">
            <div class="w-full max-w-3xl">
                <form [formGroup]="expenseFormGroup">

                    <!-- Avatar -->
                    <div class="flex flex-auto items-end -mt-16">
                        <div
                            class="relative flex items-center justify-center w-32 h-32 rounded-full overflow-hidden ring-4 ring-bg-card">
                            <div
                                class="flex items-center justify-center w-full h-full rounded overflow-hidden uppercase text-8xl font-bold leading-none bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                {{'N'}}
                            </div>
                        </div>
                        <!-- Actions -->
                        <div *ngIf="false" class="flex items-center ml-auto mb-1">
                            <button (click)="this.openYoutubeDialog()"
                                    color="primary"
                                    mat-stroked-button
                                    type="button">
                                <mat-icon
                                    [svgIcon]="'mat_outline:ondemand_video'"
                                    class="icon-size-5"></mat-icon>
                                <span class="ml-2">Video Tutorial</span>
                            </button>
                        </div>
                    </div>

                    <div *ngIf="!this.expense" @zoomIn @zoomOut class="mt-3 text-4xl font-bold truncate">
                        Neue Ausgabe erfassen
                    </div>

                    <div *ngIf="this.expense" @zoomIn @zoomOut class="mt-3 text-4xl font-bold truncate">
                        Ausgabe aktualisieren
                    </div>

                    <div class="my-8">
                        <mat-divider></mat-divider>
                    </div>

                    <div class="flex flex-col mt-4 pt-6 space-y-4">

                        <mat-radio-group class="drop-shadow-2xl" formControlName="type">
                            <mat-radio-button
                                matTooltip="Wählen Sie 'Ausgabe', wenn es sich um einen Kostenpunkt handelt. Der Betrag wird automatisch als negativer Wert verbucht."
                                value="EXPENSE">
                                Ausgabe
                            </mat-radio-button>
                            <mat-radio-button
                                matTooltip="Wählen Sie 'Gutschrift' für Rückerstattungen oder Einnahmen. Der Betrag wird automatisch als positiver Wert verbucht."
                                value="CREDIT">
                                Gutschrift
                            </mat-radio-button>
                        </mat-radio-group>

                        <!-- Datum der Ausgabe -->
                        <mat-form-field appearance="fill"
                                        class="drop-shadow-2xl"
                                        subscriptSizing="dynamic"
                                        matTooltip="Geben Sie das Datum ein, an dem die Ausgabe erfolgt ist.">
                            <mat-label>Datum</mat-label>
                            <input [matDatepicker]="picker" formControlName="date" matInput
                                   placeholder="Wählen Sie ein Datum">
                            <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error>Ungültiges Datum</mat-error>
                        </mat-form-field>

                        <!-- Betrag -->
                        <mat-form-field appearance="fill" class="drop-shadow-2xl" subscriptSizing="dynamic">
                            <mat-label>Betrag</mat-label>
                            <input [options]="{ prefix: '€', thousands: '.', decimal: ',',align: 'left'}"
                                   currencyMask
                                   formControlName="amount"
                                   matInput placeholder="Betrag in Euro">
                            <mat-error>Ungültiger Betrag</mat-error>
                        </mat-form-field>

                        <div class="flex flex-row space-x-2">
                            <!-- Checkbox für includesVAT -->
                            <mat-checkbox class="drop-shadow-2xl" color="primary" formControlName="includesVAT">
                                Beinhaltet MWST
                            </mat-checkbox>

                            <!-- MWST Rate und Betrag, die angezeigt werden, wenn includesVAT aktiv ist -->
                            <div *ngIf="this.expenseFormGroup?.get('includesVAT')?.value" @zoomIn @zoomOut>
                                <mat-form-field class="fuse-mat-emphasized-affix w-full drop-shadow-2xl"
                                                matTooltip="Geben Sie den MWST-Satz ein, z.B. 19 für 19% MWST"
                                                subscriptSizing="dynamic">
                                    <!--                                    <mat-label>MWST-Satz</mat-label>-->
                                    <div
                                        class="text-secondary"
                                        matPrefix>
                                        MWST-Satz
                                    </div>
                                    <div
                                        class="text-secondary"
                                        matSuffix>
                                        %
                                    </div>
                                    <input formControlName="vatRate" matInput placeholder="MWST-Satz in %"
                                           type="number">
                                    <mat-error>Ungültiger MWST-Satz</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div>
                            <mat-divider class="my-2"></mat-divider>
                        </div>

                        <!-- Nahaus booking account -->
                        <mat-form-field appearance="fill" class="drop-shadow-2xl" subscriptSizing="dynamic">
                            <mat-label>Buchungskonto auswählen</mat-label>
                            <mat-select [compareWith]="this.compareExpenses" formControlName="nahausBookingsAccount">
                                <mat-option *ngFor="let konto of this.nahausBookingsAccounts" [value]="konto">
                                    <div class="flex flex-row space-x-2 items-center">
                                        <div class="flex flex-row">
                                            <div *ngIf="!!konto.refID "
                                                 class="px-2 bg-primary text-on-primary rounded">
                                                <p class="text-truncate font-weight-600">{{ konto.refID }} </p>
                                            </div>
                                        </div>
                                        <p>{{ konto?.label }}</p>
                                    </div>
                                </mat-option>
                            </mat-select>
                            <mat-hint>Buchungskonten kategorisieren Ihre finanziellen Transaktionen.</mat-hint>
                            <mat-error
                                *ngIf="this.expenseFormGroup.get('nahausBookingsAccount').touched && this.expenseFormGroup.get('nahausBookingsAccount').hasError('required')">
                                Bitte wählen Sie ein Buchungskonto aus.
                            </mat-error>
                        </mat-form-field>

                        <ng-container
                            *ngIf="this.expenseFormGroup.get('nahausBookingsAccount')?.value as nahausBookingsAccount">
                            <mat-form-field *ngIf="nahausBookingsAccount?.isAllocatable"
                                            @zoomIn
                                            @zoomOut
                                            class="drop-shadow-2xl"
                                            appearance="fill"
                                            subscriptSizing="dynamic">
                                <mat-label>Abrechnungsjahre</mat-label>
                                <mat-select formControlName="accountingYears"
                                            matTooltip="Wählen Sie alle Jahre aus, die von der Ausgabe betroffen sind. Z.B.: Eine Rechnung vom 01.11.2023 bis 31.03.2024 betrifft die Jahre 2023 und 2024."
                                            multiple>
                                    <mat-option *ngFor="let year of this.availableYears"
                                                [value]="year">{{ year }}</mat-option>
                                </mat-select>
                                <mat-hint>Wählen Sie jedes Jahr aus, in dem Kosten angefallen sind.</mat-hint>
                                <mat-error *ngIf="this.expenseFormGroup.get('accountingYears').errors?.['required']">
                                    Bitte wählen Sie mindestens ein Abrechnungsjahr aus.
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <mat-form-field
                            appearance="fill"
                            class="drop-shadow-2xl"
                            matTooltip="Leistungszeitraum bezieht sich auf den Zeitraum, in dem die erfasste Leistung erbracht wurde. Bitte geben Sie das Start- und Enddatum an, um eine genaue Zuordnung und Abrechnung zu gewährleisten."
                            subscriptSizing="dynamic">
                            <mat-label>Leistungszeitraum
                            </mat-label>
                            <mat-date-range-input [formGroup]="expenseFormGroup" [rangePicker]="picker2">
                                <input formControlName="serviceStartDate" matStartDate placeholder="Startdatum">
                                <input formControlName="serviceEndDate" matEndDate placeholder="Enddatum">
                            </mat-date-range-input>
                            <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
                            <mat-date-range-picker #picker2></mat-date-range-picker>
                            <mat-hint align="end">Optional</mat-hint>
                            <mat-hint
                                *ngIf="this.expenseFormGroup.get('serviceStartDate')?.invalid || this.expenseFormGroup.get('serviceEndDate')?.invalid">
                                Geben Sie das Datum im Format dd.mm.yyyy ein
                            </mat-hint>
                            <mat-error *ngIf="expenseFormGroup?.get('serviceStartDate').errors?.['required']">
                                Startdatum ist erforderlich.
                            </mat-error>
                            <mat-error *ngIf="expenseFormGroup?.get('serviceEndDate').errors?.['required']">
                                Enddatum ist erforderlich.
                            </mat-error>
                        </mat-form-field>


                        <fuse-alert
                            @zoomIn @zoomOut [type]="'primary'" class="mt-4 w-full">
                            <mat-icon [svgIcon]="'mat_outline:auto_fix_high'"
                                      class="icon-size-5"
                                      fuseAlertIcon>
                            </mat-icon>
                            <span fuseAlertTitle>Automatisierung (Beta)</span>
                            Die Ausgaben werden <span
                            class="font-extrabold">automatisch</span> in die Jahresabrechnung übernommen, die für die
                            entsprechenden Abrechnungsjahre erstellt wird. Dies setzt voraus, dass die Ausgabe einem
                            umlagefähigen Buchungskonto zugeordnet, sowie die zugehörige Immobilie und gegebenenfalls
                            die Einheiten korrekt zugewiesen wurden. Indem Sie den gesamten Leistungszeitraum einer
                            Ausgabe erfassen, wird deren Verteilung <span
                            class="font-extrabold">automatisch</span> in die Abrechnung einfließen, was den
                            Abrechnungsprozess nicht nur vereinfacht, sondern auch sicherstellt, dass alle relevanten
                            Kosten lückenlos berücksichtigt werden.
                        </fuse-alert>

                        <div>
                            <mat-divider class="my-2"></mat-divider>
                        </div>

                        <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                            <!-- Property -->
                            <div class="sm:col-span-4">
                                <ais-instantsearch [config]='this.configProperties'>
                                    <!--								<ais-configure [searchParameters]="{ facets: ['customerID'] }"></ais-configure>-->
                                    <app-algolia-autocomplete
                                        (onQuerySuggestionClick)=this.onQueryPropertySuggestionClick($event)
                                        *ngIf="!this.expenseFormGroup.get('property')?.value as property" @zoomIn
                                        @zoomOut
                                        class="flex drop-shadow-2xl"
                                        formFieldClass="w-full"
                                        label="Immobilie zuordnen"
                                        matOptionStyles="height: 70px; line-height: 20px;"
                                        placeholder="Immobilien-ID oder -Name eingeben">
                                        <ng-template let-option='option'>
                                            <div class="flex flex-col">
                                            <span
                                                [innerHTML]="option?._highlightResult?.h_id_text?.value && option?._highlightResult?.h_id_text?.matchLevel === 'full' ? option?._highlightResult?.h_id_text?.value : 'H' + option?._highlightResult?.h_id?.value + ' ' + option?._highlightResult?.address?.streetName?.value + ' ' + option?._highlightResult?.address?.streetNumber?.value"
                                                matLine></span>
                                                <span
                                                    [innerHTML]="option?._highlightResult?.address?.streetName?.value + ' ' + option?._highlightResult?.address?.streetNumber?.value + ', ' + option?._highlightResult?.address?.postalCode?.value + ' ' + option?._highlightResult?.address?.locality?.long?.value"
                                                    class="text-xs" matLine></span>
                                                <mat-divider class="mt-2"></mat-divider>
                                            </div>
                                        </ng-template>
                                    </app-algolia-autocomplete>
                                </ais-instantsearch>

                                <!-- Property -->
                                <div *ngIf="this.expenseFormGroup?.get('property')?.value as property"
                                     @zoomIn @zoomOut
                                     class="sm:col-span-2">
                                    <mat-form-field class="fuse-mat-emphasized-affix w-full drop-shadow-2xl">
                                        <mat-label>Immobilie</mat-label>
                                        <div
                                            class="text-secondary"
                                            matPrefix>
                                            H{{property?.h_id}}
                                        </div>
                                        <button (click)="clearSelectedProperty()"
                                                mat-icon-button
                                                matSuffix
                                                matTooltip="Zurücksetzen">
                                            <mat-icon color="warn">delete</mat-icon>
                                        </button>
                                        <input [disabled]="true"
                                               [value]="property?.address | formatAddress"
                                               matInput>
                                    </mat-form-field>
                                </div>
                            </div>

                            <ng-container *ngIf="this.expenseFormGroup.get('property')?.value as property">
                                <ng-container *ngIf="this.loadingUnits; else renderUnitSection">
                                    <div @zoomIn @zoomOut
                                         class="sm:col-span-4">
                                        <div class="w-full flex justify-center">
                                            <mat-progress-spinner [diameter]="36" color="primary"
                                                                  matTooltip="Die Einheiten werden geladen..."
                                                                  mode="indeterminate"></mat-progress-spinner>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>

                            <ng-template #renderUnitSection>
                                <div *ngIf="this.expenseFormGroup.get('property')?.value as property"
                                     @zoomIn @zoomOut class="sm:col-span-4">
                                    <mat-form-field appearance="fill" class="w-full drop-shadow-2xl"
                                                    subscriptSizing="dynamic">
                                        <mat-label>Einheiten zuordnen</mat-label>
                                        <mat-select formControlName="unitsID" multiple>
                                            <mat-select-trigger>
                                                {{this.getUnitLabelForInput(this.expenseFormGroup?.get('unitsID').value?.[0]) || ''}}
                                                <span
                                                    *ngIf="(this.expenseFormGroup?.get('unitsID').value?.length || 0) > 1"
                                                    class="example-additional-selection">
                                                (+{{(this.expenseFormGroup?.get('unitsID').value?.length || 0) - 1}}
                                                    weitere)
                                            </span>
                                            </mat-select-trigger>
                                            <mat-option *ngFor="let unit of this.units"
                                                        [value]="unit?.id">
                                                <div class="flex flex-row space-x-2">
                                                    <div class="flex flex-row">
                                                        <div class="px-2 bg-primary text-on-primary rounded">
                                                            <p class="text-truncate font-weight-600">W{{unit?.w_id}}</p>
                                                        </div>
                                                    </div>
                                                    <p>{{ unit?.label }}</p>
                                                </div>
                                            </mat-option>
                                        </mat-select>
                                        <mat-hint>
                                        </mat-hint>
                                    </mat-form-field>


                                    <fuse-alert
                                        *ngIf="!this.expenseFormGroup?.get('nahausBookingsAccount')?.value && this.expenseFormGroup?.get('unitsID').value?.length > 0"
                                        @zoomIn @zoomOut [type]="'warning'" class="mt-4 w-full">
                                        <span fuseAlertTitle>Achtung: Buchungskonto fehlt</span>
                                        Sie haben Einheiten einer Ausgabe zugeordnet, aber es wurde entweder kein
                                        Buchungskonto ausgewählt oder das ausgewählte Konto ist für Umlagen nicht
                                        geeignet.
                                        Bitte wählen Sie ein umlagefähiges Buchungskonto aus, um sicherzustellen, dass
                                        diese
                                        Ausgabe in den Nebenkostenabrechnungen korrekt berücksichtigt wird. Ausgaben
                                        ohne
                                        umlagefähiges Buchungskonto werden nicht automatisch in den
                                        Nebenkostenabrechnungen
                                        aufgeführt.
                                    </fuse-alert>

                                    <!-- Warning -->
                                    <ng-container
                                        *ngIf="this.expenseFormGroup?.get('nahausBookingsAccount')?.value as nahausBookingsAccount">
                                        <fuse-alert
                                            *ngIf="!nahausBookingsAccount.isAllocatable && this.expenseFormGroup?.get('unitsID').value?.length > 0"
                                            @zoomIn @zoomOut [type]="'warning'" class="mt-4 w-full">
                                            <span fuseAlertTitle>Achtung: Buchungskonto ist nicht umlagefähig</span>
                                            Sie haben Einheiten einer Ausgabe zugeordnet, aber es wurde entweder kein
                                            Buchungskonto ausgewählt oder das ausgewählte Konto ist für Umlagen nicht
                                            geeignet.
                                            Bitte wählen Sie ein umlagefähiges Buchungskonto aus, um sicherzustellen,
                                            dass
                                            diese
                                            Ausgabe in den Nebenkostenabrechnungen korrekt berücksichtigt wird. Ausgaben
                                            ohne
                                            umlagefähiges Buchungskonto werden nicht automatisch in den
                                            Nebenkostenabrechnungen
                                            aufgeführt.
                                        </fuse-alert>

                                        <fuse-alert
                                            *ngIf="nahausBookingsAccount.isAllocatable"
                                            @zoomIn @zoomOut [type]="'primary'" class="mt-4 w-full">
                                            <mat-icon [svgIcon]="'mat_outline:auto_fix_high'"
                                                      class="icon-size-5"
                                                      fuseAlertIcon>
                                            </mat-icon>
                                            <span fuseAlertTitle>Automatisierung (Beta)</span>
                                            Durch die Zuordnung spezifischer Einheiten ermöglichen Sie nahaus, die
                                            Nebenkostenabrechnung effizienter und genauer zu <span
                                            class="font-extrabold">automatisieren</span>.
                                            Dies
                                            erleichtert die präzise Verteilung der Kosten auf die entsprechenden
                                            Einheiten
                                            innerhalb der ausgewählten Immobilie.
                                        </fuse-alert>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </div>

                        <div>
                            <mat-divider class="my-2"></mat-divider>
                        </div>
                        <!-- Lieferant oder Dienstleister -->
                        <mat-form-field appearance="fill" class="drop-shadow-2xl">
                            <mat-label>Lieferant/Dienstleister</mat-label>
                            <input formControlName="supplierOrService" matInput
                                   name="supplierOrService"
                                   type="text"
                                   placeholder="Name des Lieferanten oder Dienstleisters">
                            <mat-hint *ngIf="!formControls?.supplierOrService?.value">Bitte Lieferant oder Dienstleister
                                eingeben
                            </mat-hint>
                        </mat-form-field>


                        <div>
                            <mat-divider class="my-2"></mat-divider>
                        </div>

                        <div class="sm:col-span-4">
                            <!-- Card #1 -->
                            <fuse-card class="flex flex-col w-full filter-article drop-shadow-2xl">
                                <img
                                    [src]="'assets/illustrations/image_upload/'+ this.appTheme+'/undraw_image_upload.svg'"
                                    alt="Card cover image"
                                    class="object-contain">
                                <div class="m-8">
                                    <div class="text-2xl font-semibold leading-tight">Belege hochladen</div>
                                    <div class="mt-2">
                                        Laden Sie hier Ihre Rechnungs- oder Quittungsbelege hoch.
                                    </div>
                                </div>
                                <div class="mt-1">
                                    <div class="flex justify-center">
                                        <button class="animate-bounce cursor-default" color="primary" disableRipple
                                                mat-mini-fab>
                                            <mat-icon color="primary"
                                                      svgIcon="mat_outline:arrow_downward"></mat-icon>
                                        </button>
                                        <svg *ngIf="false" aria-hidden="true"
                                             class="animate-bounce w-6 h-6 text-primary"
                                             fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 14l-7 7m0 0l-7-7m7 7V3" stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  stroke-width="2"/>
                                        </svg>
                                        <mat-icon *ngIf="false" class="animate-bounce w-6 h-6" color="primary"
                                                  svgIcon="mat_outline:arrow_downward"></mat-icon>
                                    </div>
                                    <app-expenses-file-pond #filePond [uploadedImages]="this.expense?.files"
                                                            class="block -mb-4"></app-expenses-file-pond>
                                </div>
                            </fuse-card>
                        </div>

                        <div>
                            <mat-divider class="my-2"></mat-divider>
                        </div>

                        <!-- Notes -->
                        <div class="mt-8 ">
                            <mat-form-field class="fuse-mat-textarea fuse-mat-no-subscript w-full drop-shadow-2xl">
                                <mat-label>Notizen</mat-label>
                                <mat-icon
                                    [svgIcon]="'heroicons_solid:menu-alt-2'"
                                    class="hidden sm:flex icon-size-5"
                                    matPrefix></mat-icon>
                                <textarea
                                    [formControlName]="'notes'"
                                    [placeholder]="'Notes'"
                                    [rows]="5"
                                    [spellcheck]="false"
                                    matInput></textarea>
                            </mat-form-field>
                        </div>
                    </div>


                    <!-- Actions -->
                    <div
                        class="flex items-center mt-10 -mx-6 sm:-mx-12 py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t bg-gray-50 dark:bg-transparent">
                        <!-- Delete -->
                        <button (click)="this.requestDelete()"
                                *ngIf="this.expense"
                                [color]="'warn'"
                                mat-button>
                            <span *ngIf="!this.deleting">Löschen</span>
                            <mat-progress-spinner
                                *ngIf="this.deleting"
                                [diameter]="24"
                                [mode]="'indeterminate'">
                            </mat-progress-spinner>
                        </button>
                        <!-- Cancel -->
                        <button
                            (click)="this.expense ? toggleEditMode(false) : this.cancel()"
                            class="ml-auto"
                            mat-button>
                            Abbrechen
                        </button>
                        <!-- Save -->
                        <button (click)="this.save()"
                                [color]="'primary'"
                                [disabled]="this.updating"
                                class="ml-2 fuse-mat-button-large"
                                mat-flat-button>
                            <ng-container *ngIf="this.expense">
                                <span *ngIf="!this.updating">Speichern</span>
                            </ng-container>
                            <ng-container *ngIf="!this.expense">
                                <span *ngIf="!this.updating">Ausgabe erfassen</span>
                            </ng-container>
                            <mat-progress-spinner
                                *ngIf="this.updating"
                                [diameter]="24"
                                [mode]="'indeterminate'"></mat-progress-spinner>
                        </button>
                    </div>

                </form>
            </div>
        </div>
    </ng-container>

</div>

