import firebase from 'firebase/compat';
import { get, has } from 'lodash-es';
import { MonoTypeOperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import Timestamp = firebase.firestore.Timestamp;

export const buildPath = (path: string, customerID: string | null): string => customerID ? `customers/${customerID}/${path}` : path;

export type FunctionsErrorCode =
  'ok'
  | 'cancelled'
  | 'unknown'
  | 'invalid-argument'
  | 'deadline-exceeded'
  | 'not-found'
  | 'already-exists'
  | 'permission-denied'
  | 'resource-exhausted'
  | 'failed-precondition'
  | 'aborted'
  | 'out-of-range'
  | 'unimplemented'
  | 'internal'
  | 'unavailable'
  | 'data-loss'
  | 'unauthenticated';


/**
 * rxjs pipe to convert timestamps
 */
export function convertTimestampsPipe<T>(): MonoTypeOperatorFunction<T> {
  return input$ => input$.pipe(
    map((val: any) => convertTimestamps(val))
  );
}

/**
 * convert any timestamp properties to js date format
 * @param firebaseObject
 */
export function convertTimestamps<T>(firebaseObject: T | T[]): T | T[] {
  if (!firebaseObject) {
    // console.log('firebaseobject is null or undefined');
    return firebaseObject;
  }
  // if (typeof firebaseObject === 'undefined') return firebaseObject;

  // if an array was passed
  if (Array.isArray(firebaseObject)) {
    return firebaseObject.map((item: any) => convertTimestamps(item));
  }

  // if its a map (object)
  if (firebaseObject instanceof Object) {

    // iterate object properties
    for (const [key, value] of Object.entries(firebaseObject)) {

      // convert simple properties
      if (value && isTimestamp(value)) {
        // tslint:disable-next-line:no-parameter-reassignment
        firebaseObject = {
          ...firebaseObject,
          [key]: convertTimestamp(value)
        };
        continue;
      }

      // recursively convert items inside array
      if (value && Array.isArray(value)) {
        // tslint:disable-next-line:no-parameter-reassignment
        firebaseObject = {
          ...firebaseObject,
          [key]: value.map(item => isTimestamp(item) ? convertTimestamp(item) : convertTimestamps(item))
        };
        continue;
      }

      // recursively convert inner objects (maps)
      if (value && typeof value === 'object') {
        // tslint:disable-next-line:no-parameter-reassignment
        firebaseObject = {
          ...firebaseObject,
          [key]: convertTimestamps(value)
        };
      }

    }
  }
  return firebaseObject;
}

/**
 * convert any value
 * @param value
 */
export function convertTimestamp<T extends object>(value: T): T | Date {
  if (value === null || typeof value === 'undefined') {
    return value;
  }

  // from firestore
  if (isTimestamp(value)) {
    // console.log('isTimestamp');
    try {
      return (value as Timestamp).toDate();
    } catch (err) {
      console.error('Error', err);
      return value;
    }
  }
  // From algolia
  else if (has(value, '_seconds') && has(value, '_nanoseconds')) {
    // console.log('it\'s a date from algolia 1');
    // @ts-ignore
    return new Date(get(value, '_seconds') * 1000 + get(value, '_nanoseconds') / 1000000);
  } else if (has(value, '_seconds') && !has(value, '_nanoseconds')) {
    // console.log('it\'s a date from algolia 2');
    // @ts-ignore
    return new Date(get(value, '_seconds') * 1000);
  }
  // not from algolia but manipulated from angular material
  else if (has(value, 'seconds') && has(value, 'nanoseconds')) {
    // console.log('it\'s a not date from algolia 1');
    // @ts-ignore
    return new Date(get(value, 'seconds') * 1000 + get(value, 'nanoseconds') / 1000000);
  } else if (has(value, 'seconds') && !has(value, 'nanoseconds')) {
    // console.log('it\'s a not date from algolia 2');
    // @ts-ignore
    return new Date(get(value, 'seconds') * 1000);
  }

  // console.log('convertTimestamp', value);

  return value;
}

/**
 * verify if value is timestamp
 * @param value
 */
function isTimestamp(value: any): boolean {
  return value.hasOwnProperty('seconds') &&
    value.hasOwnProperty('nanoseconds') &&
    typeof value.toDate === 'function';
}


