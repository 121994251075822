import {PublicProfileSettings} from '@settings/models/publicProfile.settings.interface';
import {Dunning} from "../models/dunning.interface";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {Property} from "../../../../properties/models/property.interface";
import {PropertyUnit} from "../../../../properties/features/units/models/unit.interface";
import {Bookings} from "../../../models/booking";
import {convertTimestamp} from "../../../../../../helpers";

export interface PaymentReminderTemplateParams {
    property: Property;
    unit: PropertyUnit;
    publicProfile: PublicProfileSettings;
    dunning: Dunning;
    currencyPipe: CurrencyPipe;
    datePipe: DatePipe;
}

export const PAYMENT_REMINDER_TEMPLATE = (params: PaymentReminderTemplateParams) => `
<p>Sehr geehrte Damen und Herren,</p>
<p><br></p>
<p>wir möchten Sie höflich darauf hinweisen, dass für die folgenden offenen Posten bisher noch kein Zahlungseingang verbucht werden konnte:</p>
<p><br></p>
<ol>
  ${buildOpList(params.dunning.relatedBookings, params.datePipe, params.currencyPipe)}
  <!-- Fügen Sie weitere Listenelemente für andere offene Posten hinzu -->
</ol>
<p><br></p>
<p>Diese Zahlungen waren ursprünglich bis zu den oben genannten Fälligkeitsdaten zu leisten. Der Gesamtbetrag, der derzeit noch aussteht, beläuft sich auf <strong>${params.currencyPipe.transform(params.dunning.amountToPay, 'EUR')}</strong>.</p>
<p><br></p>
<p>Wir bitten Sie daher, den Gesamtbetrag umgehend, jedoch spätestens bis zum <strong>${params.datePipe.transform(params.dunning.dueDate as Date, 'shortDate')}</strong>, auf das Ihnen bekannte Konto mit dem Verwendungszweck <strong>H${params?.property?.h_id}/W${params.unit.w_id}</strong> zu überweisen.</p>
<p><br></p>
<p>Sollten Sie bereits eine Zahlung vorgenommen haben oder bei Fragen zu dieser Angelegenheit, kontaktieren Sie uns bitte umgehend, um Unklarheiten zu vermeiden.</p>
<p><br></p>
<p>Wir danken Ihnen für Ihre Aufmerksamkeit in dieser Angelegenheit und stehen für jegliche Fragen oder Klärungen gerne zur Verfügung.</p>
<p><br></p>
<p>${params.publicProfile?.greetings || 'Mit freundlichen Grüßen'}</p>
<p><br></p>
<p>${params.publicProfile?.sender || ''}</p>
`.replace(/>\s+</g, '><')
;

const buildOpList = (bookings: Bookings, datePipe: DatePipe, currencyPipe: CurrencyPipe) => {
    if (bookings?.length > 0) {
        const opList = bookings.map(booking => {
            return `<li><strong>${booking?.text || ''} - Fällig am ${datePipe.transform(convertTimestamp(booking.date as Date), 'shortDate')} - Betrag: ${currencyPipe.transform(Math.abs(booking.saldo), 'EUR')}</strong></li>`
        })
        // const a = [1,2,3]
        // const b = ...a;
        return opList.join('');
    }
}
