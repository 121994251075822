<mat-form-field class="w-full">
	<mat-label>{{this.label}}</mat-label>
	<mat-icon
			[svgIcon]="'heroicons_solid:user-circle'"
			class="hidden sm:flex icon-size-5"
			matPrefix></mat-icon>
	<input #query
	       (change)="updateValue($event)"
	       (click)="$event.stopPropagation()"
	       (focus)="focus.next()"
	       (keyup)="handleChange($event)"
	       [disableControl]="!!this.selectedLandlord"
	       [disabled]="this.disabled"
	       [formControl]="fc"
	       [matAutocomplete]="auto"
	       id="search"
	       matInput
	       placeholder="Eigentümer suchen und auswählen"
	       required/>
	<mat-hint *ngIf="this.hint"> {{this.hint}}</mat-hint>
	<mat-autocomplete
      #auto="matAutocomplete">
		<div *ngFor="let index of state.indices">
			<mat-option
					(click)="this.selectLandlord(option)"
					(keyup.enter)="onQuerySuggestionClick.emit(option)"
					*ngFor="let option of index.hits as Landlord;trackBy: trackByFn"
					[value]="getDisplayName(option)">
				<span [innerHTML]="getDisplayNameHighlighted(option?._highlightResult)" matLine></span>
			</mat-option>
		</div>
	</mat-autocomplete>
	<button (click)="this.clear()" *ngIf="query.value.length > 0"
	        aria-label="Clear"
	        mat-icon-button matSuffix
	        matTooltip="Löschen">
		<mat-icon>close</mat-icon>
	</button>
	<mat-hint *ngIf="this.selectedLandlord">Vermieter ID: <b>{{this.selectedLandlord?.id}}</b></mat-hint>
	<mat-icon *ngIf="query.value.length == 0" matSuffix>search</mat-icon>
	<mat-error>Bitte überprüfen Sie Ihre Eingaben!</mat-error>
</mat-form-field>




