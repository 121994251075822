import {Activity} from '@activities/models/activities.types';
import {Injectable} from '@angular/core';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';

@Injectable()
export class DunningsActivitiesService extends AbstractFirestoreService<Activity> {

    COLLECTION = 'activities';

    constructor(authService: AuthService) {
        super(authService);
    }

    setParentPathForPeriod(propertyID: string, unitID: string, periodID: string, dunningID: string): void {
        this.parentPath = `properties/${propertyID}/units/${unitID}/periods/${periodID}/dunnings/${dunningID}`;
    }

}
