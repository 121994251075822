import {ActivatedRouteSnapshot, RouterStateSnapshot, Routes} from '@angular/router';
import {ExpensesMainComponent} from "./components/expenses-main/expenses-main.component";
import {ExpensesListComponent} from "./components/expenses-list/expenses-list.component";
import {CreateExpenseComponent} from "./components/expenses-list/create-expense/create-expense.component";
import {
    NahausBookingsAccountsCollectionResolver
} from "../bookingsAccounts/resolvers/nahaus-bookings-accounts-collection.resolver";
import {ExpenseResolver} from "./resolvers/expense.resolver";
export enum ExpensesAccessLayerOptions {
    PERIOD = "PERIOD",
    PROPERTY = "PROPERTY",
    ACCOUNT = "ACCOUNT",
}

export type ExpensesAccessLayer =
ExpensesAccessLayerOptions.PERIOD
    | ExpensesAccessLayerOptions.PROPERTY
    | ExpensesAccessLayerOptions.ACCOUNT
/**
 * Can deactivate contacts details
 *
 * @param component
 * @param currentRoute
 * @param currentState
 * @param nextState
 */
const canDeactivateExpensesDetails = (
    component: CreateExpenseComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot) => {
    // Get the next route
    let nextRoute: ActivatedRouteSnapshot = nextState.root;
    while (nextRoute.firstChild) {
        nextRoute = nextRoute.firstChild;
    }

    // If the next state doesn't contain '/ausgaben'
    // it means we are navigating away from the
    // contacts app
    if (!nextState.url.includes('/ausgaben')) {
        // Let it navigate
        return true;
    }

    // If we are navigating to another contact...
    if (nextRoute.paramMap.get('id')) {
        // Just navigate
        return true;
    }

    // Otherwise, close the drawer first, and then navigate
    return component.closeDrawer().then(() => true);
};

export default [
    {
        path: '',
        component: ExpensesMainComponent,
        data: {
            // onlyUnpaid: true,
            // permission: PermissionOptions.BOOKINGS_READ
        },
        // canActivate: [PermissionsGuard],
        children: [
            {
                path: '',
                component: ExpensesListComponent,
                resolve: {
                    nahausBookingsAccounts: NahausBookingsAccountsCollectionResolver
                },
                // data: { permission: PermissionOptions.MSA_READ },
                // canActivate: [PermissionsGuard],
                children: [
                    {
                        path: 'erfassen',
                        component: CreateExpenseComponent,
                        // data: { permission: PermissionOptions.MSA_WRITE },
                        // canActivate: [PermissionsGuard],
                        canDeactivate: [canDeactivateExpensesDetails],
                    },
                    {
                        path: ':id',
                        component: CreateExpenseComponent,
                        // data: { permission: PermissionOptions.MSA_WRITE },
                        // canActivate: [PermissionsGuard],
                        canDeactivate: [canDeactivateExpensesDetails],
                        resolve: {
                            expense: ExpenseResolver
                        }
                    }
                ]
            },
            {
                path: 'filters/:filter',
                component: ExpensesListComponent,
                resolve: {
                    nahausBookingsAccounts: NahausBookingsAccountsCollectionResolver
                },
                // data: { permission: PermissionOptions.MSA_READ },
                // canActivate: [PermissionsGuard],
                children: [
                    {
                        path: 'erfassen',
                        component: CreateExpenseComponent,
                        // data: { permission: PermissionOptions.MSA_WRITE },
                        // canActivate: [PermissionsGuard],
                        canDeactivate: [canDeactivateExpensesDetails],
                    },
                    {
                        path: ':id',
                        component: CreateExpenseComponent,
                        // data: { permission: PermissionOptions.MSA_WRITE },
                        // canActivate: [PermissionsGuard],
                        canDeactivate: [canDeactivateExpensesDetails],
                        resolve: {
                            expense: ExpenseResolver
                        }
                    }
                ]
            },
        ]
    },
] as Routes;
