<div
    cdkScrollable
    class="absolute inset-0 flex flex-col min-w-0 overflow-y-auto">

    <!-- Header -->
    <div
        class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
        <!-- Actions -->
        <div *ngIf="this.accessLayer !== this.bookingsAccessLayerOptions.ACCOUNT"
             class="flex shrink-0 items-center  sm:mx-4">
            <button
                (click)="matDrawer.toggle()"
                mat-icon-button>
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </div>
        <div *ngIf="this.accessLayer !== this.bookingsAccessLayerOptions.ACCOUNT" class="flex-1 min-w-0">
            <!-- Title -->
            <div class="mt-2 flex flex-row items-center space-x-2">
                <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                    Digitale Protokolle
                </h2>
                <div>
                    <mat-icon *ngIf="this.accessLayer !== this.bookingsAccessLayerOptions.PERIOD"
                              matTooltip="TEST - Hier werden nur die Sollstellungen der letzten 60 Tage angezeigt. Für mehr Daten navigieren Sie bitte in den gewünschten Vermietungszeitraum, um weitere Details zu sehen."
                              svgIcon="mat_outline:info"></mat-icon>
                </div>
            </div>
        </div>

        <ais-instantsearch [config]='this.configProperties' class="w-full">
            <ais-configure [searchParameters]="{ facets: ['customerID'] }"></ais-configure>
            <div class="flex flex-col space-y-2 w-full">
                <div class="flex items-center flex-auto w-full">

                    <!--                        <button-->
                    <!--                                (click)="this.sideBadDrawer?.toggle()"-->
                    <!--                                class="flex lg:hidden -ml-2"-->
                    <!--                                mat-icon-button>-->
                    <!--                            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>-->
                    <!--                        </button>-->
                    <instant-search
                        class="flex w-full"
                        placeHolder="Suche nach Immobilienadresse oder Mietername..."></instant-search>
                    <ais-hits>
                        <ng-template let-hits='hits' let-results='results'>
                            <ng-template (onNewHits)='onNewHits($event)' [hits]='hits'
                                         appHitsListener></ng-template>
                        </ng-template>
                    </ais-hits>
                    <div class="flex shrink-0 space-x-2 items-center">
                        <button (click)="this.openYoutubeDialog(true)"
                                class="px-1 sm:px-4 min-w-10"
                                color="primary"
                                mat-stroked-button
                                matTooltip="Klicken Sie hier, um eine Videoanleitung zum Hinzufügen von Immobilien anzusehen"
                                type="button">
                            <mat-icon
                                [svgIcon]="'mat_outline:ondemand_video'"
                                class="icon-size-5"></mat-icon>
                            <span class="hidden sm:inline-block ml-2">Video-Anleitung</span>
                        </button>
                        <a [color]="'primary'"
                           class="px-1 sm:px-4 min-w-10"
                           mat-flat-button
                           routerLink="/protokolle/erstellen">
                            <mat-icon
                                [svgIcon]="'heroicons_solid:plus-circle'"
                                class="icon-size-5"></mat-icon>
                            <span class="hidden sm:inline-block ml-2">Erstellen</span>
                        </a>
                    </div>
                </div>
                <div class="flex items-center flex-auto w-full">
                    <ais-stats>
                        <ng-template let-state='state'>
                            <ng-template (onNewStat)='onNewStat($event)' [state]='state'
                                         appStatListener></ng-template>
                            <span *ngIf='this.state?.query?.length > 0'>{{this.state.nbHits}}
                                Ergebnisse gefunden in {{this.state.processingTimeMS}}
                                ms.</span>
                        </ng-template>
                    </ais-stats>
                </div>
            </div>
        </ais-instantsearch>

        <div *ngIf="this.accessLayer === this.bookingsAccessLayerOptions.ACCOUNT && false" class="w-full">
            <ais-instantsearch [config]='this.configProperties'>
                <!--								<ais-configure [searchParameters]="{ facets: ['customerID'] }"></ais-configure>-->
                <app-algolia-autocomplete
                    (onQuerySuggestionClick)=this.onQueryPropertySuggestionClick($event)
                    class="flex"
                    formFieldClass="w-full"
                    label="Immobilie auswählen"
                    matOptionStyles="height: 70px; line-height: 20px;"
                    placeholder="Suche...">
                    <ng-template let-option='option'>
                        <div class="flex flex-col">
                                            <span
                                                [innerHTML]="option?._highlightResult?.h_id_text?.value && option?._highlightResult?.h_id_text?.matchLevel === 'full' ? option?._highlightResult?.h_id_text?.value : 'H' + option?._highlightResult?.h_id?.value + ' ' + option?._highlightResult?.address?.streetName?.value + ' ' + option?._highlightResult?.address?.streetNumber?.value"
                                                matLine></span>
                            <span
                                [innerHTML]="option?._highlightResult?.address?.postalCode?.value + ' ' + option?._highlightResult?.address?.locality?.long?.value"
                                class="text-xs" matLine></span>
                            <mat-divider class="mt-2"></mat-divider>
                        </div>
                    </ng-template>
                </app-algolia-autocomplete>
            </ais-instantsearch>
            <div class="w-full mt-1">
                <mat-chip-listbox #chipGrid>
                    <mat-chip (removed)="remove(property)" *ngFor="let property of this.selectedProperties">
                        H{{property?.h_id}} {{property?.address | formatAddress}}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                </mat-chip-listbox>
            </div>
        </div>
        <!-- Actions -->
        <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-2 items-center space-x-4">

            <div *ngIf="!this.selection?.isEmpty()" @slideOutRight
                 class="flex flex-row space-x-4">
                <button (click)="this.requestDeleteSelection()"
                        @slideInRight @slideOutLeft
                        [disabled]="this.deleting"
                        class="px-1 sm:px-4 min-w-10"
                        color="warn"
                        mat-stroked-button>
                    <mat-icon *ngIf="!this.deleting"
                              [svgIcon]="'mat_outline:delete'"
                              class="icon-size-5"></mat-icon>
                    <span *ngIf="!this.deleting" class="hidden sm:inline-block ml-2">Auswahl löschen</span>
                    <mat-progress-spinner
                        *ngIf=this.deleting
                        [diameter]="24"
                        [mode]="'indeterminate'"
                        matTooltip="Bitte warten..."></mat-progress-spinner>
                </button>
            </div>

            <button *ngIf="this.accessLayer === this.bookingsAccessLayerOptions.PERIOD"
                    @slideInRight @slideOutRight
                    [color]="'accent'"
                    class="px-1 sm:px-4 min-w-10" mat-raised-button matTooltip="Exportieren Sie als PDF oder CSV.">
                <mat-icon @zoomIn @zoomOut
                          [svgIcon]="'heroicons_outline:document-download'"
                          class="icon-size-5"></mat-icon>
                <span class="hidden sm:inline-block ml-2">Export</span>
            </button>

            <button *ngIf="this.accessLayer === this.bookingsAccessLayerOptions.PERIOD && this.selection?.isEmpty()"
                    @slideInRight @slideOutRight
                    [color]="'primary'"
                    [disabled]="this.creating"
                    class="px-1 sm:px-4 min-w-10" mat-flat-button matTooltip="Buchung hinzufügen">
                <mat-icon *ngIf="!this.creating" @zoomIn @zoomOut [svgIcon]="'heroicons_outline:plus'"
                          class="icon-size-5"></mat-icon>
                <span *ngIf="!this.creating" class="hidden sm:inline-block ml-2">Hinzufügen</span>
                <mat-progress-spinner
                    *ngIf="this.creating"
                    [diameter]="24"
                    [mode]="'indeterminate'"
                    matTooltip="Bitte warten..."></mat-progress-spinner>
            </button>
        </div>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">

        <!-- List -->
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden">
            <mat-progress-bar *ngIf="this.isLoading" @zoomIn @zoomOut color="primary"
                              mode="query"></mat-progress-bar>
            <!-- Booking Table -->
            <ng-container *ngIf="this.dataSource?.data?.length; else loading">
                <div
                    cdkScrollable
                    class="overflow-x-auto sm:overflow-y-auto">
                    <table
                        (matSortChange)='this.sortMsaData($event)'
                        [dataSource]="this.dataSource"
                        [matSortDisableClear]="true"
                        [multiTemplateDataRows]="true"
                        [ngClass]="{'pointer-events-none': this.isLoading}"
                        [trackBy]="trackByFn"
                        class="w-full bg-transparent lg:table-fixed"
                        mat-table
                        matSort>

                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th *matHeaderCellDef
                                class="w-24 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                mat-header-cell>
                                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                              [aria-label]="checkboxLabel()"
                                              [checked]="selection.hasValue() && isAllSelected()"
                                              [indeterminate]="selection.hasValue() && !isAllSelected()"
                                              color="primary">
                                </mat-checkbox>
                            </th>
                            <td *matCellDef="let row; let i = index" mat-cell>
                                <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                                              (click)="$event.stopPropagation()"
                                              [aria-label]="checkboxLabel(row,i)"
                                              [checked]="selection.isSelected(row)"
                                              color="primary">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <!-- status-->
                        <ng-container matColumnDef='status'>
                            <th
                                *matHeaderCellDef
                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                disableClear
                                mat-header-cell
                                mat-sort-header>
                                Status
                            </th>
                            <td
                                *matCellDef="let protocol"
                                class=" w-12"
                                mat-cell>
                                <div class="flex items-center w-5">
                                    <div
                                        *ngIf="!this.protocol?.status || this.protocol?.status === this.protocolStatusOptions.DRAFT"
                                        class="flex items-center justify-center py-1 px-3 rounded-full leading-normal text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700 relative"
                                        matTooltip="Das Protokoll befindet sich im Entwurfsstatus und kann bearbeitet werden.">
                                        <span class="text-sm font-medium whitespace-nowrap">Entwurf</span>
                                        <span *ngIf="this.protocol?.new"
                                              class="absolute top-0 right-0 flex h-3 w-3 -mt-1 -mr-1"
                                              matTooltip="Neu">
                                                                    <span
                                                                        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-400 opacity-75"></span>
                                                                    <span
                                                                        class="relative inline-flex rounded-full h-3 w-3 bg-primary-500"></span>
                                                                </span>
                                    </div>


                                    <div *ngIf="this.protocol?.status === this.protocolStatusOptions.IN_PROGRESS"
                                         class="flex items-center justify-center py-1 px-3 rounded-full leading-normal text-white bg-orange-500 dark:text-white-300 dark:bg-orange-400"
                                         matTooltip="Das Protokoll wird derzeit bearbeitet.">
                                        <span class="text-sm font-medium whitespace-nowrap">In Bearbeitung</span>
                                    </div>

                                    <div *ngIf="this.protocol?.status === this.protocolStatusOptions.COMPLETED"
                                         class="flex items-center justify-center py-1 px-3 rounded-full leading-normal text-white bg-blue-500 dark:text-white-300 dark:bg-blue-400"
                                         matTooltip="Das Protokoll ist fertiggestellt und bereit für die nächste Phase.">
                                        <span class="text-sm font-medium whitespace-nowrap">Fertiggestellt</span>
                                    </div>

                                    <div *ngIf="this.protocol?.status === this.protocolStatusOptions.SENT"
                                         class="flex items-center justify-center py-1 px-3 rounded-full leading-normal text-white bg-amber-500 dark:text-white-300 dark:bg-amber-400"
                                         matTooltip="Das Protokoll wurde an die entsprechenden Parteien versendet.">
                                        <span class="text-sm font-medium whitespace-nowrap">Versendet</span>
                                    </div>

                                    <div *ngIf="this.protocol?.status === this.protocolStatusOptions.AWAITING_SIGNATURE"
                                         class="flex items-center justify-center py-1 px-3 rounded-full leading-normal text-white bg-pink-500 dark:text-white-300 dark:bg-pink-400"
                                         matTooltip="Das Protokoll wartet auf die Unterschriften der beteiligten Parteien.">
                                        <span
                                            class="text-sm font-medium whitespace-nowrap">Warten auf Unterschrift</span>
                                    </div>

                                    <div *ngIf="this.protocol?.status === this.protocolStatusOptions.CLOSED"
                                         class="flex items-center justify-center py-1 px-3 rounded-full leading-normal text-white bg-green-500 dark:text-white-300 dark:bg-green-400"
                                         matTooltip="Das Protokoll ist vollständig abgeschlossen und alle erforderlichen Unterschriften sind geleistet.">
                                        <span class="text-sm font-medium whitespace-nowrap">Abgeschlossen</span>
                                    </div>

                                </div>
                            </td>

                        </ng-container>

                        <!-- Name -->
                        <ng-container matColumnDef="name">
                            <th *matHeaderCellDef
                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                mat-header-cell
                                matTooltip="Name des Mieters">
                                Mieter
                            </th>
                            <td
                                *matCellDef="let protocol"
                                class="truncate"
                                mat-cell>
                                <span class="pr-6 whitespace-nowrap truncate">
                                    {{protocol?.tenantsName}}
                                </span>
                            </td>
                        </ng-container>

                        <!-- Einheit -->
                        <ng-container matColumnDef="unitLabel">
                            <th *matHeaderCellDef
                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                mat-header-cell
                                matTooltip="Einheit der Immobilie, z.B. EG Links">
                                Einheit
                            </th>
                            <td
                                *matCellDef="let protocol"
                                [matTooltip]="'H' + protocol?.h_id + '/W' + protocol?.w_id"
                                mat-cell>
                                <span class="pr-6 whitespace-nowrap">
                                    {{protocol?.unitLabel}}
                                </span>
                            </td>
                        </ng-container>

                        <!-- address -->
                        <ng-container matColumnDef="address">
                            <th *matHeaderCellDef
                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                mat-header-cell
                                matTooltip="Adresse der Immobilie">
                                Adresse
                            </th>
                            <td
                                *matCellDef="let booking"
                                mat-cell>
                                <div class="flex truncate">
                                    <span *ngIf="booking?.address as address" [matTooltip]="address | formatAddress"
                                          class="pr-6 whitespace-nowrap truncate">
                                        {{address | formatAddress }}
                                    </span>
                                </div>
                            </td>
                        </ng-container>

                        <!-- Date -->
                        <ng-container matColumnDef="date">
                            <th *matHeaderCellDef
                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                mat-header-cell
                                mat-sort-header
                                matTooltip="Datum der Sollstellung">
                                Datum
                            </th>
                            <td
                                *matCellDef="let booking"
                                mat-cell>
                                <span [ngClass]="{'leading-normal text-sm text-secondary' : !!booking?.bookingID}"
                                      class="pr-6 whitespace-nowrap">
                                    {{booking?.date | toDate | date:'shortDate'}}
                                </span>
                            </td>
                        </ng-container>


                        <!-- pdf -->
                        <ng-container matColumnDef="pdf">
                            <th
                                *matHeaderCellDef
                                class="w-24 px-4 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                mat-header-cell>
                                PDF
                            </th>
                            <td
                                *matCellDef="let protocol"
                                mat-cell>
                                <div class="flex">
                                    <a (click)="$event.stopPropagation()" *ngIf="protocol?.url"
                                       [disabled]="!protocol?.url"
                                       [href]="protocol?.url"
                                       color="primary"
                                       mat-icon-button
                                       matTooltip="PDF herunterladen"
                                       target="_blank">
                                        <mat-icon [svgIcon]="'heroicons_outline:document-download'"></mat-icon>
                                    </a>
                                    <button *ngIf="!protocol?.url" color="accent" mat-mini-fab>
                                        <mat-icon svgIcon="mat_outline:file_upload"></mat-icon>
                                    </button>
                                </div>
                            </td>
                        </ng-container>

                        <!-- action -->
                        <ng-container matColumnDef="action">
                            <th *matHeaderCellDef
                                class="bg-gray-50 dark:bg-black dark:bg-opacity-5 text-right"
                                mat-header-cell>
                                Aktionen
                            </th>
                            <td
                                *matCellDef="let booking"
                                mat-cell>
                                <div class="flex w-full items-center justify-end">
                                    <button
                                        [color]="'accent'"
                                        class="ml-4"
                                        mat-raised-button
                                        matTooltip="Zahlung erfassen">
                                        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                                        <span class="ml-2 mr-1">Zahlung erfassen</span>
                                    </button>
                                </div>
                            </td>
                        </ng-container>

                        <!-- Buttons -->
                        <ng-container matColumnDef="buttons">
                            <th
                                *matHeaderCellDef
                                class="w-12 bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                mat-header-cell>
                            </th>
                            <td
                                *matCellDef="let booking"
                                mat-cell>
                                <div *ngIf="!booking?.bookingID" class="flex justify-end">
                                    <button (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu"
                                            mat-icon-button>
                                        <mat-icon [svgIcon]="'heroicons_outline:dots-vertical'"></mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item>
                                            <mat-icon color="accent"
                                                      svgIcon="mat_outline:edit"></mat-icon>
                                            Bearbeiten
                                        </button>
                                        <button (click)="this.openDeleteBookingDialog(booking)" mat-menu-item>
                                            <mat-icon color="warn"
                                                      matTooltip="Löschen"
                                                      svgIcon="mat_outline:delete"></mat-icon>
                                            Löschen
                                        </button>
                                    </mat-menu>
                                </div>
                            </td>
                        </ng-container>


                        <tr
                            *matHeaderRowDef="this.columns"
                            mat-header-row></tr>
                        <tr
                            *matRowDef="let booking; columns: this.columns;"
                            class="order-row h-16 hover:bg-gray-100 dark:hover:bg-hover"
                            mat-row></tr>
                    </table>
                </div>
                <mat-paginator (page)='onPageChanged($event)'
                               @zoomIn
                               @zoomOut
                               [disabled]="this.isLoading"
                               [length]="this.page?.length"
                               [ngClass]="{'pointer-events-none': this.isLoading}"
                               [pageIndex]="this.page?.pageIndex"
                               [pageSizeOptions]="[10, 25, 50]"
                               [pageSize]="this.page?.pageSize"
                               [showFirstLastButtons]="false"
                               class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent">
                </mat-paginator>
            </ng-container>

            <!-- No Bookings -->
            <ng-template #loading>
                <div *ngIf="!this.initialLoad; else nodata"
                     class="p-8 sm:p-16 flex justify-center items-center nodata-container h-full">
                    <mat-spinner></mat-spinner>
                </div>
            </ng-template>
            <ng-template #nodata>
                <div class="flex flex-col h-full overflow-x-auto sm:overflow-y-auto">
                    <div class="flex flex-col justify-center p-8 sm:p-16 h-full">
                        <div class="flex justify-center">
                            <!-- Card #4 -->
                            <fuse-card class="flex flex-col max-w-80 sm:max-w-100 w-full filter-article drop-shadow-xl">
                                <img
                                    [src]="'assets/illustrations/good_team/' + this.appTheme + '/undraw_good_team.svg'"
                                    alt="Card cover image"
                                    class="object-cover">
                                <div class="m-8 mb-4">
                                    <div class="text-2xl font-semibold leading-tight">
                                        Noch keine Daten vorhanden
                                    </div>
                                    <div class="text-secondary text-md mt-1">Erstellen Sie Ihr erstes Protokoll, um
                                        loszulegen.
                                    </div>
                                    <!--                                    <div class="mt-4">-->
                                    <!--                                        Erstellen Sie Ihr erstes Protokoll, um loszulegen.-->
                                    <!--                                    </div>-->
                                    <div class="flex mt-3 -mx-3 justify-center">
                                        <button (click)="this.openDialogToSelectPropertyUnitPeriod()"
                                                [color]="'primary'"
                                                class="px-3 items-center"
                                                mat-button>
                                            <mat-icon
                                                [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                                            <span class="ml-2">Protokoll erstellen</span>
                                        </button>
                                    </div>
                                </div>
                            </fuse-card>
                        </div>
                    </div>
                </div>
            </ng-template>

            <!-- loading -->
            <ng-template #loading>
                <div class="flex flex-auto flex-col items-center justify-center bg-gray-100 dark:bg-transparent">
                    <mat-spinner></mat-spinner>
                </div>
            </ng-template>
        </div>

    </div>

</div>
