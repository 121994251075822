import {PublicProfileSettings} from '@settings/models/publicProfile.settings.interface';
import {Dunning, LastWarningTypeOptions} from "../models/dunning.interface";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {Property} from "../../../../properties/models/property.interface";
import {PropertyUnit} from "../../../../properties/features/units/models/unit.interface";
import {Bookings} from "../../../models/booking";
import {convertTimestamp} from "../../../../../../helpers";

export interface PaymentReminderTemplateParams {
    property: Property;
    unit: PropertyUnit;
    publicProfile: PublicProfileSettings;
    dunning: Dunning;
    currencyPipe: CurrencyPipe;
    datePipe: DatePipe;
}

export const FINAL_REMINDER_TEMPLATE = (params: PaymentReminderTemplateParams) => `
<p>Sehr geehrte Damen und Herren,</p>
<p><br></p>
<p>trotz unserer wiederholten Mahnungen und Zahlungserinnerungen müssen wir Ihnen leider mitteilen, dass die Zahlung für die folgenden Positionen noch immer aussteht:</p>
<p><br></p>
<ol>
  ${buildOpList(params.dunning.relatedBookings, params.datePipe, params.currencyPipe)}
  <!-- Fügen Sie weitere Listenelemente für andere offene Posten hinzu -->
</ol>
<p><br></p>
<p>Zum aktuellen Zeitpunkt beläuft sich der ausstehende Gesamtbetrag auf <strong>${params.currencyPipe.transform(params.dunning.amountToPay, 'EUR')}</strong>.</p>
<p><br></p>
${renderFeesAndInterestBlock(params)}
${renderInterestCalculationList(params)}
<p>Wir setzen Ihnen eine letzte Frist bis zum <strong>${params.datePipe.transform(params.dunning.dueDate as Date, 'shortDate')}</strong>, um den gesamten ausstehenden Betrag mit dem Verwendungszweck <strong>H${params?.property?.h_id}/W${params.unit.w_id}</strong> auf das Ihnen bekannte Konto zu überweisen.</p>
<p><br></p>
<p><strong>${renderLastReminderTextBlock(params)}</strong></p>
<p><br></p>
<p>Sollten Sie den Betrag bereits angewiesen haben, betrachten Sie dieses Schreiben bitte als gegenstandslos</p>
<p><br></p>
<p>Bei Fragen stehen wir Ihnen selbstverständlich zur Verfügung.</p>
<p><br></p>
<p>${params.publicProfile?.greetings || 'Mit freundlichen Grüßen'}</p>
<p><br></p>
<p>${params.publicProfile?.sender || ''}</p>
`.replace(/>\s+</g, '><')
;

const buildOpList = (bookings: Bookings, datePipe: DatePipe, currencyPipe: CurrencyPipe) => {
    if (bookings?.length > 0) {
        const opList = bookings.map(booking => {
            return `<li><strong>${booking?.text || ''} - Fällig am ${datePipe.transform(convertTimestamp(booking.date as Date), 'shortDate')} - Betrag: ${currencyPipe.transform(Math.abs(booking.saldo), 'EUR')}</strong></li>`
        })
        return opList.join('');
    }
}

const renderFeesAndInterestBlock = (params: PaymentReminderTemplateParams) => {
    const dunning = params.dunning;
    const block: string[] = [];
    if (dunning?.enableLateFees) {
        block.push(`<p>Zusätzlich möchten wir Sie darauf hinweisen, dass aufgrund der verspäteten Zahlung nun Mahngebühren in Höhe von <strong>${params.currencyPipe.transform(dunning.feesToPay, 'EUR')} ${dunning.enablePaymentDelayInterest ? `sowie Verzugszinsen in Höhe von ${params.currencyPipe.transform(dunning.interestToPay, 'EUR')}` : ''}</strong> angefallen sind. Dies erhöht den Gesamtbetrag, der zu begleichen ist, auf <strong>${params.currencyPipe.transform(dunning.amountToPayInclFeesAndInterest, 'EUR')} inkl. Mahngebühren ${dunning.enablePaymentDelayInterest ? 'und Verzugszinsen' : ''}</strong>.</p>`);
        block.push(`<p><br></p>`);
        return block.join('');
    } else if (!dunning.enableLateFees && dunning.enablePaymentDelayInterest) {
        block.push(`<p>Zusätzlich möchten wir Sie darauf hinweisen, dass aufgrund der verspäteten Zahlung Verzugszinsen in Höhe von <strong>${params.currencyPipe.transform(dunning.interestToPay, 'EUR')}</strong> angefallen sind. Dies erhöht den Gesamtbetrag, der zu begleichen ist, auf <strong>${params.currencyPipe.transform(dunning.amountToPayInclFeesAndInterest, 'EUR')}</strong>.</p>`);
        block.push(`<p><br></p>`);
        return block.join('');
    } else {
        return ``;
    }
}

const renderInterestCalculationList = (params: PaymentReminderTemplateParams) => {
    const dunning = params.dunning;
    const block: string[] = [];

    if (dunning?.enablePaymentDelayInterest && dunning?.bookingsWithInterest?.length > 0) {
        block.push(`<p>Bezüglich der Verzugszinsen möchten wir Sie darüber informieren, dass diese auf Basis eines Zinssatzes von <strong>${dunning?.interestRate}%</strong> pro Jahr berechnet wurden. Die Berechnung erfolgte für jeden offenen Posten wie folgt:</p>`);
        block.push(`<p><br></p>`);
        block.push(`<ol>`);
        for (const bwi of dunning.bookingsWithInterest) {
            block.push(`<li>Sollstellung: <strong>${bwi?.booking?.text}</strong> - Fällig am <strong>${params.datePipe.transform(convertTimestamp(bwi.booking.date as Date), 'shortDate')}</strong> - Betrag: <strong>${params.currencyPipe.transform(Math.abs(bwi.booking.saldo))}</strong> - Überfällige Tage: <strong>${bwi.overdueDays}</strong> - Zinsbetrag: <strong>${params.currencyPipe.transform(Math.abs(bwi.interestAmount))}</strong></li>`)
        }
        block.push(`</ol>`);
        // block.push(`<p><br></p>`);
        block.push(`<!--<p>Die Summe der Verzugszinsen für alle offenen Posten beläuft sich auf <strong>${params.currencyPipe.transform(Math.abs(dunning.interestToPay))}</strong>. Dieser Betrag wurde zum Gesamtbetrag der offenen Posten hinzugerechnet, was zu einem zu begleichenden Gesamtbetrag von [Gesamtbetrag inkl. Verzugszinsen]€ führt.</p>-->`);
        block.push(`<p><br></p>`);
        return block.join('');
    } else {
        return ''
    }
}

const renderLastReminderTextBlock = (params: PaymentReminderTemplateParams) => {
    const dunning = params.dunning;
    switch (dunning?.lastWarningType) {
        case LastWarningTypeOptions.FINAL_WARNING_BEFORE_LEGAL_ACTION:
            return 'Vorsorglich weisen wir Sie darauf hin, dass wir bei Nichtzahlung bis zum genannten Datum ohne weitere Ankündigung einen Mahnbescheid gegen Sie erwirken werden, was mit erheblichen zusätzlichen Kosten für Sie verbunden ist.';
        case LastWarningTypeOptions.FINAL_WARNING_BEFORE_TERMINATION:
            return 'Bitte beachten Sie, dass dies die letzte Mahnung vor einer fristlosen Kündigung ist. Wir möchten weitere rechtliche Schritte vermeiden und bitten Sie daher, den ausstehenden Betrag umgehend zu begleichen.';
        default:
            return 'Vorsorglich weisen wir Sie darauf hin, dass dies unsere letzte Mahnung ist, bevor weitere Schritte eingeleitet werden. Bitte nehmen Sie diese Mahnung ernst und begleichen Sie den ausstehenden Betrag umgehend.';
    }
}
