import {Injectable} from '@angular/core';
import {Firestore} from '@angular/fire/firestore';
import {StatMsa} from '@msa/models';
import {AbstractFirestoreModularService} from '@shared/services/abstracts/AbstractFirestoreModularService';
import {AuthService} from '@shared/services/auth.service';
import {Transaction, Transactions} from '@transactions/models/transaction.interface';
import {BehaviorSubject} from 'rxjs';
import {BankAccounts} from "../../bankAccounts/models";

export interface TransactionsRange {
    start: Date | null;
    end: Date | null;
}

@Injectable()
export class TransactionsModularService extends AbstractFirestoreModularService<Transaction> {

    COLLECTION = 'transactions';

    displayedColumns: string[] =
        [
            // 'select',
            'process',
            'bank.accountHolder',
            'bank',
            'bookingDate',
            'remittanceInformationUnstructured',
            'transactionAmount.amount',
            'debtorName',
            'detectionStatus',
            // 'detectionStatus'
            // 'target.property'
        ];
    onBankAccountFilterChanged$: BehaviorSubject<BankAccounts> = new BehaviorSubject<BankAccounts>(null);
    onRangeChanged$: BehaviorSubject<TransactionsRange> = new BehaviorSubject<TransactionsRange>(null);
    onStatsChanged: BehaviorSubject<StatMsa> = new BehaviorSubject<StatMsa>(null);
    onListChanged$: BehaviorSubject<Transactions> = new BehaviorSubject<Transactions>(null);
    private _columns = new BehaviorSubject<string[]>(this.displayedColumns); // Standardauswahl
    columns$ = this._columns.asObservable();
    private _list: Transactions;

    constructor(authService: AuthService,
                firestore: Firestore) {
        super(authService, firestore);
    }

    set transactionsList(value: Transactions) {
        this._list = value;
        this.onListChanged$.next(value);
    }

    updateColumns(columns: string[]) {
        this._columns.next(columns);
    }

}
