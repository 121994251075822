import { environment } from '@env/environment';

const getNahausLogo = () => ({
  svg: '<svg fill="none" height="314" viewBox="0 0 984 314" width="984" xmlns="http://www.w3.org/2000/svg">\n' +
    '\t<path clip-rule="evenodd"\n' +
    '\t      d="M353.677 66.6124V313.336H276.711V105.707H175.923V313.336H0L44.4387 157.482H100.331V44.9516L175.923 0.0464172V61.3308C191.041 38.613 212.116 27.5172 239.144 27.5172H315.195C325.73 27.5172 334.895 31.2172 342.224 39.1415C350.012 47.0658 353.677 56.0473 353.677 66.6124Z"\n' +
    '\t      fill="#4F46E5" fill-rule="evenodd"/>\n' +
    '\t<path clip-rule="evenodd"\n' +
    '\t      d="M250.598 217.711C250.598 214.21 253.264 211.37 256.553 211.37C259.843 211.37 262.509 214.21 262.509 217.711C262.509 221.212 259.843 224.05 256.553 224.05C253.264 224.05 250.598 221.212 250.598 217.711Z"\n' +
    '\t      fill="#4F46E5" fill-rule="evenodd"/>\n' +
    '\t<path clip-rule="evenodd" d="M70.5522 216.126H120.947V274.769H70.5522V216.126Z" fill="white" fill-rule="evenodd"/>\n' +
    '\t<path clip-rule="evenodd" d="M92.2315 216.294H100.936V275.995H92.2315V216.294Z" fill="#4F46E5" fill-rule="evenodd"/>\n' +
    '\t<path clip-rule="evenodd" d="M70.0942 240.427H121.405V250.466H70.0942V240.427Z" fill="#4F46E5" fill-rule="evenodd"/>\n' +
    '\t<path clip-rule="evenodd"\n' +
    '\t      d="M613.112 117.589V310.195H563.026V229.298H548.036V310.195H497.952V117.589H548.036V186.47H563.026V117.589H613.112Z"\n' +
    '\t      fill="#444F60" fill-rule="evenodd"/>\n' +
    '\t<path clip-rule="evenodd"\n' +
    '\t      d="M674.737 213.474H629.649V202.888C629.649 190.675 631.056 181.254 633.871 174.633C636.687 168.01 642.338 162.16 650.824 157.085C659.311 152.01 670.336 149.471 683.897 149.471C700.156 149.471 712.41 152.345 720.658 158.097C728.907 163.845 733.864 170.905 735.528 179.272C737.195 187.641 738.027 204.871 738.027 230.964V310.195H691.273V296.157C688.338 301.787 684.551 306.013 679.913 308.828C675.272 311.644 669.74 313.051 663.315 313.051C654.909 313.051 647.196 310.691 640.177 305.974C633.159 301.254 629.649 290.922 629.649 274.981V262.013C629.649 250.197 631.512 242.146 635.238 237.863C638.966 233.582 648.207 228.585 662.96 222.875C678.741 216.687 687.188 212.524 688.299 210.382C689.409 208.24 689.965 203.878 689.965 197.296C689.965 189.047 689.35 183.673 688.12 181.176C686.891 178.677 684.848 177.429 681.994 177.429C678.741 177.429 676.719 178.478 675.926 180.581C675.133 182.684 674.737 188.135 674.737 196.938V213.474ZM689.965 235.128C682.272 240.759 677.81 245.477 676.58 249.285C675.352 253.091 674.737 258.564 674.737 265.701C674.737 273.872 675.272 279.145 676.343 281.525C677.414 283.903 679.535 285.094 682.708 285.094C685.721 285.094 687.684 284.161 688.598 282.299C689.509 280.434 689.965 275.537 689.965 267.605V235.128Z"\n' +
    '\t      fill="#444F60" fill-rule="evenodd"/>\n' +
    '\t<path clip-rule="evenodd"\n' +
    '\t      d="M864.609 152.326V310.195H815.713L816.546 297.11C813.214 302.423 809.11 306.409 804.232 309.066C799.355 311.722 793.743 313.051 787.398 313.051C780.182 313.051 774.193 311.782 769.434 309.245C764.675 306.707 761.166 303.335 758.906 299.131C756.644 294.928 755.238 290.547 754.681 285.987C754.128 281.426 753.851 272.364 753.851 258.803V152.326H801.913V259.753C801.913 272.048 802.289 279.343 803.043 281.644C803.796 283.943 805.839 285.094 809.17 285.094C812.737 285.094 814.86 283.903 815.534 281.525C816.208 279.145 816.546 271.492 816.546 258.564V152.326H864.609Z"\n' +
    '\t      fill="#444F60" fill-rule="evenodd"/>\n' +
    '\t<path clip-rule="evenodd"\n' +
    '\t      d="M980.005 202.768H937.415V194.203C937.415 186.905 936.998 182.286 936.167 180.343C935.334 178.4 933.251 177.429 929.92 177.429C927.225 177.429 925.202 178.321 923.854 180.106C922.506 181.89 921.831 184.565 921.831 188.135C921.831 192.973 922.168 196.521 922.841 198.783C923.517 201.043 925.539 203.523 928.909 206.219C932.281 208.915 939.201 212.84 949.67 217.996C963.628 224.816 972.788 231.242 977.151 237.269C981.512 243.296 983.694 252.061 983.694 263.561C983.694 276.41 982.028 286.105 978.697 292.648C975.367 299.191 969.795 304.228 961.981 307.757C954.169 311.287 944.752 313.051 933.727 313.051C921.514 313.051 911.064 311.147 902.379 307.341C893.694 303.533 887.727 298.379 884.475 291.875C881.224 285.372 879.599 275.537 879.599 262.37V254.757H922.187V264.75C922.187 273.236 922.723 278.748 923.794 281.286C924.865 283.825 926.985 285.094 930.159 285.094C933.569 285.094 935.949 284.241 937.297 282.536C938.645 280.83 939.319 277.241 939.319 271.769C939.319 264.235 938.446 259.516 936.702 257.611C934.878 255.708 925.559 250.079 908.746 240.719C894.627 232.788 886.021 225.591 882.929 219.125C879.837 212.663 878.289 204.989 878.289 196.107C878.289 183.497 879.954 174.197 883.286 168.208C886.617 162.221 892.268 157.6 900.239 154.35C908.209 151.098 917.47 149.471 928.017 149.471C938.486 149.471 947.389 150.799 954.726 153.455C962.063 156.115 967.694 159.624 971.619 163.985C975.545 168.348 977.925 172.391 978.756 176.121C979.589 179.848 980.005 185.678 980.005 193.608V202.768Z"\n' +
    '\t      fill="#444F60" fill-rule="evenodd"/>\n' +
    '\t<path clip-rule="evenodd"\n' +
    '\t      d="M417.923 209.976H372.833V199.388C372.833 187.173 374.242 177.756 377.058 171.133C379.873 164.51 385.524 158.661 394.011 153.586C402.496 148.51 413.52 145.972 427.083 145.972C443.341 145.972 455.595 148.847 463.844 154.597C472.093 160.347 477.048 167.406 478.715 175.773C480.38 184.14 481.214 201.371 481.214 227.465V306.696H434.46V292.657C431.524 298.289 427.738 302.514 423.097 305.329C418.457 308.143 412.927 309.552 406.501 309.552C398.094 309.552 390.381 307.191 383.363 302.471C376.342 297.754 372.833 287.423 372.833 271.481V258.514C372.833 246.697 374.698 238.647 378.425 234.364C382.152 230.082 391.394 225.086 406.144 219.373C421.928 213.188 430.374 209.025 431.484 206.883C432.595 204.741 433.151 200.379 433.151 193.797C433.151 185.548 432.536 180.174 431.306 177.676C430.076 175.177 428.035 173.93 425.179 173.93C421.928 173.93 419.905 174.979 419.111 177.081C418.318 179.184 417.923 184.637 417.923 193.439V209.976ZM433.151 231.628C425.456 237.259 420.997 241.977 419.767 245.785C418.536 249.591 417.923 255.063 417.923 262.202C417.923 270.372 418.457 275.646 419.528 278.025C420.599 280.404 422.721 281.593 425.893 281.593C428.906 281.593 430.869 280.662 431.781 278.799C432.693 276.934 433.151 272.038 433.151 264.105V231.628Z"\n' +
    '\t      fill="#4F46E5" fill-rule="evenodd"/>\n' +
    '</svg>',
  width: 100
  // absolutePosition: { x: 470, y: 20 }
});

const getJugLogo = (): any => ({
  svg: '<svg width="150px" height="85px" viewBox="0 0 150 85" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
    '    <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->\n' +
    '    <title>Untitled 3</title>\n' +
    '    <desc>Created with Sketch.</desc>\n' +
    '    <defs></defs>\n' +
    '    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" font-family="Galvji" font-weight="normal">\n' +
    '        <g id="Group" fill="#302B2B">\n' +
    '            <text id="JUG" font-size="64">\n' +
    '                <tspan x="12.03125" y="62">JUG</tspan>\n' +
    '            </text>\n' +
    '            <text id="_VERWALTUNG_" font-size="10">\n' +
    '                <tspan x="36.054759" y="78">_VERWALTUNG_</tspan>\n' +
    '            </text>\n' +
    '        </g>\n' +
    '    </g>\n' +
    '</svg>',
  width: 100,
  absolutePosition: { x: 470, y: 20 }
});

export const getLogo = () => environment?.pool ? getNahausLogo() : getJugLogo();
