<div class="relative flex flex-col w-full h-full">

	<!-- Dismiss button -->
	<div class="absolute top-0 right-0 pt-4 pr-4">
		<button
				[matDialogClose]="undefined"
				mat-icon-button>
			<mat-icon
					[svgIcon]="'heroicons_outline:x'"
					class="text-secondary"></mat-icon>
		</button>
	</div>


	<!-- Content -->
	<div class="flex flex-col space-y-6 p-8 pb-6 sm:pb-8">
		<div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start">

			<!-- Icon -->
			<div
					class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600">
				<mat-icon
						class="text-current" svgIcon="mat_outline:ondemand_video"></mat-icon>
			</div>


			<div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">

				<!-- Title -->
				<div class="text-xl leading-6 font-medium">{{this.title}}</div>

				<!-- Message -->
				<div class="text-secondary">{{this.subtitle}}</div>
			</div>

		</div>

		<div class="w-full h-48 sm:h-80 md:h-96 lg:h-128">
			<youtube-player [height]="'100%'"
			                [playerVars]="{rel: 0}"
			                [videoId]="this.videoID"
			                [width]="'100%'"
			                suggestedQuality="highres">
			</youtube-player>
		</div>
	</div>

	<!-- Actions -->
	<div class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

		<!-- Cancel -->
		<button
				[matDialogClose]="'IGNORE'"
				mat-stroked-button>
			Nicht mehr anzeigen
		</button>

		<!-- Confirm -->
		<button
				[matDialogClose]="'CONTINUE'"
				color="primary"
				mat-flat-button>
			Weiter
		</button>

	</div>

</div>
