import { Injectable } from '@angular/core';
import { AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';
import { Booking } from '../models/booking';
import { BookingAccount, BookingAccountOptions } from '../models/bookingAccount';

@Injectable()
export class BookingAccountService extends AbstractFirestoreService<BookingAccount> {

  COLLECTION = 'bookingAccounts';

  bookingAccountOptions = BookingAccountOptions;

  constructor(authService: AuthService) {
    super(authService);
  }

  setParentPathForPeriod(propertyID: string, unitID: string, periodID: string): void {
    this.parentPath = `properties/${propertyID}/units/${unitID}/periods/${periodID}`;
  }

  setParentPathForManagement(propertyID: string, unitID: string, managementID: string): void {
    this.parentPath = `properties/${propertyID}/units/${unitID}/managements/${managementID}`;
  }

  getSaldoAccount(): AngularFirestoreDocument<BookingAccount> {
    return this.doc(this.bookingAccountOptions.saldo);
  }

  getRentalAccount(): AngularFirestoreDocument<BookingAccount> {
    return this.doc(this.bookingAccountOptions.rental);
  }

  getDepositAccount(): AngularFirestoreDocument<BookingAccount> {
    return this.doc(this.bookingAccountOptions.deposit);
  }
}
