import { Pipe, PipeTransform } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Pipe({
    name: 'errorColor',
    standalone: true
})
export class ErrorColorPipe implements PipeTransform {

  transform(value: ThemePalette, isError: boolean): ThemePalette {
    return isError ? 'warn' : value;
  }

}
