<div *ngIf="this.loading" class="flex justify-center mb-4">
    <mat-progress-spinner [diameter]="48" mode="indeterminate"></mat-progress-spinner>
</div>
<fuse-card class="w-full max-h-128">
    <pdf-viewer (page-rendered)="pageRendered($event)"
                [original-size]="false"
                [render-text]="true"
                [src]="this.url"
                style="width: 100%; height: 500px"
    ></pdf-viewer>
</fuse-card>
