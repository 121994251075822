import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {
    ImageEditorDialogComponent
} from '@shared/components/filepond/image-editor-dialog/image-editor-dialog.component';
import {defaultAvatarSize} from '@shared/angular-dropzone/models/constants';
import {AvatarCropper} from '@shared/angular-dropzone/models/file.model';
import Cropper from 'cropperjs';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import {NgIf} from '@angular/common';

@Component({
    selector: 'lib2-cropperjs',
    templateUrl: './cropperjs.component.html',
    styleUrls: ['./cropperjs.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, MatButtonModule, MatDialogModule, MatProgressBarModule]
})
export class CropperjsComponent implements OnInit, AfterViewInit {

  @Input() file!: File;
  @Input() avatar: AvatarCropper = { width: defaultAvatarSize, height: defaultAvatarSize, round: true };
  @Output() croppedAvatar = new EventEmitter<File>();
  @Output() cancelCrop = new EventEmitter<boolean>();

  @ViewChild('cropper') cropper!: ElementRef<HTMLImageElement>;

  loadingImage: boolean = true;

  private cropperInstance!: Cropper;
  cropped: any;

  constructor(private cf: ChangeDetectorRef,
              private matDialogRef: MatDialogRef<ImageEditorDialogComponent>) {
  }


  convertBlobToBase64(blob: Blob, element: HTMLImageElement) {
    let reader = new FileReader();
    reader.readAsDataURL(blob); // converts the blob to base64 and calls onload

    reader.onload = () => {
      element.src = reader.result as string; // data url
      this.cropperInstance = new Cropper(this.cropper.nativeElement, {
        aspectRatio: 1,
        viewMode: 0,
        dragMode: 'crop',
        guides: false,
        minContainerWidth: 100
      });
      this.loadingImage = false;
    };
  }

  getImageBlob() {
    this.cropperInstance.getCroppedCanvas().toBlob((blob) => {
      const file = new File([blob!], this.file.name);
      // let reader = new FileReader();
      // reader.readAsDataURL(file);
      // console.log('reader = ', reader);
      // reader.onload = () => {
      //   this.cropped = reader.result as string;
      // };
      // console.log('blob = ', blob);
      // console.log('file = ', file);
      // console.log('cropped = ', this.cropped);
      // this.croppedAvatar.emit(file);
      // this.cf.markForCheck();
      // this.matDialogRef.close(file);
      this.matDialogRef.close(blob);
      // setTimeout(() => this.matDialogRef.close(file), 2000);
    }, 'image/jpeg');
    this.cropperInstance.crop();

    console.log('crop --> ', this.cropperInstance);
    this.cf.markForCheck();
  }

  onCancel() {
    this.cancelCrop.emit(false);
  }

  ngOnInit() {
    this.cf.markForCheck();
  }

  ngAfterViewInit(): void {
    this.convertBlobToBase64(this.file, this.cropper.nativeElement);
  }

}
