<div class="relative flex flex-col w-full h-full">

    <!-- Dismiss button -->
    <ng-container>
        <div class="absolute top-0 right-0 pt-4 pr-4">
            <button
                [matDialogClose]="undefined"
                mat-icon-button>
                <mat-icon
                    [svgIcon]="'heroicons_outline:x'"
                    class="text-secondary"></mat-icon>
            </button>
        </div>
    </ng-container>

    <!-- Content -->
    <div class="flex flex-col">
        <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">

            <!-- Icon -->
            <ng-container>
                <div
                    class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600">
                    <mat-icon
                        [svgIcon]="'mat_outline:folder_open'"
                        class="text-current"></mat-icon>
                </div>
            </ng-container>

            <ng-container>
                <div
                    class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">

                    <!-- Title -->
                    <ng-container>
                        <div
                            class="text-xl leading-6 font-medium">{{this.title}}
                        </div>
                    </ng-container>

                    <!-- Message -->
                    <ng-container>
                        <div *ngIf="this.message" class="text-secondary">
                            {{this.message}}
                        </div>
                    </ng-container>
                </div>
            </ng-container>

        </div>


        <div class="flex flex-row space-x-4 w-full mb-8">
            <div class="flex items-center">
                <button (click)="this.prevDoc()" mat-icon-button>
                    <mat-icon
                        [svgIcon]="'heroicons_outline:chevron-left'"
                        class="icon-size-5"></mat-icon>
                </button>
            </div>
            <div [ngSwitch]="this.selectedFile?.type" class="flex justify-center w-full h-full">
                <app-pdf-loader *ngSwitchCase="'application/pdf'" @zoomIn @zoomOut
                                [url]="this.selectedFile?.url"></app-pdf-loader>
                <app-image-loader *ngSwitchCase="'image/jpg'" @zoomIn @zoomOut
                                  [url]="this.selectedFile | toThumb:512"></app-image-loader>
                <app-image-loader *ngSwitchCase="'image/jpeg'" @zoomIn @zoomOut
                                  [url]="this.selectedFile| toThumb:512"></app-image-loader>
                <app-image-loader *ngSwitchCase="'image/png'" @zoomIn @zoomOut
                                  [url]="this.selectedFile | toThumb:512"></app-image-loader>
                <div *ngSwitchDefault>
                    <img @zoomIn @zoomOut
                         src="assets/illustrations/warning/default/undraw_warning.svg">
                    <span @zoomIn @zoomOut
                          class="text-warn">Der Dateityp wird nicht unterstützt</span>
                </div>
            </div>
            <div class="flex items-center">
                <button (click)="this.nextDoc()" mat-icon-button>
                    <mat-icon
                        [svgIcon]="'heroicons_outline:chevron-right'"
                        class="icon-size-5"></mat-icon>
                </button>
            </div>
        </div>
    </div>

    <!-- Actions -->
    <ng-container>
        <div
            class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

            <!-- Cancel -->
            <ng-container>
                <button
                    [matDialogClose]="'cancelled'"
                    mat-stroked-button>
                    Abbrechen
                </button>
            </ng-container>

            <!-- Confirm -->
            <ng-container>
                <button (click)="this.download()"
                        [disabled]="this.downloading"
                        color="primary"
                        mat-flat-button>
                    <span *ngIf="!this.downloading">Herunterladen</span>
                    <mat-progress-spinner
                        *ngIf=this.downloading
                        [diameter]="24"
                        [mode]="'indeterminate'"
                        matTooltip="Bitte warten..."></mat-progress-spinner>
                </button>
            </ng-container>

        </div>
    </ng-container>

</div>
