import { GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import { Pipe, PipeTransform } from '@angular/core';
import { formatAddressfromCSV } from '../../helpers/address';

@Pipe({
    name: 'formatAddressCSV',
    standalone: true
})
export class FormatAddressCSVPipe implements PipeTransform {

  transform(value: GermanAddress): unknown {
    return formatAddressfromCSV(value);
  }

}
