import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CustomerLogo } from '@settings/models/logo.settings.interface';
import { LogoSettingsService } from '@settings/services/logoSettings.service';
import { NahausTiersOptions } from '@users/models/user.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LogoSettingsResolver implements Resolve<CustomerLogo> {

  constructor(private logoSettingsService: LogoSettingsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CustomerLogo> {
    if ([NahausTiersOptions.PRO, NahausTiersOptions.ENTERPRISE].includes(this.logoSettingsService?.authService?.tier)) {
      return this.logoSettingsService.doc().get().pipe(map(doc => doc?.data() as CustomerLogo));
    } else {
      return null;
    }
  }
}
