import { Injectable } from '@angular/core';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';
import { BookingAccountStat } from '../models/bookingAccount';

@Injectable()
export class BookingAccountHKStatsService extends AbstractFirestoreService<BookingAccountStat> {

  COLLECTION = 'stats';

  constructor(authService: AuthService) {
    super(authService);
  }

  setParentPathForPeriod(propertyID: string, unitID: string, periodID: string): void {
    this.parentPath = `properties/${propertyID}/units/${unitID}/periods/${periodID}/bookingAccounts/hk`;
  }
}
