import { Injectable, Self } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractFirestoreDocResolver } from '@shared/services/abstracts/AbstractFirestoreDocResolver';
import { TenantsService } from '@tenants/services/tenants.service';
import { Tenant } from '../../properties/features/units/features/period/features/tenants/models';


@Injectable()
export class TenantResolver extends AbstractFirestoreDocResolver<Tenant> {

  constructor(route: ActivatedRoute, router: Router, @Self() service: TenantsService) {
    super(router, service);
  }
}
