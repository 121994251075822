import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FuseCardComponent} from '@fuse/components/card/card.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgIf} from '@angular/common';

@Component({
    selector: 'app-image-loader',
    templateUrl: './image-loader.component.html',
    styleUrls: ['./image-loader.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule, FuseCardComponent]
})
export class ImageLoaderComponent implements OnInit, OnChanges {

    @Input() url: string;

    loading = true;

    constructor() {
    }

    ngOnInit(): void {
    }

    onImageLoad($event: any): void {
        this.loading = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.url?.currentValue !== changes?.url?.previousValue) {
            this.loading = true;
            this.url = changes?.url?.currentValue;
        }
    }

}
