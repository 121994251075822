import {Injectable} from '@angular/core';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';
import {ICard} from "../models/scrumboard.types";

@Injectable()
export class CardsService extends AbstractFirestoreService<ICard> {

    COLLECTION = 'cards';

    constructor(authService: AuthService) {
        super(authService);
    }

    setParentPath(cardID: string): void {
        this.parentPath = `scrumboards/${cardID}`;
    }
}
