import { createReducer, on } from '@ngrx/store';
import { NahausTier, NahausTiersOptions, User } from '@users/models/user.interface';
import firebase from 'firebase/compat';
import * as AppActions from '../actions/app.actions';


export const appFeatureKey = 'auth';

// export interface AppState {
//   auth: AuthState;
// }

export interface AuthState {
  authUser: Partial<firebase.User>;
  firestoreUser: User;
  customerID: string;
  role: NahausTier;
}

export const initialState: AuthState = {
  authUser: null,
  firestoreUser: null,
  customerID: null,
  role: null
};

export const appReducer = createReducer(
  initialState,
  on(AppActions.userReceivedFromFirebaseAuthentication, (state, { user }) => ({ ...state, authUser: user })),
  on(AppActions.userReceivedFromFirestore, (state, { user }) => ({ ...state, firestoreUser: user })),
  on(AppActions.userNahausRoleReceived, (state, { role }) => ({ ...state, role })),
  on(AppActions.resetUserFirestore, state => ({ ...state, firestoreUser: null })),
  on(AppActions.receivedCustomerID, (state, { customerID }) => ({ ...state, customerID }))
);

export const selectAuthUser = (state: AuthState): Partial<firebase.User> => state.authUser;
export const selectFirestoreUser = (state: AuthState): User => state.firestoreUser;
export const selectRole = (state: AuthState): NahausTier => state.role;
export const selectCustomerID = (state: AuthState): string => state.customerID;
// export const selectRole2 = createSelector(state => state, selectRole(st));
