<div class="relative flex flex-col w-full h-full">

    <!-- Dismiss button -->
    <div class="absolute top-0 right-0 pt-4 pr-4">
        <button
            [matDialogClose]="undefined"
            mat-icon-button>
            <mat-icon
                [svgIcon]="'heroicons_outline:x'"
                class="text-secondary"></mat-icon>
        </button>
    </div>

    <!-- Content -->
    <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">

        <!-- Icon -->
        <div
            class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600">
            <mat-icon
                [svgIcon]="'mat_outline:delete_forever'"
                class="text-current"></mat-icon>
        </div>


        <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">

            <!-- Title -->
            <div
                class="text-xl leading-6 font-medium">Löschen
            </div>

            <!-- Message -->
            <div class="text-secondary">
                Möchten Sie die Buchung <span class="font-extrabold">{{this.booking?.text}}</span> wirklich löschen?
            </div>
        </div>


    </div>

    <!-- Actions -->
    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

        <!-- Cancel -->
        <button
            [matDialogClose]="'cancelled'"
            mat-stroked-button>
            Abbrechen
        </button>

        <!-- Confirm -->
        <button (click)="this.delete()"
                [color]="'warn'"
                [disabled]="this.isDeleting"
                [matDialogClose]="'confirmed'"
                [matTooltip]="'Löschen'"
                mat-flat-button>
            <span *ngIf="!this.isDeleting">Löschen</span>
            <mat-progress-spinner
                *ngIf=this.isDeleting
                [diameter]="24"
                [mode]="'indeterminate'"
                matTooltip="Wird gelöscht..."></mat-progress-spinner>
        </button>

    </div>

</div>
