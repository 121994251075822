import {PublicProfileSettings} from '@settings/models/publicProfile.settings.interface';
import {Landlord} from '../../landlords/models';
import {Manager} from "../../managers/models";
import {CustomerLogo} from "@settings/models/logo.settings.interface";

export const getPublicProfileData = (publicProfile: PublicProfileSettings | null, landlord: Landlord, manager?: Manager) => {
    if (!!publicProfile) {
        return publicProfile;
    } else if (!!manager?.id) {
        const publicProfileFromLandlord: PublicProfileSettings = {
            id: manager.id,
            companyName: manager?.companyName,
            hrb:manager?.hrb,
            // companyComplement: 'Unternehemen',
            firstName: manager?.firstName,
            lastName: manager?.lastName,
            address: manager?.address,
            sender: manager?.companyName ? manager?.companyName : `${manager?.firstName} ${manager?.lastName}`,
            phoneNumber: manager?.phoneNumbers?.length > 0 ? manager.phoneNumbers[0].value : undefined,
            email: manager?.emails?.length > 0 ? manager.emails[0].value : undefined,
            features: {
                hasEmail: true,
                hasPhoneNumber: true,
                hasName:true,
                hasHrbNumber:true,
                hasVatID:true,

            }
        };
        return publicProfileFromLandlord;
    } else {
        const publicProfileFromLandlord: PublicProfileSettings = {
            id: landlord?.id,
            companyName: landlord?.companyName,
            hrb:landlord?.hrb,
            // companyComplement: 'Unternehemen',
            firstName: landlord?.firstName,
            lastName: landlord?.lastName,
            address: landlord?.address,
            sender: landlord?.companyName ? landlord?.companyName : `${landlord?.firstName} ${landlord?.lastName}`,
            phoneNumber: landlord?.phoneNumbers?.length > 0 ? landlord.phoneNumbers[0].value : undefined,
            email: landlord?.emails?.length > 0 ? landlord.emails[0].value : undefined,
            features: {
                hasEmail: true,
                hasPhoneNumber: true,
                hasName:true,
                hasHrbNumber:true,
                hasVatID:true,

            }
        };
        return publicProfileFromLandlord;
    }
};

export const getPublicProfileDataWithLogo = (publicProfile: PublicProfileSettings | null, landlord: Landlord, manager: Manager, logo: CustomerLogo) => {
    if (!!publicProfile) {
        publicProfile.logo = logo;
        return publicProfile;
    } else if (!!manager?.id) {
        const publicProfileFromLandlord: PublicProfileSettings = {
            logo,
            id: manager.id,
            companyName: manager?.companyName,
            hrb:manager?.hrb,
            // companyComplement: 'Unternehemen',
            firstName: manager?.firstName,
            lastName: manager?.lastName,
            address: manager?.address,
            sender: manager?.companyName ? manager?.companyName : `${manager?.firstName} ${manager?.lastName}`,
            phoneNumber: manager?.phoneNumbers?.length > 0 ? manager.phoneNumbers[0].value : undefined,
            email: manager?.emails?.length > 0 ? manager.emails[0].value : undefined,
            features: {
                hasEmail: true,
                hasPhoneNumber: true,
                hasName:true,
                hasHrbNumber:true,
                hasVatID:true,
            }
        };
        return publicProfileFromLandlord;
    } else {
        const publicProfileFromLandlord: PublicProfileSettings = {
            logo,
            id: landlord?.id,
            companyName: landlord?.companyName,
            hrb:landlord?.hrb,
            // companyComplement: 'Unternehemen',
            firstName: landlord?.firstName,
            lastName: landlord?.lastName,
            address: landlord?.address,
            sender: landlord?.companyName ? landlord?.companyName : `${landlord?.firstName} ${landlord?.lastName}`,
            phoneNumber: landlord?.phoneNumbers?.length > 0 ? landlord.phoneNumbers[0].value : undefined,
            email: landlord?.emails?.length > 0 ? landlord.emails[0].value : undefined,
            features: {
                hasEmail: true,
                hasPhoneNumber: true,
                hasName:true,
                hasHrbNumber:true,
                hasVatID:true,
            }
        };
        return publicProfileFromLandlord;
    }
};
