import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {
    FormBuilder,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import {MatButton, MatButtonModule} from '@angular/material/button';
import {ShortcutsService} from './shortcuts.service';
import {Shortcut} from './shortcuts.types';
import {Subject, takeUntil} from 'rxjs';
import {MatButtonToggleChange, MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {RouterLink} from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgIf, NgFor, NgClass, NgTemplateOutlet} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';

@Component({
    selector: 'shortcuts-old',
    templateUrl: './shortcuts.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'shortcuts',
    standalone: true,
    imports: [MatButtonModule, MatIconModule, NgIf, MatTooltipModule, NgFor, NgClass, NgTemplateOutlet, RouterLink, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonToggleModule, MatSlideToggleModule]
})
export class ShortcutsComponent implements OnInit, OnDestroy {
  mode: 'view' | 'modify' | 'add' | 'edit' = 'view';
  shortcutForm: UntypedFormGroup;
  shortcuts: Shortcut[];
  shortcut: Shortcut;
  isSaving : boolean
  @ViewChild('shortcutsOrigin') private _shortcutsOrigin: MatButton;
  @ViewChild('shortcutsPanel') private _shortcutsPanel: TemplateRef<any>;
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
 iconList = [
        {name: 'home', isSelected: false},
        {name: 'assignment_turned_in', isSelected: false},
        {name: 'calendar_today', isSelected: false},
        {name: 'check_circle', isSelected: false},
        {name: 'commute', isSelected: false},
        {name: 'dashboard', isSelected: false},
        {name: 'description', isSelected: false},
        {name: 'event', isSelected: false},
        {name: 'settings', isSelected: false},
        {name: 'language', isSelected: false},
    ];
  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _formBuilder: FormBuilder,
    private _shortcutsService: ShortcutsService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private formBuilder: UntypedFormBuilder,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Initialize the form
    console.log('we are opening the shortcuts')
    this.getCurrentUserDate()

    this.shortcutForm = this.createFormGroup();
    // this.shortcutForm = this._formBuilder.group({
    //   id: this.shortcut?.id ||  this._shortcutsService.createID(),
    //   label: [this.shortcut?.label ? this.shortcut?.label : '', Validators.required],
    //   description: [this.shortcut?.description],
    //   icon: [this.shortcut?.icon ? this.shortcut?.icon : '', Validators.required],
    //   link: [this.shortcut?.link ? this.shortcut?.link : '', Validators.required],
    //   useRouter: [this.shortcut?.useRouter ? this.shortcut?.label : false, Validators.required]
    // });

    // Get the shortcuts
    this._shortcutsService.shortcuts$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((shortcuts: Shortcut[]) => {

        // Load the shortcuts
        this.shortcuts = shortcuts;

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
      this.getAllData()
  }
  createFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      id: this.shortcut?.id ||  this._shortcutsService.createID(),
      label: [this.shortcut?.label ? this.shortcut?.label : '', Validators.required],
      description: [this.shortcut?.description],
      icon: [this.shortcut?.icon ? this.shortcut?.icon : '', Validators.required],
      link: [this.shortcut?.link ? this.shortcut?.link : '', Validators.required],
      useRouter: [this.shortcut?.useRouter ? this.shortcut?.label : false]
    });
}
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();

    // Dispose the overlay
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open the shortcuts panel
   */
  openPanel(): void {
    // Return if the shortcuts panel or its origin is not defined
    if (!this._shortcutsPanel || !this._shortcutsOrigin) {
      return;
    }

    // Make sure to start in 'view' mode
    this.mode = 'view';

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(new TemplatePortal(this._shortcutsPanel, this._viewContainerRef));
  }

  /**
   * Close the shortcuts panel
   */
  closePanel(): void {
    this._overlayRef.detach();
  }

  /**
   * Change the mode
   */
  changeMode(mode: 'view' | 'modify' | 'add' | 'edit'): void {
    // Change the mode
    this.mode = mode;
  }

  /**
   * Prepare for a new shortcut
   */
  newShortcut(): void {
    // Reset the form
    this.shortcut = null
    this.shortcutForm?.reset();

    // Enter the add mode
    this.mode = 'add';
  }

  /**
   * Edit a shortcut
   */
  editShortcut(shortcut: Shortcut): void {
    this.shortcut = shortcut
    // Reset the form with the shortcut
    this.shortcutForm.reset(shortcut);

    // Enter the edit mode
    this.mode = 'edit';
  }

  /**
   * Save shortcut
   */
  save(editedShortcutID? : string): void {
    // Get the data from the form
    // this.getAllData()
    this.isSaving = true
    console.log('the id of updating one is' , editedShortcutID)
    const newID = editedShortcutID || this._shortcutsService.createID()
    this.shortcutForm.get('id').patchValue(newID);
    console.log('we are here' , newID)
    console.log('we are here' , this._shortcutsService.createID())
    const shortcut = this.shortcutForm.value;
    console.log('the value of the form are' ,shortcut )
    const userId =  this._shortcutsService.authService.authUser.uid
    this._shortcutsService.setParentPath(userId)
    this._shortcutsService
    .doc(shortcut?.id)
    .set(shortcut, {merge: true})
    .then(() => {
      this._changeDetectorRef.markForCheck()
      console.log('the shortcut is added successfully' )
      this.isSaving = false
    }).catch(err =>{
      console.log('error added shortcut' ,  err)
      this.isSaving = false
    })


    // const user = this._shortcutsService.authService.currentUser$.
    // If there is an id, update it...
    // if (shortcut.id) {
    //   this._shortcutsService.update(shortcut.id, shortcut).subscribe();
    // }
    // // Otherwise, create a new shortcut...
    // else {
    //   this._shortcutsService.create(shortcut).subscribe();
    // }

    // Go back the modify mode
    this.mode = 'modify';
  }
  selectIcon($event: MatButtonToggleChange){
   console.log('the selected one is', $event.value)
  }
getAllData(){
  const userId =  this._shortcutsService.authService.authUser.uid
  this._shortcutsService.setParentPath(userId)
  this._shortcutsService.collection().valueChanges().pipe(takeUntil(this._unsubscribeAll)).subscribe(
    (shortcuts : Shortcut[]) => {
        this.shortcuts = shortcuts
        console.log('the all data for shortcuts are' , shortcuts)
        this._changeDetectorRef.markForCheck()
    }
)
}
  /**
   * Delete shortcut
   */
  delete(id): void {
    // Get the data from the form
    const shortcut = this.shortcutForm.value;
    const userId =  this._shortcutsService.authService.authUser.uid
    this._shortcutsService.setParentPath(userId)
    // Delete
    // this._shortcutsService.delete(shortcut.id).subscribe();
    this._shortcutsService.doc(id).delete().then(()=>{
      console.log('it is deleted successfully')
      this.mode = 'modify';
    })
    // Go back the modify mode

  }
  getCurrentUserDate(){
    this._shortcutsService.authService.getCurrentUser().subscribe((data) => {
        console.log("data for user are", data)
    })
    const userId =  this._shortcutsService.authService.authUser.uid
    console.log("the user id are" , userId)
    // this.usersService.doc(userId).get().subscribe((data)=>{
    // console.log('the data for the current  user areeee' , data.data())
    // this.currentUser = data.data()
    // })
}
  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item?.id || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create the overlay
   */
  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay.position()
        .flexibleConnectedTo(this._shortcutsOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom'
          }
        ])
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }
}
