import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {NgIf} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

export interface ShowImgDialogData {
  url: string;
  title?: string;
  message?: string;
}

@Component({
    selector: 'show-img-dialog',
    templateUrl: './show-img-dialog.component.html',
    styles: [
        `
      .fuse-confirmation-dialog-panel {
        @screen md {
          @apply w-128;
        }

        .mat-mdc-dialog-container {

          .mat-mdc-dialog-surface {
            padding: 0 !important;
          }
        }
      }
    `
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatButtonModule, MatDialogModule, MatIconModule, NgIf]
})
export class ShowImgDialogComponent {
  /**
   * Constructor
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: ShowImgDialogData) {
  }

}
