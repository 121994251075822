import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDrawerToggleResult} from '@angular/material/sidenav';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Params, Router, RouterLink} from '@angular/router';
import {environment} from '@env/environment';
import {fuseAnimations} from '@fuse/animations';
import {FuseConfirmationService} from '@fuse/services/confirmation-old';
import {HotkeysService} from '@ngneat/hotkeys';
import {PermissionOptions} from '@users/models/user.interface';
import {debounceTime, Subject, switchMap, take} from 'rxjs';
import {filter, map, startWith, takeUntil, tap} from 'rxjs/operators';
import {FuseConfigService} from "@fuse/services/config-old";
import {Expense, ExpenseTypeOptions} from "../../../models/expenses.interface";
import {ExpensesListComponent} from "../expenses-list.component";
import {ExpensesModularService} from "../../../services/expenses-modular.service";
import {AppConfig} from "../../../../../../core/config/app.config";
import {convertTimestamp, findInvalidControls} from "../../../../../../helpers";
import {NahausBookingsAccount} from "../../../../bookingsAccounts/models/NahausBookingsAccount.interface";
import {InstantSearchConfig} from "angular-instantsearch/instantsearch/instantsearch";
import {Property} from "../../../../properties/models/property.interface";
import {pick} from "lodash-es";
import {UnitsService} from "../../../../properties/features/units/services/units.service";
import {PropertyUnit} from "../../../../properties/features/units/models/unit.interface";
import {ExpensesService} from "../../../services/expenses.service";
import {ExpensesFilePondComponent} from "../../expenses-file-pond/expenses-file-pond.component";
import {ExpensesImagesService} from "../../../services/expenses-images.service";
import {
    FilesPreviewDataDialog,
    NahausImagesPreviewDialogComponent
} from "@shared/components/files-preview/nahaus-images-preview-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {FormatAddressPipe, ToDatePipe} from '@shared/pipes';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {
    AlgoliaAutocompleteComponent
} from '@shared/algolia/components/algolia-autocomplete/algolia-autocomplete.component';
import {NgAisInstantSearchModule} from 'angular-instantsearch';
import {FuseAlertComponent} from '@fuse/components/alert';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgxCurrencyModule} from 'ngx-currency';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import {FuseCardComponent} from '@fuse/components/card';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {CurrencyPipe, DatePipe, NgClass, NgFor, NgIf} from '@angular/common';

@Component({
    selector: 'create-expense',
    templateUrl: './create-expense.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    providers: [UnitsService, ExpensesImagesService],
    standalone: true,
    imports: [NgIf, MatProgressBarModule, MatButtonModule, MatTooltipModule, MatIconModule, NgClass, FuseCardComponent, RouterLink, FormsModule, ReactiveFormsModule, MatDividerModule, MatRadioModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, NgxCurrencyModule, MatCheckboxModule, MatSelectModule, NgFor, MatOptionModule, FuseAlertComponent, NgAisInstantSearchModule, AlgoliaAutocompleteComponent, MatProgressSpinnerModule, ExpensesFilePondComponent, CurrencyPipe, DatePipe, ToDatePipe, FormatAddressPipe]
})
export class CreateExpenseComponent implements OnInit, OnDestroy {

    @ViewChild('filePond')
        // @ViewChild("filePond") filePond: any;
    filePondComponent: ExpensesFilePondComponent;

    appTheme: string;

    // data
    expense: Expense;

    nahausBookingsAccounts: NahausBookingsAccount[];
    units: PropertyUnit[];

    configProperties: InstantSearchConfig;

    // forms
    expenseFormGroup: FormGroup;
    configForm: UntypedFormGroup;

    availableYears: number[] = [];

    // indicators
    editMode: boolean = false;
    deleting: boolean;
    updating: boolean;
    loading: boolean;
    loadingUnits: boolean;
    creating: boolean;


    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _unsubscribeWhenIdChanges: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef,
        private expensesListComponent: ExpensesListComponent,
        private expensesModularService: ExpensesModularService,
        private formBuilder: FormBuilder,
        private router: Router,
        private matSnackbar: MatSnackBar,
        private fuseConfirmationService: FuseConfirmationService,
        private _fuseConfigService: FuseConfigService,
        private unitsService: UnitsService,
        private expensesService: ExpensesService,
        private hotkeys: HotkeysService,
        private expensesImagesService: ExpensesImagesService,
    ) {
        this.configProperties = {
            indexName: environment.algolia.indexName.properties,
            searchClient: this.expensesModularService?.authService?.getAlgoliaSearchClient(true),
            routing: true
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // Open the drawer
        this.expensesListComponent.drawer.open();
        this.initializeAvailableYears();

        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: AppConfig) => {
                console.log('config', config);
                this.appTheme = config?.theme.split('-')[1];
                this.changeDetectorRef.markForCheck();
            });

        this.nahausBookingsAccounts = this.route.snapshot?.parent?.data?.nahausBookingsAccounts as NahausBookingsAccount[];
        console.log("nahausBookingsAccounts received from FS --> ", this.nahausBookingsAccounts);

        if (this.route?.snapshot?.data?.expense) {
            this.expense = this.route.snapshot.data.expense;
            this.changeDetectorRef.markForCheck();
        }

        console.log('this.expense == ', this.expense);

        // Create the contact form
        this.expenseFormGroup = this.createFormGroup();
        this.listenOnFormChanges();


        if (!environment?.production) {
            this.listenOnHotKeys();
        }

        // Get the landlord
        if (!this.expense) {
            this.toggleEditMode(true);
            // Mark for check
            this.changeDetectorRef.markForCheck();
        }

        this.listenOnFirestoreDocChanges();

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        this._unsubscribeWhenIdChanges.next(null);
        this._unsubscribeWhenIdChanges.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    get formControls() {
        return this.expenseFormGroup?.controls;
    }

    /**
     * Close the drawer
     */
    closeDrawer(): Promise<MatDrawerToggleResult> {
        return this.expensesListComponent.drawer.close();
    }

    /**
     * Toggle edit mode
     *
     * @param editMode
     */
    toggleEditMode(editMode: boolean | null = null): void {
        if (editMode === null) {
            this.editMode = !this.editMode;
        } else {
            this.editMode = editMode;
        }

        console.log("get raw value --> ", this.expenseFormGroup.getRawValue())

        // Mark for check
        this.changeDetectorRef.markForCheck();
    }

    createFormGroup(): UntypedFormGroup {
        console.log("this.expense --> ", this.expense)
        return this.formBuilder.group({
            id: this.expense?.id || this.expensesModularService.createID(),
            customerID: this.expense?.customerID || this.expensesModularService?.authService?.customerID,
            type: this.formBuilder.control(this.expense?.type || ExpenseTypeOptions.EXPENSE),
            date: this.formBuilder.control(this.expense?.date ? convertTimestamp(this.expense?.date) : null, Validators.required),
            category: this.formBuilder.control(this.expense?.category),
            amount: this.formBuilder.control(this.expense?.amount, [Validators.required, Validators.min(0)]),
            includesVAT: this.formBuilder.control(this.expense?.includesVAT ?? true),
            vatRate: this.formBuilder.control(this.expense?.vatRate || 19, [Validators.required, Validators.min(0)]),
            notes: this.expense?.notes,
            nahausBookingsAccount: this.formBuilder.control(this.expense?.nahausBookingsAccount, Validators.required),
            property: this.expense?.property,
            unitsID: this.formBuilder.control(this.expense?.unitsID?.length ? [...this.expense.unitsID] : []),
            accountingYears: this.formBuilder.control(this.expense?.accountingYears),
            serviceStartDate: this.formBuilder.control(this.expense?.serviceStartDate ? convertTimestamp(this.expense.serviceStartDate) : null),
            serviceEndDate: this.formBuilder.control(this.expense?.serviceEndDate ? convertTimestamp(this.expense.serviceEndDate) : null),
            supplierOrService: this.formBuilder.control(this.expense?.supplierOrService),
        });
    }

    listenOnFormChanges(): void {

        this.expenseFormGroup
            .get('type')?.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                takeUntil(this._unsubscribeWhenIdChanges),
                startWith(this.expenseFormGroup.get('type')?.value)
            )
            .subscribe((type: ExpenseTypeOptions) => {
                const amountFC = this.expenseFormGroup.get('amount');
                const amountValue = amountFC?.value as number;

                if (type === ExpenseTypeOptions.EXPENSE) {
                    amountFC.clearValidators();
                    amountFC.setValidators([Validators.required, Validators.max(0)]);
                    if (amountValue > 0) {
                        amountFC.patchValue(amountValue * -1);
                    }
                } else {
                    amountFC.clearValidators();
                    amountFC.setValidators([Validators.required, Validators.min(0)]);
                    if (amountValue < 0) {
                        amountFC.patchValue(amountValue * -1);
                    }
                }
                amountFC.updateValueAndValidity();
                this.expenseFormGroup.updateValueAndValidity();
            });

        this.expenseFormGroup
            .get('nahausBookingsAccount')?.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                takeUntil(this._unsubscribeWhenIdChanges),
                startWith(this.expenseFormGroup.get('nahausBookingsAccount')?.value)
            )
            .subscribe((nahausBookingsAccount: NahausBookingsAccount) => {

                console.log("nahausBookingsAccount on change --> ", nahausBookingsAccount);
                console.log("this.expenseFormGroup.getRawValue on change --> ", this.expenseFormGroup.getRawValue());

                const accountingYearsFC = this.expenseFormGroup.get('accountingYears');
                const accountingYearsValue = accountingYearsFC?.value as number[];

                if (nahausBookingsAccount?.isAllocatable) {
                    accountingYearsFC.clearValidators();
                    accountingYearsFC.setValidators(Validators.required);

                } else {
                    accountingYearsFC.clearValidators();
                }

                if (nahausBookingsAccount?.isAllocatable && !(accountingYearsValue?.length > 0)) {
                    const dateFC = this.expenseFormGroup.get('date');
                    const dateValue = dateFC?.value as Date;
                    const dateToProcess = dateValue || new Date();

                    accountingYearsFC.patchValue([dateToProcess.getFullYear()]);
                }

                accountingYearsFC.updateValueAndValidity();
                this.expenseFormGroup.updateValueAndValidity();
            });

        this.expenseFormGroup
            .get('amount')?.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                takeUntil(this._unsubscribeWhenIdChanges),
                startWith(this.expenseFormGroup.get('amount')?.value),
                debounceTime(800),
            )
            .subscribe((amount: number) => {
                const expenseFC = this.expenseFormGroup.get('type')?.value as ExpenseTypeOptions;
                const amountFC = this.expenseFormGroup.get('amount');

                if (expenseFC === ExpenseTypeOptions.EXPENSE && amount > 0) {
                    amountFC.patchValue(amount * -1);
                } else if (expenseFC === ExpenseTypeOptions.CREDIT && amount < 0) {
                    amountFC.patchValue(amount * -1);
                }
            });

        this.expenseFormGroup.get('property').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                takeUntil(this._unsubscribeWhenIdChanges),
                startWith(this.expenseFormGroup.get('property')?.value),
                tap((property: Property) => {
                    console.log("on tap --> property --> ", property);
                    if (!(property && property?.id)) {
                        this.units = [];
                    }
                }),
                filter((property) => !!property?.id),
                switchMap((property) => {
                    this.loading = true;
                    this.loadingUnits = true;
                    this.changeDetectorRef.markForCheck();
                    this.unitsService.setParentPath(property?.id);
                    return this.unitsService.collection(ref => ref.orderBy('w_id')).get().pipe(take(1), map(result => result?.empty ? [] : result?.docs.map(d => d.data() as PropertyUnit)));
                })
            )
            .subscribe((units: PropertyUnit[]) => {
                console.log("units received = ", units);
                this.units = units;
                this.loading = false;
                this.loadingUnits = false;
               const nahausBookingsAccount: NahausBookingsAccount = this.expenseFormGroup.get('nahausBookingsAccount')?.value;
      if (nahausBookingsAccount?.isAllocatable) {
          const allUnitIds = this.units.map(unit => unit.id);
          this.expenseFormGroup.get('unitsID').setValue(allUnitIds);
      }

      this.changeDetectorRef.markForCheck();
            });
    }

    /**
     * Update the landlord
     */
    save(): void {

        if (this.expenseFormGroup.invalid) {
            this.expenseFormGroup.markAllAsTouched();
            this.matSnackbar.open('Bitte überprüfen Sie Ihre Eingaben', 'OK', {duration: 5000});
            console.log('invalid controls', findInvalidControls(this.expenseFormGroup));
            this.scrollToElementById('title');
            this.changeDetectorRef.markForCheck();
            return;
        }

        this.updating = true;
        this.changeDetectorRef.markForCheck();
        this.expenseFormGroup.disable();

        // Get the expense object
        let expense = this.expenseFormGroup.getRawValue() as Expense;
        console.log('expense to save', expense);

        // return;


        // return;

        this.expensesImagesService.setParentPath(expense.id);

        if (this.expense) {
            this.expensesModularService.update(expense.id, expense)
                .then(() => {
                    this.matSnackbar.open('Ausgabe erfolgreich aktualisiert', 'Schließen', {
                        duration: 5000,
                    });
                })
                .then(() => this.filePondComponent.myPond.processFiles())
                .catch((err) => {
                    this.matSnackbar.open('Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.', 'Schließen', {
                        duration: 5000,
                    });
                }).finally(() => {
                this.updating = false;
                this.expenseFormGroup.enable();
                this.toggleEditMode(false);
                this.changeDetectorRef.markForCheck();
            })
        } else {
            this.expensesModularService.setWithMerge(expense.id, expense)
                .then(() => {
                    this.matSnackbar.open('Ausgabe erfolgreich erfasst.', 'Schließen', {
                        duration: 5000,
                    });
                })
                .then(() => this.filePondComponent.myPond.processFiles())
                .then(() => this.router.navigate(['/ausgaben', expense.id]))
                .catch((err) => {
                    this.matSnackbar.open('Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.', 'Schließen', {
                        duration: 5000,
                    });
                }).finally(() => {
                this.updating = false;
                this.expenseFormGroup.enable();
                this.toggleEditMode(false);
                this.changeDetectorRef.markForCheck();
            })
        }
    }

    /**
     * Delete the contact
     */
    delete(): void {

        this.deleting = true;
        this.changeDetectorRef.markForCheck();

        this.expensesModularService
            .delete(this.expense?.id)
            .then(() => {
                const msg = 'Die Ausgabe wurde erfolgreich gelöscht.';
                this.matSnackbar.open(msg, 'Schließen', {duration: 5000});
                return this.router.navigate(['ausgaben']);
            })
            .catch((err) => {
                const msg = 'Das Löschen der Ausgabe ist fehlgeschlagen. Bitte versuchen Sie es erneut.';
                this.matSnackbar.open(msg, 'Schließen', {duration: 5000});
            })
            .finally(() => {
                this.deleting = false;
                this.changeDetectorRef.markForCheck();
            })

        // Mark for check
        this.changeDetectorRef.markForCheck();
    }

    requestDelete(): void {

        if (!this.expensesModularService.authService.checkPermission(PermissionOptions.BOOKINGS_DELETE)) {
            const msg = 'Zugriff verweigert: Sie verfügen nicht über die erforderliche Berechtigung \'BUCHHALTUNG - LÖSCHEN\', um einen Eigentümer zu löschen. Bitte kontaktieren Sie Ihren Admin-Benutzer.';
            this.matSnackbar.open(msg, 'OK', {duration: 10000});
            return;
        }

        this.deleting = true;
        this.changeDetectorRef.markForCheck();
        // Build the config form
        this.configForm = this.formBuilder.group({
            title: 'Ausgabe löschen',
            message: `Sind Sie sicher, dass Sie diese Ausgabe <span class="font-medium">löschen</span> möchten? Diese Aktion kann nicht rückgängig gemacht werden.`,
            icon: this.formBuilder.group({
                show: true,
                name: 'mat_outline:delete_forever',
                color: 'warn'
            }),
            actions: this.formBuilder.group({
                confirm: this.formBuilder.group({
                    show: true,
                    label: 'Löschen',
                    color: 'warn'
                }),
                cancel: this.formBuilder.group({
                    show: true,
                    label: 'Abbrechen'
                })
            }),
            dismissible: false
        });

        const dialogRef = this.fuseConfirmationService.open(this.configForm.value);
        dialogRef.afterClosed().pipe(map(result => result === 'confirmed')).subscribe((value) => {
            if (value) {
                console.log('confirmed');
                this.delete();
            } else {
                this.deleting = false;
            }
            this.changeDetectorRef.markForCheck();
        });
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item?.id || index;
    }


    cancel(): void {
        this.expensesModularService.selected = null;
        this.router.navigate(['../'], {relativeTo: this.route})
            .then(() => this.changeDetectorRef.markForCheck());
    }

    listenOnHotKeys() {
        this.hotkeys.addShortcut({keys: 'meta.a'}).pipe(takeUntil(this._unsubscribeAll)).subscribe(e => console.log('Hotkey', e));
        // go to create new landlord
        this.hotkeys.addShortcut({keys: 'meta.x'}).pipe(takeUntil(this._unsubscribeAll)).subscribe(e => this.cancel());
        if (!environment.production) {
            this.hotkeys.addShortcut({keys: 'meta.g'}).pipe(takeUntil(this._unsubscribeAll)).subscribe(e => this.generateData());
        }
    }

    generateData(): void {
        // const landlord: Expense = generateLandlord();
        // console.log('generated landlord', landlord);
        // // this.landlordForm = this.createFormGroup();
        // this.expenseFormGroup.patchValue(landlord);
        // this.changeDetectorRef.markForCheck();
    }


    loadData(): void {
        this.route
            .params
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((params) => {
                console.log('on params: ', params);
            });
    }

    scrollToElement(element: HTMLElement) {
        element.scrollIntoView({behavior: 'smooth'});
    }

    scrollToElementById(id: string) {
        const element = this.__getElementById(id);
        this.scrollToElement(element);
    }

    openYoutubeDialog(): void {
        // this.expensesListComponent.openYoutubeDialog(true);
    }

    compareExpenses(expense1: Expense, expense2: Expense) {
        return expense1?.id == expense2?.id;
    }

    onQueryPropertySuggestionClick(property: Property): void {
        // this.setMeterUnitPath(property?.id);
        this.expenseFormGroup.get('property').patchValue(pick(property, ['id', 'h_id', 'address']));
        console.log('onQueryPropertySuggestionClick', this.expenseFormGroup.getRawValue());
        // // Mark for check
        this.changeDetectorRef.markForCheck();
    }

    clearSelectedProperty(): void {
        this.expenseFormGroup.get('property').reset();
        // // Mark for check
        this.changeDetectorRef.markForCheck();
    }

    getUnitLabelForInput(id: string) {
        if (!this.units?.length) {
            return 'UNBEKANNT'
        }
        const unit = this.units.find(unit => unit?.id === id);
        return unit?.label
    }

    listenOnFirestoreDocChanges(): void {
        this.route
            .params
            .pipe(
                takeUntil(this._unsubscribeAll),
                map((params: Params) => params['id']),
                filter((id: string) => {
                    console.log('param expense id --> ', id);
                    if (!id) {
                        console.log("iam here 1")
                        this.editMode = true;
                        this.expense = null;
                        // this.expenseFormGroup = this.createFormGroup();
                        this.changeDetectorRef.markForCheck();
                        return false;
                    } else {
                        console.log("iam here 2")
                        if (this.editMode) {
                            console.log("iam here 3")
                            this.editMode = false;
                            this.changeDetectorRef.markForCheck();
                        }
                        return true;
                    }
                }),
                switchMap((expenseID: string) => {
                    console.log("on switch map in listenOnFirestoreDocChanges")
                    this.loading = true;
                    this.changeDetectorRef.markForCheck();
                    return this.expensesService.doc(expenseID).valueChanges().pipe(tap(() => this._unsubscribeWhenIdChanges.next(null)))
                })
            )
            .subscribe((expense) => {
                console.log('on expense received --> ', expense);
                this.expense = expense;
                this.expensesImagesService.setParentPath(expense?.id);
                this.expenseFormGroup = this.createFormGroup();
                this.listenOnFormChanges();
                this.loading = false;
                // Mark for check
                this.changeDetectorRef.markForCheck();
            });
    }

    initializeAvailableYears() {
        const currentYear = new Date().getFullYear();
        // Startet 3 Jahre zurück und endet 3 Jahre in der Zukunft
        this.availableYears = [];
        for (let year = currentYear - 3; year <= currentYear + 3; year++) {
            this.availableYears.push(year);
        }
    }

    openPreview() {
        const data: FilesPreviewDataDialog = {
            id: this.expense.id,
            title: 'Belegvorschau',
            files: this.expense?.files
        }
        this.dialog.open(NahausImagesPreviewDialogComponent, {
            autoFocus: false,
            disableClose: false,
            data,
            panelClass: 'fuse-confirmation-dialog-panel'
        });
    }

    private __getElementById(id: string): HTMLElement {
        const element = document.getElementById(id);
        return element;
    }
}
