import {Injectable} from '@angular/core';
import {AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {StatMsa} from '@msa/models';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';

@Injectable({providedIn:"root"})
export class MsaStatsService extends AbstractFirestoreService<StatMsa> {

  COLLECTION = 'stat';

  override doc(): AngularFirestoreDocument<StatMsa> {
    return super.doc('msa');
  }

  constructor(authService: AuthService) {
    super(authService);
  }
}
