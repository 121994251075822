<mat-chip-grid #chipList [disabled]="disabled" [required]="required">
    <mat-chip (removed)="remove(val)" *ngFor="let val of value" [removable]="true" [selectable]="true">
        {{ val.length > maxValueLength ? val.substr(0, maxValueLength - 1) + '&hellip;' : val }}
        <mat-icon matChipRemove>clear</mat-icon>
    </mat-chip>
    <input #emailInputControl="ngModel" (blur)="handleOnTouched()" (matChipInputTokenEnd)="add($event)"
           [(ngModel)]="emailInputValue" [email]="true"
           [matChipInputAddOnBlur]="true"
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [placeholder]="placeholder">
    <ng-container *ngIf="this.showCcAndBCC">
        <div *ngIf="this.showCC || this.showBCC" class="copy-fields-toggles" matSuffix>
		<span (click)="this.onShowCcClicked.next();this.showCC = false" *ngIf="this.showCC"
              class="text-sm font-medium cursor-pointer select-none hover:underline"
              matRipple>
			Cc
		</span>
            <span (click)="this.onShowBccClicked.next();this.showBCC = false" *ngIf="this.showBCC"
                  class="ml-2 text-sm font-medium cursor-pointer select-none hover:underline"
                  matRipple>
			Bcc
		</span>
        </div>
    </ng-container>
</mat-chip-grid>
