import { GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

export const formatAddress = (address: GermanAddress): string => {
  if (address?.streetName && address?.streetNumber && address?.postalCode && address?.locality?.long) {
    return `${address?.streetName ?? ''} ${address?.streetNumber ?? ''}, ${address?.postalCode ?? ''} ${address?.locality?.long ?? ''}`;
  } else {
    return '';
  }
};

export const formatAddressfromCSV = (address: GermanAddress): string => {
  if (address?.streetName || address?.streetNumber || address?.postalCode || address?.locality?.long) {
    return `${address?.streetName ?? ''} ${address?.streetNumber ?? ''}, ${address?.postalCode ?? ''} ${address?.locality?.long ?? ''}`;
  } else {
    return '';
  }
};

export const buildAddressFormGroup = (formBuilder: UntypedFormBuilder, address: GermanAddress, required?: boolean) => {
  return formBuilder.group({
    id: address?.id,
    placeID: address?.placeID,
    gmID: address?.gmID,
    name: address?.name,
    icon: address?.icon,
    url: address?.url,
    streetName: [address?.streetName, required ? Validators.required : null],
    streetNumber: [address?.streetNumber, required ? Validators.required : null],
    postalCode: [address?.postalCode, required ? Validators.required : null],
    vicinity: [address?.vicinity],
    displayAddress: address?.displayAddress,
    sublocality: address?.sublocality,
    locality: formBuilder.group({
      short: address?.locality?.short,
      long: [address?.locality?.long, required ? Validators.required : null]
    }),
    state: formBuilder.group({
      short: address?.state?.short,
      long: address?.state?.long
    }),
    country: formBuilder.group({
      short: address?.country?.short,
      long: address?.country?.long
    }),
    geoLocation: formBuilder.group({
      latitude: address?.geoLocation?.latitude,
      longitude: address?.geoLocation?.longitude
    })
  });
};

export const getGermanAddressFormGroup = (address: GermanAddress, fullAddressRequired = true): FormGroup => {
  return new FormGroup({
    id: new FormControl(address?.id),
    url: new FormControl(address?.url),
    gmID: new FormControl(address?.gmID),
    placeID: new FormControl(address?.placeID),
    name: new FormControl(address?.name),
    icon: new FormControl(address?.icon),
    displayAddress: new FormControl(address?.displayAddress),
    postalCode: new FormControl(address?.postalCode, fullAddressRequired ? Validators.required : null),
    streetNumber: new FormControl(address?.streetNumber, fullAddressRequired ? Validators.required : null),
    streetName: new FormControl(address?.streetName, fullAddressRequired ? Validators.required : null),
    vicinity: new FormControl(address?.vicinity),
    sublocality: new FormControl(address?.sublocality),
    locality: new FormGroup({
      short: new FormControl(address?.locality?.short),
      long: new FormControl(address?.locality?.long, fullAddressRequired ? Validators.required : null)
    }),
    state: new FormGroup({
      short: new FormControl(address?.state?.short),
      long: new FormControl(address?.state?.long)
    }),
    country: new FormGroup({
      short: new FormControl(address?.country?.short),
      long: new FormControl(address?.country?.long)
    })
  });
};
