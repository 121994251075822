import {Pipe, PipeTransform} from '@angular/core';
import {
    NahausBookingAccountTypeOptions
} from "../../modules/admin/bookingsAccounts/models/NahausBookingsAccount.interface";

@Pipe({
    name: 'expenseTypeText',
    standalone: true
})
export class ExpenseTypeTextPipe implements PipeTransform {

    transform(value: NahausBookingAccountTypeOptions): string {
        switch (value) {
            // case NahausBookingAccountTypeOptions.INCOME:
            //     return 'Einnahmen';
            case NahausBookingAccountTypeOptions.EXPENSE:
                return 'Ausgaben';
            case NahausBookingAccountTypeOptions.INVESTMENT:
                return 'Investitionen';
            case NahausBookingAccountTypeOptions.SAVINGS:
                return 'Ersparnisse" (oder "Rückstellungen", falls zutreffender)';
            case NahausBookingAccountTypeOptions.LOANS:
                return 'Darlehen';
            case NahausBookingAccountTypeOptions.OTHER:
                return 'Sonstiges';
            default:
                return 'UNBEKANNT';
        }
    }

}
