import {EventEmitter, Injectable} from '@angular/core';

import {PageEvent} from '@angular/material/paginator';
import {MSA, MsaStatus, MsaType} from '@msa/models';
import {Hit} from 'angular-instantsearch/instantsearch/instantsearch';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn:"root"})
export class MsaOldService {

  onSelectedChanged$: BehaviorSubject<MSA> = new BehaviorSubject<MSA>(null);
  onListChanged$: BehaviorSubject<MSA[]> = new BehaviorSubject<MSA[]>(null);
  // Filters
  onPageEventChanged$: BehaviorSubject<PageEvent> = new BehaviorSubject<PageEvent>(null);
  onFilterMsaStatusChanged$: BehaviorSubject<MsaStatus> = new BehaviorSubject<MsaStatus>(null);
  onTargetTypeFilterChanged$: BehaviorSubject<MsaType> = new BehaviorSubject<MsaType>(null);
  onCreatedDateAndTimeRangeChanged$: BehaviorSubject<{ min?: Date, max?: Date }> = new BehaviorSubject<{ min?: Date; max?: Date }>(null);
  // algolia search engine
  onNewHits: EventEmitter<Hit[]> = new EventEmitter<Hit[]>();

  /**
   * Constructor
   *
   */
  constructor() {
    console.log('on new MsaOldService constructor');
  }

  private _selected: MSA;

  set selected(value: MSA) {
    this._selected = value;
    this.onSelectedChanged$.next(value);
  }

  private _list: MSA[];

  set list(value: MSA[]) {
    this._list = value;
    this.onListChanged$.next(value);
  }


}
