import {Injectable} from '@angular/core';
import {Firestore} from '@angular/fire/firestore';
import {AbstractFirestoreModularService} from '@shared/services/abstracts/AbstractFirestoreModularService';
import {AuthService} from '@shared/services/auth.service';
import {Expense} from "../models/expenses.interface";

export interface TransactionsRange {
    start: Date | null;
    end: Date | null;
}

@Injectable({providedIn: 'root'})
export class ExpensesModularService extends AbstractFirestoreModularService<Expense> {

    COLLECTION = 'expenses';

    // onRangeChanged$: BehaviorSubject<TransactionsRange> = new BehaviorSubject<TransactionsRange>(null);
    // onStatsChanged: BehaviorSubject<StatMsa> = new BehaviorSubject<StatMsa>(null);
    // onListChanged$: BehaviorSubject<Transactions> = new BehaviorSubject<Transactions>(null);

    constructor(authService: AuthService,
                firestore: Firestore) {
        super(authService, firestore);
    }

    // private _list: Transactions;

    // set transactionsList(value: Transactions) {
    //     this._list = value;
    //     this.onListChanged$.next(value);
    // }


}
