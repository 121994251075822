<mat-nav-list>
    <!-- <div mat-subheader>Export your bookings</div> -->
    <div mat-subheader>Ihre Buchungen exportieren</div>

    <a (click)="dismiss('pdf')" mat-list-item>
        <img class="mat-icon" matListItemIcon src="assets/icons/pdf/pdf.svg">
        <span matListItemTitle>PDF</span>
        <!-- <span matListItemLine>Generate a PDF document for your booking records.</span> -->
        <span matListItemLine>Erstellen Sie ein PDF-Dokument Ihrer Buchungsunterlagen.</span>
    </a>

    <a (click)="dismiss('csv')" mat-list-item>
        <img class="mat-icon" matListItemIcon src="assets/icons/csv/csv2.svg">
        <span matListItemTitle>CSV File</span>
        <!-- <span matListItemLine>Download your bookings in CSV format for spreadsheet compatibility.</span> -->
        <span matListItemLine>Laden Sie Ihre Buchungsdaten im CSV-Format herunter, für die Nutzung in Tabellenkalkulationsprogrammen.</span>
    </a>

</mat-nav-list>
