<div class="relative flex flex-col w-full h-full">

    <!-- Dismiss button -->
    <div class="absolute top-0 right-0 pt-4 pr-4">
        <button
            [matDialogClose]="undefined"
            [disabled]="this.creating"
            mat-icon-button>
            <mat-icon
                [svgIcon]="'mat_outline:close'"
                class="text-secondary"></mat-icon>
        </button>
    </div>

    <!-- Content -->
    <div class="flex flex-col w-full">
        <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">

            <!-- Icon -->
            <div
                class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600">
                <mat-icon
                    [svgIcon]="'heroicons_outline:clipboard'"
                    class="text-current"></mat-icon>
            </div>

            <div
                class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">

                <!-- Title -->
                <div class="text-xl leading-6 font-medium">Neues Protokoll erstellen</div>

                <!-- Message -->
                <div class="text-secondary">Bitte wählen Sie die Immobilie und definieren Sie die notwendigen Details
                    für
                    das Protokoll.
                </div>
            </div>

        </div>

        <div class="w-full drop-shadow-xl">
            <mat-vertical-stepper #stepper [linear]="true" [selectedIndex]="this.stepperIndex">
                <!-- select property -->
                <mat-step [stepControl]="this.selectDataFormGroup.get('property')">
                    <form [formGroup]="this.selectDataFormGroup">
                        <ng-template
                            [matTooltip]="selectDataFormGroup.get('property')?.invalid ? 'Wählen Sie die Immobilie aus' : ''"
                            matStepLabel>
                            <ng-container *ngIf="stepper.selectedIndex !== 0">
                                <ng-container
                                    *ngIf="selectDataFormGroup.get('property')?.value as property">
                                    <span>H{{property?.h_id}} {{property?.address | formatAddress}}</span>
                                </ng-container>
                            </ng-container>
                            <span
                                *ngIf="selectDataFormGroup.get('property')?.invalid || stepper.selectedIndex === 0">Wählen Sie die Immobilie aus</span>
                        </ng-template>
                        <div *ngIf="!this.selectDataFormGroup.get('property')?.value" @zoomIn @zoomOut
                             class="flex flex-col space-y-2 w-full">
                            <ais-instantsearch [config]='this.configProperties'>
                                <!--								<ais-configure [searchParameters]="{ facets: ['customerID'] }"></ais-configure>-->
                                <app-algolia-autocomplete
                                    (onQuerySuggestionClick)=this.onQueryPropertySuggestionClick($event)
                                    class="flex"
                                    formFieldClass="w-full"
                                    label="Suchen Sie nach der Adresse der Immobilie"
                                    matOptionStyles="height: 70px; line-height: 20px;"
                                    placeholder="Suche...">
                                    <ng-template let-option='option'>
                                        <div class="flex flex-col">
                                            <span
                                                [innerHTML]="option?._highlightResult?.h_id_text?.value && option?._highlightResult?.h_id_text?.matchLevel === 'full' ? option?._highlightResult?.h_id_text?.value : 'H' + option?._highlightResult?.h_id?.value + ' ' + option?._highlightResult?.address?.streetName?.value + ' ' + option?._highlightResult?.address?.streetNumber?.value"
                                                matLine></span>
                                            <span
                                                [innerHTML]="option?._highlightResult?.address?.streetName?.value + ' ' + option?._highlightResult?.address?.streetNumber?.value + ', ' + option?._highlightResult?.address?.postalCode?.value + ' ' + option?._highlightResult?.address?.locality?.long?.value"
                                                class="text-xs" matLine></span>
                                            <mat-divider class="mt-2"></mat-divider>
                                        </div>
                                    </ng-template>
                                </app-algolia-autocomplete>
                            </ais-instantsearch>

                            <div class="flex items-center my-8">
                                <div class="flex-auto mt-px border-t"></div>
                                <div class="mx-2 text-secondary">Oder weiter mit manuellen Eingaben.</div>
                                <div class="flex-auto mt-px border-t"></div>
                            </div>

                            <mat-form-field class="fuse-mat-emphasized-affix w-full">
                                <!--							<mat-label>H_ID Eingabe</mat-label>-->
                                <div
                                    class="text-secondary"
                                    matPrefix>
                                    H
                                </div>
                                <mat-icon
                                    *ngIf="this.selectDataFormGroup.get('property')?.valid && this.selectDataFormGroup.get('property.h_id')?.value === this.property?.h_id"
                                    class="text-green-600" matSuffix
                                    svgIcon="heroicons_outline:badge-check"></mat-icon>
                                <mat-icon
                                    *ngIf="this.selectDataFormGroup.get('property')?.invalid "
                                    class="text-warn" matSuffix
                                    svgIcon="heroicons_outline:exclamation"></mat-icon>
                                <mat-progress-spinner
                                    *ngIf="this.h_id_loading"
                                    [diameter]="24" matSuffix
                                    mode="indeterminate"></mat-progress-spinner>
                                <input #h_id
                                       [formControl]="this.hIDFC"
                                       matInput
                                       name="h_id"
                                       type="number">
                                <mat-hint>Die eindeutige H_ID der Immobilie eingeben</mat-hint>
                            </mat-form-field>
                        </div>
                        <div *ngIf="this.selectDataFormGroup.get('property')?.value as property"
                             @zoomIn @zoomOut
                             class="w-full">
                            <!-- Card #43 -->
                            <fuse-card
                                class="flex items-center justify-between max-w-80 w-full p-6 filter-info drop-shadow-xl mb-2">
                                <div class="flex flex-col">
                                    <div class="text-2xl font-semibold leading-tight">Immobilie <span
                                        class="font-extrabold text-primary">H{{property?.h_id}}</span></div>
                                    <div
                                        class="text-md leading-tight text-primary">{{ property?.address | formatAddress}}</div>
                                    <!--								<div class="text-md text-secondary mt-2">h.brian@company.com</div>-->
                                </div>
                                <button (click)="this.resetProperty()" color="warn" mat-mini-fab
                                        matTooltip="Zurücksetzen">
                                    <mat-icon
                                        [svgIcon]="'mat_outline:close'"
                                        class="icon-size-5 text-current"></mat-icon>
                                </button>
                                <!--							<img-->
                                <!--									class="w-16 h-16 ml-6 rounded-full"-->
                                <!--									src="assets/images/avatars/male-04.jpg"-->
                                <!--									alt="Card cover image">-->
                            </fuse-card>
                        </div>
                        <div *ngIf="this.propertyInvalid" @zoomIn @zoomOut class="w-full mt-4">
                            <!-- Warn -->
                            <fuse-alert [type]="'warn'">
                                <span fuseAlertTitle>Keine Immobilie ausgewählt</span>
                                Bitte wählen Sie eine Immobilie aus der Liste aus, bevor Sie fortfahren.
                            </fuse-alert>
                        </div>
                        <div class="mt-4">
                            <button (click)="this.checkProperty()" color="primary" mat-raised-button
                                    matStepperNext>Weiter
                            </button>
                        </div>
                    </form>
                </mat-step>

                <!-- select unit -->
                <mat-step [stepControl]="this.selectDataFormGroup.get('unit')">
                    <form [formGroup]="this.selectDataFormGroup">
                        <ng-template matStepLabel>
                            <ng-container *ngIf="stepper.selectedIndex !== 1">
                                <ng-container
                                    *ngIf="selectDataFormGroup.get('unit')?.value as unit">
                                    <span>W{{unit?.w_id}} {{unit?.label || ''}} {{unit?.address | formatAddress}}</span>
                                </ng-container>
                            </ng-container>
                            <span
                                *ngIf="selectDataFormGroup.get('unit')?.invalid || stepper.selectedIndex === 1">Wählen Sie die entsprechende Einheit aus</span>
                        </ng-template>
                        <div class="w-full my-4">
                            <div *ngIf="this.loadingUnits; else templateUnits" @zoomIn @zoomOut
                                 matTooltip="Die Einheiten werden geladen. Bitte haben Sie einen Moment Geduld.">
                                <mat-progress-spinner [diameter]="48"
                                                      mode="indeterminate"></mat-progress-spinner>
                            </div>
                            <ng-template #templateUnits>
                                <div *ngIf="this.availableUnits?.length > 0; else noUnits" @zoomIn @zoomOut>
                                    <mat-selection-list #units
                                                        (selectionChange)="this.onUnitsSelectionListChanged($event)"
                                                        [(ngModel)]="this.selectedUnit"
                                                        [multiple]="false"
                                                        [ngModelOptions]="{standalone: true}"
                                                        color="primary">
                                        <mat-list-option *ngFor="let unit of this.availableUnits"
                                                         [value]="unit"
                                                         class="my-2 h-18" togglePosition="before">
                                            <h3 matLine><span
                                                class="font-extrabold text-primary">W{{unit?.w_id}}</span> {{unit?.label}}
                                            </h3>
                                            <!--										<p matLine>Mieter: {{unit?.currentPeriod?.tenantsName}}</p>-->
                                            <div class="flex flex-row flex-wrap space-x-2" matLine>
                                                <div *ngIf="unit?.currentPeriod?.rental?.isRented"
                                                     [matTooltip]="'Einzug: ' + (unit?.currentPeriod?.rental?.startDate | toDate | date)"
                                                     class="px-2 bg-primary text-on-primary rounded">
                                                    vermietet
                                                </div>
                                                <div *ngIf="unit?.currentPeriod?.rental?.isRented"
                                                     class="px-2 bg-primary text-on-primary rounded"
                                                     matTooltip="Saldo">
                                                    {{unit?.currentPeriod?.costs?.total | currency:'EUR'}}
                                                </div>
                                                <div *ngIf="unit?.currentPeriod?.rental?.isRented"
                                                     class="px-2 bg-primary text-on-primary rounded"
                                                     matTooltip="Mieter">
                                                    {{unit?.currentPeriod?.tenantsName}}
                                                </div>
                                                <div *ngIf="!unit?.currentPeriod?.rental?.isRented"
                                                     [matTooltip]="'Einzug: ' + (unit?.currentPeriod?.rental?.startDate | toDate | date)"
                                                     class="px-2 bg-warn text-on-warn rounded">
                                                    leerstand
                                                </div>
                                            </div>

                                        </mat-list-option>
                                    </mat-selection-list>
                                </div>
                            </ng-template>

                            <ng-template #noUnits>
                                <div @zoomIn @zoomOut class="w-full my-4">
                                    <fuse-alert [type]="'warn'">
                                        <span fuseAlertTitle>Keine Einheiten gefunden</span>
                                        Für die ausgewählte Immobilie wurden keine Einheiten gefunden. Bitte stellen Sie
                                        sicher, dass mindestens eine Einheit vorhanden ist, um mit der Erstellung des
                                        digitalen Übergabeprotokolls fortzufahren.
                                    </fuse-alert>
                                </div>
                            </ng-template>
                        </div>
                        <div *ngIf="this.unitInvalid && !(this.availablePeriods?.length > 0)" @zoomIn
                             @zoomOut
                             class="w-full my-4">
                            <!-- Warn -->
                            <fuse-alert [type]="'warn'">
                                <span fuseAlertTitle>Keine Einheit ausgewählt</span>
                                Bitte wählen Sie eine Einheit aus der Liste aus, bevor Sie fortfahren.
                            </fuse-alert>
                        </div>
                        <div>
                            <button mat-button matStepperPrevious>Zurück</button>
                            <button (click)="this.checkUnit()" color="primary" mat-raised-button
                                    matStepperNext>Weiter
                            </button>
                        </div>
                    </form>
                </mat-step>

                <!-- select period -->
                <mat-step [stepControl]="this.selectDataFormGroup.get('period')">
                    <form [formGroup]="this.selectDataFormGroup">
                        <ng-template matStepLabel>
                            <ng-container *ngIf="stepper.selectedIndex !== 2">
                                <ng-container
                                    *ngIf="selectDataFormGroup.get('period')?.value as period">
                                            <span>Vermietung von {{period?.rental?.startDate | toDate | date:'shortDate'}}
                                                / <span *ngIf="period?.tenantsName">{{period?.tenantsName}} / </span> Warmmiete {{period?.costs?.total | currency:'EUR'}}</span>
                                </ng-container>
                            </ng-container>
                            <span
                                *ngIf="selectDataFormGroup.get('period')?.invalid || stepper.selectedIndex === 2">Wählen Sie den zugehörigen Vermietungszeitraum aus</span>
                        </ng-template>
                        <div class="w-full my-4">
                            <div *ngIf="this.loadingPeriods; else templatePeriods" @zoomIn @zoomOut
                                 matTooltip="Die Vermietungen werden geladen. Bitte haben Sie einen Moment Geduld.">
                                <mat-progress-spinner [diameter]="48"
                                                      mode="indeterminate"></mat-progress-spinner>
                            </div>
                            <ng-template #templatePeriods>
                                <div *ngIf="this.availablePeriods?.length > 0; else noPeriods" @zoomIn
                                     @zoomOut>
                                    <mat-selection-list #units
                                                        (selectionChange)="this.onPeriodsSelectionListChanged($event)"
                                                        [(ngModel)]="this.selectedPeriod"
                                                        [multiple]="false"
                                                        [ngModelOptions]="{standalone: true}"
                                                        color="primary">
                                        <mat-list-option *ngFor="let period of this.availablePeriods"
                                                         [value]="period"
                                                         class="my-2 h-18" togglePosition="before">
                                            <ng-container
                                                *ngIf="this.selectDataFormGroup.get('unit')?.value as unit">
                                                <!--Aktuelle Vermietung-->
                                                <h3 *ngIf="unit?.currentPeriod?.id === period?.id"
                                                    class="font-extrabold text-primary" matLine>Aktive
                                                    Vermietung</h3>
                                                <h3 *ngIf="unit?.currentPeriod?.id  !== period?.id"
                                                    class="font-extrabold" matLine>Beendete Vermietung</h3>
                                            </ng-container>
                                            <!--										<p matLine>Mieter: {{period?.currentPeriod?.tenantsName}}</p>-->
                                            <div class="flex flex-row flex-wrap space-x-2" matLine>
                                                <div [matTooltip]="'Start Datum'"
                                                     class="px-2 bg-primary text-on-primary rounded">
                                                    {{period?.rental?.startDate | toDate | date}}
                                                </div>
                                                <div *ngIf="period?.costs?.total"
                                                     class="px-2 bg-primary text-on-primary rounded"
                                                     matTooltip="Warmmiete">
                                                    {{period?.costs?.total | currency:'EUR'}}
                                                </div>
                                                <div *ngIf="period?.tenantsName"
                                                     class="px-2 bg-primary text-on-primary rounded"
                                                     matTooltip="Mieter">
                                                    {{period?.tenantsName}}
                                                </div>
                                            </div>

                                        </mat-list-option>
                                    </mat-selection-list>
                                </div>
                            </ng-template>

                            <ng-template #noPeriods>
                                <div @zoomIn @zoomOut class="w-full my-4">
                                    <fuse-alert [type]="'warn'">
                                        <span fuseAlertTitle>Keine Vermietungen gefunden</span>
                                        Für die ausgewählte Einheit wurden keine Vermietungen gefunden. Bitte stellen
                                        Sie sicher, dass mindestens eine Vermietung vorhanden ist, um mit der Erstellung
                                        des digitalen Übergabeprotokolls fortzufahren.
                                    </fuse-alert>
                                </div>
                            </ng-template>
                        </div>
                        <div *ngIf="this.periodInvalid" @zoomIn @zoomOut class="w-full my-4">
                            <!-- Warn -->
                            <fuse-alert [type]="'warn'">
                                <span fuseAlertTitle>Keine Vermietung ausgewählt</span>
                                Bitte wählen Sie eine Vermietung aus der Liste aus, bevor Sie fortfahren.
                            </fuse-alert>
                        </div>
                        <div>
                            <button mat-button matStepperPrevious>Zurück</button>
                        </div>
                    </form>
                </mat-step>

            </mat-vertical-stepper>
        </div>
    </div>

    <!-- Actions -->
    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

        <!-- Cancel -->
        <button
            [matDialogClose]="'cancelled'"
            [disabled]="this.creating"
            mat-stroked-button>
            Abbrechen
        </button>

        <!-- Confirm -->
        <button *ngIf="this.selectDataFormGroup?.valid"
                @zoomIn @zoomOut
                [disabled]="this.creating"
                (click)="this.creatingDraft()"
                color="primary"
                mat-flat-button>
            <mat-icon *ngIf="!this.creating" svgIcon="mat_outline:add_circle_outline"></mat-icon>
            <span *ngIf="!this.creating" class="ml-2">Erstellen</span>
            <mat-progress-spinner
                *ngIf=this.creating
                [diameter]="24"
                [mode]="'indeterminate'"
                matTooltip="wird gespeirchert..."></mat-progress-spinner>
        </button>

    </div>

</div>
