import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { StatsService } from '../../stats/services/stats.service';
import { NahausContactsStat } from '../models';

@Injectable()
export class StatContactsResolver implements Resolve<NahausContactsStat> {

  constructor(private statService: StatsService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<NahausContactsStat> {
    return (await lastValueFrom(this.statService.doc('contacts').get()))?.data() as NahausContactsStat;
  }
}
