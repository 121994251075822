<div class="flex flex-col w-full">
    <div *ngIf="this.loading">
        <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
    <!-- View mode -->
    <ng-container>

        <!-- Header -->
        <div class="relative w-full h-40 sm:h-48 px-8 sm:px-12 shadow-md">
            <!-- Background -->
            <ng-container>
                <img
                        [src]="'assets/illustrations/calculator/' + this.appTheme + '/undraw_calculator.svg'"
                        class="absolute inset-0 object-contain w-full h-full">
            </ng-container>
            <!-- Close button -->
            <div class="flex items-center justify-end w-full max-w-3xl mx-auto pt-6">
                <button
                        [matTooltip]="'Schließen'"
                        [routerLink]="['../']"
                        color="primary"
                        mat-icon-button>
                    <mat-icon
                            [svgIcon]="'heroicons_outline:x'"
                            class="text-white"></mat-icon>
                </button>
            </div>
        </div>

        <!-- MSA -->
        <div class="relative flex flex-col flex-auto items-center p-6 pt-0 sm:p-12 sm:pt-0">
            <div class="w-full max-w-3xl">

                <!-- Avatar and actions -->
                <div class="flex flex-auto items-end -mt-16">
                    <!-- Avatar -->
                    <div
                            class="flex items-center justify-center w-32 h-32 rounded-full overflow-hidden ring-4 ring-bg-card">
                        <!--                        <img *ngIf="this.dunning?.bankAccount?.img"-->
                        <!--                             [src]="this.dunning?.bankAccount?.img"-->
                        <!--                             class="object-cover w-full h-full bg-white">-->
                        <!--                        <div *ngIf="!this.dunning?.bankAccount?.img"-->
                        <!--                             class="flex items-center justify-center w-full h-full rounded overflow-hidden uppercase text-8xl font-bold leading-none bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">-->
                        <!--                            <span *ngIf="this.dunning?.debtorName">{{this.dunning?.debtorName?.charAt(0)}}</span>-->
                        <!--                            <span *ngIf="this.dunning?.creditorName">{{this.dunning?.creditorName?.charAt(0)}}</span>-->
                        <!--                        </div>-->
                    </div>
                    <!-- Actions -->
                    <div class="flex items-center ml-auto mb-1 gap-1">
                        <button (click)="this.openPDF()"
                                *ngIf="this.dunning?.url"
                                @zoomIn
                                @zoomOut
                                [color]="'primary'"
                                mat-stroked-button
                                matTooltip="Mahnungsschreiben als PDF herunterladen und ansehen">
                            <mat-icon [svgIcon]="'heroicons_outline:document-download'"
                                      class="animate-bounce-5"></mat-icon>
                            <span class="ml-2 mr-1">PDF</span>
                        </button>
                        <button (click)="this.openNextStageHintDialog()"
                                @zoomIn
                                @zoomOut
                                [color]="'primary'"
                                class="ml-4"
                                mat-flat-button
                                matTooltip="Mahnung hochstufen, falls die offene Posten noch unbezahlt sind">
                            <mat-icon [svgIcon]="'mat_outline:arrow_upward'"
                                      class="animate-bounce-5"></mat-icon>
                            <span class="ml-2 mr-1">Hochstufen</span>
                        </button>
                    </div>
                </div>

                <!-- Name -->
                <div class="mt-3 text-4xl font-bold truncate" matTooltip="Verwendungszweck">
                    <span
                            *ngIf="this.dunning?.type === this.dunningTypeOptions.PAYMENT_REMINDER">Zahlungserinnerung</span>
                    <span *ngIf="this.dunning?.type === this.dunningTypeOptions.FIRST_REMINDER">Erste Mahnung</span>
                    <span *ngIf="this.dunning?.type === this.dunningTypeOptions.SECOND_REMINDER">Zweite Mahnung</span>
                    <ng-container *ngIf="this.dunning?.type === this.dunningTypeOptions.FINAL_REMINDER">
                        <ng-container [ngSwitch]="dunning?.lastWarningType">
                            <span *ngSwitchDefault>Letzte Mahnung</span>
                            <span *ngSwitchCase="this.lastWarningTypeOptions.FINAL_WARNING_BEFORE_LEGAL_ACTION">Letzte Mahnung vor Mahnbescheid</span>
                            <span *ngSwitchCase="this.lastWarningTypeOptions.FINAL_WARNING_BEFORE_TERMINATION">Letzte Mahnung vor Fristloskündigung</span>
                        </ng-container>
                    </ng-container>
                    <!--					<span i18n>{this.transaction?.contactData?.title, select, M {Herr} F {Frau}}</span> {{this.transaction?.contactData?.firstName}} {{this.transaction?.contactData?.lastName}}-->
                </div>

                <!-- Target Tags -->
                <ng-container>
                    <div class="flex flex-wrap items-center mt-2">

                        <div
                            *ngIf="this.dunning?.unitLabel && this.accessLayer !== this.bookingsAccessLayerOptions.PERIOD"
                            @slideInRight
                            @slideOutLeft
                            class="flex items-center justify-center py-1 px-3 mr-3 mb-3 rounded-full leading-normal text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700"
                            matTooltip="Bezeichnung der Wohneinheit">
                            <span class="text-sm font-medium whitespace-nowrap">{{this.dunning?.unitLabel}}</span>
                        </div>

                        <div
                            *ngIf="this.dunning?.address && this.accessLayer !== this.bookingsAccessLayerOptions.PERIOD"
                            @slideInRight
                            @slideOutLeft
                            class="flex items-center justify-center py-1 px-3 mr-3 mb-3 rounded-full leading-normal text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700"
                            matTooltip="Adresse der Wohneinheit">
                            <span
                                class="text-sm font-medium whitespace-nowrap">{{this.dunning?.address | formatAddress}}</span>
                        </div>

                        <div *ngIf="this.dunning?.tenantsName"
                             @slideInRight
                             @slideOutLeft
                             class="flex items-center justify-center py-1 px-3 mr-3 mb-3 rounded-full leading-normal text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700"
                             matTooltip="Name des Mieters oder der Organisation, an die die Mahnung gerichtet ist.">
                            <span class="text-sm font-medium whitespace-nowrap">{{this.dunning?.tenantsName}}</span>
                        </div>
                        <div
                                @slideInRight
                                @slideOutLeft
                                class="flex items-center justify-center py-1 px-3 mr-3 mb-3 rounded-full leading-normal text-warn-800 bg-warn-100 dark:text-warn-50 dark:bg-warn-500"
                                matTooltip="Gesamtbetrag, der gezahlt werden muss (einschließlich überfälliger Miete, Mahngebühren und Verzugszinsen).">
                            <span
                                    class="text-sm font-medium whitespace-nowrap">{{this.dunning?.amountToPayInclFeesAndInterest| currency: 'EUR'}}</span>
                        </div>

                        <div *ngIf="this.dunning?.status !== this.bookingStatusOptions.PAID"
                             @slideInRight
                             @slideOutLeft
                             class="flex items-center justify-center py-1 px-3 mr-3 mb-3  rounded-full leading-normal text-white bg-warn dark:text-white-300 dark:bg-warn-400"
                             matTooltip="Offen: Der Betrag ist teilweise oder vollständig unbezahlt.">
                            <span class="text-sm font-medium whitespace-nowrap">Offen</span>
                        </div>

                        <div *ngIf="this.dunning?.status === this.bookingStatusOptions.PAID"
                             @slideInRight
                             @slideOutLeft
                             class="flex items-center justify-center py-1 px-3 mr-3 mb-3  rounded-full leading-normal text-on-primary bg-primary-500 dark:text-white-300 dark:bg-primary-400"
                             matTooltip="Bezahlt: Der Betrag wurde vollständig bezahlt.">
                            <span class="text-sm font-medium whitespace-nowrap">Bezahlt</span>
                        </div>
                        <div *ngIf="this.isOverdue()"
                             @slideInRight
                             @slideOutLeft
                             class="flex items-center justify-center py-1 px-3 mr-3 mb-3  rounded-full leading-normal text-white bg-warn dark:text-white-300 dark:bg-warn-400"
                             matTooltip="Offen: Der Betrag ist teilweise oder vollständig unbezahlt.">
                            <span class="text-sm font-medium whitespace-nowrap">Überfällig</span>
                        </div>
                    </div>
                </ng-container>

                <!--                // Contact Data-->
                <div class="flex flex-col mt-4 pt-6 border-t space-y-8">
                    <div *ngIf="this.accessLayer !== this.bookingsAccessLayerOptions.PERIOD" @zoomIn
                         @zoomOut
                         class="flex sm:items-center">
                        <mat-icon [svgIcon]="'mat_outline:calendar_today'"></mat-icon>
                        <div class="ml-6 leading-6">
                            H-ID: {{ this.dunning?.h_id }}</div>
                    </div>
                    <div *ngIf="this.accessLayer !== this.bookingsAccessLayerOptions.PERIOD" @zoomIn
                         @zoomOut
                         class="flex sm:items-center">
                        <mat-icon [svgIcon]="'mat_outline:calendar_today'"></mat-icon>
                        <div class="ml-6 leading-6">
                            W-ID: {{ this.dunning?.w_id }}</div>
                    </div>
                    <div @zoomIn @zoomOut
                         class="flex sm:items-center">
                        <mat-icon [svgIcon]="'mat_outline:calendar_today'"></mat-icon>
                        <div class="ml-6 leading-6">
                            {{dunning?.type === this.dunningTypeOptions.PAYMENT_REMINDER ? 'Referenz-ID' : 'Mahnungs-ID'}}
                            : {{ this.dunning?.refID }}</div>
                    </div>

                    <!-- Account holder -->
                    <div @zoomIn @zoomOut
                         class="flex sm:items-center">
                        <mat-icon [svgIcon]="'mat_outline:calendar_today'"></mat-icon>
                        <div class="ml-6 leading-6">
                            Erstellt am: {{ this.dunning?.date | toDate |date:'shortDate' }}</div>
                    </div>

                    <div @zoomIn @zoomOut
                         class="flex sm:items-center">
                        <mat-icon [svgIcon]="'mat_outline:date_range'"
                                  matTooltip="Fälligkeitsdatum der Zahlung"></mat-icon>
                        <div class="ml-6 leading-6">
                            Fälligkeitsdatum: {{ this.dunning?.dueDate | toDate |date:'shortDate' }}</div>
                    </div>

                    <div *ngIf="this.dunning?.feesToPay" @zoomIn @zoomOut
                         class="flex sm:items-center">
                        <mat-icon [svgIcon]="'mat_outline:payments'"></mat-icon>
                        <div class="ml-6 leading-6">
                            Mahngebühren: {{ this.dunning?.feesToPay |  currency:'EUR' }}</div>
                    </div>

                    <div *ngIf="this.dunning?.interestToPay" @zoomIn @zoomOut
                         class="flex sm:items-center">
                        <mat-icon [svgIcon]="'mat_outline:trending_up'"></mat-icon>
                        <div class="ml-6 leading-6">
                            Zahlungsverzug Zinsen: {{ this.dunning?.interestToPay |  currency:'EUR' }}</div>
                    </div>

                </div>

                <!-- Bookings -->
                <div @zoomIn @zoomOut
                     class="flex flex-col mt-8 pt-6 space-y-8">
                    <fuse-card
                            class="flex flex-col max-w-80 md:max-w-160 w-full filter-listing drop-shadow-xl">
                        <div class="flex flex-col md:flex-row w-full">
                            <div class="flex-0 w-80 md:w-64">
                                <img
                                        [src]="'assets/illustrations/add_notes/' + this.appTheme + '/undraw_add_notes.svg'"
                                        alt="Card cover image"
                                        class="w-full h-full object-contain">
                            </div>
                            <div class="flex flex-col flex-auto m-8 mb-4">
                                <div class="text-2xl font-semibold leading-tight text-primary">Sollstellungen in dieser
                                    Mahnung
                                </div>
                                <div class="mt-6">
                                    Posten die in dieser Mahnung enthalten sind
                                </div>
                                <div *ngIf="false" class="flex items-center mt-3 -mx-3">
                                    <button (click)="this.checkBookingsState()"
                                            [disabled]="this.checkingBookings"
                                            [color]="'primary'"
                                            mat-flat-button>
                                        <mat-icon *ngIf="!this.checkingBookings"
                                                  [svgIcon]="'mat_outline:manage_search'"
                                                  class="icon-size-5"></mat-icon>
                                        <span *ngIf="!this.checkingBookings"
                                              class=" ml-2">Zahlungsstatus überprüfen</span>
                                        <mat-progress-spinner
                                                *ngIf=this.checkingBookings
                                                [diameter]="24"
                                                [matTooltip]="'Bitte warten...'"
                                                [mode]="'indeterminate'"></mat-progress-spinner>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="w-full">
                            <mat-divider></mat-divider>
                            <mat-list *ngIf="this.dunning?.relatedBookings">
                                <div mat-subheader>Sollstellungen</div>
                                <mat-list-item *ngFor="let booking of this.dunning?.relatedBookings">
                                    <mat-icon *ngIf="booking?.status === this.bookingStatusOptions.PAID" color="primary"
                                              matListItemIcon
                                              matTooltip="Diese Sollstellung wurde bezahlt."
                                              svgIcon="mat_outline:check"></mat-icon>
                                    <mat-icon *ngIf="booking?.status !== this.bookingStatusOptions.PAID" color="warn"
                                              matListItemIcon
                                              matTooltip="Diese Sollstellung wurde noch unbezahlt."
                                              svgIcon="mat_outline:close"></mat-icon>
                                    <!--                                    <div matListItemTitle></div>-->
                                    <div class="flex flex-row space-x-2" matListItemLine>
                                        <div class="w-full truncate" matTooltip="Beschreibung der Sollstellung">
                                            {{ booking?.text }}
                                        </div>
                                        <div class="w-full" matTooltip="Fälligkeitsdatum">
                                            {{ booking?.date | toDate | date:'shortDate'}}
                                        </div>
                                        <div class="w-full"
                                             matTooltip="Saldo der Sollstellung: Zeigt den noch offenen und zu bezahlenden Betrag an. Ein positiver Wert bedeutet, dass eine Zahlung aussteht.">
                                            {{ booking?.saldo | currency:'EUR' }}
                                        </div>
                                        <div class="flex w-full">
                                            <div *ngIf="booking?.status !== this.bookingStatusOptions.PAID"
                                                 class="px-2 bg-warn text-on-warn rounded"
                                                 matTooltip="Offen: Der Betrag ist teilweise oder vollständig unbezahlt.">
                                                Offen
                                            </div>
                                            <div *ngIf="booking?.status === this.bookingStatusOptions.PAID"
                                                 class="px-2 bg-primary text-on-primary rounded"
                                                 matTooltip="Bezahlt: Der Betrag wurde vollständig bezahlt.">
                                                Bezahlt
                                            </div>
                                        </div>
                                    </div>
                                </mat-list-item>
                            </mat-list>
                        </div>
                    </fuse-card>
                </div>

                <div class="my-12">
                    <mat-divider></mat-divider>
                </div>

                <!-- NOTES -->
                <div @zoomIn @zoomOut
                     class="flex flex-col mt-4 pt-6 space-y-8">
                    <fuse-card
                            class="flex flex-col md:flex-row max-w-80 md:max-w-160 w-full filter-listing drop-shadow-xl">
                        <div class="flex-0 w-80 md:w-64">
                            <img
                                    [src]="'assets/illustrations/add_notes/' + this.appTheme + '/undraw_add_notes.svg'"
                                    alt="Card cover image"
                                    class="w-full h-full object-contain">
                        </div>
                        <div class="flex flex-col flex-auto m-8 mb-4">
                            <div class="text-2xl font-semibold leading-tight text-primary">Fügen Sie Notizen hinzu</div>
                            <div class="mt-6">
                                Fügen Sie hier wichtige Notizen zur Mahnung hinzu, die für spätere Referenzen oder
                                Nachverfolgungen nützlich sein können.
                            </div>
                            <div class="flex items-center mt-3 -mx-3">
                                <button (click)="addNewNote()"
                                        [color]="'primary'"
                                        mat-flat-button
                                        matTooltip="Sie haben die Möglichkeit, zusätzliche Informationen oder Notizen zur Mahnung
                                hinzuzufügen. Diese können für zukünftige Nachverfolgungen oder Referenzen hilfreich
                                sein. Nutzen Sie dieses Feld, um wichtige Details festzuhalten, die im Kontext der
                                Mahnung relevant sein könnten.">
                                    <mat-icon
                                            [svgIcon]="'heroicons_solid:plus-circle'"
                                            class="icon-size-5"></mat-icon>
                                    <span class=" ml-2">Neue Notiz</span>
                                </button>
                            </div>
                        </div>
                    </fuse-card>
                </div>

                <div class="my-12">
                    <mat-divider></mat-divider>
                </div>

                <!-- Activities -->
                <div class="flex flex-col flex-wrap content-center pt-6 space-y-8 drop-shadow-xl">
                    <dunning-activities [dunning]="this.dunning"></dunning-activities>
                </div>

            </div>
        </div>

        <!-- Actions -->
        <ng-container>
            <div class="relative flex flex-col flex-auto items-center px-6 sm:px-12">
                <div class="w-full max-w-3xl">
                    <div
                            class="flex items-center mt-10 -mx-6 sm:-mx-12 py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t bg-gray-50 dark:bg-transparent">
                        <!-- Delete -->
                        <button
                                (click)="requestDelete()"
                                [color]="'warn'"
                                [matTooltip]="'Löschen'"
                                mat-button>
                            <span class="ml-2 mr-1">Löschen</span>
                        </button>
                        <!-- Cancel -->
                        <button [matTooltip]="'Schließen'"
                                [routerLink]="['../']"
                                class="ml-auto"
                                mat-button>
                            Schließen
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

</div>
