import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { PropertyUnit } from '../../../../../models/unit.interface';
import { PropertyUnitPeriod } from '../../../models/property.unit.period.interface';

@Injectable()
export class PeriodPreviousPeriodResolver implements Resolve<PropertyUnitPeriod> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): PropertyUnitPeriod {
    return route?.parent?.data?.period as PropertyUnitPeriod;
  }
}
