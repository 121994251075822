import {Injectable} from '@angular/core';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';
import {
    GraduatedRentPeriod
} from '../../properties/features/units/features/period/models/property.unit.period.interface';

@Injectable()
export class GraduatedRentService extends AbstractFirestoreService<GraduatedRentPeriod> {


    COLLECTION = 'graduatedRent';

    constructor(authService: AuthService) {
        super(authService);
    }

}
