import { Activity } from '@activities/models/activities.types';
import { Injectable } from '@angular/core';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';

@Injectable({providedIn:"root"})
export class MsaActivitiesService extends AbstractFirestoreService<Activity> {

  COLLECTION = 'activities';

  constructor(authService: AuthService) {
    super(authService);
  }

}
