export const generateUniqueId = (customerId: string, periodId: string): string => {
    // Nehmen Sie die aktuelle Zeit in Millisekunden
    const time = new Date().getTime();

    // Erstellen Sie einen Hash-Wert basierend auf Zeit, Kunden- und Perioden-ID
    const hash = createHash(time.toString() + customerId + periodId);

    // Transformieren Sie den Hash in eine alphanumerische Zeichenfolge
    const alphanumericHash = hash.replace(/[^a-z0-9]/gi, '0').slice(0, 4);

    return alphanumericHash;
};

const createHash = (input: string): string => {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
        const char = input.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0; // Konvertieren Sie den Hash in eine 32bit Ganzzahl
    }
    return hash.toString(16);
};
