import {Injectable} from '@angular/core';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';
import {BookingPayment} from "../models/bookingPayment";

@Injectable()
export class BookingPaymentsService extends AbstractFirestoreService<BookingPayment> {

  COLLECTION = 'bookingPayments';

  constructor(authService: AuthService) {
    super(authService);
  }

  setOnlyPeriod(propertyID: string, unitID: string, periodID: string): void {
    this.parentPath = `properties/${propertyID}/units/${unitID}/periods/${periodID}`;
  }

  setParentPath(propertyID: string, unitID: string, periodID: string, bookingID: string): void {
    this.parentPath = `properties/${propertyID}/units/${unitID}/periods/${periodID}/bookings/${bookingID}`;
  }
}
