import { Timestamp } from 'firebase/firestore';
import { ContractBankAccount } from '../../bankAccounts/models';
import { Landlord } from '../../landlords/models';
import { Tenants } from '../../properties/features/units/features/period/features/tenants/models';
import {
  PropertyUnitPeriod
} from '../../properties/features/units/features/period/models/property.unit.period.interface';
import { PropertyUnit } from '../../properties/features/units/models/unit.interface';

/**
 * @author Anthony Nahas
 * @since 22.07.2021
 * @updated 10.03.23
 * @version 1.3
 */
export interface Contract {
  id?: string; // contract ID should always be equal to it's appropriate period ID

  h_id?: string;
  w_id?: string;
  customerID?: string;
  createdAt?: Date | Timestamp;
  updatedAt?: Date | Timestamp;

  type?: ContractType;

  // storage
  path?: string;
  url?: string;
  // data
  date?: Date | Timestamp;
  location?: string; // Ort
  unitViewingDate?: Date | Timestamp; // Besichtigungstermin
  status?: ContractStatus;
  unit?: Partial<PropertyUnit>;
  msaIDList?: string[]; // liste von Bewerber by ID --> diese werden umgewandelt zu Mieter
  tenants?: Tenants;
  period?: PropertyUnitPeriod;
  landlord?: Partial<Landlord>;
  bankAccount?: Partial<ContractBankAccount>;
  // tracking the contract
  currentStep?: number;
  isCompleted?: boolean;
  isDeleted?: boolean;

  // Schlüssel
  keys?: {
    uponDelivery?: true;
    index?: {
      house?: number;
      flat?: number;
      rooms?: number;
      attic?: number;
      basement?: number;
      postbox?: number;
      others?: number;
    };
  };

  // CONTENT
  // BK
  bkPerTenantText?: string;
  bkPerQMorUnitText?: string;
  bkAdditionalAgreementsText?: string;
  otherBKAgreements?: string; // Sonstige Vereinbarungen füe BK
  otherAgreements?: string; // Sonstige Vereinbarungen
  // repairs
  customSmallRepairsAgreements?: string;

  // Garage Offer
  garageOffer?: GarageOffers;
}

export enum ContractStatusOptions {
  DRAFT = 'DRAFT',
  CREATED = 'CREATED',
  SENT = 'SENT',
  CANCELLED = 'CANCELLED',
  SIGNED = 'SIGNED',
}

export type ContractStatus =
  ContractStatusOptions.DRAFT
  | ContractStatusOptions.CREATED
  | ContractStatusOptions.SENT
  | ContractStatusOptions.CANCELLED
  | ContractStatusOptions.SIGNED;

export type Contracts = Contract[];

export interface Category {
  id?: string;
  title?: string;
  value?: string;
  slug?: string;
}

export enum ContractTypeOptions {
  RESIDENTIAL = 'RESIDENTIAL',
  GARAGE = 'GARAGE',
  COMMERCIAL = 'COMMERCIAL', // todo 10.03.23 not implemented yet
  CUSTOM = 'CUSTOM', // todo 10.03.23 not implemented yet
}

export type ContractType =
  ContractTypeOptions.RESIDENTIAL
  | ContractTypeOptions.GARAGE
  | ContractTypeOptions.COMMERCIAL
  | ContractTypeOptions.CUSTOM;

export interface GarageOffers {
  garage?: number;
  carports?: number;
  collectiveSpots?: number; // Sammelgaragenplatz/plätze
  parking?: number; // Stellplatz/plätze
}
