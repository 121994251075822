<mat-form-field [class]="this.formFieldClass">
	<mat-label *ngIf="this.label">{{this.label}}</mat-label>
	<mat-icon
			[svgIcon]="'heroicons_solid:search'"
			class="icon-size-5"
			matPrefix></mat-icon>
	<input
			#searchInput
			(keyup)="handleChange($event)"
			[autocomplete]="'off'"
			[matAutocomplete]="auto"
			[placeholder]="this.placeholder"
			autocapitalize="none"
			autocomplete="off"
			autocorrect="off"
			id="search"
			matInput
			spellcheck="false"
			type="text">

	<mat-autocomplete
			#auto="matAutocomplete"
			style="margin-top: 30px">

		<ng-container *ngFor="let index of state.indices || []">
			<mat-option (click)="onQuerySuggestionClick.emit(option)"
			            *ngFor="let option of index.hits"
			            [style]="this.matOptionStyles">
				<ng-container [ngTemplateOutletContext]="{ option: option } "
				              [ngTemplateOutlet]="template"></ng-container>
			</mat-option>
		</ng-container>
	</mat-autocomplete>
	<mat-hint *ngIf="this.hint">{{this.hint}}</mat-hint>
</mat-form-field>


