import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {StatMsa} from '@msa/models';
import {StatsService} from '../../stats/services/stats.service';

@Injectable({providedIn:"root"})
export class StatsMsaResolver implements Resolve<StatMsa> {

  constructor(private statsService: StatsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<StatMsa> {
    return this.statsService
      .doc('msa')
      .get()
      .toPromise()
      .then((res) => {
        const stats = res.data() as StatMsa;
        console.log('stats msa received from firestore', stats);
        return stats;
      })
      .catch((err) => {
        console.error('Error:', err);
        return null;
      });
  }
}
