import { Injectable } from '@angular/core';
import { AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { CustomerLogo } from '@settings/models/logo.settings.interface';
import { PublicProfileSettings } from '@settings/models/publicProfile.settings.interface';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PublicProfileSettingsService extends AbstractFirestoreService<PublicProfileSettings> {

  COLLECTION = 'settings';

  override doc(): AngularFirestoreDocument<PublicProfileSettings> {
    return super.doc('publicProfile');
  }

  getLogo(): AngularFirestoreDocument<CustomerLogo> {
    return super.doc('logo');
  }

  constructor(authService: AuthService) {
    super(authService);
  }
}
