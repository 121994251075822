import {Injectable} from '@angular/core';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';
import {BehaviorSubject} from 'rxjs';
import {Label, Note} from '../models/notes.types';

@Injectable()
export class LabelsService extends AbstractFirestoreService<Label> {

    Labels$: BehaviorSubject<Label[]> = new BehaviorSubject<Label[]>(null);

    COLLECTION = 'notesLabels';

    constructor(authService: AuthService) {
        super(authService);
  }

   
}
