// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {env} from '@env/.env';

export const environment = {
    production: false,
    useEmulators: false,
    pool: false,
    isComingSoon: false,
    showQuickChat: false,
    customer: 'jug',
    version: env.npm_package_version + '-dev',
    defaultLanguage: 'de',
    supportedLanguages: ['en', 'de', 'fr'],
    GTM_ID: 'GTM-5972GVP',
    GOOGLE_MAPS_API_KEY: 'AIzaSyBUatcv92TBlAxy-XHGQavV6u-o0FkQrx4',
    stripeKey: 'pk_test_51J2LciBpGVbP5eMS1tLLjN3P3pcRkApEvGKZ9CZ49aPUngV6H6XAl57ZxQ5ovkEzD2EgNMTe9objTvSe6X6YxSU200lRrf6ize',
    reCaptchaV3Key: '6LeqVVoiAAAAAATRpSgYWaLRx7DeIMuj4dkECEZR',
    firebase: {
        apiKey: 'AIzaSyAl9q026thJtozspiXkmGzDBrQ_BQJF6c0',
        authDomain: 'dev-nahaus-de.firebaseapp.com',
        databaseURL: 'https://dev-nahaus-de.firebaseio.com',
        projectId: 'dev-nahaus-de',
        storageBucket: 'dev-nahaus-de.appspot.com',
        messagingSenderId: '988017796709',
        appId: '1:988017796709:web:ce228222a9e984e820488c',
        measurementId: 'G-P0F0Y3F7XH'
    },
    webApiKey: 'AIzaSyAl9q026thJtozspiXkmGzDBrQ_BQJF6c0',
    googleclientId: '988017796709-fvjk8oofeps10qdvoflss6bhvk0a4thf.apps.googleusercontent.com',
    algolia: {
        applicationId: 'H4BDFXZHFE',
        searchApiKey: '4a824d174c3c00b676e1e007f229b5bb',
        indexName: {
            bankAccounts: 'bankAccounts',
            msa: 'msa',
            users: 'users',
            properties: 'properties',
            places: 'places',
            units: 'units',
            tenants: 'tenants',
            landlords: 'landlords',
            vacancies: 'vacancies',
            serviceProviders: 'serviceProviders',
            serviceContracts: 'serviceContracts',
            meters: 'meters',
            metersReRegistrations: 'metersReRegistrations',
            insurance: 'insurance',
            contracts: 'contracts',
            loans: 'loans',
            letters: 'letters',
            institutions: 'institutions',
            managers: 'managers',
            graduatedRent: 'graduatedRent',
            transactions: 'transactions',
            bookings: 'bookings',
            files: 'files',
            terminations: 'terminations',
            letterTemplates: 'letterTemplates',
            contacts: 'contacts',
            dunnings: 'dunnings',
            nahausBookingsAccounts: 'nahausBookingsAccounts',
            cards: 'cards',
            expenses: 'expenses',
            reminders: 'reminders',
        }
    },
    buckets: {
        main: 'dev-nahaus-de'
    },
    msaBaseURL: 'https://dev.jug.nahaus.de',
    stripe: {
        prices: {
            sms: 'price_1P2ZSfBpGVbP5eMSB5OCyaQz'
        }
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
