import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnInit,
    Optional,
    Output,
    ViewChild
} from '@angular/core';
import {FormControl, FormsModule} from '@angular/forms';
import {MatOptionModule} from '@angular/material/core';
import {BaseWidget, NgAisIndex, NgAisInstantSearch} from 'angular-instantsearch';
import {connectAutocomplete} from 'instantsearch.js/es/connectors';
import {Institution} from '../../../../modules/admin/institutions/models/institution.interface';
import {Landlord} from '../../../../modules/admin/landlords/models';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {NgClass, NgFor, NgIf} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
    selector: 'app-admin-autocomplete-algolia-institutions-only-search',
    templateUrl: './autocomplete-algolia-institutions-only-search.component.html',
    styleUrls: ['./autocomplete-algolia-institutions-only-search.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, NgClass, NgIf, MatInputModule, MatAutocompleteModule, FormsModule, NgFor, MatOptionModule, MatButtonModule, MatTooltipModule, MatIconModule]
})
export class AutocompleteAlgoliaInstitutionsOnlySearchComponent extends BaseWidget implements OnInit {

  @ViewChild('query') inputElement: ElementRef;

  @Input() emphasized: boolean;
  @Input() label: string = 'Bank Institut';
  @Input() clearIconTooltip: string = 'Zurücksetzen';
  @Input() showHint: boolean = true;
  @Input() hintText: string = 'Unser System ist nahtlos mit den meisten deutschen Banken integriert';
  @Input() errorText: string = 'Bitte wählen Sie aus der Liste Ihre Bank aus.';

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onQuerySuggestionClick = new EventEmitter<Institution | null>();
  @Output() focus = new EventEmitter<void>();

  selectedInstitution: Institution;

  state: {
    query: string;
    refine: Function;
    indices: object[];
  };

  ngOnInit() {
    this.createWidget(connectAutocomplete, {});
    super.ngOnInit();
  }

  control: FormControl;
  disabled = false;

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch) {
    super('AutocompleteComponent');
    this.onQuerySuggestionClick.subscribe((value) => console.log('onQuerySuggestionClick --> ', value));
  }

  handleChange($event: KeyboardEvent) {
    this.state.refine(($event.target as HTMLInputElement).value);
  }

  trackByFn(index: number, landlord: Landlord) {
    return landlord.id; // or item.id
  }

  clear() {
    this.selectedInstitution = null;
  }

  select(option: Institution) {
    this.selectedInstitution = option;
    this.onQuerySuggestionClick.emit(option);
  }
}
