import {Injectable} from '@angular/core';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';
import {Costs} from "../../../models/property.unit.period.interface";

@Injectable()
export class CostChangesService extends AbstractFirestoreService<Costs> {

    COLLECTION = 'rentalCostChanges';

    constructor(authService: AuthService) {
        super(authService);
    }

    setParentPath(propertyID: string, unitID: string, periodID: string): void {
        this.parentPath = `properties/${propertyID}/units/${unitID}/periods/${periodID}`;
    }
}
