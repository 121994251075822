import {Timestamp} from 'firebase/firestore';

export interface BookingAccount {
  id?: BookingAccountType;
  customerID?: string;
  createdAt?: Timestamp | Date;
  updatedAt?: Timestamp | Date;
  propertyID?: string;
  unitID?: string;
  periodID?: string;
  amount?: number; // what the tenant should pay only for the saldo account
  saldo?: number; // what the tenant should pay for all accounts except saldo account
}

export enum BookingAccountOptions {
  saldo = 'saldo', // offene Posten
  deposit = 'deposit', // Kaution
  rental = 'rental', // Miete
  bk = 'bk', // Betriebskosten
  hk = 'hk', // Heizkosten
  dunningFees = 'dunningFees', // Mahngebühren
  weg = 'weg', // Hausgeld
  reserve = 'reserve', // Rücklagen
}

export type BookingAccountType =
  BookingAccountOptions.saldo
  | BookingAccountOptions.deposit
  | BookingAccountOptions.rental
  | BookingAccountOptions.bk
  | BookingAccountOptions.hk
  | BookingAccountOptions.dunningFees
  | BookingAccountOptions.weg
  | BookingAccountOptions.reserve;

export type WEGBookingAccountType = BookingAccountOptions.weg
    | BookingAccountOptions.reserve;
/**
 * Every time this document is created --> a logic will be triggered to generate stats for the target booking account
 *
 * @author Anthony Nahas
 * @since 17.04.23
 * @version 1.0
 */
export interface RequestStats {
  id?: string;
  createdAt?: Date | Timestamp;
}

export interface BookingAccountStat {
  id?: string;
  createdAt?: Date | Timestamp;
  amount?: number;
  from: Date | Timestamp;
  to: Date | Timestamp;
}
