import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedDefaultTagsService {
    selectedTags: string[] = [];
    selectedTagsChange: Subject<string[]> = new Subject<string[]>();

    constructor() {
    }

    updateSelectedTags(tags: string[]) {
        this.selectedTags = tags;
        this.selectedTagsChange.next(this.selectedTags);
    }
}
