<div class="relative flex flex-col w-full h-full">

	<!-- Dismiss button -->
	<div class="absolute top-0 right-0 pt-4 pr-4">
		<button
				[matDialogClose]="undefined"
				mat-icon-button>
			<mat-icon
					[svgIcon]="'heroicons_outline:x'"
					class="text-secondary"></mat-icon>
		</button>
	</div>

	<!-- Content -->
	<div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">

		<!-- Icon -->
		<div class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600">
			<mat-icon
					class="text-current"
					svgIcon="mat_outline:image"></mat-icon>
		</div>

		<ng-container *ngIf="data?.title || data?.message">
			<div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">

				<!-- Title -->
				<ng-container *ngIf="data?.title">
					<div
							[innerHTML]="data?.title"
							class="text-xl leading-6 font-medium"></div>
				</ng-container>

				<!-- Message -->
				<ng-container *ngIf="data?.message">
					<div
							[innerHTML]="data?.message"
							class="text-secondary"></div>
				</ng-container>
			</div>
		</ng-container>

	</div>

	<div>
		<img [src]="data?.url">
	</div>

	<!-- Actions -->
	<div class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

		<!-- Cancel -->
		<button
				[matDialogClose]="'cancelled'"
				mat-stroked-button>
			Abbrechen
		</button>
	</div>

</div>
