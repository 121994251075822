import {Injectable} from '@angular/core';
import {Firestore} from '@angular/fire/firestore';
import {AbstractFirestoreModularService} from '@shared/services/abstracts/AbstractFirestoreModularService';
import {AuthService} from '@shared/services/auth.service';
import {DigitalProtocol} from "../models/digital-protocol.interface";

export interface TransactionsRange {
    start: Date | null;
    end: Date | null;
}

@Injectable()
export class DigitalProtocolModularService extends AbstractFirestoreModularService<DigitalProtocol> {

    COLLECTION = 'protocols';

    constructor(authService: AuthService,
                firestore: Firestore) {
        super(authService, firestore);
    }
}
