import {Injectable} from '@angular/core';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';
import {Letter} from '../models/letter.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LettersService extends AbstractFirestoreService<Letter> {

    COLLECTION = 'letters';

    public letterFormGroupChanged = new BehaviorSubject<Letter>(null);

    constructor(authService: AuthService) {
        super(authService);
    }
}
