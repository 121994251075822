import {Injectable} from '@angular/core';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';
import {Expense} from "../models/expenses.interface";
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ExpensesService extends AbstractFirestoreService<Expense> {
    private _data: BehaviorSubject<any> = new BehaviorSubject(null);
    COLLECTION = 'expenses';

    constructor(authService: AuthService) {
        super(authService);
    }

    get data$(): Observable<any> {
        return this._data.asObservable();
    }
}
