import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {TransactionsRange} from "@transactions/services/transactions-modular.service";

@Injectable()
export class BookingsHelperService {
    private dateRangeSubject = new BehaviorSubject<{ start: Date, end: Date, rangeType: string }>({
        start: null,
        end: null,
        rangeType: null
    });

    dateRange$ = this.dateRangeSubject.asObservable();

    setDateRange(start: Date, end: Date, rangeType: string) {
        this.dateRangeSubject.next({start, end, rangeType});
    }
    private _columns = new BehaviorSubject<string[]>(null); // Standardauswahl
    columns$ = this._columns.asObservable();
    onRangeChanged$: BehaviorSubject<TransactionsRange> = new BehaviorSubject<TransactionsRange>(null);
    onDashboardRangeChanged$: BehaviorSubject<TransactionsRange> = new BehaviorSubject<TransactionsRange>(null);

    constructor() {
    }

    updateColumns(columns: string[]) {
        this._columns.next(columns);
    }

    initColumns(columns: string[]) {
        this._columns.next(columns);
    }
}
