<div class="relative flex flex-col flex-auto min-w-0 overflow-hidden">

    <!-- Main -->
    <div class="flex flex-col flex-auto px-6 py-10 sm:px-16 sm:pt-18 sm:pb-20">

        <!-- Activity feed -->
        <div class="w-full max-w-3xl">

            <!-- Title -->
            <div class="text-4xl font-extrabold tracking-tight leading-none">Mahnungshistorie</div>
            <div class="mt-1.5 text-lg text-secondary">Hier finden Sie eine Übersicht aller Aktivitäten und Schritte,
                die im Zusammenhang mit dieser Mahnung durchgeführt wurden.
            </div>

            <ng-container *ngIf="this.activities">
                <ng-container *ngIf="this.activities?.length > 0; else noActivity">
                    <div class="mt-8">
                        <ol>
                            <!-- Activities -->
                            <ng-container
                                *ngFor="let activity of activities; let i = index; let first = first; let last = last; trackBy: trackByFn">

                                <!-- Date separator -->
                                <ng-container
                                    *ngIf="first || !this.isSameDay(activity?.date | toDate, activities[i - 1]?.date | toDate)">
                                    <li class="relative flex py-7">
                                        <div
                                            class="relative py-2 px-8 text-md font-medium leading-5 rounded-full bg-primary text-on-primary">
                                            {{this.getRelativeFormat(activity?.date | toDate)}}
                                        </div>
                                    </li>
                                </ng-container>

                                <!-- Activity -->
                                <li class="relative flex py-7">
                                    <!-- Line -->
                                    <ng-container
                                        *ngIf="!last && this.isSameDay(activity?.date | toDate, activities[i + 1].date | toDate)">
                                        <div
                                            class="absolute top-7 left-5 w-0.5 h-full -ml-px bg-gray-300 dark:bg-gray-600"></div>
                                    </ng-container>

                                    <div @zoomIn @zoomOut class="relative flex flex-auto">
                                        <!-- Icon -->
                                        <ng-container *ngIf="activity?.icon && !activity?.image">
                                            <div
                                                class="flex flex-shrink-0 items-center justify-center w-10 h-10 mr-4 rounded-full bg-gray-400">
                                                <mat-icon
                                                    [svgIcon]="activity?.icon"
                                                    class="icon-size-5 text-white">
                                                </mat-icon>
                                            </div>
                                        </ng-container>

                                        <!-- Image -->
                                        <ng-container *ngIf="activity?.image">
                                            <img
                                                [alt]="'Activity image'"
                                                [src]="activity?.image"
                                                class="flex-shrink-0 w-10 h-10 mr-4 rounded-full overflow-hidden object-cover object-center">
                                        </ng-container>

                                        <!-- Content -->
                                        <div class="flex flex-col flex-auto items-start">
                                            <!-- Description -->
                                            <ng-container *ngIf="activity?.description">
                                                <div [innerHTML]="activity?.description"></div>
                                            </ng-container>
                                            <div
                                                class="flex flex-col sm:flex-row sm:items-center mt-2 sm:mt-1 sm:space-x-2 text-md leading-5">
                                                <!-- Date -->
                                                <div class="text-secondary">
                                                    {{activity?.date | toDate | date:'MMM dd YYYY, H:mm'}}
                                                </div>
                                                <!-- Linked content -->
                                                <ng-container *ngIf="activity?.linkedContent">
                                                    <div class="hidden sm:block">&bull;</div>
                                                    <!-- Internal link -->
                                                    <ng-container *ngIf="activity?.useRouter">
                                                        <a
                                                            [routerLink]="activity?.link"
                                                            class="cursor-pointer text-primary">
                                                            {{activity?.linkedContent}}
                                                        </a>
                                                    </ng-container>
                                                    <!-- External link -->
                                                    <ng-container *ngIf="!activity?.useRouter">
                                                        <a
                                                            [href]="activity?.link"
                                                            class="cursor-pointer text-primary"
                                                            target="_blank">
                                                            {{activity?.linkedContent}}
                                                        </a>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                            <!-- Extra content -->
                                            <ng-container *ngIf="activity?.extraContent">
                                                <div
                                                    [innerHTML]="activity?.extraContent"
                                                    class="mt-4 py-4 px-5 rounded-lg bg-gray-200 dark:bg-gray-800"></div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </li>
                            </ng-container>
                        </ol>
                    </div>
                </ng-container>
            </ng-container>

            <!-- Loading template -->
            <ng-template [ngIf]="this.loading">
                <mat-progress-bar class="mt-2" mode="query"></mat-progress-bar>
            </ng-template>

            <!-- No Activity template -->
            <ng-template #noActivity>
                <div class="mt-2">
                    <span class="font-medium">Im Moment gibt es keine Aktivitäten...</span>
                </div>
            </ng-template>

        </div>
    </div>

</div>
