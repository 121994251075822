<div class="relative flex flex-col w-full h-full">

	<!-- Dismiss button -->
	<div class="absolute top-0 right-0 pt-4 pr-4">
		<button
				[matDialogClose]="undefined"
				mat-icon-button>
			<mat-icon
					[svgIcon]="'heroicons_outline:x'"
					class="text-secondary"></mat-icon>
		</button>
	</div>

	<!-- Content -->
	<div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">

		<!-- Icon -->
		<div class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600">
			<mat-icon
					class="text-current"
					svgIcon="mat_outline:image"></mat-icon>
		</div>

		<div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">

			<!-- Title -->
			<div class="text-xl leading-6 font-medium">Bild bearbeiten</div>

			<!-- Message -->
			<!--			<ng-container *ngIf="data?.message">-->
			<!--			<div class="text-secondary">Bitte warten</div>-->
			<!--			</ng-container>-->
		</div>
	</div>

	<div class="w-full">
        <lib2-cropperjs
				(cancelCrop)="onCancelEdit()"
				(croppedAvatar)="onAvatarCropped($event)"
				[file]="this.data?.file"
        ></lib2-cropperjs>
	</div>

</div>
