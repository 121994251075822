import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '@env/environment';
import {FuseConfigService} from '@fuse/services/config-old';
import {AuthService} from '@shared/services/auth.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AppConfig} from '../../../core/config/app.config';

@Component({
    template: '',
    standalone: true
})
export class NahausMainBaseComponent implements OnInit, OnDestroy {

  env = environment;
  appTheme: string;

  // RXJS
  unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private fuseConfigService: FuseConfigService,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    console.log('ngOnInit-NahausMainBaseComponent');
    this.fuseConfigService.config$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((config: AppConfig) => {
        console.log('config', config);
        this.appTheme = config?.theme.split('-')[1];
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    console.log('ngOnDestroy NahausMainBaseComponent');
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item?.id || index;
  }

}
