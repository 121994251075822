import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Expense} from "../models/expenses.interface";
import {ExpensesModularService} from "../services/expenses-modular.service";
import {AbstractFirestoreModularDocResolver} from "@shared/services/abstracts/AbstractFirestoreModularDocResolver";


@Injectable({providedIn: 'root'})
export class ExpenseResolver extends AbstractFirestoreModularDocResolver<Expense> {

    constructor(router: Router, service: ExpensesModularService) {
        super(router, service);
    }
}
