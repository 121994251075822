import { Injectable } from '@angular/core';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';
import { Transaction } from '@transactions/models/transaction.interface';


@Injectable()
export class TransactionsService extends AbstractFirestoreService<Transaction> {

  COLLECTION = 'transactions';

  constructor(authService: AuthService) {
    super(authService);
  }

  setParentPath(bankAccountID: string): void {
    this.parentPath = `bankAccounts/${bankAccountID}`;
  }
}
