import {Injectable} from '@angular/core';
import {Firestore} from '@angular/fire/firestore';
import {AbstractFirestoreModularService} from '@shared/services/abstracts/AbstractFirestoreModularService';
import {AuthService} from '@shared/services/auth.service';
import {NahausBookingsAccount} from "../models/NahausBookingsAccount.interface";

@Injectable({providedIn: 'root'})
export class NahausBookingsAccountsModularService extends AbstractFirestoreModularService<NahausBookingsAccount> {

    COLLECTION = 'nahausBookingsAccounts'; // not the same as bookingsAccount with the period doc as subCollection --> this is only available on account layer

    constructor(authService: AuthService,
                firestore: Firestore) {
        super(authService, firestore);
    }
}
