import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {CurrencyPipe, DatePipe, formatCurrency, NgClass, NgFor, NgIf} from '@angular/common';
import {ChangeDetectorRef, Component, LOCALE_ID, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {MatChipInputEvent, MatChipListbox, MatChipsModule} from '@angular/material/chips';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {environment} from '@env/environment';
import {fuseAnimations} from '@fuse/animations';
import {getPublicProfileData, getPublicProfileDataWithLogo} from '@settings/helpers';
import {CustomerLogo} from '@settings/models/logo.settings.interface';
import {PublicProfileSettings} from '@settings/models/publicProfile.settings.interface';
import {PdfService} from '@shared/services/pdf.service';
import htmlToPdfmake from 'html-to-pdfmake';
import {set} from 'lodash-es';
import moment from 'moment';
import {NgxMailtoService} from 'ngx-mailto';
import {ContentChange, QuillEditorComponent, QuillModules} from 'ngx-quill';

import {combineLatest, forkJoin, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, finalize, map, startWith, take, takeUntil, tap} from 'rxjs/operators';
import {LettersService} from "@letters/services/letters.service";
import {PeriodsService} from "../../../../../properties/features/units/features/period/services/periods.service";
import {Property} from "../../../../../properties/models/property.interface";
import {PropertyUnit} from "../../../../../properties/features/units/models/unit.interface";
import {
    PropertyUnitPeriod
} from "../../../../../properties/features/units/features/period/models/property.unit.period.interface";
import {Tenant, Tenants} from "../../../../../properties/features/units/features/period/features/tenants/models";
import {
    TerminationConfirmation
} from "../../../../../properties/features/units/features/period/features/termination/models/termination.interface";
import {TenantsService} from "@tenants/services/tenants.service";
import {
    convertTimestamps,
    findInvalidControls,
    getDateStringForFileName,
    removeEmpty
} from "../../../../../../../helpers";
import {Letter, settings} from "@letters/models/letter.interface";
import {formatAddress} from "../../../../../../../helpers/address";
import {DunningsModularService} from "../../services/dunnings-modular.service";
import {
    Dunning,
    DunningType,
    DunningTypeOptions,
    LastWarningType,
    LastWarningTypeOptions
} from "../../models/dunning.interface";
import {MatListModule, MatSelectionListChange} from "@angular/material/list";
import {BookingService} from "../../../../services/booking.service";
import {
    Booking,
    BookingArtOptions,
    Bookings,
    BookingStatusOptions,
    BookingTypeOptions
} from "../../../../models/booking";
import {FuseConfigService} from "@fuse/services/config-old";
import {AppConfig} from "../../../../../../../core/config/app.config";
import {calculateInterest} from "../../helpers";
import {PAYMENT_REMINDER_TEMPLATE, PaymentReminderTemplateParams} from "../../pdf/PAYMENT_REMINDER_TEMPLATE";
import {FIRST_REMINDER_TEMPLATE} from "../../pdf/FIRST_REMINDER_TEMPLATE";
import {SECOND_REMINDER_TEMPLATE} from "../../pdf/SECOND_REMINDER_TEMPLATE";
import {FINAL_REMINDER_TEMPLATE} from "../../pdf/FINAL_REMINDER_TEMPLATE";
import {MatDialog} from "@angular/material/dialog";
import {
    SelectDunningLastReminderTypeDialogComponent
} from "../select-dunning-last-reminder-type-dialog/select-dunning-last-reminder-type-dialog.component";
import {
    emailArrayValidator,
    requiredArrayValidator
} from "@shared/components/chip-list-email-input/chip-list-email-input.component";
import {setDoc} from "@angular/fire/firestore";
import {generateUniqueId} from "@shared/helpers";
import {STANDARD_DUNNING, StandardDunningData} from "../../pdf/standard_dunning";
import {Landlord} from "../../../../../landlords/models";
import {Manager} from "../../../../../managers/models";
import {DunningsActivitiesService} from "../../services/dunnings-activities.service";
import {Activity, ActivityFeatureOption} from "@activities/models/activities.types";
import {QUILL_CONFIG} from '@shared/quill.config';
import {MatSlideToggleChange, MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MomentModule} from 'ngx-moment';
import {MathAbsPipe} from '../../../../../../../shared/pipes/math-abs.pipe';
import {ToDatePipe} from '../../../../../../../shared/pipes/to-date.pipe';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {MatExpansionModule} from '@angular/material/expansion';
import {
    ChipListEmailInputComponent
} from '../../../../../../../shared/components/chip-list-email-input/chip-list-email-input.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatTableModule} from '@angular/material/table';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {NgxCurrencyModule} from 'ngx-currency';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FuseAlertComponent} from '@fuse/components/alert/alert.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatStepperModule} from '@angular/material/stepper';
import {FuseCardComponent} from '@fuse/components/card/card.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {CustomDateAdapter} from "../../../../../../../app.component";
import {Platform} from "@angular/cdk/platform";
import {roundIfMoreThanTwoDecimals} from "@shared/currency";
import {ActivitiesService} from "@activities/services/activities.service";

export const minOneBookingValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
    const bookings = control.value;
    return Array.isArray(bookings) && bookings.length > 0 ? null : {'minOneBooking': {value: control.value}};
};

@Component({
    selector: 'create-termination',
    templateUrl: 'dunning-create.component.html',
    styleUrls: ['dunning-create.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    standalone: true,
    imports: [CdkScrollable, RouterLink, MatIconModule, MatButtonModule, NgIf, FuseCardComponent, MatStepperModule, MatListModule, FormsModule, ReactiveFormsModule, NgFor, MatTooltipModule, FuseAlertComponent, MatFormFieldModule, MatInputModule, NgxCurrencyModule, MatDatepickerModule, MatSlideToggleModule, MatTableModule, NgClass, MatDividerModule, MatChipsModule, ChipListEmailInputComponent, MatExpansionModule, QuillEditorComponent, PdfViewerModule, MatProgressSpinnerModule, CurrencyPipe, DatePipe, ToDatePipe, MathAbsPipe, MomentModule],
    providers: [
        PeriodsService, DunningsModularService, DunningsActivitiesService, LettersService, BookingService, ActivitiesService, {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: moment.ISO_8601
                },
                display: {
                    dateInput: 'LL',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY'
                }
            }
        },
        {provide: LOCALE_ID, useValue: 'de'},
        {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, Platform]}
    ],
})
export class DunningCreateComponent implements OnInit, OnDestroy {

    @ViewChild('quill') quill: QuillEditorComponent;
    @ViewChild('chipList') chipList: MatChipListbox;

    publicProfile: PublicProfileSettings;
    publicProfileOrigin: PublicProfileSettings;
    logo: CustomerLogo;

    landlord?: Landlord;
    manager?: Manager;

    property: Property;
    unit: PropertyUnit;
    period: PropertyUnitPeriod;
    tenants: Tenants;
    unpaidBookings: Bookings;

    today = new Date();

    pdfSrc: string;

    creating: boolean;
    isError: boolean;
    loading = true;
    loadingPDF: boolean;
    renderingPDF: boolean;
    isLinear = true;

    nextStage: DunningTypeOptions;

    totalSaldoToPay: number;
    // bookingsWithInterest: BookingWithInterest[] = []; // Ihr Array mit Zinsdaten
    // displayedColumns: string[] = ['dueDate', 'amount', 'overdueDays', 'interestAmount', 'totalInterest'];
    displayedColumns: string[] = ['text', 'dueDate', 'amount', 'overdueDays', 'interestAmount'];

    // reactive forms
    dunningFormGroup: FormGroup;
    letterFormGroup: FormGroup;
    confirmationFormGroup: FormGroup;
    dunningTypeFC = new FormControl([DunningTypeOptions.PAYMENT_REMINDER])
    selectedBookingsFC: FormControl;

    dunningTypeOptions = DunningTypeOptions;
    dunningTypes = [
        {
            value: DunningTypeOptions.PAYMENT_REMINDER,
            viewValue: 'Zahlungserinnerung',
            tooltip: 'Eine freundliche Erinnerung an eine ausstehende Zahlung, ohne formelle Folgen.'
        },
        {
            value: DunningTypeOptions.FIRST_REMINDER,
            viewValue: 'Erste Mahnung',
            tooltip: 'Die erste formelle Aufforderung zur Zahlung mit Hinweis auf mögliche Verzugszinsen.'
        },
        {
            value: DunningTypeOptions.SECOND_REMINDER,
            viewValue: 'Zweite Mahnung',
            tooltip: 'Eine weitere Mahnung mit stärkerem Nachdruck und der Ankündigung von weiteren Maßnahmen bei Nichtzahlung.'
        },
        {
            value: DunningTypeOptions.FINAL_REMINDER,
            viewValue: 'Letzte Mahnung',
            tooltip: 'Die letzte Mahnung vor rechtlichen Schritten, deutlicher Hinweis auf ernste Konsequenzen.'
        }
    ];

    copyFields: { cc: boolean; bcc: boolean } = {
        cc: false,
        bcc: false
    };

    quillModules: QuillModules = QUILL_CONFIG;


    // step 4 indicators
    dunningCreated: boolean;
    emailGenerated: boolean;
    pdfCreated: boolean;
    pdfUploaded: boolean;

    // data for letters PDF
    email: string;
    msaURL: string;

    addOnBlur = true;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    emailFormControl: FormControl = new FormControl(
        [],
        [requiredArrayValidator(), emailArrayValidator()]
    );

    cCemailFormControl: FormControl = new FormControl(
        [],
        [emailArrayValidator()]
    );

    bCCemailFormControl: FormControl = new FormControl(
        [],
        [emailArrayValidator()]
    );

    // upload
    uploadPercent: Observable<number>;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    activeUser: boolean;

    settings: settings = {
        logo: true,
        footer: true,
        bankAccount: true
    };
    signatureRequired: boolean = true;
    hasLogo: boolean = true;


    /**
     * Constructor
     */
    constructor(private _changeDetectorRef: ChangeDetectorRef,
                private fuseConfigService: FuseConfigService,
                private _formBuilder: UntypedFormBuilder,
                private dialog: MatDialog,
                private router: Router,
                private route: ActivatedRoute,
                private snackbar: MatSnackBar,
                private periodService: PeriodsService,
                private dunningsModularService: DunningsModularService,
                private dunningsActivitiesService: DunningsActivitiesService,
                private bookingsService: BookingService,
                private tenantsService: TenantsService,
                private ngxMailtoService: NgxMailtoService,
                private datePipe: DatePipe,
                private currencyPipe: CurrencyPipe,
                private pdf: PdfService,
                private lettersService: LettersService,
                private storage: AngularFireStorage,
                private activitiesService: ActivitiesService) {
    }

    get confirmationFG(): UntypedFormGroup {
        return this.confirmationFormGroup as UntypedFormGroup;
    }

    get emailsControls(): UntypedFormArray {
        return this.confirmationFormGroup?.get('to') as UntypedFormArray;
    }

    get namesControls(): UntypedFormArray {
        return this.confirmationFormGroup?.get('tenantsNameList') as UntypedFormArray;
    }

    ngOnInit(): void {

        this.nextStage = this.route.snapshot.queryParams['nextStage'];
        console.log("nextStage = ", this.nextStage, this.route.snapshot.queryParams);

        this.logo = this.route.snapshot.data?.logo as CustomerLogo;
        console.log("logo --> ", this.logo);
        this.publicProfileOrigin = this.route.snapshot.data?.publicProfile as PublicProfileSettings;
        this.publicProfile = getPublicProfileDataWithLogo(this.route.snapshot.data?.publicProfile as PublicProfileSettings, this.property?.landlord, this.property?.manager, this.logo);

        this.activeUser = this.dunningsModularService?.authService?.isActiveUser;
        this.dunningsModularService?.authService?.isActiveUser$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isActive) => this.activeUser = isActive);

        console.log('activeUser = ', this.activeUser);

        this.fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: AppConfig) => {
                console.log('config', config);
                // this.appTheme = config?.theme.split('-')[1];
                // this.changeDetectorRef.markForCheck();
                // this.changeDetectorRef.detectChanges();
            });

        this.property = this.route.snapshot.data?.property as Property;
        this.unit = this.route.snapshot?.data?.unit as PropertyUnit;
        this.period = this.route.snapshot?.data?.period as PropertyUnitPeriod;

        this.dunningsModularService.setParentPathForPeriod(this.property?.id, this.unit?.id, this.period?.id);
        this.tenantsService.setPath(this.property?.id, this.unit?.id, this.period?.id);
        this.bookingsService.setParentPath(this.property?.id, this.unit?.id, this.period?.id);

        this.logo = this.route.snapshot.data?.logo as CustomerLogo;
        this.publicProfile = getPublicProfileData(this.route.snapshot.data?.publicProfile as PublicProfileSettings, this.property?.manager || this.property?.landlord);
        this.publicProfile.logo = this.logo;
        this.getDataForPDF();
        // this.period = this.route.snapshot?.data?.property_unit_period.period as PropertyUnitPeriod;

        this.dunningFormGroup = this.createDunningFormGroup();
        this.letterFormGroup = this.createLetterFormGroup();
        this.confirmationFormGroup =  // Letter
            this._formBuilder.group({
                date: [this.today, Validators.required],
                byPost: true,
                byEmail: true,
                receivers: null,
                // to: this._formBuilder.control([], requiredArrayValidator(), emailArrayValidator() as any),
                to: this.emailFormControl,
                tenantsNameList: this._formBuilder.array([], Validators.required),
                tenantsName: null,
                cc: this.cCemailFormControl,
                bcc: this.bCCemailFormControl,
                subject: [this.getSubject(), Validators.required],
                html: [this.getHtmlTemplate(), Validators.required],
                settings: this._formBuilder.group({
                    logo: this.settings?.logo ?? true,
                    footer: this.settings?.footer ?? true,
                    bankAccount: this.settings?.bankAccount ?? true,
                }),
                hasLogo: this.hasLogo ?? true,
                signatureRequired: this.signatureRequired ?? true
                // text: [TERMINATION_CONFIRMED_TEMPLATE(this.convertDateToString(terminationDate), this.convertDateToString(endDate))]
            })

        this.confirmationFormGroup.valueChanges.subscribe(v => {
            // console.log('Mahnung', v, this.emailsControls);
            console.log('confirmationFormGroup changed', v);
            console.log('confirmationFormGroup error', findInvalidControls(this.dunningFormGroup));
        });


        if (!(this.property && this.unit)) {
            // error
            console.error('Error: some important data has been not received');
        } else {
            // fetch period
            console.log('publicProfile', this.publicProfile);
            console.log('Fetch period for id', this.unit?.currentPeriod?.id, this.route.snapshot?.params['periodID']);
            this.periodService.parentPath = `properties/${this.property?.id}/units/${this.unit?.id}`;

            this.letterFormGroup.get('property').patchValue(this.property);
            this.letterFormGroup.get('unitID').patchValue(this.unit?.id);

            this.loading = true;
            this._changeDetectorRef.markForCheck();
            this.getPeriodByID(this.route.snapshot?.params['periodID']);
        }

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    getPeriodByID(id: string): void {
        this.loading = true;
        forkJoin(
            [
                this.periodService.doc(id).get(),
                this.tenantsService.collection(query => query.where('isCurrent', '==', true)).get(),
                this.bookingsService.collection(query => query.where('status', 'in', [BookingStatusOptions.NOT_PAID, BookingStatusOptions.PARTIALLY_PAID]).where('saldo', "<", 0)).get()
            ]
        )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(([period, tenants, bookings]) => {
                if (period?.exists) {
                    // period logic
                    this.period = period?.data() as PropertyUnitPeriod;
                    this.tenantsService.parentPath = `properties/${this.property?.id}/units/${this.unit?.id}/periods/${this.period?.id}`;

                    // this.periodFormGroup.get('rental.terminationDate').patchValue(new Date());
                    this._changeDetectorRef.markForCheck();
                    console.log('period received from firestore', this.period);

                    // listeners
                    this.listenOnTenantsNameListChanges();
                    this.listenOnTerminationConfirmationTypeChanges();
                    this.listenOnContentChanges();
                    this.listenOnDunningFormGroupChanges();
                    this.dunningFormGroup.valueChanges.subscribe(v => {
                        // console.log('Mahnung', v, this.emailsControls);
                        console.log('dunning form group changed', v);
                        console.log('dunning form group error', findInvalidControls(this.dunningFormGroup));
                        this.confirmationFormGroup.get('html').patchValue(this.getHtmlTemplate())
                    });

                    // tenants logic
                    if (tenants?.size > 0) {
                        const t = tenants?.docs.map(d => convertTimestamps(d.data()) as Tenant);
                        this.tenants = t;
                        console.log('received tenants', t);

                        const names = t.map(v => v?.contactData?.lastName);
                        if (names?.length > 0) {
                            names.forEach(name => this.addName(null, name));
                        }

                        const emails = t.filter(t => !!t?.contactData?.email).map(v => v?.contactData?.email);
                        if (emails?.length > 0) {
                            this.emailFormControl.patchValue(emails);
                            // emails.forEach(email => this.addEmail(null, email));
                        } else {
                            this.confirmationFormGroup.get('byEmail')?.patchValue(false);
                            console.log("patching byEmail to false")
                        }
                    }

                    if (bookings?.size > 0) {
                        this.unpaidBookings = bookings?.docs?.map(d => d.data() as Booking);
                    } else {
                        this.unpaidBookings = [];
                    }
                    this.selectedBookingsFC = new FormControl(this.unpaidBookings, [minOneBookingValidator]);
                    this.listenOnSelectedBookingsChanges();
                    console.log("unpaid bookings => ", this.unpaidBookings)
                } else {
                    // show error
                }
                this.loading = false;
            });
    }

    createLetterFormGroup(): UntypedFormGroup {
        const terminationDate = this.today;
        const endDate = moment(this.today).add(3, 'month').endOf('months').toDate();
        return this._formBuilder.group({
            id: this.lettersService.createID(),
            createdAt: new Date(),
            // status: LetterStatusOptions.DRAFT,
            unitID: null,
            property: this._formBuilder.group({
                id: null,
                h_id: null,
                h_id_text: null,
                address: this._formBuilder.group({
                    streetName: null,
                    streetNumber: null,
                    postalCode: null,
                    displayAddress: null,
                    locality: this._formBuilder.group({
                        short: null,
                        long: null
                    }),
                    country: this._formBuilder.group({
                        short: null,
                        long: null
                    }),
                    geoLocation: this._formBuilder.group({
                        latitude: null,
                        longitude: null
                    })
                })
            }),

        });
    }

    createDunningFormGroup(): UntypedFormGroup {
        console.log("on createDunningFormGroup", this.nextStage)
        let dueDate = moment(this.today).add(7, 'days').toDate();

        return this._formBuilder.group({
            id: this.periodService.createID(),
            refID: generateUniqueId(this.dunningsModularService.authService.customerID, this.period?.id),
            customerID: this.dunningsModularService.authService.customerID,
            propertyID: this.property?.id,
            unitID: this.unit?.id,
            periodID: this.period?.id,
            createdAt: this.today,
            relatedBookings: this._formBuilder.control(null),
            address: this._formBuilder.control(this.unit?.address),
            h_id: this._formBuilder.control(this.property?.h_id),
            w_id: this._formBuilder.control(this.unit?.w_id),
            dueDate: this._formBuilder.control(dueDate, Validators.required),
            amountToPay: this._formBuilder.control(null, [Validators.required, Validators.min(0.01)]),
            type: this._formBuilder.control(this.nextStage || DunningTypeOptions.PAYMENT_REMINDER, Validators.required),
            enableLateFees: this._formBuilder.control(false),
            enablePaymentDelayInterest: this._formBuilder.control(false),
            interestRate: this._formBuilder.control(this.period?.costs?.interestRateDueLatePayment && this.period?.costs?.interestRateDueLatePayment > 0 ? this.period?.costs?.interestRateDueLatePayment * 100 : null, [Validators.required, Validators.min(0.01), Validators.max(15)]),
            bookingsWithInterest: this._formBuilder.control(null),
            interestToPay: this._formBuilder.control(null),
            feesToPay: this._formBuilder.control(this.period?.costs?.dunningFees),
            amountToPayInclFeesAndInterest: this._formBuilder.control(null),
            lastWarningType: this._formBuilder.control(null),
            status: BookingStatusOptions.NOT_PAID,
        });
    }

    showCopyField(name: string): void {
        // Return if the name is not one of the available names
        if (name !== 'cc' && name !== 'bcc') {
            return;
        }

        // Show the field
        this.copyFields[name] = true;
    }

    hideCopyField(name: string): void {
        // Return if the name is not one of the available names
        if (name !== 'cc' && name !== 'bcc') {
            return;
        }

        // Show the field
        this.copyFields[name] = false;
    }


    listenOnTenantsNameListChanges(): void {
        this.confirmationFormGroup
            .get('tenantsNameList')
            .valueChanges
            .pipe(takeUntil(this._unsubscribeAll), debounceTime(250))
            .subscribe(async (namesList: string[]) => {
                console.log('listenOnTenantsNameListChanges', namesList.join(', '));
                this.confirmationFormGroup.get('tenantsName')?.patchValue(namesList.join(', '));
                await this.renderPreviewPDF();
            });
    }

    addName(event: MatChipInputEvent, name?: string): void {
        if (name) {
            this.namesControls.push(this._formBuilder.control(name));
            return;
        }

        const value = (event?.value || '').trim();

        // Add our fruit
        if (value) {
            // this.fruits.push({ name: value });
            this.namesControls.push(this._formBuilder.control(value));

        }

        // Clear the input value
        event?.chipInput?.clear();
    }

    removeName(name: string): void {
        const index = this.namesControls.value.indexOf(name);
        if (index >= 0) {
            this.namesControls.removeAt(index);
        }
    }

    onQuillContentChanged($event: ContentChange): void {
        console.log('onQuillContentChanged', $event);
        // console.log('onQuillContentChanged', this.quill.quillEditor.getText());
        // console.log('onQuillContentChanged text', $event.text);
    }

    confirmTerminationViaEmail(): void {
        // const dunning = this.dunningFormGroup.getRawValue() as Dunning;
        const confirmation = this.confirmationFormGroup.getRawValue() as TerminationConfirmation;
        console.log('this.quill.quillEditor.getText()', this.quill.quillEditor.getText());
        this.ngxMailtoService.open(
            {
                receiver: confirmation?.to,
                cc: confirmation?.cc,
                bcc: confirmation?.bcc,
                subject: confirmation?.subject,
                body: this.quill.quillEditor.getText()
            });
    }

    checkFormInvalidReason(): void {
        if (this.dunningFormGroup?.invalid) {
            if (this.namesControls?.invalid) {
                console.error('Bitte Mieter Name eingeben!');
                this.snackbar.open('Bitte Mieter Name eingeben!', 'OK', {duration: 5000});
                return;
            }
            if (this.emailsControls?.invalid) {
                console.error('Bitte Mieter emails eingeben!');
                this.snackbar.open('Bitte Mieter emails eingeben!', 'OK', {duration: 5000});
            }
        }
    }

    listenOnTerminationConfirmationTypeChanges(): void {
        this.confirmationFormGroup.get('byPost')
            ?.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value: boolean) => {
                !!value ? this.namesControls.setValidators([Validators.required]) : this.namesControls.clearValidators();
                this.namesControls?.updateValueAndValidity();
            });

        this.confirmationFormGroup.get('byEmail')
            ?.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value: boolean) => {
                !!value ? this.emailsControls.setValidators([requiredArrayValidator(), emailArrayValidator()]) : this.emailsControls.clearValidators();
                this.emailsControls?.updateValueAndValidity();
            });
    }

    pageRendered(e: CustomEvent): void {
        console.log('(page-rendered)', e);
        this.renderingPDF = false;
        this._changeDetectorRef.markForCheck();
    }

    async generatePdf(): Promise<any> {
        this.loadingPDF = false;
        this._changeDetectorRef.markForCheck();

        try {
            await this.pdf.open(this.getPDFTemplate());
            this.loadingPDF = false;
        } catch (err) {
            console.error('Error', err);
            this.snackbar.open('Es gab ein Problem bei der Erstellung des PDFs.', 'OK', {duration: 5000});
            this.loadingPDF = false;
        }
        this._changeDetectorRef.markForCheck();
    }

    listenOnContentChanges(): void {
        this.confirmationFormGroup
            .get('html')
            .valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(1000),
                distinctUntilChanged(),
                map(value => htmlToPdfmake(value)),
            )
            .subscribe(async value => await this.renderPreviewPDF());

        this.confirmationFormGroup
            .get('date')
            .valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(1000),
                distinctUntilChanged()
                // tap((value) => console.log('html', value))
            )
            .subscribe(async value => await this.renderPreviewPDF());

        this.confirmationFormGroup
            .get('subject')
            .valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(1000),
                distinctUntilChanged(),
                tap(value => console.log('html', value))
            )
            .subscribe(async value => await this.renderPreviewPDF());

        this.confirmationFormGroup
            .get('signatureRequired')
            .valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(200),
                distinctUntilChanged(),
            )
            .subscribe(async (signatureRequired: boolean) => {
                console.log("on configuration changed --> signatureRequired = ", signatureRequired);
                this.signatureRequired = signatureRequired;
                await this.renderPreviewPDF();
            });

        this.confirmationFormGroup
            .get('settings')
            .valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(200),
                distinctUntilChanged(),
            )
            .subscribe(async (settings: settings) => {
                console.log("on configuration changed --> settings = ", settings);
                this.settings = settings;
                await this.renderPreviewPDF();
            });

        this.confirmationFormGroup
            .get('hasLogo')
            .valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(200),
                distinctUntilChanged(),
            )
            .subscribe(async (hasLogo: boolean) => {
                console.log("on configuration changed --> hasLogo = ", hasLogo);
                this.hasLogo = hasLogo;
                await this.renderPreviewPDF();
            });
    }

    async renderPreviewPDF(): Promise<any> {
        console.log('renderPreviewPDF is running');
        this.renderingPDF = true;
        this._changeDetectorRef.markForCheck();

        const pdfDocGenerator = await this.pdf.create(this.getPDFTemplate());
        pdfDocGenerator.getBuffer((blob) => {
            this.pdfSrc = blob;
            this._changeDetectorRef.markForCheck();
        });
    }

    convertConfirmationToLetter(confirmation: TerminationConfirmation, local: boolean = true): Letter {
        const letter: Letter = {
            id: local ? null : this.lettersService.createID(),
            createdAt: new Date(),
            property: this.property,
            unitID: this.unit?.id,
            matter: {
                title: confirmation?.subject
            },
            content: {
                subject: confirmation?.subject,
                html: confirmation?.html
            },
            settings: {
                logo: this.settings?.logo ?? true,
                footer: this.settings?.footer ?? true,
                bankAccount: this.settings?.bankAccount ?? true,
            },
            hasLogo: this.hasLogo ?? true,
            signatureRequired: this.signatureRequired ?? true
        };
        return letter;
    }

    uploadFile(filePath: string, buffer: any, dunning: Dunning, letter: Letter): any {
        const metadata = {
            contentType: 'application/pdf'
        };
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, buffer, metadata);
        // observe percentage changes
        this.uploadPercent = task.percentageChanges();
        // get notified when the download URL is available
        task.snapshotChanges().pipe(
            finalize(() => {
                // this.downloadURL = fileRef.getDownloadURL()
                fileRef.getDownloadURL().pipe(takeUntil(this._unsubscribeAll)).subscribe((url) => {
                    this.pdfUploaded = true;
                    this._changeDetectorRef.markForCheck();
                    console.log('download URL --> ', url);
                    // 4. save obj in firestore
                    this.submitDunning(filePath, url, dunning, letter)
                        .then(() => {
                            console.log('success --> saved Kündigungsbestätigung. in firestore');
                            return;
                        })
                        .catch((err) => {
                            console.error('Error: ', err);
                            this.snackbar.open('Ein Fehler ist aufgetreten: ' + err, 'OK', {duration: 5000});
                        })
                        .finally(() => {
                            this.creating = false;
                        });
                });
            })
        )
            .subscribe((value) => {
                console.log('uploading', value.state);
            });
    }

    async submitDunning(path: string, downloadURL: string, dunning: Dunning, letter: Letter): Promise<any> {
        this.isError = false;
        const confirmation: TerminationConfirmation = this.confirmationFG.getRawValue() as TerminationConfirmation;

        // 1. set parent path
        this.dunningsModularService.setParentPathForPeriod(this.property?.id, this.unit?.id, this.period?.id);
        this.dunningsActivitiesService.setParentPathForPeriod(this.property?.id, this.unit?.id, this.period?.id, dunning?.id);

        // 2. add dunning and letter doc
        set(letter, 'filePath', path);
        set(letter, 'url', downloadURL);

        set(dunning, 'filePath', path);
        set(dunning, 'url', downloadURL);
        set(dunning, 'date', confirmation.date);
        set(dunning, 'tenantsName', confirmation.tenantsName);
        set(dunning, 'tenantsNameList', confirmation.tenantsNameList);

        const firestoreOps: Promise<any>[] = [];

        // 3. create first activity
        const actionDescription = dunning?.type === DunningTypeOptions.PAYMENT_REMINDER ? 'Zahlungserinnerung' : 'Mahnung'
        const user = this.dunningsActivitiesService?.authService?.authUser.displayName || this.dunningsActivitiesService?.authService?.firestoreUser.displayName || this.dunningsActivitiesService?.authService?.authUser.email;
        const activity: Activity = {
            user,
            date: new Date(),
            icon: 'mat_outline:add',
            description: '<strong>' + user + '</strong> hat die ' + actionDescription + ' erstellt',
        };

        const rootActivity: Activity = this.createActivityOnCreateDunning(dunning, downloadURL);

        firestoreOps.push(this.dunningsActivitiesService.collection().add(activity));
        firestoreOps.push(this.activitiesService.doc(rootActivity.id).set(rootActivity, {merge: true}));

        // 4. check if enableLateFees
        if (dunning?.enableLateFees) {
            const booking: Booking = {
                id: this.bookingsService.createID(),
                art: BookingArtOptions.DUNNING_FEES,
                type: BookingTypeOptions.PAYMENT,
                propertyID: this.property?.id,
                unitID: this.unit?.id,
                periodID: this.period?.id,
                customerID: this.bookingsService.authService.customerID,
                amount: dunning?.feesToPay,
                saldo: dunning?.feesToPay * -1,
                date: dunning?.dueDate,
                h_id: this.property?.h_id,
                w_id: this.unit?.w_id,
                text: "Mahngebühren",
                tenantsName: this.period?.tenantsName,
                unitLabel: this.unit?.label,
                address: this?.unit?.address || this.property?.address,
            }
            dunning.relatedBookings.push(booking);
            firestoreOps.push(this.bookingsService.doc(booking?.id).set(booking, {merge: true}));

        }

        // 5. check if enablePaymentDelayInterest
        if (dunning?.enablePaymentDelayInterest) {
            const booking: Booking = {
                id: this.bookingsService.createID(),
                art: BookingArtOptions.INTEREST_EXPENSES,
                type: BookingTypeOptions.PAYMENT,
                propertyID: this.property?.id,
                unitID: this.unit?.id,
                periodID: this.period?.id,
                customerID: this.bookingsService.authService.customerID,
                amount: dunning?.interestToPay,
                saldo: dunning?.interestToPay * -1,
                date: dunning?.dueDate,
                h_id: this.property?.h_id,
                w_id: this.unit?.w_id,
                text: "Zinsaufwendungen",
                tenantsName: this.period?.tenantsName,
                unitLabel: this.unit?.label,
                address: this?.unit?.address || this.property?.address,
            }
            dunning.relatedBookings.push(booking);
            firestoreOps.push(this.bookingsService.doc(booking?.id).set(booking, {merge: true}));
        }

        firestoreOps.push(setDoc(this.dunningsModularService.doc(dunning.id), dunning, {merge: true}));

        // save all
        Promise.all(firestoreOps)
            .then(async () => {
                this.snackbar.open('Mahnung erfolgreich erstellt', 'Schließen', {
                    duration: 5000
                });
                this.dunningCreated = true;


                if (confirmation?.byPost) {
                    setTimeout(async () => await window.open(downloadURL, '_blank'), 3000);
                }

                if (confirmation?.byEmail) {
                    setTimeout(() => this.confirmTerminationViaEmail(), 3000);
                    this.emailGenerated = true;
                }
                // immobilien/HltM6Glnwj8gCJp2BczW/einheit/ilBYv6mArQmh8LQ591VG/kuendigung
                // await this.router.navigate(['immobilien', this.property?.id, 'einheit', this.unit?.id, 'kuendigung', this.period?.id]);
                await this.router.navigate(['../'], {relativeTo: this.route});
                this.creating = false;
                // this.matDialogRef.close();
            })
            .catch((err) => {
                console.error('Error:', err);
                this.snackbar.open('Fehler: Mahnung konnte nicht erstellt werden', 'Schließen', {
                    duration: 5000
                });
                this.isError = true;
                this.creating = false;
                // this.vacancyForm.enable();
                this.dunningFormGroup.enable();
            });
    }

    async save(): Promise<any> {
        console.log('this.quill.quillEditor.getText()', this.quill.quillEditor.getText());
        // return;
        this.creating = true;
        // 1. get object
        const dunning: Dunning = removeEmpty(this.dunningFormGroup.getRawValue()) as Dunning;
        const termination: TerminationConfirmation = removeEmpty(this.confirmationFormGroup.getRawValue()) as TerminationConfirmation;
        const letter = this.convertConfirmationToLetter(termination, false);
        set(termination, 'letterID', letter.id);
        const result = htmlToPdfmake(letter?.content?.html) as any[];
        if (result?.length > 0) {
            result?.forEach(x => delete x['margin']);
        }

        // 2. generate and upload pdf
        const path = this.dunningsModularService.authService.buildPath(`properties/${this.property?.id}/units/${this.unit?.id}/periods/${this.period?.id}/mahnung/${letter?.id}`);
        const fileName = `${getDateStringForFileName(letter?.createdAt as Date)} Mahnung.pdf`;
        const filePath = `${path}/${fileName}`;

        try {
            const pdfDocGenerator = await this.pdf.create(this.getPDFTemplate());
            pdfDocGenerator.getBuffer((buffer) => {
                this.pdfCreated = true;
                this.pdfSrc = buffer;
                // 3. save path and downloadURL in obj and run the advanced logic
                this.snackbar.open('Mahnung erfolgreich erstellt', 'Schließen', {
                    duration: 3000,
                    panelClass: ['snackbar-warn']
                });
                // this.router.navigate(['../'], {relativeTo: this.route});
                // return;
                this.uploadFile(filePath, buffer, dunning, letter);
            });
        } catch (err) {
            console.error('Error', err);
        }
    }

    getSubject(): string {
        const dunningType = this.dunningFormGroup.get('type')?.value as DunningType;
        switch (dunningType) {
            case DunningTypeOptions.PAYMENT_REMINDER:
                return `Zahlungserinnerung \n H${this.unit?.h_id}/W${this.unit?.w_id} ${formatAddress(this.unit?.address)} | ${this.unit?.label}`;
            // return 'Zahlungserinnerung';
            case DunningTypeOptions.FIRST_REMINDER:
                // return 'Erste Mahnung';
                return `Erste Mahnung für ausstehende Zahlungen \n H${this.unit?.h_id}/W${this.unit?.w_id} ${formatAddress(this.unit?.address)} | ${this.unit?.label}`;
            case DunningTypeOptions.SECOND_REMINDER:
                return `Zweite Mahnung für ausstehende Zahlungen \n H${this.unit?.h_id}/W${this.unit?.w_id} ${formatAddress(this.unit?.address)} | ${this.unit?.label}`;
            case DunningTypeOptions.FINAL_REMINDER:
                const title = this.getTitleStartTextForFinalReminder();
                return `${title} \n H${this.unit?.h_id}/W${this.unit?.w_id} ${formatAddress(this.unit?.address)} | ${this.unit?.label}`;
        }
    }

    getTitleStartTextForFinalReminder(): string {
        const lastWarningType = this.dunningFormGroup.get('lastWarningType')?.value as LastWarningType;
        switch (lastWarningType) {
            case LastWarningTypeOptions.FINAL_WARNING_BEFORE_LEGAL_ACTION:
                return 'Letzte Mahnung vor Mahnbescheid – Ausstehende Zahlung';
            case LastWarningTypeOptions.FINAL_WARNING_BEFORE_TERMINATION:
                return 'Letzte Mahnung vor Fristloskündigung – Ausstehende Zahlung';
            default:
                return 'Letzte Mahnung – Ausstehende Zahlung'
        }
    }

    getPDFTemplate(): any {
        const dunning = this.dunningFormGroup.getRawValue() as Dunning;
        const confirmation = this.confirmationFormGroup.getRawValue() as TerminationConfirmation;

        const letter = this.convertConfirmationToLetter(confirmation);
        const result = letter?.content?.html ? htmlToPdfmake(letter?.content?.html) as any[] : '';
        if (result instanceof Object && result?.length > 0) {
            result?.forEach(x => delete x['margin']);
        }
        console.log('on rendering pdf --> result', result);
        const data: StandardDunningData = {
            content: result || '',
            dunning,
            letter,
            date: this.datePipe.transform(confirmation?.date as Date, 'shortDate'),
            unit: this.unit,
            tenantsName: this.confirmationFormGroup.get('tenantsName')?.value as string,
            signatureRequired: this.signatureRequired,
            settings: this.settings,
            publicProfile: this.publicProfile,
            isActiveUser: this.activeUser,
        }
        return STANDARD_DUNNING(data);
    }

    getHtmlTemplate(): any {
        const dunning = this.dunningFormGroup.getRawValue() as Dunning;
        const params: PaymentReminderTemplateParams = {
            publicProfile: this.publicProfile,
            dunning,
            currencyPipe: this.currencyPipe,
            datePipe: this.datePipe,
            property: this.property,
            unit: this.unit
        }
        console.log("PaymentReminderTemplateParams --> ", params)

        switch (dunning.type) {
            case DunningTypeOptions.PAYMENT_REMINDER:
                return PAYMENT_REMINDER_TEMPLATE(params);
            case DunningTypeOptions.FIRST_REMINDER:
                return FIRST_REMINDER_TEMPLATE(params);
            case DunningTypeOptions.SECOND_REMINDER:
                return SECOND_REMINDER_TEMPLATE(params);
            case DunningTypeOptions.FINAL_REMINDER:
                return FINAL_REMINDER_TEMPLATE(params);
        }

        return PAYMENT_REMINDER_TEMPLATE(params);

    }

    getEmailForPDF(): void {
        if (environment.pool) {
            this.email = this.dunningsModularService.authService?.authUser?.email || this.dunningsModularService.authService?.firestoreUser?.email || 'BITTE E-MAIL EINGEBEN';
        } else {
            if (environment.customer === 'jug') {
                this.email = 'vermietung@jug-verwaltung.de';
            } else {
                this.email = 'BITTE ÜBERPRÜFEN!';
            }
        }
    }

    getDataForPDF(): void {
        this.getEmailForPDF();
    }

    onDunningTypeSelectionChanged($event: MatSelectionListChange): void {
        const typeSelected: DunningType = $event.options[0].value as DunningType;
        console.log('onDunningTypeSelectionChanged', typeSelected);
        this.dunningFormGroup.get('type').patchValue(typeSelected);
        if (typeSelected === DunningTypeOptions.FINAL_REMINDER) {
            this.openDialogToSelectLastReminderType();
        }
    }

    listenOnSelectedBookingsChanges(): void {
        this.selectedBookingsFC
            .valueChanges
            .pipe(takeUntil(this._unsubscribeAll), startWith(this.selectedBookingsFC?.value))
            .subscribe((bookings: Bookings) => {
                console.log("listenOnSelectedBookingsChanges --> ", bookings);
                this.dunningFormGroup.get('relatedBookings')?.patchValue(bookings);
                this.totalSaldoToPay = this.calculateTotalSaldo(bookings);
                this.dunningFormGroup.get('amountToPay')?.patchValue(Math.abs(this.totalSaldoToPay));
            })
    }

    listenOnDunningFormGroupChanges(): void {
        const bookingsWithInterestFC = this.dunningFormGroup.get('bookingsWithInterest');
        const dunningTypeFC = this.dunningFormGroup.get('type');
        const enablePaymentDelayInterestFC = this.dunningFormGroup.get('enablePaymentDelayInterest');
        const enableLateFeesFC = this.dunningFormGroup.get('enableLateFees');
        const interestRateFC = this.dunningFormGroup.get('interestRate');
        const interestToPayFC = this.dunningFormGroup.get('interestToPay');
        const feesToPayFC = this.dunningFormGroup.get('feesToPay');
        const amountToPayFC = this.dunningFormGroup.get('amountToPay');
        const amountToPayInclFeesAndInterestFC = this.dunningFormGroup.get('amountToPayInclFeesAndInterest');

        dunningTypeFC.valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((dunningType: DunningType) => {
                this.confirmationFormGroup.get('subject').patchValue(this.getSubject());
            });

        combineLatest([enablePaymentDelayInterestFC?.valueChanges
            .pipe(startWith(enablePaymentDelayInterestFC?.value), takeUntil(this._unsubscribeAll)),
            interestRateFC?.valueChanges
                .pipe(startWith(interestRateFC?.value), takeUntil(this._unsubscribeAll), debounceTime(400))])
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(([enablePaymentDelayInterest, interestRate]: [boolean, number]) => {

                if (!enablePaymentDelayInterest) {
                    interestToPayFC.patchValue(0);
                    bookingsWithInterestFC.patchValue(0);
                    return;
                }

                if (enablePaymentDelayInterest && interestRate > 0) {
                    const bookingsWithInterest = calculateInterest(this.selectedBookingsFC?.value as Bookings, interestRate);
                    console.log("bookingsWithInterest --> ", bookingsWithInterest)
                    const totalInterest = bookingsWithInterest.reduce((acc, cur) => acc + cur.interestAmount, 0);
                    interestToPayFC.patchValue(totalInterest);
                    bookingsWithInterestFC.patchValue(bookingsWithInterest);
                }
            })

        combineLatest([
            amountToPayFC?.valueChanges.pipe(startWith(amountToPayFC?.value), takeUntil(this._unsubscribeAll)),
            feesToPayFC?.valueChanges.pipe(startWith(interestRateFC?.value), takeUntil(this._unsubscribeAll), debounceTime(400)),
            interestToPayFC?.valueChanges.pipe(startWith(interestToPayFC?.value), takeUntil(this._unsubscribeAll)),
        ]).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(([amountToPay, feesToPay, interestToPay]: [number, number, number]) => {
                const interestToPayAdjusted = roundIfMoreThanTwoDecimals(interestToPay || 0);
                if (interestToPayAdjusted !== 0) {
                    interestToPayFC.patchValue(interestToPayAdjusted, {
                        emitEvent: false,
                        onlySelf: true
                    });
                }
                const totalAmount = roundIfMoreThanTwoDecimals(amountToPay + feesToPay + interestToPayAdjusted);
                // Hier können Sie `totalAmount` weiterverwenden
                amountToPayInclFeesAndInterestFC.patchValue(totalAmount);
            })

        enablePaymentDelayInterestFC?.valueChanges
            .pipe(startWith(enablePaymentDelayInterestFC?.value), takeUntil(this._unsubscribeAll))
            .subscribe((enablePaymentDelayInterest: boolean) => {
                this.enableInterestRate(enablePaymentDelayInterest);

                if (!enablePaymentDelayInterest) {
                    interestRateFC.patchValue(null);
                }
            })

        enableLateFeesFC?.valueChanges
            .pipe(startWith(enableLateFeesFC?.value), takeUntil(this._unsubscribeAll))
            .subscribe((enableLateFeesFC: boolean) => {
                this.enableFeesToPayFC(enableLateFeesFC);

                if (!enableLateFeesFC) {
                    feesToPayFC.patchValue(null);
                }
            })
    }

    enableInterestRate(enable: boolean): void {
        const interestRateFC = this.dunningFormGroup.get('interestRate');
        if (enable) {
            if (this.period?.costs?.interestRateDueLatePayment) {
                interestRateFC.patchValue(this.period?.costs?.interestRateDueLatePayment * 100);
            }
            interestRateFC.setValidators([Validators.required, Validators.min(0.01), Validators.max(15)]);
            interestRateFC.updateValueAndValidity();
            this.dunningFormGroup.updateValueAndValidity();
        } else {
            interestRateFC.clearValidators();
            interestRateFC.updateValueAndValidity();
            this.dunningFormGroup.updateValueAndValidity();
        }
    }

    enableFeesToPayFC(enable: boolean): void {
        const feesToPayFC = this.dunningFormGroup.get('feesToPay');
        if (enable) {
            if (this.period?.costs?.dunningFees) {
                feesToPayFC.patchValue(this.period?.costs?.dunningFees);
            }
            feesToPayFC.setValidators([Validators.required, Validators.min(0.01), Validators.max(10)]);
            feesToPayFC.updateValueAndValidity();
            this.dunningFormGroup.updateValueAndValidity();
        } else {
            feesToPayFC.clearValidators();
            feesToPayFC.updateValueAndValidity();
            this.dunningFormGroup.updateValueAndValidity();
        }
    }


    calculateTotalSaldo(bookings: Bookings): number {
        return bookings.reduce((total, booking) => {
            // Addieren Sie den Saldo jeder Buchung zum Gesamtsaldo
            return total + (booking.saldo || 0);
        }, 0);
    }

    goToStep2() {
        if (this.selectedBookingsFC?.hasError('minOneBooking')) {
            const msg = 'Um fortzufahren, wählen Sie bitte mindestens eine offene Posten Sollstellung aus.';
            this.snackbar.open(msg, "OK", {duration: 5000});
        }
    }

    openDialogToSelectLastReminderType(): void {
        this.dialog.open(SelectDunningLastReminderTypeDialogComponent, {
            panelClass: 'fuse-confirmation-dialog-panel',
            autoFocus: false,
            disableClose: true,
            maxHeight: '90vh',
        }).afterClosed().pipe(take(1)).subscribe((lastWarningType: LastWarningType) => {
            console.log("lastWarningType received from dialog --> ", lastWarningType);
            this.dunningFormGroup.get('lastWarningType').patchValue(lastWarningType);
        })
    }

    updateSettings(settingName: string, event: MatSlideToggleChange) {
        const settings = this.confirmationFormGroup.get('settings');
        settings.get(settingName).patchValue(event.checked);
        this.settings = this.confirmationFormGroup.get('settings').value;
        console.log("settingName", settingName);
        console.log("event.checked", event.checked);
    }

    updateSignature(event: MatSlideToggleChange) {
        const signature = this.confirmationFormGroup.get('signatureRequired');
        signature.patchValue(event.checked);
        this.signatureRequired = signature.value;
        console.log("event.checked", event.checked);
        console.log('form settings ', signature.value)
        console.log('form settings ', this.signatureRequired)
    }

    createActivityOnCreateDunning(dunning: Dunning, pdfURL: string): Activity {
        const now = new Date();
        const user = this.activitiesService.authService.authUser.displayName || this.activitiesService.authService.firestoreUser.displayName || this.activitiesService.authService.authUser.email;
        const unitLabel = this.unit?.label;
        const tenantsName = this.period?.tenantsName;
        const actionDescription = dunning?.type === DunningTypeOptions.PAYMENT_REMINDER ? 'Zahlungserinnerung' : 'Mahnung'

        const activity: Activity = {
            id: this.activitiesService.createID(),
            createdAt: now,
            date: now,
            user,
            uid: this.activitiesService.authService.authUser?.uid,
            icon: 'mat_outline:edit',
            address: formatAddress(this.unit?.address),
            customerID: this.activitiesService.authService.customerID,
            propertyID: this.property?.id,
            unitID: this.unit?.id,
            periodID: this.period?.id,
            h_id: this.property?.h_id,
            w_id: this.unit?.w_id,
            unitLabel,
            tenantsName,
            featureType: ActivityFeatureOption.DUNNING,
            managementID: null,
            useRouter: true,
            linkedContent: 'Mehr erfahren',
            link: `/immobilien/${this.property?.id}/einheit/${this.unit?.id}/buchungen/${this.period?.id}/mahnwesen/${dunning?.id}`,
            pdfURL,
            description: `${user} hat eine <span class="font-extrabold">${actionDescription} - ${dunning?.refID}</span> erstellt für ${unitLabel} - ${tenantsName} (OP ${formatCurrency(dunning?.amountToPayInclFeesAndInterest, 'de-DE', "€", 'EUR')})`
        };
        return activity;
    }


}

