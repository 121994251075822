import {Timestamp} from "firebase/firestore";

/**
 * @author Anthony Nahas
 * @since 07.02.24
 * @version 1.0
 */
export interface NahausBookingsAccount {
    id?: string;
    customerID?: string;
    createdAt?: Timestamp | Date;
    updatedAt?: Timestamp | Date;
    refID?: string; // Kontierung: SK2912
    label: string; // max length 255
    accountType?: NahausBookingsAccountType;
    taxRelevant: boolean; // Steuerrelevant
    depreciationRelevant: boolean; // Abschreibungsrelevant
    isAllocatable: boolean; // Gibt an, ob die Ausgabe umlegbar ist - Umlagefähig
    extraInfo: string; // Für Tooltip oder zusätzliche Erklärungen
}

/**
 * @author Anthony Nahas
 * @since 08.02.24
 * @version 1.0
 */
export enum NahausBookingAccountTypeOptions {
    // INCOME = "INCOME",
    EXPENSE = "EXPENSE",
    INVESTMENT = "INVESTMENT",
    SAVINGS = "SAVINGS",
    LOANS = "LOANS",
    OTHER = "OTHER"
}

/**
 * @author Anthony Nahas
 * @since 08.02.24
 * @version 1.0
 */
export type NahausBookingsAccountType =
// NahausBookingAccountTypeOptions.INCOME
    | NahausBookingAccountTypeOptions.EXPENSE
    | NahausBookingAccountTypeOptions.INVESTMENT
    | NahausBookingAccountTypeOptions.SAVINGS
    | NahausBookingAccountTypeOptions.LOANS
    | NahausBookingAccountTypeOptions.OTHER;

export const nahausBookingsAccounts: NahausBookingsAccount[] = [
    // {
    //     id: "1",
    //     refID: "8105", // checked 23.02.24 --> oder refID 8000
    //     label: "Mieteinnahmen Wohnungen", // nur kalt miete von wohnraum
    //     // accountType: NahausBookingAccountTypeOptions.INCOME,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Einnahmen aus der Vermietung von Wohnimmobilien. Steuerfreie Umsätze nach §4Nr.12UStG (Vermietung und Verpachtung)"
    // },
    // {
    //     id: "2",
    //     refID: "8010",
    //     label: "Mieteinnahmen gewerbliche Räume",
    //     // accountType: NahausBookingAccountTypeOptions.INCOME,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Einnahmen aus der Vermietung von Gewerbeimmobilien."
    // },
    // {
    //     id: "3",
    //     refID: "6020",
    //     label: "Heizkosten",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: false,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Kosten für die Beheizung von Mietobjekten."
    // },
    {
        id: "4",
        refID: "4241", // checked 24.02.24
        label: "Wasserkosten",
        accountType: NahausBookingAccountTypeOptions.EXPENSE,
        taxRelevant: false,
        depreciationRelevant: false,
        isAllocatable: true,
        extraInfo: "Kosten für Wasserverbrauch in Mietobjekten."
    },
    // {
    //     id: "5",
    //     refID: "6120",
    //     label: "Instandhaltung Gebäude",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: true,
    //     isAllocatable: false,
    //     extraInfo: "Kosten für die Instandhaltung und Reparatur von Gebäuden."
    // },
    {
        id: "6",
        refID: "2375", // checked 23.02.24
        label: "Grundsteuer",
        accountType: NahausBookingAccountTypeOptions.EXPENSE,
        taxRelevant: true,
        depreciationRelevant: false,
        isAllocatable: true,
        extraInfo: "Jährliche Grundsteuer für Immobilien."
    },
    {
        id: "7",
        refID: "4366", // checked 23.02.24
        label: "Versicherungen für Gebäude",
        accountType: NahausBookingAccountTypeOptions.EXPENSE,
        taxRelevant: true,
        depreciationRelevant: false,
        isAllocatable: true,
        extraInfo: "Versicherungskosten für Gebäude"
    },
    {
        id: "8",
        refID: "4831", // checked 23.02.024
        label: "Abschreibungen auf Gebäude",
        accountType: NahausBookingAccountTypeOptions.EXPENSE,
        taxRelevant: true,
        depreciationRelevant: true,
        isAllocatable: false,
        extraInfo: "Abschreibungen auf den Gebäudewert über die Nutzungsdauer."
    },
    // {
    //     id: "9",
    //     refID: "6350",
    //     label: "Hausmeister",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Kosten für Hausmeisterdienste und allgemeine Instandhaltung."
    // },
    // {
    //     id: "10",
    //     refID: "4600", // checked 24.02.24
    //     label: "Werbekosten",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Kosten für Werbung und Marketing zur Vermietung oder Verkauf von Immobilien."
    // },
    {
        id: "11",
        refID: "4950", // checked 23.02.24
        label: "Beratungs- und Rechtskosten",
        accountType: NahausBookingAccountTypeOptions.EXPENSE,
        taxRelevant: true,
        depreciationRelevant: false,
        isAllocatable: false,
        extraInfo: "Kosten für rechtliche Beratung und sonstige Beratungsdienstleistungen."
    },
    // {
    //     id: "13",
    //     refID: "4420",
    //     label: "Erhaltungsaufwand",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Kosten für die Erhaltung und Verbesserung von Immobilien."
    // },
    // {
    //     id: "14",
    //     refID: "4840",
    //     label: "Verschiedene Verwaltungskosten",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Sonstige Verwaltungskosten, die nicht anderweitig klassifiziert sind."
    // },
    // {
    //     id: "16",
    //     refID: "3000",
    //     label: "Rücklagen für Instandhaltung",
    //     accountType: NahausBookingAccountTypeOptions.SAVINGS,
    //     taxRelevant: false,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Rücklagenbildung für zukünftige Instandhaltungsmaßnahmen."
    // },
    {
        id: "18",
        refID: "4250", // checked 24.02.24
        label: "Reinigungskosten",
        accountType: NahausBookingAccountTypeOptions.EXPENSE,
        taxRelevant: true,
        depreciationRelevant: false,
        isAllocatable: true,
        extraInfo: "Kosten für Reinigungsdienste."
    },
    {
        id: "19",
        refID: "4240", // checked 24.02.24
        label: "Stromkosten",
        accountType: NahausBookingAccountTypeOptions.EXPENSE,
        taxRelevant: false,
        depreciationRelevant: false,
        isAllocatable: true,
        extraInfo: "Kosten für Stromverbrauch in den Immobilien."
    },
    {
        id: "20",
        refID: "4243", // checked 24.02.24
        // label: "Müllabfuhr",
        label: "Abfallbeseitigung",
        accountType: NahausBookingAccountTypeOptions.EXPENSE,
        taxRelevant: false,
        depreciationRelevant: false,
        isAllocatable: true,
        extraInfo: "Kosten für Müllentsorgung."
    },
    // {
    //     id: "21",
    //     refID: "4400",
    //     label: "Verwaltungsgebühren",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Gebühren für die Verwaltung der Immobilien, inklusive Softwarelizenzen."
    // },
    // {
    //     id: "22",
    //     refID: "4410",
    //     label: "Berufsgenossenschaftsbeiträge",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Beiträge zur Berufsgenossenschaft für gewerbliche Vermietung."
    // },
    // {
    //     id: "23",
    //     refID: "7500",
    //     label: "Zuweisungen zu den Rücklagen",
    //     accountType: NahausBookingAccountTypeOptions.SAVINGS,
    //     taxRelevant: false,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Bildung von Rücklagen für größere Reparaturen oder Renovierungen."
    // },
    // {
    //     id: "24",
    //     refID: "4000",
    //     label: "Erlöse aus dem Verkauf von Anlagevermögen",
    //     accountType: NahausBookingAccountTypeOptions.INCOME,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Einnahmen aus dem Verkauf von Immobilien oder Teilen davon."
    // },
    // {
    //     id: "25",
    //     refID: "4700",
    //     label: "Maklergebühren",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Kosten für Immobilienmakler bei der Vermittlung von Mietverträgen oder Verkäufen."
    // },
    // {
    //     id: "26",
    //     refID: "4730",
    //     label: "Notarkosten",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Gebühren für notarielle Dienstleistungen im Zusammenhang mit Immobilientransaktionen."
    // },
    // {
    //     id: "27",
    //     refID: "4800",
    //     label: "Gebäudeversicherung",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Prämien für die Versicherung der Gebäude gegen Schäden."
    // },
    // {
    //     id: "28",
    //     refID: "4810",
    //     label: "Haftpflichtversicherung",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Versicherungskosten für Haftpflichtversicherungen."
    // },
    // {
    //     id: "29",
    //     refID: "4850",
    //     label: "Kontoführungsgebühren",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Gebühren für die Führung von Bankkonten."
    // },
    // {
    //     id: "30",
    //     refID: "4880",
    //     label: "Beratungskosten",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Kosten für externe Beratungsdienste, z.B. Steuerberatung."
    // },
    // {
    //     id: "31",
    //     refID: "3070",
    //     label: "Rückstellungen für Instandhaltung",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Gelder reserviert für erwartete Instandhaltungskosten, die innerhalb von drei Monaten nachgeholt wird."
    // },
    // {
    //     id: "32",
    //     refID: "4000",
    //     label: "Erlöse aus Dienstleistungen",
    //     accountType: NahausBookingAccountTypeOptions.INCOME,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Einnahmen aus zusätzlichen Dienstleistungen, die im Zusammenhang mit der Immobilienverwaltung stehen."
    // },
    // {
    //     id: "33",
    //     refID: "1770", // checked 13.02.24
    //     label: "Kautionen",
    //     accountType: NahausBookingAccountTypeOptions.OTHER,
    //     taxRelevant: false,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Sicherheitsleistungen oder Kautionen, die von Mietern hinterlegt werden."
    // },
    // {
    //     refID: "0540", // checked 13.02.24
    //     label: "Sonstige Ausleihungen",
    //     accountType: NahausBookingAccountTypeOptions.OTHER,
    //     taxRelevant: false,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Verwendung für die Erfassung von Sicherheitsleistungen oder Kautionen, die für Miet- oder Leihverhältnisse auf unbestimmte Zeit hinterlegt werden. Dient der Buchung von Kautionen, die als finanzielle Sicherheit in verschiedenen Kontexten dienen."
    // },
    // {
    //     id: "35",
    //     refID: "0480",
    //     label: "Geringwertige Wirtschaftsgüter (GWG)", // checked 12.02.24
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: true,
    //     isAllocatable: false,
    //     extraInfo: "Sofortabschreibung geringwertiger Wirtschaftsgüter, die für den Betrieb der Immobilie angeschafft werden."
    // },
    // Vorherige Konten...
    // {
    //     id: "36",
    //     refID: "2740",
    //     label: "Zuschüsse und Subventionen",
    //     accountType: NahausBookingAccountTypeOptions.OTHER,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Staatliche Zuschüsse und Subventionen für Sanierungen oder energieeffiziente Maßnahmen."
    // },
    // {
    //     id: "37",
    //     refID: "6300",
    //     label: "Gebühren für Müllentsorgung",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: false,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Gebühren für die Entsorgung von Müll und Abfallprodukten."
    // },
    // {
    //     id: "38",
    //     refID: "4400",
    //     label: "Grundbesitzabgaben",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Steuern und Abgaben, die direkt mit dem Besitz von Immobilien zusammenhängen."
    // },
    // {
    //     id: "39",
    //     refID: "2130",
    //     label: "Rücklagen für Großreparaturen",
    //     accountType: NahausBookingAccountTypeOptions.SAVINGS,
    //     taxRelevant: false,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Rücklagenbildung für zukünftige Großreparaturen oder Sanierungen."
    // },
    // {
    //     id: "40",
    //     refID: "5400",
    //     label: "Außerordentliche Einnahmen",
    //     accountType: NahausBookingAccountTypeOptions.OTHER,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Einnahmen, die außerhalb der regulären Geschäftstätigkeit erzielt werden."
    // },
    // {
    //     id: "41",
    //     refID: "6900",
    //     label: "Außerordentliche Aufwendungen",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Aufwendungen, die außerhalb der regulären Geschäftstätigkeit anfallen."
    // },
    // {
    //     id: "42",
    //     refID: "7815",
    //     label: "Einnahmen aus der Auflösung von Rückstellungen",
    //     accountType: NahausBookingAccountTypeOptions.INCOME,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Erträge, die durch die Auflösung zuvor gebildeter Rückstellungen entstehen."
    // },
    // Vorherige Konten...
    // {
    //     id: "43",
    //     refID: "4100",
    //     label: "Rücklagenzuführung für Instandhaltung (WEG)",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: false,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Jährliche Zuführungen zu den Instandhaltungsrücklagen gemäß Wirtschaftsplan der WEG."
    // },
    // {
    //     id: "44",
    //     refID: "4220",
    //     label: "Hausgeldzahlungen (WEG)",
    //     accountType: NahausBookingAccountTypeOptions.INCOME,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Forderungen aus dem Hausgeld, das von den Eigentümern für die Verwaltung und Instandhaltung der Gemeinschaftseigentum gezahlt wird."
    // },
    // {
    //     id: "45",
    //     refID: "7900",
    //     label: "Zinserträge",
    //     accountType: NahausBookingAccountTypeOptions.INCOME,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Einnahmen aus Zinsen, beispielsweise auf Rücklagen oder Guthaben."
    // },
    // {
    //     id: "46",
    //     refID: "7910",
    //     label: "Mietausfallwagnis",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Rückstellungen für potenzielle Mietausfälle oder Forderungsausfälle."
    // },
    {
        id: "47",
        refID: "2120", // checked 24.02.24
        label: "Zinsaufwendungen für langfristige Verbindlichkeiten",
        accountType: NahausBookingAccountTypeOptions.EXPENSE,
        taxRelevant: true,
        depreciationRelevant: false,
        isAllocatable: false,
        extraInfo: "Zinsaufwendungen für Darlehen, die für die Finanzierung von Immobilienprojekten aufgenommen wurden."
    },
    // {
    //     id: "48",
    //     refID: "1600",
    //     label: "Bankguthaben",
    //     accountType: NahausBookingAccountTypeOptions.OTHER,
    //     taxRelevant: false,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Kontostände auf Bankkonten, die für die Verwaltung von Mieteingängen und Ausgaben genutzt werden."
    // },
    // {
    //     id: "49",
    //     refID: "4300",
    //     label: "Nebenkostenabrechnungen",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Ausgaben für die Erstellung von Nebenkostenabrechnungen für Mieter."
    // },
    // {
    //     id: "50",
    //     refID: "4385",
    //     label: "Modernisierungskosten",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: true,
    //     isAllocatable: false,
    //     extraInfo: "Kosten für Modernisierungsmaßnahmen zur Wertsteigerung der Immobilien."
    // },
    // {
    //     id: "51",
    //     refID: "1732",
    //     label: "Erhaltene Kautionen",
    //     accountType: NahausBookingAccountTypeOptions.OTHER,
    //     taxRelevant: false,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Hinterlegte Kautionen von Mietern als Sicherheitsleistung. (Kaution für eine bestimme Zeit)"
    // },
    // {
    //     id: "52",
    //     refID: "4500",
    //     label: "Gewerbesteuer",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Zu entrichtende Gewerbesteuer für gewerblich genutzte Immobilien."
    // },
    {
        id: "53",
        refID: "4230", // checked 24.02.24
        label: "Heizung/ Gas",
        accountType: NahausBookingAccountTypeOptions.EXPENSE,
        taxRelevant: false,
        depreciationRelevant: false,
        isAllocatable: true,
        extraInfo: "Kosten für Gas und Heizung, die auf Mieter umlegbar sind."
    },
    // {
    //     id: "54",
    //     refID: "4820",
    //     label: "Wartungskosten für Anlagen und Einrichtungen",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Regelmäßige Wartungskosten für Aufzüge, Heizungsanlagen und andere Einrichtungen."
    // },
    // {
    //     id: "55",
    //     refID: "4845",
    //     label: "Versicherungskosten (Nicht-Gebäude)",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Prämien für Haftpflicht- und andere Versicherungen, die nicht direkt Gebäude betreffen."
    // },
    // {
    //     id: "56",
    //     refID: "3000",
    //     label: "Anschaffungskosten Grundstücke",
    //     accountType: NahausBookingAccountTypeOptions.INVESTMENT,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Kosten für den Erwerb von Grundstücken."
    // },
    // {
    //     id: "57",
    //     refID: "3001",
    //     label: "Anschaffungskosten Gebäude",
    //     accountType: NahausBookingAccountTypeOptions.INVESTMENT,
    //     taxRelevant: true,
    //     depreciationRelevant: true,
    //     isAllocatable: false,
    //     extraInfo: "Kosten für den Erwerb oder Bau von Gebäuden."
    // },
    // {
    //     id: "58",
    //     refID: "4816",
    //     label: "Kosten für Leerstand",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: false,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Laufende Kosten für derzeit nicht vermietete Einheiten."
    // },
    // {
    //     id: "59",
    //     refID: "4840",
    //     label: "Instandsetzung nach Mieterwechsel",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Kosten für die Renovierung und Instandsetzung von Mieteinheiten zwischen Mieterwechseln."
    // },
    // {
    //     id: "60",
    //     refID: "4885",
    //     label: "Berufsgenossenschaftsbeiträge für Hausangestellte",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Sozialversicherungsbeiträge für angestelltes Personal im Immobilienbereich."
    // },
    // {
    //     id: "61",
    //     refID: "4920",
    //     label: "Steuern und Abgaben auf Grundbesitz",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Direkte Steuern und Abgaben, die für den Besitz und Betrieb von Immobilien anfallen."
    // },
    // {
    //     id: "62",
    //     refID: "7000",
    //     label: "Umsatzerlöse aus dem Verkauf von Immobilien",
    //     accountType: NahausBookingAccountTypeOptions.INCOME,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: false,
    //     extraInfo: "Erlöse aus dem Verkauf von Immobilieneigentum."
    // },
    // {
    //     id: "63",
    //     refID: "6110",
    //     label: "Kosten für Dachrinnenreinigung",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Ausgaben für die regelmäßige Reinigung und Wartung der Dachrinnen."
    // },
    // {
    //     id: "64",
    //     refID: "6120",
    //     label: "Kosten für Garten- und Landschaftspflege",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Ausgaben für die Pflege von Gartenanlagen und Grünflächen, einschließlich Baumschnitt."
    // },
    // {
    //     id: "65",
    //     refID: "6130",
    //     label: "Kosten für Schneeräumung und Streudienst",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Ausgaben für Winterdienste wie Schneeräumung und Streuen von Gehwegen."
    // },
    // {
    //     id: "66",
    //     refID: "6140",
    //     label: "Kosten für die Reinigung von Gemeinschaftsflächen",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Ausgaben für die Reinigung von Treppenhäusern, Fluren und anderen Gemeinschaftsflächen."
    // },
    // {
    //     id: "67",
    //     refID: "6150",
    //     label: "Kosten für Aufzugswartung",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Ausgaben für die regelmäßige Wartung und Überprüfung der Aufzugsanlagen."
    // },
    // {
    //     id: "68",
    //     refID: "6160",
    //     label: "Versicherungskosten für Gebäude",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Prämien für Gebäudeversicherungen, inklusive Feuer-, Haftpflicht- und Wasserschadensversicherung."
    // },
    // {
    //     id: "69",
    //     refID: "6170",
    //     label: "Kosten für Hausmeisterdienste",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Ausgaben für Hausmeisterdienste wie Reparaturen, Reinigungsarbeiten und kleine Instandhaltungsmaßnahmen."
    // },
    // {
    //     id: "70",
    //     refID: "6180",
    //     label: "Kosten für Heizung und Warmwasser",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Ausgaben für Heizung und Warmwasserbereitung, einschließlich Brennstoffkosten und Wartungskosten für Heizanlagen."
    // },
    // {
    //     id: "71",
    //     refID: "6190",
    //     label: "Kosten für Strom und Beleuchtung",
    //     accountType: NahausBookingAccountTypeOptions.EXPENSE,
    //     taxRelevant: true,
    //     depreciationRelevant: false,
    //     isAllocatable: true,
    //     extraInfo: "Ausgaben für Stromverbrauch und Beleuchtungskosten in Gemeinschaftsbereichen."
    // },
    {
        id: "72",
        refID: "4997", // checked 24.02.24
        label: "Verwaltungskosten",
        accountType: NahausBookingAccountTypeOptions.EXPENSE,
        taxRelevant: true,
        depreciationRelevant: false,
        isAllocatable: false,
        extraInfo: "Ausgaben für die Verwaltung der Immobilie, einschließlich Honorare für Hausverwaltung und Buchhaltungsdienstleistungen."
    },
    // {
    //     id: "73",
    //     refID: "6210",
    //     label: "Instandhaltungsrückstellungen",
    //     accountType: NahausBookingAccountTypeOptions.SAVINGS,
    //     taxRelevant: false,
    //     depreciationRelevant: true,
    //     isAllocatable: false,
    //     extraInfo: "Rückstellungen für zukünftige Instandhaltungs- und Reparaturkosten, um langfristig die Vermögenswerte zu erhalten."
    // },
];


// Example of using enums for categories and payment methods
enum ExpenseCategory {
    Repairs,
    Maintenance,
    Insurance,
    AdministrationCosts,
    // ... other categories
}

enum PaymentMethod {
    BankTransfer,
    Cash,
    CreditCard,
    // ... other methods
}
