<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">

    <mat-drawer-container class="flex-auto h-full">

        <!-- Drawer -->
        <mat-drawer
            #matDrawer
            [autoFocus]="false"
            [mode]="drawerMode"
            [opened]="drawerOpened"
            class="w-90 dark:bg-gray-900">
            <div class="flex flex-col items-start p-8 border-b">
                <!-- Back to courses -->
                <a
                    [routerLink]="['..']"
                    class="inline-flex items-center leading-6 text-primary hover:underline">
                    <span class="inline-flex items-center">
                        <mat-icon
                            [svgIcon]="'heroicons_solid:arrow-sm-left'"
                            class="icon-size-5 text-current"></mat-icon>
                        <span class="ml-1.5 font-medium leading-5">Zurück</span>
                    </span>
                </a>
                <!-- Course category -->
                <div
                    class="mt-7 py-0.5 px-3 rounded-full text-sm font-semibold text-primary-800 bg-primary-100 dark:text-primary-50 dark:bg-primary-500">
                    Entwurf
                </div>
                <!-- Course title & description -->
                <div class="mt-3 text-2xl font-semibold">Übergabe</div>
                <div class="text-secondary">Herr Mustermann</div>
                <div class="text-secondary text-sm">EG Links</div>
                <div class="text-secondary text-xs">Zollstock 34, 37081 Göttingen</div>

                <div class="mt-4 text-secondary">VERMIETUNG VON BIS</div>
                <!-- Course time -->
                <div class="mt-6 flex items-center leading-5 text-md text-secondary">
                    <mat-icon
                        [svgIcon]="'heroicons_solid:clock'"
                        class="icon-size-5 text-hint"></mat-icon>
                    <div class="ml-1.5">2 minutes</div>
                </div>
            </div>

            <!-- Steps -->
            <div class="py-2 px-8">
                <ol>
                    <ng-container *ngFor="let step of this.steps; let last = last; trackBy: trackByFn">
                        <li
                            [class.current-step]="step.order === currentStep"
                            class="relative group py-6">
                            <ng-container *ngIf="!last">
                                <div
                                    [ngClass]="{'bg-primary': step.order < currentStep,
                                                'bg-gray-300 dark:bg-gray-600': step.order >= currentStep}"
                                    class="absolute top-6 left-4 w-0.5 h-full -ml-px"></div>
                            </ng-container>
                            <div
                                (click)="goToStep(step.order)"
                                class="relative flex items-start cursor-pointer">
                                <div
                                    [ngClass]="{'bg-primary dark:bg-primary text-on-primary group-hover:bg-primary-800 ring-transparent': step.order < currentStep,
                                                'ring-primary': step.order === currentStep,
                                                'ring-gray-300 dark:ring-gray-600 group-hover:ring-gray-400': step.order > currentStep}"
                                    class="flex flex-0 items-center justify-center w-8 h-8 rounded-full ring-2 ring-inset bg-card dark:bg-default">
                                    <!-- Check icon, show if the step is completed -->
                                    <ng-container *ngIf="step.order < currentStep">
                                        <mat-icon
                                            [svgIcon]="'heroicons_solid:check'"
                                            class="icon-size-5 text-current"></mat-icon>
                                    </ng-container>
                                    <!-- Step order, show if the step is the current step -->
                                    <ng-container *ngIf="step.order === currentStep">
                                        <div
                                            class="text-md font-semibold text-primary dark:text-primary-500">{{step.order + 1}}</div>
                                    </ng-container>
                                    <!-- Step order, show if the step is not completed -->
                                    <ng-container *ngIf="step.order > currentStep">
                                        <div
                                            class="text-md font-semibold text-hint group-hover:text-secondary">{{step.order + 1}}</div>
                                    </ng-container>
                                </div>
                                <div class="ml-4">
                                    <div class="font-medium leading-4">{{step.title}}</div>
                                    <div class="mt-1.5 text-md leading-4 text-secondary">{{step.subtitle}}</div>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                </ol>
            </div>

        </mat-drawer>

        <!-- Drawer content -->
        <mat-drawer-content class="flex flex-col overflow-hidden">

            <!-- Header -->
            <div
                class="lg:hidden flex flex-0 items-center py-2 pl-4 pr-6 sm:py-4 md:pl-6 md:pr-8 border-b lg:border-b-0 bg-card dark:bg-transparent">
                <!-- Title & Actions -->
                <a
                    [routerLink]="['..']"
                    mat-icon-button>
                    <mat-icon [svgIcon]="'heroicons_solid:arrow-sm-left'"></mat-icon>
                </a>
                <h2 class="ml-2.5 text-md sm:text-xl font-medium tracking-tight truncate">
                    Title
                </h2>
            </div>
            <mat-progress-bar
                [value]="100 * (currentStep + 1) / this.totalSteps"
                class="hidden lg:block flex-0 h-0.5 w-full"></mat-progress-bar>

            <!-- Main -->
            <div
                cdkScrollable
                class="flex-auto overflow-y-auto">

                <!-- Steps -->
                <mat-tab-group
                    #courseSteps
                    [animationDuration]="'200'"
                    class="fuse-mat-no-header">
                    <ng-container *ngFor="let step of this.steps;index as i; trackBy: trackByFn">
                        <mat-tab>
                            <ng-template matTabContent>
                                <div
                                    class="prose prose-sm max-w-3xl mx-auto sm:my-2 lg:mt-4 p-6 sm:p-10 sm:py-12 rounded-2xl shadow overflow-hidden bg-card">
                                    <div [ngSwitch]="i">
                                        <div *ngSwitchCase="0">
                                            <ng-container *ngTemplateOutlet="step0"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="1">
                                            <ng-container *ngTemplateOutlet="step1"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="2">
                                            <ng-container *ngTemplateOutlet="step2"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="3">
                                            <ng-container *ngTemplateOutlet="step3"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="4">
                                            <ng-container *ngTemplateOutlet="step4"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="5">
                                            <ng-container *ngTemplateOutlet="step5"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="6">
                                            <ng-container *ngTemplateOutlet="step6"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="7">
                                            <ng-container *ngTemplateOutlet="step7"></ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </ng-container>
                </mat-tab-group>

                <!-- Navigation - Desktop -->
                <div class="z-10 sticky hidden lg:flex bottom-4 p-4">
                    <div class="flex items-center justify-center mx-auto p-2 rounded-full shadow-lg bg-primary">
                        <button
                            (click)="goToPreviousStep()"
                            [color]="'primary'"
                            class="flex-0"
                            mat-flat-button>
                            <span class="inline-flex items-center">
                                <mat-icon
                                    [svgIcon]="'heroicons_outline:arrow-narrow-left'"
                                    class="mr-2"></mat-icon>
                                <span class="mr-1">Vorherige</span>
                            </span>
                        </button>
                        <div class="flex items-center justify-center mx-2.5 font-medium leading-5 text-on-primary">
                            <span>{{currentStep + 1}}</span>
                            <span class="mx-0.5 text-hint">/</span>
                            <span>{{this.totalSteps}}</span>
                        </div>
                        <button
                            (click)="goToNextStep()"
                            *ngIf="this.currentStep + 1 !== this.totalSteps"
                            [color]="'primary'"
                            class="flex-0"
                            mat-flat-button>
                            <span class="inline-flex items-center">
                                <span class="ml-1">Nächste</span>
                                <mat-icon
                                    [svgIcon]="'heroicons_outline:arrow-narrow-right'"
                                    class="ml-2"></mat-icon>
                            </span>
                        </button>
                        <button *ngIf="this.currentStep + 1 === this.totalSteps"
                                [color]="'primary'"
                                class="flex-0"
                                mat-flat-button>
                            <span class="ml-1">Protokoll Erstellen</span>
                            <mat-icon
                                [svgIcon]="'heroicons_outline:document-text'"
                                class="ml-2"></mat-icon>
                        </button>
                    </div>
                </div>

            </div>

            <!-- Progress & Navigation - Mobile -->
            <div class="lg:hidden flex items-center p-4 border-t bg-card">
                <button
                    (click)="matDrawer.toggle()"
                    mat-icon-button>
                    <mat-icon [svgIcon]="'heroicons_outline:view-list'"></mat-icon>
                </button>
                <div class="flex items-center justify-center ml-1 lg:ml-2 font-medium leading-5">
                    <span>{{currentStep + 1}}</span>
                    <span class="mx-0.5 text-hint">/</span>
                    <span>{{this.totalSteps}}</span>
                </div>
                <mat-progress-bar
                    [value]="100 * (currentStep + 1) / this.totalSteps"
                    class="flex-auto ml-6 rounded-full"></mat-progress-bar>
                <button
                    (click)="goToPreviousStep()"
                    class="ml-4"
                    mat-icon-button>
                    <mat-icon [svgIcon]="'heroicons_outline:arrow-narrow-left'"></mat-icon>
                </button>
                <button
                    (click)="goToNextStep()"
                    class="ml-0.5"
                    mat-icon-button>
                    <mat-icon [svgIcon]="'heroicons_outline:arrow-narrow-right'"></mat-icon>
                </button>
            </div>

        </mat-drawer-content>

    </mat-drawer-container>

</div>

<ng-template #step0>
    <form [formGroup]="this.digitalProtocolForm">
        <h1 class="text-2xl sm:text-3xl mb-1">Stammdaten</h1>
        <div class="text-secondary mt-1 mb-4">Erfassen Sie alle grundlegenden Informationen für die Übergabe.
        </div>
        <div class="flex flex-col space-y-2 w-full">
            <mat-form-field appearance="fill">
                <mat-label>Datum und Uhrzeit</mat-label>
                <input [matDatepicker]="picker" matInput placeholder="Wählen Sie Datum und Uhrzeit">
                <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-hint>Wählen Sie Datum und Uhrzeit</mat-hint>
                <mat-error>Datum und Uhrzeit sind erforderlich</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Mietverhältnis</mat-label>
                <input matInput placeholder="Start- und Enddatum">
                <mat-icon matSuffix>date_range</mat-icon>
                <mat-hint>Geben Sie das Start- und Enddatum ein</mat-hint>
                <mat-error>Start- und Enddatum sind erforderlich</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Mieter</mat-label>
                <input matInput placeholder="Name des Mieters">
                <mat-icon matSuffix>person</mat-icon>
                <mat-hint>Geben Sie den Namen des Mieters ein</mat-hint>
                <mat-error>Mietername ist erforderlich</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Einheit</mat-label>
                <input matInput placeholder="Einheitsnummer">
                <mat-icon matSuffix>home</mat-icon>
                <mat-hint>Geben Sie die Einheitsnummer ein</mat-hint>
                <mat-error>Einheitsnummer ist erforderlich</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Immobilie</mat-label>
                <input matInput placeholder="Adresse oder Bezeichnung der Immobilie">
                <mat-icon matSuffix>location_city</mat-icon>
                <mat-hint>Geben Sie die Adresse oder Bezeichnung der Immobilie ein</mat-hint>
                <mat-error>Die Angabe der Immobilie ist erforderlich</mat-error>
            </mat-form-field>

            <mat-checkbox color="primary">
                Wohnungsgeberbescheinigung erhalten
            </mat-checkbox>
        </div>
    </form>
</ng-template>

<ng-template #step1>
    <form [formGroup]="this.digitalProtocolForm">
        <h1 class="text-2xl sm:text-3xl mb-1">Schlüsselverwaltung</h1>
        <div class="text-secondary mt-1 mb-4">Dokumentieren Sie die Anzahl und Typen der übergebenen Schlüssel.</div>
        <!-- Schleife über das FormArray 'keys' -->
        <div formArrayName="keys">
            <div *ngFor="let keyControl of keys.controls; let i = index" @zoomIn
                 @zoomOut [formGroupName]="i" class="flex flex-row space-x-2 items-center">
                <!-- Material Input für den Schlüsseltyp -->
                <mat-form-field appearance="fill" class="w-full">
                    <mat-label>Schlüsseltyp</mat-label>
                    <input formControlName="type" matInput placeholder="Geben Sie den Typ des Schlüssels ein">
                    <mat-icon matSuffix>vpn_key</mat-icon>
                </mat-form-field>

                <!-- Material Input für die Schlüsselanzahl -->
                <mat-form-field appearance="fill" class="w-full">
                    <mat-label>Anzahl</mat-label>
                    <input formControlName="quantity" matInput placeholder="Anzahl der Schlüssel" type="number">
                    <mat-icon matSuffix>confirmation_number</mat-icon>
                </mat-form-field>

                <!-- Material Input für die Schlüsselnummer -->
                <mat-form-field appearance="fill" class="w-full">
                    <mat-label>Nummer</mat-label>
                    <input formControlName="numberID" matInput placeholder="Schlüsselnummer">
                    <mat-icon matSuffix>format_list_numbered</mat-icon>
                </mat-form-field>

                <!-- Entfernen-Button für den aktuellen Schlüssel -->
                <button (click)="removeKey(i)" color="warn" mat-icon-button type="button">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>

        <!-- Button zum Hinzufügen eines neuen Schlüssels -->
        <div class="actions">
            <button (click)="this.addKey('', '', '')" mat-button type="button">
                <mat-icon>add</mat-icon>
                Schlüssel hinzufügen
            </button>
        </div>
    </form>
</ng-template>

<ng-template #step2>
    <form [formGroup]="this.divisionForm">
        <h1 class="text-2xl sm:text-3xl mb-1">Räumlichkeiten prüfen</h1>
        <div class="text-secondary mt-1 mb-4">Erfassen Sie den Zustand und Details aller relevanten Räume.</div>
        <div formArrayName="divisions">
            <div *ngFor="let division of divisions.controls; let i = index" @zoomIn
                 @zoomOut [formGroupName]="i" class="drop-shadow-xl filter-article my-8">
                <div class="flex flex-col space-y-2 w-full">
                    <mat-form-field appearance="fill">
                        <mat-label>Titel</mat-label>
                        <input formControlName="title" matInput placeholder="Raumbezeichnung">
                    </mat-form-field>

                    <!-- Quill Editor für den HTML-Inhalt; hier könnte eine Quill-Editor-Komponente eingebunden werden -->
                    <quill-editor formControlName="html"></quill-editor>

                    <button (click)="removeDivision(i)" color="warn" mat-button type="button">Entfernen</button>
                </div>
                <mat-divider class="my-10"></mat-divider>
            </div>
        </div>
        <button (click)="addDivision('', '')" class="mt-4" mat-button
                matTooltip="Klicken Sie hier, um einen weiteren Raum mit spezifischen Details wie Zustand und Einrichtung zu erfassen."
                type="button">
            <mat-icon>add</mat-icon>
            Neuen Bereich erfassen
        </button>
    </form>
</ng-template>

<ng-template #step3>
    <form [formGroup]="this.divisionForm">
        <h1 class="text-2xl sm:text-3xl mb-1">Zählerstände erfassen</h1>
        <div class="text-secondary mt-1 mb-4">
            Dokumentieren Sie die aktuellen Zählerstände und fügen Sie bei Bedarf Fotos hinzu.
        </div>
        <div class="mt-4 w-full">
            <!-- Card #15 -->
            <fuse-card class="flex flex-col w-full p-8 pb-4 filter-article drop-shadow-xl">
                <div class="flex items-center">
                    <img
                        alt="Card cover image"
                        class="w-20 rounded-lg object-cover"
                        src="/assets/icons/meter/electric-meter_1.png">
                    <div class="flex flex-col ml-6">
                        <div class="text-2xl font-semibold leading-tight">Stromzähler</div>
                        <div class="text-secondary font-medium text-md">#86516206151561</div>
                        <div class="flex flex-row space-x-1 items-center">
                            <mat-icon
                                [svgIcon]="'heroicons_solid:location-marker'"
                                class="icon-size-4"></mat-icon>
                            <div class="text-secondary font-medium text-sm">Keller</div>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <!-- Info -->
                    <fuse-alert
                        [appearance]="'outline'"
                        [type]="'info'">
                        <span fuseAlertTitle>Bemerkung</span>
                        This is a alert with an 'info' level severity
                    </fuse-alert>
                </div>

                <div class="flex flex-col sm:flex-row space-x-0 sm:space-x-2 sm:space-y-0 space-y-2 w-full my-4">
                    <mat-form-field appearance="fill" class="w-full">
                        <mat-label>Datum der Ablesung</mat-label>
                        <input [matDatepicker]="picker" matInput
                               placeholder="Wählen Sie ein Datum">
                        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <!--                        <mat-hint>Tag der Zählerablesung</mat-hint>-->
                        <mat-error>Datum ist erforderlich</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="w-full">
                        <mat-label>Zählerstand</mat-label>
                        <input matInput placeholder="Geben Sie den Zählerstand ein"
                               type="number">
                        <mat-icon matPrefix>speed</mat-icon>
                        <mat-hint>Aktueller Stand des Zählers</mat-hint>
                        <mat-error>Zählerstand ist erforderlich</mat-error>
                    </mat-form-field>
                </div>

                <div class="mt-3 -mx-3">
                    <!-- photo-capture.component.html -->
                    <button (click)="openCameraDialog()" color="accent" mat-raised-button>
                        <mat-icon>camera_alt</mat-icon>
                        <span class="ml-2">Foto aufnehmen</span>
                    </button>

                </div>
            </fuse-card>
        </div>
    </form>
</ng-template>


<ng-template #step4>
    <form [formGroup]="this.divisionForm">
        <h1 class="text-2xl sm:text-3xl mb-1">Mängelmanagement</h1>
        <div class="text-secondary mt-1 mb-4">
            Erfassen Sie Mängel und Schäden und leiten Sie sofortige Maßnahmen ein.
        </div>
    </form>
</ng-template>

<ng-template #step5>
    <form [formGroup]="this.divisionForm">
        <h1 class="text-2xl sm:text-3xl mb-1">Mieterdaten überprüfen</h1>
        <div class="text-secondary mt-1 mb-4">
            Kontrollieren Sie die persönlichen Daten Ihrer Mieter für die abschließende Bestätigung
        </div>
    </form>
</ng-template>

<ng-template #step6>
    <form [formGroup]="this.divisionForm">
        <h1 class="text-2xl sm:text-3xl mb-1">Bemerkungen</h1>
        <div class="text-secondary mt-1 mb-4">
            Fügen Sie hier zusätzliche Anmerkungen oder spezielle Hinweise hinzu.
        </div>
    </form>
</ng-template>

<ng-template #step7>
    <form [formGroup]="this.divisionForm">
        <h1 class="text-2xl sm:text-3xl mb-1">Unterschriften</h1>
        <div class="text-secondary mt-1 mb-4">
            Vollziehen Sie den Abschluss durch die Unterschriften von Vermieter und Mieter.
        </div>
    </form>
</ng-template>
