import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MSA} from '@msa/models';
import {MsaService} from '@msa/services/msa.service';
import {AbstractFirestoreDocResolver} from '@shared/services/abstracts/AbstractFirestoreDocResolver';


@Injectable({providedIn:"root"})
export class MsaResolver extends AbstractFirestoreDocResolver<MSA> {

  constructor(route: ActivatedRoute, router: Router, service: MsaService) {
    super(router, service);
  }
}
