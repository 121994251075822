import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet} from '@angular/router';
import {FuseLoadingService} from '@fuse/services/loading-old';
import {TranslateService} from '@ngx-translate/core';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {Subject, switchMap, take, takeUntil} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {SubscriptionsService} from "@subscriptions/services/subscriptions.service";
import {environment} from "@env/environment";
import {NahausTiersOptions} from "@users/models/user.interface";
import {NativeDateAdapter} from "@angular/material/core";

declare var rewardful: any;
declare var Rewardful: any;

// declare var window.Rewardful: any;

export class CustomDateAdapter extends NativeDateAdapter {
    parse(value: any): Date | null {
        const currentDate = new Date();
        let year: number = currentDate.getFullYear();
        let month: number = currentDate.getMonth();
        let day: number = currentDate.getDate();

        if ((typeof value === 'string') &&
            ((value.indexOf('/') > -1) || (value.indexOf('.') > -1) || (value.indexOf('-') > -1))) {

            const str = value.split(/[\./-]/);

            day = !!str[0] ? +str[0] : day;
            month = !!str[1] ? +str[1] - 1 : month;
            year = !!str[2] ?
                // If year is less than 3 digit long, we add 2000.
                +str[2].length <= 3 ? +str[2] + 2000 : +str[2] : year;

            return new Date(year, month, day);
        }
    }
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet]
})
export class AppComponent implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     *
     */
    constructor(private translate: TranslateService,
                private router: Router,
                private _fuseLoadingService: FuseLoadingService,
                private gtmService: GoogleTagManagerService,
                private subscriptionsService: SubscriptionsService
    ) {
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('de');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.translate.use('de');

        // push GTM data layer for every visited page
        // if (environment.pool) {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {

            const gtmTag = {
                event: 'page',
                pageName: event.urlAfterRedirects
            };

            // console.log('pushTag --> ', gtmTag);

            this.gtmService.pushTag(gtmTag);

        });
        // }


        this.router.events.subscribe((event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this._fuseLoadingService.show();
                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this._fuseLoadingService.hide();
                    break;
                }
                default: {
                    break;
                }
            }
        });
    }

    ngOnInit(): void {
        rewardful('ready', () => {
            console.log('Rewardful Ready!');
            if (Rewardful.referral) {
                // The current website visitor is a referral from an affiliate.
                // @ts-ignore
                console.log('rewardful 1 = ', window.Rewardful && window.Rewardful.referral || ('checkout_' + (new Date).getTime()));
                // @ts-ignore
                console.log('rewardful 2 = ', window.Rewardful, window.Rewardful.referral);
                console.log('rewardful 3 = ', Rewardful);
                console.log('rewardful 4 = ', rewardful);
                // rewardful('convert', { email: 'test@nahaus.de' });
            } else {
                console.log('no referral found');
            }
        });
        // console.log('current lang', this.translate.currentLang);
        // console.log('AppComponent ngOnInit');
        // console.log('rewardful = ', rewardful);
        // // @ts-ignore
        // console.log('rewardful 1 = ', window.Rewardful && window.Rewardful.referral || ('checkout_' + (new Date).getTime()));

        this.initSubLogic();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        console.log('AppComponent ngOnDestroy');
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    initSubLogic(): void {
        if(!environment?.pool){
            this.subscriptionsService.authService.isActiveUser = true;
            return;
        }
        this.subscriptionsService?.authService?.customerID$
        .pipe(
            filter(customerID => !!customerID),
            switchMap(() => {
                return this.subscriptionsService?.authService?.auth?.user
                    .pipe(filter(user => !!user?.uid),
                        switchMap(() => {
                            return this.subscriptionsService.collection((ref) =>
                                ref.where('role', "in", [NahausTiersOptions.STARTER, NahausTiersOptions.PRO, NahausTiersOptions.ENTERPRISE])
                                    .where('status', "==", 'active').orderBy('created', 'desc')
                                    .limit(1))
                                .get()
                                .pipe(take(1), map(res => res.size > 0));
                        }));
            }),
            takeUntil(this._unsubscribeAll)
        )
        .subscribe((isActiveUser) => {
            this.subscriptionsService.authService.isActiveUser = isActiveUser;
        });
    }
}
