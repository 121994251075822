import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSelectionListChange, MatListModule} from "@angular/material/list";
import {LastWarningType, LastWarningTypeOptions} from "../../models/dunning.interface";
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

@Component({
    selector: 'fuse-confirmation-dialog',
    templateUrl: './select-dunning-last-reminder-type-dialog.component.html',
    styles: [
        `
            .fuse-confirmation-dialog-panel {
                @screen md {
                    @apply w-128;
                }

                .mat-mdc-dialog-container {

                    .mat-mdc-dialog-surface {
                        padding: 0 !important;
                    }
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatListModule]
})
export class SelectDunningLastReminderTypeDialogComponent implements OnInit {

    lastWarningType: LastWarningType;
    lastWarningTypeOptions = LastWarningTypeOptions;

    /**
     * Constructor
     */
    constructor(private dialogRef: MatDialogRef<SelectDunningLastReminderTypeDialogComponent>) {
    }

    ngOnInit(): void {
    }

    onDunningTypeSelectionChanged($event: MatSelectionListChange): void {
        this.lastWarningType = $event.options[0].value as LastWarningType;
        console.log('onDunningTypeSelectionChanged', this.lastWarningType);
    }

    close() {
        this.dialogRef.close(this.lastWarningType || LastWarningTypeOptions.FINAL_WARNING);
    }

}
