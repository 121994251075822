import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {catchError, map, Observable, throwError} from 'rxjs';
import {ScrumboardService} from "../services/scrumboard.service";
import {Card} from "../models/scrumboard.models";
import { BoardService } from '../services/board.service';

@Injectable()
export class CardResolver implements Resolve<Card> {

    constructor(private scrumboardService: ScrumboardService,
                private router: Router ,
                private boardService : BoardService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Card> {
        const boardId = route.parent.paramMap.get('boardId')
        const cardId = route.params['cardId'];
        // console.log('the param is', route.parent.paramMap.get('boardId'))
        console.log('in the resolver the board id is', boardId )
        console.log('in the resolver the card id is', cardId )
     
        return this.boardService.doc(boardId).collection<Card>('cards').doc(cardId).get().pipe(map(
          doc => doc?.data() as Card
        ));
    }

}
