import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFirestoreCollectionGroup } from '@angular/fire/compat/firestore/collection-group/collection-group';
import { QueryGroupFn } from '@angular/fire/compat/firestore/interfaces';
import { environment } from '@env/environment';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';
import { ManagementUnitPeriod } from '../models/managementUnitPeriod';

@Injectable({
  providedIn: 'root'
})
export class ManagementPeriodsService extends AbstractFirestoreService<ManagementUnitPeriod> {

  COLLECTION = 'managements';

  // @ts-ignore
  override collectionGroup(queryFn?: QueryFn | QueryGroupFn<ManagementUnitPeriod>): AngularFirestoreCollection<ManagementUnitPeriod> | AngularFirestoreCollectionGroup<ManagementUnitPeriod> {
    if (environment.pool) {
      return this.collection(queryFn);
    } else {
      return super.collectionGroup(queryFn);
    }
  }

  constructor(authService: AuthService) {
    super(authService);
  }

  setPath(propertyID: string, unitID: string): void {
    this.parentPath = `properties/${propertyID}/units/${unitID}`;
  }
}
