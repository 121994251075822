import {SelectionModel} from '@angular/cdk/collections';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {DocumentChangeAction, Query} from '@angular/fire/compat/firestore';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {MatDrawer, MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort, MatSortModule, Sort} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {ActivatedRoute, Router, RouterLink, RouterOutlet} from '@angular/router';
import {environment} from '@env/environment';
import {fuseAnimations} from '@fuse/animations';
import {FuseConfigService} from '@fuse/services/config-old';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher-old';
import {MSA, MsaStatus, MsaType} from '@msa/models';
import {MsaProcess} from '@msa/models/msa-process.interface';
import {MsaService} from '@msa/services/msa.service';
import {SearchState} from '@shared/algolia/directives/stat-listener.directive';
import {Hit, InstantSearchConfig} from 'angular-instantsearch/instantsearch/instantsearch';
import {LocalStorage} from 'ngx-webstorage';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {catchError, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {AppConfig} from '../../../../../core/config/app.config';
import {Expense} from "../../models/expenses.interface";
import {ExpensesMainComponent} from "../expenses-main/expenses-main.component";
import {ExpensesModularService} from "../../services/expenses-modular.service";
import {QueryConstraint} from "@firebase/firestore";
import {
    collectionSnapshots,
    getCountFromServer,
    limit,
    orderBy,
    query,
    startAfter,
    startAt,
    where
} from "@angular/fire/firestore";
import {QueryDocumentSnapshot} from "rxfire/firestore/interfaces";
import {Transaction} from "@transactions/models/transaction.interface";
import {
    YoutubeDialogComponent,
    YoutubeDialogData,
    YoutubeDialogResult
} from "@shared/youtube-dialog/dialog/youtube-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MomentModule} from 'ngx-moment';
import {FormatAddressPipe} from '../../../../../shared/pipes/format-address.pipe';
import {ToDatePipe} from '../../../../../shared/pipes/to-date.pipe';
import {FuseCardComponent} from '@fuse/components/card/card.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {HitsListenerDirective} from '../../../../../shared/algolia/directives/hits-listener.directive';
import {StatListenerDirective} from '../../../../../shared/algolia/directives/stat-listener.directive';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {
    AlgoliaInstantSearchComponent
} from '../../../../../shared/algolia/components/algolia-instant-search/algolia-instant-search.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {CurrencyPipe, DatePipe, NgClass, NgIf} from '@angular/common';
import {NgAisConfigureModule, NgAisHitsModule, NgAisInstantSearchModule, NgAisStatsModule} from 'angular-instantsearch';
import { ExpensesAccessLayerOptions } from '../../expenses.routing';
import { Property } from '../../../../../../../functions/src/core/properties/models/property.interface';
import {YoutubePreviewComponent} from '@shared/youtube/youtube-preview/youtube-preview.component';

@Component({
    selector: 'expenses-list',
    templateUrl: './expenses-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    standalone: true,
    imports: [NgAisInstantSearchModule, NgAisConfigureModule, MatSidenavModule, RouterOutlet, NgIf, MatProgressBarModule, MatButtonModule, MatIconModule, AlgoliaInstantSearchComponent, MatTooltipModule, RouterLink, MatProgressSpinnerModule, NgAisStatsModule, StatListenerDirective, NgAisHitsModule, HitsListenerDirective, CdkScrollable, MatTableModule, MatSortModule, NgClass, MatCheckboxModule, MatPaginatorModule, FuseCardComponent, CurrencyPipe, DatePipe, ToDatePipe, FormatAddressPipe, MomentModule, YoutubePreviewComponent],
    providers: [MsaService],
})
export class ExpensesListComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('matDrawer', {static: true}) drawer: MatDrawer;
    @ViewChild(MatSort) _sort: MatSort;

    @Input() searchState: SearchState;

    @LocalStorage('ExpensesListComponent.defaultPageSize', 25)
    defaultPageSize: number;

    @LocalStorage('ExpensesListComponent.openYoutube', true)
    openYoutube: boolean;

    // Drawer
    sideBadDrawer: MatDrawer;
    drawerMode: 'side' | 'over';

    // pagination
    firstDocMap: Map<number, any> = new Map<number, any>();
    lastItem: any;
    page: PageEvent;
    // page: PageEvent = { pageSize: 10, pageIndex: 0, length: 100 };
    // readonly defaultPage: PageEvent = { pageSize: 10, pageIndex: 0, length: 100 };
    // pageSize: number = this.defaultPage?.pageSize;

    // table source
    dataSource: MatTableDataSource<Expense>;
    hitsSource: MatTableDataSource<Expense>;
    selection = new SelectionModel<Expense>(true, []);
    // displayedColumns
    displayedColumns: string[] =
        [
            'nahausBookingsAccount.refID',
            // 'description',
            'date',
            // 'nahausBookingsAccount.label',
            'amount',
            'property.address.streetName',
            // 'target.desiredStartOfRental',
            // 'contactData.firstName',
            // 'contactData.phoneNumber',
            // 'employment.type',
            // 'target.type'
        ];

    flashMessage: 'success' | 'error' | null = null;
    // indicators
    isLoading: boolean = false;
    creatingContract: boolean;
    initialLoad: boolean;
    expenseExists!: boolean;

    selected: Expense | null = null;
    // Filters
    onPageEventChanged$: BehaviorSubject<PageEvent>;
    onFilterMsaProcessChanged$: BehaviorSubject<MsaProcess>;
    onFilterMsaStatusChanged$: BehaviorSubject<MsaStatus>;
    onTargetTypeFilterChanged$: BehaviorSubject<MsaType>;
    onSortChanged$: BehaviorSubject<Sort> = new BehaviorSubject<Sort>(null);

    // Algolia
    config: InstantSearchConfig;


    appTheme: string;
    property: Property;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    accessLayer: ExpensesAccessLayerOptions;
    bookingsAccessLayerOptions = ExpensesAccessLayerOptions;
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseConfigService: FuseConfigService,
        private expensesModularService: ExpensesModularService,
        private expensesMainComponent: ExpensesMainComponent,
        private msaService: MsaService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog,
    ) {
        this.config = {
            indexName: environment.algolia.indexName.expenses,
            searchClient: this.expensesModularService?.authService?.getAlgoliaSearchClient(false),
            routing: true
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.accessLayer = (this.route.snapshot?.data?.accessLayer || this.route.parent?.snapshot?.data?.accessLayer)as ExpensesAccessLayerOptions || ExpensesAccessLayerOptions.PERIOD; ;
        console.log('the data of access layer are : ' , this.accessLayer)
        this.property = (this.route.snapshot.data?.property || this.route?.parent.snapshot.data?.property) as Property;
        console.log('the property data are:', this.property?.id)
        this.sideBadDrawer = this.expensesMainComponent.drawer;

        this.page = {pageSize: this.defaultPageSize, pageIndex: 0, length: 0};
        this.onPageEventChanged$ = new BehaviorSubject<PageEvent>(this.page);

        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: AppConfig) => {
                console.log('config', config);
                this.appTheme = config?.theme.split('-')[1];
                this._changeDetectorRef.markForCheck();
                this._changeDetectorRef.detectChanges();
            });

        this.expensesModularService
            .onSelectedChanged$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((account) => {
                this.selected = account;
                console.log('on account changed', this.selected);
            });

        this.loadData();

        // this.msaServiceOld
        //     .onSelectedChanged$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((msa) => {
        //         this.selected = msa;
        //         console.log('onSelectedMsaChanged', this.selected);
        //     });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Set the drawerMode if the given breakpoint is active
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                } else {
                    this.drawerMode = 'over';
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

    }

    /**
     * After view init
     */
    ngAfterViewInit(): void {
        // If the user changes the sort order...
        this._sort?.sortChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Reset back to the first page
                // this._paginator.pageIndex = 0;

                // Close the details
                this.closeDetails();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle product details
     *
     * @param msaID
     */
    toggleDetails(msaID: string): void {
        // If the product is already selected...
        if (this.selected && this.selected.id === msaID) {
            // Close the details
            this.closeDetails();
            return;
        }
    }

    /**
     * Close the details
     */
    closeDetails(): void {
        this.selected = null;
    }

    /**
     * Show flash message
     */
    showFlashMessage(type: 'success' | 'error'): void {
        // Show the message
        this.flashMessage = type;

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Hide it after 3 seconds
        setTimeout(() => {

            this.flashMessage = null;

            // Mark for check
            this._changeDetectorRef.markForCheck();
        }, 3000);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item?.id || index;
    }

    onBackdropClicked(): void {
        // Go back to the list
        this.router.navigate(['./'], {relativeTo: this.route});

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    // listenOnNewHits(): void {
    //     this.msaServiceOld.onNewHits
    //         .pipe(
    //             takeUntil(this._unsubscribeAll),
    //             debounceTime(500),
    //             map((value) => {
    //                 const msaList: MSA[] = value as MSA[];
    //                 console.log('msaList', msaList);
    //                 if (msaList?.length > 0) {
    //                     msaList.forEach((msa) => {
    //                         console.log('msa.createdAt 1', msa.createdAt);
    //                         // msa.createdAt = new Date(msa?.createdAt); // todo: please update this line 28.08.21
    //                         console.log('msa.createdAt 2', msa.createdAt);
    //                         return msa;
    //                     });
    //                 }
    //                 return msaList;
    //             }))
    //         .subscribe((hits) => {
    //             console.log('hits', hits);
    //             // @ts-ignore
    //             this.hitsSource = new MatTableDataSource<MSA>(hits ? hits : []);
    //         });
    // }

    loadData2(): void {
        console.log('loadData()');
        combineLatest(
            [
                this.onPageEventChanged$,
                this.onSortChanged$
                // this.msaServiceOld.onCreatedDateAndTimeRangeChanged$
            ]
        ).pipe(
            tap(() => {
                console.log('loading ...');
                this.isLoading = true;
            }),
            switchMap(([pageEvent, sort]:
                           [PageEvent, Sort]) =>
                this.msaService.collection((ref) => {

                    let query: Query = ref;


                    console.table([{
                        'pageEvent': pageEvent,
                        'sort': sort
                    }]);


                    if (sort) {
                        // this.page = this.defaultPage;
                        // for paginating --> when the list already initialized
                        if (sort?.active === 'createdAt') {
                            query = query
                                .orderBy(sort?.active, sort?.direction === 'asc' ? 'asc' : 'desc')
                                .limit(pageEvent?.pageSize);
                        } else {
                            query = query
                                .orderBy(sort?.active, sort?.direction === 'asc' ? 'asc' : 'desc')
                                .orderBy('createdAt', 'desc')
                                .limit(pageEvent?.pageSize);
                        }
                    } else {
                        // for paginating --> when the list already initialized
                        query = query
                            .orderBy('createdAt', 'desc')
                            .limit(pageEvent?.pageSize);
                    }

                    // if (process) {
                    //     query = query.where('process', '==', process);
                    // }

                    if (pageEvent.pageIndex < pageEvent.previousPageIndex) {
                        // get previous page
                        console.log('get previous page', this.firstDocMap);
                        if (this.firstDocMap) {
                            query = query.startAt(this.firstDocMap.get(pageEvent.pageIndex));
                        }
                    } else {
                        // get next page
                        console.log('get next page', this.lastItem);
                        if (this.lastItem) {
                            query = query.startAfter(this.lastItem);
                        }
                    }

                    console.log('query', query);
                    return query;
                }).snapshotChanges()))
            .pipe(
                takeUntil(this._unsubscribeAll),
                catchError((error: any) => {
                    console.error('Error: ', error);
                    this.router.navigate(['fehler', '404']);
                    return error;
                }))
            .subscribe((data: DocumentChangeAction<MSA>[]) => {
                console.log('data response', data);

                if (!this.initialLoad) {
                    this.initialLoad = true;

                }

                if (data.length === 0) {
                    this.dataSource = new MatTableDataSource();
                    this.firstDocMap = new Map<number, any>();
                    this.lastItem = null;
                    this.isLoading = false;
                    this._changeDetectorRef.markForCheck();
                    return;
                }

                const dataList = [];
                data.forEach((action) => {
                    // dataList.push(convertTimestamps(action.payload.doc.data()));
                    dataList.push(action.payload.doc.data());
                });

                this.dataSource = new MatTableDataSource(dataList);
                this.firstDocMap.set(this.page?.pageIndex ? this.page?.pageIndex : 0, data[0].payload.doc);
                this.lastItem = data[data.length - 1].payload.doc;

                console.log('loading done', this.dataSource.data);
                this.isLoading = false;
                this._changeDetectorRef.markForCheck();
            });
    }

    loadData(): void {
        console.log('loadData()');
        combineLatest(
            [
                this.onPageEventChanged$,
                this.onSortChanged$,
                this.route.params.pipe(map(params => params['filter'])).pipe(tap(() => this.resetFirstAndLastItem())),
                // this.route.params.pipe(map(params => params['filter'])),
                // this.msaServiceOld.onCreatedDateAndTimeRangeChanged$
            ]
        ).pipe(
            tap(() => {
                console.log('loading ...');
                this.isLoading = true;
                this._changeDetectorRef.markForCheck();
            }),
            switchMap(([pageEvent, sort, filter]:
                           [PageEvent, Sort, string]) => {

                const queryConstraintList: QueryConstraint[] = [];
                const limitConstraintList: QueryConstraint[] = [];
                if (this.accessLayer === ExpensesAccessLayerOptions.PROPERTY) {
                    queryConstraintList.push(where('property.id', '==', this.property?.id));
                }
                console.table([{
                    'pageEvent': pageEvent,
                    'sort': sort,
                }]);

                if (sort) {
                    // this.page = this.defaultPage;
                    // for paginating --> when the list already initialized
                    if (sort?.active === 'date') {
                        queryConstraintList.push(orderBy(sort?.active, sort?.direction === 'asc' ? 'asc' : 'desc'));
                        limitConstraintList.push(limit(pageEvent?.pageSize));
                    } else {
                        queryConstraintList.push(orderBy(sort?.active, sort?.direction === 'asc' ? 'asc' : 'desc'));
                        queryConstraintList.push(orderBy('date', 'desc'));
                        limitConstraintList.push(limit(pageEvent?.pageSize));
                    }
                } else {
                    // for paginating --> when the list already initialized
                    queryConstraintList.push(orderBy('date', 'desc'));
                    limitConstraintList.push(limit(pageEvent?.pageSize));
                }

                console.log("buchungskonten filter = ", filter)
                switch (filter) {
                    case 'umlagefaehig':
                        queryConstraintList.push(where('nahausBookingsAccount.isAllocatable', '==', true));
                        break;
                    case 'steuerrelevant':
                        queryConstraintList.push(where('nahausBookingsAccount.taxRelevant', '==', true));
                        break;
                    case 'abschreibungrelevant':
                        queryConstraintList.push(where('nahausBookingsAccount.depreciationRelevant', '==', true));
                        break;
                }

                // if (status) {
                //     queryConstraintList.push(where('status', '==', status));
                //     // query = query.where('status', '==', status);
                // }


                if (pageEvent.pageIndex < pageEvent.previousPageIndex) {
                    // get previous page
                    console.log('get previous page', this.firstDocMap);
                    if (this.firstDocMap) {
                        limitConstraintList.push(startAt(this.firstDocMap.get(pageEvent.pageIndex)));
                        // query = query.startAt(this.firstDocMap.get(pageEvent.pageIndex));
                    }
                } else {
                    // get next page
                    console.log('get next page', this.lastItem);
                    if (this.lastItem) {
                        limitConstraintList.push(startAfter(this.lastItem));
                        // query = query.startAfter(this.lastItem);
                    }
                }


                const collectionQuery = this.expensesModularService.collection();
                const ref = query(collectionQuery, ...queryConstraintList, ...limitConstraintList);
                const refCount = query(collectionQuery, ...queryConstraintList);

                getCountFromServer(refCount)
                    .then((res) => {
                        const count = res.data().count || 0;
                        console.log('getCountFromServer --> ', res, count);
                        this.page.length = count;
                    }).catch((err) => {
                    console.error('Error in getCountFromServer --> ', err);
                });

                return collectionSnapshots(ref).pipe(takeUntil(this._unsubscribeAll));
            }))
            .pipe(
                takeUntil(this._unsubscribeAll),
                catchError((error: any) => {
                    console.error('Error: ', error);
                    this.router.navigate(['fehler', '404']);
                    return error;
                }))
            .subscribe((data: QueryDocumentSnapshot<Transaction>[]) => {
                console.log('data response', data);

                if (!this.initialLoad) {
                    this.initialLoad = true;
                    this.expenseExists = data?.length > 0;
                    console.log("expenseExists" + this.expenseExists);
                }

                if (data.length === 0) {
                    //this.openYoutubeDialog();
                    this.dataSource = new MatTableDataSource();
                    this.firstDocMap = new Map<number, any>();
                    this.lastItem = null;
                    this.isLoading = false;
                    this._changeDetectorRef.markForCheck();
                    return;
                }

                const list = [];
                data.forEach((action) => {
                    // list.push(convertTimestamps(action.payload.doc.data()));
                    list.push(action.data());
                });
                // this.transactionsModularService.transactionsList=list;
                this.dataSource = new MatTableDataSource(list);
                this.firstDocMap.set(this.page?.pageIndex ? this.page?.pageIndex : 0, data[0]);
                this.lastItem = data[data.length - 1];
                console.log('loading done', this.dataSource.data);
                this.isLoading = false;
                this._changeDetectorRef.markForCheck();
            });
    }

    resetFirstAndLastItem(): void {
        this.firstDocMap = new Map<number, any>();
        this.lastItem = null;
    }

    sortMsaData($event: Sort): void {
        console.log('sortData:', $event);
        this.resetFirstAndLastItem();
        this.page = {pageSize: this.page?.pageSize, length: this.page?.length, pageIndex: 0};
        this.onPageEventChanged$.next(this.page);
        this.onSortChanged$.next($event);
    }

    goTo(expense: Expense): void {
        // this.selectedLandlord = this.lls.landlord = landlord;
        // Get the current activated route
        let route = this.route;
        while (route.firstChild) {
            route = route.firstChild;
        }

        if (this.expensesModularService?.selected?.id !== expense?.id) {
            this.expensesModularService.selected = expense;
        }
        // Go to expense
        this.router.navigate([expense?.id], {relativeTo: this.route});

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    onNewStat($event: SearchState): void {
        setTimeout(() => {
            this.searchState = $event;
            this._changeDetectorRef.markForCheck();
            // this._changeDetectorRef.detectChanges();
        });
        console.log('search state = ', this.searchState);
    }

    onNewHits(hits: Hit[] | Expense[]): void {
        console.log('on new msa hits', hits);
        this.hitsSource = new MatTableDataSource<Expense>(hits as Expense[]);
        // this.landlordHits$.next(hits as Landlord[]);
        // this.msa = hits as Landlord[];
        this._changeDetectorRef.markForCheck();
        this._changeDetectorRef.detectChanges();
    }

    onPageChanged($event: PageEvent): void {
        if (this.page?.pageSize !== $event?.pageSize) {
            this.resetFirstAndLastItem();
        }
        if (this.defaultPageSize !== $event?.pageSize) {
            this.defaultPageSize = $event.pageSize;
        }
        this.onPageEventChanged$.next($event);
        this.page = $event;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows(): void {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.dataSource.data);
        console.log('selection', this.selection);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Expense, i?: number): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${i + 1}`;
    }

    openYoutubeDialog(force = false) {

        if (!this.openYoutube && !force) {
            return;
        }

        const data: YoutubeDialogData = {
            videoID: 'tTtOVOhiSnQ', // Ersetzen Sie dies durch die tatsächliche Video-ID Ihres Ausgabenverwaltungsvideos
            title: 'Willkommen bei der Ausgabenverwaltung',
            subtitle: 'Schauen Sie sich dieses kurze Video an, um zu erfahren, wie Sie Ausgaben erfassen und verwalten können'
        };


        this.dialog.open(YoutubeDialogComponent, {
            autoFocus: false,
            disableClose: true,
            data,
            panelClass: 'fuse-confirmation-dialog-panel',
            maxHeight: '90vh'
        }).afterClosed().pipe(take(1)).subscribe((res: YoutubeDialogResult) => {
            if (res === 'IGNORE') {
                this.openYoutube = false;
            }

            if (res === 'CONTINUE') {
                // this.addNewLandlord();
            }
        });
    }
}
