// import { canActivate, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import {emailVerified, redirectLoggedInTo, redirectUnauthorizedTo} from '@angular/fire/auth-guard';
import {AuthPipe} from '@angular/fire/auth-guard/auth-guard';
import {canActivate} from '@angular/fire/compat/auth-guard';
import {Route, Routes} from '@angular/router';
import {ComingSoonGuard} from '@coming-soon/guards/coming-soon.guard';
import {environment} from '@env/environment';
import {CanAccessHandoversGuard} from '@handover/guards/can-access-handovers.guard';
import {CanAccessMetersGuard} from '@meters/guards/can-access-meters.guard';
import {CanAccessMsaGuard} from '@msa/guards/can-access-msa.guard';
import {BannedToUpgradeGuard} from '@quota/guards/banned-to-upgrade.guard';
import {IsSubscriberCustomerGuard} from '@shared/guards/is-subscriber-customer.guard';
import {CanAccessUsersGuard} from '@users/guards/can-access-users.guard';
import {CanAccessVacanciesGuard} from '@vacancies/guards/can-access-vacancies.guard';
import {InitialDataResolver} from 'app/app.resolvers';
import {LayoutComponent} from 'app/layout/layout.component';
import {pipe} from 'rxjs';
import {map} from 'rxjs/operators';
import {IsMasterUserGuard} from './guards/is-master-user.guard';
import {PermissionsGuard} from './guards/permissions.guard';
import {CanAccessContractsGuard} from './modules/admin/contracts/guards/can-access-contracts.guard';
import {CanAccessLettersGuard} from './modules/admin/letters/guards/can-access-letters.guard';
import {PermissionOptions} from './modules/admin/users/models/user.interface';
import {AccessLayerOptions} from "./modules/admin/booking/booking.routing";
import {DigitalProtocolAccessLayerOptions} from "./modules/admin/digital-protocol/digital-protocol.routing";
import {GraduatedRentService} from "./modules/admin/graduated-rent/services/graduated-rent.service";
import {provideTranslocoScope, TRANSLOCO_SCOPE} from "@ngneat/transloco";
import {LetterTemplatesService} from "@letters/services/letterTemplates.service";
import {TasksService} from "./modules/admin/tasks/services/tasks.service";
import {TaskTagsService} from "./modules/admin/tasks/services/task-tags.service";
import {MsaService} from "@msa/services/msa.service";
import {MsaOldService} from "@msa/services";
import {MsaStatsService} from "@msa/services/msa-stats.service";
import {ContractsService} from "./modules/admin/contracts/services/contracts.service";
import {MsaResolver} from "@msa/resolvers/msa.resolver";
import {StatsMsaResolver} from "@msa/resolvers/stats-msa.resolver";
import {StatsService} from "./modules/admin/stats/services/stats.service";
import {TenantsService} from "@tenants/services/tenants.service";
import {TenantResolver} from "@tenants/resolvers/tenant.resolver";
import {TenantsModularService} from "@tenants/services/tenants-modular.service";
import {TenantsSearchActivitiesService} from "@tenants/services/tenants-search-activities.service";
import {CanDeactivateTenantDetails} from "@tenants/guards/tenants.guard";
import {UsersQuotaGuard} from "@users/guards/users-quota.guard";
import {BUCKET} from "@angular/fire/compat/storage";
import {TransactionsService} from "@transactions/services/transactions.service";
import {TransactionsModularService} from "@transactions/services/transactions-modular.service";
import {TransactionsActivitiesService} from "@transactions/services/transactions-activities.service";
import {TransactionResolver} from "@transactions/resolvers/transaction.resolver";
import {TransactionsStatsService} from "@transactions/services/transactions-stats.service";
import {BookingService} from "./modules/admin/booking/services/booking.service";
import {BookingPaymentsService} from "./modules/admin/booking/services/bookingPayments.service";
import {BoardService} from "./modules/admin/scrumboards/services/board.service";
import {ReminderService} from "./modules/admin/reminders/services/nahausReminders.service";
import {NahausReminderModularService} from "./modules/admin/reminders/services/nahausReminders-modular.service";
import {ReminderResolver} from "./modules/admin/reminders/resolvers/reminder.resolver";
import {BoardsResolver} from "./modules/admin/scrumboards/resolvers/boards.resolver";
import {BoardResolver} from "./modules/admin/scrumboards/resolvers/board.resolver";
import {CardResolver} from "./modules/admin/scrumboards/resolvers/card.resolver";
import {BoardListResolver} from "./modules/admin/scrumboards/resolvers/list.resolver";
import {CardsService} from "./modules/admin/scrumboards/services/cards.service";
import {BoardOldService} from "./modules/admin/scrumboards/services/boardService.service";
import {ScrumboardImagesService} from "./modules/admin/scrumboards/services/scrumboard-images.service";
import {BoardsModularService} from "./modules/admin/scrumboards/services/boards-modular.service";
import {NoteService} from "./modules/admin/notes/services/note.service";
import {LabelsService} from "./modules/admin/notes/services/labels.service";
import {ContactsService} from "./modules/admin/contacts/services/contacts.service";
import {ContactsStatService} from "./modules/admin/contacts/services/contacts-stat.service";
import {StatContactsResolver} from "./modules/admin/contacts/resolvers/stat-contacts.resolver";
import {ContactResolver} from "./modules/admin/contacts/resolvers/contact.resolver";
import {ContactsTagsService} from "./modules/admin/contacts/services/contactsTags.service";
import {ContactsModularService} from "./modules/admin/contacts/services/contacts-modular.service";
import {SharedDefaultTagsService} from "./modules/admin/contacts/services/shared-default-tags.service";
import {ContractsModularService} from "./modules/admin/contracts/services/contracts-modular.service";
import {ContractsResolver} from "./modules/admin/contracts/resolvers/contracts-resolver.service";
import {MsaListForContractResolver} from "./modules/admin/contracts/resolvers/msa-list-for-contract.resolver";
import {CreateContractDraftGuard} from "./modules/admin/contracts/guards/create-contract-draft.guard";
import {DirtyCheckGuard} from "./modules/admin/contracts/guards/dirty-check.guard";
import {DirtyCheckGarageContractGuard} from "./modules/admin/contracts/guards/dirty-check-garage-contract.guard";
import {RegistrationStore} from "@meters/store/registration.store";
import {PropertyResolver} from "./modules/admin/properties/resolvers/property.resolver";
import {
    PropertyByIdFromParentDeep1Resolver
} from "./modules/admin/properties/resolvers/property-by-id-from-parent-deep1.resolver";
import {
    UnitsResolverDeep1Resolver
} from "./modules/admin/properties/features/units/resolvers/units-resolver-deep1.resolver";
import {ManagementByIDResolver} from "./modules/admin/managers/resolvers/managementByID.resolver";
import {
    PropertyPreviousPeriodResolver
} from "./modules/admin/properties/features/units/features/period/features/previous-period/resolvers/property-previous-period.resolver";
import {
    UnitPreviousPeriodResolver
} from "./modules/admin/properties/features/units/features/period/features/previous-period/resolvers/unit-previous-period.resolver";
import {
    PeriodPreviousPeriodResolver
} from "./modules/admin/properties/features/units/features/period/features/previous-period/resolvers/period-previous-period.resolver";
import {
    PropertyFormParentRouteResolver
} from "./modules/admin/properties/features/units/features/period/resolvers/property-form-parent-route.resolver";
import {
    UnitFromParentRouteResolver
} from "./modules/admin/properties/features/units/features/period/resolvers/unit-from-parent-route.resolver";
import {
    PeriodFromParentRouteResolver
} from "./modules/admin/properties/features/units/features/period/resolvers/period-from-parent-route.resolver";
import {TimeAgoPipe} from "ngx-moment";
import {
    BookingAccountsForPeriodResolverResolver
} from "./modules/admin/booking/components/booking-accounts-dashboard/resolvers/booking-accounts-for-period-resolver.resolver";
import {BookingAccountService} from "./modules/admin/booking/services/bookingAccount.service";
import {ExpensesAccessLayerOptions} from 'app/modules/admin/expenses/expenses.routing';

import 'moment/locale/de';
import {registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';
import {Settings} from 'luxon'

registerLocaleData(localeDe);

// Global
Settings.defaultLocale = 'de-DE'
// const adminOnly = () => hasCustomClaim('admin');
const redirectUnauthorizedToLogin = (): AuthPipe => redirectUnauthorizedTo(['anmelden']);
const redirectUnauthorizedTo404 = (): AuthPipe => redirectUnauthorizedTo(['fehler/404']);
const redirectLoggedInToMainApp = (): AuthPipe => redirectLoggedInTo(['immobilien']);
const redirectUnverifiedTo = (redirect: any[]): any => pipe(emailVerified, map(emailVerified => emailVerified || !environment.production || redirect));
const redirectUnverifiedEmailsToConfirm = (): AuthPipe => redirectUnverifiedTo(['bestaetigung-erforderlich']);
// const isEmailVerified = () => emailVerified();
// const adminOnly = () => hasCustomClaim('admin');

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

  // Redirect empty path to '/dashboards/project'
  { path: '', pathMatch: 'full', redirectTo: 'dashboards' },

  // Redirect signed in user to the '/dashboards/project'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboards' },

  // Auth routes for guests
  {
    path: '',
    // canActivate: [NoAuthGuard],
    // canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      // {
      //   path: 'bestaetigung-erforderlich',
      //   loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routing'),
      // },
      {
        path: 'forgot-password',
        loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routing'),
      },
      {
        path: 'referral-sign-in',
        loadChildren: () => import('app/modules/auth/referral-sign-in/referral-sign-in.routing'),
        ...canActivate(redirectLoggedInToMainApp),
      },
      {
        path: 'anmelden',
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.routing'),
        ...canActivate(redirectLoggedInToMainApp),
      },
      {
        path: 'abmelden',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.routing'),
      },
      {
        path: 'registrieren',
        loadChildren: () => import('app/modules/auth/sign-up/sign-up.routing'),
        // data: {
        //   // This 👇 will be used as the page seo data
        //   NgaoxSeo: {
        //     title: 'Registrieren Sie sich jetzt bei Nahaus für eine effektive Immobilienverwaltung',
        //     description: 'Nahaus Immobilienverwaltung Software bietet eine leistungsstarke und sichere Immobiliencloud-Lösung. Verwalten Sie Ihre Immobilien einfach und effizient online und behalten Sie stets den Überblick. Registrieren Sie sich jetzt kostenlos!',
        //     siteName: 'Nahaus.de'
        //   }
        // }
      },
      // {
      //   path: 'unlock-session',
      //   loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule),
      // },
      {
        path: 'willkommen',
        loadChildren: () => import('app/modules/auth/welcome/welcome.routing'),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      // {
      //   path: 'home',
      //   loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule),
      //   canLoad: [ComingSoonGuard]
      // },
      // Pages without authentication
      {
        path: 'konto-geloescht',
        loadChildren: () => import('app/modules/admin/pages/account-deleted/account-deleted.routing'),
      },
      {
        path: 'coming-soon',
        loadChildren: () => import('app/modules/admin/pages/coming-soon/coming-soon.routing')
      },
    ]
  },

  // Admin routes
  {
    path: '',
    canActivate: [ComingSoonGuard, BannedToUpgradeGuard],
    ...canActivate(redirectUnauthorizedToLogin),
    // ...canActivate(redirectUnverifiedEmailsToConfirm),
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver
    },
    children: [
      // SETTINGS
      {
        path: 'einstellungen',
        loadChildren: () => import('app/modules/admin/settings/settings.routing'),
      },
      {
        path: 'abonnement',
        loadChildren: () => import('app/modules/admin/subscriptions/subscription.routing'),
        canLoad :[IsMasterUserGuard],
        canActivate :[IsMasterUserGuard],
      },
      // CORE FEATURES
      {
        path: 'dashboards',
        loadChildren: () => import('./modules/admin/dashboards/main/project.routing'),
        // canLoad:[BannedToUpgradeGuard],
          canActivate:[BannedToUpgradeGuard]
        // canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
      },
      {
        path: 'immobilien',
        loadChildren: () => import('app/modules/admin/properties/properties.routing'),
        canLoad: [BannedToUpgradeGuard, IsSubscriberCustomerGuard, PermissionsGuard],
        canActivate: [BannedToUpgradeGuard, IsSubscriberCustomerGuard, PermissionsGuard],
        // canActivateChild: [PermissionsGuard],
        data: {
          permission: PermissionOptions.PROPERTIES_READ
        },
          providers:[
              PropertyResolver,
              PropertyByIdFromParentDeep1Resolver,
              UnitsResolverDeep1Resolver,
              ManagementByIDResolver,
              PropertyPreviousPeriodResolver,
              UnitPreviousPeriodResolver,
              PeriodPreviousPeriodResolver,
              PropertyFormParentRouteResolver,
              UnitFromParentRouteResolver,
              PeriodFromParentRouteResolver,
              BookingAccountService,
              BookingAccountsForPeriodResolverResolver,
              BoardService,
              TimeAgoPipe,
              BoardsModularService,
          ]
      },
      {
        path: 'eigentuemer',
        // loadChildren: () => import('app/modules/admin/landlords/landlords.module').then(m => m.LandlordsModule),
        loadChildren: () => import('app/modules/admin/landlords/landlords.routing'),
        data: {
          permission: PermissionOptions.LANDLORDS_READ
        },
        canLoad: [BannedToUpgradeGuard, IsSubscriberCustomerGuard],
        canActivate: [BannedToUpgradeGuard, IsSubscriberCustomerGuard],
      },
      {
        path: 'verwalter', // managers
        loadChildren: () => import('app/modules/admin/managers/managers.routing'),
        canLoad: [BannedToUpgradeGuard, IsSubscriberCustomerGuard], // IsOwnerOrAdminGuard
        canActivate: [BannedToUpgradeGuard, IsSubscriberCustomerGuard], // IsOwnerOrAdminGuard
      },

      // ############# VERMIETUNG #############

      // BASIC FEATURES
      {
        path: 'msa',
        redirectTo: 'mieterselbstauskunft'
      },
      {
        path: 'mieterselbstauskunft',
        loadChildren: () => import('app/modules/admin/msa/msa.routing'),
        data: { permission: PermissionOptions.MSA_READ },
        canLoad: [BannedToUpgradeGuard, CanAccessMsaGuard, PermissionsGuard],
        canActivate: [BannedToUpgradeGuard, CanAccessMsaGuard, PermissionsGuard],
        providers: [
            MsaService,
            MsaOldService,
            MsaStatsService,
            ContractsService,
            MsaResolver,
            StatsService,
            StatsMsaResolver,
            {provide: TRANSLOCO_SCOPE, useValue: 'msa'}
        ]
      },
      {
        path: 'mietersuche',
        loadChildren: () => import('app/modules/admin/tenants/tenants.routing'),
        data: { permission: PermissionOptions.TENANTS_READ },
        canLoad: [BannedToUpgradeGuard, PermissionsGuard],
        canActivate: [BannedToUpgradeGuard, PermissionsGuard],
          providers:[
              TenantsService,
              TenantResolver,
              TenantsModularService,
              TenantsSearchActivitiesService,
              CanDeactivateTenantDetails,
          ]
      },
      {
        path: 'leerstand',
        loadChildren: () => import('app/modules/admin/vacancies/vacancies.routing'),
        data: { permission: PermissionOptions.VACANCIES_READ },
        canLoad: [BannedToUpgradeGuard, CanAccessVacanciesGuard, PermissionsGuard],
        canActivate: [BannedToUpgradeGuard, PermissionsGuard],
          providers: [
              {provide: TRANSLOCO_SCOPE, useValue: 'vacancies'}
          ]
      },
      {
        path: 'ein-auszug',
        loadChildren: () => import('app/modules/admin/handover/handover.routing'),
        data: { permission: PermissionOptions.PERIODS_READ },
        canLoad: [BannedToUpgradeGuard, CanAccessHandoversGuard, PermissionsGuard],
        canActivate: [BannedToUpgradeGuard, PermissionsGuard],
      },
      // PRO FEATURES
      {
        path: 'mietvertraege',
        loadChildren: () => import('app/modules/admin/contracts/contracts.routing'),
        data: { permission: PermissionOptions.CONTRACTS_READ },
        canLoad: [BannedToUpgradeGuard, CanAccessContractsGuard, PermissionsGuard],
        canActivate: [BannedToUpgradeGuard, PermissionsGuard],
          providers: [
              ContractsService,
              ContractsModularService,
              ContractsResolver,
              MsaListForContractResolver,
              CreateContractDraftGuard,
              DirtyCheckGuard,
              DirtyCheckGarageContractGuard,
              // DatePipe,
          ]
      },
      {
        path: 'anschreiben',
        loadChildren: () => import('app/modules/admin/letters/letters.routing'),
        canLoad: [BannedToUpgradeGuard, CanAccessLettersGuard],
        canActivate: [BannedToUpgradeGuard, CanAccessLettersGuard],
          providers: [GraduatedRentService,LetterTemplatesService, provideTranslocoScope('letters')]
        // canActivate: [BannedToUpgradeGuard,CanAccessLettersGuard],
      },

      // ############# BUCHHALTUNG #############
      // CORE FEATURES
      {
        path: 'bankkonten',
        loadChildren: () => import('app/modules/admin/bankAccounts/bank-accounts.routing'),
        data: { permission: PermissionOptions.BANK_ACCOUNTS_READ },
        canLoad: [BannedToUpgradeGuard, IsSubscriberCustomerGuard, PermissionsGuard],
        canActivate: [BannedToUpgradeGuard, IsSubscriberCustomerGuard, PermissionsGuard],
      },

      // ############# ADVANCED FEATURES #############

      // ENTERPRISE FEATURES
      {
        path: 'benutzer',
        loadChildren: () => import('app/modules/admin/users/users.routing'),
        data: { permission: PermissionOptions.USERS_READ },
        canLoad: [BannedToUpgradeGuard, CanAccessUsersGuard, PermissionsGuard],
        canActivate: [BannedToUpgradeGuard, CanAccessUsersGuard, PermissionsGuard],
          providers: [UsersQuotaGuard,{provide: BUCKET, useValue: environment.buckets.main},]
      },
      {
        path: 'zaehler',
        loadChildren: () => import('app/modules/admin/meters/meters.routing'),
        canLoad: [BannedToUpgradeGuard, CanAccessMetersGuard, PermissionsGuard],
        canActivate:[BannedToUpgradeGuard, CanAccessMetersGuard, PermissionsGuard],
        data: { permission: PermissionOptions.METERS_READ },
          providers: [
              RegistrationStore
          ]
      },
      {
        path: 'dienstleistungen',
        loadChildren: () => import('app/modules/admin/serviceProviders/service-providers.routing'),
        // canLoad: [ComingSoonGuard]
      },
      {
        path: 'versicherungen',
        loadChildren: () => import('app/modules/admin/insurance/insurance.routing'),
        // canLoad: [ComingSoonGuard]
      },
      {
        path: 'files',
        loadChildren: () => import('app/modules/admin/file-manager/file-manager.routing'),
        canActivate :[BannedToUpgradeGuard],
      },
      {
        path: 'kontakte',
        loadChildren: () => import('app/modules/admin/contacts/contacts.routing'),
        canActivate :[BannedToUpgradeGuard],
          providers:[
              ContactsService,
              ContactsStatService,
              StatContactsResolver,
              ContactResolver,
              ContactsTagsService,
              ContactsModularService,
              SharedDefaultTagsService,
              {provide: TRANSLOCO_SCOPE, useValue: 'contacts'},
          ]
      },
        {
            path: 'mailbox',
            loadChildren: () => import('app/modules/admin/mailbox/mailbox.routing')
        },
        {
            path: 'chat_intern',
            loadChildren: () => import('app/modules/admin/chat/chat.routes')
        },
        {
          path: 'chat',
          loadChildren: () => import('app/modules/admin/chatAI/chat.routes')
        },

      // {
      //   path: 'vermoegen',
      //   loadChildren: () => import('app/modules/admin/assets/assets.module').then(m => m.AssetsModule)
      // },

      // pricing
      {
        path: 'preise',
        loadChildren: () => import('app/modules/admin/pages/pricing/modern/modern.routing'),
        canLoad :[IsMasterUserGuard],
        canActivate :[IsMasterUserGuard],
      },
      {
        path: 'upgrade',
        loadChildren: () => import('app/modules/admin/pages/pricing/modern/modern.routing'),
        canLoad :[IsMasterUserGuard],
        canActivate :[IsMasterUserGuard],
      },
      {
        path: 'abonnement/bestaetigt/BASIC',
        redirectTo:'abonnement/bestaetigt/basic',
      },
      {
        path: 'abonnement/bestaetigt/basic',
        loadChildren: () => import('app/modules/admin/pages/basic-subscription-succeeded/basic-subscription-succeeded.routing'),
      },
      {
        path: 'abonnement/bestaetigt/starter',
        loadChildren: () => import('app/modules/admin/pages/basic-subscription-succeeded/basic-subscription-succeeded.routing'),
      },
      {
        path: 'abonnement/bestaetigt/PRO',
        redirectTo:'abonnement/bestaetigt/pro',
      },
      {
        path: 'abonnement/bestaetigt/pro',
        loadChildren: () => import('app/modules/admin/pages/pro-subscription-succeeded/pro-subscription-succeeded.routing'),
      },
      {
        path: 'abonnement/zahlungsmethode-hinzugefuegt',
        loadChildren: () => import('app/modules/admin/pages/payment-method-added/payment-method-added.routing'),
      },
      {
        path: 'vermieter-bescheinigung',
        loadChildren: () => import('app/modules/admin/landlord-certificate/landlord-certificate.routing'),
        canActivate :[BannedToUpgradeGuard],
      },
      {
        path: 'support',
        loadChildren: () => import('app/modules/admin/help-center/help-center.routing')
      },
        {
            path: 'faqs',
            loadChildren: () => import('app/modules/admin/help-center/faqs/faqs.routing')
        },
      {
        path: 'staffelmiete',
        loadChildren: () => import('app/modules/admin/graduated-rent/graduated-rent.routing'),
        canActivate :[BannedToUpgradeGuard],
      },
      {
        path: 'transaktionen',
        loadChildren: () => import('app/modules/admin/transactions/transactions.routing'),
        canActivate :[BannedToUpgradeGuard],
          providers:[
              TransactionsService,
              TransactionsModularService,
              TransactionsActivitiesService,
              TransactionResolver,
              TransactionsStatsService,
              BookingService,
              BookingPaymentsService,
          ]
      },
        {
            path: 'mietverhaeltnisse',
            loadChildren: () => import('app/modules/admin/root-periods/root-periods.routing'),
        },
      {
        path: 'aufgaben',
        loadChildren: () => import('app/modules/admin/tasks/tasks.routing'),
        canActivate :[BannedToUpgradeGuard],
          providers: [
              TasksService,
              TaskTagsService,
              {provide: TRANSLOCO_SCOPE, useValue: 'tasks'}
          ]
      },
      {
        path: 'buchungen',
        loadChildren: () => import('app/modules/admin/booking/booking.routing'),
        data : {
            accessLayer: AccessLayerOptions.ACCOUNT
        },
        canActivate :[BannedToUpgradeGuard],
      },
      {
        path: 'buchungen/op',
        loadChildren: () => import('app/modules/admin/booking/booking.routing'),
        data : {
            accessLayer: AccessLayerOptions.ACCOUNT
        },
        canActivate :[BannedToUpgradeGuard],
      },
      {
        path: 'notes',
        loadChildren: () => import('app/modules/admin/notes/notes.routing'),
          providers: [
              NoteService,
              LabelsService,
              {provide: TRANSLOCO_SCOPE, useValue: 'notes'}
          ]
      },
        {
            path: 'empfehlungsbonus',
            loadChildren: () => import('app/modules/admin/pages/affiliates/affiliates.routing')
        },
      {
         path: 'ticketing',
         loadChildren: () => import('app/modules/admin/scrumboards/scrumboards.routing'),
          providers:[
              BoardService,
              CardsService,
              BoardOldService,
              ScrumboardImagesService,
              BoardsModularService,
              BoardsResolver,
              BoardResolver,
              CardResolver,
              BoardListResolver,
              {provide: TRANSLOCO_SCOPE, useValue: 'boards'},
          ]
      },
        {
            path: 'reminders',
            loadChildren: () => import('app/modules/admin/reminders/reminders.routing'),
            providers: [
                BoardService,
                ReminderService,
                NahausReminderModularService,
                ReminderResolver,
                {provide: TRANSLOCO_SCOPE, useValue: 'reminders'},
            ]
        },
        {
            path: 'messages',
            loadChildren: () => import('./modules/admin/messages/messages.routes')
        },
        // {
        //     path: 'anleitungen',
        //     loadChildren: () => import('app/modules/admin/instructions/instructions.module').then(m => m.InstructionsModule)
        // },
        {
            path: 'ausgaben',
            loadChildren: () => import('app/modules/admin/expenses/expenses.routing'),
            data : {
              accessLayer: ExpensesAccessLayerOptions.PERIOD
          },
        },
        {
            path: 'buchungskonten',
            loadChildren: () => import('app/modules/admin/bookingsAccounts/bookingsAccounts.routing'),
        },
        {
            path: 'protokolle',
            loadChildren: () => import('app/modules/admin/digital-protocol/digital-protocol.routing'),
            data : {
                accessLayer: DigitalProtocolAccessLayerOptions.ACCOUNT
            },
            canActivate :[BannedToUpgradeGuard],
        },
        // Invoice
        {
            path: 'invoice',
            children: [
                {
                    path: '', loadChildren: () => import('app/modules/admin/invoice/invoice.routing')
                },
                {
                    path: 'printable',
                    children: [
                        {path: 'compact', loadChildren: () => import('app/modules/admin/invoice/printable/compact/compact.routes')},
                        {path: 'modern', loadChildren: () => import('app/modules/admin/invoice/printable/modern/modern.routes')}
                    ]
                }
            ]},
        {
            path: 'inventory',
            loadChildren: () => import('app/modules/admin/inventory/ecommerce.routes')
        },
        {
            path: 'historie',
            loadChildren: () => import('app/modules/admin/activities/activities.routing'),
            data : {
                accessLayer: AccessLayerOptions.ACCOUNT
            },
        },
      // {
      //   path: 'kalendar',
      //   loadChildren: () => import('app/modules/admin/calendar/calendar.routing')
      // },
      // Error
      {
        path: 'fehler', children: [
          {
            path: '401',
            loadChildren: () => import('app/modules/admin/pages/error/error-401/error-401.routing')
          },
          {
            path: '403',
            loadChildren: () => import('app/modules/admin/pages/error/error-403-access-denied/error-403.routing')
          },
          {
            path: '404',
            loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.routing')
          },
          {
            // 426 - Upgrade required
            path: '426',
            loadChildren: () => import('app/modules/admin/pages/error/error-426/error-426.routing')
          },
          {
            // 427 - Upgrade required - landlords used up
            path: '427',
            loadChildren: () => import('app/modules/admin/pages/error/error-427-landlords-used-up/error-427-landlords-used-up.routing')
          },
          {
            // 428 - Upgrade required - properties used up
            path: '428',
            loadChildren: () => import('app/modules/admin/pages/error/error-428-properties-used-up/error-428-properties-used-up.routing')
          },
          {
            // 429 - Upgrade required - units used up
            path: '429',
            loadChildren: () => import('app/modules/admin/pages/error/error-429-units-used-up/error-429-units-used-up.routing')
          },
          {
            // 430 - Upgrade to PRO required - module is locked
            path: '430',
            loadChildren: () => import('app/modules/admin/pages/error/error-430-upgrade-required/error-430-upgrade-required.routing')
          },
          {
            // 431 - Upgrade to ENTERPRISE required - module is locked
            path: '431',
            loadChildren: () => import('app/modules/admin/pages/error/error-431-upgrade-to-enterprise/error-431-upgrade-to-enterprise.routing')
          },
          {
            // 432 - Upgrade required - users used up
            path: '432',
            loadChildren: () => import('app/modules/admin/pages/error/error-432-users-used-up/error-432-users-used-up.routing')
          },
          {
            path: '500',
            loadChildren: () => import('app/modules/admin/pages/error/error-500/error-500.routing')
          }
        ]
      },


      // 404 & Catch all
      {
        path: '404-not-found',
        pathMatch: 'full',
        loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.routing')
      },
      { path: '**', redirectTo: '404-not-found' }
    ]
  }
] as Routes;
