import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {
    PropertyUnitPeriod
} from '../../../../properties/features/units/features/period/models/property.unit.period.interface';
import {BookingAccount} from '../../../models/bookingAccount';
import {BookingAccountService} from '../../../services/bookingAccount.service';

@Injectable()
export class BookingAccountsForPeriodResolverResolver implements Resolve<BookingAccount[]> {

    constructor(private bookingAccountService: BookingAccountService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BookingAccount[]> {

        const period: PropertyUnitPeriod = route?.parent?.data?.period as PropertyUnitPeriod;
        console.log('BookingAccountsForPeriodResolverResolver --> period --> ', period);
        const propertyID = period?.propertyID;
        const unitID = period?.unitID;
        const periodID = period?.id;

        console.log('BookingAccountsForPeriodResolverResolver --> ', propertyID, unitID, periodID);

        this.bookingAccountService.setParentPathForPeriod(propertyID, unitID, periodID);
        return this.bookingAccountService
            .collection(query => query.where('id', 'in', ['bk', 'deposit', 'hk']))
            .get()
            .pipe(take(1), map((docs) => docs?.docs?.map(d => d.data() as BookingAccount)));
    }
}
