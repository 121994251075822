import {EventEmitter, Injectable} from '@angular/core';

import {Hit} from 'angular-instantsearch/instantsearch/instantsearch';
import {BehaviorSubject} from 'rxjs';
import {SearchState} from '@shared/algolia/directives/stat-listener.directive';

@Injectable({providedIn: 'root'})
export class BoardOldService {
    private  filterState = {
        noMembers: false,
        assignedToMe: false,
        selectedMembers: [],
        noDueDate: false,
        overdue: false,
        dueNextDay: false,
        selectedLabels: []
    };
    
      // EventEmitter to broadcast filter changes
      filterChanged: EventEmitter<any> = new EventEmitter();
    
      // Function to update a specific filter and emit the change
      updateFilter(filter: string, value: any): void {
        if (this.filterState.hasOwnProperty(filter)) {
          if (filter === 'selectedLabels') {
            const currentIndex = this.filterState[filter].indexOf(value);
            if (currentIndex === -1) {
              this.filterState[filter].push(value); // Add label ID if not present
            } else {
              this.filterState[filter].splice(currentIndex, 1); // Remove label ID if present
            }
          } else {
            this.filterState[filter] = value; // For non-array filters
          }
          this.filterChanged.emit(this.filterState);
        }
      }
      
    
      // Function to get the current state of filters
      getFilters(): any {
        return this.filterState;
      }
      currentFilterState() {
        
        return this.filterState;
      }


    onNewHits: EventEmitter<Hit[]> = new EventEmitter<Hit[]>();
    // stat
    onCountChanged$: BehaviorSubject<number> = new BehaviorSubject(null);
    private searchStateSource = new BehaviorSubject<SearchState | null>(null);
    currentSearchState = this.searchStateSource.asObservable();

    updateSearchState(state: SearchState): void {
        this.searchStateSource.next(state);
    }
resetFilter(){
  this.filterState =  {
    noMembers: false,
    assignedToMe: false,
    selectedMembers: [],
    noDueDate: false,
    overdue: false,
    dueNextDay: false,
    selectedLabels: [],
};
   this.filterChanged.emit(this.filterState);
}
    // ACTIONS
//   onExportAsPdfRequested: Subject<void> = new Subject();


//   private _selected: Property;

//   set selected(value: Property) {
//     this._selected = value;
//     this.onSelectedChanged.next(value);
//   }

}
