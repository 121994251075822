import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'extractImageName',
    standalone: true
})
export class ExtractImageNamePipe implements PipeTransform {

  transform(downloadURL: string): string {
    const decodedUrl = decodeURIComponent(downloadURL);
    const pathPart = decodedUrl.split('?')[0];
    const parts = pathPart.split('/');  
    return parts[parts.length-1]
  }

}
