import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {FuseCardComponent} from '@fuse/components/card/card.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgIf} from '@angular/common';

@Component({
    selector: 'app-pdf-loader',
    templateUrl: './pdf-loader.component.html',
    styleUrls: ['./pdf-loader.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule, FuseCardComponent, PdfViewerModule]
})
export class PdfLoaderComponent implements OnInit, OnChanges {

    @Input() url: string;

    loading = true;

    constructor(private cf: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.url?.currentValue !== changes?.url?.previousValue) {
            this.loading = true;
            this.url = changes?.url?.currentValue;
        }
    }

    pageRendered(e: CustomEvent): void {
        console.log('(page-rendered)', e);
        this.loading = false;
        this.cf.markForCheck();
    }

}
