import {Injectable} from '@angular/core';
import {FuseNavigationItem} from '@fuse/components/navigation/navigation.types';
import {FuseVerticalNavigationComponent} from '@fuse/components/navigation/vertical/vertical.component';
import {AuthService} from '@shared/services/auth.service';
import {NahausTier, NahausTiersOptions} from '@users/models/user.interface';
import {Subject, takeUntil} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {getTierBadge} from '../../../app/navigation/nahaus.navigation';

@Injectable({
    providedIn: 'root'
})
export class FuseNavigationService {

    featuresTier: Map<string, NahausTier> = new Map();

    private _componentRegistry: Map<string, any> = new Map<string, any>();
    private _navigationStore: Map<string, FuseNavigationItem[]> = new Map<string, any>();
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(private authService: AuthService) {
        this.featuresTier.set('feature.msa', NahausTiersOptions.STARTER);
        this.featuresTier.set('feature.vacancies', NahausTiersOptions.STARTER);
        this.featuresTier.set('feature.handover', NahausTiersOptions.STARTER);
        this.featuresTier.set('feature.contracts', NahausTiersOptions.PRO);
        this.featuresTier.set('feature.letters', NahausTiersOptions.PRO);
        this.featuresTier.set('feature.meters.list', NahausTiersOptions.PRO);
        this.featuresTier.set('feature.meters.re_registration', NahausTiersOptions.PRO);
        this.featuresTier.set('settings.users', NahausTiersOptions.ENTERPRISE);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register navigation component
     *
     * @param name
     * @param component
     */
    registerComponent(name: string, component: any): void {
        console.log('registerComponent', name, component);
        this._componentRegistry.set(name, component);
        if (name === 'mainNavigation') {
            this.authService.tier$.pipe(takeUntil(this._unsubscribeAll), startWith(this.authService.tier)).subscribe((tier) => {
                // console.log('authService.role$', tier);
                // this.updateBadgeTitle('settings.users', 'mainNavigation', 'test');
                this.updateBadgeForTier(Array.from(this.featuresTier.keys()), 'mainNavigation', tier);
            });
        }
    }

    /**
     * Deregister navigation component
     *
     * @param name
     */
    deregisterComponent(name: string): void {
        this._componentRegistry.delete(name);
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /**
     * Get navigation component from the registry
     *
     * @param name
     */
    getComponent<T>(name: string): T {
        return this._componentRegistry.get(name);
    }

    /**
     * Store the given navigation with the given key
     *
     * @param key
     * @param navigation
     */
    storeNavigation(key: string, navigation: FuseNavigationItem[]): void {
        // Add to the store
        this._navigationStore.set(key, navigation);
    }

    /**
     * Get navigation from storage by key
     *
     * @param key
     */
    getNavigation(key: string): FuseNavigationItem[] {
        return this._navigationStore.get(key) ?? [];
    }

    /**
     * Delete the navigation from the storage
     *
     * @param key
     */
    deleteNavigation(key: string): void {
        // Check if the navigation exists
        if (!this._navigationStore.has(key)) {
            console.warn(`Navigation with the key '${key}' does not exist in the store.`);
        }

        // Delete from the storage
        this._navigationStore.delete(key);
    }

    /**
     * Utility function that returns a flattened
     * version of the given navigation array
     *
     * @param navigation
     * @param flatNavigation
     */
    getFlatNavigation(navigation: FuseNavigationItem[], flatNavigation: FuseNavigationItem[] = []): FuseNavigationItem[] {
        for (const item of navigation) {
            if (item.type === 'basic') {
                flatNavigation.push(item);
                continue;
            }

            if (item.type === 'aside' || item.type === 'collapsable' || item.type === 'group') {
                if (item.children) {
                    this.getFlatNavigation(item.children, flatNavigation);
                }
            }
        }

        return flatNavigation;
    }

    /**
     * Utility function that returns the item
     * with the given id from given navigation
     *
     * @param id
     * @param navigation
     */
    getItem(id: string, navigation: FuseNavigationItem[]): FuseNavigationItem | null {
        for (const item of navigation) {
            if (item.id === id) {
                return item;
            }

            if (item.children) {
                const childItem = this.getItem(id, item.children);

                if (childItem) {
                    return childItem;
                }
            }
        }

        return null;
    }

    /**
     * Utility function that returns the item's parent
     * with the given id from given navigation
     *
     * @param id
     * @param navigation
     * @param parent
     */
    getItemParent(
        id: string,
        navigation: FuseNavigationItem[],
        parent: FuseNavigationItem[] | FuseNavigationItem
    ): FuseNavigationItem[] | FuseNavigationItem | null {
        for (const item of navigation) {
            if (item.id === id) {
                return parent;
            }

            if (item.children) {
                const childItem = this.getItemParent(id, item.children, item);

                if (childItem) {
                    return childItem;
                }
            }
        }

        return null;
    }

    /**
     * Update badge title
     *
     * @param itemId
     * @param navigationName
     * @param title
     */
    updateBadgeTitle(itemId, navigationName, title): void {
        // Get the component -> navigation data -> item
        const navComponent = this.getComponent<FuseVerticalNavigationComponent>(navigationName);

        // Return if the navigation component does not exist
        if (!navComponent) {
            console.error('navComponent not found');
            return null;
        }
        console.info('navComponent', navComponent);

        // Get the navigation item, update the badge and refresh the component
        const navigation = navComponent.navigation;
        const item = this.getItem(itemId, navigation);
        console.info('item', item);
        item.badge = getTierBadge(NahausTiersOptions.ENTERPRISE);
        navComponent.refresh();
    }

    updateBadge(itemId, navigationName, newBadge: { title?: string; classes?: string; }): void {
        // Get the component -> navigation data -> item
        const navComponent = this.getComponent<FuseVerticalNavigationComponent>(navigationName);

        // Return if the navigation component does not exist
        if (!navComponent) {
            console.error('navComponent not found');
            return null;
        }
        console.info('navComponent', navComponent);

        // Get the navigation item, update the badge and refresh the component
        const navigation = navComponent.navigation;
        const item = this.getItem(itemId, navigation);
        console.info('item', item);
        item.badge = newBadge;
        navComponent.refresh();
    }


    /**
     * Update badge title
     *
     * @param itemId
     * @param navigationName
     * @param title
     */
    updateBadgeForTier(itemIds: string[], navigationName: string, tier: NahausTier): void {
        // Get the component -> navigation data -> item
        const navComponent = this.getComponent<FuseVerticalNavigationComponent>(navigationName);

        // Return if the navigation component does not exist
        if (!navComponent) {
            console.error('navComponent not found');
            return null;
        }
        // console.info('navComponent', navComponent);

        // Get the navigation item, update the badge and refresh the component
        const navigation = navComponent.navigation;
        itemIds.forEach(itemID => {
            const item = this.getItem(itemID, navigation);
            // console.info('item', item);
            item.badge = getTierBadge(this.featuresTier.get(itemID), tier);
        });
        navComponent.refresh();
    }

    updateChildren(parentItem: string, navigationName: string, childrenItems: FuseNavigationItem[]): void {
        // Get the component -> navigation data -> item
        const navComponent = this.getComponent<FuseVerticalNavigationComponent>(navigationName);

        // Return if the navigation component does not exist
        if (!navComponent) {
            console.error('navComponent not found');
            return null;
        }
        // console.info('navComponent', navComponent);

        // Get the navigation item, update the badge and refresh the component
        const navigation = navComponent.navigation;
        const item = this.getItem(parentItem, navigation);
        item.children = childrenItems;
        navComponent.refresh();
    }

}

