import {Injectable} from '@angular/core';
import {NahausImage} from '@shared/models/image.interface';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';

@Injectable()
export class ScrumboardImagesService extends AbstractFirestoreService<NahausImage> {

    COLLECTION = 'images';

    constructor(authService: AuthService) {
        super(authService);
    }

    setParentPath(boardId: string, cardId: string): void {
        this.parentPath = `scrumboards/${boardId}/cards/${cardId}`;
    }

}
