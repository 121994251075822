import {Injectable} from '@angular/core';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';
import {Booking} from '../models/booking';

@Injectable()
export class BookingService extends AbstractFirestoreService<Booking> {

    COLLECTION = 'bookings';

    constructor(authService: AuthService) {
        super(authService);
    }

    setParentPath(propertyID: string, unitID: string, periodID: string): void {
        this.parentPath = `properties/${propertyID}/units/${unitID}/periods/${periodID}`;
    }
}
