import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {List} from "../models/scrumboard.models";
import {BoardService} from '../services/board.service';
import {map} from 'rxjs/operators';

@Injectable()
export class BoardListResolver implements Resolve<List[]> {

    constructor(private boardService: BoardService,
                private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<List[]> {
        const boardId = route.params['boardId'];
        return this.boardService.doc(boardId).collection<List>('lists', ref => ref.orderBy('position')).snapshotChanges().pipe(
            map(actions => {
                return actions.map(a => {
                    const data = a.payload.doc.data() as List;
                    const id = a.payload.doc.id;
                    return {id, ...data};
                });
            })
        );
    }
}
