import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { redirectToErrorPage426, redirectToSignOutRoute } from '@nahausStore/actions/app.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@shared/services/auth.service';
import { EMPTY, of, tap } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';


@Injectable()
export class AuthEffects {

  signOut$ = createEffect(() => this.actions$.pipe(
      ofType('[Movies Page] Load Movies'),
      mergeMap(() => of(this.authService.auth.signOut())
        .pipe(
          tap(() => this.authService.reInitSubscription()),
          map(() => (redirectToSignOutRoute())),
          catchError(() => EMPTY)
        ))
    )
  );

  redirectToErrorPage426$ = createEffect(() => this.actions$.pipe(
      ofType('[Auth] user is banned to upgrade'),
      mergeMap(() => of(this.router.navigate(['fehler', '426']))
        .pipe(
          map(() => (redirectToErrorPage426())),
          catchError(() => EMPTY)
        ))
    )
  );

  constructor(private actions$: Actions,
              private router: Router,
              private authService: AuthService) {
  }
}
