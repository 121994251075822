import {Injectable} from '@angular/core';
import {Router} from '@angular/router';


import {AbstractFirestoreModularDocResolver} from "@shared/services/abstracts/AbstractFirestoreModularDocResolver";
import { NahausReminder } from '../models/nahausReminder.interface';
import { NahausReminderModularService } from '../services/nahausReminders-modular.service';


@Injectable()
export class ReminderResolver extends AbstractFirestoreModularDocResolver<NahausReminder> {

    constructor(router: Router, service: NahausReminderModularService) {
        super(router, service);
    }
}
