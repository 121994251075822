import {Pipe, PipeTransform} from '@angular/core';
import {NahausImage} from '@shared/models/image.interface';

export interface NahausImageThumb {
    id?: string,
    downloadURL?: string,
    path?: string
}

export interface NahausImageThumbInterface {
    thumbs: { [size: number]: NahausImageThumb };
}

@Pipe({
    name: 'toThumbObject',
    standalone: true
})

export class ToThumbObjectPipe implements PipeTransform {

    transform(img: NahausImage, size: number): any | undefined {
        return closestImage(img, size);
    }
}

export const closestImage = (img: NahausImage, size: number): any | undefined => {
    if (!img || !img.thumbs) {
        return undefined;
    }
    const sizes = Object.keys(img.thumbs).map(Number).sort((a, b) => a - b);
    const closestSize = sizes.find(s => s >= size) || sizes[0];
    console.log("closestSize", closestSize);
    return img.thumbs[closestSize.toString()];
}
