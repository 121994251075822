import {PublicProfileSettings} from '@settings/models/publicProfile.settings.interface';
import {Dunning} from "../models/dunning.interface";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {Property} from "../../../../properties/models/property.interface";
import {PropertyUnit} from "../../../../properties/features/units/models/unit.interface";
import {Bookings} from "../../../models/booking";
import {convertTimestamp} from "../../../../../../helpers";

export interface PaymentReminderTemplateParams {
    property: Property;
    unit: PropertyUnit;
    publicProfile: PublicProfileSettings;
    dunning: Dunning;
    currencyPipe: CurrencyPipe;
    datePipe: DatePipe;
}

export const FIRST_REMINDER_TEMPLATE = (params: PaymentReminderTemplateParams) => `
<p>Sehr geehrte Damen und Herren,</p>
<p><br></p>
<p>trotz unserer freundlichen Zahlungserinnerung müssen wir leider feststellen, dass die Zahlung für die folgenden offenen Posten noch immer aussteht:</p>
<p><br></p>
<ol>
  ${buildOpList(params.dunning.relatedBookings, params.datePipe, params.currencyPipe)}
  <!-- Fügen Sie weitere Listenelemente für andere offene Posten hinzu -->
</ol>
<p><br></p>
<p>Zum aktuellen Zeitpunkt beläuft sich der ausstehende Gesamtbetrag auf <strong>${params.currencyPipe.transform(params.dunning.amountToPay, 'EUR')}</strong>.</p>
<p><br></p>
${renderFeesAndInterestBlock(params)}
${renderInterestCalculationList(params)}
<p>Wir bitten Sie dringend, diesen Gesamtbetrag umgehend, jedoch spätestens bis zum <strong>${params.datePipe.transform(params.dunning.dueDate as Date, 'shortDate')}</strong>, auf das Ihnen bekannte Konto mit dem Verwendungszweck <strong>H${params?.property?.h_id}/W${params.unit.w_id}</strong> zu überweisen.</p>
<p><br></p>
<p>Bitte beachten Sie, dass bei weiterer Verzögerung zusätzliche Kosten entstehen können und wir gegebenenfalls rechtliche Schritte einleiten müssen.</p>
<p><br></p>
<p>Sollten Sie die Zahlung bereits veranlasst haben oder weitere Fragen haben, kontaktieren Sie uns bitte umgehend, um eventuelle Missverständnisse auszuräumen.</p>
<p><br></p>
<p>Wir danken Ihnen für Ihre umgehende Aufmerksamkeit in dieser Angelegenheit und stehen für Rückfragen gerne zur Verfügung.</p>
<p><br></p>
<p>${params.publicProfile?.greetings || 'Mit freundlichen Grüßen'}</p>
<p><br></p>
<p>${params.publicProfile?.sender || ''}</p>
`.replace(/>\s+</g, '><')
;

const buildOpList = (bookings: Bookings, datePipe: DatePipe, currencyPipe: CurrencyPipe) => {
    if (bookings?.length > 0) {
        const opList = bookings.map(booking => {
            return `<li><strong>${booking?.text || ''} - Fällig am ${datePipe.transform(convertTimestamp(booking.date as Date), 'shortDate')} - Betrag: ${currencyPipe.transform(Math.abs(booking.saldo), 'EUR')}</strong></li>`
        })
        // const a = [1,2,3]
        // const b = ...a;
        return opList.join('');
    }
}

const renderFeesAndInterestBlock = (params: PaymentReminderTemplateParams) => {
    const dunning = params.dunning;
    const block: string[] = [];
    if (dunning?.enableLateFees) {
        block.push(`<p>Zusätzlich möchten wir Sie darauf hinweisen, dass aufgrund der verspäteten Zahlung nun Mahngebühren in Höhe von <strong>${params.currencyPipe.transform(dunning.feesToPay, 'EUR')} ${dunning.enablePaymentDelayInterest ? `sowie Verzugszinsen in Höhe von ${params.currencyPipe.transform(dunning.interestToPay, 'EUR')}` : ''}</strong> angefallen sind. Dies erhöht den Gesamtbetrag, der zu begleichen ist, auf <strong>${params.currencyPipe.transform(dunning.amountToPayInclFeesAndInterest, 'EUR')} inkl. Mahngebühren ${dunning.enablePaymentDelayInterest ? 'und Verzugszinsen' : ''}</strong>.</p>`);
        block.push(`<p><br></p>`);
        return block.join('');
    } else if (!dunning.enableLateFees && dunning.enablePaymentDelayInterest) {
        block.push(`<p>Zusätzlich möchten wir Sie darauf hinweisen, dass aufgrund der verspäteten Zahlung Verzugszinsen in Höhe von <strong>${params.currencyPipe.transform(dunning.interestToPay, 'EUR')}</strong> angefallen sind. Dies erhöht den Gesamtbetrag, der zu begleichen ist, auf <strong>${params.currencyPipe.transform(dunning.amountToPayInclFeesAndInterest, 'EUR')}</strong>.</p>`);
        block.push(`<p><br></p>`);
        return block.join('');
    } else {
        return ``;
    }
}

const renderInterestCalculationList = (params: PaymentReminderTemplateParams) => {
    const dunning = params.dunning;
    const block: string[] = [];

    if (dunning?.enablePaymentDelayInterest && dunning?.bookingsWithInterest?.length > 0) {
        block.push(`<p>Bezüglich der Verzugszinsen möchten wir Sie darüber informieren, dass diese auf Basis eines Zinssatzes von <strong>${dunning?.interestRate}%</strong> pro Jahr berechnet wurden. Die Berechnung erfolgte für jeden offenen Posten wie folgt:</p>`);
        block.push(`<p><br></p>`);
        block.push(`<ol>`);
        for (const bwi of dunning.bookingsWithInterest) {
            block.push(`<li>Sollstellung: <strong>${bwi?.booking?.text}</strong> - Fällig am <strong>${params.datePipe.transform(convertTimestamp(bwi.booking.date as Date), 'shortDate')}</strong> - Betrag: <strong>${params.currencyPipe.transform(Math.abs(bwi.booking.saldo))}</strong> - Überfällige Tage: <strong>${bwi.overdueDays}</strong> - Zinsbetrag: <strong>${params.currencyPipe.transform(Math.abs(bwi.interestAmount))}</strong></li>`)
        }
        block.push(`</ol>`);
        // block.push(`<p><br></p>`);
        block.push(`<!--<p>Die Summe der Verzugszinsen für alle offenen Posten beläuft sich auf <strong>${params.currencyPipe.transform(Math.abs(dunning.interestToPay))}</strong>. Dieser Betrag wurde zum Gesamtbetrag der offenen Posten hinzugerechnet, was zu einem zu begleichenden Gesamtbetrag von [Gesamtbetrag inkl. Verzugszinsen]€ führt.</p>-->`);
        block.push(`<p><br></p>`);
        return block.join('');
    } else {
        return ''
    }
}

//
