import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {
    collectionSnapshots,
    getCountFromServer,
    limit,
    orderBy,
    query,
    startAfter,
    startAt,
    where
} from '@angular/fire/firestore';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSortModule, Sort} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {QueryConstraint} from '@firebase/firestore';
import {fuseAnimations} from '@fuse/animations';
import {FuseConfigService} from '@fuse/services/config-old';
import {LocalStorage} from 'ngx-webstorage';
import {QueryDocumentSnapshot} from 'rxfire/firestore/interfaces';
import {BehaviorSubject, combineLatest, firstValueFrom, Subject, switchMap} from 'rxjs';
import {debounceTime, map, take, takeUntil, tap} from 'rxjs/operators';
import {AppConfig} from '../../../../../core/config/app.config';
import {
    PropertyUnitPeriod
} from '../../../properties/features/units/features/period/models/property.unit.period.interface';
import {PropertyUnit} from '../../../properties/features/units/models/unit.interface';
import {Property} from '../../../properties/models/property.interface';
import {DateTime} from "luxon";
import {Query} from "@angular/fire/compat/firestore";
import {SelectionModel} from "@angular/cdk/collections";
import {FuseConfirmationConfig, FuseConfirmationService} from "@fuse/services/confirmation-old";
import {CurrencyPipe, DatePipe, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';
import moment from "moment";
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {getPublicProfileData} from '@settings/helpers';
import {CustomerLogo} from '@settings/models/logo.settings.interface';
import {PublicProfileSettings} from '@settings/models/publicProfile.settings.interface';
import {MatDrawer} from "@angular/material/sidenav";
import {Hit, InstantSearchConfig} from "angular-instantsearch/instantsearch/instantsearch";
import {environment} from "@env/environment";
import {TransactionsRange} from "@transactions/services/transactions-modular.service";
import {
    YoutubeDialogComponent,
    YoutubeDialogData,
    YoutubeDialogResult
} from "@shared/youtube-dialog/dialog/youtube-dialog.component";
import {FormatAddressPipe, ToDatePipe} from '@shared/pipes';
import {FuseCardComponent} from '@fuse/components/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MouseHoverDirective} from '@shared/directives/mouse-hover.directive';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatChipsModule} from '@angular/material/chips';
import {MatDividerModule} from '@angular/material/divider';
import {
    AlgoliaAutocompleteComponent
} from '@shared/algolia/components/algolia-autocomplete/algolia-autocomplete.component';
import {NgAisConfigureModule, NgAisHitsModule, NgAisInstantSearchModule, NgAisStatsModule} from 'angular-instantsearch';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {DigitalProtocolAccessLayerOptions} from "../../digital-protocol.routing";
import {DigitalProtocolMainComponent} from "../digital-protocol-main/digital-protocol-main.component";
import {DigitalProtocol, ProtocolStatusOptions} from "../../models/digital-protocol.interface";
import {DigitalProtocolModularService} from "../../services/digital-protocol-modular.service";
import {DigitalProtocolService} from "../../services/digital-protocol.service";
import {DigitalProtocolHelperService} from "../../services/digital-protocol-helper.service";
import {
    AlgoliaInstantSearchComponent
} from "@shared/algolia/components/algolia-instant-search/algolia-instant-search.component";
import {SearchState, StatListenerDirective} from "@shared/algolia/directives/stat-listener.directive";
import {HitsListenerDirective} from "@shared/algolia/directives/hits-listener.directive";
import {
    SelectTargetForDigitalProtocolDialog,
    SelectTargetForDigitalProtocolDialogComponent
} from "../seelct-target-for-digital-protocol-dialog/select-target-for-digital-protocol-dialog.component";

@Component({
    selector: 'digital-protocol-list',
    templateUrl: './digital-protocol-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    providers: [DigitalProtocolService, DigitalProtocolModularService],
    standalone: true,
    imports: [NgAisInstantSearchModule, NgAisConfigureModule, DigitalProtocolMainComponent, MatBottomSheetModule, CdkScrollable, NgIf, MatButtonModule, MatIconModule, MatTooltipModule, NgAisInstantSearchModule, AlgoliaAutocompleteComponent, MatDividerModule, MatChipsModule, NgFor, MatProgressSpinnerModule, MatProgressBarModule, MatTableModule, MatSortModule, NgClass, MatCheckboxModule, NgSwitch, NgSwitchCase, NgSwitchDefault, RouterLink, MouseHoverDirective, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule, MatPaginatorModule, FuseCardComponent, CurrencyPipe, DatePipe, ToDatePipe, FormatAddressPipe, AlgoliaInstantSearchComponent, NgAisStatsModule, StatListenerDirective, HitsListenerDirective, NgAisHitsModule]
})
export class DigitalProtocolListComponent implements OnInit, OnDestroy {

    @LocalStorage('BookingsMainSidebarComponent.openYoutube', true)
    openYoutube: boolean;

    @LocalStorage('BookingPeriodDetailsListComponent.defaultPageSize', 10)
    defaultPageSize: number;

    searchState: SearchState;

    matDrawer: MatDrawer;

    now = DateTime.now();

    property: Property;
    unit: PropertyUnit;
    period: PropertyUnitPeriod;
    accessLayer: DigitalProtocolAccessLayerOptions;
    bookingsAccessLayerOptions = DigitalProtocolAccessLayerOptions;

    initialLoad: boolean;
    appTheme: string;
    selection = new SelectionModel<DigitalProtocol>(true, []);
    dataSource: MatTableDataSource<DigitalProtocol>;
    hitsSource: MatTableDataSource<DigitalProtocol>;
    columns: string[] = ['select', 'status', 'name', 'date', 'pdf', 'buttons'];

    selectedProtocol: DigitalProtocol;
    protocolStatusOptions = ProtocolStatusOptions;


    // pagination
    firstDocMap: Map<number, any> = new Map<number, any>();
    lastItem: any;
    page: PageEvent;

    // indicators
    isLoading: boolean = false;
    creating: boolean;
    loadingSaldo: boolean;
    saldo: number;
    totalAmount: number;
    addingPayments: boolean;
    deleting: boolean;

    selectedProperties: Property[] = [];

    // Filters
    onPageEventChanged$: BehaviorSubject<PageEvent>;
    onPropertyFilterChanged$: BehaviorSubject<Property[]> = new BehaviorSubject<Property[]>(null);
    // onStatusFilterChanged$: BehaviorSubject<BookingStatusOptions> = new BehaviorSubject<BookingStatusOptions>(null);
    onSortChanged$: BehaviorSubject<Sort> = new BehaviorSubject<Sort>(null);

    configProperties: InstantSearchConfig;

    publicProfile: PublicProfileSettings;
    logo: CustomerLogo;
    activeUser: boolean;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private route: ActivatedRoute,
        private cf: ChangeDetectorRef,
        private router: Router,
        private digitalProtocolService: DigitalProtocolService,
        private digitalProtocolHelperService: DigitalProtocolHelperService,
        private digitalProtocolModularService: DigitalProtocolModularService,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
        private _fuseConfigService: FuseConfigService,
        private fuseConfirmationService: FuseConfirmationService,
        private digitalProtocolMainComponent: DigitalProtocolMainComponent,
    ) {
        this.configProperties = {
            indexName: environment.algolia.indexName.properties,
            searchClient: this.digitalProtocolService?.authService?.getAlgoliaSearchClient(true),
            routing: true
        };
    }

    /**
     * On init
     */
    ngOnInit(): void {

        this.openDialogToSelectPropertyUnitPeriod();

        this.matDrawer = this.digitalProtocolMainComponent.drawer;

        console.log("BookingPeriodDetailsListComponent --> ", this.route.parent?.parent?.parent?.parent?.parent?.snapshot?.data)
        console.log("BookingPeriodDetailsListComponent --> ", this.route.parent?.parent?.parent?.parent?.snapshot?.data)
        console.log("BookingPeriodDetailsListComponent --> ", this.route.parent?.parent?.parent?.snapshot?.data)
        console.log("BookingPeriodDetailsListComponent --> ", this.route.parent?.parent?.snapshot?.data)
        console.log("BookingPeriodDetailsListComponent --> ", this.route.parent?.snapshot?.data)
        console.log("BookingPeriodDetailsListComponent --> ", this.route?.snapshot?.data)

        this.accessLayer = (this.route.snapshot?.data?.accessLayer || this.route.parent?.snapshot?.data?.accessLayer) as DigitalProtocolAccessLayerOptions || DigitalProtocolAccessLayerOptions.PERIOD;


        console.log("this.accessLayer = ", this.accessLayer)
        this.property = (this.route.snapshot.data?.property || this.route?.parent.snapshot.data?.property) as Property;
        this.unit = (this.route.snapshot?.data?.unit || this.route?.parent.snapshot.data?.unit) as PropertyUnit;
        this.period = (this.route.snapshot?.data?.period || this.route?.parent.snapshot.data?.period) as PropertyUnitPeriod;


        this.logo = (this.route.snapshot.data?.logo || this.route?.parent.snapshot.data?.logo) as CustomerLogo;
        console.log('logo = ', this.logo);
        this.publicProfile = getPublicProfileData((this.route.snapshot.data?.publicProfile || this.route?.parent?.snapshot.data?.publicProfile) as PublicProfileSettings, null);
        this.publicProfile.logo = this.logo;

        console.log('publicProfile = ', this.publicProfile);

        this.activeUser = this.digitalProtocolService?.authService?.isActiveUser;
        this.digitalProtocolService?.authService?.isActiveUser$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isActive) => this.activeUser = isActive);
        console.log('activeUser = ', this.activeUser);


        this.page = {pageSize: this.defaultPageSize, length: this.page?.length, pageIndex: 0};
        this.onPageEventChanged$ = new BehaviorSubject<PageEvent>(this.page);

        console.log('BookingPeriodListComponent --> data --> ', this.property, this.unit, this.period);
        // console.log('AddendumListComponent --> data 2 --> ', this.route.snapshot?.parent?.data);

        if (!(this.property && this.unit && this.period)) {
            console.error('Error no property unit and period found');

            if (this.accessLayer === DigitalProtocolAccessLayerOptions.PERIOD) {
                // go to error page
                // this.router.navigate(['fehler', '404'])
                //     .then(() => console.error('!(this.property && this.unit && this.period) === true --> navigate to error page'));
            }

        }

        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: AppConfig) => {
                console.log('config', config);
                this.appTheme = config?.theme.split('-')[1];
                this.cf.markForCheck();
            });

        this.adaptColumnView();
        this.loadData();

        this.digitalProtocolHelperService.initColumns(this.columns);

        this.digitalProtocolHelperService.columns$.pipe(takeUntil(this._unsubscribeAll)).subscribe(columns => {
            this.columns = columns;
            this.cf.markForCheck();
        });


        // console.log("starting with getNextBatch");
        // this.getNextBatchOfBookings(100)
        //     .then((res) => {
        //         console.log("getNextBatchOfBookings resolved --> ", res);
        //     })
        //     .catch((err) => console.error("Error in getNextBatchOfBookings --> ", err))
        // console.log("done with getNextBatch");

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    onNewStat($event: SearchState): void {
        setTimeout(() => {
            this.searchState = $event;
            this.cf.markForCheck();
        });
        console.log('search state = ', this.searchState);
    }

    onNewHits(hits: Hit[] | DigitalProtocol[]): void {
        console.log('on new properties hits', hits);
        this.hitsSource = new MatTableDataSource<DigitalProtocol>(hits as DigitalProtocol[]);
        // this.landlordHits$.next(hits as Landlord[]);
        // this.msa = hits as Landlord[];
        this.cf.markForCheck();
        this.cf.detectChanges();
    }

    loadData(): void {
        combineLatest([
            this.onSortChanged$,
            this.onPageEventChanged$,
            this.onPropertyFilterChanged$,
            this.digitalProtocolHelperService.onRangeChanged$.pipe(tap(() => this.resetFirstAndLastItem())),
        ]).pipe(
            takeUntil(this._unsubscribeAll),
            debounceTime(250),
            tap(() => {
                console.log('loading ...');
                this.isLoading = true;
                this.cf.markForCheck();
            }),
            switchMap(([sort, pageEvent, selectedProperties, range]: [Sort, PageEvent, Property[], TransactionsRange]) => {

                    console.log("load data queries --> ", sort, selectedProperties, range)

                    const queryConstraintList: QueryConstraint[] = [];
                    const limitConstraintList: QueryConstraint[] = [];

                    if (this.accessLayer === DigitalProtocolAccessLayerOptions.PROPERTY) {
                        queryConstraintList.push(where('propertyID', '==', this.property?.id));
                    }


                    if (selectedProperties?.length) {
                        const propertyIDList = selectedProperties.map(p => p.id);
                        console.log("selectedProperties --> ", selectedProperties);
                        console.log("propertyIDList --> ", propertyIDList);
                        queryConstraintList.push(where('propertyID', 'in', propertyIDList));
                    }


                    // filtering
                    // if (this.onlyUnpaid) {
                    //     queryConstraintList.push(where('paid', '==', false));
                    // }
                    //
                    // if (this.onlyDeposit) {
                    //     queryConstraintList.push(where('art', '==', this.bookingArtOptions.KAUTION));
                    // }
                    //
                    // if (this.statusFilter) {
                    //     queryConstraintList.push(where('status', '==', this.statusFilter));
                    // }


                    if (range && sort?.active !== 'date') {
                        if (range?.start) {
                            queryConstraintList.push(where('date', '>=', range.start));
                        }
                        if (range?.end) {
                            queryConstraintList.push(where('date', '<=', range.end));
                        }
                    }

                    if (sort) {
                        if ((range?.start || range?.end) && sort?.active !== 'date') {
                            queryConstraintList.push(orderBy('date', 'desc'));
                        }
                        queryConstraintList.push(orderBy(sort.active, sort.direction === 'asc' ? "asc" : 'desc'));
                        if (sort.active !== 'date' && !range) {
                            queryConstraintList.push(orderBy('date', 'desc'));
                        }
                    } else {
                        queryConstraintList.push(orderBy('date', 'desc'));
                    }

                    // sorting
                    // queryConstraintList.push(orderBy('date', 'desc'));
                    limitConstraintList.push(limit(pageEvent?.pageSize));

                    // pagination
                    if (pageEvent.pageIndex < pageEvent.previousPageIndex) {
                        // get previous page
                        // console.log('get previous page', this.firstDocMap);
                        if (this.firstDocMap) {
                            limitConstraintList.push(startAt(this.firstDocMap.get(pageEvent.pageIndex)));
                        }
                    } else {
                        // get next page
                        // console.log('get next page', this.lastItem);
                        if (this.lastItem) {
                            limitConstraintList.push(startAfter(this.lastItem));
                        }
                    }

                    const collectionQuery = this.digitalProtocolModularService.collection();
                    const ref = query(collectionQuery, ...queryConstraintList, ...limitConstraintList);
                    const refCount = query(collectionQuery, ...queryConstraintList);

                    getCountFromServer(refCount)
                        .then((res) => {
                            const count = res.data().count || 0;
                            console.log('getCountFromServer --> ', res, count);
                            this.page.length = count;
                        }).catch((err) => {
                        console.error('Error in getCountFromServer --> ', err);
                    });


                    return collectionSnapshots(ref);
                }
            ))
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((bookingsSnaps: QueryDocumentSnapshot<DigitalProtocol>[]) => {
                const bookings = [];
                bookingsSnaps.forEach((action) => {
                    // list.push(convertTimestamps(action.payload.doc.data()));
                    bookings.push(action.data());
                });
                console.log('loaded bookings -> ', bookings);
                // console.log('loaded payments -> ', payments);
                const list = [];
                bookings.forEach((b: DigitalProtocol) => {
                    // const bookingPayments = payments.filter((p) => p?.bookingID === b?.id);
                    list.push(b);
                });

                if (!this.initialLoad) {
                    this.initialLoad = true;
                }

                this.dataSource = new MatTableDataSource<DigitalProtocol>(list);

                if (bookings?.length === 0) {
                    this.openYoutubeDialog();
                    this.firstDocMap = new Map<number, any>();
                    this.lastItem = null;
                } else {
                    this.firstDocMap.set(this.page?.pageIndex ? this.page?.pageIndex : 0, bookingsSnaps[0]);
                    this.lastItem = bookingsSnaps[bookingsSnaps.length - 1];
                }

                this.isLoading = false;
                // Mark for check
                this.cf.markForCheck();
            });
    }

    formatDate(date: Date | any, format: string = 'DD.MM.YYYY'): string {
        return moment(date?.toDate()).format(format).toString();
    }

    resetFirstAndLastItem(): void {
        this.firstDocMap = new Map<number, any>();
        this.lastItem = null;
    }

    onPageChanged($event: PageEvent): void {
        if (this.page?.pageSize !== $event?.pageSize) {
            this.resetFirstAndLastItem();
        }
        if (this.defaultPageSize !== $event?.pageSize) {
            this.defaultPageSize = $event.pageSize;
        }
        this.onPageEventChanged$.next($event);
        this.page = $event;
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item?.id || index;
    }

    openDeleteBookingDialog(booking: DigitalProtocol): void {
        // this.dialog.open(DeleteBookingDialogComponent, {
        //     autoFocus: false,
        //     disableClose: true,
        //     data: booking,
        //     panelClass: 'fuse-confirmation-dialog-panel',
        //     maxHeight: '90vh'
        // });
    }

    /**
     * Close the details
     */
    closeDetails(): void {
        // this.router.navigate(['./'], { relativeTo: this.route });
        this.selectedProtocol = null;
        // this.paramPropertyID = null;
    }

    adaptColumnView(): void {
        switch (this.accessLayer) {
            case DigitalProtocolAccessLayerOptions.ACCOUNT:
                this.columns.splice(4, 0, 'address');
                this.columns.splice(3, 0, 'unitLabel');
                this.cf.markForCheck();
                break;
            case DigitalProtocolAccessLayerOptions.PROPERTY:
                this.columns.splice(3, 0, 'unitLabel');
                this.cf.markForCheck();
                break;
        }
    }

    async getNextBatchOfBookings(limit: number, lastDoc?: any): Promise<any> {
        const snapshot = await firstValueFrom(this.digitalProtocolService.collection((ref) => {
            let query: Query = ref;
            if (lastDoc) {
                console.log("lastDoc exists --> startAfter(lastDoc)")
                query = query.startAfter(lastDoc);
            } else {
                console.warn("lastDoc does not exist");
            }

            query = query.limit(limit);

            return query;
        }).get().pipe(take(1)));

        if (snapshot.empty) {
            console.log("snapshot is empty --> done with querying the collection --> return");
            return;
        } else {
            console.log(`snapshot size = ${snapshot.size}`);

            const lastDocSnap = snapshot.docs[snapshot?.size - 1]; // setting new last doc
            return this.getNextBatchOfBookings(limit, lastDocSnap)
        }
    }

    sortMsaData($event: Sort): void {
        console.log('sortData:', $event);
        this.resetFirstAndLastItem();
        this.page = {pageSize: this.page?.pageSize, length: this.page?.length, pageIndex: 0};
        this.onPageEventChanged$.next(this.page);
        this.onSortChanged$.next($event);
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows(): void {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.dataSource.data);
        console.log('selection', this.selection);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: DigitalProtocol, i?: number): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${i + 1}`;
    }

    requestDeleteSelection(): void {
        if (this.selection?.isEmpty()) {
            console.log("selection is empty --> return");
            return;
        }

        const config: FuseConfirmationConfig = {
            title: 'Löschung bestätigen',
            message: 'Sind Sie sicher, dass Sie die ausgewählte(n) Sollstellung(en) löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden und alle zugehörigen Daten gehen verloren.',
            icon: {
                show: true,
                name: 'mat_outline:delete',
                color: 'warn'
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'Löschen',
                    color: 'warn'
                },
                cancel: {
                    show: true,
                    label: 'Abbrechen'
                }
            },
            dismissible: false
        };

        this.fuseConfirmationService.open(config).afterClosed().pipe(map(result => result === 'confirmed')).subscribe((value) => {
            if (value) {
                this.deleteSelectedBookings();
            }
        });
    }

    deleteSelectedBookings(): void {
        this.deleting = true;
        this.cf.markForCheck();

        const batch = this.digitalProtocolService.batch();

        this.selection.selected.forEach(booking => {
            const docRef = this.digitalProtocolService.docWithParent(`properties/${booking?.propertyID}/units/${booking?.unitID}/periods/${booking?.periodID}`, booking?.id).ref;
            batch.delete(docRef);
        })

        batch
            .commit()
            .then(() => {
                const msg = 'Die ausgewählte(n) Sollstellung(en) wurde(n) erfolgreich gelöscht.';
                this.snackbar.open(msg, "OK", {duration: 5000});
            })
            .catch((err) => {
                console.error("Error while deleting selected booking --> ", err)
                const msg = `Fehler beim Löschen der Sollstellung(en). Bitte versuchen Sie es erneut --> ${err}`;
                this.snackbar.open(msg, "OK", {duration: 5000});
            })
            .finally(() => {
                this.deleting = false;
                this.selection.clear();
                this.cf.markForCheck();
            })

    }

    getPdf(url: string): void {
        if (url) {
            window.open(url, '_blank');
        }
    }

    remove(property: Property): void {
        const index = this.selectedProperties.indexOf(property);

        console.log("index = ", index);

        if (index >= 0) {
            this.selectedProperties.splice(index, 1);
            this.onPropertyFilterChanged$.next(this.selectedProperties);
            this.resetFirstAndLastItem();
        }
    }

    onQueryPropertySuggestionClick(property: Property) {

        const index = this.selectedProperties.indexOf(property);

        if (index < 0) {
            this.selectedProperties.push(property);
            this.onPropertyFilterChanged$.next(this.selectedProperties);
            this.resetFirstAndLastItem();
        }
    }

    openYoutubeDialog(force = false) {
        return;
        if (!this.openYoutube && !force) {
            return;
        }

        const data: YoutubeDialogData = {
            videoID: 'nO_NkZzx4pE',
            title: 'Willkommen beim Sollstellungsmodul',
            subtitle: 'Entdecken Sie in unserem kurzen Video, wie Sie Sollstellungen effizient erstellen und verwalten können'
        };

        this.dialog.open(YoutubeDialogComponent, {
            autoFocus: false,
            disableClose: true,
            data,
            panelClass: 'fuse-confirmation-dialog-panel',
            maxHeight: '90vh'
        }).afterClosed().pipe(take(1)).subscribe((res: YoutubeDialogResult) => {
            if (res === 'IGNORE') {
                this.openYoutube = false;
            }

            if (res === 'CONTINUE') {
            }
        });
    }

    openDialogToSelectPropertyUnitPeriod(): void {
        if (this.accessLayer !== DigitalProtocolAccessLayerOptions.PERIOD) {
            const data: SelectTargetForDigitalProtocolDialog = null;
            this.dialog.open(SelectTargetForDigitalProtocolDialogComponent, {
                autoFocus: false,
                disableClose: true,
                data,
                panelClass: 'fuse-confirmation-dialog-panel',
                maxHeight: '90vh'
            })
        } else {

        }
    }
}
