import {Booking, Bookings, BookingStatus} from "../../../models/booking";
import {Timestamp} from "firebase/firestore";
import {GermanAddress} from "@angular-material-extensions/google-maps-autocomplete";

/**
 * @author Anthony Nahas
 * @since 27.11.23
 * @updated 17.06.24
 * @version 1.2
 */
export interface Dunning {
    id?: string;
    refID?: string; // reference ID - Eine einzigartige ID ermöglicht es Ihnen, jede Mahnung eindeutig zu identifizieren. Dies ist besonders nützlich, wenn Sie viele Mahnungen verwalten müssen. - 4 Stellig
    h_id?: number;
    w_id?: number;
    unitLabel?: string;
    createdAt?: Timestamp | Date;
    updatedAt?: Timestamp | Date;
    date?: Timestamp | Date;
    dueDate?: Timestamp | Date;
    customerID?: string;
    propertyID?: string;
    unitID?: string;
    periodID?: string;
    relatedBookings?: Bookings;
    relatedBookingsIds?: string[];
    bookingsWithInterest?: BookingWithInterest[];
    status?: BookingStatus;
    type?: DunningType;
    amountToPay?: number;
    amountToPayInclFeesAndInterest?: number;
    feesToPay?: number;
    // Mahngebühren
    enableLateFees?: boolean;
    enablePaymentDelayInterest?: boolean;
    interestRate?: number; // number between 0 and 100
    interestToPay?: number;
    lastWarningType?: LastWarningType;
    // pdf
    filePath?: string;
    url?: string;

    address?: GermanAddress;
    tenantsName?: string;

    // auto sync with file manager
    nahausFileID?: string;
}


export enum DunningTypeOptions {
    PAYMENT_REMINDER = "PAYMENT_REMINDER",
    FIRST_REMINDER = "FIRST_REMINDER",
    SECOND_REMINDER = "SECOND_REMINDER",
    FINAL_REMINDER = "FINAL_REMINDER"
}

export type DunningType = DunningTypeOptions;

export interface BookingWithInterest {
    booking: Booking;
    overdueDays: number;
    interestAmount: number;
}

export enum LastWarningTypeOptions {
    FINAL_WARNING = 'FINAL_WARNING',
    FINAL_WARNING_BEFORE_LEGAL_ACTION = 'FINAL_WARNING_BEFORE_LEGAL_ACTION',
    FINAL_WARNING_BEFORE_TERMINATION = 'FINAL_WARNING_BEFORE_TERMINATION'
}

// Zuweisung eines Wertes aus dem Enum
export type LastWarningType =
    LastWarningTypeOptions.FINAL_WARNING
    | LastWarningTypeOptions.FINAL_WARNING_BEFORE_LEGAL_ACTION
    | LastWarningTypeOptions.FINAL_WARNING_BEFORE_TERMINATION;

export const getNextDunningStage = (currentStage: DunningTypeOptions): DunningTypeOptions | null => {
    switch (currentStage) {
        case DunningTypeOptions.PAYMENT_REMINDER:
            return DunningTypeOptions.FIRST_REMINDER;
        case DunningTypeOptions.FIRST_REMINDER:
            return DunningTypeOptions.SECOND_REMINDER;
        case DunningTypeOptions.SECOND_REMINDER:
            return DunningTypeOptions.FINAL_REMINDER;
        case DunningTypeOptions.FINAL_REMINDER:
            return null; // Keine weitere Stufe verfügbar
        default:
            return null;
    }
}
