import {NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';
import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {FuseConfirmationConfig} from '@fuse/services/confirmation/confirmation.types';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {take} from "rxjs";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FuseAlertComponent} from "@fuse/components/alert";

@Component({
    selector: 'nahaus-camera-dialog',
    templateUrl: './camera-dialog.component.html',
    styles: [
        `
            .fuse-confirmation-dialog-panel {

                @screen md {
                    @apply w-128;
                }

                .mat-mdc-dialog-container {

                    .mat-mdc-dialog-surface {
                        padding: 0 !important;
                    }
                }
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, MatButtonModule, MatDialogModule, MatIconModule, NgClass, MatProgressSpinnerModule, MatProgressBarModule, NgForOf, MatTooltipModule, FuseAlertComponent, NgSwitch, NgSwitchCase, NgSwitchDefault],
})
export class CameraDialogComponent implements AfterViewInit, OnDestroy {

    @ViewChild('video') videoElement: ElementRef;
    video: HTMLVideoElement;
    cameras: MediaDeviceInfo[] = [];
    currentStream: MediaStream;
    loading = true; // Initialzustand für das Laden
    isDesktop: boolean = true;
    accessDenied: boolean;

    /**
     * Constructor
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: FuseConfirmationConfig,
                public dialogRef: MatDialogRef<CameraDialogComponent>,
                private breakpointObserver: BreakpointObserver,
                private snackBar: MatSnackBar) {
        this.breakpointObserver.observe([
            Breakpoints.HandsetPortrait,
            Breakpoints.HandsetLandscape
        ]).pipe(take(1)).subscribe(result => {
            this.isDesktop = !result.matches;
        });
    }

    ngAfterViewInit(): void {
        this.video = this.videoElement.nativeElement;
        this.getDevices().then(() => this.setupCamera());
    }

    ngOnDestroy(): void {
        this.stopCurrentStream();
    }

    async setupCamera(cameraId: string = ''): Promise<void> {
        this.accessDenied = false;
        this.stopCurrentStream();

        const constraints = cameraId ? {video: {deviceId: {exact: cameraId}}} : {video: true};

        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            this.currentStream = stream;
            this.video.srcObject = stream;
            this.video.play().catch(error => {
                console.error('Error starting video playback:', error);
                this.loading = false; // Hiding spinner when error occurs
            });
        } catch (error) {
            console.error('Error accessing the camera', error);
            this.loading = false;
            this.accessDenied = true;
            this.showCameraAccessInstructions();
            // this.openSnackBar("Zugriff auf die Kamera wurde verweigert", "Schließen");
        }
    }

    showCameraAccessError() {
        // Sie können hier Ihren Snackbar-Service oder einen Dialog verwenden, um die Anweisungen anzuzeigen
        this.snackBar.open('Kamerazugriff wurde blockiert. Bitte ändern Sie die Berechtigungseinstellungen in Ihrem Browser.', 'Einstellungen öffnen', {
            duration: 10000
        }).onAction().pipe(take(1)).subscribe(() => {
            // Optional: Führen Sie den Benutzer direkt zu den Browser-Einstellungen, wenn möglich
            window.open('chrome://settings/content/siteDetails?site=' + encodeURIComponent(window.location.origin));
        });
    }

    showCameraAccessInstructions() {
        const browser = this.getBrowser();
        let message = '';

        switch (browser) {
            case 'Chrome':
                message = 'Kamerazugriff wurde blockiert. Bitte ändern Sie die Berechtigungseinstellungen in Ihrem Browser. Gehen Sie zu Einstellungen > Datenschutz und Sicherheit > Website-Einstellungen > Kamera, und erlauben Sie den Zugriff für diese Website.';
                break;
            case 'Firefox':
                message = 'Kamerazugriff wurde blockiert. Bitte ändern Sie die Berechtigungseinstellungen in Ihrem Browser. Klicken Sie auf das Schloss-Symbol in der Adressleiste > Berechtigungen > Zugriff auf Kamera erlauben.';
                break;
            case 'Safari':
                message = 'Kamerazugriff wurde blockiert. Bitte ändern Sie die Berechtigungseinstellungen in Ihrem Browser. Gehen Sie zu Safari > Einstellungen > Websites > Kamera und erlauben Sie den Zugriff für diese Website.';
                break;
            case 'Edge':
                message = 'Kamerazugriff wurde blockiert. Bitte ändern Sie die Berechtigungseinstellungen in Ihrem Browser. Klicken Sie auf das Schloss-Symbol in der Adressleiste > Berechtigungen > Kamera, und erlauben Sie den Zugriff.';
                break;
            default:
                message = 'Kamerazugriff wurde blockiert. Bitte ändern Sie die Berechtigungseinstellungen in Ihrem Browser. Bitte überprüfen Sie die Einstellungen Ihres Browsers, um die Kameraberechtigungen zu ändern.';
                break;
        }

        this.snackBar.open(message, 'Schließen', {
            duration: 60000
        });
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
        });
    }

    onCanPlay() {
        this.loading = false; // Verstecken des Spinners, sobald das Video bereit ist
        console.log("on can play --> ", this.loading);
    }


    captureImage() {
        if (!this.loading) {
            const canvas = document.createElement('canvas');
            canvas.width = this.video.videoWidth;
            canvas.height = this.video.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(this.video, 0, 0, canvas.width, canvas.height);
            const imageData = canvas.toDataURL('image/png');
            this.dialogRef.close(imageData);
        }
    }

    async getDevices(): Promise<void> {
        const devices = await navigator.mediaDevices.enumerateDevices();
        this.cameras = devices.filter(device => device.kind === 'videoinput');
    }

    async switchCamera(cameraId: string): Promise<void> {
        this.stopCurrentStream();
        const constraints = {video: {deviceId: {exact: cameraId}}};
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            this.currentStream = stream;
            this.video.srcObject = stream;
            this.loading = false;
        } catch (error) {
            console.error('Error switching the camera', error);
            this.loading = false;
        }
    }

    toggleCamera(): void {
        this.loading = true;
        // Bestimmen Sie die aktuell ausgewählte Kamera
        const currentIndex = this.cameras.findIndex(cam => cam.deviceId === this.currentStream.getVideoTracks()[0].getSettings().deviceId);
        // Finden Sie den Index der nächsten Kamera
        const nextIndex = (currentIndex + 1) % this.cameras.length;
        // Schalten Sie zur nächsten Kamera um
        this.switchCamera(this.cameras[nextIndex].deviceId)
            .then(() => this.loading = false)
            .catch((err) => {
                console.error("Error while toggleCamera() --> ", err);
                this.loading = false;
            })
    }

    getBrowser() {
        const userAgent = navigator.userAgent;

        if (userAgent.match(/chrome|chromium|crios/i)) {
            return 'Chrome';
        } else if (userAgent.match(/firefox|fxios/i)) {
            return 'Firefox';
        } else if (userAgent.match(/safari/i)) {
            return 'Safari';
        } else if (userAgent.match(/edg/i)) {
            return 'Edge';
        } else if (userAgent.match(/opr\//i)) {
            return 'Opera';
        } else {
            return 'Unknown';
        }
    }

    private stopCurrentStream(): void {
        if (this.currentStream) {
            this.currentStream.getTracks().forEach(track => track.stop());
        }
    }

}
