import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {catchError, Observable, of, switchMap} from 'rxjs';
import {ScrumboardService} from "../services/scrumboard.service";
import {List} from "../models/scrumboard.models";
import {IBoard} from '../models/scrumboard.types';
import {BoardService} from '../services/board.service';
import {omit} from 'lodash';
import { AuthService } from '@shared/services/auth.service';

@Injectable()
export class BoardsResolver implements Resolve<IBoard | IBoard[]> {

    lists: List[] = [
        {
            id: this.boardService.createID(),
            title: 'Erfasst',
            description: 'Tickets, die neu erstellt wurden, sei es durch Mieter via QR-Code oder direkt durch Verwalter in der Software, landen zunächst in dieser Spalte. Sie markiert den Startpunkt des Bearbeitungsprozesses und zeigt an, dass die Anfragen zur weiteren Prüfung und Bearbeitung bereitstehen.',
            position: 1,
            boardId: "maintenance",
            cards: [], 
            customerID: this.authService.customerID,
            createdAt : new Date(),
            updatedAt: new Date()

        },
        {
            id: this.boardService.createID(),
            title: 'In Bearbeitung',
            description: 'In diese Spalte werden Tickets verschoben, die aktuell bearbeitet werden. Das umfasst alle Aktivitäten von der ersten Begutachtung bis zur Beauftragung von Reparaturen, was signalisiert, dass an einer Lösung gearbeitet wird.',
            position: 2,
            boardId: "maintenance",
            cards: [],
            customerID: this.authService.customerID,
            createdAt : new Date(),
            updatedAt: new Date()
        },
        {
            id: this.boardService.createID(),
            title: 'Zur Überprüfung',
            description: 'Diese Spalte ist für Tickets vorgesehen, die eine finale Überprüfung benötigen, bevor sie abgeschlossen werden können. Dies kann eine interne Prüfung durch das Verwaltungsteam, eine Abnahme der durchgeführten Arbeiten oder eine letzte Bestätigung durch den Mieter umfassen.',
            position: 3,
            boardId: "maintenance",
            cards: [],
            customerID: this.authService.customerID,
            createdAt : new Date(),
            updatedAt: new Date()
        },
        {
            id: this.boardService.createID(),
            title: 'Abgeschlossen',
            description: 'Für Tickets, deren Mängel behoben wurden und die alle notwendigen Überprüfungsprozesse erfolgreich durchlaufen haben.',
            position: 4,
            boardId: "maintenance",
            cards: [],
            customerID: this.authService.customerID,
            createdAt : new Date(),
            updatedAt: new Date()
        }
        ,
        // {id: this.boardService.createID(), title: 'To do', position: 1, boardId: "maintenance", cards: []},
        // {id: this.boardService.createID(), title: 'In progress', position: 2, boardId: "maintenance", cards: []},
        // {id: this.boardService.createID(), title: 'In review', position: 3, boardId: "maintenance", cards: []},
        // {id: this.boardService.createID(), title: 'Completed', position: 4, boardId: "maintenance", cards: []}
    ];

    constructor(private scrumboardService: ScrumboardService,
                private router: Router,
                private boardService: BoardService , 
                private authService : AuthService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IBoard | IBoard[]> {
        const boardId = 'maintenance';

        // Check if the board with 'maintenance' id exists
        return this.boardService.doc(boardId).valueChanges().pipe(
            switchMap((board: IBoard) => {
                if (board) {
                    // If the board exists, return the existing boards
                    return this.boardService.collection().valueChanges();
                } else {
                    // If the board doesn't exist, generate the default board
                    return this.generateDefaultBoard().pipe(
                        catchError(() => of([])) // Handle errors during board generation
                    );
                }
            })
        );
    }

    generateDefaultBoard(): Observable<IBoard> {
        const maintenanceBoard = {
            id: 'maintenance',
            title: 'Mängel- und Schadensboard',
            description: 'Das Mängel- und Schadensboard ist Ihre zentrale Plattform zur schnellen Erfassung, Organisation und Priorisierung von Mängelanzeigen und Schadensmeldungen. Mit der integrierten QR-Code-Funktion können Mieter über das Mieter Portal Beta direkt Tickets erstellen, die sofort im Board erscheinen. Dies ermöglicht eine effiziente Bearbeitung und fördert die direkte Kommunikation zwischen Mietern und Verwaltung.',
            icon: 'build',
            lastActivity: new Date(),
            lists: this.lists,
            labels: null,
            members: null,
            createdAt: new Date(),
        }
        const formData: IBoard = omit(maintenanceBoard, ['lists']) as IBoard;
        return new Observable<IBoard>((observer) => {
            this.boardService.doc('maintenance').set(formData)
                .then(() => {
                    const boardDoc = this.boardService.doc(formData?.id).collection<List>('lists');
                    if (this.lists) {
                        this.lists.forEach((list) => {
                            const listId = list.id || this.boardService.createID();
                            boardDoc.doc(listId).set(list).then(() => console.log('added to sub collection lists'));
                        });
                    }
                    console.log('Maintenance board added successfully');
                    observer.next();
                    observer.complete();
                })
                .catch((err) => {
                    console.log('Error adding maintenance board', err);
                    observer.error(err);
                });
        });
    }
}
