import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {FuseConfirmationService} from '@fuse/services/confirmation-old';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ResidentialContractDetailsComponent} from '../components/residential-details/residential-details.component';

@Injectable()
export class DirtyCheckGuard implements CanDeactivate<ResidentialContractDetailsComponent> {

  configForm: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder,
              private _fuseConfirmationService: FuseConfirmationService) {
    // Build the config form
    this.configForm = this._formBuilder.group({
      title: 'Mietvertrag löschen',
        message: `Diesen Mietvertrag ist nicht vollständig!
                <span class="font-medium">Möchten Sie den wirklich dauerhaft löschen?</span>`,
      icon: this._formBuilder.group({
        show: true,
        name: 'heroicons_outline:exclamation',
        color: 'warn'
      }),
      actions: this._formBuilder.group({
        confirm: this._formBuilder.group({
          show: true,
          label: 'Löschen',
          color: 'warn'
        }),
        cancel: this._formBuilder.group({
          show: true,
          label: 'Abbrechen'
        })
      }),
      dismissible: false
    });

  }

  canDeactivate(
    component: ResidentialContractDetailsComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (component?.deleting) {
      return true;
    }

    const contractID = component?.contractFormGroup?.get('id').value as string;
    const w_id = component?.contractFormGroup?.get('unit.w_id').value as string;
    console.log('DirtyCheckGuard contractID --> ', contractID);
    console.log('DirtyCheckGuard', w_id);

    if (!w_id) {
      // Open the dialog and save the reference of it
      const dialogRef = this._fuseConfirmationService.open(this.configForm.value);

      // Subscribe to afterClosed from the dialog reference
      return dialogRef.afterClosed().pipe(map((result) => result === 'confirmed'));
    }
    return true;
  }

}
