import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {TransactionsRange} from "@transactions/services/transactions-modular.service";

@Injectable()
export class DigitalProtocolHelperService {

    onRangeChanged$: BehaviorSubject<TransactionsRange> = new BehaviorSubject<TransactionsRange>(null);
    private _columns = new BehaviorSubject<string[]>(null); // Standardauswahl
    columns$ = this._columns.asObservable();

    constructor() {
    }

    updateColumns(columns: string[]) {
        this._columns.next(columns);
    }

    initColumns(columns: string[]) {
        this._columns.next(columns);
    }
}
