import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Property } from '../../../../../models/property.interface';

@Injectable()
export class PropertyFormParentRouteResolver implements Resolve<Property> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Property {
    return route?.parent?.data?.property_and_unit?.property as Property;
  }
}
