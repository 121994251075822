import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractFirestoreDocResolver } from '@shared/services/abstracts/AbstractFirestoreDocResolver';
import { Contract } from '../models/contract';
import { ContractsService } from '../services/contracts.service';


@Injectable()
export class ContractsResolver extends AbstractFirestoreDocResolver<Contract> {

  constructor(router: Router, service: ContractsService) {
    super(router, service);
  }
}
