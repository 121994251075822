import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComingSoonGuard implements CanActivate, CanLoad {

  env = environment;

  constructor(private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.runLogic();
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return !(this.env?.isComingSoon && this.env?.pool && !this.env?.production);
    // return this.goToComingSoon();
    return true;
  }

  runLogic(): boolean | Promise<boolean> {
    // return !(this.env?.isComingSoon && this.env?.pool && !this.env?.production) ? this.goToComingSoon() : true;
    // return this.goToComingSoon();
    return true;
  }

  goToComingSoon(): boolean {
    this.router.navigate(['coming-soon']);
    return false;
  }
}
