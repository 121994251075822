export const ResidentialProtocolSteps = [
    {
        order: 0,
        title: 'Stammdaten',
        subtitle: 'Erfassen Sie alle grundlegenden Informationen für die Übergabe.'
    },
    {
        order: 1,
        title: 'Schlüsselverwaltung',
        subtitle: 'Dokumentieren Sie die Anzahl und Typen der übergebenen Schlüssel.'
    },
    {
        order: 2,
        title: 'Räumlichkeiten prüfen',
        subtitle: 'Erfassen Sie den Zustand und Details aller relevanten Räume.'
    },
    {
        order: 3,
        title: 'Zählerstände erfassen',
        subtitle: 'Dokumentieren Sie die aktuellen Zählerstände und fügen Sie bei Bedarf Fotos hinzu.'
    },
    {
        order: 4,
        title: 'Mängelmanagement',
        subtitle: 'Erfassen Sie Mängel und Schäden und leiten Sie sofortige Maßnahmen ein.'
    },
    {
        order: 5,
        title: 'Mieterdaten überprüfen',
        subtitle: 'Kontrollieren Sie die persönlichen Daten Ihrer Mieter für die abschließende Bestätigung'
    },
    {
        order: 6,
        title: 'Bemerkungen',
        subtitle: 'Fügen Sie hier zusätzliche Anmerkungen oder spezielle Hinweise hinzu.'
    },
    {
        order: 7,
        title: 'Unterschriften',
        subtitle: 'Vollziehen Sie den Abschluss durch die Unterschriften von Vermieter und Mieter.'
    },
];

