import {Injectable} from '@angular/core';
import {Firestore} from '@angular/fire/firestore';
import {AbstractFirestoreModularService} from '@shared/services/abstracts/AbstractFirestoreModularService';
import {AuthService} from '@shared/services/auth.service';
import {NahausReminder} from "../models/nahausReminder.interface";


@Injectable()
export class NahausReminderModularService extends AbstractFirestoreModularService<NahausReminder> {

    COLLECTION = 'reminders';

    constructor(authService: AuthService,
                firestore: Firestore) {
        super(authService, firestore);
    }

}
