import {CustomerLogo} from '@settings/models/logo.settings.interface';
import {PublicProfileSettings} from '@settings/models/publicProfile.settings.interface';
import {cloneDeep} from 'lodash-es';
import {Letter, settings} from "@letters/models/letter.interface";
import {PropertyUnit} from "../../../../properties/features/units/models/unit.interface";
import {Dunning, DunningTypeOptions} from "../models/dunning.interface";
import { addAddress, addBankAccount, addContactInfo, createPublicProfileHeader } from '@settings/components/branding/pdf/profile_pdf';

export interface StandardDunningData {
    content: any;
    dunning: Dunning,
    letter: Letter;
    date: string;
    unit?: PropertyUnit;
    signatureRequired?: boolean;
    settings?: settings;
    tenantsName?: string;
    publicProfile: PublicProfileSettings;
    isActiveUser?: boolean;
}

const buildTenantsNameBlock = (tenantsName: string): any => {
    if (tenantsName) {
        return {
            text: tenantsName,
            // bold: true,
            color: '#000000',
            alignment: 'left'
        };
    } else {
        return {
            text: 'Bitte ergänzen Sie den Namen des Mieters',
            bold: true,
            background: '#ec2323',
            alignment: 'left'
        };
    }
};

const cloneDataContent = (content: any): any => cloneDeep(content) || '';

const buildContent = (dunning: Dunning, publicProfile: PublicProfileSettings, letter: Letter, content: any, date: string, unit?: PropertyUnit, tenantsName?: string): any[] => [
    {
        columns: [
            putLogo(publicProfile?.logo,letter?.settings),
            // renderLogo(),
            createPublicProfileHeader(publicProfile)
        ]
    },
    {
        margin: [0, 30, 0, 5],
        columns: [
            {
                stack: [
                    {
                        text: getMailSignHead(publicProfile),
                        color: '#000000',
                        bold: true,
                        fontSize: 7,
                        alignment: 'left',
                        margin:[0,0,0,12]

                    },
                    // {
                    //   text: 'Wohgemeinschaft',
                    //   // bold: true,
                    //   color: '#333333',
                    //   alignment: 'left'
                    // },
                    buildTenantsNameBlock(tenantsName || unit?.currentPeriod?.tenantsName),
                    {
                        text:  `${unit?.address?.streetName || letter?.property?.address?.streetName} ${unit?.address?.streetNumber ||''} `,
                        // bold: true,
                        // fontSize: 9,
                        color: '#000000',
                        alignment: 'left'
                    },
                    {
                        text: `${unit.address.postalCode || letter?.property?.address?.postalCode} ${unit.address.locality.long || letter?.property?.address?.locality?.long} `,
                        // bold: true,
                        // fontSize: 9,
                        color: '#000000',
                        alignment: 'left'
                    }
                ]
            },
            {
                stack: [
                    {
                        // text: `Unser Zeichen: H${unit?.h_id}/W${unit?.w_id}`,
                        text: `Unser Zeichen: H${letter?.property?.h_id}${unit ? '/W' + unit?.w_id : ''}`,
                        bold: true,
                        color: '#333333',
                        // fontSize: 9,
                        alignment: 'right',
                        width: 100
                    },
                    {
                        text: `Datum: ${date}`,
                        // text: 'Datum: 01.01.01',
                        bold: true,
                        color: '#333333',
                        // fontSize: 9,
                        alignment: 'right',
                        width: 100
                    },
                    {
                        // text: 'Mahnungs-ID',
                        text: `${dunning?.type === DunningTypeOptions.PAYMENT_REMINDER ? 'Referenz-ID' : 'Mahnungs-ID'}: ${dunning?.refID}`,
                        bold: true,
                        // fontSize: 9,
                        alignment: 'right',
                        color: '#333333',
                        width: 100
                    }
                ],
                margin:[0,20,0,0]
            }
        ]
    },
    '\n\n',
    {
        width: '100%',
        text: `${letter?.matter?.title || letter?.content?.subject}`,
        bold: true,
        margin: [0, 40, 0, 0],
        fontSize: 13,
    },
    '\n\n',
    cloneDataContent(content || '')
];

const buildLetter = (data: StandardDunningData): any[] => {
    console.log('buildLetter', data);
    const contentList: any[] = [];
    if (!!data?.unit) {
        console.log('data unit exists in standard letter', data?.unit);
        contentList.push(...buildContent(data?.dunning, data?.publicProfile, data?.letter, data?.content, data?.date, data?.unit, data?.tenantsName));
    } else {
        console.log('data unit exists in standard letter', data?.unit);
        for (let j = 0; j < data?.letter?.units?.length; j++) {
            console.log('iterating over the units of the letters', j);
            contentList.push(...buildContent(data?.dunning, data?.publicProfile, data?.letter, data?.content, data?.date, data.letter.units[j], data?.tenantsName));
            if (j !== data?.letter?.units?.length - 1) {
                contentList.push({text: '', pageBreak: 'before'});
            }
        }
    }

    console.log('contentList', contentList);
    return contentList;
};

const getName = (profile: PublicProfileSettings): string => {
    return !!profile?.companyName ? profile?.companyName : `${profile?.firstName} ${profile?.lastName}`;
};

const getMailSignHead = (profile: PublicProfileSettings): string => {
    return `${getName(profile)} ${profile?.companyComplement ?? ''} - ${profile?.address?.streetName} ${profile?.address?.streetNumber}, ${profile?.address?.postalCode} ${profile?.address?.locality?.long}`;
};

// const createPublicProfileHeader = (profile: PublicProfileSettings) => {
//     const profileHeaderRows = [];
//     profileHeaderRows.push({
//         text: getName(profile),
//         color: '#333333',
//         width: '*',
//         fontSize: 16,
//         bold: true,
//         alignment: 'right',
//         margin: [0, 0, 0, 0]
//     });

//     if (!!profile?.companyComplement) {
//         profileHeaderRows.push(
//             {
//                 text: profile?.companyComplement,
//                 color: '#333333',
//                 width: '*',
//                 fontSize: 9,
//                 bold: true,
//                 alignment: 'right'
//             }
//         );
//     }
//     if (profile?.features?.hasVatID) {
//         profileHeaderRows.push(
//             {
//                 text: `${profile?.vatID ? 'VAT ID: ' + profile?.vatID : ''}`,
//                 color: '#333333',
//                 width: '*',
//                 fontSize: 8,
//                 alignment: 'right',
//             },
//         );
//     }
//     if (profile?.features?.hasHrbNumber) {
//         profileHeaderRows.push(
//             {
//                 text: `${profile?.hrb ? 'HRB ' + profile?.hrb : ''}`,
//                 color: '#333333',
//                 width: '*',
//                 fontSize: 8,
//                 alignment: 'right',
//             }
//         );
//     }
//     profileHeaderRows.push(
//         {
//             text: `${profile?.address?.streetName} ${profile?.address?.streetNumber}`,
//             color: '#333333',
//             width: '*',
//             fontSize: 9,
//             alignment: 'right'
//         },
//         {
//             text: `${profile?.address?.postalCode} ${profile?.address?.locality?.long}`,
//             color: '#333333',
//             width: '*',
//             fontSize: 9,
//             alignment: 'right',
//         }
//     );
//     profileHeaderRows.push(
//         {
//             text: '',
//             margin: [0, 0, 0, 20]
//         }
//     );
//     return profileHeaderRows;
// };

const putLogo = (logo: CustomerLogo, settings: settings,) => {
    if (!!logo && settings?.logo) {
        return {
            image: 'logo',
            fit: [90, 90],
            //absolutePosition: {x: 64, y: 20}
            margin:[0,0,0,5]

        };
    }
};

const getImageDef = (logo: CustomerLogo) => {
    if (!!logo && !!logo?.url) {
        return {logo: logo?.url};
    }
};

// const addAddress = (data: StandardDunningData) => {
//     const profileHeaderRows = [];
//     if (data?.publicProfile?.address?.streetName) {
//         profileHeaderRows.push(
//             {
//                 width: '33%',
//                 text: [
//                     {
//                         text: 'Firmeninformation\n',
//                         bold: true,
//                         fontSize: 8,
//                     },
//                     {
//                         text: `${data?.publicProfile?.address?.streetName ? data?.publicProfile?.address?.streetName : ''} ${data?.publicProfile?.address?.streetNumber ? data?.publicProfile?.address?.streetNumber : ''}` + '\n' + `${data?.publicProfile?.address?.postalCode ? data?.publicProfile?.address?.postalCode : ''} ${data?.publicProfile?.address?.locality?.long ? data?.publicProfile?.address?.locality?.long : ''}` + '\n' + `${data?.publicProfile?.features?.hasVatID ? data?.publicProfile?.vatID ? 'VAT ID: ' + data?.publicProfile?.vatID + '\n' : '' : ''} ${data?.publicProfile?.features?.hasHrbNumber ? data?.publicProfile?.hrb ? 'HRB ' + data?.publicProfile?.hrb : '' : ''}`,
//                         fontSize: 7,
//                         color: '#404040'
//                     }
//                 ],
//                 //text: 'Firmeninformation\nAm Wasser 100\n12345 Am Wasser\nDeutschland\nUST-Id-Nr:DE999999999',
//                 style: 'footerColumn',
//             },
//         )
//     } else {
//         profileHeaderRows.push(
//             {
//                 width: '33%',
//                 text: '',
//                 // text: [
//                 //     {
//                 //         text: 'Firmeninformation\n',
//                 //         bold: true,
//                 //         fontSize: 8,
//                 //     },
//                 // ],
//                 //text: 'Firmeninformation\nAm Wasser 100\n12345 Am Wasser\nDeutschland\nUST-Id-Nr:DE999999999',
//                 style: 'footerColumn',
//             },
//         )

//     }
//     return profileHeaderRows;
// }
// const addContactInfo = (data: StandardDunningData) => {
//     const profileHeaderRows = [];

//     if (data?.publicProfile?.email || data?.publicProfile?.phoneNumber) {
//         profileHeaderRows.push(
//             {
//                 width: '33%',
//                 text: [
//                     {
//                         text: 'Kontaktinformation\n',
//                         bold: true,
//                         fontSize: 8,

//                     },
//                     {
//                         text: `${data?.publicProfile?.companyName ? (data?.publicProfile?.companyName + ' ' + (data?.publicProfile?.companyComplement ? data?.publicProfile?.companyComplement : '')) : (data?.publicProfile?.firstName + ' ' + data?.publicProfile?.lastName)}`+ '\n' + `${data?.publicProfile?.features?.hasPhoneNumber ? data?.publicProfile?.phoneNumber ? 'Telefon: ' + data?.publicProfile?.phoneNumber + '\n' : '' : ''}` + `${data?.publicProfile?.features?.hasEmail ? data?.publicProfile?.email ? 'E-Mail: ' + data?.publicProfile?.email : '' : ''}`,
//                         fontSize: 7,
//                         color: '#404040'
//                     }
//                 ],
//                 //text: 'Kontaktinformation\nKontaktperson\nTelefon: 020 123 45 67\nE-Mail: kontakt@firma.de\nwww.beispielfirma.be',
//                 style: 'footerColumn',
//             },
//         );
//     } else {
//         profileHeaderRows.push(
//             {
//                 width: '33%',
//                 text: '',
//                 // text: [
//                 //     {
//                 //         text: 'Kontaktinformation\n',
//                 //         bold: true,
//                 //         fontSize: 8,
//                 //     },
//                 // ],
//                 //text: 'Firmeninformation\nAm Wasser 100\n12345 Am Wasser\nDeutschland\nUST-Id-Nr:DE999999999',
//                 style: 'footerColumn',
//             },
//         )

//     }
//     return profileHeaderRows;
// };
// const addBankAccount = (data: StandardDunningData) => {
//     const profileHeaderRows = [];

//     if (data?.publicProfile?.bankAccount && data?.settings?.bankAccount) {
//         profileHeaderRows.push(
//             {
//                 width: '33%',
//                 text: [
//                     {
//                         text: 'Bankverbindung\n',
//                         bold: true,
//                         fontSize: 8,
//                     },
//                     {
//                         text: 'Bank: ' + `${data?.publicProfile?.bankAccount?.bank}` + '\nIBAN: ' + `${data?.publicProfile?.bankAccount?.IBAN}` + '\nBIC: ' + `${data?.publicProfile?.bankAccount?.BIC}`,
//                         fontSize: 7,
//                         color: '#404040'
//                     }
//                 ],
//                 //text: 'Bankverbindung\nBank: Sparkasse Wasser\nIBAN: DE231124098234\nBIC: DEHHCCX1001',
//                 style: 'footerColumn',
//             }
//         );
//     } else {
//         profileHeaderRows.push(
//             {
//                 width: '33%',
//                 text: '',
//                 // text: [
//                 //     {
//                 //         text: 'Bankverbindung\n',
//                 //         bold: true,
//                 //         fontSize: 8,
//                 //     },
//                 // ],
//                 //text: 'Firmeninformation\nAm Wasser 100\n12345 Am Wasser\nDeutschland\nUST-Id-Nr:DE999999999',
//                 style: 'footerColumn',
//             },
//         )

//     }
//     return profileHeaderRows;
// };

export const STANDARD_DUNNING = (data: StandardDunningData): any => {
    console.log('STANDARD_LETTER --> data', data);
    const margin = 70.8;
    const top = 10;
    console.log('margin margin', margin - top);
    return {
        pageMargins: [margin, 30, 56.7,data.settings?.footer?100:55],
        watermark: data.isActiveUser ? '' : {
            text: 'Testversion',
            color: '#4f46e5',
            opacity: 0.3,
            bold: true,
            italics: false
        },
        content: buildLetter(data),
        footer: function (currentPage, pageCount) {
            if (data.settings?.footer) {
                return {
                    stack: [
                        {
                            text: `${data?.signatureRequired ?'Dieses Schreiben wurde maschinell erstellt und ist ohne Unterschrift gültig' :'' }`,
                            color: '#000000',
                            fontSize: 6,
                            alignment: 'left',
                            margin: [margin, 0, 0, 0]
                        },
                        {
                            canvas: [
                                {
                                    type: 'line',
                                    x1: 0,
                                    y1: 5,
                                    x2: 595 - 2 * margin,
                                    y2: 5,
                                    lineWidth: 1,
                                }
                            ],
                            margin: [margin, 0, 0, 5],
                        },
                        {
                            columns: [
                                addAddress(data?.publicProfile),
                                addContactInfo(data?.publicProfile),
                                addBankAccount(data?.publicProfile),
                            ],
                            margin: [margin, 0, 0, 0]

                        },
                        {
                            text: [
                                {
                                    text: '--------------------------------------------------------------------------' +
                                        '\n',
                                },
                                {
                                    text: [
                                        {
                                            text: '© nahaus.de ',
                                            bold: true,
                                            color: '#4f46e5',
                                            fontSize: 8,
                                        },
                                        {
                                            text: data?.letter?.units && data?.letter?.units?.length > 1 ? '' : currentPage.toString() + ' von ' + pageCount,
                                            fontSize: 8,
                                        }
                                    ]
                                }
                            ],
                            alignment: 'center',
                            margin: [0, 0, 0, 0]
                        },


                    ],
                };
            } else {
                return {
                    stack: [
                        {
                            text: `${data?.signatureRequired ? 'Dieses Schreiben wurde maschinell erstellt und ist ohne Unterschrift gültig':''}`,
                            color: '#000000',
                            fontSize: 8,
                            alignment: 'center',
                            //margin: [margin, -50, 0,0]
                        },
                        {
                            text: [
                                {
                                    text: '--------------------------------------------------------------------------' +
                                        '\n',
                                },
                                {
                                    text: [
                                        {
                                            text: '© nahaus.de ',
                                            bold: true,
                                            color: '#4f46e5',
                                            fontSize: 8,
                                        },
                                        {
                                            text: currentPage.toString() + ' von ' + pageCount,
                                            fontSize: 8,
                                        }
                                    ]
                                }
                            ],
                            alignment: 'center',
                            margin: [0, 0, 0, 0]
                        },


                    ],
                };
            }

        },
        // styles: {
        //   notesTitle: {
        //     fontSize: 10,
        //     bold: true,
        //     margin: [0, 50, 0, 3]
        //   },
        //   notesText: {
        //     fontSize: 10
        //   }
        // },
        // images: {
        //   // in browser is supported loading images via url (https or http protocol) (minimal version: 0.1.67)
        //   logo: data?.publicProfile?.logo?.url,
        //   // logo: 'https://picsum.photos/seed/picsum/200/300',
        // },
        styles:{
            "ql-align-center":{
                alignment: 'center'
            },
            "ql-align-left":{
                alignment: 'left'
            },
            "ql-align-right":{
                alignment: 'right'
            },
            "ql-align-justify":{
                alignment: 'justify'
            },
        },
        images: getImageDef(data?.publicProfile?.logo),
        defaultStyle: {
            // columnGap: 20,
            pageSize: 'A4',
            pageMargins: [margin, 60, 56.7, 60],
            // font: 'Quicksand',
        }
    };
};
