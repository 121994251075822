import { Injectable } from '@angular/core';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';
import { Letter } from '../models/letter.interface';

@Injectable()
export class LetterTemplatesService extends AbstractFirestoreService<Letter> {

  COLLECTION = 'letterTemplates';

  constructor(authService: AuthService) {
    super(authService);
  }
}
