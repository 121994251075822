import {Injectable} from '@angular/core';
import {MSA, MsaOperationType, MsaStatus, StatMsa} from '@msa/models';
import {MsaProcess} from '@msa/models/msa-process.interface';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';
import {set} from 'lodash-es';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn:"root"})
export class MsaService extends AbstractFirestoreService<MSA> {

  COLLECTION = 'msa';

  async delete(msaID: string): Promise<void> {
    return await this.doc(msaID).delete();
  }

  onStatsChanged: BehaviorSubject<StatMsa> = new BehaviorSubject<StatMsa>(null);

  constructor(authService: AuthService) {
    super(authService);
  }

  async update(msaID: string, data: Partial<MSA>): Promise<void> {
    return this.doc(msaID).set(data, { merge: true });
  }

  async updateStatus(msaID: string, msaStatus: MsaStatus): Promise<void> {
    return this.doc(msaID).update({ status: msaStatus });
  }

  async changeProcess(msaID: string, process: MsaProcess): Promise<void> {
    return this.doc(msaID).update({ process });
  }

  async updateStatusAndProcess(msaID: string, status: MsaStatus, process: MsaProcess): Promise<void> {
    return this.doc(msaID).update({ status, process });
  }

  async updateOperation(msaID: string, operation: MsaOperationType): Promise<void> {
    // const operationProperty: string = `operations.${operation}`;
    const list = set({}, ['operations', operation], { checked: true });
    return this.doc(msaID).set(list as Partial<MSA>, { merge: true });
  }

}
