import { Injectable } from '@angular/core';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';
import { Property } from '../models/property.interface';

@Injectable({
  providedIn: 'root'
})
export class PropertiesService extends AbstractFirestoreService<Property> {

  COLLECTION = 'properties';

  constructor(authService: AuthService) {
    super(authService);
  }

  public checkH_IDAvailability(h_id: number): Promise<boolean> {
    console.log('checkH_IDAvailability', h_id);
    return this.collection(ref =>
      ref.where('h_id', '==', +h_id)
        .limit(1)
    ).get().toPromise().then((res) => {
      console.log('checkH_IDAvailability', h_id, res.docs);
      console.log(h_id);
      console.log(+h_id);
      return res.size > 0;
    });
  }

  async lockProperty(property: Property, isLocked: boolean): Promise<any> {
    // return await this.pfs
    //   .lockProperty(property.id, isLocked)
    //   .then(() => {
    //     // this.snacknar.open(`Die Immobilie ${property?.address?.displayAddress} wurde erfolgreich ${isLocked ? 'gesperrt' : 'freigegeben'}!`, 'OK', { duration: 5000 });
    //   })
    //   .catch(err => {
    //     // this.snacknar.open(`Fehler: ${err}`, 'OK', { duration: 5000 });
    //   });
  }

  //  async lockProperty(id: string, isLocked: boolean): Promise<any> {
  //   const batch = this.batch();
  //   batch.update(this.doc(id).ref, { isLocked: isLocked });
  //   console.log('starting');
  //   const unitsCollection = await this.getUnitsCollectionRef(id)
  //     .get()
  //     .toPromise();
  //   console.log('units collection received');
  //   if (unitsCollection.size > 0) {
  //     let i = 0;
  //     unitsCollection.forEach(unit => {
  //       console.log('unit setting in batch --> ', ++i);
  //       batch.update(unit.ref, { isLocked: isLocked });
  //     });
  //   }
  //   console.log('lockProperty done!');
  //   return batch.commit();
  // }
}
