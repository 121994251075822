import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { PropertyUnitPeriod } from '../models/property.unit.period.interface';

@Injectable()
export class PeriodFromParentRouteResolver implements Resolve<PropertyUnitPeriod> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): PropertyUnitPeriod {
    return route?.parent?.data?.period as PropertyUnitPeriod;
  }
}
