import {Injectable} from '@angular/core';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';
import {NahausTag} from '../models/tasks.types';

@Injectable()
export class TaskTagsService extends AbstractFirestoreService<NahausTag> {

    selectedTags: string[] = []
    COLLECTION = 'tasksTags';

    constructor(authService: AuthService) {
        super(authService);
    }

    setParentPath(userID: string): void {
        this.parentPath = `users/${userID}`;
    }

    resetPath(): void {
        this.parentPath = null;
    }

}
