export const getFileNameOrId = (path: string): string => {
    const parts = path.split('/');
    const fileName = parts.pop(); // Nimmt den letzten Teil des Pfades

    if (fileName) {
        return fileName;
    } else {
        return parts.pop() || ''; // Nimmt den letzten Teil, wenn fileName nicht vorhanden ist
    }
};
