import { AbstractFirestoreService } from "@shared/services/abstracts/AbstractFirestoreService";
import { NahausReminder } from "../models/nahausReminder.interface";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "@shared/services/auth.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Injectable } from "@angular/core";
@Injectable()
export class ReminderService extends AbstractFirestoreService<NahausReminder> {

    onListChanged$: BehaviorSubject<NahausReminder> = new BehaviorSubject<NahausReminder>(null);

    COLLECTION = 'reminders';

    constructor(authService: AuthService, private firestore: AngularFirestore) {
        super(authService);
    }
}