import { Activity } from '@activities/models/activities.types';
import { Injectable } from '@angular/core';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';

@Injectable()
export class TransactionsActivitiesService extends AbstractFirestoreService<Activity> {

  COLLECTION = 'activities';

  constructor(authService: AuthService) {
    super(authService);
  }

  setParentPath(bankAccountID: string, transactionID: string): void {
    this.parentPath = `bankAccounts/${bankAccountID}/transactions/${transactionID}`;
  }

}
