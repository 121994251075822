import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnInit,
    Optional,
    Output,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';
import {BaseWidget, NgAisIndex, NgAisInstantSearch} from 'angular-instantsearch';
import {connectAutocomplete} from 'instantsearch.js/es/connectors';
import {MatLineModule, MatOptionModule} from '@angular/material/core';
import {NgFor, NgIf} from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
    selector: 'app-algolia-autocomplete-msa',
    templateUrl: './algolia-autocomplete-msa.component.html',
    styleUrls: ['./algolia-autocomplete-msa.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatFormFieldModule, MatIconModule, MatInputModule, MatAutocompleteModule, NgFor, MatOptionModule, NgIf, MatLineModule]
})
export class AlgoliaAutocompleteMsaComponent extends BaseWidget implements OnInit {

  @ContentChild(TemplateRef) public template?: TemplateRef<any>;

  @Input() disabled: boolean;
  @Input() formFieldClass: string;
  @Input() placeholder: string = 'Suchen...';

  // @ts-ignore
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onQuerySuggestionClick = new EventEmitter<any>();

  state: {
    query: string;
    refine: Function;
    indices: object[];
  };

  ngOnInit(): void {
    this.createWidget(connectAutocomplete, {});
    super.ngOnInit();
  }

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    super('AutocompleteComponent');
  }

  public handleChange($event: KeyboardEvent): void {
    this.state.refine(($event.target as HTMLInputElement).value);
    this._changeDetectorRef.markForCheck();
  }

}
