import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Dunning, DunningTypeOptions} from "../../models/dunning.interface";
import {DunningsModularService} from "../../services/dunnings-modular.service";
import {deleteDoc} from "@angular/fire/firestore";
import {ActivatedRoute, Router} from "@angular/router";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {formatCurrency, NgIf} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {Activity, ActivityFeatureOption} from "@activities/models/activities.types";
import {formatAddress} from "../../../../../../../helpers/address";
import {ActivitiesService} from "@activities/services/activities.service";


@Component({
    selector: 'delete-period-dialog',
    templateUrl: './delete-dunning-dialog.component.html',
    styles: [
        /* language=SCSS */
        `
            .fuse-confirmation-dialog-panel {
                @screen md {
                    @apply w-128;
                }

                .mat-mdc-dialog-container {

                    .mat-mdc-dialog-surface {
                        padding: 0 !important;
                    }
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    providers: [DunningsModularService, ActivitiesService],
    standalone: true,
    imports: [MatButtonModule, MatDialogModule, MatIconModule, MatTooltipModule, NgIf, MatProgressSpinnerModule]
})
export class DeleteDunningDialogComponent implements OnInit {

    dunning: Dunning;
    isDeleting: boolean;

    /**
     * Constructor
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Dunning,
        public matDialogRef: MatDialogRef<DeleteDunningDialogComponent>,
        private snackbar: MatSnackBar,
        private route: ActivatedRoute,
        private router: Router,
        private dunningsModularService: DunningsModularService,
        private activitiesService: ActivitiesService
    ) {
        console.log('data', data);
        if (!data) {
            // todo 26.10.21 --> show error
            this.snackbar.open('Etwas ist schief gelaufen, bitte versuchen Sie es später erneut oder wenden Sie sich an den Support', 'OK', {duration: 10000});
            this.matDialogRef.close('cancelled');
        } else {
            this.dunning = data;
            this.dunningsModularService.setParentPathForPeriod(this.dunning?.propertyID, this.dunning?.unitID, this.dunning?.periodID);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    createActivityOnDeleteDunning(): Activity {
        const now = new Date();
        const user = this.activitiesService.authService.authUser.displayName || this.activitiesService.authService.firestoreUser.displayName || this.activitiesService.authService.authUser.email;
        const unitLabel = this.dunning?.unitLabel;
        const tenantsName = this.dunning?.tenantsName;
        const actionDescription = this.dunning?.type === DunningTypeOptions.PAYMENT_REMINDER ? 'Zahlungserinnerung' : 'Mahnung'

        const activity: Activity = {
            id: this.activitiesService.createID(),
            createdAt: now,
            date: now,
            user,
            uid: this.activitiesService.authService.authUser?.uid,
            icon: 'mat_outline:delete',
            address: formatAddress(this.dunning?.address),
            customerID: this.activitiesService.authService.customerID,
            propertyID: this.dunning?.propertyID,
            unitID: this.dunning?.unitID,
            periodID: this.dunning?.periodID,
            h_id: this.dunning?.h_id,
            w_id: this.dunning?.w_id,
            unitLabel,
            tenantsName,
            featureType: ActivityFeatureOption.DUNNING,
            managementID: null,
            useRouter: true,
            linkedContent: 'Mehr erfahren',
            link: `/immobilien/${this.dunning?.propertyID}/einheit/${this.dunning?.unitID}/buchungen/${this.dunning?.periodID}/mahnwesen`,
            description: `${user} hat eine <span class="font-extrabold">${actionDescription} - ${this.dunning?.refID}</span> gelöscht für ${unitLabel} - ${tenantsName} (ursprünglich ${formatCurrency(this.dunning?.amountToPayInclFeesAndInterest, 'de-DE', "€", 'EUR')})`
            // description: `${user} hat eine <span class="font-extrabold">${actionDescription} - ${dunning?.refID}</span> erstellt für ${unitLabel} - Familie ${tenantsName} (OP ${formatCurrency(dunning?.amountToPayInclFeesAndInterest, 'de-DE', "€", 'EUR')})`
        };
        return activity;
    }

    delete(): void {
        this.isDeleting = true;

        const rootActivity: Activity = this.createActivityOnDeleteDunning();

        Promise.all([this.activitiesService.doc(rootActivity.id).set(rootActivity), deleteDoc(this.dunningsModularService.doc(this.dunning.id))])
            .then(() => {
                this.matDialogRef.close();
                const msg = 'Mahnung erfolgreich gelöscht';
                this.snackbar.open(msg, 'Schließen', {duration: 5000});
                return this.router.navigate(['/immobilien', this.dunning?.propertyID, 'einheit', this.dunning?.unitID, 'buchungen', this.dunning?.periodID, 'mahnwesen'])
            })
            .catch((err) => {
                console.error('Error:', err);
                const msg = 'Fehler beim Löschen der Mahnung';
                this.snackbar.open(msg, 'Schließen', {duration: 10000});
            })
            .finally(() => this.isDeleting = false);
    }
}
