<div class="flex flex-col flex-auto min-w-0 bg-default">

    <!-- Main -->
    <div class="flex-auto border-t -mt-px pt-4 sm:pt-6">
        <div class="w-full max-w-screen-xl mx-auto">
            <!-- Tabs -->
            <mat-tab-group
                [animationDuration]="'0'"
                class="sm:px-2"
                mat-stretch-tabs="false">

                <!-- Budget -->
                <mat-tab label="Buchungskonten">
                    <ng-template matTabContent>
                        <div class="grid grid-cols-1 sm:grid-cols-6 gap-6 w-full min-w-0">
                            <!-- BookingAccounts distribution -->
                            <div
                                class="sm:col-span-3 lg:col-span-4 flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                                <div class="text-lg font-medium tracking-tight leading-6 truncate">Verteilung
                                </div>
                                <div class="flex flex-col flex-auto">
                                    <apx-chart #bookingAccountsChart
                                               [chart]="chartBookingAccountsDistribution.chart"
                                               [colors]="chartBookingAccountsDistribution.colors"
                                               [dataLabels]="chartBookingAccountsDistribution.dataLabels"
                                               [markers]="chartBookingAccountsDistribution.markers"
                                               [plotOptions]="chartBookingAccountsDistribution.plotOptions"
                                               [series]="chartBookingAccountsDistribution.series"
                                               [stroke]="chartBookingAccountsDistribution.stroke"
                                               [tooltip]="chartBookingAccountsDistribution.tooltip"
                                               [xaxis]="chartBookingAccountsDistribution.xaxis"
                                               [yaxis]="chartBookingAccountsDistribution.yaxis"
                                               class="flex-auto w-full h-80"></apx-chart>
                                </div>
                            </div>
                            <!-- Expense -->
                            <div class="sm:col-span-3 lg:col-span-2 grid grid-cols-1 gap-y-6">
                                <!-- Monthly expenses -->
                                <div
                                    class="sm:col-span-2 flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                                    <div class="flex items-start justify-between">
                                        <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                            Bisherige Mieteinnahmen
                                        </div>
                                        <div *ngIf="false" class="ml-2 -mt-2 -mr-3">
                                            <button
                                                [matMenuTriggerFor]="monthlyExpensesMenu"
                                                mat-icon-button>
                                                <mat-icon
                                                    [svgIcon]="'heroicons_solid:dots-vertical'"
                                                    class="icon-size-5"></mat-icon>
                                            </button>
                                            <mat-menu #monthlyExpensesMenu="matMenu">
                                                <button mat-menu-item>This week</button>
                                                <button mat-menu-item>Last week</button>
                                                <button mat-menu-item>2 weeks ago</button>
                                            </mat-menu>
                                        </div>
                                    </div>
                                    <div @zoomIn class="flex items-center mt-1">
                                        <div class="flex flex-col">
                                            <div class="text-3xl font-semibold tracking-tight leading-tight">
                                                {{this.totalRental | currency:'EUR'}}
                                            </div>
                                            <div *ngIf="!this.loadingRentalStats; else loadingTemplate"
                                                 @zoomIn class="flex items-center">
                                                <ng-container *ngIf="this.rentalPerc">
                                                    <mat-icon *ngIf="this.rentalPerc > 0"
                                                              [svgIcon]="'heroicons_solid:trending-up'"
                                                              class="mr-1 icon-size-5 text-green-500"></mat-icon>
                                                    <div *ngIf="this.rentalPerc > 0"
                                                         class="font-medium text-sm text-secondary leading-none whitespace-nowrap">
														<span
                                                            class="text-green-500">{{this.rentalPerc | number:'1.0-1'}}
                                                            %</span>
                                                        Steigerung
                                                    </div>
                                                    <mat-icon *ngIf="this.rentalPerc < 0"
                                                              [svgIcon]="'heroicons_solid:trending-down'"
                                                              class="mr-1 icon-size-5 text-red-500"></mat-icon>
                                                    <div *ngIf="this.rentalPerc < 0"
                                                         class="font-medium text-sm text-secondary leading-none whitespace-nowrap">
														<span class="text-red-500">{{this.rentalPerc | number: '1.0-1'}}
                                                            %</span>
                                                        Rückgang
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="flex flex-col flex-auto ml-8">
                                            <apx-chart #rentalChart
                                                       [chart]="chartRental.chart"
                                                       [colors]="chartRental.colors"
                                                       [series]="chartRental.series"
                                                       [stroke]="chartRental.stroke"
                                                       [tooltip]="chartRental.tooltip"
                                                       [xaxis]="chartRental.xaxis"
                                                       [yaxis]="chartRental.yaxis"
                                                       class="flex-auto w-full h-16"></apx-chart>
                                        </div>
                                    </div>
                                    <ng-template #loadingTemplate>
                                        <div class="flex justify-center items-center mt-6">
                                            <div class="flex flex-row">
                                                <mat-progress-spinner diameter="48"
                                                                      mode="indeterminate"></mat-progress-spinner>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>

                                <!-- tenantsAccChart -->
                                <div
                                    class="sm:col-span-2 flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                                    <div class="flex items-start justify-between">
                                        <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                            Mieterkontenstand
                                        </div>
                                        <div *ngIf="false" class="ml-2 -mt-2 -mr-3">
                                            <button
                                                [matMenuTriggerFor]="weeklyExpensesMenu"
                                                mat-icon-button>
                                                <mat-icon
                                                    [svgIcon]="'heroicons_solid:dots-vertical'"
                                                    class="icon-size-5"></mat-icon>
                                            </button>
                                            <mat-menu #weeklyExpensesMenu="matMenu">
                                                <button mat-menu-item>This week</button>
                                                <button mat-menu-item>Last week</button>
                                                <button mat-menu-item>2 weeks ago</button>
                                            </mat-menu>
                                        </div>
                                    </div>
                                    <div class="flex items-center mt-1">
                                        <div class="flex flex-col">
                                            <div class="text-3xl font-semibold tracking-tight leading-tight">
                                                {{this.accumulatedTotalAmount | currency:'EUR'}}
                                            </div>
                                            <div *ngIf="false" class="flex items-center">
                                                <mat-icon
                                                    [svgIcon]="'heroicons_solid:trending-down'"
                                                    class="mr-1 icon-size-5 text-red-500"></mat-icon>
                                                <div
                                                    class="font-medium text-sm text-secondary leading-none whitespace-nowrap">
                                                    <span class="text-red-500">2%</span>
                                                    Rückgang
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex flex-col flex-auto ml-8">
                                            <apx-chart #tenantsAccChart
                                                       [chart]="chartTenantsAccounts.chart"
                                                       [colors]="chartTenantsAccounts.colors"
                                                       [series]="chartTenantsAccounts.series"
                                                       [stroke]="chartTenantsAccounts.stroke"
                                                       [tooltip]="chartTenantsAccounts.tooltip"
                                                       [xaxis]="chartTenantsAccounts.xaxis"
                                                       [yaxis]="chartTenantsAccounts.yaxis"
                                                       class="flex-auto w-full h-16"></apx-chart>
                                        </div>
                                    </div>
                                </div>

                                <!-- Saldo Chart -->
                                <div
                                    class="sm:col-span-2 flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                                    <div class="flex items-start justify-between">
                                        <div class="text-lg font-medium tracking-tight leading-6 truncate">
                                            Offene Posten und aktuelles Saldo
                                        </div>
                                        <div *ngIf="false" class="ml-2 -mt-2 -mr-3">
                                            <button
                                                [matMenuTriggerFor]="yearlyExpensesMenu"
                                                mat-icon-button>
                                                <mat-icon
                                                    [svgIcon]="'heroicons_solid:dots-vertical'"
                                                    class="icon-size-5"></mat-icon>
                                            </button>
                                            <mat-menu #yearlyExpensesMenu="matMenu">
                                                <button mat-menu-item>This week</button>
                                                <button mat-menu-item>Last week</button>
                                                <button mat-menu-item>2 weeks ago</button>
                                            </mat-menu>
                                        </div>
                                    </div>
                                    <div class="flex items-center mt-1">
                                        <div class="flex flex-col">
                                            <div class="text-3xl font-semibold tracking-tight leading-tight">
												<span
                                                    [ngClass]="{'text-warn': this.totalSaldo < 0, 'text-primary': this.totalSaldo > 0, 'font-extrabold': this.totalSaldo !== 0}"
                                                    class="pr-6 whitespace-nowrap"><span *ngIf="this.totalSaldo > 0"
                                                                                         class="font-extrabold text-primary">+</span>{{this.totalSaldo | currency:'EUR'}}</span>
                                            </div>
                                            <div *ngIf="!this.loadingSaldoStats; else loadingTemplate"
                                                 class="flex items-center">
                                                <ng-container *ngIf="this.saldoPerc">
                                                    <mat-icon *ngIf="this.saldoPerc > 0"
                                                              [svgIcon]="'heroicons_solid:trending-up'"
                                                              class="mr-1 icon-size-5 text-green-500"></mat-icon>
                                                    <div *ngIf="this.saldoPerc > 0"
                                                         class="font-medium text-sm text-secondary leading-none whitespace-nowrap">
														<span class="text-green-500">{{this.saldoPerc | number:'1.0-1'}}
                                                            %</span>
                                                        Rückgang
                                                    </div>
                                                    <mat-icon *ngIf="this.saldoPerc < 0"
                                                              [svgIcon]="'heroicons_solid:trending-down'"
                                                              class="mr-1 icon-size-5 text-red-500"></mat-icon>
                                                    <div *ngIf="this.saldoPerc < 0"
                                                         class="font-medium text-sm text-secondary leading-none whitespace-nowrap">
														<span class="text-red-500">{{this.saldoPerc | number: '1.0-1'}}
                                                            %</span>
                                                        Steigerung
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="flex flex-col flex-auto ml-8">
                                            <apx-chart #saldoChart
                                                       [chart]="chartSaldo.chart"
                                                       [colors]="chartSaldo.colors"
                                                       [series]="chartSaldo.series"
                                                       [stroke]="chartSaldo.stroke"
                                                       [tooltip]="chartSaldo.tooltip"
                                                       [xaxis]="chartSaldo.xaxis"
                                                       [yaxis]="chartSaldo.yaxis"
                                                       class="flex-auto w-full h-16"></apx-chart>
                                        </div>
                                        <ng-template #loadingTemplate>
                                            <div class="flex justify-center items-center mt-6">
                                                <div class="flex flex-row">
                                                    <mat-progress-spinner diameter="48"
                                                                          mode="indeterminate"></mat-progress-spinner>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <!-- Budget details -->
                            <div
                                class="sm:col-span-6 flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                                <div class="text-lg font-medium tracking-tight leading-6 truncate">Details der
                                    Buchungskonten
                                </div>
                                <div *ngIf="this.bookingsAccountsDataSource"
                                     class="flex flex-col flex-auto mt-2 overflow-x-auto">
                                    <table
                                        [dataSource]="this.bookingsAccountsDataSource"
                                        class="min-w-240 overflow-y-visible"
                                        mat-table>

                                        <!-- Type -->
                                        <ng-container
                                            matColumnDef="type"
                                            sticky>
                                            <th
                                                *matHeaderCellDef
                                                mat-header-cell>
                                                Konto
                                            </th>
                                            <td
                                                *matCellDef="let bookingAccount"
                                                mat-cell>
                                                <span class="flex items-center">
                                                    <span
                                                        [ngClass]="{'bg-red-500': bookingAccount?.saldo < 0,
                                                                    'bg-primary': !(bookingAccount?.saldo < 0)}"
                                                        class="w-2 h-2 rounded-full"></span>
                                                    <span
                                                        class="ml-3 leading-4">{{this.convertBookingAccountIDToText(bookingAccount)}}</span>
                                                </span>
                                            </td>
                                        </ng-container>

                                        <!-- Total -->
                                        <ng-container matColumnDef="total">
                                            <th
                                                *matHeaderCellDef
                                                mat-header-cell>
                                                Kontostand
                                            </th>
                                            <td
                                                *matCellDef="let bookingAccount"
                                                mat-cell>
                                                <span class="font-medium text-right">
                                                    {{bookingAccount?.amount | currency:'EUR'}}
                                                </span>
                                            </td>
                                        </ng-container>


                                        <!-- Expenses percentage -->
                                        <ng-container matColumnDef="expensesPercentage">
                                            <th
                                                *matHeaderCellDef
                                                mat-header-cell>
                                                Aktualisiert am
                                            </th>
                                            <td
                                                *matCellDef="let budget"
                                                mat-cell>
                                                {{budget?.updatedAt | toDate |date:'mediumDate'}}
                                            </td>
                                        </ng-container>

                                        <!-- Remaining amount -->
                                        <ng-container matColumnDef="remainingAmount">
                                            <th
                                                *matHeaderCellDef
                                                mat-header-cell>
                                                Saldo (EUR)
                                            </th>
                                            <td
                                                *matCellDef="let budget"
                                                mat-cell>
												<span
                                                    [ngClass]="{'text-warn': budget?.saldo < 0, 'text-primary': budget?.saldo > 0, 'font-extrabold': budget?.saldo !== 0}"
                                                    class="pr-6 whitespace-nowrap"><span *ngIf="budget?.saldo > 0"
                                                                                         class="font-extrabold text-primary">+</span>{{budget?.saldo | currency:'EUR'}}</span>
                                                <!--												{{budget?.saldo || 0 | currency:'EUR'}}-->
                                            </td>
                                        </ng-container>

                                        <!-- Remaining percentage -->
                                        <ng-container matColumnDef="remainingPercentage">
                                            <th
                                                *matHeaderCellDef
                                                mat-header-cell>
                                                Stabilität (%)
                                            </th>
                                            <td
                                                *matCellDef="let bookingAccount"
                                                mat-cell>
                                                <span
                                                    *ngIf="(bookingAccount?.saldo || 0) * 100 / (bookingAccount?.amount || 0) as perc; else noPerc"
                                                    class="flex items-center">
                                                    <span>
                                                        <!-- Low -->
                                                        <span
                                                            *ngIf="perc < 30"
                                                            class="flex items-end w-1 h-4 bg-red-200 rounded overflow-hidden">
                                                            <span class="flex w-full h-1/3 bg-red-600"></span>
                                                        </span>
                                                        <!-- Medium -->
                                                        <span
                                                            *ngIf="perc >= 30 && perc < 80"
                                                            class="flex items-end w-1 h-4 bg-orange-200 rounded overflow-hidden">
                                                            <span class="flex w-full h-2/4 bg-orange-400"></span>
                                                        </span>
                                                        <!-- High -->
                                                        <span
                                                            *ngIf="perc >= 80"
                                                            class="flex items-end w-1 h-4 bg-green-100 rounded overflow-hidden">
                                                            <span class="flex w-full h-full bg-green-400"></span>
                                                        </span>
                                                    </span>
                                                </span>
                                                <ng-template #noPerc>
		                                                <span class="flex items-center">
		                                                <span
                                                            class="flex items-end w-1 h-4 bg-green-100 rounded overflow-hidden">
                                                            <span class="flex w-full h-full bg-green-400"></span>
                                                        </span>
                                                        </span>
                                                </ng-template>
                                            </td>
                                        </ng-container>

                                        <tr
                                            *matHeaderRowDef="this.columns"
                                            mat-header-row></tr>
                                        <tr
                                            *matRowDef="let row; columns: this.columns;"
                                            mat-row></tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>

            </mat-tab-group>
        </div>
    </div>

</div>
