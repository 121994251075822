<div class="flex flex-col flex-auto min-w-0">

    <!-- Header -->
    <div
       *ngIf="this.accessLayer !== this.expensesAccessLayerOptions.PROPERTY"
        class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 pb-22 sm:pt-12 sm:pb-28 sm:px-10 bg-default dark:bg-transparent">
        <div class="flex-1 min-w-0" >
            <!-- Breadcrumbs -->
            <div>
                <div class="hidden sm:flex flex-wrap items-center font-medium">
                    <div>
                        <a class="whitespace-nowrap text-primary-500">Ausgaben</a>
                    </div>
                    <!--                    <div class="flex items-center ml-1 whitespace-nowrap">-->
                    <!--                        <mat-icon-->
                    <!--                            class="icon-size-5 text-secondary"-->
                    <!--                            [svgIcon]="'mat_outline:chevron_right'"></mat-icon>-->
                    <!--                        <a class="ml-1 text-primary-500">Weekend Project</a>-->
                    <!--                    </div>-->
                    <div class="flex items-center ml-1 whitespace-nowrap">
                        <mat-icon
                            [svgIcon]="'mat_outline:chevron_right'"
                            class="icon-size-5 text-secondary"></mat-icon>
                        <span class="ml-1 text-secondary">Liste</span>
                    </div>
                </div>
                <div class="flex sm:hidden">
                    <a
                        [routerLink]="'./..'"
                        class="inline-flex items-center -ml-1.5 text-secondary font-medium">
                        <mat-icon
                            [svgIcon]="'heroicons_solid:chevron-left'"
                            class="icon-size-5 text-secondary"></mat-icon>
                        <span class="ml-1">Zurück</span>
                    </a>
                </div>
            </div>
            <!-- Title -->
            <div class="mt-2">
                <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                    Ausgabenverwaltung
                </h2>
            </div>
        </div>
        <!-- Actions -->
        <div class="shrink-0 items-center mt-6 sm:mt-0 sm:ml-4 hidden lg:flex">
            <button
                (click)="matDrawer.toggle()"
                mat-icon-button>
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </div>
    </div>

    <mat-drawer-container class="flex-auto -mt-16 rounded-t-2xl shadow bg-card">

        <!-- Drawer -->
        <mat-drawer
            #matDrawer
            [autoFocus]="false"
            [mode]="drawerMode"
            [opened]="drawerOpened"
            class="w-72 dark:bg-gray-900">
            <!-- Demo sidebar -->
            <expenses-sidebar></expenses-sidebar>
        </mat-drawer>

        <!-- Drawer content -->
        <mat-drawer-content class="flex flex-col">
            <router-outlet></router-outlet>
        </mat-drawer-content>

    </mat-drawer-container>

</div>
