import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { firstValueFrom, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NahausContact } from '../models';
import { ContactsService } from '../services/contacts.service';

@Injectable()
export class ContactResolver implements Resolve<NahausContact> {

  nahausContact: NahausContact;
  id: string;

  constructor(private router: Router,
              private contactsService: ContactsService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Promise<NahausContact> | NahausContact {
    console.log('ContactResolver is running');

    this.id = route.params['id'];

    return new Promise((resolve, reject) => {
      return firstValueFrom(
        this.contactsService
          .doc(this.id)
          .get()
          .pipe(map(doc => doc.data() as NahausContact), catchError((error) => {

            // Log the error
            console.error(error);

            // Get the parent url
            const parentUrl = state.url.split('/').slice(0, -1).join('/');

            // Navigate to there
            this.router.navigateByUrl(parentUrl);

            // Throw an error
            return throwError(error);
          })))
        .then((doc) => {
          console.log('doc found in firestore - contact', doc);
          // this.lls.landlord = doc;
          return resolve(doc);
        })
        .catch(err => {
          console.error('Error while fetching a contact with id', this.id, err);
          this.router.navigate(['kontakte']);
          return reject();
        });

    });


  }
}
