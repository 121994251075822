import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { StatMsa } from '@msa/models';
import { AbstractFirestoreModularService } from '@shared/services/abstracts/AbstractFirestoreModularService';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';
import { Transaction } from '@transactions/models/transaction.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ContractsModularService extends AbstractFirestoreModularService<Transaction> {

  COLLECTION = 'contracts';

  constructor(authService: AuthService,
              firestore: Firestore) {
    super(authService, firestore);
  }


}
