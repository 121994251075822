import {Injectable} from '@angular/core';
import {Firestore} from '@angular/fire/firestore';
import {AbstractFirestoreModularService} from '@shared/services/abstracts/AbstractFirestoreModularService';
import {AuthService} from '@shared/services/auth.service';
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {NahausMessage} from "../models/nahausMessage.interface";
import {BehaviorSubject} from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class NahausMessagesModularService extends AbstractFirestoreModularService<NahausMessage> {

    COLLECTION = 'nahausMessages';

    public resetPaginationIndex$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    public deletedMessageSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    public notifySelectedMessageChanged$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(authService: AuthService,
                firestore: Firestore,
                private fns: AngularFireFunctions) {
        super(authService, firestore);
    }

}
