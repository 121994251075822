import { Directive, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ShowImgDialogComponent,
  ShowImgDialogData
} from '@shared/components/show-img-dialog/show-img-dialog.component';

@Directive({
    selector: '[appShowImageDialog]',
    standalone: true
})
export class ShowImageDialogDirective {

  @Input() showImageUrl: string;
  @Input() showImageTitle: string | null;
  @Input() showImageMessage: string | null;

  constructor(private dialog: MatDialog) {

  }

  @HostListener('click') onClick($event): void {
    // console.log('mouseenter');
    this.dialog.open(ShowImgDialogComponent, {
      autoFocus: false,
      maxHeight: '90vh', //you can adjust the value as per your view
      disableClose: false,
      data: {
        url: this.showImageUrl,
        title: this.showImageTitle,
        message: this.showImageMessage
      } as ShowImgDialogData,
      panelClass: 'fuse-confirmation-dialog-panel'
    });
  }

}
