import { Injectable } from '@angular/core';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';
import { NahausContactsStat } from '../models';

@Injectable()
export class ContactsStatService extends AbstractFirestoreService<NahausContactsStat> {

  COLLECTION = 'stat';

  constructor(authService: AuthService) {
    super(authService);
  }
}
