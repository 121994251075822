import { Pipe, PipeTransform } from '@angular/core';
import { NahausModulesPermission, PermissionOptions } from '@users/models/user.interface';

@Pipe({
    name: 'permissionText',
    standalone: true
})
export class PermissionTextPipe implements PipeTransform {

  transform(value: NahausModulesPermission): string {

    switch (value) {

      // MSA
      case PermissionOptions.MSA_READ:
        return 'MIETERSELBSTAUSKUNFT - LESEN';
      case PermissionOptions.MSA_WRITE:
        return 'MIETERSELBSTAUSKUNFT - SCHREIBEN';
      case PermissionOptions.MSA_DELETE:
        return 'MIETERSELBSTAUSKUNFT - LÖSCHEN';

      // Landlords
      case PermissionOptions.LANDLORDS_READ:
        return 'EIGENTÜMER - LESEN';
      case PermissionOptions.LANDLORDS_WRITE:
        return 'EIGENTÜMER - SCHREIBEN';
      case PermissionOptions.LANDLORDS_DELETE:
        return 'EIGENTÜMER - LÖSCHEN';

      // PROPERTIES
      case PermissionOptions.PROPERTIES_READ:
        return 'IMMOBILIEN - LESEN';
      case PermissionOptions.PROPERTIES_WRITE:
        return 'IMMOBILIEN - SCHREIBEN';
      case PermissionOptions.PROPERTIES_DELETE:
        return 'IMMOBILIEN - LÖSCHEN';

      // UNITS
      case PermissionOptions.UNITS_READ:
        return 'EINHEITEN - LESEN';
      case PermissionOptions.UNITS_WRITE:
        return 'EINHEITEN - SCHREIBEN';
      case PermissionOptions.UNITS_DELETE:
        return 'EINHEITEN - LÖSCHEN';

      // PERIODS
      case PermissionOptions.PERIODS_READ:
        return 'VERMIETUNGEN - LESEN';
      case PermissionOptions.PERIODS_WRITE:
        return 'VERMIETUNGEN - SCHREIBEN';
      case PermissionOptions.PERIODS_DELETE:
        return 'VERMIETUNGEN - LÖSCHEN';

      // TENANTS
      case PermissionOptions.TENANTS_READ:
        return 'MIETER - LESEN';
      case PermissionOptions.TENANTS_WRITE:
        return 'MIETER - SCHREIBEN';
      case PermissionOptions.TENANTS_DELETE:
        return 'MIETER - LÖSCHEN';

      // NK
      case PermissionOptions.NK_READ:
        return 'NEBENKOSTEN - LESEN';
      case PermissionOptions.NK_WRITE:
        return 'NEBENKOSTEN - SCHREIBEN';
      case PermissionOptions.NK_DELETE:
        return 'NEBENKOSTEN - LÖSCHEN';
      case PermissionOptions.NK_DELIVER:
        return 'NEBENKOSTEN - VERSCHICKEN';

      // CONTRACTS
      case PermissionOptions.CONTRACTS_READ:
        return 'MIETVERTRÄGE - LESEN';
      case PermissionOptions.CONTRACTS_WRITE:
        return 'MIETVERTRÄGE - SCHREIBEN';
      case PermissionOptions.CONTRACTS_DELETE:
        return 'MIETVERTRÄGE - LÖSCHEN';

      // METERS
      case PermissionOptions.METERS_READ:
        return 'ZÄHLER - LESEN';
      case PermissionOptions.METERS_WRITE:
        return 'ZÄHLER - SCHREIBEN';
      case PermissionOptions.METERS_DELETE:
        return 'ZÄHLER - LÖSCHEN';

      // METERS_READINGS
      case PermissionOptions.METERS_READINGS_READ:
        return 'ZÄHLER ABLESUNG - LESEN';
      case PermissionOptions.METERS_READINGS_WRITE:
        return 'ZÄHLER ABLESUNG - SCHREIBEN';
      case PermissionOptions.METERS_READINGS_DELETE:
        return 'ZÄHLER ABLESUNG - LÖSCHEN';

      // METERS_RE_REGISTRATIONS
      case PermissionOptions.METERS_RE_REGISTRATIONS_READ:
        return 'ZÄHLER UMMELDUNG - LESEN';
      case PermissionOptions.METERS_RE_REGISTRATIONS_WRITE:
        return 'ZÄHLER UMMELDUNG - SCHREIBEN';
      case PermissionOptions.METERS_RE_REGISTRATIONS_DELETE:
        return 'ZÄHLER UMMELDUNG - LÖSCHEN';

      // VACANCIES
      case PermissionOptions.VACANCIES_READ:
        return 'LEERSTAND - LESEN';
      case PermissionOptions.VACANCIES_WRITE:
        return 'LEERSTAND - SCHREIBEN';
      case PermissionOptions.VACANCIES_DELETE:
        return 'LEERSTAND - LÖSCHEN';

      // BANK_ACCOUNTS
      case PermissionOptions.BANK_ACCOUNTS_READ:
        return 'BANKKONTEN - LESEN';
      case PermissionOptions.BANK_ACCOUNTS_WRITE:
        return 'BANKKONTEN - SCHREIBEN';
      case PermissionOptions.BANK_ACCOUNTS_DELETE:
        return 'BANKKONTEN - LÖSCHEN';

      // LOANS
      case PermissionOptions.LOANS_READ:
        return 'DARLEHEN - LESEN';
      case PermissionOptions.LOANS_WRITE:
        return 'DARLEHEN - SCHREIBEN';
      case PermissionOptions.LOANS_DELETE:
        return 'DARLEHEN - LÖSCHEN';

      // ASSETS
      case PermissionOptions.ASSETS_READ:
        return 'VERMÖGENSÜBERSICHT - LESEN';
      case PermissionOptions.ASSETS_WRITE:
        return 'VERMÖGENSÜBERSICHT - SCHREIBEN';
      case PermissionOptions.ASSETS_DELETE:
        return 'VERMÖGENSÜBERSICHT - LÖSCHEN';

      // USERS
      case PermissionOptions.USERS_READ:
        return 'BENUTZERVERWALTUNG - LESEN';
      case PermissionOptions.USERS_WRITE:
        return 'BENUTZERVERWALTUNG - SCHREIBEN';
      case PermissionOptions.USERS_DELETE:
        return 'BENUTZERVERWALTUNG - LÖSCHEN';
    }

    return null;
  }

}
