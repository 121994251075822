import {Booking, BookingStatusOptions} from "../../../models/booking";
import {Timestamp} from "firebase/firestore";
import {BookingWithInterest} from "../models/dunning.interface";
import {Activity} from "@activities/models/activities.types";

export const calculateOverdueDays = (today: Date, dueDate: Date): number => {
    const oneDay = 24 * 60 * 60 * 1000; // Millisekunden pro Tag
    const difference = today.getTime() - dueDate.getTime();
    return Math.max(Math.floor(difference / oneDay), 0); // Negative Tage werden als 0 behandelt
};

export const calculateInterestAmount = (amount: number, annualInterestRate: number, overdueDays: number): number => {
    const dailyInterestRate = annualInterestRate / 365 / 100;
    return amount * dailyInterestRate * overdueDays;
};

export const calculateInterest = (bookings: Booking[], annualInterestRate: number): BookingWithInterest[] => {
    const today = new Date();

    return bookings.map(booking => {
        const dueDate = (booking?.date as Timestamp)?.toDate();
        const overdueDays = calculateOverdueDays(today, dueDate);
        const interestAmount = calculateInterestAmount(booking?.saldo >= 0 ? 0 : Math.abs(booking.saldo), annualInterestRate, overdueDays);

        return {
            booking,
            overdueDays,
            interestAmount
        };
    });
};

export const determineDunningStatus = (bookings: Booking[]): BookingStatusOptions => {
    let hasPaid = false;
    let hasNotPaid = false;
    let hasPartiallyPaid = false;
    let hasExpected = false;

    for (const booking of bookings) {
        switch (booking.status) {
            case BookingStatusOptions.PAID:
                hasPaid = true;
                break;
            case BookingStatusOptions.NOT_PAID:
                hasNotPaid = true;
                break;
            case BookingStatusOptions.PARTIALLY_PAID:
                hasPartiallyPaid = true;
                break;
            case BookingStatusOptions.EXPECTED:
                hasExpected = true;
                break;
        }
    }

    if (hasExpected && !hasPaid && !hasNotPaid && !hasPartiallyPaid) {
        return BookingStatusOptions.EXPECTED;
    } else if (hasPaid && !hasNotPaid && !hasPartiallyPaid) {
        return BookingStatusOptions.PAID;
    } else if (hasNotPaid || hasPartiallyPaid) {
        return BookingStatusOptions.NOT_PAID;
    } else {
        return BookingStatusOptions.PARTIALLY_PAID;
    }
};

export const generatePaymentStatusActivities = (originalBookings: Booking[], updatedBookings: Booking[]): Activity[] => {
    const activities: Activity[] = [];

    originalBookings.forEach(originalBooking => {
        const updatedBooking = updatedBookings.find(b => b.id === originalBooking.id);
        if (updatedBooking && originalBooking.status !== updatedBooking.status) {
            // Fall: Von 'NOT_PAID' oder 'PARTIALLY_PAID' zu 'PAID'
            if ((originalBooking.status === BookingStatusOptions.NOT_PAID || originalBooking.status === BookingStatusOptions.PARTIALLY_PAID) && updatedBooking.status === BookingStatusOptions.PAID) {
                activities.push({
                    date: new Date(),
                    icon: 'mat_outline:check',
                    description: `<strong>Nahaus.de</strong> hat die Sollstellung <strong>${updatedBooking?.text}</strong> als vollständig bezahlt erkannt`
                });
            }
            // Fall: Von 'NOT_PAID' zu 'PARTIALLY_PAID'
            else if (originalBooking.status === BookingStatusOptions.NOT_PAID && updatedBooking.status === BookingStatusOptions.PARTIALLY_PAID) {
                activities.push({
                    date: new Date(),
                    icon: 'mat_outline:warning',
                    description: `<strong>Nahaus.de</strong> hat die Sollstellung <strong>${updatedBooking?.text}</strong> als teilweise bezahlt erkannt`
                });
            }
        }
    });

    return activities;
};
