import {Timestamp} from "firebase/firestore";
import {NahausImages} from "@shared/models/image.interface";

/**
 * @author Anthony Nahas
 * @since 31.05.2024
 * @version 1.0
 */
export interface DigitalProtocolDivision {
    id?: string;
    customerID?: string;
    digitalProtocolID?: string;

    createdAt?: Timestamp | Date;
    updatedAt?: Timestamp | Date;

    // content
    title?: string; // Küche, Wohnzimmer, Room1, Room2, Bad, WC, Keller
    html?: string;
    imgs?: NahausImages;
}

export const defaultResidentialDivision: DigitalProtocolDivision[] = [
    {title: 'Küche', html: null},
    {title: 'Badezimmer', html: null},
    {title: 'Gäste-WC', html: null},
    {title: 'Flur', html: null},
    {title: 'Räumlichkeiten', html: null},
    {title: 'Notizen', html: null},
]
