import {Routes} from '@angular/router';
import {PublicProfileSettingsResolver} from '@settings/resolvers/publicProfile.settings.resolver';
import {LogoSettingsResolver} from '@settings/resolvers/logo.settings.resolver';
import {PermissionsGuard} from "../../../guards/permissions.guard";
import {DigitalProtocolMainComponent} from "./components/digital-protocol-main/digital-protocol-main.component";
import {DigitalProtocolListComponent} from "./components/digital-protocol-list/digital-protocol-list.component";
import {CreateDigitalProtocolComponent} from "./components/create-digital-protocol/create-digital-protocol.component";

export enum DigitalProtocolAccessLayerOptions {
    PERIOD = "PERIOD",
    PROPERTY = "PROPERTY",
    ACCOUNT = "ACCOUNT",
}

export type DigitalProtocolAccessLayer =
    DigitalProtocolAccessLayerOptions.PERIOD
    | DigitalProtocolAccessLayerOptions.PROPERTY
    | DigitalProtocolAccessLayerOptions.ACCOUNT

export default [
    {
        path: '',
        component: DigitalProtocolMainComponent,
        data: {
            // permission: PermissionOptions.BOOKINGS_READ
        },
        resolve: {
            publicProfile: PublicProfileSettingsResolver,
            logo: LogoSettingsResolver,

        },
        canActivate: [PermissionsGuard],
        children: [
            {
                path: '',
                redirectTo: 'alle',
                pathMatch: 'full'
            },
            {
                path: '',
                component: DigitalProtocolListComponent,
                data: {
                    // accessLayer: BookingsAccessLayerOptions.ACCOUNT,
                    // permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
            {
                path: 'alle',
                component: DigitalProtocolListComponent,
                data: {
                    // accessLayer: BookingsAccessLayerOptions.ACCOUNT,
                    // permission: PermissionOptions.BOOKINGS_READ
                },
                canActivate: [PermissionsGuard],
            },
        ]
    },
    {
        path: ':id',
        component: CreateDigitalProtocolComponent
    }
] as Routes;
