import { EventEmitter, Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';

import { Hit } from 'angular-instantsearch/instantsearch/instantsearch';
import { BehaviorSubject, Subject } from 'rxjs';
import { Property } from '../models/property.interface';

@Injectable({ providedIn: 'root' })
export class PropertiesOldService {

  onTableViewChanged$: Subject<string[]> = new BehaviorSubject(null);
  onSelectedChanged: BehaviorSubject<Property> = new BehaviorSubject<Property>(null);
  onCurrentModelChanged$: BehaviorSubject<Property> = new BehaviorSubject<Property>(null);

  // suggestedPlaces: Place[];
  // sort
  onSortChanged$: BehaviorSubject<Sort> = new BehaviorSubject<Sort>(null);
  // ALgolia
  onNewHits: EventEmitter<Hit[]> = new EventEmitter<Hit[]>();
  // stat
  onCountChanged$: BehaviorSubject<number> = new BehaviorSubject(null);
  // ACTIONS
  onExportAsPdfRequested: Subject<void> = new Subject();


  private _selected: Property;

  set selected(value: Property) {
    this._selected = value;
    this.onSelectedChanged.next(value);
  }

}
