import {Activity} from '@activities/models/activities.types';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {Label, Note, Task} from '@msa/components/msa-list/notes-details/notes.types';
import {MsaActivitiesService} from '@msa/services/msa-activities.service';
import firebase from 'firebase/compat';
import {Observable, of, Subject} from 'rxjs';
import {Dunning} from "../../models/dunning.interface";
import {DunningsActivitiesService} from "../../services/dunnings-activities.service";
import {MatMenuModule} from '@angular/material/menu';
import {MatRippleModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TextFieldModule} from '@angular/cdk/text-field';
import {FormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {AsyncPipe, NgClass, NgFor, NgIf} from '@angular/common';


@Component({
    selector: 'dunning-notes-details',
    templateUrl: './dunning-notes-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MsaActivitiesService, DunningsActivitiesService],
    standalone: true,
    imports: [NgIf, MatButtonModule, MatIconModule, FormsModule, TextFieldModule, NgFor, MatCheckboxModule, NgClass, MatProgressSpinnerModule, MatTooltipModule, MatRippleModule, MatMenuModule, MatDialogModule, AsyncPipe]
})
export class DunningNotesDialogComponent implements OnInit, OnDestroy {

    saving: boolean;

    dunning: Dunning;
    user: firebase.User | null;

    note$: Observable<Note>;
    labels$: Observable<Label[]>;

    noteChanged: Subject<Note> = new Subject<Note>();
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private dunningsActivitiesService: DunningsActivitiesService,
        @Inject(MAT_DIALOG_DATA) private _data: { dunning: Dunning, user: firebase.User | null, note: Note },
        private _matDialogRef: MatDialogRef<DunningNotesDialogComponent>
    ) {
        this.dunning = this._data.dunning;
        this.user = this._data.user;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Edit
        if (this._data.note.id) {
            // Request the data from the server
            // this._notesService.getNoteById(this._data.note.id).subscribe();

            // Get the note
            // this.note$ = this._notesService.note$;
        }
        // Add
        else {
            // Create an empty note
            const note = {
                id: null,
                title: '',
                content: '',
                tasks: null,
                image: null,
                reminder: null,
                labels: [],
                archived: false,
                createdAt: null,
                updatedAt: null
            };

            this.note$ = of(note);
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create a new note
     *
     * @param note
     */
    createNote(note: Note): void {
        console.log('create note', note);
        this.saving = true;

        const activity: Activity = {
            user: this.user?.displayName,
            date: new Date(),
            icon: 'heroicons_outline:pencil',
            description: '<strong>' + this.user?.displayName + '</strong> hat eine neue Notiz hinzugefügt',
            extraContent: '<div class="font-bold">' + note?.title + '</div><br>' +
                '<div>' + note?.content + '</div>'
        };

        this.dunningsActivitiesService.setParentPathForPeriod(this.dunning?.propertyID, this.dunning?.unitID, this.dunning?.periodID, this.dunning?.id);

        this.dunningsActivitiesService
            .collection()
            .add(activity)
            .then((res) => {
                console.log('activity has been added', res);
            })
            .catch((err) => console.error('Error', err))
            .finally(() => {
                this.saving = false;
                this._matDialogRef.close();
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * Upload image to given note
     *
     * @param note
     * @param fileList
     */
    uploadImage(note: Note, fileList: FileList): void {
        // Return if canceled
        if (!fileList.length) {
            return;
        }

        const allowedTypes = ['image/jpeg', 'image/png'];
        const file = fileList[0];

        // Return if the file is not allowed
        if (!allowedTypes.includes(file.type)) {
            return;
        }

        this._readAsDataURL(file).then((data) => {

            // Update the image
            note.image = data;

            // Update the note
            this.noteChanged.next(note);
        });
    }

    /**
     * Remove the image on the given note
     *
     * @param note
     */
    removeImage(note: Note): void {
        note.image = null;

        // Update the note
        this.noteChanged.next(note);
    }

    /**
     * Add an empty tasks array to note
     *
     * @param note
     */
    addTasksToNote(note): void {
        if (!note.tasks) {
            note.tasks = [];
        }
    }

    /**
     * Add task to the given note
     *
     * @param note
     * @param task
     */
    addTaskToNote(note: Note, task: string): void {
        if (task.trim() === '') {
            return;
        }

        // Add the task
        // this._notesService.addTask(note, task).subscribe();
    }

    /**
     * Remove the given task from given note
     *
     * @param note
     * @param task
     */
    removeTaskFromNote(note: Note, task: Task): void {
        // Remove the task
        note.tasks = note.tasks.filter(item => item.id !== task?.id);

        // Update the note
        this.noteChanged.next(note);
    }

    /**
     * Update the given task on the given note
     *
     * @param note
     * @param task
     */
    updateTaskOnNote(note: Note, task: Task): void {
        // If the task is already available on the item
        if (task?.id) {
            // Update the note
            this.noteChanged.next(note);
        }
    }

    /**
     * Is the given note has the given label
     *
     * @param note
     * @param label
     */
    isNoteHasLabel(note: Note, label: Label): boolean {
        return !!note.labels.find(item => item.id === label.id);
    }

    /**
     * Toggle the given label on the given note
     *
     * @param note
     * @param label
     */
    toggleLabelOnNote(note: Note, label: Label): void {
        // If the note already has the label
        if (this.isNoteHasLabel(note, label)) {
            note.labels = note.labels.filter(item => item.id !== label.id);
        }
        // Otherwise
        else {
            note.labels.push(label);
        }

        // Update the note
        this.noteChanged.next(note);
    }

    /**
     * Toggle archived status on the given note
     *
     * @param note
     */
    toggleArchiveOnNote(note: Note): void {
        note.archived = !note.archived;

        // Update the note
        this.noteChanged.next(note);

        // Close the dialog
        this._matDialogRef.close();
    }

    /**
     * Update the note details
     *
     * @param note
     */
    updateNoteDetails(note: Note): void {
        this.noteChanged.next(note);
    }

    /**
     * Delete the given note
     *
     * @param note
     */
    deleteNote(note: Note): void {
        // this._notesService.deleteNote(note)
        //   .subscribe((isDeleted) => {
        //
        //     // Return if the note wasn't deleted...
        //     if (!isDeleted) {
        //       return;
        //     }
        //
        //     // Close the dialog
        //     this._matDialogRef.close();
        //   });
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item?.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Read the given file for demonstration purposes
     *
     * @param file
     */
    private _readAsDataURL(file: File): Promise<any> {
        // Return a new promise
        return new Promise((resolve, reject) => {

            // Create a new reader
            const reader = new FileReader();

            // Resolve the promise on success
            reader.onload = (): void => {
                resolve(reader.result);
            };

            // Reject the promise on error
            reader.onerror = (e): void => {
                reject(e);
            };

            // Read the file as the
            reader.readAsDataURL(file);
        });
    }
}
