import { Injectable } from '@angular/core';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';
import { Tenant } from '../../properties/features/units/features/period/features/tenants/models';

@Injectable({
  providedIn: 'root'
})
export class TenantsService extends AbstractFirestoreService<Tenant> {

  COLLECTION = 'tenants';

  constructor(authService: AuthService) {
    super(authService);
  }

  setPath(propertyID: string, unitID: string, periodID: string): void {
    this.parentPath = `properties/${propertyID}/units/${unitID}/periods/${periodID}`;
  }
}
