import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { NahausTier, NahausTiersOptions } from '@users/models/user.interface';
import { Observable, of, take } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsSubscriberCustomerGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private auth: AuthService,
              private router: Router) {
  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canAccess();
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canAccess();
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canAccess();
  }

  canAccess(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.tier$.pipe(filter(tier => tier !== undefined), take(1), map((tier: NahausTier) => {
      const canAccess = [NahausTiersOptions.STARTER, NahausTiersOptions.PRO, NahausTiersOptions.ENTERPRISE].includes(tier);
      console.log('IsSubscriberCustomerGuard --> canAccess --> ', canAccess, tier);
      if (canAccess) {
        return true;
      } else {
        this.router.navigate(['fehler', '430'])
          .then(() => null)
          .catch((err) => console.error('Error while redirecting to error page 430: ', err));
      }
    }));
  }
}
