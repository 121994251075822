import {Timestamp} from "firebase/firestore";
import {NahausBookingsAccount} from "../../bookingsAccounts/models/NahausBookingsAccount.interface";
import {Property} from "../../properties/models/property.interface";
import {NahausImages} from "@shared/models/image.interface";

/**
 * @author Anthony Nahas
 * @since 03.01.24
 * @updated 23.02.24
 * @version 1.1
 */
export interface Expense {
    // DATA SECTION
    id: string;
    createdAt?: Date | Timestamp;
    updatedAt?: Date | Timestamp;
    customerID?: string;
    // propertyID?: string;
    property?: Partial<Property>;
    unitsID?: string[];
    periodID?: string;
    managementID?: string;

    // CORE SECTION
    type?: ExpenseType;
    description: string; // Kurze Beschreibung oder Zweck der Ausgabe
    date?: Date | Timestamp;
    category?: string; // You could also use an enum type for predefined categories - Eine Einteilung der Ausgaben in verschiedene Kategorien, wie zum Beispiel Reparaturen, Wartung, Versicherungen, Nebenkosten, Verwaltungskosten usw.
    amount: number;
    amountForNK?: number; // it will be only used and locally calculated when creating a nk
    paymentMethod?: string; // This could also be an enum for predefined methods like 'BankTransfer', 'Cash', 'CreditCard', etc.

    associatedProperty: string; // Property ID or some unique identifier
    associatedTenantOrLease?: string; // Optional, only if the expense is directly related to a specific tenant or lease
    receiptDocument?: string; // URL or path to the image/document of the receipt

    // SUPPLIER SECTION
    supplierOrService: string; // Information about the supplier or service provider

    // TAX SECTION
    includesVAT: boolean; // Gibt an, ob die Ausgabe MWST beinhaltet
    vatRate?: number; // Optional, der MWST-Satz in Prozent, falls zutreffend
    vatAmount?: number; // Optional, der Betrag der MWST, berechnet aus dem Gesamtbetrag

    // EXTRA SECTION
    notes?: string; // Optional field for additional details
    nahausBookingsAccount?: NahausBookingsAccount;

    // Anpassung für Mehrfachauswahl des Abrechnungsjahres
    accountingYears?: number[];

    // Erweiterung für Leistungszeitraum
    serviceStartDate?: Date | Timestamp; // Startdatum der Leistung
    serviceEndDate?: Date | Timestamp;   // Enddatum der Leistung

    // upload pdf or images
    files?: NahausImages;

    // Transactions related properties
    transactionID?: string;

    // for nk calculation
    effectiveStartDate?: Date | Timestamp;
    effectiveEndDate?: Date | Timestamp;
    effectiveTotalDay?: number;
}

export enum ExpenseTypeOptions {
    EXPENSE = 'EXPENSE',
    CREDIT = 'CREDIT',
}

export type ExpenseType = ExpenseTypeOptions.EXPENSE | ExpenseTypeOptions.CREDIT;

export type Expenses = Expense[];

// Example of using enums for categories and payment methods
enum ExpenseCategory {
    Repairs,
    Maintenance,
    Insurance,
    AdministrationCosts,
    // ... other categories
}

enum PaymentMethod {
    BankTransfer,
    Cash,
    CreditCard,
    // ... other methods
}
