import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDrawer, MatSidenavModule} from '@angular/material/sidenav';
import {ActivatedRoute, RouterLink, RouterOutlet} from '@angular/router';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher';

import {Subject, takeUntil} from 'rxjs';
import {DigitalProtocolSidebarComponent} from "../digital-protocol-sidebar/digital-protocol-sidebar.component";
import {NgClass} from "@angular/common";
import {DigitalProtocolAccessLayerOptions} from "../../digital-protocol.routing";
import {DigitalProtocolHelperService} from "../../services/digital-protocol-helper.service";

@Component({
    selector: 'carded-left-sidebar-2-normal-scroll',
    templateUrl: './digital-protocol-main.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatIconModule, RouterLink, MatButtonModule, MatSidenavModule, DigitalProtocolSidebarComponent, RouterOutlet, NgClass],
    providers: [DigitalProtocolHelperService]
})
export class DigitalProtocolMainComponent implements OnInit, OnDestroy {

    @ViewChild('matDrawer', {static: true}) drawer: MatDrawer;

    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;

    accessLayer: DigitalProtocolAccessLayerOptions;
    bookingsAccessLayerOptions = DigitalProtocolAccessLayerOptions;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(private _fuseMediaWatcherService: FuseMediaWatcherService,
                private route: ActivatedRoute) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        console.log("DigitalProtocolMainComponent --> ", this.route.parent?.parent?.parent?.snapshot?.data)
        console.log("DigitalProtocolMainComponent --> ", this.route.parent?.parent?.snapshot?.data)
        console.log("DigitalProtocolMainComponent --> ", this.route.parent?.snapshot?.data)
        console.log("DigitalProtocolMainComponent --> ", this.route?.snapshot?.data)
        this.accessLayer = this.route.snapshot?.data?.accessLayer;
        console.log("DigitalProtocolMainComponent this.accessLayer  --> ", this.accessLayer);
        // this.onlyDeposit = this.route.snapshot?.data?.onlyDeposit;
        // this.onlyUnpaid = this.route.snapshot?.data?.onlyUnpaid;

        if (this.accessLayer === this.bookingsAccessLayerOptions.ACCOUNT) {
            // Subscribe to media changes
            this._fuseMediaWatcherService.onMediaChange$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(({matchingAliases}) => {
                    // Set the drawerMode and drawerOpened if
                    if (matchingAliases.includes('lg')) {
                        this.drawerMode = 'side';
                        this.drawerOpened = true;
                    } else {
                        this.drawerMode = 'over';
                        this.drawerOpened = false;
                    }
                });
        } else {
            this.drawerMode = 'over';
            this.drawerOpened = false;
        }


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
