import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import {Dunning, DunningTypeOptions, getNextDunningStage} from "../../models/dunning.interface";
import {Router} from "@angular/router";
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

@Component({
    selector: 'fuse-confirmation-dialog',
    templateUrl: './next-dunning-type-hint-dialog.component.html',
    styles: [
        `
            .fuse-confirmation-dialog-panel {
                @screen md {
                    @apply w-128;
                }

                .mat-mdc-dialog-container {

                    .mat-mdc-dialog-surface {
                        padding: 0 !important;
                    }
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatButtonModule, MatDialogModule, MatIconModule]
})
export class NextDunningTypeHintDialogComponent {

    message: string;
    dunning: Dunning;
    nextStage: DunningTypeOptions;

    /**
     * Constructor
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: Dunning,
                private dialogRef: MatDialogRef<NextDunningTypeHintDialogComponent>,
                private router: Router) {
        console.log("NextDunningTypeHintDialogComponent --> data", data);
        if (!data) {
            this.dialogRef.close(undefined);
        } else {
            this.dunning = data;
        }

        this.nextStage = getNextDunningStage(this.dunning?.type);
        this.message = this.generateDialogDescription(this.dunning?.type);
    }

    generateDialogDescription(currentStage: DunningTypeOptions): string {
        switch (currentStage) {
            case DunningTypeOptions.PAYMENT_REMINDER:
                return `Die aktuelle Mahnung ist eine <span class="font-extrabold">Zahlungserinnerung</span>. Wir empfehlen, zur <span class="font-extrabold">Ersten Mahnung</span> überzugehen, um die Dringlichkeit zu betonen.`;

            case DunningTypeOptions.FIRST_REMINDER:
                return `Sie befinden sich derzeit in der Phase der <span class="font-extrabold">Ersten Mahnung'. Es ist ratsam, zur <span class="font-extrabold">Zweiten Mahnung</span> zu wechseln, um den Druck zu erhöhen.`;

            case DunningTypeOptions.SECOND_REMINDER:
                return `Sie sind nun bei der <span class="font-extrabold">Zweiten Mahnung</span> angelangt. Der nächste Schritt wäre die <span class="font-extrabold">Letzte Mahnung</span>, um die Ernsthaftigkeit der Situation zu unterstreichen.`;

            case DunningTypeOptions.FINAL_REMINDER:
                return `Sie sind bereits bei der <span class="font-extrabold">Letzten Mahnung</span>. Weitere Schritte könnten rechtliche Maßnahmen oder andere ernsthafte Konsequenzen beinhalten.`;

            default:
                return "Der Status der Mahnung konnte nicht ermittelt werden.";
        }
    }

    goToNextStage() {
        // http://localhost:4213/immobilien/vLtuMdskBrPwR10Hxbm2/einheit/ne8jxX2b0v2U2olW7xxM/buchungen/eYJz30HTAUS5iWbdGKSO/mahnwesen/erstellen
        const propertyID = this.dunning?.propertyID;
        const unitID = this.dunning?.unitID;
        const periodID = this.dunning?.periodID;
        this.router.navigate(['/immobilien', propertyID, 'einheit', unitID, 'buchungen', periodID, 'mahnwesen', 'erstellen'], {
            queryParams: {
                nextStage: this.nextStage
            },
            queryParamsHandling: 'merge'
        }).then(() => this.dialogRef.close(undefined))
    }
}
