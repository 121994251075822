<div class="cropper-container">
	<img #cropper class="image-cropper"/>
</div>

<!--<div class="w-40 h-40">-->
<!--	<img *ngIf="this.cropped" [src]="this.cropped">-->
<!--</div>-->

<!-- Actions -->
<div *ngIf="!this.loadingImage; else loading"
     class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

	<!-- Cancel -->
	<button [matDialogClose]="undefined" mat-stroked-button>
		Abbrechen
	</button>

	<button (click)="getImageBlob()"
	        color="primary"
	        mat-flat-button>
		Fertig
	</button>

</div>

<ng-template #loading>
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
