import { Injectable } from '@angular/core';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';
import { NahausContactTag } from '../models';

@Injectable()
export class ContactsTagsService extends AbstractFirestoreService<NahausContactTag> {

    selectedTags: string[] = []
  COLLECTION = 'contactsTags';

  constructor(authService: AuthService) {
    super(authService);
  }
}
