<mat-form-field [class]="this.formFieldClass">
	<mat-icon
			[svgIcon]="'heroicons_solid:search'"
			class="icon-size-5"
			matPrefix></mat-icon>
	<input
      #searchInput
	  (keyup)="handleChange($event)"
	  [autocomplete]="'off'"
	  [disabled]="this.disabled"
	  [matAutocomplete]="auto"
	  [placeholder]="this.placeholder"
	  autocapitalize="none"
	  autocomplete="off"
	  autocorrect="off"
	  id="search"
	  matInput
	  spellcheck="false"
	  type="text">

	<mat-autocomplete
      #auto="matAutocomplete">

		<ng-container *ngFor="let index of state.indices || []">
			<mat-option (click)="onQuerySuggestionClick.emit(option)"
			            *ngFor="let option of index.hits"
			            style=" height: 70px; line-height: 20px;">
				<div class="flex flex-col">
                    <span *ngIf="option?._highlightResult?.contactData?.name"
                          [innerHTML]="option?._highlightResult?.contactData?.name?.value"
                          matLine></span>
					<span *ngIf="!option?._highlightResult?.contactData?.name"
					      [innerHTML]="option?._highlightResult?.contactData?.firstName?.value + ' ' +option?._highlightResult?.contactData?.lastName?.value"
					      matLine></span>
					<small [innerHTML]="option?._highlightResult?.contactData?.email?.value" matLine></small>
					<small [innerHTML]="option?._highlightResult?.target?.property?.value" matLine></small>
				</div>
			</mat-option>
		</ng-container>
	</mat-autocomplete>
</mat-form-field>


