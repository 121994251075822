import {DEFAULT_CURRENCY_CODE, enableProdMode, importProvidersFrom, LOCALE_ID} from '@angular/core';

import {environment} from 'environments/environment';
import {AppComponent, CustomDateAdapter} from './app/app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {AuthEffects} from '@nahausStore/effects/auth.effects';
import {EffectsModule} from '@ngrx/effects';
import {appReducer} from './app/store/reducers/app.reducer';
import {StoreModule} from '@ngrx/store';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {GoogleTagManagerModule} from 'angular-google-tag-manager';
import {NgxCurrencyModule} from 'ngx-currency';
import {QuillModule} from 'ngx-quill';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {MomentModule} from 'ngx-moment';
import {NgAisModule} from 'angular-instantsearch';
import {HttpClient} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import {LayoutModule} from 'app/layout/layout.module';
import {CoreModule} from 'app/core/core.module';
import {FuseMockApiModule} from '@fuse/lib/mock-api';
import {appConfig} from 'app/core/config/app.config';
import {FuseConfigModule} from '@fuse/services/config-old';
import {FuseModule} from '@fuse';
import {getStorage, provideStorage} from '@angular/fire/storage';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {
    connectFirestoreEmulator,
    initializeFirestore,
    persistentLocalCache,
    persistentMultipleTabManager,
    provideFirestore
} from '@angular/fire/firestore';
import {getApp, initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {AngularFireModule} from '@angular/fire/compat';
import {NgOptimizedImage} from '@angular/common';
import {appRoutes} from './app/app.routing';
import {ExtraOptions, PreloadAllModules, RouterModule} from '@angular/router';
import {provideAnimations} from '@angular/platform-browser/animations';
import {bootstrapApplication, BrowserModule} from '@angular/platform-browser';
import {mockApiServices} from 'app/mock-api';
import {provideFuse} from '@fuse/fuse.provider';
import {AngularFireStorageModule, USE_EMULATOR as USE_STORAGE_EMULATOR} from '@angular/fire/compat/storage';
import {AngularFirestoreModule, USE_EMULATOR as USE_FIRESTORE_EMULATOR} from '@angular/fire/compat/firestore';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MAT_CHIPS_DEFAULT_OPTIONS} from '@angular/material/chips';
import {MyCustomPaginatorIntl} from '@shared/classes/MyCustomPaginatorIntl';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {LANGUAGE_CODE, USE_DEVICE_LANGUAGE, USE_EMULATOR as USE_AUTH_EMULATOR} from '@angular/fire/compat/auth';
import {
    AngularFireFunctionsModule,
    REGION,
    USE_EMULATOR as USE_FUNCTIONS_EMULATOR
} from '@angular/fire/compat/functions';
import {HttpLoaderFactory} from './app/app.module';
import {
    AngularFireAnalyticsModule,
    APP_NAME,
    APP_VERSION,
    ScreenTrackingService,
    UserTrackingService
} from '@angular/fire/compat/analytics';
import {AngularFirePerformanceModule, PerformanceMonitoringService} from '@angular/fire/compat/performance';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import * as moment from "moment/moment";
import {Platform} from "@angular/cdk/platform";

const googleMapsParams = {
    apiKey: environment.GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
    language: 'de',
    region: 'DE'
};
const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    initialNavigation: 'enabledBlocking'
};


if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule.withServerTransition({appId: 'serverApp'}), RouterModule.forRoot(appRoutes, routerConfig), NgOptimizedImage,
            // Angular Fire
            AngularFireModule.initializeApp(environment.firebase), AngularFirestoreModule.enablePersistence(), AngularFireFunctionsModule, AngularFireStorageModule, AngularFirePerformanceModule, AngularFireAnalyticsModule, provideFirebaseApp(() => initializeApp(environment.firebase)), provideFirestore(() => {
                const firestore = initializeFirestore(getApp(), {
                    localCache: persistentLocalCache({
                        tabManager: persistentMultipleTabManager()
                    }),
                });
                // persistentMultipleTabManager();
                if (environment.useEmulators) {
                    connectFirestoreEmulator(firestore, 'localhost', 8080);
                }
                return firestore;
            }),
            // provideFirestore(() => {
            //     const firestore = getFirestore();
            //
            //     // persistentMultipleTabManager();
            //     if (environment.useEmulators) {
            //         connectFirestoreEmulator(firestore, 'localhost', 8080);
            //     }
            //
            //     return firestore;
            // }),
            provideAuth(() => getAuth()), provideStorage(() => getStorage()),
            // provideAppCheck(() =>
            //   initializeAppCheck(undefined, {
            //     provider: new ReCaptchaV3Provider(environment.reCaptchaV3Key),
            //     isTokenAutoRefreshEnabled: true
            //   })
            // ),
            // Fuse, FuseConfig & FuseMockAPI
            FuseModule, FuseConfigModule.forRoot(appConfig), FuseMockApiModule.forRoot(mockApiServices),
            // NEW FOR STANDALONE COMPONENTS
            // Core module of your application
            CoreModule,
            // Layout module of your application
            LayoutModule,
            // 3rd party modules
            MatGoogleMapsAutocompleteModule.forRoot(environment.GOOGLE_MAPS_API_KEY), TranslateModule.forRoot({
                useDefaultLang: true,
                defaultLanguage: 'de',
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient]
                }
            }), NgAisModule.forRoot(), MomentModule.forRoot(), NgxWebstorageModule.forRoot(), QuillModule.forRoot(), NgxCurrencyModule, GoogleTagManagerModule.forRoot({
                id: environment.GTM_ID
            }),
            // Material
            MatSnackBarModule,
            // Ngrx
            StoreModule.forRoot({app: appReducer}), EffectsModule.forRoot([AuthEffects]), StoreDevtoolsModule.instrument({
                maxAge: 25,
                logOnly: environment.production
            }), ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: environment.production,
                // Register the ServiceWorker as soon as the app is stable
                // or after 30 seconds (whichever comes first).
                registrationStrategy: 'registerWhenStable:30000'
            }), ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: environment.production,
                // Register the ServiceWorker as soon as the application is stable
                // or after 30 seconds (whichever comes first).
                registrationStrategy: 'registerWhenStable:30000'
            })),
        PerformanceMonitoringService,
        ScreenTrackingService,
        UserTrackingService,
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: moment.ISO_8601
                },
                display: {
                    dateInput: 'LL',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY'
                }
            }
        },
        {provide: LOCALE_ID, useValue: 'de'},
        {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, Platform]},
        {provide: APP_NAME, useValue: environment.customer},
        {provide: APP_VERSION, useValue: environment.version},
        // { provide: USE_DEVICE_LANGUAGE, useValue: false },
        {provide: REGION, useValue: 'europe-west3'},
        {provide: LANGUAGE_CODE, useValue: 'de'},
        {provide: USE_DEVICE_LANGUAGE, useValue: true},
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
        {provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl},
        {
            provide: MAT_CHIPS_DEFAULT_OPTIONS,
            useValue: {
                separatorKeyCodes: [ENTER, COMMA]
            }
        },
        // firebase emulators
        {provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:9099'] : undefined},
        {provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined},
        {provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined},
        {provide: USE_STORAGE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9199] : undefined},
        // { provide: USE_DATABASE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9000] : undefined },
        // TODO: new for standalone component 04.04.24
        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            fuse: {
                layout: 'classy',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-default',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
        provideAnimations()
    ]
})
    .catch(err => console.error(err));
