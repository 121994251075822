import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {MatDialog} from '@angular/material/dialog';
import {
    ImageEditorDialog,
    ImageEditorDialogComponent
} from '@shared/components/filepond/image-editor-dialog/image-editor-dialog.component';
import {NahausImage} from '@shared/models/image.interface';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {FilePond, FilePondOptions} from 'filepond';
import {finalize, tap} from 'rxjs/operators';
import {CommonMimeType} from "../../../../modules/admin/file-manager/models/nahausFile";
import {closestImage} from "@shared/pipes/to-thumb.pipe";
import {firstValueFrom, take} from "rxjs";
import {FilePondModule} from 'ngx-filepond';

@Component({
    selector: 'abstract-file-pond',
    templateUrl: './file-pond-abstract.component.html',
    styleUrls: ['./file-pond-abstract.component.scss']
    // changeDetection: ChangeDetectionStrategy.OnPush
    ,
    standalone: true,
    imports: [FilePondModule]
})
export class FilePondAbstractComponent implements OnInit, OnChanges {

    @Input() uploadedImages: NahausImage[];

    @Input() labelIdle = `Bilder per Drag-and-Drop hierher ziehen oder <span class="filepond--label-action"> Durchsuchen </span>`;

    allowProcess: boolean = false;
    instantUpload: boolean = false;

    maxMB: number = 3;
    maxFiles: number = 5;

    @ViewChild('myPond') myPond: FilePond;

    imgFirestoreAndStorageMap: Map<string, string> = new Map<string, string>();

    imgDocsRef: any[] = [];

    pondOptions: FilePondOptions;

    pondFiles: FilePondOptions['files'] = [
        // {
        //   source: 'https://firebasestorage.googleapis.com/v0/b/dev-nahaus-de.appspot.com/o/images%2F1672443379769_WhatsApp%20Image%202022-12-30%20at%2021.43.00%201.jpeg?alt=media&token=bd706354-a6fb-447a-8d2b-e4d4b668af5f',
        //   options: {
        //     type: 'local'
        //   }
        // }
    ];


    constructor(private storage: AngularFireStorage,
                private cf: ChangeDetectorRef,
                private dialog: MatDialog,
                private service: AbstractFirestoreService<any>) {
    }

    ngOnInit(): void {
        this.pondOptions = {
            imageEditEditor: {

                // Called by FilePond to edit the image
                // - should open your image editor
                // - receives file object and image edit instructions
                open: (file, instructions) => {
                    this.cf.markForCheck();
                    // open editor here
                    console.log('file --> ', file);
                    console.log('instructions --> ', instructions);
                    console.log('getFiles ', this.myPond.getFiles());
                    const allFiles = this.myPond.getFiles();

                    const data: ImageEditorDialog = {file, instructions};

                    setTimeout(() => {
                        this.dialog.open(ImageEditorDialogComponent, {
                            panelClass: 'fuse-confirmation-dialog-panel',
                            autoFocus: false,
                            maxHeight: '90vh',
                            data
                        }).afterClosed().subscribe((fileCropped) => {
                            if (fileCropped) {
                                this.myPond.addFile(fileCropped)
                                    .then(() => {
                                        console.log('image added', this.myPond);
                                        const index = allFiles.findIndex(item => item?.file === file);
                                        console.log('index --> ', index);
                                        this.myPond.removeFile(index + 1);
                                        // setTimeout(() => this.myPond.removeFile({ file }), 50);
                                        this.cf.markForCheck();
                                    })
                                    .catch((err) => console.error('Error: --> ', err));
                            }
                        });
                    }, 10);
                    this.cf.markForCheck();
                },

                // Callback set by FilePond
                // - should be called by the editor when user confirms editing
                // - should receive output object, resulting edit information
                onconfirm: (output) => {
                },

                // Callback set by FilePond
                // - should be called by the editor when user cancels editing
                oncancel: () => {
                },

                // Callback set by FilePond
                // - should be called by the editor when user closes the editor
                onclose: () => {
                }
            },
            allowProcess: this.allowProcess,
            instantUpload: this.instantUpload,
            allowMultiple: true,
            fileRenameFunction: file => file.name.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, ''),
            labelIdle: this.labelIdle,
            labelInvalidField: 'Feld enthält ungültige Dateien',
            labelFileWaitingForSize: 'Warten auf Größe',
            labelFileSizeNotAvailable: 'Größe nicht verfügbar',
            labelFileLoading: 'bitte warten...',
            labelFileLoadError: 'Fehler beim Laden',
            labelFileProcessing: 'Hochladen',
            labelFileProcessingComplete: 'Hochladen abgeschlossen',
            labelFileProcessingAborted: 'Hochladen abgebrochen',
            labelFileProcessingError: 'Fehler beim Hochladen',
            labelFileProcessingRevertError: 'Fehler beim Zurücksetzen',
            labelFileRemoveError: 'Fehler beim Entfernen',
            labelTapToCancel: 'Tippen Sie zum Abbrechen\n',
            labelTapToRetry: 'Tippen Sie auf, um es erneut zu versuchen\n',
            labelTapToUndo: 'Tippen Sie zum Rückgängigmachen\n',
            labelButtonRemoveItem: 'Entfernen',
            labelButtonAbortItemLoad: 'Abbrechen',
            labelButtonRetryItemLoad: 'Wiederholen',
            labelButtonAbortItemProcessing: 'Stornieren',
            labelButtonUndoItemProcessing: 'Rückgängig machen',
            labelButtonRetryItemProcessing: 'Wiederholen',
            labelButtonProcessItem: 'Hochladen',
            // acceptedFileTypes: ['image/*', 'image/jpeg'],
            maxFileSize: `${this.maxMB}MB`,
            allowReorder: true,
            maxFiles: this.maxFiles,
            server: {
                process: (fieldName, file, metadataFile, load, error, progress, abort) => {
                    const id = this.service.createID();
                    const fileName = `${new Date().getTime()}_${file.name}`;
                    // const path = `images/${fileName}`;
                    const pathToBuild = `${this.service.parentPath}/images/${id}/${fileName}`;
                    const path = this.service.authService.buildPath(pathToBuild);
                    const metadata = {
                        contentType: file?.type || null,
                        size: file?.size || 0
                    };
                    console.log("metadataFile --> ", metadataFile);
                    console.log("metadata --> ", metadata);
                    console.log("file --> ", file);
                    // @ts-ignore
                    file.id = id;
                    const firestoreImage: NahausImage = {
                        id,
                        path,
                        type: metadata?.contentType as CommonMimeType,
                        size: metadata?.size
                    };
                    const uploadTask = this.storage.upload(path, file, metadata);
                    const ref = this.storage.ref(path);
                    uploadTask
                        .snapshotChanges()
                        .pipe(
                            tap(snap => {
                                progress(true, 1024, 1024);
                            }),
                            finalize(() => {
                                ref.getDownloadURL()
                                    .subscribe(downloadURL => {
                                        firestoreImage.url = downloadURL;
                                        this.service.doc(id).set(firestoreImage, {merge: true})
                                            .then(() => {
                                                this.imgFirestoreAndStorageMap.set(downloadURL, id);
                                                load(downloadURL);
                                            }).catch((err) => {
                                            console.error('Error: ', err);
                                            error(err);
                                        });
                                        return {
                                            abort: () => {
                                                abort();
                                            }
                                        };
                                    });
                            })
                        )
                        .subscribe();
                },
                load: (source, load, error, progress, abort, headers) => {
                    console.log('load source --> ', source);
                    console.log('load load --> ', load);
                    console.log('load headers --> ', headers);

                    // this.storage.refFromURL(source).
                    fetch(source)
                        .then(function (e: any) {
                            console.log('after fetching --> ', e);
                            return e.blob();
                        })
                        .then((blobFile) => {
                            firstValueFrom(this.storage.refFromURL(source).getMetadata().pipe(take(1)))
                                .then((metadata) => {
                                    console.log("metadata received from firestore --> ", metadata);
                                    const file = new File([blobFile], metadata?.name || 'bereits hochgeladenes Bild', {type: metadata?.contentType || 'image/'});
                                    load(file);
                                })
                                .catch((err) => {
                                    console.error("Error on loading metadata --> ", err)
                                    const file = new File([blobFile], 'bereits hochgeladenes Bild', {type: 'image/'});
                                    load(file);
                                })
                        })
                        .catch(function () {
                            console.error('Error: ', error);
                        });
                    return {
                        abort: e => {
                            console.log(e);
                            abort();
                        }
                    };
                },
                revert: (uniqueFileId, load, error) => {
                    // Should remove the earlier created temp file here
                    console.log('on revert --> ', uniqueFileId);
                    // ...
                    const id = this.imgFirestoreAndStorageMap.get(uniqueFileId);
                    this.service.doc(id).delete()
                        .then(() => {
                            this.storage.refFromURL(uniqueFileId)
                                .delete()
                                .subscribe(() => load());
                        })
                        .catch((err) => console.error(err));

                    // Can call the error method if something is wrong, should exit after
                    // error('oh my goodness');

                    // Should call the load method when done, no parameters required
                    // load();
                },
                remove: (source, load, error) => {
                    // Should somehow send `source` to server so server can remove the file with this source
                    console.log('on removing --> ', source, this.uploadedImages);
                    const imageToDelete: NahausImage = this.uploadedImages.find(img => img.url === source || img?.thumbs?.['256']?.downloadURL === source);
                    console.log('imageToDelete --> ', imageToDelete);

                    if (imageToDelete) {
                        // Should call the load method when done, no parameters required
                        this.service.doc(imageToDelete?.id)
                            .delete()
                            .then(() => load())
                            .catch((err) => error(err));
                    } else {
                        error('Es tut uns leid, aber beim Löschen dieses Bildes ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder wenden Sie sich an unseren Support, falls das Problem weiterhin besteht');
                    }
                }
            }
            // fake server to simulate loading a 'local' server file and processing a file
            // server: {
            //   process: (fieldName, file, metadata, load) => {
            //     // simulates uploading a file
            //     setTimeout(() => {
            //       load(Date.now().toString);
            //     }, 1500);
            //   },
            //   load: (source, load) => {
            //     // simulates loading a file from the server
            //     fetch(source).then(res => res.blob()).then(load);
            //   }
            // }
        };
        this.cf.markForCheck();
        // const interval = 2000;
        // setInterval(() => {
        //     console.log('interval', interval);
        // }, interval);
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('ngOnChanges --> ', changes);
        this.prepareUploadedImages(changes?.uploadedImages?.currentValue);
    }

    public pondHandleInit() {
        console.log('FilePond has initialised', this.myPond);
    }

    pondHandleAddFile(event: any) {
        console.log('A file was added', event);
    }

    pondHandleRemoveFile(event: any) {
        console.log('A file was removed', event);
    }

    pondHandleActivateFile(event: any) {
        console.log('A file was activated', event);
    }

    prepareUploadedImages(images: NahausImage[]): void {
        console.log('prepareUploadedImages is now running...', images?.length);
        this.pondFiles = [];
        if (images?.length > 0) {
            images.forEach((img) => {
                this.pondFiles.push(
                    {
                        source: closestImage(img, 256) || img?.url,
                        options: {
                            type: 'local',
                            // metadata: {},
                            // mock file information
                            // file: {
                            // name: img?.name || 'test',
                            // size: null,
                            // size: img?.size,
                            // type: img?.type,
                            // type: 'image/',
                            // },
                        }
                    }
                );
                console.log('pondFiles --> ', this.pondFiles);
            });
        }

    }
}
