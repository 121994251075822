import {GermanAddress} from '@angular-material-extensions/google-maps-autocomplete';
import {NahausImage} from '@shared/models/image.interface';
import firebase from 'firebase/compat';
import {BankAccount} from '../../bankAccounts/models';
import {Landlord} from '../../landlords/models';
import {Manager} from '../../managers/models';
import {PropertyUnit} from '../features/units/models/unit.interface';
import {NahausContact} from '../../../../../../functions/src/core/contacts/models/nahausContact.interface';
import Timestamp = firebase.firestore.Timestamp;

/**
 * @author Anthony Nahas
 * @version 1.8
 * @since 12.19
 * @updated 022.011.23
 */
export interface Property {
    id?: string;
    customerID?: string;
    h_id?: number; // 1
    h_id_text?: string; // --> H1
    address?: GermanAddress;
    createdAt?: Timestamp;
    updatedAt?: Date | Timestamp;
    units?: Partial<PropertyUnit>[];
    isLocked?: boolean; // ob die Immobilie gesperrt --> keine kann das sehen

    income?: PropertyUnitsRevenue;

    img?: NahausImage;
    imgs?: Array<any>;
    docs?: Array<any>;
    facilityManagers?: Array<any>; // Hausmeister
    isStairCleaningRequired?: boolean;
    cleaners?: Array<any>;
    knownIssues?: Array<any>;
    garages?: Array<string>;
    parking?: Array<string>;

    // data and properties
    area?: PropertyArea;
    type?: PropertyType;
    managementType?: ManagementType;
    constructionYear?: number;
    purchaseYear?: number;
    dateOfPurchase?: Date | Timestamp;
    dateOfConstruction?: Date | Timestamp;
    purchasePrice?: number; // Kaufpreis --> z.B: 250.000€
    lastKnownRenovationYear?: number; // Sanierung oder Renovierung

    manager?: Manager;
    // Landlord
    wegLandlordsName?: string; // für WEG und Sonderverwaltung --> sync name here
    landlord?: Landlord; // only if property.managementType = 'MIET'
    // todo deprecated
    landlords?: Array<Landlord>; // array of landlords id
    landlordsID?: Array<string>; // all landlord ids

    // BankAccount
    bankAccount?: BankAccount; // für Mietverwaltung
    wegBankAccount?: BankAccount; // für WEG Verwaltung - Hausgeldkonto
    reserveBankAccount?: BankAccount; // für WEG Verwaltung - Rücklagen Konto
    // todo deprecated
    bankAccounts?: BankAccount[];
    bankAccountsID?: Array<string>; // all BankAccount ids

    // Eigenschaften
    isGardeningRequired?: boolean;
    hasBasement?: boolean;
    hasGarden?: boolean;

    // Stats
    numberOfUnits?: number;

    // archived
    archived?: boolean;
    archivedDate?: Date | Timestamp;

    // deleted
    deleted?: boolean;
    deletedDate?: Date | Timestamp;

    // stats
    rentalStat?: PropertyRentalStat;
    contacts?: NahausContact

    // WEG
    // meaTotal?: number; // total number of Wohnungseigentumsanteil
    landSize?: number; // Grundstücksgröße in qm
    landValuePerSquareMeter?: number; // Indicates the land value per square meter based on the local land rate.
    propertyArea?:string;
    livingSpace?:string;
    isShortCut?:boolean;
    shortcutID?:string
}

export interface PropertyRentalStat {
    total?: number;
    rented?: number;
}

export enum PropertyType {
    HAUS = 'HAUS',
    EINFAMILIENHAUS = 'EINFAMILIENHAUS',
    MEHRFAMILIENHAUS = 'MEHRFAMILIENHAUS',
    REIHENHAUS = 'REIHENHAUS',
    REIHENMITTELHAUS = 'REIHENMITTELHAUS',
    BUNGALOW = 'BUNGALOW',
    BAUERNHAUS = 'BAUERNHAUS',
    DOPPELHAUSHAELFTE = 'DOPPELHAUSHAELFTE',
    VILLA = 'VILLA',
    GEBAEUDE = 'GEBAEUDE',
    GEWERBE = 'GEWERBE',
    MIET_UND_GEWERBE = 'MIET_UND_GEWERBE',
    EIGENTUMWOHNUNG = 'EIGENTUMWOHNUNG',
    // BUEROGEBAEUDE = 'BUEROGEBAEUDE',
    // LAGERHALLE = 'LAGERHALLE',
    // FABRIKGEBAEUDE = 'FABRIKGEBAEUDE',
    // EINKAUFSZENTRUM = 'EINKAUFSZENTRUM',
    // HOTEL = 'HOTEL',
    // RESTAURANT = 'RESTAURANT',
    // PARKHAUS = 'PARKHAUS',
    // APARTMENTHAUS = 'APARTMENTHAUS',
    // LANDWIRTSCHAFTLICHE_FLAECHEN = 'LANDWIRTSCHAFTLICHE_FLAECHEN',
    // FREIZEITANLAGE = 'FREIZEITANLAGE',
    // SONSTIGE = 'SONSTIGE'
}

export enum ManagementType {
    MIET = 'MIET',
    WEG = 'WEG',
    MIET_UND_WEG = 'MIET_UND_WEG'
}

export interface PropertyUnitsRevenue {
    kalt?: number;
    nk?: number;
    warm?: number;
}

export interface PropertyArea {
    property?: number; // Grundstückfläche
    usable?: number; // Wohn/Nutzfläche
}

export interface PropertyPurchase {
    date?: Date;
    price?: {
        original?: number;
        agreed?: number;
    };
}

export interface RealEstateFinance {
    loans: Loan[];
}

export interface Loan {
    bankName?: string;
    number?: string; // Darlehen Nr.
    remainingDebt?: number; // Restschuld
    interestAndRepayment?: number; // Restschuld
}

export type Properties = Property[];
