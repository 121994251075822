import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FuseNavigationItem} from '@fuse/components/navigation/navigation.types';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {debounceTime, distinctUntilChanged, filter, take, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {BookingsHelperService} from "../../../services/bookings-helper.service";
import {TransactionsRange} from "../../../services/bookings-modular.service";
import {isEqual} from "lodash";
import {LocalStorage} from "ngx-webstorage";
import {
    YoutubeDialogComponent,
    YoutubeDialogData,
    YoutubeDialogResult
} from "@shared/youtube-dialog/dialog/youtube-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MatIconModule} from '@angular/material/icon';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatDividerModule} from '@angular/material/divider';
import {NgFor, NgIf} from '@angular/common';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FuseVerticalNavigationComponent} from '@fuse/components/navigation/vertical/vertical.component';

@Component({
    selector: 'demo-sidebar',
    template: `
        <div class="py-10">
            <!-- Add any extra content that might be supplied with the component -->
            <div class="extra-content">
                <ng-content></ng-content>
            </div>

            <!-- Fixed demo sidebar -->
            <!--            <div class="mx-6 text-3xl font-bold tracking-tighter">Buchhaltungmenü</div>-->
            <fuse-vertical-navigation
                [appearance]="'default'"
                [navigation]="menuData"
                [inner]="true"
                [mode]="'side'"
                [name]="'demo-sidebar-navigation'"
                [opened]="true"></fuse-vertical-navigation>

            <div class="mx-6 my-2">
                <div class="flex items-center">
                    <mat-form-field class="w-full">
                        <mat-label>Benutzerdefinierter Zeitraum</mat-label>
                        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                            <input formControlName="start" matStartDate placeholder="Start Datum">
                            <input formControlName="end" matEndDate placeholder="End Datum">
                        </mat-date-range-input>
                        <mat-hint *ngIf="!(this.range.get('start')?.value && this.range.get('end')?.value)">TT.MM.JJJJ –
                            TT.MM.JJJJ
                        </mat-hint>
                        <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
            </div>

            <div class="my-4 w-full">
                <mat-divider></mat-divider>
            </div>

            <div class="mx-6 my-2">
                <div class="flex items-center">
                    <mat-form-field appearance="fill" class="w-full">
                        <mat-label>Spalten auswählen</mat-label>
                        <mat-select (selectionChange)="updateColumns($event.value)" [value]="this.selectedColumns"
                                    multiple>
                            <mat-option *ngFor="let column of allColumns" [value]="column?.key">
                                {{ column?.displayName }}
                            </mat-option>
                        </mat-select>
                        <mat-icon matSuffix>view_column</mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div class="my-4 w-full">
                <mat-divider></mat-divider>
            </div>

            <fuse-vertical-navigation
                [appearance]="'default'"
                [navigation]="menuData2"
                [inner]="true"
                [mode]="'side'"
                [name]="'demo-sidebar-navigation2'"
                [opened]="true"></fuse-vertical-navigation>
        </div>
    `,
    styles: [
        `
            demo-sidebar fuse-vertical-navigation .fuse-vertical-navigation-wrapper {
                box-shadow: none !important;
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FuseVerticalNavigationComponent, MatFormFieldModule, MatDatepickerModule, FormsModule, ReactiveFormsModule, NgIf, MatDividerModule, MatSelectModule, NgFor, MatOptionModule, MatIconModule]
})
export class BookingsMainSidebarComponent implements OnInit, OnDestroy {

    @LocalStorage('BookingsMainSidebarComponent.openYoutube', true)
    openYoutube: boolean;

    range: FormGroup;
    menuData: FuseNavigationItem[];
    menuData2: FuseNavigationItem[];

    selectedColumns: string[];
    allColumns: {
        key: string;
        displayName: string
    }[] = [
        {key: 'transactionId', displayName: 'Bezeichnung'},
        {key: 'address', displayName: 'Immobilie'},
        {key: 'unitLabel', displayName: 'Einheit'},
        {key: 'name', displayName: 'Name'},
        {key: 'date', displayName: 'Datum'},
        {key: 'amount', displayName: 'Soll'},
        {key: 'haben', displayName: 'Zahlung'},
        {key: 'saldo', displayName: 'Saldo'},
        {key: 'status', displayName: 'Status'},
        {key: 'lastDunning.date', displayName: 'Mahnungsstatus'},
        // Weitere Spalten hier
    ];

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(private bookingsHelperService: BookingsHelperService,
                private dialog: MatDialog) {

        this.range = new FormGroup({
            start: new FormControl<Date | null>(null),
            end: new FormControl<Date | null>(null)
        });

        this.menuData = [
            {
                title: 'Status',
                type: 'group',
                children: [
                    {
                        title: 'Alle',
                        type: 'basic',
                        icon: 'mat_outline:format_list_bulleted',
                        link: 'alle',
                    },
                    {
                        title: 'Vollständig bezahlt',
                        type: 'basic',
                        icon: 'mat_outline:done',
                        link: 'bezahlt'
                    },
                    {
                        title: 'Teilweise Bezahlt',
                        type: 'basic',
                        icon: 'mat_outline:donut_large',
                        link: 'teil_bezahlt',
                    },
                    {
                        title: 'Unbezahlt',
                        type: 'basic',
                        icon: 'mat_outline:highlight_off',
                        link: 'unbezahlt',
                    },
                    {
                        title: 'Erwartet',
                        type: 'basic',
                        icon: 'mat_outline:hourglass_top',
                        link: 'erwartet',
                    },
                ],
            },
            {
                title: 'Zeitraumfilter',
                type: 'group',
                children: [
                    {
                        title: 'Diesen Monat',
                        type: 'basic',
                        icon: 'mat_outline:today',
                        link: 'thisMonth'
                        // badge: {
                        //     title: '49',
                        //     classes: 'px-2 bg-primary text-on-primary rounded-full',
                        // },
                    },
                    {
                        title: 'Letzter Monat',
                        type: 'basic',
                        icon: 'mat_outline:history',
                        link: 'lastMonth'
                    },
                ],
            },
            {
                type: 'divider',
            },
        ];
        this.menuData2 = [
            {
                title: 'Hilfe',
                type: 'group',
                children: [
                    {
                        title: 'Video-Anleitung',
                        type: 'basic',
                        icon: 'mat_outline:ondemand_video',
                        function: item => this.openYoutubeDialog(true)
                    },
                    {
                        title: 'Support',
                        type: 'basic',
                        icon: 'mat_outline:contact_support',
                        link: '/support',
                    },
                ]
            },
        ];
    }

    ngOnInit(): void {

        this.bookingsHelperService.columns$.pipe(takeUntil(this._unsubscribeAll)).subscribe(columns => {
            this.selectedColumns = columns;
        });

        this.range.valueChanges.pipe(takeUntil(this._unsubscribeAll), filter(range => !!range), debounceTime(1000), distinctUntilChanged())
            .subscribe((range) => {
                console.log('range changed --> ', range);
                this.bookingsHelperService.onRangeChanged$.next(range);
            });

        this.bookingsHelperService.onRangeChanged$.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((range) => {
                const currentValue = this.range.value as TransactionsRange;
                if (!isEqual(currentValue, range)) {
                    this.range.patchValue(range, {emitEvent: false, onlySelf: true})
                }
            })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    openYoutubeDialog(force = false) {

        if (!this.openYoutube && !force) {
            return;
        }

        const data: YoutubeDialogData = {
            videoID: 'nO_NkZzx4pE',
            title: 'Willkommen beim Sollstellungsmodul',
            subtitle: 'Entdecken Sie in unserem kurzen Video, wie Sie Sollstellungen effizient erstellen und verwalten können'
        };

        this.dialog.open(YoutubeDialogComponent, {
            autoFocus: false,
            disableClose: true,
            data,
            panelClass: 'fuse-confirmation-dialog-panel',
            maxHeight: '90vh'
        }).afterClosed().pipe(take(1)).subscribe((res: YoutubeDialogResult) => {
            if (res === 'IGNORE') {
                this.openYoutube = false;
            }

            if (res === 'CONTINUE') {
            }
        });
    }

    updateColumns(columns: string[]) {
        this.bookingsHelperService.updateColumns(columns);
    }
}
