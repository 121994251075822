import {HttpClient} from '@angular/common/http';
import {Component, Inject, OnDestroy, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {saveAs} from 'file-saver';
import JSZip from 'jszip';
import {lastValueFrom, Subject} from 'rxjs';
import {NahausImage, NahausImages} from "@shared/models/image.interface";
import {fuseAnimations} from "@fuse/animations";
import {largestImage, ToThumbPipe} from '../../pipes/to-thumb.pipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ImageLoaderComponent} from './image-loader/image-loader.component';
import {PdfLoaderComponent} from './pdf-loader/pdf-loader.component';
import {NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {getFileNameOrId} from "@shared/files";
import {NahausFile} from "../../../modules/admin/file-manager/models/nahausFile";
import {MatSnackBar} from "@angular/material/snack-bar";

export interface FilesPreviewDataDialog {
    id: string;
    title: string;
    message?: string;
    files: NahausImages;
}

@Component({
    selector: 'fuse-confirmation-dialog',
    templateUrl: './nahaus-images-preview-dialog.component.html',
    styles: [
        `
            .fuse-confirmation-dialog-panel {
                @screen md {
                    @apply w-128;
                }

                .mat-mdc-dialog-container {

                    .mat-mdc-dialog-surface {
                        padding: 0 !important;
                    }
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    standalone: true,
    imports: [MatButtonModule, MatDialogModule, MatIconModule, NgIf, NgSwitch, NgSwitchCase, PdfLoaderComponent, ImageLoaderComponent, NgSwitchDefault, MatProgressSpinnerModule, MatTooltipModule, ToThumbPipe]
})
export class NahausImagesPreviewDialogComponent implements OnDestroy {

    id: string;
    title: string;
    message: string;
    files: NahausImages;

    selectedFile: NahausImage;
    docIndex = 0;
    downloading: boolean;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: FilesPreviewDataDialog,
                public matDialogRef: MatDialogRef<NahausImagesPreviewDialogComponent>,
                private httpClient: HttpClient,
                private snackbar: MatSnackBar) {
        this.id = data?.id;
        this.title = data?.title;
        this.message = data?.message;
        this.files = data?.files;
        if (!this.files || !this.files?.length) {
            // todo: show error
            this.matDialogRef.close();
        }
        this.selectedFile = this.files[0];
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    nextDoc(): void {
        if (this.docIndex < this.files?.length - 1) {
            this.selectedFile = this.files[++this.docIndex];
            console.log('on nextDoc', this.selectedFile, this.docIndex);
        }
    }

    prevDoc(): void {
        if (this.docIndex > 0) {
            this.selectedFile = this.files[--this.docIndex];
            console.log('on prevDoc', this.selectedFile, this.docIndex);
        }
    }

    async download(): Promise<any> {
        this.downloading = true;
        const zipFile: JSZip = new JSZip();
        let count = 0;
        const docs: NahausFile[] = this.files;
        if (!docs?.length) {
            return;
        }

        for (const doc of docs) {
            const name = doc?.name || getFileNameOrId(doc?.path);
            const url = doc?.thumbs ? largestImage(doc) : doc?.url;

            try {
                const response: any = await lastValueFrom(this.httpClient.get(url, {responseType: 'blob'}));
                zipFile.file(name, response, {binary: true});
            } catch (error) {
                this.snackbar.open('Fehler beim Herunterladen der Datei: ' + name, 'Schließen', {duration: 5000});
                this.downloading = false;
                return;
            }

            if (++count === docs.length) {
                zipFile.generateAsync({type: 'blob'}).then((content) => {
                    saveAs(content, `${this.id}` + '.zip');
                    this.downloading = false;
                })
                    .catch((err) => {
                        // snackbar message
                        this.snackbar.open('Fehler beim Erstellen des ZIP-Archivs', 'Schließen', {duration: 5000});
                        this.downloading = false;
                    });
            }
        }
    }

}
