import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { AbstractFirestoreModularService } from '@shared/services/abstracts/AbstractFirestoreModularService';
import { AuthService } from '@shared/services/auth.service';
import { Transaction } from '@transactions/models/transaction.interface';
import { Tenant } from '../../properties/features/units/features/period/features/tenants/models';


@Injectable()
export class TenantsModularService extends AbstractFirestoreModularService<Tenant> {

  COLLECTION = 'tenants';

  constructor(authService: AuthService,
              firestore: Firestore) {
    super(authService, firestore);
  }


}
