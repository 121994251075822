import {CurrencyPipe, DatePipe, NgClass, NgForOf, NgIf} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {Property} from "../../../properties/models/property.interface";
import {
    AlgoliaAutocompleteComponent
} from "@shared/algolia/components/algolia-autocomplete/algolia-autocomplete.component";
import {FormatAddressPipe, ToDatePipe} from "@shared/pipes";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {FuseAlertComponent} from "@fuse/components/alert";
import {FuseCardComponent} from "@fuse/components/card";
import {MatDividerModule} from "@angular/material/divider";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatLineModule} from "@angular/material/core";
import {MatListModule, MatSelectionListChange} from "@angular/material/list";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatStepper, MatStepperModule} from "@angular/material/stepper";
import {MatTooltipModule} from "@angular/material/tooltip";
import {NgAisInstantSearchModule} from "angular-instantsearch";
import {InstantSearchConfig} from "angular-instantsearch/instantsearch/instantsearch";
import {Subject, switchMap, takeUntil} from "rxjs";
import {PropertiesService} from "../../../properties/services/properties.service";
import {UnitsService} from "../../../properties/features/units/services/units.service";
import {PeriodsService} from "../../../properties/features/units/features/period/services/periods.service";
import {environment} from "@env/environment";
import {filter, map, tap} from "rxjs/operators";
import {QuerySnapshot} from "@angular/fire/compat/firestore";
import {PropertyUnit} from "../../../properties/features/units/models/unit.interface";
import {pick} from "lodash-es";
import {
    PropertyUnitPeriod
} from "../../../properties/features/units/features/period/models/property.unit.period.interface";
import {MatSnackBar} from "@angular/material/snack-bar";
import {fuseAnimations} from "@fuse/animations";
import {DigitalProtocolService} from "../../services/digital-protocol.service";
import {DigitalProtocol, ProtocolStatusOptions} from "../../models/digital-protocol.interface";
import {formatAddress} from "../../../../../helpers/address";
import {Router} from "@angular/router";

export interface SelectTargetForDigitalProtocolDialog {
    property?: Property
}

@Component({
    selector: 'fuse-confirmation-dialog',
    templateUrl: './select-target-for-digital-protocol-dialog.component.html',
    styles: [
        `
            .fuse-confirmation-dialog-panel {

                @screen md {
                    @apply w-128;
                }

                .mat-mdc-dialog-container {

                    .mat-mdc-dialog-surface {
                        padding: 0 !important;
                    }
                }
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    standalone: true,
    imports: [NgIf, MatButtonModule, MatDialogModule, MatIconModule, NgClass, AlgoliaAutocompleteComponent, CurrencyPipe, DatePipe, FormatAddressPipe, FormsModule, FuseAlertComponent, FuseCardComponent, MatDividerModule, MatFormFieldModule, MatInputModule, MatLineModule, MatListModule, MatProgressSpinnerModule, MatStepperModule, MatTooltipModule, NgAisInstantSearchModule, NgForOf, ToDatePipe, ReactiveFormsModule],
    providers: [DigitalProtocolService, PropertiesService, UnitsService, PeriodsService]
})
export class SelectTargetForDigitalProtocolDialogComponent implements OnInit, OnDestroy {

    @ViewChild('stepper') matStepper: MatStepper;

    stepperIndex = 0;

    property: Property;

    selectDataFormGroup: FormGroup;
    hIDFC: FormControl = new FormControl<number>(null);

    propertyInvalid: boolean;
    unitInvalid: boolean;
    periodInvalid: boolean;
    loadingUnits: boolean;
    loadingPeriods: boolean;
    h_id_loading: boolean;
    creating: boolean;

    availableUnits: PropertyUnit[] = [];
    availablePeriods: PropertyUnitPeriod[] = [];

    selectedUnit: PropertyUnit[];
    selectedPeriod: PropertyUnitPeriod[];

    configProperties: InstantSearchConfig;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: SelectTargetForDigitalProtocolDialog,
                private matDialogRef: MatDialogRef<SelectTargetForDigitalProtocolDialogComponent>,
                private router: Router,
                private cf: ChangeDetectorRef,
                private propertiesService: PropertiesService,
                private unitsService: UnitsService,
                private periodsService: PeriodsService,
                private _formBuilder: FormBuilder,
                private snackbar: MatSnackBar,
                private digitalProtocolService: DigitalProtocolService) {
        //  PROPERTIES
        this.configProperties = {
            indexName: environment.algolia.indexName.properties,
            searchClient: this.propertiesService?.authService?.getAlgoliaSearchClient(true),
            routing: false
        };
    }

    ngOnInit(): void {
        this.createFormGroup();
        this.initListeners();
        this.loadUnits();
        this.loadPeriods();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        console.log('ngOnDestroy --> TransactionsDetailsComponent');
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    createFormGroup(): void {
        this.selectDataFormGroup = this._formBuilder.group({
            property: [null, Validators.required],
            unit: [null, Validators.required],
            period: [null, Validators.required],
        });
    }

    onQueryPropertySuggestionClick(property: Property): void {
        this.propertyInvalid = false;
        console.log('onQueryPropertySuggestionClick --> ', property);
        this.selectDataFormGroup.get('property').patchValue(property);
    }

    checkProperty() {
        const propertyFC = this.selectDataFormGroup.get('property');
        if (propertyFC.invalid) {
            this.propertyInvalid = true;
        }
    }

    resetProperty() {
        this.propertyInvalid = true;
        const propertyFC = this.selectDataFormGroup.get('property');
        propertyFC.patchValue(null);
        this.hIDFC.patchValue(null);
    }

    loadUnits(): void {
        this.selectDataFormGroup.get('property')?.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap((property: Property) => {
                    console.log('on tap property --> ', property);
                    this.loadingUnits = true;
                    this.unitsService.parentPath = `properties/${property?.id}`;
                }),
                switchMap(property => this.unitsService.collection(ref => ref.orderBy('w_id')).get()),
                map((query: QuerySnapshot<PropertyUnit>) => {
                    console.log('units query', query?.docs, query?.size);
                    const keys = ['id', 'propertyID', 'address', 'h_id', 'w_id', 'label', 'area', 'summary', 'currentPeriod.id', 'currentPeriod.rental.isRented', 'categoryType', 'currentPeriod.tenantsName', 'currentPeriod.rental.startDate', 'currentPeriod.costs.total'];
                    const units: PropertyUnit[] = [];
                    if (!query?.empty) {
                        query.forEach(doc => units.push(pick(doc?.data(), keys)));
                    }
                    return units;
                })
            )
            .subscribe((units) => {
                this.availableUnits = units;
                // if (this.extractedData?.exUnitsIDs?.w_id && units?.length) {
                //     const filterUnitsW_ID = units.filter(u => u.w_id === this.extractedData?.exUnitsIDs?.w_id);
                //     if (filterUnitsW_ID?.length) {
                //         this.selectedUnit = filterUnitsW_ID
                //         const unitFC = this.bookTransactionFormGroup.get('unit');
                //         if (!unitFC?.value) {
                //             unitFC.patchValue(filterUnitsW_ID[0]);
                //             // this.stepperIndex = 2;
                //         }
                //     }
                // }
                this.loadingUnits = false;
            });
    }

    loadPeriods(): void {
        this.selectDataFormGroup.get('unit')?.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap((unit2: PropertyUnit) => {
                    console.log('on unit changed --> ', unit2);
                    this.loadingPeriods = true;
                    const property: Property = this.selectDataFormGroup.get('property').getRawValue() as Property;
                    const unit: PropertyUnit = this.selectDataFormGroup.get('unit').getRawValue() as PropertyUnit;
                    this.periodsService.setPath(property?.id, unit?.id);
                    console.log('loading periods --> ', this.periodsService.parentPath, property, unit, unit2);
                }),
                switchMap(property => this.periodsService.collection(ref => ref.where('rental.isRented', '==', true).orderBy('rental.startDate', 'desc').limit(3)).get()),
                map((query: QuerySnapshot<PropertyUnit>) => {
                    console.log('periods query', query?.docs, query?.size);
                    return query?.size > 0 ? query.docs.map(doc => doc.data() as PropertyUnitPeriod) : [];
                })
            )
            .subscribe((periods) => {
                this.selectedPeriod = [];
                this.availablePeriods = periods;

                console.log("periods --> ", periods);
                this.selectedPeriod = [periods[0]];
                const periodFC = this.selectDataFormGroup.get('period');
                periodFC.patchValue(this.selectedPeriod[0]);
                this.loadingPeriods = false;
            });
    }

    checkUnit(): void {
        const unitFC = this.selectDataFormGroup.get('unit');
        if (unitFC.invalid) {
            this.unitInvalid = true;
        }
    }

    initListeners(): void {
        this.hIDFC
            .valueChanges
            .pipe(takeUntil(this._unsubscribeAll), filter((h_id) => !!h_id),
                switchMap((h_id: number) => this.propertiesService.collection(ref => ref.where('h_id', '==', h_id).limit(1)).get()),
                map((query: QuerySnapshot<Property>) => {
                    if (query?.size > 0) {
                        this.property = query.docs[0].data() as Property;
                        this.propertyInvalid = false;
                        this.unitsService.parentPath = `properties/${this.property?.id}`;
                        this.selectDataFormGroup.get('property')?.patchValue(this.property);
                        // this.onPropertySelectedAndChecked.next(this.property);
                        this.h_id_loading = false;
                        return this.property;
                    } else {
                        const msg = `Es wurde keine Immobilie mit der angegebenen H_ID ${this.hIDFC?.value} gefunden. Bitte überprüfen Sie die eingegebene H_ID und versuchen Sie es erneut.`;
                        this.snackbar.open(msg, 'OK', {duration: 5000});
                        this.property = null;
                        this.propertyInvalid = true;
                        this.unitsService.parentPath = `properties/${this.property?.id}`;
                        return null;
                    }
                })).subscribe((property) => {
            console.log('property received after validating --> ', property);
        });
    }

    onUnitsSelectionListChanged($event: MatSelectionListChange) {
        this.unitInvalid = false;
        console.log('onUnitsSelectionListChanged --> ', $event);
        const unitFC = this.selectDataFormGroup.get('unit');
        if (!unitFC?.value) {
            unitFC.patchValue($event.options[0].value);
            this.stepperIndex = 2;
            return;
        }
        unitFC.patchValue($event.options[0].value);
    }

    onPeriodsSelectionListChanged($event: MatSelectionListChange) {
        this.periodInvalid = false;
        console.log('onPeriodsSelectionListChanged --> ', $event);
        const periodFC = this.selectDataFormGroup.get('period');
        if (!periodFC?.value) {
            periodFC.patchValue($event.options[0].value);
            this.stepperIndex = 3;
            return;
        }
        periodFC.patchValue($event.options[0].value);
    }

    creatingDraft(): void {
        if (this.selectDataFormGroup.invalid) {
            // show error and return
            const msg = '';
            this.snackbar.open(msg, "Schließen", {duration: 5000});
            return;
        }

        this.creating = true;
        this.cf.markForCheck();

        const property: Property = this.selectDataFormGroup.get('property')?.getRawValue() as Property;
        const unit: PropertyUnit = this.selectDataFormGroup.get('unit')?.getRawValue() as PropertyUnit;
        const period: PropertyUnitPeriod = this.selectDataFormGroup.get('period')?.getRawValue() as PropertyUnitPeriod;

        const now = new Date();

        const digitalProtocol: DigitalProtocol = {
            id: this.digitalProtocolService.createID(),
            customerID: this.digitalProtocolService.authService?.customerID,
            propertyID: property?.id,
            unitID: unit?.id,
            periodID: period?.id,
            h_id: property?.h_id,
            w_id: unit?.w_id,
            createdAt: now,
            date: now,
            status: ProtocolStatusOptions.DRAFT,
            address: unit?.address || property?.address,
            unitLabel: unit?.label,
            unitIDLabel: `H${property?.h_id}/W${unit?.w_id}`,
            propertyIDLabel: `${formatAddress(unit?.address || property?.address)} - ${unit?.label || ''} - ${unit?.summary || ''}`,
            tenantsName: period?.tenantsName,
            landlordCertificateGeneratedAndSent: false,
        }

        this.digitalProtocolService.doc(digitalProtocol.id)
            .set(digitalProtocol, {merge: true})
            .then(async () => {
                this.snackbar.open('Der Entwurf Ihres digitalen Übergabeprotokolls wurde erfolgreich erstellt.', 'OK', {
                    duration: 5000,
                });
                await this.router.navigate(['protokolle', digitalProtocol.id]);
                return this.matDialogRef.close('confirmed');
            })
            .catch((err) => {
                console.log("Error while creating a draft of the digital protocol--> ", err)
                this.snackbar.open('Fehler beim Erstellen des Entwurfs. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.', 'Schließen', {
                    duration: 5000,
                });
            })
            .finally(() => {
                this.creating = false;
                this.cf.markForCheck();
            })
    }
}
