import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ContractStatusOptions } from '../models/contract';
import { ContractsService } from '../services/contracts.service';

@Injectable()
export class CreateContractDraftGuard implements CanActivate {


  constructor(private router: Router,
              private service: ContractsService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('this.router.getCurrentNavigation().extras.state', this.router.getCurrentNavigation().extras.state); // should log out 'bar'
    const id = this.service.createID();
    return this.service.doc(id).set({ id, status: ContractStatusOptions.DRAFT })
      .then(() => this.router
        .navigate(['mietvertraege', 'wohnraum', id],
          { state: this.router.getCurrentNavigation().extras.state }))
      .catch((err) => {
        console.error('Error', err);
        // todo: go to error page
        return this.router.navigate(['fehler', '500']);
      });
    // return true;
  }

}
