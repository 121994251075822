import firebase from 'firebase/compat';
import Timestamp = firebase.firestore.Timestamp;

/**
 * @author Anthony Nahas
 * @since 24.06.2021
 * @updated 17.06.2024
 * @version 1.1
 */
export interface Activity {
    id?: string;
    createdAt?: string | Date | Timestamp;
    user?: string; // system - nahaus.de - Anthony Nahas
    uid?: string; // the user id
    icon?: string;
    image?: string;
    description?: string;
    date?: string | Date | Timestamp;
    extraContent?: string;
    linkedContent?: string;
    link?: string;
    useRouter?: boolean;
    pdfURL?: string;

    // metadata
    customerID?: string;
    propertyID?: string;
    unitID?: string;
    periodID?: string;
    managementID?: string;
    featureType?: ActivityFeatureType;

    // additional content
    h_id?: number;
    w_id?: number;
    address?: string; // address of the property or unit
    unitLabel?: string; // EG Rechts, OG, Restaurant
    tenantsName?: string;
}

export type Activities = Activity[];

export enum ActivityFeatureOption {
    DUNNING = "DUNNING",
    LETTER = "LETTER",
    PERIOD = "PERIOD",
    NK = "NK",
}

export type ActivityFeatureType =
    ActivityFeatureOption.DUNNING
    | ActivityFeatureOption.LETTER
    | ActivityFeatureOption.PERIOD
    | ActivityFeatureOption.NK

