import {Component, LOCALE_ID, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher-old';
import {Subject, takeUntil} from 'rxjs';
import {ActivatedRoute, RouterLink, RouterOutlet} from "@angular/router";
import {MatDrawer, MatSidenavModule} from "@angular/material/sidenav";
import {AccessLayerOptions} from "../../booking.routing";
import {BookingsMainSidebarComponent} from './demo-sidebar/bookings-main-sidebar.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {NgClass, NgIf} from '@angular/common';
import {BookingsHelperService} from "../../services/bookings-helper.service";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {CustomDateAdapter} from "../../../../../app.component";
import {Platform} from "@angular/cdk/platform";
import * as moment from "moment";

@Component({
    selector: 'carded-left-sidebar-2-normal-scroll',
    templateUrl: './bookings-main.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, MatIconModule, RouterLink, MatButtonModule, MatSidenavModule, NgClass, BookingsMainSidebarComponent, RouterOutlet],
    providers: [
        BookingsHelperService,
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: moment.ISO_8601
                },
                display: {
                    dateInput: 'LL',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY'
                }
            }
        },
        {provide: LOCALE_ID, useValue: 'de'},
        {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, Platform]}
    ]
})
export class BookingsMainComponent implements OnInit, OnDestroy {

    @ViewChild('matDrawer', {static: true}) drawer: MatDrawer;

    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;

    accessLayer: AccessLayerOptions;
    onlyDeposit: boolean;
    onlyUnpaid: boolean;
    bookingsAccessLayerOptions = AccessLayerOptions;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(private _fuseMediaWatcherService: FuseMediaWatcherService,
                private route: ActivatedRoute) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        console.log("BookingsMainComponent --> ", this.route.parent?.parent?.parent?.snapshot?.data)
        console.log("BookingsMainComponent --> ", this.route.parent?.parent?.snapshot?.data)
        console.log("BookingsMainComponent --> ", this.route.parent?.snapshot?.data)
        console.log("BookingsMainComponent --> ", this.route?.snapshot?.data)
        this.accessLayer = this.route.snapshot?.data?.accessLayer;
        this.onlyDeposit = this.route.snapshot?.data?.onlyDeposit;
        this.onlyUnpaid = this.route.snapshot?.data?.onlyUnpaid;

        if (this.accessLayer === this.bookingsAccessLayerOptions.ACCOUNT) {
            // Subscribe to media changes
            this._fuseMediaWatcherService.onMediaChange$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(({matchingAliases}) => {
                    // Set the drawerMode and drawerOpened if
                    if (matchingAliases.includes('lg')) {
                        this.drawerMode = 'side';
                        this.drawerOpened = true;
                    } else {
                        this.drawerMode = 'over';
                        this.drawerOpened = false;
                    }
                });
        } else {
            this.drawerMode = 'over';
            this.drawerOpened = false;
        }


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
