<!-- Bar search -->
<ng-container *ngIf="appearance === 'bar'">
    <button
        (click)="open()"
        *ngIf="!opened"
        mat-icon-button>
        <mat-icon [svgIcon]="'heroicons_outline:search'"></mat-icon>
    </button>
    <div
        *ngIf="opened"
        @slideInTop
        @slideOutTop
        class="absolute inset-0 flex items-center shrink-0 z-99 bg-card">
        <mat-icon
            [svgIcon]="'heroicons_outline:search'"
            class="absolute ml-6 sm:ml-8"></mat-icon>
            <ais-instantsearch [config]='this.config' class="w-full">
          
                <ais-configure [searchParameters]="{ facets: ['customerID'] }"></ais-configure>
                <ais-index indexName="tenants"></ais-index>
                <ais-index indexName="contacts"></ais-index>
                <ais-index indexName="properties"></ais-index>
                <ais-index indexName="units"></ais-index>
                <ais-index indexName="dunnings"></ais-index>
                <ais-index indexName="msa"></ais-index>
                <ais-index indexName="landlords"></ais-index>
                <ais-index indexName="managers"></ais-index>
                <ais-index indexName="meters"></ais-index>
                <ais-index indexName="files"></ais-index>
                <ais-index indexName="cards"></ais-index>
                <app-algolia-multi-autocomplete-search
                    (onQuerySuggestionClick)=onQuerySuggestionClick($event)
                    formFieldClass="w-full h-full px-16 sm:px-18"
                    placeholder="properties , unites , meters , landlords , contacts , tickets , .....">
                    <ng-template let-indexName='indexName' let-option='option'>
                        <div [ngSwitch]="indexName" class="flex flex-col">
                            <ng-container *ngSwitchCase="'properties'">
                                <span
                                [innerHTML]="option?._highlightResult?.h_id_text?.value && option?._highlightResult?.h_id_text?.matchLevel === 'full' ? option?._highlightResult?.h_id_text?.value : 'H' + option?._highlightResult?.h_id?.value + ' ' + option?._highlightResult?.address?.streetName?.value + ' ' + option?._highlightResult?.address?.streetNumber?.value"
                                    class="font-semibold"></span>
                                <mat-divider class="mt-2"></mat-divider>
                            </ng-container>
                            <ng-container *ngSwitchCase="'units'">
                                <span [innerHTML]="'W' + option?._highlightResult?.w_id?.value + ' ' + option?._highlightResult?.label?.value"
                                matLine></span>
                                <span [innerHTML]="option?._highlightResult?.address?.displayAddress?.value"
                                class="text-xs" matLine></span>
                               <mat-divider class="mt-2"></mat-divider>
                            </ng-container>
                            <ng-container *ngSwitchCase="'cards'">
                                <span
                                [innerHTML]="option?._highlightResult?.title?.value ? option?._highlightResult?.title?.value : option?.title"
                                    class="font-semibold"></span>
                                <!-- <span
                                [innerHTML]="option?._highlightResult?.labels?.valu?.length > 0 && option?._highlightResult?.h_id_text?.matchLevel === 'full' ? option?._highlightResult?.h_id_text?.value : 'H' + option?._highlightResult?.h_id?.value + ' ' + option?._highlightResult?.address?.streetName?.value + ' ' + option?._highlightResult?.address?.streetNumber?.value"
                                    class="font-semibold"></span> -->
                                <mat-divider class="mt-2"></mat-divider>
                               
                            </ng-container>
                            <ng-container *ngSwitchCase="'landlords'">
                                <span
                                    [innerHTML]='option?._highlightResult? option?._highlightResult?.displayName?.value : option?.displayName'
                                    class="font-semibold"></span>
                                <mat-divider class="mt-2"></mat-divider>
                            </ng-container>
                            <ng-container *ngSwitchCase="'msa'">
                                <span *ngIf='!option?._highlightResult?.exactUnitSuggestion?.value'
                                [innerHTML]='option?._highlightResult?.target?.property?.value'></span>
                                <span
                                *ngIf='option?._highlightResult?.exactUnitSuggestion?.value'
                                [innerHTML]='option?._highlightResult?.exactUnitSuggestion?.value?.value'></span>
                                <span
                                [innerHTML]="option?._highlightResult?.contactData?.firstName?.value + ' ' + option?._highlightResult?.contactData?.lastName?.value"></span>
                                <mat-divider class="mt-2"></mat-divider>
                            </ng-container>
                            <ng-container *ngSwitchCase="'managers'">
                                <span [innerHTML]='option?._highlightResult? option?._highlightResult?.displayName?.value : option?.displayName'></span>
                                <mat-divider class="mt-2"></mat-divider>
                            </ng-container>
                            <ng-container *ngSwitchCase="'contacts'">
                                <span
                                [innerHTML]='option?._highlightResult? option?._highlightResult?.displayName?.value : (option | contactName )'></span>
                                <mat-divider class="mt-2"></mat-divider>
                            </ng-container>
                            <ng-container *ngSwitchCase="'tenants'">
                                <span
                                    [innerHTML]="option?._highlightResult?.contactData?.displayName?.value || (option?._highlightResult?.contactData?.firstName?.value + ' ' + option?._highlightResult?.contactData?.lastName?.value)"
                                    class="font-semibold"></span>
                                <span *ngIf='option?.contactData?.email'
                                      [innerHTML]="option?._highlightResult?.contactData?.email?.value"
                                      class="text-sm"></span>
                                      <mat-divider class="mt-2"></mat-divider>
                                <ng-container *ngIf='option?.contactData?.phoneNumbers?.length'>
                                    <span
                                        *ngFor="let phoneNumber of option?._highlightResult?.contactData?.phoneNumbers; trackBy: trackByFn"
                                        [innerHTML]="phoneNumber?.value?.value" class="text-xs" matLine></span>
                                        <mat-divider class="mt-2"></mat-divider>
                                </ng-container>
                                <mat-divider class="mt-2"></mat-divider>
                            </ng-container>
                            <ng-container *ngSwitchCase="'files'">
                                <span
                                [innerHTML]="option?._highlightResult?.name?.value"
                                    class="font-semibold"></span>
                                <mat-divider class="mt-2"></mat-divider>
                            </ng-container>
                        </div>
                    </ng-template>
                </app-algolia-multi-autocomplete-search>
            </ais-instantsearch>
        <!-- <input
            #barSearchInput
            (keydown)="onKeydown($event)"
            [formControl]="searchControl"
            [matAutocomplete]="matAutocomplete"
            [placeholder]="'Search...'"
            class="w-full h-full px-16 sm:px-18"> -->
        <!-- <mat-autocomplete
            #matAutocomplete="matAutocomplete"
            [autoSelectActiveOption]="true"
            [disableRipple]="true"
            class="max-h-128 sm:px-2 border-t rounded-b shadow-md">
            <mat-option
                *ngIf="resultSets && !resultSets.length"
                class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent">
                No results found!
            </mat-option>
            <ng-container *ngFor="let resultSet of resultSets; trackBy: trackByFn">
                <mat-optgroup class="flex items-center mt-2 px-2">
                    <span
                        class="text-sm font-semibold tracking-wider text-secondary">{{resultSet.label.toUpperCase()}}</span>
                </mat-optgroup>
                <ng-container *ngFor="let result of resultSet.results; trackBy: trackByFn">
                    <mat-option
                        [routerLink]="result.link"
                        [value]="result.value"
                        class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover">
   
                        <ng-container *ngIf="resultSet.id === 'contacts'">
                            <ng-container
                                *ngTemplateOutlet="contactResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                    
                        <ng-container *ngIf="resultSet.id === 'pages'">
                            <ng-container *ngTemplateOutlet="pageResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
            
                        <ng-container *ngIf="resultSet.id === 'tasks'">
                            <ng-container *ngTemplateOutlet="taskResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                    </mat-option>
                </ng-container>
            </ng-container>
        </mat-autocomplete> -->
        <button
            (click)="close()"
            class="absolute top-1/2 right-5 sm:right-7 shrink-0 w-10 h-10 -mt-5"
            mat-icon-button>
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
</ng-container>

<!-- Basic search -->
<ng-container *ngIf="appearance === 'basic'">
    <div class="w-full sm:min-w-80">
        <mat-form-field class="w-full" subscriptSizing="dynamic">
            <mat-icon
                [svgIcon]="'heroicons_outline:search'"
                matPrefix></mat-icon>
            <input
                (keydown)="onKeydown($event)"
                [formControl]="searchControl"
                [matAutocomplete]="matAutocomplete"
                [placeholder]="'Search...'"
                matInput>
        </mat-form-field>
        <mat-autocomplete
            #matAutocomplete="matAutocomplete"
            [autoSelectActiveOption]="true"
            [disableRipple]="true"
            class="max-h-128 mt-1 rounded">
            <mat-option
                *ngIf="resultSets && !resultSets.length"
                class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent">
                No results found!
            </mat-option>
            <ng-container *ngFor="let resultSet of resultSets; trackBy: trackByFn">
                <mat-optgroup class="flex items-center mt-2 px-2">
                    <span
                        class="text-sm font-semibold tracking-wider text-secondary">{{resultSet.label.toUpperCase()}}</span>
                </mat-optgroup>
                <ng-container *ngFor="let result of resultSet.results; trackBy: trackByFn">
                    <mat-option
                        [routerLink]="result.link"
                        [value]="result.value"
                        class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover">
                        <!-- Contacts -->
                        <ng-container *ngIf="resultSet.id === 'contacts'">
                            <ng-container
                                *ngTemplateOutlet="contactResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                        <!-- Pages -->
                        <ng-container *ngIf="resultSet.id === 'pages'">
                            <ng-container *ngTemplateOutlet="pageResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                        <!-- Tasks -->
                        <ng-container *ngIf="resultSet.id === 'tasks'">
                            <ng-container *ngTemplateOutlet="taskResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                    </mat-option>
                </ng-container>
            </ng-container>
        </mat-autocomplete>
    </div>
</ng-container>

<!-- Contact result template -->
<ng-template
    #contactResult
    let-result>
    <div class="flex items-center">
        <div
            class="flex shrink-0 items-center justify-center w-8 h-8 rounded-full overflow-hidden bg-primary-100 dark:bg-primary-800">
            <img
                *ngIf="result.avatar"
                [src]="result.avatar">
            <mat-icon
                *ngIf="!result.avatar"
                [svgIcon]="'heroicons_outline:user-circle'"
                class="m-0 icon-size-5 text-primary dark:text-primary-400"></mat-icon>
        </div>
        <div class="ml-3 truncate">
            <span [innerHTML]="result.name"></span>
        </div>
    </div>
</ng-template>

<!-- Page result template -->
<ng-template
    #pageResult
    let-result>
    <div class="flex flex-col">
        <div
            [innerHTML]="result.title"
            class="truncate leading-normal"></div>
        <div class="truncate leading-normal text-sm text-secondary">
            {{result.link}}
        </div>
    </div>
</ng-template>

<!-- Task result template -->
<ng-template
    #taskResult
    let-result>
    <div class="flex items-center">
        <ng-container *ngIf="result.completed">
            <mat-icon
                [svgIcon]="'heroicons_outline:check-circle'"
                class="mr-0 text-primary dark:text-primary-400"></mat-icon>
        </ng-container>
        <ng-container *ngIf="!result.completed">
            <mat-icon
                [svgIcon]="'heroicons_outline:check-circle'"
                class="mr-0 text-hint"></mat-icon>
        </ng-container>
        <div
            [innerHTML]="result.title"
            [ngClass]="{'line-through text-hint': result.completed}"
            class="ml-3 truncate leading-normal"></div>
    </div>
</ng-template>
