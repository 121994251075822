/**
 * @author Anthony Nahas
 * @since 31.05.2024
 * @version 1.0
 */
export interface DigitalProtocolKey {
    type: string;
    quantity: string;  // Typ als 'string' angenommen, um leere Werte zuzulassen. Kann auch 'number' sein, wenn nur numerische Werte erlaubt sind.
    numberID: string;
}

/**
 * @author Anthony Nahas
 * @since 31.05.2024
 * @version 1.0
 */
export const defaultResidentialKeys: DigitalProtocolKey[] = [
    {type: 'Haustürschlüssel', quantity: '', numberID: ''},
    {type: 'Wohnungsschlüssel', quantity: '', numberID: ''},
    {type: 'Briefkastenschlüssel', quantity: '', numberID: ''},
    {type: 'Kellerschlüssel', quantity: '', numberID: ''},
    {type: 'Garagenschlüssel', quantity: '', numberID: ''},
    {type: 'Sonstige', quantity: '', numberID: ''},
];
