<mat-form-field [ngClass]="{'fuse-mat-emphasized-affix': this.emphasized}" class="w-full">
	<div *ngIf="this.emphasized"
         class="text-secondary hidden sm:block"
	     matPrefix>
		{{this.label}}<sup>*</sup>
	</div>
	<mat-label *ngIf="!this.emphasized">{{this.label}}</mat-label>
	<img *ngIf="this.value?.logo"
	     [src]="this.value?.logo"
         class="mat-icon icon-size-5 object-contain hidden sm:block"
	     matPrefix>
	<input #query
	       [errorStateMatcher]='errorStateMatcher'
	       (click)="$event.stopPropagation()"
	       (keyup)="handleChange($event)"
	       [value]="this.value?.name || ''"
	       [formControl]="this.control"
	       [disabled]="this.disabled"
	       [matAutocomplete]="auto"
	       matInput
	       id="search"
	       placeholder="Bank suchen und auswählen"/>
	<mat-hint *ngIf="this.showHint">{{this.hintText}}</mat-hint>
	<mat-error>{{this.errorText}}</mat-error>
	<mat-autocomplete
      #auto="matAutocomplete">
		<div *ngFor="let index of state.indices">
			<mat-option
					(click)="this.select(option)"
					*ngFor="let option of index?.hits;trackBy: trackByFn"
					[value]="this.value?.name">
				<!--				<span [innerHTML]="getDisplayNameHighlighted(option?._highlightResult)" matLine></span>-->
				<div class="flex items-center space-x-4">
					<ng-container *ngIf="option?.logo">
						<img
								[src]="option?.logo"
								alt="Contact avatar"
								class="object-cover w-6 h-full justify-center"/>
					</ng-container>
					<ng-container *ngIf="!option?.logo">
						<div class="flex items-center justify-center w-6 h-6 rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
							{{option?.name.charAt(0)}}
						</div>
					</ng-container>
					<span [innerHTML]="option?._highlightResult?.name?.value"></span>
				</div>
			</mat-option>
		</div>
	</mat-autocomplete>
	<button (click)="this.clear()" *ngIf="query.value.length > 0 && this.value?.id"
	        aria-label="Clear"
	        mat-icon-button matSuffix
	        [matTooltip]="this.clearIconTooltip">
		<mat-icon>close</mat-icon>
	</button>
	<!--	<mat-hint *ngIf="this.selectedLandlord">Vermieter ID: <b>{{this.selectedLandlord?.id}}</b></mat-hint>-->
	<mat-icon *ngIf="query.value.length == 0" matSuffix>search</mat-icon>
</mat-form-field>




