import { Injectable } from '@angular/core';
import { AbstractFirestoreService } from '@shared/services/abstracts/AbstractFirestoreService';
import { AuthService } from '@shared/services/auth.service';
import { Activity } from '../models/activities.types';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService extends AbstractFirestoreService<Activity> {

  COLLECTION = 'activities';

  constructor(authService: AuthService) {
    super(authService);
  }
}
