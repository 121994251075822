import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { NahausQuota } from '@quota/models';
import { AuthService } from '@shared/services/auth.service';
import { filter, Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CanAccessVacanciesGuard implements CanActivate, CanActivateChild, CanLoad {

  quota: NahausQuota;

  constructor(private auth: AuthService,
              private router: Router) {
    this.quota = this.auth?.quota;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canAccess();
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canAccess();
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canAccess();
  }

  canAccess(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.quota$.pipe(filter(quota => !!quota), take(1), map((quota: NahausQuota) => {
      if (quota?.hasVacanciesModule) {
        return true;
      } else {
        this.router.navigate(['fehler', '430'])
          .then(() => null)
          .catch((err) => console.error('Error while redirecting to error page 430: ', err));
      }
    }));
  }

}
