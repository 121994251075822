import {Injectable} from '@angular/core';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';
import {PropertyUnit, Units} from '../models/unit.interface';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UnitsService extends AbstractFirestoreService<PropertyUnit> {


    COLLECTION = 'units';

    displayedColumns: string[] =
        [
            'w_id',
            'currentPeriod.rental.isRented',
            'label',
            'categoryType',
            'currentPeriod.tenantsName',
            'currentPeriod.numberOfResidents',
            'area',
            'currentPeriod.costs.total',
            'rent_area_ratio'
        ];
    updateColumnsWithoutStorage$ = new Subject<string []>();
    updateColumnsWithStorage$ = new Subject<string []>();
    private _columns = new BehaviorSubject<string[]>(null); // Standardauswahl
    columns$ = this._columns.asObservable();


    onListChanged$: BehaviorSubject<Units> = new BehaviorSubject<Units>(null);

  constructor(authService: AuthService) {
    super(authService);
  }


  setParentPath(propertyID: string): void {
    this.parentPath = `properties/${propertyID}`;
  }

    updateColumns(columns: string[]) {
        const updatedColumns = [...columns, 'buttons'];

        this._columns.next(updatedColumns);
    }


    getColumns(): string[] {

        // Retrieve the JSON string from localStorage

        const columnsFromStorage = localStorage.getItem('selectedColumns');

        // Parse the JSON string back to an array

        return columnsFromStorage ? JSON.parse(columnsFromStorage) : [];
    }

    clearColumns() {

        localStorage.removeItem('selectedColumns');
    }

    saveColumns(columns: string[]) {

        // Serialize the array as a JSON string
        const serializedItems = JSON.stringify(columns);

        // Store in localStorage
        localStorage.setItem('selectedColumns', serializedItems);
    }


    private _list: Units;

    set unitsList(value: Units) {
        this._list = value;
        this.onListChanged$.next(value);
    }
}
