/* eslint-disable */
export const items = [
  {
    id: 'cd6897cb-acfd-4016-8b53-3f66a5b5fc68',
    folderID: null,
    name: 'Wohnungen',
    createdBy: 'Anthony Nahas',
    createdAt: 'April 14, 2023',
    modifiedAt: 'April 14, 2023',
    size: '87 MB',
    type: 'folder',
    contents: '57 Dateien',
    description: 'In diesem Ordner finden Sie alle relevanten Dokumente, Pläne, Verträge und Fotos in Bezug auf die Wohnungen in unserem Immobilienbestand.'
  },
  {
    id: '6da8747f-b474-4c9a-9eba-5ef212285500',
    folderID: null,
    name: 'Bilder',
    createdBy: 'Anthony Nahas',
    createdAt: 'November 01, 2022',
    modifiedAt: 'November 01, 2022',
    size: '3015 MB',
    type: 'folder',
    contents: '907 Dateien',
    description: 'In diesem Ordner finden Sie eine Sammlung von Fotos und Bildern unserer Immobilien, einschließlich Innen- und Außenansichten, Grundrisse, Renovierungen und besondere Merkmale.'
  },
  {
    id: 'ed58add1-45a7-41db-887d-3ca7ee7f2719',
    folderID: null,
    name: 'Grundrisse',
    createdBy: 'Anthony Nahas',
    createdAt: 'Mai 8, 2021',
    modifiedAt: 'Mai 8, 2021',
    size: '14 MB',
    type: 'folder',
    contents: '24 Dateien',
    description: 'In diesem Ordner finden Sie Grundrisse aller Wohnungen und Häuser, um Ihnen einen detaillierten Überblick über die Raumaufteilung und Größe der einzelnen Einheiten zu bieten.'
  },
  {
    id: 'ed58add1-45a7-41db-887d-3ca7ee7f2711',
    folderID: null,
    name: 'Verträge',
    createdBy: 'Anthony Nahas',
    createdAt: 'Mai 18, 2022',
    modifiedAt: 'Mai 18, 2022',
    size: '14 MB',
    type: 'folder',
    contents: '71 Dateien',
    description: 'In diesem Ordner finden Sie alle Verträge, die mit unseren Mietern, Handwerkern und anderen Dienstleistern abgeschlossen wurden. Hierzu gehören Mietverträge, Werkverträge, Dienstleistungsverträge und sonstige Vereinbarungen, die für die Verwaltung unserer Immobilien relevant sind.'
  },
  {
    id: 'ed58add1-45a7-41db-887d-3ca7ee7f27111',
    folderID: null,
    name: 'Rechnungen',
    createdBy: 'Anthony Nahas',
    createdAt: 'Mai 8, 2020',
    modifiedAt: 'Mai 8, 2020',
    size: '14 MB',
    type: 'folder',
    contents: '94 Dateien',
    description: ' In diesem Ordner finden Sie alle Rechnungen, die im Zusammenhang mit unseren Immobilien anfallen. Dazu gehören Rechnungen von Handwerkern, Dienstleistern, Versorgern und sonstigen Kosten, die für die Verwaltung und Instandhaltung unserer Immobilien entstehen. Hier können Sie auch Ihre eigenen erstellten Rechnungen für die Nebenkostenabrechnungen und Mieteinnahmen ablegen.'
  },
  {
    id: '5cb66e32-d1ac-4b9a-8c34-5991ce25add2',
    folderID: null,
    name: 'Nebenkostenabrechnung_2022',
    createdBy: 'Anthony Nahas',
    createdAt: 'Januar 14, 2022',
    modifiedAt: 'Januar 14, 2022',
    size: '1.2 MB',
    type: 'PDF',
    contents: null,
    description: null
  },
  {
    id: '3ffc3d84-8f2d-4929-903a-ef6fc21657a7',
    folderID: null,
    name: 'Mieterkommunikation_2023',
    createdBy: 'Anthony Nahas',
    createdAt: 'Dezember 14, 2020',
    modifiedAt: 'Dezember 14, 2020',
    size: '679 KB',
    type: 'XLS',
    contents: null,
    description: null
  },
  {
    id: '157adb9a-14f8-4559-ac93-8be893c9f80a',
    folderID: null,
    name: 'Instandhaltungsprotokoll_2021',
    createdBy: 'Anthony Nahas',
    createdAt: 'Mai 8, 2022',
    modifiedAt: 'Mai 8, 2022',
    size: '1.5 MB',
    type: 'DOC',
    contents: null,
    description: null
  },
  {
    id: '4f64597a-df7e-461c-ad60-f33e5f7e0747',
    folderID: null,
    name: 'Heizkostenabrechnung_2021',
    createdBy: 'Anthony Nahas',
    createdAt: 'Januar 12, 2020',
    modifiedAt: 'Januar 12, 2020',
    size: '17.8 MB',
    type: 'PDF',
    contents: null,
    description: null
  },
  {
    id: 'e445c445-57b2-4476-8c62-b068e3774b8e',
    folderID: null,
    name: 'Mieterbeschwerden_Musterstraße_1a',
    createdBy: 'Anthony Nahas',
    createdAt: 'Juni 8, 2020',
    modifiedAt: 'Juni 8, 2020',
    size: '11.3 MB',
    type: 'TXT',
    contents: null,
    description: null
  },
  {
    id: 'b482f93e-7847-4614-ad48-b78b78309f81',
    folderID: null,
    name: 'Rauchmelder_Wartungsprotokoll',
    createdBy: 'Anthony Nahas',
    createdAt: 'Juni 8, 2020',
    modifiedAt: 'Juni 8, 2020',
    size: '9.3 MB',
    type: 'TXT',
    contents: null,
    description: null
  },
  {
    id: 'ec07a98d-2e5b-422c-a9b2-b5d1c0e263f5',
    folderID: null,
    name: 'Handwerkerrechnung_Flachdachsanierung',
    createdBy: 'Anthony Nahas',
    createdAt: 'März 18, 2020',
    modifiedAt: 'März 18, 2020',
    size: '4.3 MB',
    type: 'PDF',
    contents: null,
    description: null
  },
  {
    id: 'ae908d59-07da-4dd8-aba0-124e50289295',
    folderID: null,
    name: 'Grundriss_Wohnung_1OG_Links',
    createdBy: 'Anthony Nahas',
    createdAt: 'August 29, 2020',
    modifiedAt: 'August 29, 2020',
    size: '4.5 MB',
    type: 'JPG',
    contents: null,
    description: null
  },
  {
    id: '4038a5b6-5b1a-432d-907c-e037aeb817a8',
    folderID: null,
    name: 'Energieausweis_Musterstraße_1a',
    createdBy: 'Anthony Nahas',
    createdAt: 'September 13, 2020',
    modifiedAt: 'September 13, 2020',
    size: '7.8 MB',
    type: 'JPG',
    contents: null,
    description: null
  },
  {
    id: '630d2e9a-d110-47a0-ac03-256073a0f56d',
    folderID: null,
    name: 'Scanned image 20201012-2',
    createdBy: 'Anthony Nahas',
    createdAt: 'September 14, 2020',
    modifiedAt: 'September 14, 2020',
    size: '7.4 MB',
    type: 'JPG',
    contents: null,
    description: null
  },
  {
    id: '1417d5ed-b616-4cff-bfab-286677b69d79',
    folderID: null,
    name: 'Hausgeldabrechnung_WEG_2022',
    createdBy: 'Anthony Nahas',
    createdAt: 'April 07, 2020',
    modifiedAt: 'April 07, 2020',
    size: '2.6 MB',
    type: 'PDF',
    contents: null,
    description: null
  },
  {
    id: 'bd2817c7-6751-40dc-b252-b6b5634c0689',
    folderID: null,
    name: 'Wartungsplan_Liftanlage',
    createdBy: 'Anthony Nahas',
    createdAt: 'März 26, 2022',
    modifiedAt: 'März 26, 2022',
    size: '2.1 MB',
    type: 'DOC',
    contents: null,
    description: null
  },
  {
    id: '14fb47c9-6eeb-4070-919c-07c8133285d1',
    folderID: null,
    name: 'Objektübersicht_Musterstraße_1a',
    createdBy: 'Anthony Nahas',
    createdAt: 'Juni 02, 2020',
    modifiedAt: 'Juni 02, 2020',
    size: '943 KB',
    type: 'XLS',
    contents: null,
    description: null
  },

  {
    id: '894e8514-03d3-4f5e-bb28-f6c092501fae',
    folderID: 'cd6897cb-acfd-4016-8b53-3f66a5b5fc68',
    name: 'Kontaktdaten_Handwerker.tx',
    createdBy: 'Anthony Nahas',
    createdAt: 'Juni 02, 2020',
    modifiedAt: 'Juni 02, 2020',
    size: '943 KB',
    type: 'XLS',
    contents: null,
    description: null
  },
  {
    id: '74010810-16cf-441d-a1aa-c9fb620fceea',
    folderID: 'cd6897cb-acfd-4016-8b53-3f66a5b5fc68',
    name: 'Mietverwaltung',
    createdBy: 'Anthony Nahas',
    createdAt: 'November 01, 2022',
    modifiedAt: 'November 01, 2022',
    size: '3015 MB',
    type: 'folder',
    contents: '907 Dateien',
    description: 'Personal photos; selfies, family, vacation and etc.'
  },
  {
    id: 'a8c73e5a-8114-436d-ab54-d900b50b3762',
    folderID: '74010810-16cf-441d-a1aa-c9fb620fceea',
    name: 'Instandhaltungsplan_2023',
    createdBy: 'Anthony Nahas',
    createdAt: 'Juni 02, 2020',
    modifiedAt: 'Juni 02, 2020',
    size: '943 KB',
    type: 'XLS',
    contents: null,
    description: null
  },

  {
    id: '12d851a8-4f60-473e-8a59-abe4b422ea99',
    folderID: '6da8747f-b474-4c9a-9eba-5ef212285500',
    name: 'Notizen_Mieterversammlung_2022',
    createdBy: 'Anthony Nahas',
    createdAt: 'Juni 02, 2020',
    modifiedAt: 'Juni 02, 2020',
    size: '943 KB',
    type: 'XLS',
    contents: null,
    description: null
  },
  {
    id: '2836766d-27e1-4f40-a31a-5a8419105e7e',
    folderID: 'ed58add1-45a7-41db-887d-3ca7ee7f2719',
    name: 'Hausordnung_Musterstraße_1a',
    createdBy: 'Anthony Nahas',
    createdAt: 'Juni 02, 2020',
    modifiedAt: 'Juni 02, 2020',
    size: '943 KB',
    type: 'DOC',
    contents: null,
    description: null
  }
];
