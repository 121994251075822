import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    ViewChild
} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {fuseAnimations} from '@fuse/animations';
import {BaseWidget, NgAisIndex, NgAisInstantSearch, NgAisModule} from 'angular-instantsearch';
import {connectSearchBox} from 'instantsearch.js/es/connectors';
import {fromEvent, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {DisableControlDirective} from '../../../directives/disable-control/disable-control.directive';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatBadgeModule} from '@angular/material/badge';
import {MatIconModule} from '@angular/material/icon';
import {NgIf} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
    selector: 'instant-search',
    templateUrl: 'algolia-instant-search.component.html',
    styleUrls: ['algolia-instant-search.component.scss'],
    animations: fuseAnimations,
    standalone: true,
    imports: [NgAisModule, MatFormFieldModule, NgIf, MatIconModule, MatBadgeModule, MatTooltipModule, MatButtonModule, MatInputModule, FormsModule, DisableControlDirective, ReactiveFormsModule]
})
export class AlgoliaInstantSearchComponent extends BaseWidget implements OnInit, OnDestroy {

    @ViewChild('search', {static: true}) searchInput: ElementRef;

    @Input() class: string = 'fuse-mat-rounded fuse-mat-dense fuse-mat-no-subscript flex-auto w-full mx-4 lg:ml-0';
    @Input() label: string;
    @Input() placeHolder: string = 'Suchen...';
    @Input() disabled: boolean;
    @Input() hits: number;
    @Input() iconToolTip: string;

    @Output() onNewQuery: EventEmitter<{
        query: string;
        refine: Function;
        clear: Function;
        isSearchStalled: boolean;
        widgetParams: object;
    }> = new EventEmitter();

    searchInputControl: UntypedFormControl = new UntypedFormControl();

    state: {
        query: string;
        refine: Function;
        clear: Function;
        isSearchStalled: boolean;
        widgetParams: object;
    };
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(@Inject(forwardRef(() => NgAisIndex))
                @Optional()
                public parentIndex: NgAisIndex,
                @Inject(forwardRef(() => NgAisInstantSearch))
                public instantSearchInstance: NgAisInstantSearch,
                private _changeDetectorRef: ChangeDetectorRef,
                private _activatedRoute: ActivatedRoute) {
        super('SearchBox');

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.createWidget(connectSearchBox, {
            // instance options
        });
        super.ngOnInit();

        if (this._activatedRoute.snapshot.queryParams.query) {
            this.searchInputControl.patchValue(this._activatedRoute.snapshot.queryParams.query);
        }


        fromEvent(this.searchInput.nativeElement, 'keyup')
            .pipe(takeUntil(this._unsubscribeAll), debounceTime(0), distinctUntilChanged())
            .subscribe((ref: any) => {
                this._changeDetectorRef.markForCheck();
                this._changeDetectorRef.detectChanges();
                console.log('this.searchInput.nativeElement ref', ref?.target?.value);
                this.state?.refine(ref?.target?.value);
                console.log('algolia state', this.state);
                this.onNewQuery.next(this.state);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        console.log('onDestroy() --> NahausSearchBoxDirective');
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        // this.instantSearchParent?.instantSearchInstance?.dispose();
    }

    clear(): void {
        this.state.clear();
        this.searchInputControl.reset();
        this._changeDetectorRef.markForCheck();
    }
}
