import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';
import { Observable, of } from 'rxjs';
import { NahausModulesPermission } from '../../modules/admin/users/models/user.interface';

@Pipe({
    name: 'hasCustomClaim',
    standalone: true
})
export class HasCustomClaimPipe implements PipeTransform {

  constructor(private auth: AuthService) {
  }

  transform(value: NahausModulesPermission): Observable<boolean> {
    if (this.auth?.claims) {
      console.log('HasCustomClaimPipe --> claims', this.auth?.claims);
      return of(!!this.auth?.claims[value]);
    } else {
      // return
    }
    // return of(true);
  }

}
