import {Timestamp} from "firebase/firestore";
import {DigitalProtocolKey} from "./digital-protocol-key.interface";
import {GermanAddress} from "@angular-material-extensions/google-maps-autocomplete";

/**
 * @author Anthony Nahas
 * @since 30.05.2024
 * @updated 02.06.2024
 * @version 1.1
 */
export interface DigitalProtocol {
    id?: string;
    customerID?: string;
    propertyID?: string;
    unitID?: string;
    periodID?: string;

    h_id?: number;
    w_id?: number;

    createdAt?: Timestamp | Date;
    updatedAt?: Timestamp | Date;
    date?: Timestamp | Date;
    status?: ProtocolStatus;

    keys?: DigitalProtocolKey[];
    address?: GermanAddress; // from unit.address || property.address
    unitIDLabel?: string;
    unitLabel?: string;
    propertyIDLabel?: string;
    tenantsName?: string;

    landlordCertificateGeneratedAndSent?: boolean; // Wohnungsgeberbescheinigung erhalten
}

export type DigitalProtocols = DigitalProtocol[];


export enum ProtocolStatusOptions {
    DRAFT = 'DRAFT',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    SENT = 'SENT',
    AWAITING_SIGNATURE = 'AWAITING_SIGNATURE',
    CLOSED = 'CLOSED'
}

export type ProtocolStatus =
    ProtocolStatusOptions.DRAFT
    | ProtocolStatusOptions.IN_PROGRESS
    | ProtocolStatusOptions.COMPLETED
    | ProtocolStatusOptions.SENT
    | ProtocolStatusOptions.AWAITING_SIGNATURE
    | ProtocolStatusOptions.CLOSED;
