import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { chain, forEach, isEmpty, isEqual, isNil, isObject, omitBy, transform } from 'lodash';
import { isArray } from 'lodash-es';

export * from './dates';
export * from './firebase';

export const removeEmpty = (obj: any): Object => {
  if (isArray(obj)) {
    // @ts-ignore
    return obj
      .filter(k => {
        // console.log('k', k);
        return k != null;
      }) // Remove undef. and null.
      .filter(j => JSON.stringify(j) !== '{}') // Remove undef. and null.
      .reduce(
        (newObj, k) => {
          // console.log('Array', 'newObj', newObj, 'k', k, 'obj[k]', obj[k]);
          return isObject(k) && !(k instanceof Date) ?
            // @ts-ignore
            [...newObj, removeEmpty(k)] : [...newObj, k];
        }, // Copy value.
        []
      );
  }
  return Object.keys(obj)
    // .map(x => x)
    // .filter(k => isEmpty(obj[k])) // Remove undef. and null.
    // .filter(k => isNil(obj[k])) // Remove undef. and null.
    // .filter(k => omitBy(obj[k], isEmpty)) // Remove undef. and null.
    .filter(k => obj[k] != null) // Remove undef. and null.
    .filter(j => JSON.stringify(obj[j]) !== '{}') // Remove undef. and null.
    .reduce(
      (newObj, k) => {
        // console.log('Object', 'newObj', newObj, 'k', k, 'obj[k]', obj[k]);
        return isObject(obj[k]) && !(obj[k] instanceof Date) ?
          { ...newObj, [k]: removeEmpty(obj[k]) } :
          { ...newObj, [k]: obj[k] };
      }, // Copy value.
      {}
    );
};


export function removeEmptyObj(obj: any): any {
  console.log('removeEmptyObj', obj);
  if (isObject(obj)) {
    // return removeEmptyObj(obj);
  }

  // Object.keys(obj).forEach(k => {
  // if (isObject(obj[key]) && !isEmpty(obj[key])) {
  //   console.log('Second Loop Object:::' + key + ' ' + obj[key]);
  //   removeEmptyObj(obj[key]);
  // }
  // if (isEmpty(obj[key])) {
  //   console.log('Delete Object:::' + key + ' ' + obj[key]);
  //   obj = omitBy(obj[key], isEmpty);
  //   obj = omitBy(obj[key], isNil);
  // }
  // });
  // for (const key of obj) {
  //   console.log('Foor Loop' + key + ' ' + obj[key]);
  // if (isObject(obj[key]) && !isEmpty(obj[key])) {
  //   console.log('Second Loop Object:::' + key + ' ' + obj[key]);
  //   removeEmptyObj(obj[key]);
  // }
  // if (isEmpty(obj[key])) {
  //   console.log('Delete Object:::' + key + ' ' + obj[key]);
  //   obj = omitBy(obj[key], isEmpty);
  //   obj = omitBy(obj[key], isNil);
  // }
  // }
  console.log(obj);
  return omitBy(obj, isEmpty);
}

export function removeEmptyObjects(obj: any): any {
  return _(obj)
    .omitBy(isEmpty) // remove all empty objects
    .omitBy(isNil) // remove all empty objects
    .pickBy(isObject) // pick objects only
    .mapValues(removeEmptyObjects) // call only for object values
    .omitBy(isEmpty) // remove all empty objects
    .omitBy(isNil) // remove all empty objects
    .assign(omitBy(obj, isObject)) // assign back primitive values
    .value();
}

export function remove2(obj: any): any {
  return chain(obj).omitBy(isEmpty).omitBy(isNil).value();
}

export function recursiveFunction(collection: object): any {
  const x = collection;
  forEach(x, (value: any, key: string) => {
    console.log('value', value, 'key', key);
    if (isObject(value)) {
      return recursiveFunction(value);
    } else {
      return omitBy(x, isEmpty);
      // omitBy(value, isNil);
    }
  });
}


/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object: any, base: any): any {
  function changes(object: any, base: any): any {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        // console.log('value', value);
        // console.log('base[key]', base[key]);
        // console.log('logic', (isObject(value) && isObject(base[key])));
        result[key] = (isObject(value) && isObject(base[key]) && !(value instanceof Date)) ? changes(value, base[key]) : value;
      }
    });
  }

  return changes(object, base);
}


export function findInvalidControls(formGroup: UntypedFormGroup): any {
  const invalid = [];
  const controls = formGroup.controls;
  for (const name in controls) {
    if (controls[name].invalid) {
      invalid.push(name);
    }
  }
  return invalid;
}

export function findInvalidDeepControls(controls: { [key: string]: AbstractControl }): any {
  const invalid = [];
  for (const name in controls) {
    if (controls[name].invalid) {
      invalid.push(name);
    }
  }
  console.log('invalid -> ', invalid);
  return invalid;
}
