import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { PublicProfileSettings } from '@settings/models/publicProfile.settings.interface';
import { PublicProfileSettingsService } from '@settings/services/publicProfileSettings.service';
import { NahausTiersOptions } from '@users/models/user.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PublicProfileSettingsResolver implements Resolve<PublicProfileSettings> {

  constructor(private publicProfileSettingsService: PublicProfileSettingsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PublicProfileSettings> {
    if (this.publicProfileSettingsService?.authService?.tier === NahausTiersOptions.ENTERPRISE) {
      return this.publicProfileSettingsService.doc().get().pipe(map(doc => doc?.data() as PublicProfileSettings));
    } else {
      return null;
    }
  }
}
