export const QUILL_CONFIG:any={
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }], // not supported
        [{ 'align': [] }], 
        ['bold', 'italic', 'underline'],
        [{list: 'ordered'}, {list: 'bullet'}],
        [{ 'color': [] }, { 'background': [] }],
        ['clean']
    ]
}


export function preprocessHtmlForPdfmake(html: string): string {
    if(!html){
        return html;
    }else{
        let processedHtml = html
        .replace(/class="ql-align-center"/g, 'style="text-align:center;"')
        .replace(/class="ql-align-right"/g, 'style="text-align:right;"')
        .replace(/class="ql-align-left"/g, 'style="text-align:left;"')
        .replace(/class="ql-align-justify"/g, 'style="text-align:justify;"');
        console.log("processedHtml : ",processedHtml)
    return processedHtml;
    }

}