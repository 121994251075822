<div class="relative flex flex-col w-full h-full">

    <div class="absolute top-0 right-0 pt-4 pr-4">
        <button
            [matDialogClose]="undefined"
            mat-icon-button>
            <mat-icon
                [svgIcon]="'heroicons_outline:x'"
                class="text-secondary"></mat-icon>
        </button>
    </div>

    <!-- Content -->
    <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">

        <!-- Icon -->
        <div
            [ngClass]="{'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600': !this.showError,
                            'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600': this.showError
                            }"
            class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full">
            <mat-icon
                [svgIcon]="'mat_outline:payments'"
                class="text-current"></mat-icon>
        </div>

        <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 text-center sm:text-left">

            <div class="flex flex-col space-y-1 w-full">

                <!-- Title -->
                <div class="text-xl leading-6 font-medium">Aufteilung der Zahlung</div>

                <!-- Message -->
                <div class="text-secondary mb-4"> Hier finden Sie eine detaillierte Auflistung der Aufteilung Ihrer
                    Zahlung auf die einzelnen Kostenpositionen.
                </div>

                <div class="text-secondary mb-4">Zahlungsdaten</div>

                <div class="flex flex-row justify-between my-2">
                    <div class="text-secondary font-semibold">Buchung:</div>
                    <div class="text-secondary text-right">{{this.bookingPayment?.text}}</div>
                </div>

                <div class="flex flex-row justify-between my-2">
                    <div class="text-secondary font-semibold">Datum</div>
                    <div
                        class="text-secondary text-right">{{this.bookingPayment?.date | toDate | date:'shortDate'}}</div>
                </div>

                <div class="flex flex-row justify-between my-2">
                    <div class="text-secondary font-semibold">Zahlung</div>
                    <div class="text-secondary text-right">{{this.bookingPayment?.amount | currency:'EUR'}}</div>
                </div>

                <div class="w-full my-8">
                    <mat-divider class="w-full my-4"></mat-divider>
                </div>

                <div class="text-secondary mb-4">Daten der Zahlungen</div>

            </div>

            <div class="flex flex-col mt-6 space-y-6 w-full">

                <form *ngIf="!this.loadingBookingPaymentDivisions;else displayLoading"
                      [formGroup]="bookingPaymentFormGroup">
                    <ng-container
                        *ngFor="let bookingPaymentDivision of this.bookingPaymentFormGroup.get('divisions')['controls']; let i = index; let last = last; let first = first"
                        formArrayName='divisions'>
                        <div [formGroupName]='i' class="flex flex-row space-y-4 w-full">
                            <mat-form-field class="w-full" subscriptSizing="dynamic">
                                <mat-label>{{bookingPaymentDivision.get('description')?.value}}</mat-label>
                                <input [options]="{ prefix: '€', thousands: '.', decimal: ',',align: 'left'}"
                                       currencyMask
                                       formControlName="amount"
                                       matInput
                                       name="amount">
                                <mat-hint>Saldo
                                    Betrag: {{bookingPaymentDivision.get('saldo')?.value | currency:'EUR'}}</mat-hint>
                                <!--						<mat-hint>Geben Sie den Betrag ein, der in der Sollstelle gebucht werden soll.</mat-hint>-->
                                <mat-error>Bitte geben Sie einen gültigen Betrag ein</mat-error>
                            </mat-form-field>
                        </div>
                    </ng-container>
                </form>

                <fuse-alert *ngIf="this.showError"
                            [appearance]="'outline'"
                            [type]="'error'"
                            class="w-full">
                    <span fuseAlertTitle>Fehler</span>
                    <div class="flex flex-col space-y-2">
                        <!--						<p>Leider ist einen Fehler aufgetreten! Bitte versuchen Sie später noch einmal!</p>-->
                        <p>{{this.errorMsg}}</p>
                    </div>
                </fuse-alert>

                <ng-template #displayLoading>
                    <div class="flex flex-row w-full justify-center">
                        <mat-progress-spinner [diameter]="48" mode="indeterminate"></mat-progress-spinner>
                    </div>
                </ng-template>
            </div>
        </div>


    </div>

    <!-- Actions -->
    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

        <!-- Cancel -->
        <button
            [matDialogClose]="'cancelled'"
            mat-stroked-button>
            Abbrechen
        </button>

        <!-- Confirm -->
        <button (click)="this.save()"
                *ngIf="!this.loadingBookingPaymentDivisions"
                [color]="this.showError | toColorPalette"
                [disabled]="this.saving"
                mat-flat-button>
            <span *ngIf="!this.saving">Speichern</span>
            <mat-progress-spinner
                *ngIf=this.saving
                [diameter]="24"
                [mode]="'indeterminate'"
                matTooltip="Bitte warten..."></mat-progress-spinner>
        </button>

    </div>

</div>
