import {CdkScrollable} from '@angular/cdk/scrolling';
import {DOCUMENT, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTabGroup, MatTabsModule} from '@angular/material/tabs';
import {RouterLink} from '@angular/router';
import {FuseFindByKeyPipe} from '@fuse/pipes/find-by-key/find-by-key.pipe';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher';
import {Subject, takeUntil} from 'rxjs';
import {ResidentialProtocolSteps} from "../../models/protocol-steps";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {defaultResidentialKeys, DigitalProtocolKey} from "../../models/digital-protocol-key.interface";
import {DigitalProtocol} from "../../models/digital-protocol.interface";
import {fuseAnimations} from "@fuse/animations";
import {defaultResidentialDivision} from "../../models/digital-protocol-division.interface";
import {QuillEditorComponent} from "ngx-quill";
import {FuseCardComponent} from "@fuse/components/card";
import {MatListModule} from "@angular/material/list";
import {MatTooltipModule} from "@angular/material/tooltip";
import {FuseAlertComponent} from "@fuse/components/alert";
import {MatDialog} from "@angular/material/dialog";
import {FuseConfirmationConfig} from "@fuse/services/confirmation";
import {CameraDialogComponent} from "@shared/components/dialogs/camera-dialog/camera-dialog.component";

@Component({
    selector: 'create-digital-protocol',
    templateUrl: './create-digital-protocol.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    standalone: true,
    imports: [MatSidenavModule, RouterLink, MatIconModule, NgIf, NgClass, NgFor, MatButtonModule, MatProgressBarModule, CdkScrollable, MatTabsModule, FuseFindByKeyPipe, NgSwitchCase, NgTemplateOutlet, NgSwitch, MatInputModule, MatDatepickerModule, MatCheckboxModule, ReactiveFormsModule, QuillEditorComponent, FuseCardComponent, MatListModule, MatTooltipModule, FuseAlertComponent],
})
export class CreateDigitalProtocolComponent implements OnInit, OnDestroy {

    @ViewChild('courseSteps', {static: true}) courseSteps: MatTabGroup;

    currentStep: number = 3;
    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;

    activeUser: boolean;
    steps = ResidentialProtocolSteps;
    totalSteps: number = 8;

    digitalProtocol: DigitalProtocol;

    // formsGroup
    digitalProtocolForm: FormGroup;
    divisionForm: FormGroup;
    // keyForm: FormGroup;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private _elementRef: ElementRef,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    get keys(): FormArray {
        return this.digitalProtocolForm.get('keys') as FormArray;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Go to given step
     *
     * @param step
     */
    goToStep(step: number): void {
        // Set the current step
        this.currentStep = step;

        // Go to the step
        this.courseSteps.selectedIndex = this.currentStep;

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Go to previous step
     */
    goToPreviousStep(): void {
        // Return if we already on the first step
        if (this.currentStep === 0) {
            return;
        }

        // Go to step
        this.goToStep(this.currentStep - 1);

        // Scroll the current step selector from sidenav into view
        this._scrollCurrentStepElementIntoView();
    }

    /**
     * Go to next step
     */
    goToNextStep(): void {
        // Return if we already on the last step
        if (this.currentStep === this.totalSteps - 1) {
            return;
        }

        // Go to step
        this.goToStep(this.currentStep + 1);

        // Scroll the current step selector from sidenav into view
        this._scrollCurrentStepElementIntoView();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.courseSteps.selectedIndex = this.currentStep;
        this.initFormGroup(this.digitalProtocol);

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                // Set the drawerMode and drawerOpened
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                    this.drawerOpened = true;
                } else {
                    this.drawerMode = 'over';
                    this.drawerOpened = false;
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    addKey(type: string, quantity: string, numberID: string): void {
        const newKey: DigitalProtocolKey = {type, quantity, numberID};
        this.keys.push(this.fb.group(newKey));
    }

    removeKey(index: number): void {
        this.keys.removeAt(index);
    }

    get divisions(): FormArray {
        return this.divisionForm.get('divisions') as FormArray;
    }

    initFormGroup(digitalProtocol: DigitalProtocol): void {
        this.digitalProtocolForm = this.fb.group({
            keys: this.fb.array([])
        });

        if (digitalProtocol && digitalProtocol?.keys && digitalProtocol?.keys?.length > 0) {
            digitalProtocol?.keys.forEach(key => {
                this.addKey(key?.type, key?.quantity, key?.numberID);
            });
        } else {
            defaultResidentialKeys.forEach(key => {
                this.addKey(key?.type, key?.quantity, key?.numberID);
            });
        }

        this.divisionForm = this.fb.group({
            divisions: this.fb.array([])
        });

        defaultResidentialDivision.forEach(key => {
            this.addDivision(key?.title, key?.html);
        });
    }

    addDivision(title: string, html: string): void {
        this.divisions.push(this.fb.group({
            title: [title, Validators.required],
            html: [html]
        }));
    }

    removeDivision(index: number): void {
        this.divisions.removeAt(index);
    }

    openCameraDialog() {
        const data: FuseConfirmationConfig = {
            title: 'Foto aufnehmen',
            message: 'Bitte nehmen Sie ein Bild auf, um den aktuellen Zustand zu dokumentieren. Dies hilft uns, alles genau zu überprüfen.',
            icon: {
                show: true,
                name: 'mat_outline:photo_camera',
                color: 'primary'
            },
            actions: {
                cancel: {
                    show: true,
                    label: 'Abbrechen'
                },
                confirm: {
                    show: true,
                    color: "primary",
                    label: 'Bild aufnehmen'
                }
            },
            dismissible: true
        }
        const dialogRef = this.dialog.open(CameraDialogComponent, {
            width: '600px',
            autoFocus: false,
            maxHeight: '90vh', //you can adjust the value as per your view,
            disableClose: true,
            data,
            panelClass: 'fuse-confirmation-dialog-panel'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log('Bild aufgenommen:', result);
                // Hier können Sie das Bild weiterverarbeiten, z.B. anzeigen oder hochladen
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Scrolls the current step element from
     * sidenav into the view. This only happens when
     * previous/next buttons pressed as we don't want
     * to change the scroll position of the sidebar
     * when the user actually clicks around the sidebar.
     *
     * @private
     */
    private _scrollCurrentStepElementIntoView(): void {
        // Wrap everything into setTimeout so we can make sure that the 'current-step' class points to correct element
        setTimeout(() => {
            // Get the current step element and scroll it into view
            const currentStepElement = this._document.getElementsByClassName('current-step')[0];
            if (currentStepElement) {
                currentStepElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        });
    }
}
