// -----------------------------------------------------------------------------------------------------
// @ Board

import {Timestamp} from "firebase/firestore";
import {IBoard, ICard, IChecklist, ILabel, IList, IMember} from "./scrumboard.types";
import {User} from "@users/models/user.interface";
import {Property} from "../../properties/models/property.interface";
import {PropertyUnit} from "../../properties/features/units/models/unit.interface";
import {NahausImage} from "@shared/models/image.interface";

// -----------------------------------------------------------------------------------------------------
export class Board implements Required<IBoard> {
    id: string | null;
    title: string;
    description: string | null;
    icon: string | null;
    lastActivity: string | Date | null;
    lists: List[];
    labels: Label[];
    members: User[];
    createdAt: Timestamp | Date;
    numberOfCards: Number;
    lastFilter:Timestamp | Date;
    hasDoneBtn: boolean;
    DoneList: IList;
    customerID: string;

    /**
     * Constructor
     */
    constructor(board: IBoard) {
        this.id = board.id || null;
        this.title = board.title;
        this.description = board.description || null;
        this.icon = board.icon || null;
        this.lastActivity = board.lastActivity || null;
        this.lists = [];
        this.labels = [];
        this.members = [];
        this.numberOfCards = 0;
        this.customerID = board.customerID

        // Lists
        if (board.lists) {
            this.lists = board.lists.map((list) => {
                if (!(list instanceof List)) {
                    return new List(list);
                }

                return list;
            });
        }

        // Labels
        if (board.labels) {
            this.labels = board.labels.map((label) => {
                if (!(label instanceof Label)) {
                    return new Label(label);
                }

                return label;
            });
        }

        // Members
        if (board.members) {
            this.members = board?.members || [];
        }
    }
}

// -----------------------------------------------------------------------------------------------------
// @ List
// -----------------------------------------------------------------------------------------------------
export class List implements Required<IList> {
    id: string | null;
    boardId: string;
    position: number;
    title: string;
    cards: Card[];
    description?: string;
    customerID: string;
    createdAt:Date | Timestamp;
    updatedAt: Timestamp | Date;

    /**
     * Constructor
     */
    constructor(list: IList) {
        this.id = list.id || null;
        this.boardId = list.boardId;
        this.position = list.position;
        this.title = list.title;
        this.cards = [];
        this.customerID = list.customerID

        // Cards
        if (list.cards) {
            this.cards = list.cards.map((card) => {
                if (!(card instanceof Card)) {
                    return new Card(card);
                }

                return card;
            });
        }
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Card
// -----------------------------------------------------------------------------------------------------
export class Card implements Required<ICard> {
    id: string | null;
    boardId: string;
    listId: string;
    position: number;
    title: string;
    description: string | null;
    labels: Label[];
    dueDate: Date | Timestamp;
    cardNumber: string | null;
    members: User[];
    startDate: Date | Timestamp;
    propertyID: string;
    unitID: string;
    property: Property;
    unit: PropertyUnit;
    reminder: string;
    checklist: IChecklist[];
    isArchived: boolean;
    attachments: NahausImage[];
    coverImageUrl: NahausImage;
    customerID: string;
    labelsListID: string[];
    isTemplate:boolean;
    createdAt: Date | Timestamp;
    updatedAt: Timestamp | Date;
    isShortcut: boolean ;
    shortcutID:string
    // property:Partial<Proper>
    /**
     * Constructor
     */
    constructor(card: ICard) {
        this.id = card.id || null;
        this.boardId = card.boardId;
        this.listId = card.listId;
        this.position = card.position;
        this.title = card.title;
        this.description = card.description || null;
        this.labels = [];
        this.dueDate = card.dueDate || null;
        this.customerID = card.customerID
        this.labelsListID = card.labelsListID || []
        this.createdAt = card.createdAt
        // Labels
        if (card.labels) {
            this.labels = card.labels.map((label) => {
                if (!(label instanceof Label)) {
                    return new Label(label);
                }

                return label;
            });
        }
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Member
// -----------------------------------------------------------------------------------------------------
export class Member implements Required<IMember> {
    id: string | null;
    name: string;
    avatar: string | null;

    /**
     * Constructor
     */
    constructor(member: IMember) {
        this.id = member.id || null;
        this.name = member.name;
        this.avatar = member.avatar || null;
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Label
// -----------------------------------------------------------------------------------------------------
export class Label implements Required<ILabel> {
    id: string | null;
    color: string;
    title: string;

    /**
     * Constructor
     */
    constructor(label: ILabel) {
        this.id = label.id || null;
        this.color = label.color;
        this.title = label.title;
    }
}
