import {Injectable} from '@angular/core';
import {AbstractFirestoreService} from '@shared/services/abstracts/AbstractFirestoreService';
import {AuthService} from '@shared/services/auth.service';
import {BehaviorSubject} from 'rxjs';
import {Note} from '../models/notes.types';
import { NahausImage } from '@shared/models/image.interface';

@Injectable()
export class NoteService extends AbstractFirestoreService<Note> {

    onListChanged$: BehaviorSubject<Note[]> = new BehaviorSubject<Note[]>(null);

    COLLECTION = 'notes';

    constructor(authService: AuthService) {
        super(authService);
  }
  setParentPath(userID: string): void {
    this.parentPath = `users/${userID}`;
}

 resetPath(): void {
    this.parentPath = null;
} 

createImageCollectionDoc(noteId: string,imageToUpdate:NahausImage) {
    if(imageToUpdate){
        console.log("the image id for the image to update",imageToUpdate.id)
        console.log("the image to update with ",imageToUpdate.path);
    }
    
    return this.doc(`${noteId}/images/${imageToUpdate.id}`).set(imageToUpdate, { merge: true });
  }

  deleteImageCollectionDoc(noteId: string,imageToDelete:NahausImage){

    return this.doc(`${noteId}/images/${imageToDelete.id}`).delete();

  }
getPath():string{
    return this.parentPath;
}
}
