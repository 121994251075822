import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractFirestoreDocResolver } from '@shared/services/abstracts/AbstractFirestoreDocResolver';
import { PropertyUnit } from '../models/unit.interface';
import { UnitsService } from '../services/units.service';


@Injectable()
export class UnitsResolverDeep1Resolver extends AbstractFirestoreDocResolver<PropertyUnit> {

  constructor(route: ActivatedRoute, router: Router, service: UnitsService) {
    super(router, service);
    this.paramKeyID = 'unitID';
    this.deep = 1;
    this.parentPathBuilderArray = [{ path: 'properties' }, { paramKey: 'id', deep: 1 }];
  }
}
