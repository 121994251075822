import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FuseNavigationItem} from '@fuse/components/navigation/navigation.types';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {debounceTime, distinctUntilChanged, filter, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgIf} from '@angular/common';
import {FuseVerticalNavigationComponent} from '@fuse/components/navigation/vertical/vertical.component';

@Component({
    selector: 'expenses-sidebar',
    template: `
        <div class="py-10">
            <!-- Add any extra content that might be supplied with the component -->
            <div class="extra-content">
                <ng-content></ng-content>
            </div>

            <!-- Fixed demo sidebar -->
            <!--            <div class="mx-6 text-3xl font-bold tracking-tighter">Buchhaltungmenü</div>-->
            <fuse-vertical-navigation
                [appearance]="'default'"
                [navigation]="menuData"
                [inner]="true"
                [mode]="'side'"
                [name]="'expenses-sidebar-navigation'"
                [opened]="true"></fuse-vertical-navigation>

            <div *ngIf="false" class="mx-6 my-2">
                <div class="flex items-center">
                    <mat-form-field class="w-full">
                        <mat-label>Benutzerdefinierter Zeitraum</mat-label>
                        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                            <input formControlName="start" matStartDate placeholder="Start Datum">
                            <input formControlName="end" matEndDate placeholder="End Datum">
                        </mat-date-range-input>
                        <mat-hint>TT.MM.JJJJ – TT.MM.JJJJ</mat-hint>
                        <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            demo-sidebar fuse-vertical-navigation .fuse-vertical-navigation-wrapper {
                box-shadow: none !important;
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FuseVerticalNavigationComponent, NgIf, MatFormFieldModule, MatDatepickerModule, FormsModule, ReactiveFormsModule]
})
export class ExpensesMainSidebarComponent implements OnInit, OnDestroy {

    range: FormGroup;
    menuData: FuseNavigationItem[];

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor() {

        this.range = new FormGroup({
            start: new FormControl<Date | null>(null),
            end: new FormControl<Date | null>(null)
        });

        this.menuData = [
            {
                title: 'FILTER',
                // subtitle: 'Verwalten Sie Ihre Ausgabenkategorien für eine präzise Buchhaltung. (Nebenkosten, Anlage V...)',
                type: 'group',
                children: [
                    {
                        title: 'Umlagefähig',
                        type: 'basic',
                        icon: 'mat_outline:call_split',
                        link: 'filters/umlagefaehig'
                    },
                    {
                        title: 'Steuerrelevant',
                        type: 'basic',
                        icon: 'mat_outline:account_balance',
                        link: 'filters/steuerrelevant'
                    },
                    {
                        title: 'Abschreibungsrelevant',
                        type: 'basic',
                        icon: 'mat_outline:trending_down',
                        link: 'filters/abschreibungrelevant'
                    },
                ],
            },
            {
                title: 'Buchungskonten',
                subtitle: 'Verwalten Sie Ihre Buchungskonten für eine präzise Buchhaltung. (Nebenkosten, Anlage V...)',
                type: 'group',
                children: [
                    {
                        title: 'Hinzufügen',
                        type: 'basic',
                        icon: 'mat_outline:add_circle_outline',
                        link: '/buchungskonten/anlegen'
                    },
                    {
                        title: 'Bearbeiten',
                        type: 'basic',
                        icon: 'mat_outline:edit',
                        link: '/buchungskonten'
                    },
                ],
            },
            {
                title: 'Zeitraumfilter',
                type: 'group',
                hidden: (() => true),
                children: [
                    {
                        title: 'Diesen Monat',
                        type: 'basic',
                        icon: 'mat_outline:today',
                        // badge: {
                        //     title: '49',
                        //     classes: 'px-2 bg-primary text-on-primary rounded-full',
                        // },
                    },
                    {
                        title: 'Letzter Monat',
                        type: 'basic',
                        icon: 'mat_outline:history',
                    },
                ],
            },
            {
                type: 'divider',
            },
        ];
    }

    ngOnInit(): void {
        this.range.valueChanges.pipe(takeUntil(this._unsubscribeAll), filter(range => !!range), debounceTime(1000), distinctUntilChanged())
            .subscribe((range) => {
                console.log('range changed --> ', range);
                // this.transactionsModularService.onRangeChanged$.next(range);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
