<div class="relative flex flex-col w-full h-full">

    <div class="absolute top-0 right-0 pt-4 pr-4">
        <button
            [matDialogClose]="undefined"
            mat-icon-button>
            <mat-icon
                [svgIcon]="'heroicons_outline:x'"
                class="text-secondary"></mat-icon>
        </button>
    </div>

    <!-- Content -->
    <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">

        <!-- Icon -->
        <div
            [ngClass]="{'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600': !this.showError,
                            'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600': this.showError
                            }"
            class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full">
            <mat-icon
                [svgIcon]="'mat_outline:payments'"
                class="text-current"></mat-icon>
        </div>

        <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 text-center sm:text-left">

            <div class="flex flex-col space-y-1 ">

                <!-- Title -->
                <div *ngIf="!this.booking" class="text-xl leading-6 font-medium">Neue Buchung in der Sollstelle
                    hinzufügen
                </div>
                <div *ngIf="this.booking" class="text-xl leading-6 font-medium">Die Buchung in der Sollstelle
                    aktualisieren
                </div>

                <!-- Message -->
                <div *ngIf="!this.booking" class="text-secondary mb-4">Fügen Sie eine neue Buchung in der Sollstelle
                    hinzu, indem Sie das
                    Datum, den
                    Betrag und eine kurze Beschreibung eingeben.
                </div>

            </div>

            <ng-container *ngIf="!this.loading && this.bookingFormGroup; else displayLoadingSpinner">
                <div class="flex flex-col mt-6 space-y-6 w-full">
                    <form [formGroup]="this.bookingFormGroup">
                        <div>
                            <mat-radio-group aria-label='Select landlord type' class="flex space-x-4 my-4"
                                             color="primary"
                                             formControlName='type'>
                                <mat-radio-button [value]='this.bookingTypeOptions.PAYMENT'>Einzahlung
                                </mat-radio-button>
                                <mat-radio-button [value]='this.bookingTypeOptions.PAYOUT'>Auszahlung</mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <mat-form-field class="w-full">
                            <mat-label>Art der Sollstellung</mat-label>
                            <mat-select [disableControl]="!!this.booking?.art" formControlName="art">
                                <mat-option [value]="this.bookingArtOptions.WARM_MIETE">Warm Miete</mat-option>
                                <mat-option [value]="this.bookingArtOptions.KALT_MIETE">Kalt Miete</mat-option>
                                <mat-option [value]="this.bookingArtOptions.BK">Betriebskosten</mat-option>
                                <mat-option [value]="this.bookingArtOptions.HK">Heizkosten</mat-option>
                                <mat-option [value]="this.bookingArtOptions.KAUTION">Kaution</mat-option>
                                <mat-option [value]="this.bookingArtOptions.DUNNING_FEES">Mahngebühren</mat-option>
                                <mat-option [value]="this.bookingArtOptions.SP">Sonderposten</mat-option>
                                <mat-option [value]="this.bookingArtOptions.INTEREST_EXPENSES">Zinsaufwendungen
                                </mat-option>
                            </mat-select>
                            <!--						<mat-hint>Bitte wählen Sie die Art der Sollstellung aus.</mat-hint>-->
                            <mat-error>Bitte wählen Sie eine Art der Sollstellung aus.</mat-error>
                        </mat-form-field>

                        <mat-form-field class="w-full">
                            <mat-label>Betrag</mat-label>
                            <input [options]="{ prefix: '€', thousands: '.', decimal: ',',align: 'left'}"
                                   currencyMask
                                   formControlName="amount"
                                   matInput
                                   name="soll.amount">
                            <!--						<mat-hint>Geben Sie den Betrag ein, der in der Sollstelle gebucht werden soll.</mat-hint>-->
                            <!--						<mat-error>{{this.bookingFormGroup.get('amount')?.errors | json}}</mat-error>-->
                            <mat-error *ngIf="this.bookingFormGroup.get('amount')?.hasError('min')">Bitte geben Sie
                                einen
                                positiven Betrag ein
                            </mat-error>
                            <mat-error *ngIf="this.bookingFormGroup.get('amount')?.hasError('max')">Bitte geben Sie
                                einen
                                negativen Betrag ein
                            </mat-error>
                            <mat-error *ngIf="this.bookingFormGroup.get('amount')?.hasError('required')">Bitte geben Sie
                                einen gültigen Betrag ein
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="w-full">
                            <mat-label>Datum der Buchung</mat-label>
                            <input [matDatepicker]="datePicker"
                                   [placeholder]="'Datum hier eingeben'"
                                   formControlName="date"
                                   matInput
                                   name="date">
                            <mat-datepicker-toggle
                                [for]="datePicker"
                                matSuffix>
                            </mat-datepicker-toggle>
                            <mat-datepicker #datePicker>
                            </mat-datepicker>
                            <!--						<mat-hint>Wählen Sie das Datum der Buchung aus</mat-hint>-->
                            <mat-error>Bitte wählen Sie ein gültiges Datum</mat-error>
                        </mat-form-field>

                        <mat-form-field class="w-full">
                            <mat-label>Buchungstext</mat-label>
                            <input #textInput
                                   [placeholder]="'Miete ' + this.today | date:'MM/yyyy'"
                                   formControlName="text" matInput
                                   maxlength="50">
                            <mat-hint *ngIf="!(textInput?.value?.length > 0)">Geben Sie eine kurze Beschreibung für die
                                Buchung ein (maximal 50 Zeichen)
                            </mat-hint>
                            <mat-hint *ngIf="textInput?.value?.length > 0" align="end">{{textInput?.value?.length}} / 50
                            </mat-hint>
                            <mat-error>Bitte geben Sie eine kurze Beschreibung für die Buchung ein (maximal 50 Zeichen)
                            </mat-error>
                        </mat-form-field>
                    </form>

                    <fuse-alert *ngIf="this.showError"
                                [appearance]="'outline'"
                                [type]="'error'"
                                class="w-full">
                        <span fuseAlertTitle>Fehler</span>
                        <div class="flex flex-col space-y-2">
                            <p>Leider ist einen Fehler aufgetreten! Bitte versuchen Sie später noch einmal!</p>
                            <p>{{this.errorMsg}}</p>
                        </div>
                    </fuse-alert>
                </div>

                <div *ngIf="this.bookingFormGroup.get('art')?.value === this.bookingArtOptions.WARM_MIETE"
                     class="flex flex-col mt-2">
                    <fuse-alert @zoomIn @zoomOut [type]="'primary'" class="">
                        <mat-icon [svgIcon]="'mat_outline:auto_fix_high'"
                                  class="icon-size-5"
                                  fuseAlertIcon>
                        </mat-icon>
                        <span fuseAlertTitle>Automatisierung</span>
                        <span>Klicken Sie auf <span class="font-extrabold">'Generieren' </span>, um mehrere Sollstellungen für einen bestimmten Zeitraum zu erstellen. <span
                            *ngIf="false">Die Sollstellungen werden entsprechend Ihrer festgelegten Zahlungsstrategie berechnet. Bei Mietbeginn im Monatsverlauf erfolgt eine anteilige Berechnung."</span></span>
                        <div class="flex flex-col justify-center mt-4">
                            <button (click)="this.openGenerateBookingsDialog()" color="primary" mat-button
                                    matTooltip="Klicken Sie auf diesen Button, um mehrere Sollstellungen auf einmal zu generieren. Geben Sie das Start- und Enddatum ein, um den Zeitraum festzulegen, für den Sollstellungen erstellt werden sollen. Beachten Sie, dass die Sollstellungen basierend auf der festgelegten Zahlungsstrategie (monatlich, vierteljährlich, halbjährlich oder jährlich) generiert werden. Wenn das Startdatum in der Mitte des Monats liegt, wird die Sollstellung für diesen Monat anteilig berechnet.">
                                <mat-icon [svgIcon]="'mat_outline:library_add'"></mat-icon>
                                <span *ngIf="false" class="ml-2 mr-1">Mehrere Sollstellungen generieren</span>
                                <span class="ml-2 mr-1">Generieren</span>
                            </button>
                        </div>
                    </fuse-alert>
                </div>
            </ng-container>
            <ng-template #displayLoadingSpinner>
                <div class="flex flex-col items-center mt-6 space-y-6 w-full">
                    <mat-progress-spinner [diameter]="64" color="primary" mode="indeterminate"></mat-progress-spinner>
                </div>
            </ng-template>
        </div>


    </div>

    <!-- Actions -->
    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

        <!-- Cancel -->
        <button
            [matDialogClose]="'cancelled'"
            mat-stroked-button>
            Abbrechen
        </button>

        <!-- Confirm -->
        <button (click)="this.createBooking()"
                [color]="this.showError | toColorPalette"
                [disabled]="this.saving"
                mat-flat-button>
            <span *ngIf="!this.saving">Speichern</span>
            <mat-progress-spinner
                *ngIf=this.saving
                [diameter]="24"
                [mode]="'indeterminate'"
                matTooltip="Bitte warten..."></mat-progress-spinner>
        </button>

    </div>

</div>
