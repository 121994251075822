import {registerLocaleData} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import '@angular/localize/init';
import {ExtraOptions, PreloadAllModules} from '@angular/router';
import {environment} from '@env/environment';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';


import 'moment/locale/de';
// import filepond module

registerLocaleData(localeDe);

// AoT requires an exported function for factories
export const HttpLoaderFactory = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http);

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    initialNavigation: 'enabledBlocking'
};


const googleMapsParams = {
    apiKey: environment.GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
    language: 'de',
    region: 'DE'
};


