import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {
    collectionSnapshots,
    getCountFromServer,
    limit,
    orderBy,
    query,
    startAfter,
    startAt,
    where
} from '@angular/fire/firestore';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {MatSelectChange, MatSelectModule} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSortModule, Sort} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {QueryConstraint} from '@firebase/firestore';
import {fuseAnimations} from '@fuse/animations';
import {FuseConfigService} from '@fuse/services/config-old';
import {LocalStorage} from 'ngx-webstorage';
import {QueryDocumentSnapshot} from 'rxfire/firestore/interfaces';
import {BehaviorSubject, combineLatest, firstValueFrom, Subject, switchMap} from 'rxjs';
import {debounceTime, map, take, takeUntil, tap} from 'rxjs/operators';
import {AppConfig} from '../../../../../core/config/app.config';
import {ContractStatus} from '../../../contracts/models/contract';
import {
    PropertyUnitPeriod
} from '../../../properties/features/units/features/period/models/property.unit.period.interface';
import {PropertyUnit} from '../../../properties/features/units/models/unit.interface';
import {Property} from '../../../properties/models/property.interface';
import {
    Booking,
    BookingArtOptions,
    BookingBuiltInDateRangeFilterOptions,
    BookingStatus,
    BookingStatusOptions,
    BookingTypeOptions
} from '../../models/booking';
import {BookingPayment} from '../../models/bookingPayment';
import {BookingService} from '../../services/booking.service';
import {BookingAccountService} from '../../services/bookingAccount.service';
import {BookingPaymentsService} from '../../services/bookingPayments.service';
import {BookingsModularService} from '../../services/bookings-modular.service';
import {
    CreateBookingDialog,
    CreateBookingDialogComponent
} from '../create-booking-dialog/create-booking-dialog.component';
import {
    CreateBookingPaymentDialog,
    CreateBookingPaymentDialogComponent
} from '../create-booking-payment-dialog/create-booking-payment-dialog.component';
import {
    CreateBookingPaymentDivisionDialog,
    CreateBookingPaymentDivisionDialogComponent
} from '../create-booking-payment-division-dialog/create-booking-payment-division-dialog.component';
import {DeleteBookingDialogComponent} from '../delete-booking-dialog/delete-booking-dialog.component';
import {AccessLayerOptions} from "../../booking.routing";
import {DateTime} from "luxon";
import {Query} from "@angular/fire/compat/firestore";
import {SelectionModel} from "@angular/cdk/collections";
import {FuseConfirmationConfig, FuseConfirmationService} from "@fuse/services/confirmation-old";
import {PdfService} from '@shared/services/pdf.service';
import {bookingslist} from '../../pdf/bookings';
import {getLogo} from '@shared/svg';
import {CurrencyPipe, DatePipe, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';
import {ngxCsv} from 'ngx-csv/ngx-csv';
import moment from "moment";
import {MatBottomSheet, MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {
    ExportBookingsTypeBottomSheetComponent
} from './export-bookings-type-bottom-sheet/export-bookings-type-bottom-sheet.component';
import {getPublicProfileData} from '@settings/helpers';
import {CustomerLogo} from '@settings/models/logo.settings.interface';
import {PublicProfileSettings} from '@settings/models/publicProfile.settings.interface';
import {DunningTypeOptions, LastWarningTypeOptions} from "../../features/dunning/models/dunning.interface";
import {MatDrawer} from "@angular/material/sidenav";
import {BookingsMainComponent} from "../bookings-main/bookings-main.component";
import {InstantSearchConfig} from "angular-instantsearch/instantsearch/instantsearch";
import {environment} from "@env/environment";
import {TransactionsRange} from "@transactions/services/transactions-modular.service";
import {BookingsHelperService} from "../../services/bookings-helper.service";
import {
    YoutubeDialogComponent,
    YoutubeDialogData,
    YoutubeDialogResult
} from "@shared/youtube-dialog/dialog/youtube-dialog.component";
import {CanCreateDunningPipe} from '../../pipes/can-create-dunning.pipe';
import {FormatAddressPipe, ToDatePipe} from '@shared/pipes';
import {FuseCardComponent} from '@fuse/components/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MouseHoverDirective} from '@shared/directives/mouse-hover.directive';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatChipsModule} from '@angular/material/chips';
import {MatDividerModule} from '@angular/material/divider';
import {
    AlgoliaAutocompleteComponent
} from '@shared/algolia/components/algolia-autocomplete/algolia-autocomplete.component';
import {NgAisInstantSearchModule} from 'angular-instantsearch';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {YoutubePreviewComponent} from '@shared/youtube/youtube-preview/youtube-preview.component';

@Component({
    selector: 'bookings-details-list',
    templateUrl: './booking-period-details-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    providers: [BookingService, BookingAccountService, BookingPaymentsService, BookingsModularService],
    standalone: true,
    imports: [MatBottomSheetModule, CdkScrollable, NgIf, MatButtonModule, MatIconModule, MatTooltipModule, NgAisInstantSearchModule, AlgoliaAutocompleteComponent, MatDividerModule, MatChipsModule, NgFor, MatProgressSpinnerModule, MatProgressBarModule, MatTableModule, MatSortModule, NgClass, MatCheckboxModule, NgSwitch, NgSwitchCase, NgSwitchDefault, RouterLink, MouseHoverDirective, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule, MatPaginatorModule, FuseCardComponent, CurrencyPipe, DatePipe, ToDatePipe, FormatAddressPipe, CanCreateDunningPipe, YoutubePreviewComponent]
})
export class BookingPeriodDetailsListComponent implements OnInit, OnDestroy {

    @LocalStorage('BookingsMainSidebarComponent.openYoutube', true)
    openYoutube: boolean;

    @LocalStorage('BookingPeriodDetailsListComponent.defaultPageSize', 10)
    defaultPageSize: number;

    matDrawer: MatDrawer;

    now = DateTime.now();

    env = environment;

    property: Property;
    unit: PropertyUnit;
    period: PropertyUnitPeriod;
    accessLayer: AccessLayerOptions;
    bookingsAccessLayerOptions = AccessLayerOptions;

    onlyDeposit: boolean;
    onlyUnpaid: boolean;
    statusFilter: BookingStatusOptions;
    dateFilter: BookingBuiltInDateRangeFilterOptions;

    initialLoad: boolean;
    bookingExists!: boolean;
    appTheme: string;
    selection = new SelectionModel<Booking>(true, []);
    bookingsDataSource: MatTableDataSource<Booking>;
    columns: string[] = ['transactionId', 'name', 'date', 'amount', 'haben', 'saldo', 'status', 'lastDunning.date', 'buttons'];
    columns2: string[] = ['transactionId', 'date', 'haben', 'c', 'd', 'e'];
    bookingTypeOptions = BookingTypeOptions;
    bookingStatusOptions = BookingStatusOptions;
    bookingArtOptions = BookingArtOptions;
    selectedBooking: Booking;

    dunningTypeOptions = DunningTypeOptions;
    lastWarningTypeOptions = LastWarningTypeOptions;

    // pagination
    firstDocMap: Map<number, any> = new Map<number, any>();
    lastItem: any;
    page: PageEvent;

    // indicators
    isLoading: boolean = false;
    creating: boolean;
    loadingSaldo: boolean;
    saldo: number;
    totalAmount: number;
    addingPayments: boolean;
    deleting: boolean;

    selectedProperties: Property[] = [];

    // Filters
    onPageEventChanged$: BehaviorSubject<PageEvent>;
    onFilterStatusChanged$: BehaviorSubject<ContractStatus> = new BehaviorSubject<ContractStatus>(null);
    onPropertyFilterChanged$: BehaviorSubject<Property[]> = new BehaviorSubject<Property[]>(null);
    // onStatusFilterChanged$: BehaviorSubject<BookingStatusOptions> = new BehaviorSubject<BookingStatusOptions>(null);
    onSortChanged$: BehaviorSubject<Sort> = new BehaviorSubject<Sort>(null);

    configProperties: InstantSearchConfig;

    publicProfile: PublicProfileSettings;
    logo: CustomerLogo;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    activeUser: boolean;

    /**
     * Constructor
     */
    constructor(
        private route: ActivatedRoute,
        private cf: ChangeDetectorRef,
        private router: Router,
        private bookingService: BookingService,
        private bookingsHelperService: BookingsHelperService,
        private bookingPaymentsService: BookingPaymentsService,
        private bookingAccountService: BookingAccountService,
        private bookingsModularService: BookingsModularService,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
        private _fuseConfigService: FuseConfigService,
        private fuseConfirmationService: FuseConfirmationService,
        private pdf: PdfService,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        private _bottomSheet: MatBottomSheet,
        private bookingsMainComponent: BookingsMainComponent,
    ) {
        this.configProperties = {
            indexName: environment.algolia.indexName.properties,
            searchClient: this.bookingService?.authService?.getAlgoliaSearchClient(true),
            routing: true
        };
    }

    /**
     * On init
     */
    ngOnInit(): void {

        this.matDrawer = this.bookingsMainComponent.drawer;

        console.log("BookingPeriodDetailsListComponent --> ", this.route.parent?.parent?.parent?.parent?.parent?.snapshot?.data)
        console.log("BookingPeriodDetailsListComponent --> ", this.route.parent?.parent?.parent?.parent?.snapshot?.data)
        console.log("BookingPeriodDetailsListComponent --> ", this.route.parent?.parent?.parent?.snapshot?.data)
        console.log("BookingPeriodDetailsListComponent --> ", this.route.parent?.parent?.snapshot?.data)
        console.log("BookingPeriodDetailsListComponent --> ", this.route.parent?.snapshot?.data)
        console.log("BookingPeriodDetailsListComponent --> ", this.route?.snapshot?.data)

        this.accessLayer = (this.route.snapshot?.data?.accessLayer || this.route.parent?.snapshot?.data?.accessLayer) as AccessLayerOptions || AccessLayerOptions.PERIOD;
        this.statusFilter = this.route?.snapshot?.data?.statusFilter as BookingStatusOptions;
        this.dateFilter = this.route?.snapshot?.data?.dateFilter as BookingBuiltInDateRangeFilterOptions;

        switch (this.dateFilter) {
            case BookingBuiltInDateRangeFilterOptions.THIS_MONTH:
                const startDate = this.now.startOf('month').toJSDate();
                const endDate = DateTime.fromJSDate(startDate).endOf('month').toJSDate();
                this.bookingsHelperService.onRangeChanged$.next({start: startDate, end: endDate})
                break;
            case BookingBuiltInDateRangeFilterOptions.LAST_MONTH:
                const startDate2 = this.now.minus({month: 1}).startOf('month').toJSDate();
                const endDate2 = DateTime.fromJSDate(startDate2).endOf('month').toJSDate();
                this.bookingsHelperService.onRangeChanged$.next({start: startDate2, end: endDate2})
                break;
            default:
                if (this.accessLayer !== AccessLayerOptions.PERIOD) {
                    const startDate = this.now.minus({month: 1}).startOf('month').toJSDate();
                    const endDate = this.now.endOf('month').toJSDate();
                    this.bookingsHelperService.onRangeChanged$.next({start: startDate, end: endDate})
                    break;
                }
        }


        console.log("this.accessLayer = ", this.accessLayer)
        this.property = (this.route.snapshot.data?.property || this.route?.parent.snapshot.data?.property) as Property;
        this.unit = (this.route.snapshot?.data?.unit || this.route?.parent.snapshot.data?.unit) as PropertyUnit;
        this.period = (this.route.snapshot?.data?.period || this.route?.parent.snapshot.data?.period) as PropertyUnitPeriod;
        this.onlyDeposit = (this.route.snapshot.data?.onlyDeposit || this.route?.parent?.snapshot.data?.onlyDeposit) as boolean;
        this.onlyUnpaid = (this.route.snapshot.data?.onlyUnpaid || this.route?.parent?.snapshot.data?.onlyUnpaid) as boolean;

        console.log("this.period = ", this.period)
        console.log("this.onlyDeposit = ", this.onlyDeposit)
        console.log("this.onlyUnpaid = ", this.onlyUnpaid)

        this.logo = (this.route.snapshot.data?.logo || this.route?.parent.snapshot.data?.logo) as CustomerLogo;
        console.log('logo = ', this.logo);
        this.publicProfile = getPublicProfileData((this.route.snapshot.data?.publicProfile || this.route?.parent?.snapshot.data?.publicProfile) as PublicProfileSettings, null);
        this.publicProfile.logo = this.logo;

        console.log('publicProfile = ', this.publicProfile);

        this.activeUser = this.bookingService?.authService?.isActiveUser;
        this.bookingService?.authService?.isActiveUser$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isActive) => this.activeUser = isActive);
        console.log('activeUser = ', this.activeUser);


        this.page = {pageSize: this.defaultPageSize, length: this.page?.length, pageIndex: 0};
        this.onPageEventChanged$ = new BehaviorSubject<PageEvent>(this.page);

        console.log('BookingPeriodListComponent --> data --> ', this.property, this.unit, this.period);
        // console.log('AddendumListComponent --> data 2 --> ', this.route.snapshot?.parent?.data);

        if (!(this.property && this.unit && this.period)) {
            console.error('Error no property unit and period found');

            if (this.accessLayer === AccessLayerOptions.PERIOD) {
                // go to error page
                // this.router.navigate(['fehler', '404'])
                //     .then(() => console.error('!(this.property && this.unit && this.period) === true --> navigate to error page'));
            }

        } else {
            this.bookingService.setParentPath(this.property?.id, this.unit?.id, this.period?.id);
            this.bookingsModularService.setParentPath(this.property?.id, this.unit?.id, this.period?.id);
            this.bookingPaymentsService.setOnlyPeriod(this.property?.id, this.unit?.id, this.period?.id);
            this.bookingAccountService.setParentPathForPeriod(this.property?.id, this.unit?.id, this.period?.id);
        }

        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: AppConfig) => {
                console.log('config', config);
                this.appTheme = config?.theme.split('-')[1];
                this.cf.markForCheck();
            });

        this.adaptColumnView();
        this.loadData();

        this.bookingsHelperService.initColumns(this.columns);

        this.bookingsHelperService.columns$.pipe(takeUntil(this._unsubscribeAll)).subscribe(columns => {
            this.columns = columns;
            this.cf.markForCheck();
        });

        if (this.accessLayer === AccessLayerOptions.PERIOD) {
            this.loadSaldo();
        }

        // console.log("starting with getNextBatch");
        // this.getNextBatchOfBookings(100)
        //     .then((res) => {
        //         console.log("getNextBatchOfBookings resolved --> ", res);
        //     })
        //     .catch((err) => console.error("Error in getNextBatchOfBookings --> ", err))
        // console.log("done with getNextBatch");

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    loadData(): void {
        combineLatest([
            this.onSortChanged$,
            this.onPageEventChanged$,
            this.onPropertyFilterChanged$,
            this.bookingsHelperService.onRangeChanged$.pipe(tap(() => this.resetFirstAndLastItem())),
        ]).pipe(
            takeUntil(this._unsubscribeAll),
            debounceTime(250),
            tap(() => {
                console.log('loading ...');
                this.isLoading = true;
                this.cf.markForCheck();
            }),
            switchMap(([sort, pageEvent, selectedProperties, range]: [Sort, PageEvent, Property[], TransactionsRange]) => {

                console.log("load data queries --> ", sort, selectedProperties, range)

                    const queryConstraintList: QueryConstraint[] = [];
                    const limitConstraintList: QueryConstraint[] = [];

                if (this.accessLayer === AccessLayerOptions.PROPERTY) {
                        queryConstraintList.push(where('propertyID', '==', this.property?.id));
                    }

                if (this.accessLayer !== AccessLayerOptions.PERIOD && !this.onlyUnpaid) {
                        // const last2Months = DateTime.now().minus({months: 2}).toJSDate();
                        // console.log("last2Months --> ", last2Months)
                        // queryConstraintList.push(where('date', '>=', last2Months));
                    }

                if (selectedProperties?.length) {
                    const propertyIDList = selectedProperties.map(p => p.id);
                    console.log("selectedProperties --> ", selectedProperties);
                    console.log("propertyIDList --> ", propertyIDList);
                    queryConstraintList.push(where('propertyID', 'in', propertyIDList));
                }


                    // filtering
                    if (this.onlyUnpaid) {
                        queryConstraintList.push(where('paid', '==', false));
                    }

                    if (this.onlyDeposit) {
                        queryConstraintList.push(where('art', '==', this.bookingArtOptions.KAUTION));
                    }

                if (this.statusFilter) {
                    queryConstraintList.push(where('status', '==', this.statusFilter));
                }


                if (range && sort?.active !== 'date') {
                    if (range?.start) {
                        queryConstraintList.push(where('date', '>=', range.start));
                    }
                    if (range?.end) {
                        queryConstraintList.push(where('date', '<=', range.end));
                    }
                }

                if (sort) {
                    if ((range?.start || range?.end) && sort?.active !== 'date') {
                        queryConstraintList.push(orderBy('date', 'desc'));
                    }
                    queryConstraintList.push(orderBy(sort.active, sort.direction === 'asc' ? "asc" : 'desc'));
                    if (sort.active !== 'date' && !range) {
                        queryConstraintList.push(orderBy('date', 'desc'));
                    }
                } else {
                    queryConstraintList.push(orderBy('date', 'desc'));
                }

                    // sorting
                // queryConstraintList.push(orderBy('date', 'desc'));
                    limitConstraintList.push(limit(pageEvent?.pageSize));

                    // pagination
                    if (pageEvent.pageIndex < pageEvent.previousPageIndex) {
                        // get previous page
                        // console.log('get previous page', this.firstDocMap);
                        if (this.firstDocMap) {
                            limitConstraintList.push(startAt(this.firstDocMap.get(pageEvent.pageIndex)));
                        }
                    } else {
                        // get next page
                        // console.log('get next page', this.lastItem);
                        if (this.lastItem) {
                            limitConstraintList.push(startAfter(this.lastItem));
                        }
                    }

                    const collectionQuery = this.bookingsModularService.collection();
                    const ref = query(collectionQuery, ...queryConstraintList, ...limitConstraintList);
                    const refCount = query(collectionQuery, ...queryConstraintList);

                    getCountFromServer(refCount)
                        .then((res) => {
                            const count = res.data().count || 0;
                            console.log('getCountFromServer --> ', res, count);
                            this.page.length = count;
                        }).catch((err) => {
                        console.error('Error in getCountFromServer --> ', err);
                    });


                    return collectionSnapshots(ref);
                }
            ))
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((bookingsSnaps: QueryDocumentSnapshot<Booking>[]) => {
                const bookings = [];
                bookingsSnaps.forEach((action) => {
                    // list.push(convertTimestamps(action.payload.doc.data()));
                    bookings.push(action.data());
                });
                console.log('loaded bookings -> ', bookings);
                // console.log('loaded payments -> ', payments);
                const list = [];
                bookings.forEach((b: Booking) => {
                    // const bookingPayments = payments.filter((p) => p?.bookingID === b?.id);
                    b?.payments?.length ? list.push(b, ...b?.payments) : list.push(b);
                });

                if (!this.initialLoad) {
                    this.initialLoad = true;
                    this.bookingExists = bookingsSnaps.length > 0;
                    console.log("landlordExist: " + this.bookingExists);

                }

                this.bookingsDataSource = new MatTableDataSource<Booking>(list);

                if (bookings?.length === 0) {
                    this.firstDocMap = new Map<number, any>();
                    this.lastItem = null;
                } else {
                    this.firstDocMap.set(this.page?.pageIndex ? this.page?.pageIndex : 0, bookingsSnaps[0]);
                    this.lastItem = bookingsSnaps[bookingsSnaps.length - 1];
                }

                this.isLoading = false;
                // Mark for check
                this.cf.markForCheck();
            });
    }

    formatDate(date: Date | any, format: string = 'DD.MM.YYYY'): string {
        return moment(date?.toDate()).format(format).toString();
    }

    export(): void {
        console.log("generate pdf data ", this.bookingsDataSource);
        this._bottomSheet
            .open(ExportBookingsTypeBottomSheetComponent)
            .afterDismissed()
            .pipe(take(1))
            .subscribe((data: any) => {
                console.log('ExportBookingsTypeBottomSheetComponent result', data);

                if (!data) {
                    return;
                }
                this.cf.markForCheck();

                if (data == 'pdf') {
                    this.pdf.open(bookingslist(this.bookingsDataSource.data, getLogo(), this.currencyPipe, this.datePipe, this.property, this.unit, this.publicProfile, this.saldo, this.activeUser));
                } else if (data === 'csv') {
                    const dataToExport: {
                        text: string,
                        date: string,
                        tenantsName: string,
                        amount: string,
                        paymentAmount: string,
                        saldo: string,
                        status: string
                    }[] =
                        this.bookingsDataSource.data.map(booking => ({
                            text: booking.text ? booking.text : "",
                            date: this.formatDate(booking.date),
                            tenantsName: booking.tenantsName ? booking.tenantsName : "",
                            amount: booking.status ? this.currencyPipe.transform(booking.amount || 0, 'EUR') : "",
                            paymentAmount: booking.status ? "" : this.currencyPipe.transform(booking.amount || 0, 'EUR'),
                            saldo:  booking?.paid ? this.currencyPipe.transform(0, 'EUR') : (booking?.saldo ? this.currencyPipe.transform(booking?.saldo || 0, 'EUR') : ""),
                            status: booking.status ? booking.status : ""
                        }));

                    console.log("generate pdf data ", dataToExport);
                    dataToExport.push({
                        text: "",
                        date: "",
                        tenantsName: "",
                        amount: "Total : ",
                        paymentAmount: "",
                        saldo: this.currencyPipe.transform(this.saldo || 0, 'EUR'),
                        status: this.saldo >= 0 ? "Alles bezahlt" : "Nicht alles bezahlt",
                    });

                    var options = {
                        fieldSeparator: ',',
                        quoteStrings: '"',
                        decimalseparator: '.',
                        showLabels: true,
                        showTitle: true,
                        title: 'Datum : ' + this.datePipe.transform(new Date(), 'shortDate'),
                        useBom: true,
                        noDownload: false,
                        headers: ['Bezeichnung', 'Datum', 'Name', 'Soll Betrag', 'Zahlung', 'Saldo', 'Status']
                    };

                    new ngxCsv(dataToExport, "bookingsCSV", options);
                } else {
                    return;
                }
            });
    }


    resetFirstAndLastItem(): void {
        this.firstDocMap = new Map<number, any>();
        this.lastItem = null;
    }

    onPageChanged($event: PageEvent): void {
        if (this.page?.pageSize !== $event?.pageSize) {
            this.resetFirstAndLastItem();
        }
        if (this.defaultPageSize !== $event?.pageSize) {
            this.defaultPageSize = $event.pageSize;
        }
        this.onPageEventChanged$.next($event);
        this.page = $event;
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item?.id || index;
    }

    openNewBookingDialog(booking?: Booking): void {
        const data: CreateBookingDialog = {
            propertyID: this.property?.id,
            unitID: this.unit?.id,
            period: this.period,
            booking
        };
        this.dialog.open(CreateBookingDialogComponent, {
            autoFocus: false,
            disableClose: true,
            data,
            panelClass: 'fuse-confirmation-dialog-panel',
            maxHeight: '90vh'
        });
    }

    openBookingPaymentOrDivisionDialog(booking: Booking | BookingPayment) {
        // @ts-ignore
        if (booking?.bookingID as BookingPayment) {
            this.openBookingPaymentsDivisionDialog(booking);
        } else {
            this.openBookingPaymentsDialog(booking);
        }
    }

    openBookingPaymentsDialog(booking: Booking): void {
        // @ts-ignore
        if (booking?.bookingID) {
            return;
        }
        const data: CreateBookingPaymentDialog = {
            propertyID: this.property?.id,
            unitID: this.unit?.id,
            period: this.period,
            booking
        };
        this.dialog.open(CreateBookingPaymentDialogComponent, {
            autoFocus: false,
            disableClose: true,
            data,
            panelClass: 'fuse-confirmation-dialog-panel',
            maxHeight: '90vh'
        });
    }

    openBookingPaymentsDivisionDialog(bookingPayment: BookingPayment): void {
        const data: CreateBookingPaymentDivisionDialog = {
            propertyID: this.property?.id,
            unitID: this.unit?.id,
            periodID: this.period?.id,
            bookingPayment
        };
        this.dialog.open(CreateBookingPaymentDivisionDialogComponent, {
            autoFocus: false,
            disableClose: true,
            data,
            panelClass: 'fuse-confirmation-dialog-panel',
            maxHeight: '90vh'
        });
    }

    openDeleteBookingDialog(booking: Booking): void {
        this.dialog.open(DeleteBookingDialogComponent, {
            autoFocus: false,
            disableClose: true,
            data: booking,
            panelClass: 'fuse-confirmation-dialog-panel',
            maxHeight: '90vh'
        });
    }

    onStatusChangeRequest($event: MatSelectChange, booking: Booking, statusSelect) {
        const selectedStatus: BookingStatus = $event.value;
        console.log('booking before canceled --> ', booking);

        switch (selectedStatus) {
            case BookingStatusOptions.EXPECTED:
            case BookingStatusOptions.NOT_PAID:
                const statusText = selectedStatus === BookingStatusOptions.EXPECTED ? 'Erwartet' : 'Nicht bezahlt';
                this.bookingService.docWithParent(`properties/${booking?.propertyID}/units/${booking?.unitID}/periods/${booking?.periodID}`, booking?.id)
                    .update({status: selectedStatus})
                    .then(() => this.snackbar.open(`Die Buchung wurde erfolgreich aktualisiert und ist nun auf den Status ${statusText} gesetzt worden.`, 'OK', {duration: 5000}));
                break;
            case BookingStatusOptions.PAID:
            case BookingStatusOptions.PARTIALLY_PAID:
                const data: CreateBookingPaymentDialog = {
                    propertyID: this.property?.id,
                    unitID: this.unit?.id,
                    period: this.period,
                    booking,
                    requestedStatus: selectedStatus
                };
                this.dialog.open(CreateBookingPaymentDialogComponent, {
                    autoFocus: false,
                    disableClose: true,
                    data,
                    panelClass: 'fuse-confirmation-dialog-panel',
                    maxHeight: '90vh'
                }).afterClosed().pipe(take(1)).subscribe((res) => {
                    console.log('after close --> ', res);
                    if (!res || res === 'cancelled') {
                        console.log('booking after canceled --> ', booking);
                        statusSelect.value = booking.status;
                    }
                });
        }
    }

    loadSaldo(): void {
        this.loadingSaldo = true;
        this.cf.markForCheck();

        const ref = this.onlyDeposit ? this.bookingAccountService.getDepositAccount() : this.bookingAccountService.getSaldoAccount();
        ref.valueChanges().pipe(takeUntil(this._unsubscribeAll)).subscribe((saldo) => {
            this.totalAmount = saldo?.amount || 0;
            this.saldo = saldo?.saldo || 0;
            if (this.loadingSaldo) {
                this.loadingSaldo = false;
            }
            this.cf.markForCheck();
        });
    }

    /**
     * Close the details
     */
    closeDetails(): void {
        // this.router.navigate(['./'], { relativeTo: this.route });
        this.selectedBooking = null;
        // this.paramPropertyID = null;
    }

    adaptColumnView(): void {
        switch (this.accessLayer) {
            case AccessLayerOptions.ACCOUNT:
                this.columns.splice(1, 0, 'address');
                this.columns.splice(2, 0, 'unitLabel');
                this.cf.markForCheck();
                break;
            case AccessLayerOptions.PROPERTY:
                this.columns.splice(1, 0, 'unitLabel');
                this.cf.markForCheck();
                break;
        }
    }

    async getNextBatchOfBookings(limit: number, lastDoc?: any): Promise<any> {
        const snapshot = await firstValueFrom(this.bookingService.collection((ref) => {
            let query: Query = ref;
            if (lastDoc) {
                console.log("lastDoc exists --> startAfter(lastDoc)")
                query = query.startAfter(lastDoc);
            } else {
                console.warn("lastDoc does not exist");
            }

            query = query.limit(limit);

            return query;
        }).get().pipe(take(1)));

        if (snapshot.empty) {
            console.log("snapshot is empty --> done with querying the collection --> return");
            return;
        } else {
            console.log(`snapshot size = ${snapshot.size}`);

            const lastDocSnap = snapshot.docs[snapshot?.size - 1]; // setting new last doc
            return this.getNextBatchOfBookings(limit, lastDocSnap)
        }
    }

    sortMsaData($event: Sort): void {
        console.log('sortData:', $event);
        this.resetFirstAndLastItem();
        this.page = {pageSize: this.page?.pageSize, length: this.page?.length, pageIndex: 0};
        this.onPageEventChanged$.next(this.page);
        this.onSortChanged$.next($event);
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.bookingsDataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows(): void {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.bookingsDataSource.data);
        console.log('selection', this.selection);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Booking, i?: number): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${i + 1}`;
    }

    requestDeleteSelection(): void {
        if (this.selection?.isEmpty()) {
            console.log("selection is empty --> return");
            return;
        }

        const config: FuseConfirmationConfig = {
            title: 'Löschung bestätigen',
            message: 'Sind Sie sicher, dass Sie die ausgewählte(n) Sollstellung(en) löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden und alle zugehörigen Daten gehen verloren.',
            icon: {
                show: true,
                name: 'mat_outline:delete',
                color: 'warn'
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'Löschen',
                    color: 'warn'
                },
                cancel: {
                    show: true,
                    label: 'Abbrechen'
                }
            },
            dismissible: false
        };

        this.fuseConfirmationService.open(config).afterClosed().pipe(map(result => result === 'confirmed')).subscribe((value) => {
            if (value) {
                this.deleteSelectedBookings();
            }
        });
    }

    deleteSelectedBookings(): void {
        this.deleting = true;
        this.cf.markForCheck();

        const batch = this.bookingService.batch();

        this.selection.selected.forEach(booking => {
            const docRef = this.bookingService.docWithParent(`properties/${booking?.propertyID}/units/${booking?.unitID}/periods/${booking?.periodID}`, booking?.id).ref;
            batch.delete(docRef);
        })

        batch
            .commit()
            .then(() => {
                const msg = 'Die ausgewählte(n) Sollstellung(en) wurde(n) erfolgreich gelöscht.';
                this.snackbar.open(msg, "OK", {duration: 5000});
            })
            .catch((err) => {
                console.error("Error while deleting selected booking --> ", err)
                const msg = `Fehler beim Löschen der Sollstellung(en). Bitte versuchen Sie es erneut --> ${err}`;
                this.snackbar.open(msg, "OK", {duration: 5000});
            })
            .finally(() => {
                this.deleting = false;
                this.selection.clear();
                this.cf.markForCheck();
            })

    }

    requestMarkingSelectionAsPaid(): void {
        if (this.selection?.isEmpty()) {
            console.log("selection is empty --> return");
            return;
        }

        const config: FuseConfirmationConfig = {
            title: 'Zahlung bestätigen',
            message: 'Sie sicher, dass Sie die ausgewählte(n) Sollstellung(en) als bezahlt markieren möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
            icon: {
                show: true,
                name: 'mat_outline:check_circle',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'Als bezahlt markieren',
                    color: 'primary'
                },
                cancel: {
                    show: true,
                    label: 'Abbrechen'
                }
            },
            dismissible: false
        };

        this.fuseConfirmationService.open(config).afterClosed().pipe(map(result => result === 'confirmed')).subscribe((value) => {
            if (value) {
                this.markSelectionAsPaid();
            }
        });
    }

    markSelectionAsPaid() {

        if (this.selection?.isEmpty()) {
            console.log("selection is empty --> return");
            return;
        }

        this.addingPayments = true;
        this.cf.markForCheck();

        const batch = this.bookingService.batch();
        const today = new Date();

        this.selection.selected.forEach(booking => {
            const saldo = booking?.saldo;
            if (saldo < 0) {
                const propertyID = booking?.propertyID;
                const unitID = booking?.unitID;
                const periodID = booking?.periodID;

                const payment: BookingPayment = {
                    id: this.bookingPaymentsService.createID(),
                    customerID: this.bookingPaymentsService.authService?.customerID || null,
                    createdAt: today,
                    propertyID,
                    unitID,
                    periodID,
                    bookingID: booking?.id,
                    date: booking.date,
                    amount: booking?.saldo * -1,
                    // text: bookingPayment?.text || this.booking?.text,
                    art: booking?.art || null
                };
                const paymentParentPath = `properties/${propertyID}/units/${unitID}/periods/${periodID}/bookings/${booking.id}`;
                const paymentRef = this.bookingPaymentsService.docWithParent(paymentParentPath, payment.id).ref;
                batch.set(paymentRef, payment, {merge: true})
            }
        })

        batch
            .commit()
            .then(() => {
                const msg = 'Die Sollstellung(en) wurde(n) erfolgreich als bezahlt markiert.';
                this.snackbar.open(msg, "OK", {duration: 5000});
            })
            .catch((err) => {
                console.error("Error while deleting selected booking --> ", err)
                const msg = `Fehler beim Markieren der Sollstellung(en) als bezahlt. Bitte versuchen Sie es erneut --> ${err}`;
                this.snackbar.open(msg, "OK", {duration: 5000});
            })
            .finally(() => {
                this.addingPayments = false;
                this.selection.clear();
                this.cf.markForCheck();
            })
    }

    createDunning(booking: Booking) {
        // http://localhost:4213/immobilien/vLtuMdskBrPwR10Hxbm2/einheit/ne8jxX2b0v2U2olW7xxM/buchungen/eYJz30HTAUS5iWbdGKSO/mahnwesen
        this.router.navigate(['/immobilien', booking?.propertyID, 'einheit', booking?.unitID, 'buchungen', booking?.periodID, 'mahnwesen', 'erstellen'])
    }

    getPdf(url: string): void {
        if (url) {
            window.open(url, '_blank');
        }
    }

    remove(property: Property): void {
        const index = this.selectedProperties.indexOf(property);

        console.log("index = ", index);

        if (index >= 0) {
            this.selectedProperties.splice(index, 1);
            this.onPropertyFilterChanged$.next(this.selectedProperties);
            this.resetFirstAndLastItem();
        }
    }

    onQueryPropertySuggestionClick(property: Property) {

        const index = this.selectedProperties.indexOf(property);

        if (index < 0) {
            this.selectedProperties.push(property);
            this.onPropertyFilterChanged$.next(this.selectedProperties);
            this.resetFirstAndLastItem();
        }
    }

    openYoutubeDialog(force = false) {

        if (!this.openYoutube && !force) {
            return;
        }

        const data: YoutubeDialogData = {
            videoID: 'nO_NkZzx4pE',
            title: 'Willkommen beim Sollstellungsmodul',
            subtitle: 'Entdecken Sie in unserem kurzen Video, wie Sie Sollstellungen effizient erstellen und verwalten können'
        };

        this.dialog.open(YoutubeDialogComponent, {
            autoFocus: false,
            disableClose: true,
            data,
            panelClass: 'fuse-confirmation-dialog-panel',
            maxHeight: '90vh'
        }).afterClosed().pipe(take(1)).subscribe((res: YoutubeDialogResult) => {
            if (res === 'IGNORE') {
                this.openYoutube = false;
            }

            if (res === 'CONTINUE') {
            }
        });
    }
}
