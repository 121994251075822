<div class="relative flex flex-col w-full h-full">

    <div class="absolute top-0 right-0 pt-4 pr-4">
        <button [disabled]="this.saving"
                [matDialogClose]="undefined"
                mat-icon-button>
            <mat-icon
                [svgIcon]="'heroicons_outline:x'"
                class="text-secondary"></mat-icon>
        </button>
    </div>

    <!-- Content -->
    <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">

        <!-- Icon -->
        <div
            [ngClass]="{'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600': !this.showError,
                            'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600': this.showError
                            }"
            class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full">
            <mat-icon
                [svgIcon]="'mat_outline:library_add'"
                class="text-current"></mat-icon>
        </div>

        <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 text-center sm:text-left">

            <div class="flex flex-col space-y-1 ">

                <!-- Title -->
                <div class="text-xl leading-6 font-medium">Mehrere Sollstellungen erstellen
                </div>

                <!-- Message -->
                <div class="text-secondary mb-4">
                    Wählen Sie Start- und Enddatum aus, um mehrere Sollstellungen automatisch zu generieren.
                </div>

                <div *ngIf="false" class="text-secondary text-xs mb-4">
                    Beachten Sie, dass die Sollstellungen basierend auf der festgelegten Zahlungsstrategie (monatlich,
                    vierteljährlich, halbjährlich oder jährlich) generiert werden.
                </div>

            </div>

            <ng-container *ngIf="!this.loading && this.bookingFormGroup; else displayLoadingSpinner">
                <form [formGroup]="this.bookingFormGroup" class="w-full">
                    <div class="flex flex-col mt-6 space-y-4 w-full">

                        <mat-form-field class="w-full">
                            <mat-label>Start Datum</mat-label>
                            <input [matDatepicker]="startDatePicker"
                                   [max]="this.maxDate"
                                   [min]="this.minDate"
                                   [placeholder]="'Datum hier eingeben'"
                                   formControlName="startDate"
                                   matInput
                                   name="startDate">
                            <mat-datepicker-toggle
                                [for]="startDatePicker"
                                matSuffix>
                            </mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker>
                            </mat-datepicker>
                            <!--						<mat-hint>Wählen Sie das Datum der Buchung aus</mat-hint>-->
                            <mat-error *ngIf="this.bookingFormGroup.get('startDate').hasError('required')">Bitte wählen
                                Sie
                                ein gültiges Datum
                            </mat-error>
                            <mat-error
                                *ngIf="this.bookingFormGroup.get('startDate').hasError('matDatepickerMin')">
                                Das eingegebene Startdatum darf nicht älter sein als das Startdatum der Vermietung.
                            </mat-error>
                            <mat-error
                                *ngIf="this.bookingFormGroup.get('startDate').hasError('matDatepickerMax')">
                                Das eingegebene Startdatum darf nicht vor dem Startdatum der Vermietung liegen.
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="w-full">
                            <mat-label>End Datum</mat-label>
                            <input [matDatepicker]="endDatePicker"
                                   [max]="this.maxDate"
                                   [min]="this.minDate"
                                   [placeholder]="'Datum hier eingeben'"
                                   formControlName="endDate"
                                   matInput
                                   name="endDate">
                            <mat-datepicker-toggle
                                [for]="endDatePicker"
                                matSuffix>
                            </mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker>
                            </mat-datepicker>
                            <!--						<mat-hint>Wählen Sie das Datum der Buchung aus</mat-hint>-->
                            <mat-error *ngIf="this.bookingFormGroup.get('endDate').hasError('required')">Bitte wählen
                                Sie
                                ein gültiges Datum
                            </mat-error>
                            <mat-error
                                *ngIf="this.bookingFormGroup.get('endDate').hasError('matDatepickerMin')">
                                Das eingegebene Enddatum darf nicht vor dem Startdatum der Vermietung liegen.
                            </mat-error>
                            <mat-error
                                *ngIf="this.bookingFormGroup.get('endDate').hasError('matDatepickerMax')">
                                Das eingegebene Enddatum darf nicht nach dem Enddatum der Vermietung liegen.
                            </mat-error>
                        </mat-form-field>

                        <mat-checkbox class="w-full"
                                      color="primary"
                                      formControlName="autoBook"
                                      labelPosition="after">
                            Alle als bezahlt verbuchen
                        </mat-checkbox>

                        <fuse-alert *ngIf="this.showError"
                                    @zoomIn
                                    [appearance]="'outline'"
                                    [type]="'error'" class="w-full">
                            <span fuseAlertTitle>Fehler</span>
                            <div class="flex flex-col space-y-2">
                                <p>Leider ist einen Fehler aufgetreten! Bitte versuchen Sie später noch einmal!</p>
                                <p>{{this.errorMsg}}</p>
                            </div>
                        </fuse-alert>

                        <!-- Warning -->
                        <fuse-alert *ngIf="!this.showError && this.showProportionalRentHint"
                                    @zoomIn
                                    [dismissible]="true" [type]="'warning'">
                            <span fuseAlertTitle>Hinweis</span>
                            Da das Startdatum nicht auf den ersten Tag des Monats fällt, wird die Buchung für diesen
                            Monat
                            automatisch anteilig berechnet.
                        </fuse-alert>

                        <!-- Warning -->
                        <fuse-alert *ngIf="!this.showError && this.showHintOfActivatedAutoSoll"
                                    @zoomIn
                                    [dismissible]="true" [type]="'warning'">
                            <span fuseAlertTitle>Hinweis</span>
                            Da das eingegebene Enddatum in der Zukunft liegt und die automatische Sollstellung für diese
                            Vermietung aktiviert ist, besteht die Möglichkeit, dass zukünftige Buchungen doppelt
                            erstellt
                            werden. Bitte überprüfen Sie Ihre Eingaben sorgfältig, um dies zu vermeiden.
                        </fuse-alert>
                    </div>
                </form>
            </ng-container>
            <ng-template #displayLoadingSpinner>
                <div class="flex flex-col items-center mt-6 space-y-6 w-full">
                    <mat-progress-spinner [diameter]="64" color="primary" mode="indeterminate"></mat-progress-spinner>
                </div>
            </ng-template>
        </div>


    </div>

    <!-- Actions -->
    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

        <!-- Cancel -->
        <button
            [disabled]="this.saving"
            [matDialogClose]="'cancelled'"
            mat-stroked-button>
            Abbrechen
        </button>

        <!-- Confirm -->
        <button (click)="this.generate()"
                [color]="this.showError | toColorPalette"
                [disabled]="this.saving"
                mat-flat-button>
            <mat-icon *ngIf="!this.saving" [svgIcon]="'mat_outline:auto_fix_high'"></mat-icon>
            <span *ngIf="!this.saving" class="ml-2 mr-1">Generieren</span>
            <mat-progress-spinner
                *ngIf=this.saving
                [diameter]="24"
                [mode]="'indeterminate'"
                matTooltip="Bitte warten..."></mat-progress-spinner>
        </button>

    </div>

</div>
