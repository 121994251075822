import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {MatDialog} from '@angular/material/dialog';
import {FilePondAbstractComponent} from '@shared/components/filepond/file-pond-base/file-pond-abstract.component';
import {ExpensesImagesService} from "../../services/expenses-images.service";
import {FilePondModule} from 'ngx-filepond';

@Component({
    selector: 'app-expenses-file-pond',
    templateUrl: '../../../../../shared/components/filepond/file-pond-base/file-pond-abstract.component.html',
    standalone: true,
    imports: [FilePondModule]
})
export class ExpensesFilePondComponent extends FilePondAbstractComponent implements OnInit {

    @Input() labelIdle = `<span class="font-extrabold">PDF oder Bilder</span> per Drag-and-Drop hierher ziehen oder <span class="filepond--label-action"> Durchsuchen </span>`;

    constructor(storage: AngularFireStorage,
                cf: ChangeDetectorRef,
                dialog: MatDialog,
                private expensesImagesService: ExpensesImagesService) {
        super(storage, cf, dialog, expensesImagesService);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

}
