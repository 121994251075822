import { CustomerLogo } from '@settings/models/logo.settings.interface';
import {PublicProfileSettings} from '@settings/models/publicProfile.settings.interface';

const getName = (profile: PublicProfileSettings): string => {
    return !!profile?.companyName ? profile?.companyName : `${profile?.firstName} ${profile?.lastName}`;
};

const getMailSignHead = (profile: PublicProfileSettings): string => {
    return `${getName(profile)} ${profile?.companyComplement ?? ''} - ${profile?.address?.streetName} ${profile?.address?.streetNumber}, ${profile?.address?.postalCode} ${profile?.address?.locality?.long}`;
};

export const createPublicProfileHeader = (profile: PublicProfileSettings) => {
    const profileHeaderRows = [];
    profileHeaderRows.push({
        text: getName(profile),
        color: '#333333',
        width: '*',
        fontSize: 16,
        bold: true,
        alignment: 'right',
        margin: [0, 0, 0, 0]
    });

    if (!!profile?.companyComplement) {
        profileHeaderRows.push(
            {
                text: profile?.companyComplement,
                color: '#333333',
                width: '*',
                fontSize: 9,
                bold: true,
                alignment: 'right'
            }
        );
    }
    profileHeaderRows.push(
        {
            text: `${profile?.address?.streetName} ${profile?.address?.streetNumber}`,
            color: '#333333',
            width: '*',
             fontSize: 9,
            alignment: 'right'
        },
        {
            text: `${profile?.address?.postalCode} ${profile?.address?.locality?.long}`,
            color: '#333333',
            width: '*',
             fontSize: 9,
            alignment: 'right',
        },
    );

    if (profile?.features?.hasPhoneNumber) {
        profileHeaderRows.push(
            {
                text: `${profile?.phoneNumber ? 'Telefon: ' + profile?.phoneNumber : ''}`,
                color: '#333333',
                width: '*',
                fontSize: 8,
                alignment: 'right',
            },
        );
    }
    if (profile?.features?.hasEmail) {
        profileHeaderRows.push(
            {
                text: `${profile?.email ? 'E-Mail: ' + profile?.email : ''}`,
                color: '#333333',
                width: '*',
                fontSize: 8,
                alignment: 'right',
            }
        );
    }
    profileHeaderRows.push(
        {
            text: '',
            margin: [0, 0, 0, 20]
        }
    );
    return profileHeaderRows;
};

export const addAddress = (publicProfile: PublicProfileSettings) => {
    const profileHeaderRows = [];
    if (publicProfile?.address?.streetName) {
        profileHeaderRows.push(
            {
                width: '33%',
                text: [
                    {
                        text: 'Firmeninformation\n',
                        bold: true,
                        fontSize: 8,
                    },
                    {
                        //+ `${publicProfile?.features?.hasVatID ? publicProfile?.vatID ? 'VAT ID: ' + publicProfile?.vatID + '\n' : '' : ''}
                        text:`${publicProfile?.companyName ? (publicProfile?.companyName + ' ' + (publicProfile?.companyComplement ? publicProfile?.companyComplement : '') + '\n') : ''}`+ `${publicProfile?.address?.streetName ?publicProfile?.address?.streetName : ''} ${publicProfile?.address?.streetNumber ? publicProfile?.address?.streetNumber : ''}` + ', ' + `${publicProfile?.address?.postalCode ? publicProfile?.address?.postalCode : ''} ${publicProfile?.address?.locality?.long ? publicProfile?.address?.locality?.long : ''}` + '\n'+`${publicProfile?.ceo?'Geschäftsführer: '+publicProfile.ceo + '\n':''}` +`${publicProfile?.court?publicProfile?.court+', ':''}`+`${publicProfile?.features?.hasHrbNumber ? publicProfile?.hrb ? 'HRB ' + publicProfile?.hrb +'\n' : '' : ''}`+`${publicProfile?.features?.hasVatID?publicProfile?.vatID ? 'Steuer-Nr. ' + publicProfile?.vatID : '' : ''}`,
                        fontSize: 6.5,
                        color: '#404040'
                    }
                ],
                //text: 'Firmeninformation\nAm Wasser 100\n12345 Am Wasser\nDeutschland\nUST-Id-Nr:DE999999999',
                style: 'footerColumn',
            },
        )
    } else {
        profileHeaderRows.push(
            {
                width: '33%',
                text: '',
                // text: [
                //     {
                //         text: 'Firmeninformation\n',
                //         bold: true,
                //         fontSize: 8,
                //     },
                // ],
                //text: 'Firmeninformation\nAm Wasser 100\n12345 Am Wasser\nDeutschland\nUST-Id-Nr:DE999999999',
                style: 'footerColumn',
            },
        )

    }
    return profileHeaderRows;
}
export const addContactInfo = (publicProfile: PublicProfileSettings) => {
    const profileHeaderRows = [];
    if(!publicProfile?.features?.hasName &&!publicProfile?.features?.hasPhoneNumber&&!publicProfile?.features?.hasEmail){
        profileHeaderRows.push(
            {
                width: '33%',
                text: '',
                style: 'footerColumn',
            },
        )
        return;
    }
    if (publicProfile?.email || publicProfile?.phoneNumber || (publicProfile?.firstName && publicProfile?.lastName)) {
        profileHeaderRows.push(
            {
                width: '33%',
                text: [
                    {
                        text: 'Kontaktinformation\n',
                        bold: true,
                        fontSize: 8,

                    },
                    {
                        text: `${publicProfile?.features?.hasName?(publicProfile?.firstName + ' ' + publicProfile?.lastName + '\n'):''}`  +  `${publicProfile?.features?.hasPhoneNumber ? publicProfile?.phoneNumber ? 'Telefon: ' + publicProfile?.phoneNumber + '\n' : '' : ''}` + `${publicProfile?.features?.hasEmail ? publicProfile?.email ? 'E-Mail: ' + publicProfile?.email : '' : ''}`,
                        fontSize: 6.5,
                        color: '#404040'
                    }
                ],
                //text: 'Kontaktinformation\nKontaktperson\nTelefon: 020 123 45 67\nE-Mail: kontakt@firma.de\nwww.beispielfirma.be',
                style: 'footerColumn',
            },
        );
    } else {
        profileHeaderRows.push(
            {
                width: '33%',
                text: '',
                // text: [
                //     {
                //         text: 'Kontaktinformation\n',
                //         bold: true,
                //         fontSize: 8,
                //     },
                // ],
                //text: 'Firmeninformation\nAm Wasser 100\n12345 Am Wasser\nDeutschland\nUST-Id-Nr:DE999999999',
                style: 'footerColumn',
            },
        )

    }
    return profileHeaderRows;
};

export const addBankAccount = (publicProfile: PublicProfileSettings) => {
    const profileHeaderRows = [];

    if (publicProfile?.bankAccount) {
        profileHeaderRows.push(
            {
                width: '33%',
                text: [
                    {
                        text: 'Bankverbindung\n',
                        bold: true,
                        fontSize: 8,
                    },
                    {
                        text:'Name: '+`${publicProfile?.bankAccount?.accountHolder}` + '\nBank: ' + `${publicProfile?.bankAccount?.bank}` + '\nIBAN: ' + `${publicProfile?.bankAccount?.IBAN}` + '\nBIC: ' + `${publicProfile?.bankAccount?.BIC}`,
                        fontSize:6.5,
                        color: '#404040'
                    }
                ],
                style: 'footerColumn',
            }
        );
    }
    return profileHeaderRows;
};

const putLogo = (logo: CustomerLogo) => {
    if (!!logo) {
        return {
            image: 'logo',
            fit: [90, 90],
            margin:[0,0,0,5]
            //absolutePosition: {x: 70.8, y: 30}
        };
    }
};

const getImageDef = (logo: CustomerLogo) => {
    if (!!logo && !!logo?.url) {
        return {logo: logo?.url};
    }
};

export const profile_pdf = (profile: PublicProfileSettings): any => {
    const margin = 70.8;

    return {
        pageMargins: [margin, 30, 56.7,100],
        content: {
            columns: [
                putLogo(profile?.logo),
                createPublicProfileHeader(profile)
            ]
        },
        footer: function (currentPage, pageCount) {
            return {
                stack: [
                    {
                        text: 'Dieses Schreiben wurde maschinell erstellt und ist ohne Unterschrift gültig',
                        color: '#000000',
                        fontSize: 6,
                        alignment: 'left',
                        margin: [margin, 0, 0, 0]
                    },
                    {
                        canvas: [
                            {
                                type: 'line',
                                x1: 0,
                                y1: 5,
                                x2: 595 - 2 * margin,
                                y2: 5,
                                lineWidth: 1,
                            }
                        ],
                        margin: [margin, 0, 0, 5],
                    },
                    {
                        columns: [
                            addAddress(profile),
                            addContactInfo(profile),
                            addBankAccount(profile),
                        ],
                        margin: [margin, 0, 0, 0]

                    },
                    {
                        text: [
                            {
                                text: '--------------------------------------------------------------------------' +
                                    '\n',
                            },
                            {
                                text: [
                                    {
                                        text: '© nahaus.de ',
                                        bold: true,
                                        color: '#4f46e5',
                                        fontSize: 8,
                                    },
                                    {
                                        text: currentPage.toString() + ' von ' + pageCount,
                                        fontSize: 8,
                                    }
                                ]
                            }
                        ],
                        alignment: 'center',
                        margin: [0, 0, 0, 0]
                    },


                ],
            };
        },
        styles: {
            header: {
                fontSize: 20,
                bold: true,
                margin: [0, 0, 0, 8]
            },
            subheader: {
                fontSize: 13,
                bold: true,
                margin: [0, 10, 0, 5]
            },
            table: {
                // alignment: 'justify',
                margin: [0, 24]
            },
            footerColumn: {
                bold: true,
            }
        },
        images: getImageDef(profile?.logo),
        defaultStyle: {
            columnGap: 10,
            fontSize: 10
        }
    };
};







