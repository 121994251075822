import { Injectable } from '@angular/core';
import { MeterReRegistrationType, MeterReRegistrationTypeOptions, UnitMeter } from '@meters/models/meter.interface';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { ToDatePipe } from '@shared/pipes';
import firebase from 'firebase/compat';
import { EMPTY, Observable } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { Landlord } from '../../landlords/models';
import { LandlordsService } from '../../landlords/services/landlords.service';
import {
  PropertyUnitPeriod
} from '../../properties/features/units/features/period/models/property.unit.period.interface';
import { PeriodsService } from '../../properties/features/units/features/period/services/periods.service';
import { Property } from '../../properties/models/property.interface';
import { PropertiesService } from '../../properties/services/properties.service';
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;

export interface RegistrationState {
  meter: UnitMeter;
  property: Property;
  propertyID: string;
  unitID: string;
  landlord: Landlord;
  previousConsumer: string; // Vormieter oder bei Leerstand Eigentümer
  currentConsumer: string; // Nachmieter oder bei Leerstand Eigentümer
  type: MeterReRegistrationType;
  previousPeriod: PropertyUnitPeriod;
  currentPeriod: PropertyUnitPeriod;
  startDate?: Date;
  error: string;
  loading: boolean;
}

const initialState: RegistrationState =
  {
    meter: null,
    property: null,
    propertyID: null,
    unitID: null,
    landlord: null,
    previousConsumer: null,
    currentConsumer: null,
    type: null,
    previousPeriod: null,
    currentPeriod: null,
    error: null,
    loading: false
  };

@Injectable()
export class RegistrationStore extends ComponentStore<RegistrationState> {

  readonly landlord$: Observable<Landlord> = this.select(state => state.landlord);
  readonly property$: Observable<Property> = this.select(state => state.property);
  readonly meter$: Observable<UnitMeter> = this.select(state => state.meter);
  readonly type$: Observable<MeterReRegistrationType> = this.select(state => state.type);
  readonly previousPeriod$: Observable<PropertyUnitPeriod> = this.select(state => state.previousPeriod);
  readonly currentPeriod$: Observable<PropertyUnitPeriod> = this.select(state => state.currentPeriod);
  readonly previousConsumer$: Observable<string> = this.select(state => state.previousConsumer);
  readonly currentConsumer$: Observable<string> = this.select(state => state.currentConsumer);
  readonly startDate$: Observable<Date> = this.select(state => state.startDate);
  readonly loading$: Observable<boolean> = this.select(state => state.loading);

  readonly updateLoading = this.updater((state, loading: boolean) => (
    {
      ...state, loading
    }
  ));

  readonly updateLandlord = this.updater((state, landlord: Landlord) => (
    {
      ...state, landlord
    }
  ));

  readonly updateProperty = this.updater((state, property: Property) => (
    {
      ...state, property
    }
  ));

  readonly updateMeter = this.updater((state, meter: UnitMeter) => (
    {
      ...state, meter, propertyID: meter?.propertyID, unitID: meter?.unitID
    }
  ));

  readonly updateType = this.updater((state, type: MeterReRegistrationType) => (
    {
      ...state, type
    }
  ));

  readonly updatePreviousConsumer = this.updater((state, previousConsumer: string) => (
    {
      ...state, previousConsumer
    }
  ));

  readonly updateCurrentConsumer = this.updater((state, currentConsumer: string) => (
    {
      ...state, currentConsumer
    }
  ));

  readonly updatePreviousPeriod = this.updater((state, previousPeriod: PropertyUnitPeriod) => (
    {
      ...state, previousPeriod
    }
  ));

  readonly updateCurrentPeriod = this.updater((state, currentPeriod: PropertyUnitPeriod) => (
    {
      ...state, currentPeriod
    }
  ));

  readonly updateStartDate = this.updater((state, startDate: Date) => (
    {
      ...state, startDate
    }
  ));

  readonly updateError = this.updater((state, error: string) => (
    {
      ...state, error
    }
  ));
  /**
   * Step 7
   *
   * Detect meter type
   */
  readonly selectRegistrationType = this.effect((type$: Observable<MeterReRegistrationType>) => type$.pipe(
    tapResponse(
      type => console.log('step 7: selectRegistrationType', type),
      error => this.updateError('Error')
    ),
    tapResponse(
      (type: MeterReRegistrationType) => this.updateType(type),
      error => this.updateError('Error')
    ),
    switchMap(() => this.state$.pipe(
      first(),
      tapResponse(
        state => console.log('step 8: switchMap to detect consumers --> state', state),
        error => this.updateError('Error')
      ),
      map((state: RegistrationState) => {
        const selectedType = state?.type;

        // updatePreviousConsumer
        switch (selectedType) {
          case MeterReRegistrationTypeOptions.RENTED_RENTED:
          case MeterReRegistrationTypeOptions.RENTED_LEERSTAND:
          case MeterReRegistrationTypeOptions.NEW_METER:
            // get Previous tenants
            this.updatePreviousConsumer(state?.previousPeriod?.tenantsName);
            break;
          case MeterReRegistrationTypeOptions.LEERSTAND_RENTED:
            this.updatePreviousConsumer(state?.landlord?.displayName || `${state?.landlord?.firstName} ${state?.landlord?.lastName}`);
            break;
          case MeterReRegistrationTypeOptions.NEW_PROPERTY:
            this.updatePreviousConsumer('Unbekannt');
            break;
        }

        // updateCurrentConsumer
        switch (selectedType) {
          case MeterReRegistrationTypeOptions.RENTED_RENTED:
          case MeterReRegistrationTypeOptions.LEERSTAND_RENTED:
          case MeterReRegistrationTypeOptions.NEW_METER:
          case MeterReRegistrationTypeOptions.NEW_PROPERTY:
            // get current tenants
            this.updateCurrentConsumer(state?.currentPeriod?.tenantsName);
            break;
          case MeterReRegistrationTypeOptions.RENTED_LEERSTAND:
            this.updateCurrentConsumer(state?.landlord?.displayName || `${state?.landlord?.firstName} ${state?.landlord?.lastName}`);
            break;
        }

        if (state?.currentPeriod?.rental?.startDate) {
          console.log('step 9: the current period has a start date', state?.currentPeriod?.rental?.startDate);
          this.updateStartDate(this.toDate.transform(state?.currentPeriod?.rental?.startDate));
        }
      })
    ))
    // map((type: MeterReRegistrationType) => type),
  ));
  /**
   * Step 6
   *
   * Detect meter type
   */
  readonly detectMeterType = this.effect((periods$: Observable<Array<PropertyUnitPeriod>>) => periods$.pipe(
    tap(periods => console.log('step 6: detectMeterType', periods)),
    map((periods: Array<PropertyUnitPeriod>) => {
      let type: MeterReRegistrationType;
      if (periods?.length === 2) {
        // current period
        const currentPeriod: PropertyUnitPeriod = periods[0];
        this.updateCurrentPeriod(currentPeriod);
        // previous period
        const previousPeriod: PropertyUnitPeriod = periods[1];
        this.updatePreviousPeriod(previousPeriod);

        if (previousPeriod?.rental?.isRented && currentPeriod?.rental?.isRented) {
          type = MeterReRegistrationTypeOptions.RENTED_RENTED;
        } else if (previousPeriod?.rental?.isRented && !currentPeriod?.rental?.isRented) {
          type = MeterReRegistrationTypeOptions.RENTED_LEERSTAND;
          // previous consumer is the landlord
          // this.updatePreviousConsumer(this.previousConsumer$);
        } else {
          type = MeterReRegistrationTypeOptions.LEERSTAND_RENTED;
          this.updateType(MeterReRegistrationTypeOptions.LEERSTAND_RENTED);
        }
      } else if (periods?.length === 1) {
        // current period
        const currentPeriod: PropertyUnitPeriod = periods[0];
        this.updateCurrentPeriod(currentPeriod);
        console.log('checkAvailableRegistrationsType --> only one period found from firestore', currentPeriod);
      } else {
        console.log('checkAvailableRegistrationsType --> no periods found in firestore');
        this.updateCurrentPeriod(null);
      }
      return this.selectRegistrationType(type);
    })
  ));
  /**
   * Step 5
   * id$[0] == propertyID and id$[0] == unitID
   *
   * get the latest two periods of the meter,
   * if the meter belongs only to the property and not to a unit --> please code
   * a new logic
   */
  readonly getLatestTwoPeriodsFromFirestore = this.effect((landlord$: Observable<Landlord>) => landlord$.pipe(
    tap(landlord => console.log('step 5: getLatestTwoPeriodsFromFirestore for the landlord', landlord)),
    tap(() => this.updateLoading(true)),
    // 👇 Handle race condition with the proper choice of the flattening operator.
    switchMap(() => this.periodsService.collection(query => query.orderBy('rental.startDate', 'desc').limit(2)).get().pipe(
      filter(doc => doc?.size > 0),
      map(doc => doc?.docs),
      map((docs: Array<QueryDocumentSnapshot<PropertyUnitPeriod>>) => docs.map(doc => doc.data() as PropertyUnitPeriod)),
      // 👇 Act on the result within inner pipe.
      tapResponse(
        (periods: Array<PropertyUnitPeriod>) => this.detectMeterType(periods),
        error => this.updateError('Error')
      ),
      tap(() => this.updateLoading(false))
      // 👇 Handle potential error within inner pipe.
      // catchError(() => EMPTY)
    ))
  ));
  /**
   * Step 4
   * fetch the property of the meter from firestore to lookup landlord
   */
  readonly selectLandlord = this.effect((landlord$: Observable<Landlord>) => landlord$.pipe(
    tap(landlord => console.log('step 4: selectLandlord', landlord)),
    tap(landlord => this.updateLandlord(landlord)),
    map(landlord => this.getLatestTwoPeriodsFromFirestore(landlord))
  ));
  /**
   * Step 3
   * Fetch landlord from firestore
   */
  readonly getLandlordFromFirestore = this.effect((id$: Observable<string>) => id$.pipe(
    tap(() => this.updateLoading(true)),
    tap(id => console.log('step 3: getLandlordFromFirestore', id)),
    // 👇 Handle race condition with the proper choice of the flattening operator.
    switchMap(id => this.landlordService.doc(id).get().pipe(
      filter(doc => doc?.exists),
      map(doc => doc?.data() as Landlord),
      // 👇 Act on the result within inner pipe.
      tapResponse(
        landlord => this.selectLandlord(landlord),
        error => this.updateError('Error - landlord could not be retrieved')
      ),
      tap(() => this.updateLoading(false))
    ))
  ));
  /**
   * Step 2
   * fetch the property of the meter from firestore to lookup landlord
   */
  readonly getPropertyFromFirestore = this.effect((id$: Observable<string>) => id$.pipe(
    tap(id => console.log('step 2: getPropertyFromFirestore', id)),
    tap(() => this.updateLoading(true)),
    // 👇 Handle race condition with the proper choice of the flattening operator.
    switchMap(id => this.propertiesService.doc(id).get().pipe(
      // delay(5000),
      tap(() => console.log('step 2.1: getPropertyFromFirestore received')),
      filter(doc => doc?.exists),
      map(doc => doc?.data() as Property),
      // 👇 Act on the result within inner pipe.
      tapResponse(
        property => this.updateProperty(property),
        error => this.updateError('Error - landlord could not be retrieved')
      ),
      map((property: Property) => {
        if (property?.landlord?.firstName) {
          this.selectLandlord(property?.landlord);
          return property?.landlord;
        } else if (property?.landlord?.id) {
          return this.getLandlordFromFirestore(property.landlord?.id);
        } else {
          return EMPTY;
        }
      }),
      tap(() => this.updateLoading(false))
    ))
  ));
  /**
   * Step 1
   * Each new call of selectMeter(meter) pushed the big logic will run.
   */
  readonly selectMeter = this.effect((meter$: Observable<UnitMeter>) => meter$.pipe(
    tap((meter: UnitMeter) => this.updateLoading(true)),
    tap((meter: UnitMeter) => this.updateMeter(meter)),
    tap((meter: UnitMeter) => this.periodsService.parentPath = `properties/${meter?.propertyID}/units/${meter?.unitID}`),
    // tapResponse(
    //   meter => this.updateMeter(meter),
    //   error => this.updateError('Error')
    // ),
    map((meter: UnitMeter) => {
      console.log('step 1: selectMeter', meter);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      meter?.landlord?.firstName && meter?.landlord?.lastName ? this.selectLandlord(meter?.landlord)
        : this.getPropertyFromFirestore(meter?.propertyID);
      console.log('selectMeter pre-done');
      return meter;
    })
    // tapResponse(
    //   (meter: UnitMeter) => this.getLatestTwoPeriodsFromFirestore([meter?.propertyID, meter?.unitID]),
    //   error => this.updateError('Error')
    // ),
    // tap(() => this.updateLoading(false))
    // get latest two periods
    // tapResponse(
    //   (landlord: any) => this.updateLandlord(landlord),
    //   error => this.updateError('Error')
    // )
  ));

  constructor(private landlordService: LandlordsService,
              private propertiesService: PropertiesService,
              private periodsService: PeriodsService,
              private toDate: ToDatePipe) {
    super(initialState);
  }

}
